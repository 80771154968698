// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import ViewPepReportPage from '../../components/PEP/ViewReport';
interface IViewReport {
    id: string
}
const ViewPepReport: React.FC<IViewReport> = ({
    id
}) => {
    return (
        <Entrypoint>
            <ViewPepReportPage id={id} />
        </Entrypoint>
    );
}

export default ViewPepReport;