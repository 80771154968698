// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import GangverdReportComp from '../../components/Property/GangverdReport/GangverdReport';

const GangverdReport: React.FC<{id?: string}> = ({
    id
}) => {
    return (
        <Entrypoint>
            <GangverdReportComp id={id} />
        </Entrypoint>
    );
}

export default GangverdReport;