// Package imports:
import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import cx from 'classnames';
import { format } from 'date-fns';
import ISLocale from 'date-fns/locale/is';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
// Service imports:
import { SHORT_MONTHS } from '../../../services/config';
// Type imports:
import { IApiLmdIndexation } from '../../../types/RatesTypes';

interface IChartDatum {
    x: number | undefined
    y: number | undefined
};
interface IChartDataInfo {
    name: string,
    data: IChartDatum[]
};

interface IProps {
    data: IApiLmdIndexation[],
    title?: string,
    detailed?: boolean,
    height?: number
};

const HagtolurChart: React.FC<IProps> = ({
    data,
    title = '',
    detailed = false,
    height = 150
}) => {
    const [ showInfo, setShowInfo ] = useState(detailed);

    const divRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const chartDataInfo: IChartDataInfo = {
            name: title,
            data: []
        }
        for (let datum of data) {
            chartDataInfo.data.push({
                x: datum.valuedate ? Date.parse(datum.valuedate) : undefined,
                y: datum.index_value ?? undefined
            });
        }

        Highcharts.setOptions({
            lang: {
                shortMonths: SHORT_MONTHS,
                resetZoom: '&nbsp &nbspTil baka &nbsp &nbsp',
                resetZoomTitle: ''
            }
        });

        if (divRef.current !== null) {
            Highcharts.chart(divRef.current, {
                chart: {
                    height: height,
                    marginTop: detailed ? 80 : 10,
                    reflow: true,
                    style: {
                        fontFamily: "Roboto"
                    },
                    zoomType: detailed ? "xy" : undefined,
                    selectionMarkerFill: '#4569EE18',
                    resetZoomButton: {
                        position: {
                            x: 0,
                            y: -75
                        },
                        theme: {
                            r: 6,
                            height: 28
                        }
                    },
                    plotBackgroundColor: {
                        linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                        stops: [
                            [0.05, '#FFF'],
                            [0.95, '#F8F8FA']
                        ]
                    }
                },
                exporting: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: '',
                    align: 'center',
                    x: 60,
                    margin: -14
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                tooltip: {
                    enabled: detailed,
                    formatter: function() {
                        return `
                            <div class="chart-tooltip">
                                <p class="tooltip-date">${format(this.x, 'MMM y', {locale: ISLocale})}</p>
                                <p class="tooltip-value">${title} ${this.y}</p>
                            </div>`;
                    },
                    borderWidth: 0,
                    shadow: false,
                    useHTML: true
                },
                xAxis: [{
                    type: 'datetime',
                    title: {
                        text: null
                    },
                    minTickInterval: detailed ? 1000*60*60*24*30 : 1000*60*60*24*365,
                    tickWidth: 0,
                    events: {
                        setExtremes: () => {
                            setShowInfo(false);
                        }
                    }
                }],
                yAxis: {
                    title: {
                        text: ''
                    },
                    gridLineDashStyle: 'Dash',
                    gridLineWidth: 1,
                    gridLineColor: '#D3D3D6'
                },
                plotOptions: {
                    series: {
                        color: '#4569EE',
                        enableMouseTracking: detailed,
                        marker: {
                            enabled: false
                        },
                        animation: false
                    }
                },
                legend: {
                    enabled: detailed,
                    align: 'left',
                    verticalAlign: 'top',
                    itemStyle: {
                        color: '#232530',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '26px'
                    },
                    y: 10
                },
                series: [{ type: 'line', ...chartDataInfo }]
            }, () => {});
        }
    }, [data, divRef.current]);


    return (
        <div className="KCL_hagtolur-chart">
            <span className={cx('info-bubble', {'is-shown': showInfo})}>
                <Alert
                    type="info"
                    headText="Hægt er að velja svæði til að skoða nánar"
                />
            </span>
            <div className="chart" ref={divRef}></div>
        </div>
    );
}

export default HagtolurChart;