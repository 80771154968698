// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import MembershipSearchPage from '../../components/Individual/Hlutafelagathatttaka/MembershipSearch';

interface IProps {
    isAuthenticated?: boolean
}
const MembershipSearch: React.FC<IProps> = ({isAuthenticated}) => {
    return (
        <Entrypoint>
            <MembershipSearchPage isAuthenticated={isAuthenticated ?? false} />
        </Entrypoint>
    );
}

export default MembershipSearch;