// Package imports:
import React, { useMemo } from 'react';
import { SeriesOptionsType } from 'highcharts/highstock';
import ISLocale from 'date-fns/locale/is';
import { format } from 'date-fns';
import cx from 'classnames';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import DefaultChart from '../DefaultChart/DefaultChart';
// Service imports:
import { formatNumber, getAPItoday } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IChartDataInfo, IDefaultProps } from '../../../types/Types';
import { IApiHistoryTimeseriesShort, IApiHistoryTimeseriesShortDATA } from '../../../types/MarketTypes';

interface IOwnProps {
    title?: string,
    height?: number
};

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const FxMarketChart: React.FC<Props> = ({
    title,
    height = 500,
    accessToken,
    refreshRateMs,
    className,
    ...props
}) => {
    const today = new Date();
    const from = new Date(today.getFullYear() - 5, today.getMonth() + 1, today.getDate());
    const [EURapiData] = useApiLmdData<IApiHistoryTimeseriesShortDATA>(
        `/v1/market_data/v1/tradables/EURISK:KELD/history_timeseries_short/?from_date=${getAPItoday(from)}&to_date=${getAPItoday(today)}`,
        accessToken,
        refreshRateMs
    );
    const [USDapiData] = useApiLmdData<IApiHistoryTimeseriesShortDATA>(
        `/v1/market_data/v1/tradables/USDISK:KELD/history_timeseries_short/?from_date=${getAPItoday(from)}&to_date=${getAPItoday(today)}`,
        accessToken,
        refreshRateMs
    );

    const handleFxmarketData = (body: IApiHistoryTimeseriesShortDATA) => {
        let dataToReturn: IApiHistoryTimeseriesShort[] = [];
        for(let datum of body.data){
            dataToReturn.push({
                trading_date: datum[0],
                open: datum[1],
                open_yield: datum[2],
                high: datum[3],
                high_yield: datum[4],
                low: datum[5],
                low_yield: datum[6],
                close: datum[7],
                close_yield: datum[8],
                volume: datum[9],
                open_corrected: datum[10],
                high_corrected: datum[11],
                low_corrected: datum[12],
                close_corrected: datum[13],
                volume_corrected: datum[14],
            })
        }
        return dataToReturn;
    }

    const getEURISK = () => {
        const chartDataInfoEURISK: IChartDataInfo = {
            name: '',
            data: []
        }
        //EURISK VOLUME
        const chartDataInfoVolumeEURISK: IChartDataInfo = {
            name: '',
            data: []
        }

        if (EURapiData.data !== null && !(EURapiData.error instanceof Error)) {
            chartDataInfoEURISK.name = 'EURISK verð';
            chartDataInfoVolumeEURISK.name = 'EURISK magn';

            for (let datum of handleFxmarketData(EURapiData.data)) {
                if (datum.trading_date === null) continue;
                chartDataInfoEURISK.data.push({
                    x: datum.trading_date,
                    y: datum.close ?? undefined
                });
                chartDataInfoVolumeEURISK.data.push({
                    x: datum.trading_date,
                    y: datum.volume ?? undefined
                });
            }
        }
        return [chartDataInfoEURISK, chartDataInfoVolumeEURISK];
    }
    const getUSDISK = () => {
        const chartDataInfoUSDISK: IChartDataInfo = {
            name: '',
            data: []
        }

        //USDISK VOLUME
        const chartDataInfoVolumeUSDISK: IChartDataInfo = {
            name: '',
            data: []
        }

        if (USDapiData.data !== null && !(USDapiData.error instanceof Error)) {
            chartDataInfoUSDISK.name = 'USDISK verð';
            chartDataInfoVolumeUSDISK.name = 'USDISK magn';
            for (let datum of handleFxmarketData(USDapiData.data)) {
                if (datum.trading_date === null) continue;
                chartDataInfoUSDISK.data.push({
                    x: datum.trading_date,
                    y: datum.close ?? undefined
                });
                chartDataInfoVolumeUSDISK.data.push({
                    x: datum.trading_date,
                    y: datum.volume ?? undefined
                });
            }
        }
        return [chartDataInfoUSDISK, chartDataInfoVolumeUSDISK];
    }
    const series: SeriesOptionsType[] = useMemo(() => {
        const [chartDataInfoEURISK, chartDataInfoVolumeEURISK] = getEURISK();
        const [chartDataInfoUSDISK, chartDataInfoVolumeUSDISK] = getUSDISK();

        return [{
            //EURISK PRICE
            type: 'line',
            id: 'EURISK_P',
            name: chartDataInfoEURISK.name,
            data: chartDataInfoEURISK.data,
            yAxis: 0
        }, {
            //EURISK VOLUME
            type: 'column',
            id: 'EURISK_V',
            name: chartDataInfoVolumeEURISK.name,
            data: chartDataInfoVolumeEURISK.data,
            yAxis: 1
        }, {
            //USDISK PRICE
            type: 'line',
            id: 'USDISK_P',
            name: chartDataInfoUSDISK.name,
            data: chartDataInfoUSDISK.data,
            color: '#038600',
            yAxis: 0
        }, {
            //USDISK VOLUME
            type: 'column',
            id: 'USDISK_V',
            name: chartDataInfoVolumeUSDISK.name,
            data: chartDataInfoVolumeUSDISK.data,
            yAxis: 1,
            color: '#038600'
        }];
    }, [EURapiData, USDapiData]);

    return (
        <DisplayBox 
            className={cx('KCL_chart', className)}
            title={title}
            {...props}
        >
            <DefaultChart
                height={height}
                tooltipFormatter={
                    function() {
                        return `<div class="chart-tooltip">
                            ${this.points?.reduce(function (s, point) {
                                return `${s}<p><span style="color: ${point.color}">${point.series.getName()}</span>: ${formatNumber(point.y)}</p>`;
                            }, `<p class="tooltip-date">${format(this.x, 'EEEE, dd. MMM yyyy', {locale: ISLocale})}</p>`)}
                        </div>`;
                        
                    }
                }
                yAxes={[{
                    title: {
                        text: 'Verð',
                        offset: 52,
                        x: 5
                    },
                    height: '70%',
                    labels: {
                        x: -11,
                        y: 2
                    }
                }, {
                    title: {
                        text: 'Magn',
                        offset: 52,
                        x: 5
                    },
                    top: '76%',
                    height: '24%',
                    offset: 0,
                    labels: {
                        x: -11,
                        y: 2
                    }
                }]}
                series={series}
            />
        </DisplayBox>
    );
}

export default FxMarketChart;