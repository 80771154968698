// Package imports:
import React, { useEffect } from 'react';
// Component imports:
import Loading from '../../ui-elements/Loading/Loading';
import Alert from '../../ui-elements/Alert/Alert';
// Service imports:
import { formatNumber } from '../../services/utils';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdTradables, IApiLmdOrderbook, IApiLmdSharesStatic } from '../../types/HlutabrefTypes';
import { useFetchedApiLmdData } from '../../services/apiHooks';

interface IOwnProps {
    symbol: string,
    setShareName(name: string | undefined): void,
}

type Props = IOwnProps & IDefaultProps;

const TradablesInformationShares: React.FC<Props> = ({
    symbol,
    setShareName,
    accessToken
}) => {
    const data = useFetchedApiLmdData<IApiLmdTradables[]>(
        `/v1/static_data/v1/exchanges/*/markets/*/tradables/${symbol}`,
        accessToken
    );
    const totalIssue = useFetchedApiLmdData<IApiLmdSharesStatic[]>(
        `/v1/static_data/v1/exchanges/*/markets/*/shares/${symbol}`,
        accessToken
    );
    
    const orderbook = useFetchedApiLmdData<IApiLmdOrderbook[]>(
        (Array.isArray(data) && data.length > 0 && data[0].IdCode !== null)
            ? `/v1/static_data/v1/sectors/orderbook/${data[0].IdCode}`
            : null,
        accessToken
    )

    useEffect(() => {
        if (Array.isArray(data) && data.length > 0 && data[0].AbbreviatedName !== null) setShareName(data[0].AbbreviatedName);
    }, [data]);

    if (data === null || orderbook === null) {
        return <div className='KCL_tradables-information'><Loading /></div>;
    }
    if (data instanceof Error) {
        return <div className='KCL_tradables-information'><Alert type="error" headText={data.message} /></div>;
    }
    return (
        <div className='KCL_tradables-information'>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Auðkenni</span>
                    <span className="value">{symbol}</span>
                </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">ISIN</span>
                    <span className="value">{data[0]?.Isin ?? '-'}</span>
                </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Gjaldmiðill</span>
                    <span className="value">{data[0]?.TradingCurrency ?? '-'}</span>
                </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Viðskiptavaki</span>
                    <span className="value">{data[0]?.LiquidityProvider ? 'Já' : 'Nei'}</span>
                </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Fjöldi hluta</span>
                    <span className="value">{(totalIssue instanceof Error || totalIssue === null) ? '-' : formatNumber(totalIssue[0]?.TotalIssue, '-', 0)}</span>
                </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Markaðsauðkenni</span>
                    <span className="value">{data[0]?.MarketSymbol ?? '-'}</span>
                </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Geiri</span>
                    <span className="value">{orderbook instanceof Error ? '-' : orderbook[0]?.SecurityGroupIdMap}</span>
                </div>
            </div>
        </div>
    );
}

export default TradablesInformationShares;