// Package imports:
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
// Component imports:
import Label from '../../../ui-elements/Label/Label';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Table from '../../../ui-elements/Table/Table';
import Alert from '../../../ui-elements/Alert/Alert';
import Loading from '../../../ui-elements/Loading/Loading';
import Button from '../../../ui-elements/Button/Button';
import Link from '../../../ui-elements/Link/Link';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { useBuyingProcess } from '../../../services/buyhook';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { IKeldanApiResponse, OwnedReport } from '../../../types/KeldanTypes';
import { Fetched } from '../../../types/Types';
import { IPropertyViewModel } from '../../../types/PropertyTypes';

type Props = IPropertyViewModel & { full?: boolean };

type PurchaseOption = 'ekki veðbönd' | 'með veðbönd';

const ReportsList: React.FC<Props> = ({
    propertyNumber,
    bondsAllowed,
    full = false
}) => {
    const [ownedReports, setOwnedReports] = useState<Fetched<OwnedReport[]>>(null);

    useEffect(() => {
        const fetchOwnedReports = async () => {
            try {
                const url = `${GET_KELDAN_API_URL()}/Property/GetOwnedReports?propertyNumber=${propertyNumber}`;
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    }
                });
                if (response.ok) {
                    if (response.redirected) {
                        window.location.href = response.url;
                    }
                    const responseBody: IKeldanApiResponse<OwnedReport[]> = await response.json();
                    const { result, message } = responseBody;
                    if (result) {
                        setOwnedReports(result);
                    } else if (message) {
                        setOwnedReports(new Error(message));
                    } else {
                        setOwnedReports(new Error(ErrorMessages.ErrorInRequest));
                    }
                } else {
                    setOwnedReports(new Error(ErrorMessages.RequestFailed));
                }
            } catch (e) {
                setOwnedReports(new Error(ErrorMessages.OtherShort));
            }
        }
        fetchOwnedReports();
    }, []);

    const { tryPurchaseItem, isItemLoading, resetState, ModalsAndToasts } = useBuyingProcess(async (option: PurchaseOption) => {
        const url = `${GET_KELDAN_API_URL()}/Fasteignir/Fasteignaskra-Kaupa?fastanr=${propertyNumber}&bonds=${(option === 'ekki veðbönd' ? 'false' : 'true')}`;
        try {
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                    return;
                }
                const responseBody: IKeldanApiResponse<OwnedReport[]> = await response.json();
                const { message } = responseBody;
                if (message) {
                    if (message === ErrorMessages.NoCard) {
                        resetState('no card');
                    } else {
                        resetState({
                            type: 'alert',
                            headText: message
                        });
                    }
                } else {
                    resetState('bad request');
                }
            } else {
                resetState('response not ok');
            }
        } catch (e) {
            resetState('network error');
        }
    }, 'fasteignaskrá');

    const displayOwnedReports = () => {
        if (ownedReports instanceof Error) {
            return <Alert type='error' headText={ownedReports.message} />;
        }
        if (ownedReports === null) {
            return <Loading />;
        }
        return <Table
            dontShowHeaders
            tableSize='lg'
            data={(full)
                ? ownedReports
                : ownedReports.slice(0, 4)
            }
            columns={[{
                title: '',
                renderCell: ({properDate}) => `Skráning keypt: ${properDate}`,
                textAlign: 'left'
            }, {
                title: '',
                renderCell: ({balls}) => 
                <Button
                    anchorProps={{href: `/Fasteignir/Fasteignaskra-Skoda/${balls}`}}
                    size='sm'
                    buttonType='secondary'
                >
                    Skoða
                </Button>
            }]}
        />
    }

    return (
        <>
            <DisplayBox
                anchorProps={{ href: `/Fasteignir/Fasteignaskra?fastanr=${propertyNumber}` }}
                className={cx('KCL_property-reports-list', {'full': full })}
                title='Fasteignaskrá'
                asideComponent={<Label  labelType='origin' text='FMR' />}
                footerLeft={!full && ownedReports !== null && !(ownedReports instanceof Error) && ownedReports.length > 4
                    ? <Link
                        linkSize='14'
                        url={`/Fasteignir/Fasteignaskra?fastanr=${propertyNumber}`}
                        className='more-link'
                        icon='forward'
                    >
                        Fleiri skráningar
                    </Link>
                    : undefined
                }
            >
                <div className='buy-current-row'>
                    <span className='buy-current-text'>Gildandi skráning</span>
                    <Button
                        buttonType='buy'
                        size='sm'
                        showLoader={isItemLoading('ekki veðbönd')}
                        onClick={() => tryPurchaseItem('ekki veðbönd', 'confirm-modal-with-reference')}
                    >
                        Kaupa
                    </Button>
                </div>
                {(bondsAllowed) &&
                    <div className='buy-current-row'>
                        <span className='buy-current-text'>Gildandi skráning með veðböndum</span>
                        <Button
                            buttonType='buy'
                            size='sm'
                            showLoader={isItemLoading('með veðbönd')}
                            onClick={() => tryPurchaseItem('með veðbönd', 'confirm-modal-with-reference')}
                        >
                            Kaupa
                        </Button>
                    </div>
                }
                {displayOwnedReports()}
            </DisplayBox>
            <ModalsAndToasts />
        </>
    );
}

export default ReportsList;