// Package imports:
import React from 'react';
// Component imports:
import Tabs from '../../../ui-elements/Tabs/Tabs';
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';
import CompanySearch from '../CompanySearch/CompanySearch';
import MembershipSearch from '../../Individual/Hlutafelagathatttaka/MembershipSearch';
// Type imports:
import { IisAuthenticated } from '../../../types/Types';
import CompanySamanburdur from '../CompanySearch/CompanySamanburdur';
import { ICompanySearchResultsItem } from '../../../types/SearchTypes';
import CompanyVerdmat from '../CompanySearch/CompanyVerdmat';
import CompanyGildandiSkraning from '../CompanySearch/CompanyGildandiSkraning';

export function pickRandomCompanies(
    companies: ICompanySearchResultsItem[],
    count: number
): ICompanySearchResultsItem[] {
    const shuffled = [...companies].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
}

const PropertySearches: React.FC<IisAuthenticated> = ({
	isAuthenticated = false,
	isSubscriber = false
}) => {

    const [showRecentReports, setShowRecentReports] = React.useState(false);
	return (
		<div className="main KCL_Fyrirtaeki company-search-page">
			<div className='section__body'>
				<div className='shell'>
					<HeadSection
						title='Fyrirtæki'
						showRecentReports={showRecentReports}
					/>
				</div>
				<Tabs  
					tabsSize="lg"
					saveTabToUrl
					tabs={[{
						id: "Fyrirtaekjaleit",
						label: "Fyrirtækjaleit",
						component: <CompanySearch />
					}, {
						id: "Hlutafelagathatttaka",
						label: "Hlutafélagaþátttaka",
						component: <MembershipSearch isAuthenticated={isAuthenticated} isSubscriber={isSubscriber} />
					}, {
						
						id: "GildandiSkraning",
						label: "Gildandi skráning",
						component: <CompanyGildandiSkraning isAuthenticated={isAuthenticated} isSubscriber={isSubscriber}/>
					}, {
						id: "Samanburdur",
						label: "Samanburður",
						component: <CompanySamanburdur />
					}, {
						id: "Verdmat",
						label: "Verðmat",
						component: <CompanyVerdmat isAuthenticated={isAuthenticated} isSubscriber={isSubscriber} />
					}]}
					shellHead
                    onTabChange={(i) => {
                        setShowRecentReports(isAuthenticated && i !== 0);
                    }}
				/>
			</div>
		</div>
	)
}

export default PropertySearches;