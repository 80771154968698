// Package imports:
import React, { useState, useMemo } from 'react';
import cx from 'classnames';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../ui-elements/Link/Link';
import CalculatedColoredNumber from '../../ui-elements/CalculatedColoredNumber/CalculatedColoredNumber';
import DelayOrClosedLabel from '../DelayOrClosedLabel/DelayOrClosedLabel';
import DatePicker from '../../ui-elements/DatePicker/DatePicker';
import PriceRange from '../../ui-elements/PriceRange/PriceRange';
import LmdTable from '../../ui-elements/Table/LmdTable';
import LmdSingleLineTable from '../../ui-elements/Table/LmdSingleLineTable';
// Service imports:
import { convertToPercentage, formatNumber, displayQuantityShorthand, getAPItoday } from '../../services/utils';
import { useApiLmdData, useApiLmdDataMappedByString } from '../../services/apiHooks';
// Type imports:
import { IApiLmdStatistics } from '../../types/HlutabrefTypes';
import { IApiLmdSnapshot, IApiLmdHistory } from '../../types/SkuldabrefTypes';
import { ApiLmdData, IDefaultProps } from '../../types/Types';

interface IOwnProps {
    symbol?: string,
    exchange?: string,
    market?: string,
    indexed?: boolean,
    showDatepicker?: boolean
}

interface ICombinedData {
    symbol: string | null,
    last_yield: number | null,
    last_yield_diff: number | null,
    bid_yield: number | null,
    ask_yield: number | null,
    last_price: number | null,
    last_price_diff: number | null,
    bid: number | null,
    ask: number | null,
    last_duration: number | null,
    intraday_accumulated_turnover: number | null,
    intraday_no_of_trades: number | null,
    weeks4_price_change_per?: number | null,
    year_price_change_per?: number | null,
    months12_price_change_per?: number | null,
    last_trade_dateutc?: string | null,
    months12_high_price?: number | null,
    months12_low_price?: number | null
}

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

/**
 * Renders a table component for bonds, given the parameters
 * @param symbol If present shows only this symbol. If undefined, show all symbols.
 * @param exchange Specifies the exchange to use.
 * @param market Specifies the market to use.
 * @param indexed is the bond indexed or not (ís. verðtryggt)
 * @param showDatepicker Allow user to choose date of data.
 * @param accessToken Default Props
 * @param refreshRateMs Default Props
 * @returns A JSX Table component
 */
const BondsTable: React.FC<Props> = ({
    symbol,
    exchange = 'XICE',
    market = 'ICECB',
    indexed,
    showDatepicker,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [data] = useApiLmdData<IApiLmdSnapshot[]>(
        (symbol
            ? `/v1/market_data/v1/exchanges/*/markets/*/bonds/${symbol}/delayed_snapshot/`
            : `/v1/market_data/v1/exchanges/${exchange}/active/bonds/delayed_snapshot`
        ),
        accessToken,
        refreshRateMs
    );
    const [currentDate, setCurrentDate, statistics] = useApiLmdDataMappedByString<IApiLmdStatistics[]>(
        getAPItoday(),
        (date: string) => `/v1/market_data/v1/exchanges/${exchange}/markets/${market}/tradables/${symbol ?? '*'}/statistics_delayed?trading_date=${date}`,
        accessToken,
        refreshRateMs
    );
    const [, setHistoryDate, history] = useApiLmdDataMappedByString<IApiLmdHistory[]>(
        getAPItoday(),
        (date: string) => `/v1/market_data/v1/exchanges/${exchange}/markets/${market}/tradables/${symbol ?? '*'}/history?from_date=${date}&to_date=${date}`,
        accessToken
    );
    const [bondsToUse, setBondsToUse] = useState<(string | null)[]>([]);

    const isToday = useMemo(() => (currentDate === getAPItoday()), [ currentDate ])

    const calculatePriceRangeRatio = (low: number | null, value: number | null, high: number | null) => {
        // Null check.
        if (low === null || value === null || high === null) return null;
        const formattedLowNumber = formatNumber(low, '-', 2);
        const formattedHighNumber = formatNumber(high, null, 2);
        // If equal, value is in middle
        if (formattedLowNumber === formattedHighNumber) return 0.5;
        return (value - low) / (high - low);
    }

    const sortData = (dataToSort: ICombinedData[]) => {
        return dataToSort.sort((a, b) => {
            if (a.symbol && b.symbol) {
                if (a.symbol > b.symbol) return 1;
                if (a.symbol < b.symbol) return -1;
                return 0;
            }
            if (a.symbol) return 1;
            if (b.symbol) return -1;
            return 0;
        })
    }
    
    const getTodayData = () => {
        const newData: ICombinedData[] = [];
        if (data === null || data.data === null || data.error instanceof Error) {
            return [];
        }
        const dataToUse = symbol ? data.data : data.data.filter(e => (e.index_name !== null) === indexed);
        dataToUse.forEach((entry) => {
            const statsEntry = statistics.data?.find(e => e.symbol === entry.symbol);
            newData.push({
                symbol: entry.symbol,
                last_yield: entry.last_yield,
                last_yield_diff: entry.last_yield_diff,
                bid_yield: entry.bid_yield,
                ask_yield: entry.ask_yield,
                last_price: entry.last_price,
                last_price_diff: entry.last_price_diff,
                bid: entry.bid,
                ask: entry.ask,
                last_duration: entry.last_duration,
                intraday_accumulated_turnover: entry.intraday_accumulated_turnover,
                intraday_no_of_trades: entry.intraday_no_of_trades,
                months12_high_price: statsEntry?.months12_high_price,
                months12_low_price: statsEntry?.months12_low_price
            });
        })
        return newData;
    }

    const getHistoryData = () => {
        const newData: ICombinedData[] = [];
        if (history === undefined || history.data === null || history.error instanceof Error) {
            return newData;
        }
        const dataToUse = history.data.filter(x => bondsToUse.includes(x.symbol));
        dataToUse.forEach((entry) => {
            const statsEntry = statistics.data?.find(e => e.symbol === entry.symbol);
            const dataEntry = data !== null && !(data.error instanceof Error) ? data.data?.find(e => e.symbol === entry.symbol) : null;
            newData.push({
                symbol: entry.symbol,
                last_yield: entry.close_yield,
                last_yield_diff: statsEntry?.yesterdays_yield_change_nom ?? 0,
                bid_yield: null,
                ask_yield: null,
                last_price: entry.close,
                last_price_diff: statsEntry?.yesterdays_price_change_per ?? 0,
                bid: null,
                ask: null,
                last_duration: dataEntry?.last_duration ?? 0,
                intraday_accumulated_turnover: statsEntry?.yesterdays_value_traded ?? 0,
                intraday_no_of_trades: entry.number_of_trades,
                months12_high_price: statsEntry?.months12_high_price,
                months12_low_price: statsEntry?.months12_low_price
            });
        })
        return newData;
    }
    
    const displayData = useMemo(() => {
        let newData: ICombinedData[] = [];
        if (statistics?.data === undefined || statistics.error instanceof Error) {
            return newData;
        }

        if (isToday) {
            newData = getTodayData();
            if (bondsToUse.length === 0) {
                const btu = newData.map(entry => entry.symbol);
                setBondsToUse(btu);
            }
        } else {
            newData = getHistoryData();
        }
        return sortData(newData);
    }, [data, statistics, history]);

    const apiData: ApiLmdData<ICombinedData[]> = {
        data: displayData,
        error: data.error ?? statistics.error ?? history.error ?? null
    }

    return (
        <DisplayBox
            className={cx('KCL_bonds-table', {'hide-symbol': symbol})}
            title={symbol ?? `${indexed ? 'Verðtryggð' : 'Óverðtryggð'} skuldabréf`}
            statusComponent={<DelayOrClosedLabel />}
            asideComponent={showDatepicker
                ? <DatePicker
                    maxDate={new Date()}
                    size="sm"
                    selectedDate={new Date(currentDate)}
                    setSelectedDate={(newDate) => { setCurrentDate(getAPItoday(newDate)); setHistoryDate(getAPItoday(newDate)); }}
                    lang="is"
                    id={`date-picker-${indexed ? 'indexed' : 'nonindexed'}`}
                />
                : undefined
            }
            {...props}
        >
            {symbol
                ? <LmdSingleLineTable
                    apiData={{ data: apiData.data?.[0] ?? null, error: apiData.error }}
                    columns={[{
                        title: 'Auðkenni',
                        renderCell: ({symbol}) => <Link url={`/Markadir/Skuldabref/${symbol}`} linkSize='15'>{symbol}</Link>,
                        textAlign: 'left',
                    }, {
                        title: 'Áv. krafa',
                        renderCell: ({last_yield}) => convertToPercentage(last_yield, true, 2),
                        textAlign : 'left',
                    }, {
                        title: 'Kröfubr.',
                        renderCell: ({last_yield_diff}) => {
                            return (
                                <CalculatedColoredNumber
                                    currentValue={last_yield_diff}
                                    formatting='yield'
                                    hasArrow
                                    hasColor
                                />
                            )
                        },
                        textAlign: 'left',
                    }, {
                        title: 'Kaupkrafa',
                        renderCell: ({bid_yield}) => convertToPercentage(bid_yield, true, 2),
                        textAlign: 'left',
                    }, {
                        title: 'Sölukrafa',
                        renderCell: ({ask_yield}) => convertToPercentage(ask_yield, true, 2),
                        textAlign: 'left',
                    }, {
                        title: 'Verð',
                        renderCell: ({last_price}) => formatNumber(last_price),
                        textAlign: 'left',
                    }, {
                        title: 'Verðbr.',
                        renderCell: ({last_price_diff}) => {
                            return (
                                <CalculatedColoredNumber
                                    currentValue={last_price_diff}
                                    formatting='integer'
                                    hasArrow
                                    hasColor
                                />
                            )
                        },
                        textAlign: 'left',
                    }, {
                        title: 'Kaupverð',
                        renderCell: ({bid}) => formatNumber(bid),
                        textAlign: 'left',
                    }, {
                        title: 'Söluverð',
                        renderCell: ({ask}) => formatNumber(ask),
                        textAlign: 'left',
                    }, {
                        title: 'Líftími',
                        renderCell: ({last_duration}) => formatNumber(last_duration),
                        textAlign: 'left',
                    }, {
                        title: 'Fj. viðskipta',
                        renderCell: ({intraday_no_of_trades}) => intraday_no_of_trades,
                        textAlign: 'left',
                    }, {
                        title: 'Velta',
                        renderCell: ({intraday_accumulated_turnover}) => displayQuantityShorthand(intraday_accumulated_turnover),
                        textAlign: 'left',
                    }, {
                        title: 'Árs bil',
                        renderCell: ({months12_low_price, months12_high_price, last_price, symbol}) => <PriceRange low={months12_low_price ?? null} high={months12_high_price ?? null} value={last_price} />,
                    }]}
                    tableSize="lg"
                />
                : <LmdTable
                    apiData={apiData}
                    columns={[{
                        title: 'Auðkenni',
                        renderCell: ({symbol}) => <Link url={`/Markadir/Skuldabref/${symbol}`} linkSize='15'>{symbol}</Link>,
                        textAlign: 'left',
                        simpleSortable: ({symbol}) => symbol
                    }, {
                        title: 'Áv. krafa',
                        renderCell: ({last_yield}) => convertToPercentage(last_yield, true, 2),
                        textAlign: 'right',
                        simpleSortable: ({last_yield}) => last_yield
                    }, {
                        title: 'Kröfubr.',
                        renderCell: ({last_yield_diff}) => {
                            return (
                                <CalculatedColoredNumber
                                    currentValue={last_yield_diff}
                                    formatting='yield'
                                    hasArrow
                                    hasColor
                                />
                            )
                        },
                        textAlign: 'right',
                        simpleSortable: ({last_yield_diff}) => last_yield_diff
                    }, {
                        title: 'Kaupkrafa',
                        renderCell: ({bid_yield}) => convertToPercentage(bid_yield, true, 2),
                        textAlign: 'right',
                        simpleSortable: ({bid_yield}) => bid_yield
                    }, {
                        title: 'Sölukrafa',
                        renderCell: ({ask_yield}) => convertToPercentage(ask_yield, true, 2),
                        textAlign: 'right',
                        simpleSortable: ({ask_yield}) => ask_yield
                    }, {
                        title: 'Verð',
                        renderCell: ({last_price}) => formatNumber(last_price),
                        textAlign: 'right',
                        simpleSortable: ({last_price}) => last_price
                    }, {
                        title: 'Verðbr.',
                        renderCell: ({last_price_diff}) => {
                            return (
                                <CalculatedColoredNumber
                                    currentValue={last_price_diff}
                                    formatting='integer'
                                    hasArrow
                                    hasColor
                                />
                            )
                        },
                        textAlign: 'right',
                        simpleSortable: ({last_price_diff}) => last_price_diff
                    }, {
                        title: 'Kaupverð',
                        renderCell: ({bid}) => formatNumber(bid),
                        textAlign: 'right',
                        simpleSortable: ({bid}) => bid
                    }, {
                        title: 'Söluverð',
                        renderCell: ({ask}) => formatNumber(ask),
                        textAlign: 'right',
                        simpleSortable: ({ask}) => ask
                    }, {
                        title: 'Líftími',
                        renderCell: ({last_duration}) => formatNumber(last_duration),
                        textAlign: 'right',
                        simpleSortable: ({last_duration}) => last_duration
                    }, {
                        title: 'Fj. viðskipta',
                        renderCell: ({intraday_no_of_trades}) => intraday_no_of_trades,
                        textAlign: 'right',
                        simpleSortable: ({intraday_no_of_trades}) => intraday_no_of_trades
                    }, {
                        title: 'Velta',
                        renderCell: ({intraday_accumulated_turnover}) => displayQuantityShorthand(intraday_accumulated_turnover),
                        textAlign: 'right',
                        simpleSortable: ({intraday_accumulated_turnover}) => intraday_accumulated_turnover
                    }, {
                        title: 'Árs bil',
                        renderCell: ({months12_low_price, months12_high_price, last_price}) => <PriceRange low={months12_low_price ?? null} high={months12_high_price ?? null} value={last_price} />,
                        simpleSortable: ({months12_low_price, last_price, months12_high_price}) => calculatePriceRangeRatio(months12_low_price ?? null, last_price, months12_high_price ?? null)
                    }]}
                    tableSize="lg"
                />
            }
        </DisplayBox> 
    );
}

export default BondsTable;