// Package imports:
import React from 'react';
// Component imports:
import Label from '../../ui-elements/Label/Label';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import LmdTable from '../../ui-elements/Table/LmdTable';
// Service imports:
import { convertToPercentage, getTableDate } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
import { CUSTOM_REFRESH_RATES } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdCrossSnapshot } from '../../types/MillibankaVextirTypes';

type Props = IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const Millibankavextir: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [rates] = useApiLmdData<IApiLmdCrossSnapshot[]>(
        `/v1/market_data/v1/keldan/rates/symbol/REIB*/currency/ISK/cross_snapshot`,
        accessToken,
        CUSTOM_REFRESH_RATES['MILLIBANKAVEXTIR']
    );

    return (
        <DisplayBox
            title="Millibankavextir"
            className="KCL_millibankavextir"
            displayBoxSize="sm"
            footerRight={
                <Label
                    text="Seðlabanki Íslands"
                    labelType="origin"
                    url='https://sedlabanki.is'
                />
            }
            {...props}
        >
            <LmdTable
                apiData={rates}
                columns={[{
                    title: 'Flokkur',
                    renderCell: ({Ibor}) => Ibor,
                    textAlign: 'left',
                    simpleSortable: ({Ibor}) => Ibor
                }, {
                    title: 'Vextir',
                    renderCell: ({Currency}) => Currency,
                    textAlign: 'left',
                    simpleSortable: ({Currency}) => Currency
                }, {
                    title: 'Dags.',
                    renderCell: ({Date}) => {
                        return getTableDate(Date, 'DD/MM/YYYY', '.')
                    },
                    simpleSortable: ({Date: ValueDate}) => ((ValueDate === null) ? 0 : new Date(ValueDate).getTime())
                }, {
                    title: 'ON %',
                    renderCell: ({ON}) => {
                        return convertToPercentage(ON, false, 4)
                    },
                    simpleSortable: ({ON}) => ON
                }, {
                    title: '1M %',
                    renderCell: ({'1M': oneM}) => {
                        return convertToPercentage(oneM, false, 4)
                    },
                    simpleSortable: ({'1M': oneM}) => oneM
                }, {
                    title: '3M %',
                    renderCell: ({'3M': threeM}) => {
                        return convertToPercentage(threeM, false, 4)
                    },
                    simpleSortable: ({'3M': threeM}) => threeM
                }, {
                    title: '6M %',
                    renderCell: ({'6M': sixM}) => {
                        return convertToPercentage(sixM, false, 4)
                    },
                    simpleSortable: ({'6M': sixM}) => sixM
                }, {
                    title: '1Y %',
                    renderCell: ({'1Y': oneY}) => {
                        return convertToPercentage(oneY, false, 4)
                    },
                    simpleSortable: ({'1Y': oneY}) => oneY
                }]}
            />
        </DisplayBox>
    );
}

export default Millibankavextir;