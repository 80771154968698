// Package imports:
import React, { useEffect, useState } from 'react';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Alert from '../../../ui-elements/Alert/Alert';
import Loading from '../../../ui-elements/Loading/Loading';
import VehiclesOwnedReportBody from '../../Okutaeki/SsnSearch/VehiclesOwnedReportBody';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { ICompanyReportModel } from '../../../types/CompanyTypes';
import { IVehicleReport } from '../../../types/VehicleTypes';
import { Fetched } from '../../../types/Types';
import { IKeldanApiResponse } from '../../../types/KeldanTypes';

const ViewVehiclesReport: React.FC<ICompanyReportModel>= ({
    id,
    eventId
}) => {
    const [ vehicleReport, setVehicleReport] = useState<Fetched<IVehicleReport>>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${GET_KELDAN_API_URL()}/Company/GetVehiclesReport/${id}?eventId=${eventId}`;
                const response = await fetch(url);
                if (!response.ok) {
                    setVehicleReport(new Error(ErrorMessages.RequestFailed));
                    return;
                }
                const responseBody: IKeldanApiResponse<IVehicleReport> = await response.json();
                if (responseBody.result) {
                    setVehicleReport(responseBody.result);
                } else if (responseBody.message) {
                    setVehicleReport(new Error(responseBody.message));
                } else {
                    setVehicleReport(new Error(ErrorMessages.OtherShort));
                }
            } catch (e) {
                setVehicleReport(new Error(ErrorMessages.NetworkError));
            }
        }
        fetchData();
    }, []);

    if (vehicleReport === null) {
        return <DisplayBox
            title='Ökutæki í eigu'
            className='KCL_ViewCompanyAssetsReport'
        >
            <Loading />
        </DisplayBox>
    }
    if (vehicleReport instanceof Error) {
        return <DisplayBox
            title='Ökutæki í eigu'
            className='KCL_ViewCompanyAssetsReport'
        >
            <Alert
                type={
                    (vehicleReport.message === ErrorMessages.RequestFailed || vehicleReport.message === ErrorMessages.NetworkError)
                    ? 'error'
                    : 'alert'
                }
                headText={vehicleReport.message}
            />
        </DisplayBox>
    }
    return <VehiclesOwnedReportBody
        id={id}
        eventId={eventId}
        vehicleReport={vehicleReport}
    />
}

export default ViewVehiclesReport;