    
// Package imports:
import React, { forwardRef, useEffect, useRef } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
// Service imports:
import { getSearchTermsFromSearch } from '../../../../services/utils';
// Type imports:
import { ICompanySearchResultsItem } from '../../../../types/SearchTypes';

interface IProps {
    searchData: ICompanySearchResultsItem[] | Error | undefined,
    isOpen: boolean,
    search: string,
    searchFieldInputRef: React.MutableRefObject<HTMLInputElement | null>,
    compareSearch?: boolean,
    setSelectedCompany?: (e: any) => void,
}

const CompanySearchResponse = forwardRef<HTMLDivElement, IProps>(({
    searchData,
    isOpen,
    search,
    searchFieldInputRef,
    compareSearch,
    setSelectedCompany
}, ref) => {
    // Refs for search data elements. Will help us with changing focus.
    const searchDataAnchorElementsRef = useRef<(HTMLAnchorElement | undefined)[]>([]);
    const searchDataButtonElementsRef = useRef<(HTMLButtonElement | undefined)[]>([]);

    // isOpen can't be read by function declared in hook. Use ref to read it.
    const isOpenRef = useRef(isOpen);
    useEffect(() => { isOpenRef.current = isOpen }, [isOpen]);

    // Document listeners for arrow key functionality.
    useEffect(() => {
        const onKeyPress = (e: KeyboardEvent) => {
            // Fetch refs.
            const isOpen = isOpenRef.current;
            let searchDataAnchorElements;
            if (compareSearch) {
                searchDataAnchorElements = searchDataButtonElementsRef.current;
            } else {
                searchDataAnchorElements = searchDataAnchorElementsRef.current;
            }
            // Should only work if the repsonse window is open.
            if (!isOpen) return;
            const currentFocusedElement = document.activeElement;
            const lastElementIndex = searchDataAnchorElements.length - 1;
            switch (e.key) {
                // Down arrow = go to next (should be like tab)
                case 'ArrowDown':
                    e.preventDefault();
                    // If focus is on search field input, move focus to 1st element in list.
                    if (currentFocusedElement === searchFieldInputRef.current) {
                        searchDataAnchorElements[0]?.focus();
                        return;
                    }
                    if (currentFocusedElement === searchDataAnchorElements[lastElementIndex]) {
                        searchFieldInputRef.current?.focus();
                        return;
                    }
                    const i = searchDataAnchorElements.findIndex(ref => ref === currentFocusedElement);
                    searchDataAnchorElements[i+1]?.focus();
                    break;
                // Up arrow = go to previous (should be like shift-tab)
                case 'ArrowUp':
                    e.preventDefault();
                    // If on search field, go to last element in list.
                    if (currentFocusedElement === searchFieldInputRef.current) {
                        searchDataAnchorElements[lastElementIndex]?.focus();
                        return;
                    }
                    // If on first element, focus on search field input.
                    if (currentFocusedElement === searchDataAnchorElements[0]) {
                        searchFieldInputRef.current?.focus();
                        return;
                    }
                    // If focus is on element in list, move to previous. 
                    const j = searchDataAnchorElements.findIndex(ref => ref === currentFocusedElement);
                    searchDataAnchorElements[j-1]?.focus();
                    break;
            }
        }
        document.addEventListener('keydown', onKeyPress);
        return () => document.removeEventListener('keydown', onKeyPress);
    }, []);

    const highlightText = (title: string) => {
        // The search terms we will search and replace in the original string.
        const searchTerms = getSearchTermsFromSearch(search);
        // Lowercase title to search without case. We will still use the normal title to return values.
        const lowercaseTitle = title.toLowerCase();
        // IMPORTANT: We hightlight using a starting and ending index method. This allows for full highlight even if some searchTerms are substrings of themselves.
        const startHighlightIndices: number[] = [];
        const endHighlightIndices: number[] = [];

        // Find the start and end highlight indices for every search term.
        for (let searchTerm of searchTerms) {
            // Constant helper variables.
            const lowercaseSearchTerm = searchTerm.toLowerCase();
            const searchTermLength = lowercaseSearchTerm.length;

            // The starting index from where to search.
            let startingIndex = 0;
            // The index of the found search term.
            let indexOfSearchTerm = lowercaseTitle.indexOf(lowercaseSearchTerm);
            // Until we no longer find search terms.
            while (indexOfSearchTerm !== -1) {
                startingIndex = (indexOfSearchTerm + searchTermLength);
                startHighlightIndices.push(indexOfSearchTerm);
                endHighlightIndices.push(startingIndex);
                indexOfSearchTerm = lowercaseTitle.indexOf(lowercaseSearchTerm, startingIndex);
            }
        }

        startHighlightIndices.sort((a, b) => a - b);
        endHighlightIndices.sort((a, b) => a - b);

        let titleWithMarks = '';
        let nextStartIndex = startHighlightIndices.shift();
        let nextEndIndex = endHighlightIndices.shift();
        for (let i = 0; i <= title.length; i++) {
            
            // Add end mark if endIndex present
            while (nextEndIndex === i) {
                titleWithMarks += '</mark>';
                nextEndIndex = endHighlightIndices.shift();
            }
            // Add start mark if startIndex present
            while (nextStartIndex === i) {
                titleWithMarks += '<mark>';
                nextStartIndex = startHighlightIndices.shift();
            }
            // Add the char (but not last since we go 1 index over string to check last endhighlight)
            if(i !== title.length) titleWithMarks += title[i];
        }

        return titleWithMarks;
    }

    return (
        <div
            className={cx('KCL_company-search-response', {'is-open': isOpen})}
            ref={ref}
        >
            <div
                className="search-response__scroll"
                tabIndex={-1}
            >
                {
                    searchData instanceof Error ? (
                        <div className="search-response__heading error">
                            <span className="error-msg">
                                <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    className="icon"
                                />
                                Ekki tókst að sækja gögn
                            </span>
                        </div>
                    ) : (
                        searchData?.map(({ssn, name}, searchResultIndex) => (
                            <div
                                key={ssn}
                                className="search-response__text"
                            >
                                {
                                    compareSearch
                                    ? <button
                                        onClick={() => {
                                            setSelectedCompany && setSelectedCompany(ssn);
                                        }}
                                        ref={ref => {
                                            searchDataButtonElementsRef.current[searchResultIndex] = (ref ?? undefined)
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: `
                                            ${highlightText(
                                                `${name} Kt. ${ssn}`
                                            )}`
                                        }}
                                    >
                                    </button>
                                    : <a
                                        href={`/Fyrirtaeki/Yfirlit/${ssn}`}
                                        ref={ref => {
                                            searchDataAnchorElementsRef.current[searchResultIndex] = (ref ?? undefined)
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: `
                                            ${highlightText(
                                                `${name} Kt. ${ssn}`
                                            )}`
                                        }}
                                    >
                                    </a>
                                }
                            </div>
                        ))
                    )
                }
            </div>
        </div>
    )
})

export default CompanySearchResponse
