// Package imports:
import React, { useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
// Component imports:
import Input from '../../../ui-elements/Input/Formik/Input';
import DropSelect from '../../../ui-elements/DropSelect/DropSelect';
import Button from '../../../ui-elements/Button/Button';
import Alert from '../../../ui-elements/Alert/Alert';
import Recaptcha from '../../../ui-elements/Recaptcha/Recaptcha';
// Service imports:
import useSendEmailHook from '../../../services/apiHooks';
// Type imports:
import { Fetched } from '../../../types/Types';

const REASON_SUBSCRIPTION = 'Ég vil koma í áskrift að Vaktaranum';
const REASON_QUESTION = 'Fyrirspurn';

interface IFormValues {
    name: string,
    email: string,
    subject: string,
    message: string,
}

const VaktarinnContactForm: React.FC = () => {
    const [captchaToken, setCaptchaToken] = useState<string | null>('');
    const [reason, setReason] = useState<string>(REASON_SUBSCRIPTION)

    const { loading, contactResponse, handleSubmit } = useSendEmailHook('Email/VaktarinnContact', captchaToken, {reason: reason});

    const subFormComponent = useMemo(() => {
        return ((props: { values: IFormValues }) => (
            <SubFormComponent
                contactResponse={contactResponse}
                values={props.values}
                setCaptchaToken={(e: string | null )=>setCaptchaToken(e)}
                loading={loading}
                reason={reason}
                setReason={(e: string) => setReason(e)}
            />
        ))
    }, [ reason, contactResponse, loading ]);
    return(
        <div className="KCL_notendur-form modal-form">
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    message: ''
                }}
                validationSchema={Yup.object({
                    name: Yup.string()
                        .required('Nafn má ekki vanta'),
                    email:  Yup.string()
                        .email('Ógilt netfang')
                        .required('Netfang verður að fylgja'),
                    message: Yup.string()
                        .required("Skilaboð má ekki vanta"),
                })}
                onSubmit={handleSubmit}
                component={subFormComponent}
            />
        </div>
    )
}

export default VaktarinnContactForm;

interface ISubFormProps {
    contactResponse: Fetched<string>,
    values: IFormValues,
    loading: boolean,
    setCaptchaToken: (e: string | null) => void,
    reason: string,
    setReason: (e: string) => void
}

const SubFormComponent: React.FC<ISubFormProps> = ({
    contactResponse,
    values,
    loading,
    setCaptchaToken,
    reason,
    setReason
}) => {
    return (
        <Form>
            <div className="form__body">
                <div className="form__row">
                    <div className="form__col">
                        <Input
                            label="Nafn"
                            name="name"
                            id="name"
                            value={values.name}
                        />
                    </div>
                    <div className="form__col">
                        <Input
                            label="Netfang"
                            name="email"
                            id="email"
                            value={values.email}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__col">
                        <DropSelect
                            options={[{
                                label: REASON_SUBSCRIPTION,
                                value: REASON_SUBSCRIPTION
                            }, {
                                label: REASON_QUESTION,
                                value: REASON_QUESTION
                            }]}
                            onChange={(e) => {
                                if (typeof e === 'string') setReason(e)
                            }}
                            defaultValue={reason}
                            size='lg'
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__col">
                        <Input
                            inputType="textArea"
                            label="Skilaboð"
                            name="message"
                            id="message"
                            value={values.message}
                        />
                    </div>
                </div>
                {(contactResponse === null)
                ? null
                : (
                    <div className="form__row">
                        <div className="form__col">
                            {(contactResponse instanceof Error)
                            ? <Alert type='error' headText={contactResponse.message} />
                            : <Alert type='info' headText={contactResponse} />}
                        </div>
                    </div>
                )}
            <Recaptcha position='left' setToken={(e: string | null) => setCaptchaToken(e)} />
            </div>
            <div className="form__actions">
                <Button type='submit' showLoader={loading} buttonType="primary" size="lg">Senda</Button>
            </div>
        </Form>
    );
}
