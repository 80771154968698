// Package imports:
import React, { useState } from 'react';
import { SwiperSlide } from 'swiper/react';
// Component imports:
import Button from '../../ui-elements/Button/Button';
import TextImageBlock from '../TextImageBlock/TextImageBlock';
import PriceCard from '../PriceCard/PriceCard';
import VaktarinnContactForm from '../ContactForms/VaktarinnContactForm/VaktarinnContactForm';
import Slider from '../Slider/Slider';
import FullScreenImage from '../../ui-elements/FullScreenImage/FullScreenImage';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';

const VAKTARINN_IMAGES_URLS = [
    "https://cdn.livemarketdata.com/KeldanImages/VaktarinnExample1.png",
    "https://cdn.livemarketdata.com/KeldanImages/VaktarinnExample2.png",
    "https://cdn.livemarketdata.com/KeldanImages/VaktarinnExample3.png",
    "https://cdn.livemarketdata.com/KeldanImages/VaktarinnExample4.png"
];

const VaktarinnPage: React.FC = () => {
    const [ fullScreenImageUrl, setFullScreenImageUrl ] = useState<string | null>(null);

    return (
        <div className='main KCL_vaktarinn-page'>
            {fullScreenImageUrl && (
                <FullScreenImage
                    imgUrl={fullScreenImageUrl}
                    imgAlt="Fullscreen Vaktarinn screenshot"
                    onExit={() => setFullScreenImageUrl(null)}
                />
            )}
            <div className="main__inner-fluid grid-items">
                <div className='shell'>
                    <HeadSection
                        title={
                            <object
                                data="https://cdn.livemarketdata.com/KeldanImages/VaktarinnLogo.svg"
                                aria-label="Vaktarinn"
                            >
                                Vaktarinn
                            </object>
                        }
                        summary={
                            <p>Vertu með puttann á púlsinum. Tilkynningar Vaktarans gera þér kleift að bregðast við umræðu um fyrirtækið þitt meðan hún á sér stað.</p>
                        }
                        actions={<>
                            <Button
                                buttonType="secondary"
                                size="lg"
                                anchorProps={{
                                    href: 'https://portal.vaktarinn.is/login',
                                    target: '_blank',
                                    rel: 'noopener noreferrer'
                                }}
                            >
                                Innskráning í Vaktarann
                            </Button>
                            <Button
                                buttonType="primary"
                                size="lg"
                                anchorProps={{href: '#askrift'}}
                            >
                                Áskrift að Vaktaranum
                            </Button>
                        </>}
                        textAlign='center'
                    />
                </div>
                <div className="section__body">
                    <div className="shell">
                        <TextImageBlock
                            imgSrc="https://cdn.livemarketdata.com/KeldanImages/Vaktarinn1.png"
                            imgSide="right"
                        >
                            <h2 id="Vaktarinn_Intensive">Öflug umræðugreining fyrir fyrirtæki</h2>
                            <div className="text-image-block__entry">
                                <p className='paragraph'>Vaktarinn er öflugt greiningartól sem býður upp á vöktun og greiningu á þeim leitarorðum sem notandi kýs að vakta.</p>
                                <p className='paragraph'>Vaktarinn fer yfir þúsundir greina á dag og rýnir innihald þeirra. Notandi hefur því góða yfirsýn yfir umræðuna, getur tekið saman umræðu fyrri ára og borið hana saman við umræðuna í dag og þannig öðlast víðtækan skilning á því hvað er verið að segja um vörumerkið, fyrirtækið eða stjórnendur þess.</p>
                            </div>
                        </TextImageBlock>
                        
                        <TextImageBlock
                            imgSrc="https://cdn.livemarketdata.com/KeldanImages/Vaktarinn2.png"
                            imgSide="left"
                        >
                            <h2 id="Vaktarinn_Easy">Auðvelt og þægilegt</h2>
                            <div className="text-image-block__entry">
                                <p className='paragraph'>Vaktarinn er aðgengilegur í gegnum vafra, en lögð er áhersla á að veita notendum auðvelt og þægilegt viðmót sem er jafnframt yfirgripsmikið og ítarlegt.</p>
                                <p className='paragraph'>Hægt er að setja upp margar leitir með mismunandi leitarskilyrðum og fá þannig góða yfirsýn yfir umræðuna, meta árangur markaðsherferða og vera meðvitaður um stöðu sína meðal viðskiptavina.</p>
                            </div>
                        </TextImageBlock>

                        <div className="text-image-block-list">
                            <TextImageBlock
                                imgSide="right"
                            >
                                <h3 id="Vaktarinn_Notifications">Tilkynningar Vaktarans</h3>
                                <div className="text-image-block__entry">
                                    <p className='paragraph'>Vaktarinn sendir út tilkynningu þegar hann grípur þau leitarorð sem valið var að leita eftir, og hægt er að fá tilkynningar í rauntíma, tvisvar á dag, daglega eða vikulega. </p>
                                    <p className='paragraph'>
                                        Tilkynningar Vaktarans er góð leið fyrir fyrirtæki til að koma í veg fyrir misskilning eða til að svara skaðlegri umræðu meðan hún er að gerast.
                                        Einnig er hægt að senda tilkynningar á alla notendur Vaktarans í þínu fyrirtæki sem eykur líkurnar á því að fyrr sé gripið í taumana og allir séu meðvitaðir um gang mála.
                                    </p>
                                </div>
                            </TextImageBlock>

                            <TextImageBlock>
                                <h3 id="Vaktarinn_Compare">Berðu þig saman við samkeppnina</h3>
                                <div className="text-image-block__entry">
                                    <p className='paragraph'>Fylgstu með því hvernig umræðan þróast og berðu þig saman við samkeppnina og markaðinn.</p>
                                    <p className='paragraph'>Það getur skipt sköpum að vita hvernig samkeppnisaðilar standa, hvernig umræðan þróast hjá þeim og hvað þitt fyrirtæki getur gert til að koma til móts við óánægða viðskiptavini samkeppnisaðila.</p>
                                </div>
                            </TextImageBlock>
                        </div>
                    </div>
                </div>
                <div className="section section--gray section--contact" id="askrift">
                    <div className="shell">
                        <div className="section__inner">
                            <div className="section__head">
                                <h2 id="Vaktarinn_Subscribe">Áskrift að Vaktaranum</h2>
                                <p className='summary-paragraph'>Hafðu samband til að koma í áskrift og byrjaðu að vakta umræðuna um þitt fyrirtæki. Einnig getur þú sent fyrirspurn hafir þú einhverjar spurningar.</p>

                                <VaktarinnContactForm />
                            </div>
                            <div className="section__body">
                                <PriceCard
                                    variant="overview"
                                    type="Áskrift Vaktarans"
                                >
                                    <h6>Mánaðargjald</h6>
                                    <div className="price-card__details">
                                        <dl>
                                            <dt>1-2 notendur</dt>
                                            <dd>19.950 kr.</dd>
                                        </dl>
                                        <dl>
                                            <dt>3-5 notendur</dt>
                                            <dd>39.950kr.</dd>
                                        </dl>
                                        <dl>
                                            <dt>6-10+ notendur</dt>
                                            <dd>Hafðu samband</dd>
                                        </dl>
                                        <p><em>Athugið, verðin eru án VSK.</em></p>
                                    </div>
                                </PriceCard>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="section__head">
                        <div className="shell">
                            <h2 id="Vaktarinn_Screenshots">Skjáskot úr Vaktaranum</h2>
                        </div>
                    </div>
                    <div className="section__body">
                        <div className="shell">
                            <Slider
                                className="slider-screenshots"
                                perView={3}
                                centerSlides={true}
                                loop={true}
                                breakpointOptions={{
                                    320: {
                                        slidesPerView: 1
                                    },
                                    768: {
                                        slidesPerView: 3
                                    }
                                }}
                                onInit={() => {}}
                                onSlideChange={() => {}}
                            >
                                {VAKTARINN_IMAGES_URLS.map((url) => (
                                    <SwiperSlide
                                        key={url}
                                        onClick={() => setFullScreenImageUrl(url)}
                                    >
                                        <img
                                            className="slide-image"
                                            src={url}
                                            alt="Vaktarinn skjáskot"
                                            width="1406"
                                            height="811"
                                        />
                                    </SwiperSlide>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VaktarinnPage;