// Package imports:
import React from 'react'

interface IProps {
    children: any,
    className?: string,
    id: string,
    label: string
};

const ScrollSpySection: React.FC<IProps> = ({
    children,
    className = '',
    id
}) => {
    return (
        <div className={`${className} scrollspy-section`} id={id}>
            {children}
        </div>
    )
}

export default ScrollSpySection
