// Package imports:
import React from 'react';
// Component imports:
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import KeldanAppDisclaimerComp from './KeldanAppDisclaimerComp/KeldanAppDisclaimerComp';

const KeldanAppDisclaimer: React.FC = () => {
    return (
        <div className="main KCL_disclaimer KCL_keldan-markadir-disclaimer">
            <div className='mini_shell'>
                <HeadSection
                    title="Keldan markaðir skilmálar"
                    summary={
                        <p>Notendur og áskrifendur að Keldan markaðir (Keldan App og/eða vefur) samþykkja eftirfarandi skilmála:</p>
                    }
                />
                <div className='section__body'>
                    <div>
                        <KeldanAppDisclaimerComp /> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KeldanAppDisclaimer;