// Package imports:
import React from 'react';
import { zonedTimeToUtc } from 'date-fns-tz';
import { format } from 'date-fns';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import DelayOrClosedLabel from '../DelayOrClosedLabel/DelayOrClosedLabel';
import LmdTable from '../../ui-elements/Table/LmdTable';
// Service imports:
import { formatNumber, getTableDate } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdTrade } from '../../types/HlutabrefTypes';

interface IOwnProps {
    symbol: string,
    limit?: number
}

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const LatestTrades: React.FC<Props> = ({
    symbol,
    limit = 5,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [data] = useApiLmdData<IApiLmdTrade[]>(
        `/v1/market_data/v1/tradables/${symbol}/latest_trades_delayed?limit=${limit}`,
        accessToken,
        refreshRateMs
    );

    return (
        <DisplayBox
            className='KCL_latest-trades'
            title="Nýjustu viðskipti"
            statusComponent={<DelayOrClosedLabel />}
            {...props}
        >
            <LmdTable
                apiData={data}
                columns={[{
                    title: 'Verð',
                    renderCell: ({price}) => formatNumber(price),
                    textAlign: 'left'
                }, {
                    title: 'Magn',
                    renderCell: ({volume}) => formatNumber(volume, null, 0),
                    textAlign: 'right'
                }, {
                    title: 'Tími',
                    renderCell: ({time_executed}) => {
                        const date = time_executed ? zonedTimeToUtc(time_executed.replace(/ /g, "T"), 'Europe/Stockholm') : undefined;
                        return getTableDate(date ? format(date, 'yyyy-MM-dd HH:mm:ss.SSS') : null, 'HH:MM:SS', '.');
                    }
                }]}
                tableSize='lg'
            />
        </DisplayBox>
    );
}

export default LatestTrades;