// Package imports:
import React from 'react';
// Component imports:
import Loading from '../../ui-elements/Loading/Loading';
import Alert from '../../ui-elements/Alert/Alert';
// Service imports:
import { convertToPercentage, formatNumber } from '../../services/utils';
import { useFetchedApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdTradables } from '../../types/HlutabrefTypes';
import { IApiLmdBond } from '../../types/SkuldabrefTypes';

interface IOwnProps {
    symbol: string
}

type Props = IOwnProps & IDefaultProps;

const TradablesInformationBonds: React.FC<Props> = ({
    symbol,
    accessToken
}) => {
    const data = useFetchedApiLmdData<IApiLmdTradables[]>(
        `/v1/static_data/v1/exchanges/*/markets/*/tradables/${symbol}`,
        accessToken
    )
    const staticDataBonds = useFetchedApiLmdData<IApiLmdBond[]>(
        `/v1/static_data/v1/exchanges/*/bonds/${symbol}`,
        accessToken
    )

    const displayData = () => {
        if (data === null || staticDataBonds === null) {
            return <Loading />;
        }
        if (data instanceof Error) {
            return <Alert type="error" headText={data.message} />;
        }
        if (staticDataBonds instanceof Error) {
            return <Alert type="error" headText={staticDataBonds.message} />;
        }
        return <>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Auðkenni</span>
                    <span className="value">{symbol}</span>
                </div>
                <div className="info-item">
                    <span className="title">ISIN</span>
                    <span className="value">{data[0]?.Isin}</span>
                </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Gjaldmiðill</span>
                    <span className="value">{data[0]?.TradingCurrency}</span>
                </div>
                <div className="info-item">
                    <span className="title">Markaðsauðkenni</span>
                    <span className="value">{data[0]?.MarketSymbol}</span>
                </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Útgáfudagur</span>
                    <span className="value">{staticDataBonds[0]?.DateOfIssue}</span>
                </div>
                <div className="info-item">
                    <span className="title">Gjalddagi</span>
                    <span className="value">{staticDataBonds[0]?.MaturityDate}</span>
                </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Vextir</span>
                    <span className="value">{convertToPercentage(staticDataBonds[0]?.CouponRate ? staticDataBonds[0].CouponRate/100 : null, true)}</span>
                </div>
                <div className="info-item">
                    <span className="title">Tegund</span>
                    <span className="value">{staticDataBonds[0]?.BondType}</span>
                </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Fyrsta vaxtagreiðsla</span>
                    <span className="value">{staticDataBonds[0]?.InterestFromDate}</span>
                </div>
                <div className="info-item">
                    <span className="title">Útistandandi upphæð</span>
                    <span className="value">{formatNumber(staticDataBonds[0]?.OutstandingAmount, '-', 0)} {data[0]?.TradingCurrency}</span>
                </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Dagaregla</span>
                    <span className="value">{staticDataBonds[0]?.DayCountMethod?.toUpperCase()}</span>
                </div>
            </div>
        </>
    }
    return (
        <div className='KCL_tradables-information tradables-information--bonds'>
            {displayData()}
        </div>
    );
}

export default TradablesInformationBonds;