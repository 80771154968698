// Package imports:
import React, { useMemo } from 'react';
import { SeriesOptionsType } from 'highcharts/highstock';
import ISLocale from 'date-fns/locale/is';
import { format } from 'date-fns';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import DefaultChart from '../DefaultChart/DefaultChart';
// Service imports:
import { formatNumber, getAPItoday } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IChartDataInfo, IDefaultProps } from '../../../types/Types';
import { IApiLmdIndexesChart } from '../../../types/MarketTypes';

type ChartType = 'index' | 'currency' | 'indices'; // Will add more types later...

interface IOwnProps {
    chartType?: ChartType,
    symbol?: string,
    title?: string,
    height?: number
};

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const IndicesChart: React.FC<Props> = ({
    chartType,
    title,
    height = 500,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const today = new Date();
    const from = new Date(today.getFullYear() - 5, today.getMonth() + 1, today.getDate());
    const [dataOMXI15] = useApiLmdData<IApiLmdIndexesChart[]>(
        `/v1/market_data/v1/indexes/symbol/OMXI15/snapshot/?from_date=${getAPItoday(from)}&to_date=${getAPItoday(today)}`,
        accessToken,
        refreshRateMs
    );
    const [dataOMXN40] = useApiLmdData<IApiLmdIndexesChart[]>(
        `/v1/market_data/v1/indexes/symbol/OMXN40/snapshot/?from_date=${getAPItoday(from)}&to_date=${getAPItoday(today)}`,
        accessToken,
        refreshRateMs
    );
    const [dataNOMXIBB] = useApiLmdData<IApiLmdIndexesChart[]>(
        `/v1/market_data/v1/indexes/symbol/NOMXIBB/snapshot/?from_date=${getAPItoday(from)}&to_date=${getAPItoday(today)}`,
        accessToken,
        refreshRateMs
    );

    const getOMXI15Data = () => {
        const chartDataInfoOMXI15: IChartDataInfo = {
            name: 'OMX Iceland 15',
            data: []
        }
        if (dataOMXI15.data !== null && !(dataOMXI15.error instanceof Error)) {
            for (let datum of dataOMXI15.data) {
                if (datum.trade_date === null) continue;
                chartDataInfoOMXI15.data.push({
                    x: Date.parse(datum.trade_date.replace(/ /g, "T")),
                    y: datum.current_value ?? undefined
                });
            }
        }
        return chartDataInfoOMXI15;
    }
    const getOMXN40Data = () => {
        const chartDataInfoOMXN40: IChartDataInfo = {
            name: 'OMX Nordic 40',
            data: []
        }
        if (dataOMXN40.data !== null && !(dataOMXN40.error instanceof Error)) {
            for (let datum of dataOMXN40.data) {
                if (datum.trade_date === null) continue;
                chartDataInfoOMXN40.data.push({
                    x: Date.parse(datum.trade_date.replace(/ /g, "T")),
                    y: datum.current_value ?? undefined
                });
            }
        }
        return chartDataInfoOMXN40;
    }
    const getNOMXIBBData = () => {
        const chartDataInfoNOMXIBB: IChartDataInfo = {
            name: 'NASDQ OMX Iceland Benchmark Bonds',
            data: []
        }
        if (dataNOMXIBB.data !== null && !(dataNOMXIBB.error instanceof Error)) {
            for (let datum of dataNOMXIBB.data) {
                if (datum.trade_date === null) continue;
                chartDataInfoNOMXIBB.data.push({
                    x: Date.parse(datum.trade_date.replace(/ /g, "T")),
                    y: datum.current_value ?? undefined
                });
            }
        }
        return chartDataInfoNOMXIBB;
    }
    
    const series: SeriesOptionsType[] = useMemo(() => {
        const chartDataInfoOMXI15 = getOMXI15Data();
        const chartDataInfoOMXN40 = getOMXN40Data();
        const chartDataInfoNOMXIBB = getNOMXIBBData();

        return [{
            type:'line',
            name: 'OMX Iceland 15',
            color: '#4569EE',
            data: chartDataInfoOMXI15.data
        }, {
            type: 'line',
            name: 'OMX Nordic 40',
            color: '#FF7697',
            data: chartDataInfoOMXN40.data
        }, {
            type: 'line',
            name: 'NASDQ OMX Iceland Benchmark Bonds',
            color: '#48A85D',
            data: chartDataInfoNOMXIBB.data,
        }]
    }, [ dataOMXI15, dataOMXN40, dataNOMXIBB]);

    return (
        <DisplayBox 
            className='KCL_chart'
            title={title}
            {...props}
        >
            <DefaultChart
                height={height}
                tooltipFormatter={
                    function() {
                        //filtera einungis það sem er visible af því ef það er notað points[0] crashar allt þegar eitthvað series er disable-að
                        return (`<div class="chart-tooltip">
                                    <p class="tooltip-date">${format(this.x, 'EEEE, dd. MMM yyyy', {locale: ISLocale})}</p>
                                    ${this.points?.filter(x => x.point.visible === true).map(x => (
                                        `<p><span style="color: ${x.color}">${x.series.getName()}</span>: ${formatNumber(x.y)}</p>`
                                    ))}
                                </div>`).replace(/>,</g,'><')
                        
                    }
                }
                yAxes={[{
                    title: {
                        text: 'Gildi',
                        offset: 52,
                        x: 5
                    },
                    labels: {
                        x: -11,
                        y: 2
                    }
                }]}
                series={series}
                startRange={6}
                seriesOptions={{ turboThreshold: 2000 }}
            />
        </DisplayBox>
    );
}

export default IndicesChart;