// Package imports:
import React from 'react';
// Component imports:
import FooterComp from '../../components/Footer/Footer';

const Footer: React.FC = () => {
    return (
        <FooterComp />
    );
}

export default Footer;