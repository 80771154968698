// Package imports:
import React, { useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react';
// Component imports:
import Loading from '../../ui-elements/Loading/Loading';
import Alert from '../../ui-elements/Alert/Alert';
import SubscriptionPricingTable from '../../ui-elements/SubscriptionPricingTable/SubscriptionPricingTable';
import Slider from '../Slider/Slider';
import PriceCard from '../PriceCard/PriceCard';
import Button from '../../ui-elements/Button/Button';
import Table from '../../ui-elements/Table/Table';
// Service imports:
import { GET_KELDAN_API_URL } from '../../services/config';
import { ErrorMessages } from '../../services/errorMessages';
import { getSubscriptionPrice } from '../../services/utils';
// Type imports:
import { IKeldanApiPricingViewModel } from '../../types/HomeTypes';
import { Fetched } from '../../types/Types';
// Static data imports:
import { pricingPageData } from '../../assets/staticData/pricingPlansTable';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';


const GagnaleitPricingSection: React.FC = () => {
    const [ data, setData ] = useState<Fetched<IKeldanApiPricingViewModel>>(null);

    const fetchData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Home/GetPriceList`;
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                const body = await response.json();
                setData(body);
            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            
            setData(new Error(ErrorMessages.NetworkError));
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const displayPricingTable = () => {
        if (data === null) return <Loading />;
        if (data instanceof Error) return <Alert type='error' headText={data.message} />;
        return <SubscriptionPricingTable groups={{products: {...data.products, "Tilkynningarskyldir aðilar": pricingPageData.pepAndDdPrices}}} />;
    }
    return (
        <div id='Gagnaleit-pricing' className='section mini_shell'>
            <div className='section__head'>
                <h2>Keldan gagnaleit - áskriftarleiðir</h2>
            </div>
            <div className="section__body">
                <div className="section__inner">
                    <div 
                        className="section__list"
                        data-aos="fade-in"
                        data-aos-delay="50"
                    >
                        <div className="subscription-plans">
                            <div className="subscription-plans__slider">
                                <Slider
                                    perView={3}
                                    centerSlides={true}
                                    loop={false}
                                    hideActions={true}
                                    initialSlide={1}
                                    breakpointOptions={{
                                        320: {
                                            slidesPerView: 1,
                                            noSwiping: false
                                        },
                                        768: {
                                            slidesPerView: 3,
                                            noSwiping: true
                                        }
                                    }}
                                    onSlideChange={() => {}}
                                    onInit={() => {}}
                                    className="price-cards__slider swiper-no-swiping"
                                >
                                    <SwiperSlide>
                                        <PriceCard
                                            variant="tertiary"
                                            type="Frítt"
                                            price="0"
                                            priceInfo="kr. á mánuði "
                                            key="Frítt"
                                        >
                                            <div className="price-card__info">
                                                <p className='paragraph-small'>Fyrir þá sem vilja skoða gögn og kaupa einstakar skýrslur.</p>
                                            </div>
                                            <div className="price-card__actions">
                                                <Button anchorProps={{href: "/Nyskraning"}}>
                                                    Stofna aðgang
                                                </Button>
                                            </div>
                                        </PriceCard>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <PriceCard
                                            variant="primary"
                                            type="Áskrift"
                                            price={getSubscriptionPrice()}
                                            priceInfo="kr. á mánuði + VSK"
                                            key="Áskrift"
                                            showRibbon
                                        >                            
                                            <div className="price-card__info">
                                                <p className='paragraph-small'>Fyrir þá sem vilja skoða gögn, vakta fyrirtæki og kaupa ódýrari skýrslur.<br/> Fyrir allt að 4 notendur.</p>
                                            </div>
                    
                                            <div className="price-card__actions">
                                                <Button
                                                    buttonType='buy'
                                                    anchorProps={{ href: `/Askrift` }}
                                                >
                                                    Kaupa áskrift
                                                </Button>
                                            </div>
                                        </PriceCard>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <PriceCard
                                            variant="secondary"
                                            type="Sérsamningur"
                                            priceText="Umsamið"
                                            key="Sérsamningur"
                                        >
                                            <div className="price-card__info">
                                                <p className='paragraph-small'>Fyrir kröfuharða notendur sem þurfa aðgang fyrir fimm eða fleiri í sínu fyrirtæki.</p>
                                            </div>
                                            <div className="price-card__actions">
                                                <Button anchorProps={{href: "/Hafa-Samband"}}>
                                                    Hafðu samband
                                                </Button>
                                            </div>
                                        </PriceCard>
                                    </SwiperSlide>
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div 
                        className="section__group"
                        data-aos="fade-in"
                        data-aos-delay="50"
                    >
                        <h3>Innifalið í áskrift</h3>
                        <Table 
                            columns={[{
                                title: '',
                                renderCell: ({label}) => label,
                                textAlign: 'left'
                            }, {
                                title: 'Frítt',
                                renderCell: ({forFreeUser}) => forFreeUser,
                                textAlign: 'center'
                            }, {
                                title: 'Áskrift',
                                renderCell: ({forSubscriber}) => forSubscriber,
                                textAlign: 'center'
                            }]}
                            data={pricingPageData.pricingPlansTable}
                            className="subscription-plans-table"
                            tableSize='lg'
                        />
                    </div>
                    <DisplayBox
                        displayBoxSize='xl'
                    >
                        <h2>Verðskrá gagna</h2>
                        {displayPricingTable()}
                            
                        <div className="section__footnote">
                            <p className='paragraph-small--italic'>
                                Allar skýrslur sem innskráður notandi kaupir eru aðgengilegar á síðunni Keyptar skýrslur án frekari kostnaðar.
                            </p>
                            <p className='paragraph-small--italic'>
                                Þar er einnig að finna nákvæmt yfirlit yfir allan kostnað vegna kaupa á skýrslum.
                            </p>
                        </div>
                    </DisplayBox>
                </div>
            </div>
        </div>
    );
}

export default GagnaleitPricingSection;