// Package imports:
import React from 'react';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Loading from '../../../ui-elements/Loading/Loading';
import Button from '../../../ui-elements/Button/Button';
import GEMMAQChart from '../../GEMMAQData/GEMMAQChart';
// Service imports:
import { getTableDate } from '../../../services/utils';
// Type imports:
import { IApiLmdGenderLensSnapshot } from '../../../types/HlutabrefTypes';

interface IOwnProps {
    data: IApiLmdGenderLensSnapshot[]
}

type Props = IOwnProps;

const GEMMAQData: React.FC<Props> = ({
    data
}) => {
    if (data.length === 0) {
        return null;
    }
    const d = data[0];
    return (
        <DisplayBox
            className='KCL_GEMMAQ-data'
            title={d.IssuerName ?? ''}
        >
            {
                d.IssuerName === null
                    ? <Loading />
                    : <>
                        <GEMMAQChart
                            data={data}
                        />
                        <div className="buttons">
                            {d.Exchange !== 'OTC'
                                ? <Button
                                    buttonType="secondary"
                                    size="sm"
                                    anchorProps={{href: `/Markadir/Hlutabref/${d.Symbol}`}}
                                >
                                    Skoða hlutabréf
                                </Button>
                                : null
                            }
                            {/* We don't have the info for this yet */}
                            {/* <Button buttonType="secondary" size="sm">Skoða fyrirtæki</Button> */}
                            <Button
                                buttonType="primary"
                                size="sm"
                                anchorProps={{href: '/Markadir/Gemmaq-Pontun'}}
                            >
                                Panta notkunarleyfi
                            </Button>
                        </div>
                        <p className="entry__previous-date">
                            <span className="entry">Einkunn gefin: {getTableDate(d.EntryDate, 'DD/MM/YY', '.')} </span>
                            <span className='previous'>Breyting frá: {getTableDate(d.PreviousGenderLensDate, 'DD/MM/YY', '.')} </span>
                        </p>
                    </>
            }
        </DisplayBox>
    );
}

export default GEMMAQData;