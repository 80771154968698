// Package imports:
import React from 'react';
// Component imports:
import SingleFundPage from '../../components/SingleFundPage/SingleFundPage';
import Entrypoint from '../Entrypoint';

interface IOwnProps {
    symbol?: string | null
}

const Funds: React.FC<IOwnProps> = ({
    symbol
}) => {
    return (
        <Entrypoint>
            <SingleFundPage symbol={symbol ?? 'akta_stokkur'} />
        </Entrypoint>
    );
}

export default Funds;