// Package imports:
import React, { useEffect, useState } from 'react';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Loading from '../../../ui-elements/Loading/Loading';
import Alert from '../../../ui-elements/Alert/Alert';
import ConstructionsOwnedReportBody from '../../Okutaeki/ConstructionsSearch/ConstructionsOwnedReportBody';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { ICompanyReportModel } from '../../../types/CompanyTypes';
import { IKeldanApiResponse } from '../../../types/KeldanTypes';
import { Fetched } from '../../../types/Types';
import { IConstructionsReport } from '../../../types/VehicleTypes';

const ViewConstructionsReport: React.FC<ICompanyReportModel>= ({
    id,
    eventId
}) => {
    const [ constructionReport, setConstructionReport] = useState<Fetched<IConstructionsReport>>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${GET_KELDAN_API_URL()}/Company/GetConstructionsReport/${id}?eventId=${eventId}`;
                const response = await fetch(url);
                if (!response.ok) {
                    setConstructionReport(new Error(ErrorMessages.RequestFailed));
                    return;
                }
                const responseBody: IKeldanApiResponse<IConstructionsReport> = await response.json();
                if (responseBody.success && responseBody.result) {
                    setConstructionReport(responseBody.result);
                } else if (responseBody.message) {
                    setConstructionReport(new Error(responseBody.message));
                } else {
                    setConstructionReport(new Error(ErrorMessages.OtherShort));
                }
            } catch (e) {
                setConstructionReport(new Error(ErrorMessages.NetworkError));
            }
        }
        fetchData();
    }, []);

    if (constructionReport === null) {
        return <DisplayBox>
            <Loading />
        </DisplayBox>
    }
    if (constructionReport instanceof Error) {
        return <DisplayBox>
            <Alert
                type={
                    (constructionReport.message === ErrorMessages.RequestFailed || constructionReport.message === ErrorMessages.NetworkError)
                    ? 'error'
                    : 'alert'
                }
                headText={constructionReport.message}
            />
        </DisplayBox>
    }
    return <ConstructionsOwnedReportBody
        id={id}
        eventId={eventId}
        constructionReport={constructionReport}
    />
}

export default ViewConstructionsReport;