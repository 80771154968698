// Package imports:
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import Cookies from 'universal-cookie';

const CookieConsentForm: React.FC = () => {
    //creating context
    const cookies = new Cookies();
    // if cookie is not found display component
    const [ visible, setVisible ] = useState(!cookies.get('_KeldanCookieConsent'));
    
    const handleClick = () => {
        let today = new Date();
        cookies.set('_KeldanCookieConsent', 1, {expires: new Date(today.setMonth(today.getMonth() + 6)), path: '/' });
        setVisible(false)
    }

    if (visible){
        return (
            <div className="KCL_cookieConsentForm">
                <div className='box'>
                    <div className='iconContainer'>
                        {/* Font awesome closing button */}
                        <FontAwesomeIcon onClick={handleClick} icon={faTimes} className="icon-fa" />
                    </div>
                    <div className='textContainer'>
                        <span>
                            Þessi vefsíða notar vefkökur til að bæta upplifun á vefnum. Með því að heimsækja Keldan.is samþykkir þú skilmála um vefkökur.
                        </span>
                    </div>
                    <div>
                        <a className='disclaimerLink' href='/Skilmalar'>Lesa skilmála Keldunnar</a>
                    </div>
                </div>
            </div>
        );
    }
    return <></>
}

export default CookieConsentForm;