// Package imports:
import React, { useState } from 'react'
import cx from 'classnames';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import ISLocale from 'date-fns/locale/is';
import USLocale from 'date-fns/locale/en-US';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons'

interface IProps {
    selectedDate: Date | null,
    setSelectedDate(date: Date): void,
    size?: 'sm' | 'lg',
    label?: string,
    lang?: 'is' | 'en',
    maxDate?: Date,
    id?: string
}

const Datepicker: React.FC<IProps> = ({
    selectedDate,
    setSelectedDate,
    label = 'Dagsetning ',
    size = 'lg',
    lang = 'en',
    maxDate,
    id = 'date-picker'
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const locale = (lang === 'is') ? ISLocale : USLocale;
    // Label starts inline, but once date or isopen changes it goes on top.
    const isLabelOnTop = (selectedDate !== null || isOpen);

    return (
        <div className={cx('KCL_datepicker', size, 'responsive-size', {
            'is-open': isOpen
        })}>
            <label
                htmlFor={id}
                className={cx(
                    "datepicker__label",
                    { 'is-on-top': isLabelOnTop }
                )}
            >
                {label}
            </label>
            <DatePicker
                id={id}
                className='datepicker__input'
                selected={selectedDate}
                open={isOpen}
                dateFormat="dd.MM.yyyy"
                locale={locale}
                onChange={(date) => (date instanceof Date) ? setSelectedDate(date) : null}
                selectsRange={false}
                onCalendarOpen={() => setIsOpen(true)}
                onCalendarClose={() => setIsOpen(false)}
                maxDate={maxDate}
                renderCustomHeader={({
                    monthDate,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => (
                    <div className="react-datepicker__header-inner">
                        <button 
                            aria-label={lang === 'is' ? 'Fyrri mánuður' : 'Previous Month'}
                            className="react-datepicker__navigation react-datepicker__navigation--previous"
                            onClick={decreaseMonth} 
                            disabled={prevMonthButtonDisabled}
                        >
                            <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.281 9.719a1.01 1.01 0 0 0 1.094.219C5.75 9.78 6 9.405 6 9V1c0-.375-.25-.75-.625-.906a1.01 1.01 0 0 0-1.094.218l-4 4A.97.97 0 0 0 0 5a.99.99 0 0 0 .281.719l4 4Z" fill="currentColor"/>
                            </svg>
                        </button>

                        <div className="react-datepicker__current-month">
                            {format(monthDate, 'MMMM y', {locale: locale})}
                        </div>
            
                        <button 
                            onClick={increaseMonth} 
                            disabled={nextMonthButtonDisabled}
                            aria-label={lang === 'is' ? 'Næsti mánuður' : 'Next Month'}
                            className="react-datepicker__navigation react-datepicker__navigation--next"
                        >
                            <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.688.313A1.01 1.01 0 0 0 .593.092.984.984 0 0 0 0 1v8c0 .406.219.781.594.938a1.01 1.01 0 0 0 1.094-.22l4-4C5.875 5.532 6 5.282 6 5c0-.25-.125-.5-.313-.688l-4-4Z" fill="currentColor"/>
                            </svg>
                        </button>
                    </div>
                )}
            />
            <FontAwesomeIcon
                icon={faCalendarDay}
                className="datepicker__icon"
            />
        </div>
    )
}

export default Datepicker
