import { ICompanySearchResultsItem } from "../../../types/SearchTypes";

export const companies: ICompanySearchResultsItem[] = [
  { ssn: "6204830369", name: "Marel hf." },
  { ssn: "6312051780", name: "Icelandair Group hf." },
  { ssn: "6702032120", name: "Hagar hf." },
  { ssn: "6904090460", name: "Eimskipafélag Íslands hf." },
  { ssn: "5702972609", name: "Norðurál Grundartangi ehf." },
  { ssn: "5203034210", name: "Alcoa Fjarðaál sf." },
  { ssn: "6804660179", name: "Rio Tinto á Íslandi hf." },
  { ssn: "5402062010", name: "Festi hf." },
  { ssn: "4910080160", name: "Íslandsbanki hf." },
  { ssn: "4710080280", name: "Landsbankinn hf." },
  { ssn: "5810080150", name: "Arion banki hf." },
  { ssn: "5602710189", name: "Össur hf." },
  { ssn: "4202691299", name: "Landsvirkjun" },
  { ssn: "5411850389", name: "Brim hf." },
  { ssn: "6110881329", name: "Iceland Seafood Internat. hf." },
  { ssn: "6102973079", name: "Samherji hf." },
  { ssn: "5002693249", name: "Olís ehf." },
  { ssn: "5512983029", name: "Orkuveita Reykjavíkur" },
  { ssn: "6407060310", name: "Actavis Group PTC ehf." },
  { ssn: "4110033370", name: "N1 ehf." },
  { ssn: "7112982239", name: "Krónan ehf." },
  { ssn: "6309212010", name: "Skeljungur ehf." },
  { ssn: "6801695009", name: "Kaupfélag Skagfirðinga" },
  { ssn: "6406750209", name: "Elkem Ísland ehf." },
  { ssn: "4911051270", name: "Eignarhaldsfélagið Hof ehf." },
  { ssn: "5702697479", name: "Síldarvinnslan hf." },
  { ssn: "6801691019", name: "Kaupfélag Suðurnesja ( svf. )" },
  { ssn: "4101694369", name: "Áfengis-/tóbaksverslun ríkisins" },
  { ssn: "5712983769", name: "Samkaup hf." },
  { ssn: "4602690599", name: "Auðhumla svf." },
  { ssn: "5405022930", name: "Kvika banki hf." },
  { ssn: "5502100370", name: "Isavia ohf." },
  { ssn: "4411081090", name: "Haru Holding ehf." },
  { ssn: "6711060670", name: "Össur Iceland ehf." },
  { ssn: "6302110500", name: "BL ehf." },
  { ssn: "6503871639", name: "Flugfélagið Atlanta ehf." },
  { ssn: "7103110640", name: "UK fjárfestingar ehf." },
  { ssn: "4409861539", name: "Samskip hf." },
  { ssn: "6704002390", name: "Norvik hf." },
  { ssn: "6803170460", name: "Air BP Iceland ehf." },
  { ssn: "7012770239", name: "Brimborg ehf." },
  { ssn: "4601693219", name: "Byko ehf." },
  { ssn: "6812160300", name: "Guðrúnarborg ehf." },
  { ssn: "5306023380", name: "Veritas ehf." },
  { ssn: "7002693299", name: "Vinnslustöðin hf." },
  { ssn: "5901693079", name: "Hampiðjan hf." },
  { ssn: "5012131870", name: "Veitur ohf." },
  { ssn: "6404062080", name: "Mókollur ehf." },
  { ssn: "6806044010", name: "Distica hf." },
  { ssn: "4203697789", name: "Ölgerðin Egill Skallagríms hf." },
  { ssn: "6509091270", name: "Sjóvá-Almennar tryggingar hf." },
  { ssn: "5512110290", name: "Húsasmiðjan ehf." },
  { ssn: "6803190730", name: "Orkan IS ehf." },
  { ssn: "4711972389", name: "Iceland Seafood ehf." },
  { ssn: "6906892009", name: "Vátryggingafélag Íslands hf." },
  { ssn: "4602070880", name: "Síminn hf." },
  { ssn: "5803100600", name: "Arnarlax ehf." },
  { ssn: "6601691219", name: "Ísfélag hf." },
  { ssn: "5808042410", name: "Landsnet hf." },
  { ssn: "4709051740", name: "Sýn hf." },
  { ssn: "4507042290", name: "Bílaumboðið Askja ehf." },
  { ssn: "7006140690", name: "Costco Wholesale Iceland ehf." },
  { ssn: "4109982629", name: "Útgerðarfélag Reykjavíkur hf." },
  { ssn: "4302141520", name: "Ístak hf." },
  { ssn: "6701695459", name: "Fagkaup ehf." },
  { ssn: "5302922079", name: "Origo hf." },
  { ssn: "6603190180", name: "Fly Play hf." },
  { ssn: "5301710279", name: "Toyota á Íslandi ehf." },
  { ssn: "5902691749", name: "Skel fjárfestingafélag hf." },
  { ssn: "4305071840", name: "Eignarhaldsfélagið Lyng ehf." },
  { ssn: "6706050780", name: "Controlant hf." },
  { ssn: "5804060680", name: "TK bílar ehf." },
  { ssn: "5804071100", name: "Lánasjóður sveitarfélaga ohf." },
  { ssn: "5202692669", name: "Rarik ohf." },
  { ssn: "6212002320", name: "Parlogis ehf." },
  { ssn: "5002090620", name: "Útgerðarfélag Akureyringa ehf." },
  { ssn: "4201080600", name: "Iceland Pelagic ehf." },
  { ssn: "6304023870", name: "Vistor ehf." },
  { ssn: "6001695139", name: "Hekla hf." },
  { ssn: "6602693399", name: "TM tryggingar hf." },
  { ssn: "4801692989", name: "Skinney-Þinganes hf." },
  { ssn: "5610003280", name: "Elko ehf." },
  { ssn: "4907922369", name: "Bláa Lónið Ísland hf." },
  { ssn: "5006830589", name: "Rapyd Europe hf." },
  { ssn: "6301694299", name: "Eskja hf." },
  { ssn: "4107861179", name: "Nesfiskur ehf." },
  { ssn: "6801697649", name: "Kaupfélag Fáskrúðsfirðinga svf" },
  { ssn: "5812012650", name: "Loðnuvinnslan hf. Fáskrúðsfirði" },
  { ssn: "4402695089", name: "Lýsi hf." },
  { ssn: "6202696119", name: "Icepharma hf." },
  { ssn: "4612891269", name: "FISK-Seafood ehf." },
  { ssn: "5212130190", name: "ON Power ohf." },
  { ssn: "5105061970", name: "ASI ehf." },
  { ssn: "4410993399", name: "Vörður tryggingar hf." },
  { ssn: "4712992359", name: "Icelandair Cargo ehf." },
  { ssn: "5811982569", name: "ÞG verktakar ehf." },
  { ssn: "4603170290", name: "Steinasalir ehf." },
  { ssn: "5412932809", name: "Miklatorg hf." },
  { ssn: "7112080700", name: "Reitir fasteignafélag hf." },
  { ssn: "4304032810", name: "H5 ehf." },
  { ssn: "6301692919", name: "Íslandshótel hf." },
  { ssn: "4710081840", name: "MarF ehf" },
  { ssn: "6511740239", name: "Höldur ehf." },
  { ssn: "5609210470", name: "Eyjaberg ehf." },
  { ssn: "6112002770", name: "Ó. Johnson & Kaaber - ÍSAM ehf." },
  { ssn: "6503871399", name: "Innnes ehf." },
  { ssn: "6607070420", name: "Steypustöðin ehf." },
  { ssn: "6912953549", name: "Íslensk erfðagreining ehf." },
  { ssn: "4203690429", name: "Þorbjörn hf." },
  { ssn: "6812711559", name: "Rammi hf." },
  { ssn: "5312050810", name: "Nova hf." },
  { ssn: "6209160560", name: "Nova klúbburinn hf." },
  { ssn: "6112042130", name: "Fríhöfnin ehf." },
  { ssn: "4701691419", name: "Coca-Cola Eur.Partn. Ísland ehf" },
  { ssn: "7011810779", name: "Vísir ehf." },
  { ssn: "6301091080", name: "Reginn hf." },
  { ssn: "7101130410", name: "Alvotech hf." },
  { ssn: "6205032660", name: "VSV Seafood Iceland ehf." },
  { ssn: "5902070120", name: "TDK Foil Iceland ehf." },
  { ssn: "7110140980", name: "Smyril Line Ísland ehf." },
  { ssn: "5310160250", name: "Eignarhaldsfélagið Kolka ehf." },
  { ssn: "4711190830", name: "Orka náttúrunnar ohf." },
  { ssn: "6110042570", name: "Arcus ehf." },
  { ssn: "6502992649", name: "Lyf og heilsa hf." },
  { ssn: "6202191260", name: "Tesla Motors Iceland ehf." },
  { ssn: "6510070620", name: "KERECIS hf." },
  { ssn: "5812891899", name: "Kjarnafæði Norðlenska hf." },
  { ssn: "4507110980", name: "Eignarhaldsfélagið Hornsteinn e" },
  { ssn: "6804750169", name: "HS Orka hf." },
  { ssn: "5912130160", name: "Orkuveita Reykjavíkur-vatns sf." },
  { ssn: "4201151050", name: "Bacco Seaproducts ehf." },
  { ssn: "4404191940", name: "Truenorth Nordic ehf." },
  { ssn: "5303901289", name: "Bananar ehf." },
  { ssn: "4903042580", name: "Northern Lights Leasing ehf." },
  { ssn: "5909023730", name: "Eik fasteignafélag hf." },
  { ssn: "5906023610", name: "Atlantsolía ehf" },
  { ssn: "6212976949", name: "BERJAYA HOTELS ICELAND hf" },
  { ssn: "4506973469", name: "CCP ehf." },
  { ssn: "5005013160", name: "Strætó bs." },
  { ssn: "4311200110", name: "StorMar ehf." },
  { ssn: "5308022640", name: "Danól ehf." },
];
