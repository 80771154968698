// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import ViewPropertiesReportComp from '../../components/Individual/IndividualAssets/ViewPropertiesReport';

const ViewPropertiesReport: React.FC<{ id: string }> = ({
    id
}) => {
    return (
        <Entrypoint>
            <ViewPropertiesReportComp id={id} />
        </Entrypoint>
    );
}

export default ViewPropertiesReport;