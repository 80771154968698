// Package imports:
import React, { useContext, useEffect, useState } from 'react';
//@ts-ignore
import Columns from 'react-columns';
// Component imports:
import FxMarketChart from '../Charts/FxMarketChart/FxMarketChart';
import Alert from '../../ui-elements/Alert/Alert';
import Link from '../../ui-elements/Link/Link';
import FxMarketTable from '../FxMarketTable/FxMarketTable';
import OpeningHours from '../OpeningHours/OpeningHours';
import MarketDepth from '../MarketDepth/MarketDepth';
import DetailsLatestTrades from '../DetailsLatestTrades/DetailsLatestTrades';
import Tooltip from '../../ui-elements/Tooltip/Tooltip';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import BulletList from '../../ui-elements/Lists/BulletList';
// Service imports:
import { DEFAULT_REFRESH_RATE, MARKET_REFRESH_RATES } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';
// Context imports:
import { AccessTokenContext } from '../../contexts/AccessTokenContext';

const GjaldeyrismarkadurPage: React.FC = () => {
    const accessToken = useContext(AccessTokenContext);
    
    const [ defaultProps, setDefaultProps ] = useState<IDefaultProps>({
        accessToken,
        refreshRateMs: DEFAULT_REFRESH_RATE
    });

    useEffect(() => {
        setDefaultProps({
            ...defaultProps,
            accessToken
        });
    }, [accessToken]);

    const queries = [{
            columns: 2,
            query: 'min-width: 1024px'
        }
    ];

    return (
        <div className='main KCL_gjaldeyrismarkadur-page KCL_market-page'>
            <div className="main__inner-fluid">
                <div className="shell">
                    <HeadSection 
                        title='Gjaldeyrismarkaður'
                        summary={
                            <p>
                                <span>Gjaldeyrismarkaður Keldunnar sýnir rauntímaupplýsingar og er opinn öllum í gegnum viðskiptabanka.</span>
                                <Tooltip
                                    text='Aðilar'
                                    tooltip={
                                        <span>Sjá útskýringu neðst á síðunni </span>
                                    }
                                />
                                <span>að markaðinum geta átt viðskipti með gjaldeyriskrossinn EUR/ISK í opinni pantanabók.</span>
                            </p>
                        }
                        actions={
                            <Link
                                url="mailto:fx@kvika.is"
                                linkSize='16'
                                icon='forward'
                            >
                                Hafa samband
                            </Link>
                        }
                        rightSide={<OpeningHours type='markadur' />}
                    />
                    <div>
                        <div className='grid-items'>
                            <div className='grid-item'>
                                <FxMarketTable
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['FXMARKETTABLE']}
                                    id="FxMarketPage_FxMarketTable"
                                />
                            </div>
                        </div>
                        <div className="main__cols grid-items">
                            <Columns className="custom-cols" queries={queries}>
                                <div className="grid-item">
                                    <MarketDepth
                                        crossCurrency='EURISK'
                                        accessToken={accessToken}
                                        refreshRateMs={MARKET_REFRESH_RATES['MARKETDEPTH']}
                                        id="FxMarketPage_MarketDepthEUR"
                                    />
                                </div>
                                <div className="grid-item">
                                    <FxMarketChart
                                        title='Velta á gjaldeyrismarkaði'
                                        accessToken={accessToken}
                                        refreshRateMs={MARKET_REFRESH_RATES['FXMARKETCHART']}
                                        id="FxMarketPage_Chart"
                                        className='chart--half-width'
                                    />
                                </div>
                                <div className="grid-item">
                                    <MarketDepth
                                        crossCurrency='USDISK'
                                        accessToken={accessToken}
                                        refreshRateMs={MARKET_REFRESH_RATES['MARKETDEPTH']}
                                        id="FxMarketPage_MarketDepthUSD"
                                    />
                                </div>
                                <div></div>
                                <div className="grid-item">
                                    <DetailsLatestTrades
                                        accessToken={accessToken}
                                        refreshRateMs={MARKET_REFRESH_RATES['LATESTTRADES']}
                                        id="FxMarketPage_LatestTrades"
                                    />
                                </div>        
                                <div></div>
                                <div className='grid-item' >
                                    <div id="FxMarketPage_Info" className="alert-information-section">
                                        <Alert 
                                            type='info' 
                                            headText='Athugið'
                                            text={
                                                <div>
                                                    <p className='paragraph-small'>
                                                        Pörunarvél (e. Matching engine) markaðarins er rekin af <Link url='https://www.kodi.is/' linkSize='16' targetBlank>Kóða ehf.</Link>
                                                    </p>
                                                    <p className='paragraph-small--bold'>Pantanagerðir:</p>
                                                    <BulletList
                                                        items={['Markaðspöntun (e. Market Order)', 'Pöntun með hámarki/lágmarki (e. Limit Order)']}
                                                        size='sm'
                                                    />
                                                    <p className='paragraph-small--bold'>Aðilar að Gjaldeyrismarkaði Keldunnar</p>
                                                    <BulletList
                                                        items={[
                                                            <Link
                                                                url='https://www.kvika.is/'
                                                                linkSize='16'
                                                                targetBlank
                                                                key="kvika"
                                                            >
                                                                Kvika banki hf.
                                                            </Link>,
                                                            'Íslenskir viðskiptabankar geta gerst aðilar að markaðnum.'
                                                        ]}
                                                        size='sm'
                                                    />
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>
                            </Columns>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GjaldeyrismarkadurPage;
