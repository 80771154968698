// Package imports:
import React, { useState } from 'react';
import { useField } from 'formik';
import cx from 'classnames';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEye, faEyeSlash, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

type InputType = 'input' | 'numberFormat' | 'textArea' | 'password';
type InputSizes = 'sm' | 'lg';
type TextAlign = 'left' | 'center' | 'right';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;
type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;
interface IOwnProps {
    inputType?: InputType,
    inputSize?: InputSizes,
    textAlign?: TextAlign,
    label?: string,
    shortLabel?: string,
    visible?: boolean,
    toggleVisible?(): void,
    withSearchGlassIcon?: boolean,
};

type Props = IOwnProps & NumberFormatProps & InputProps & TextAreaProps;

const Input: React.FC<Props> = ({
    // Custom props
    inputType = 'input',
    inputSize = 'lg',
    textAlign = 'left',
    label,
    shortLabel,
    visible,
    toggleVisible,
    withSearchGlassIcon,
    // Element props
    id,
    className,
    style,
    onFocus,
    onBlur,
    value,
    disabled,
    ...props
}) => {
    const [field, meta, helpers] = useField(props.name ?? '');
    const [isFocused, setIsFocused] = useState(false);
    const focus = () => setIsFocused(true);
    const blur = () => setIsFocused(false);

    // Props that overwrite native element props.
    const elementProps = {
        id,
        value,
        disabled,
        style: {
            ...style,
            textAlign: textAlign
        },
        onFocus: (e: React.FocusEvent<HTMLInputElement & HTMLTextAreaElement, Element>) => {
            if (onFocus) onFocus(e);
            focus();
        },
        onBlur: (e: React.FocusEvent<HTMLInputElement & HTMLTextAreaElement, Element>) => {
            if (onBlur) onBlur(e);
            blur();
        }
    }

    const getLabel = () => (
        label && (
            <label
                className="input__label"
                htmlFor={id}
            >
                <span className="main-label">{label}</span>
                <span className="short-label">{shortLabel ?? label}</span>
            </label>
        )
    )

    const getInput = () => {
        switch (inputType) {
            case 'numberFormat':
                return (
                    <>
                        <NumberFormat
                            {...elementProps}
                            {...(props as NumberFormatProps)}
                        />
                        {getLabel()}
                    </>
                );
            case 'input':
                return (
                    <>
                        <input
                            {...field}
                            {...elementProps}
                            {...(props as InputProps)}
                        />
                        {getLabel()}
                        {withSearchGlassIcon && !(meta.error && meta.touched) && <FontAwesomeIcon className='search-icon' icon={faSearch} />}
                        <FontAwesomeIcon icon={faCheck} className="icon-fa ok-icon" />
                        <button
                            type='button'
                            className='input__clear'
                            title='Hreinsa'
                            onClick={() => helpers.setValue('')}
                        >
                            <FontAwesomeIcon icon={faTimesCircle} className="icon-fa error-icon" />
                        </button>
                        {meta.error && meta.touched && <div className="input-error-message">{meta.error}</div>}
                    </>
                );
            case 'textArea':
                return (
                    <>
                        <textarea
                            {...field}
                            {...elementProps}
                            {...(props as TextAreaProps)}
                        />
                        {getLabel()}
                        {meta.error && meta.touched && <div className="input-error-message">{meta.error}</div>}
                    </>
                );
            default:
                return (
                    <>
                        <input
                            type={visible ? 'text' : 'password'}
                            {...field}
                            {...elementProps}
                            {...(props as InputProps)}
                        />
                        {getLabel()}
                        <FontAwesomeIcon icon={faCheck} className="icon-fa ok-icon" />
                        <button
                            type='button'
                            className='input__clear'
                            title='Hreinsa'
                            onClick={() => helpers.setValue('')}
                        >
                            <FontAwesomeIcon icon={faTimesCircle} className="icon-fa error-icon" />
                        </button>
                        <FontAwesomeIcon icon={faEyeSlash} className={cx("icon-fa", "eye-icon", {'visible': !visible})} onClick={toggleVisible} />
                        <FontAwesomeIcon icon={faEye} className={cx("icon-fa", "eye-icon", {'visible': visible})} onClick={toggleVisible} />
                        {meta.error && meta.touched && <div className="input-error-message">{meta.error}</div>}
                    </>
                );
        }
    }

    return (
        <div className={cx('KCL_input', inputSize, className, {
            'is-focused': isFocused,
            'has-value': (field.value !== undefined && field.value !== null && field.value.length > 0),
            'has-error': meta.error && meta.touched,
            'is-disabled': disabled
            })}
        >
            {getInput()}
        </div>
    );
}

export default (Input);