// Package imports:
import React from 'react';
// Component imports:
import PropertyReportBody from './PropertyReportBody';
// Type imports:
import { IEventReport } from "../../../types/KeldanTypes";
import { IPropertySearchResult, IPropertyDetail, IAddress } from "../../../types/PropertyTypes";
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';
// Static data imports:
import EXAMPLE_DATA from "../../../assets/staticData/ExampleReports/Property/PropertyReport.json";

const PropertyReportExample: React.FC = () => {
    const example = JSON.parse(JSON.stringify(EXAMPLE_DATA)) as IEventReport<IPropertySearchResult>;
    const property: (IPropertyDetail | null) = example.overview.property?.property?.property ? example.overview.property?.property?.property[0] : null;
    const address: (IAddress | null) = EXAMPLE_DATA.overview.property?.property?.address ?? null;
    return <>
        <HeadSection
            title={`${address?.display_address}, ${address?.city}`}
        />
        <div className='info-col-wrapper'>
            <div className='info-col'>
                <div className='info-item'>
                    <span className='title'>
                        Fastanúmer
                    </span>
                    <span className='value'>
                        {property?.property_number}
                    </span>
                </div>
            </div>
            <div className='info-col'>
                <div className='info-item'>
                    <span className='title'>
                        Merking
                    </span>
                    <span className='value'>
                        {property?.units[0].tag}
                    </span>
                </div>
            </div>
            <div className='info-col'>
                <div className='info-item'>
                    <span className='title'>
                        Heitisnúmer
                    </span>
                    <span className='value'>
                        {property?.name_number}
                    </span>
                </div>
            </div>
            <div className='info-col'>
                <div className='info-item'>
                    <span className='title'>
                        Skýring
                    </span>
                    <span className='value'>
                        {property?.units[0].description}
                    </span>
                </div>
            </div>
        </div>
        <PropertyReportBody report={example} isExample />
    </>
}

export default PropertyReportExample;