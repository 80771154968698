// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import CalendarComp from '../../components/Calendar/Calendar';

const Calendar: React.FC = () => {
    return (
        <Entrypoint>
            <CalendarComp />
        </Entrypoint>
    );
}

export default Calendar;