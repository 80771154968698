// Package imports:
import React from 'react';

export type CryptoLogoNames = 'ADA' | 'AVAX' | 'BTC' | 'DOT' | 'ITC' | 'MANA' | 'SAND' | 'TRX' | 'XPR' |'ATOM' | 'BNB' | 'DOGE' | 'ETH' | 'LINK' | 'MATIC' | 'SOL' | 'UNI' | 'XRP';

interface IProps {
    name: CryptoLogoNames,
};

const CryptoLogo: React.FC<IProps> = ({name}) => {
    return (
        <div className="KCL_crypto-logo">
            <img
                src={`https://cdn.livemarketdata.com/Avatars/CryptoLogos/${name}.svg`}
                alt={name}
                width="25"
                height="25"
            />
        </div>
    );
}

export default CryptoLogo;