// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import CompanySubPage from '../../components/Company/CompanySubPage/CompanySubPage';
import ViewPropertiesReportComp from '../../components/Company/ViewPropertiesReport/ViewPropertiesReport';
// Type imports:
import { ICompanyReportViewModel } from '../../types/CompanyTypes';

const ViewPropertiesReport: React.FC<ICompanyReportViewModel> = ({
    id,
    eventId,
    isAuthenticated,
    isSubscriber
}) => {
    return (
        <Entrypoint>
            <CompanySubPage 
                id={id} 
                title='Fasteignir'
                isAuthenticated={isAuthenticated}
                isSubscriber={isSubscriber}
                subTitle='Fasteignir - Skýrsla'
                noAd
                component={
                    <ViewPropertiesReportComp
                        id={id ?? ''}
                        eventId={eventId ?? ''}
                    />
                }
                showRecentReports
            />
        </Entrypoint>
    );
}

export default ViewPropertiesReport;