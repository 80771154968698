// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import ErrorPageComp from '../../components/ErrorPage/ErrorPage';

interface IProps {
    errorStatus?: number,
}

const ErrorPage: React.FC<IProps> = ({
    errorStatus
}) => {
    return (
        <Entrypoint>
            <ErrorPageComp errorStatus={errorStatus} />
        </Entrypoint>
    );
}

export default ErrorPage;