// Package imports:
import React, { useMemo } from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import DelayOrClosedLabel from '../DelayOrClosedLabel/DelayOrClosedLabel';
import Alert from '../../ui-elements/Alert/Alert';
import Loading from '../../ui-elements/Loading/Loading';
import TandemTable from '../../ui-elements/Table/TandemTable';
import CalculatedColoredNumber from '../../ui-elements/CalculatedColoredNumber/CalculatedColoredNumber';
import Link from '../../ui-elements/Link/Link';
// Service imports:
import { formatNumber, displayQuantityShorthand } from '../../services/utils';
// Type imports:
import { IApiLmdSnapshot } from '../../types/HlutabrefTypes';
import { IDefaultProps } from '../../types/Types';
import { useApiLmdData } from '../../services/apiHooks';

type OverviewType = 'shares' | 'bonds';

interface IOwnProps {
    overviewType?: OverviewType
}
type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const MarketOverview: React.FC<Props> = ({
    overviewType = 'shares',
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [overviewData] = useApiLmdData<IApiLmdSnapshot[]>(
        (overviewType === 'shares'
            ? `/v1/market_data/v1/keldan/exchanges/[XICE;FNIS]/equities/delayed_snapshot`
            : `/v1/market_data/v1/exchanges/XICE/markets/*/bonds/*/delayed_snapshot`
        ),
        accessToken,
        refreshRateMs
    );

    const noIncrease: IApiLmdSnapshot[] = [{symbol: 'Engin hækkun hefur orðið í dag', intraday_per_change: null, last_price: null, intraday_accumulated_turnover: null, intraday_no_of_trades: null, market_symbol: null, bid: null, ask: null, last_price_diff: null, previous_closing_price: null, intraday_accumulated_volume: null, trading_start: null, trading_close: null, last_yield: null}];
    const noDecrease: IApiLmdSnapshot[] = [{symbol: 'Engin lækkun hefur orðið í dag', intraday_per_change: null, last_price: null, intraday_accumulated_turnover: null, intraday_no_of_trades: null, market_symbol: null, bid: null, ask: null, last_price_diff: null, previous_closing_price: null, intraday_accumulated_volume: null, trading_start: null, trading_close: null, last_yield: null}];
    const noTurnover: IApiLmdSnapshot[] = [{symbol: 'Engin viðskipti hafa orðið í dag', intraday_accumulated_turnover: null, intraday_no_of_trades: null, intraday_per_change: null, last_price: null, market_symbol: null, bid: null, ask: null, last_price_diff: null, previous_closing_price: null, intraday_accumulated_volume: null, trading_start: null, trading_close: null, last_yield: null}];
    
    const { mostIncrease, anyIncreased } = useMemo(() => {
        const { data } = overviewData;
        if (data === null) return { mostIncrease: noIncrease, anyIncreased: false };
        const increased = data.filter((d) => d.intraday_per_change !== null && d.intraday_per_change > 0).sort((a, b) => {
            const aChange = a.intraday_per_change;
            const bChange = b.intraday_per_change;
            if (aChange === null && bChange === null) return 0;
            if (aChange === null) return 1;
            if (bChange === null) return -1;
            return bChange - aChange;
        });
        if (increased.length === 0) return { mostIncrease: noIncrease, anyIncreased: false };
        return { mostIncrease: increased.slice(0, 3), anyIncreased: true };
    }, [overviewData]);

    const { mostDecrease, anyDecreased } = useMemo(() => {
        const { data } = overviewData;
        if (data === null) return { mostDecrease: noDecrease, anyDecreased: false };
        const decreased = data.filter((d) => d.intraday_per_change !== null && d.intraday_per_change < 0).sort((a, b) => {
            const aChange = a.intraday_per_change;
            const bChange = b.intraday_per_change;
            if (aChange === null && bChange === null) return 0;
            if (aChange === null) return -1;
            if (bChange === null) return 1;
            return aChange - bChange;
        });
        if (decreased.length === 0) return { mostDecrease: noDecrease, anyDecreased: false };
        return { mostDecrease: decreased.slice(0, 3), anyDecreased: true };
    }, [overviewData]);

    const { mostTurnover, anyTurnover } = useMemo(() => {
        const { data } = overviewData;
        if (data === null) return { mostTurnover: noTurnover, anyTurnover: false };
        const turnover = data.filter((d) => d.intraday_accumulated_turnover !== null && d.intraday_accumulated_turnover > 0).sort((a, b) => {
            const aTurnover = a.intraday_accumulated_turnover;
            const bTurnover = b.intraday_accumulated_turnover;
            if (aTurnover === null && bTurnover === null) return 0;
            if (aTurnover === null) return 1;
            if (bTurnover === null) return -1;
            return bTurnover - aTurnover;
        });
        if (turnover.length === 0) return { mostTurnover: noTurnover, anyTurnover: false };
        return { mostTurnover: turnover.slice(0, 3), anyTurnover: true };
    }, [overviewData]);

    const totals = useMemo(() => {
        const { data } = overviewData;
        if (data === null) return [];
        let totalTurnover = 0;
        let totalTrades = 0;
        for (let d of data) {
            totalTurnover += d.intraday_accumulated_turnover ?? 0;
            totalTrades += d.intraday_no_of_trades ?? 0;
        }
        return [{
            symbol: '',
            intraday_accumulated_turnover: totalTurnover,
            intraday_no_of_trades: totalTrades,
            market_symbol: null, bid: null, ask: null, last_price: null, last_price_diff: null, previous_closing_price: null, intraday_accumulated_volume: null,intraday_per_change: null, trading_start: null, trading_close: null, last_yield: null
        }];
    }, [overviewData]);


    if (overviewData.data === null) {
        return (
            <DisplayBox
                title="Helstu tölur í dag"
                statusComponent={<DelayOrClosedLabel />}
                {...props}
            >
                <Loading />
            </DisplayBox>
        );
    }
    if (overviewData.error instanceof Error) {
        return (
            <DisplayBox
                title="Helstu tölur í dag"
                statusComponent={<DelayOrClosedLabel />}
                {...props}
            >
                <Alert type={'error'} headText={overviewData.error.message}/>
            </DisplayBox>
        )
    }

    
    return (
        <DisplayBox className='KCL_market-overview'
            title="Helstu tölur í dag"
            statusComponent={<DelayOrClosedLabel />}
            {...props}
        >
            <TandemTable
                data={[
                    mostIncrease,
                    mostDecrease,
                    mostTurnover,
                    totals
                ]}
                columns={[
                    anyIncreased
                        ? [{
                            title: 'Mesta hækkun',
                            renderCell: ({symbol}) => (
                                <Link url={`/Markadir/${(overviewType === 'shares') ? 'Hlutabref' : 'Skuldabref' }/${symbol}`} linkSize='15'>{symbol}</Link>
                            ),
                            textAlign: 'left'
                        }, {
                            title: 'Breyting',
                            renderCell: ({intraday_per_change}) => {
                                return (
                                    <CalculatedColoredNumber
                                        currentValue={intraday_per_change}
                                        formatting='percent'
                                        hasArrow
                                        hasColor
                                    />
                                )
                            },
                        }, {
                            title: 'Síðasta söluverð',
                            renderCell: ({last_price, last_yield}) => formatNumber(overviewType === 'shares' ? last_price : last_yield),
                            textAlign: 'right'
                        }]
                        : [{
                            title: 'Mesta hækkun',
                            renderCell: ({symbol}) => symbol,
                            textAlign: 'left'
                        }, {
                            title: 'Breyting',
                            renderCell: ({intraday_per_change}) => intraday_per_change,
                            textAlign: 'right'
                        }, {
                            title: 'Síðasta söluverð',
                            renderCell: ({last_price}) => last_price
                        }],
                    anyDecreased
                        ? [{
                            title: 'Mesta lækkun',
                            renderCell: ({symbol}) => (
                                <Link url={`/Markadir/${(overviewType === 'shares') ? 'Hlutabref' : 'Skuldabref' }/${symbol}`} linkSize='15'>{symbol}</Link>
                            ),
                            textAlign: 'left'
                        }, {
                            title: 'Breyting',
                            renderCell: ({intraday_per_change}) => {
                                return (
                                    <CalculatedColoredNumber
                                        currentValue={intraday_per_change}
                                        formatting='percent'
                                        hasArrow
                                        hasColor
                                    />
                                )
                            }
                        }, {
                            title: 'Síðasta söluverð',
                            renderCell: ({last_price, last_yield}) => formatNumber(overviewType === 'shares' ? last_price : last_yield),
                            textAlign: 'right'
                        }]
                        : [{
                            title: 'Mesta lækkun',
                            renderCell: ({symbol}) => symbol,
                            textAlign: 'left'
                        }, {
                            title: 'Breyting',
                            renderCell: ({intraday_per_change}) => intraday_per_change,
                            textAlign: 'right'
                        }, {
                            title: 'Síðasta söluverð',
                            renderCell: ({last_price}) => last_price
                        }],
                    anyTurnover
                        ? [{
                            title: 'Mesta velta',
                            renderCell: ({symbol}) => (
                                symbol !== 'Samtals'
                                ? <Link url={`/Markadir/${(overviewType === 'shares') ? 'Hlutabref' : 'Skuldabref' }/${symbol}`} linkSize='15'>{symbol}</Link>
                                : <strong>{symbol}</strong>
                            ),
                            textAlign: 'left'
                        }, {
                            title: 'Velta',
                            renderCell: ({intraday_accumulated_turnover}) => displayQuantityShorthand(intraday_accumulated_turnover),
                            textAlign: 'right'
                        }, {
                            title: 'Fjöldi viðskipta',
                            renderCell: ({intraday_no_of_trades}) => intraday_no_of_trades,
                            textAlign: 'right'
                        }]
                        : [{
                            title: 'Mesta velta',
                            renderCell: ({symbol}) => symbol,
                            textAlign: 'left'
                        }, {
                            title: 'Velta',
                            renderCell: ({intraday_accumulated_turnover}) => intraday_accumulated_turnover,
                            textAlign: 'right'
                        }, {
                            title: 'Fjöldi viðskipta',
                            renderCell: ({intraday_no_of_trades}) => intraday_no_of_trades
                        }],
                    [{
                        title: 'Heildarvelta',
                        renderCell: () => null,
                        textAlign: 'left'
                    }, {
                        title: 'Velta',
                        renderCell: ({intraday_accumulated_turnover}) => displayQuantityShorthand(intraday_accumulated_turnover)
                    }, {
                        title: 'Fjöldi',
                        renderCell: ({intraday_no_of_trades}) => intraday_no_of_trades
                    }]
                ]}
                tableSize="lg"
            />
        </DisplayBox>
    );
}

export default MarketOverview;