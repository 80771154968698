// Package imports:
import React from 'react';
// Component imports:
import GemmaqPublishingComponent from '../../components/GEMMAQPublishing/GEMMAQPublishing';

const GEMMAQPublish: React.FC = () => {
    return (
        <GemmaqPublishingComponent />
    );
}

export default GEMMAQPublish;