// Package imports:
import React, { useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";
// Component imports:
import Modal from '../../../ui-elements/Modal/Modal';
import Alert from "../../../ui-elements/Alert/Alert";
import Button from '../../../ui-elements/Button/Button';
import Checkbox from "../../../ui-elements/Input/Checkbox";
import Loading from "../../../ui-elements/Loading/Loading";
import Table from '../../../ui-elements/Table/Table';
import NotendurForm from './NotendurForm';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from "../../../services/errorMessages";
// Type imports:
import { KeldanUser, MyUsersViewModel } from "../../../types/MyKeldaTypes";
import { Fetched } from "../../../types/Types";

export interface IEditUser {
    name: string | null,
    email: string | null,
    category: string | null,
    active: boolean | null,
    bonds: boolean | null,
    id: string | null,
    username: string | null,
    ssn: string | null
};

type Props = IProps;

interface IProps {
    isAdmin?: boolean | null,
}

const Notendur: React.FC<Props> = ({
    isAdmin
}) => {
    const [activeUsers, setActiveUsers] = useState(false);
    const [newUserFormOpen, setNewUserFormOpen] = useState(false);
    const [editUserFormOpen, setEditUserFormOpen] = useState(false);
    const [data, setData] = useState<Fetched<KeldanUser[]>>(null);
    const [editUserData, setUserData] = useState<IEditUser>();

    const [contactResponse, setContactResponse] = useState<JSX.Element>(<></>);

    const fetchData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/MyKelda/UserList`;
            const response = await fetch(url);
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const body: MyUsersViewModel = await response.json();
                    setData(body.users);
                }
            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setData(new Error(ErrorMessages.NetworkError));
        }
    }
    useEffect(() => {
        fetchData();
    }, []);

    const openNewUserForm = () => {
        setNewUserFormOpen(true);
    };

    const openEditUserForm = (user: KeldanUser) => {
        const obj: IEditUser = {
            id: user.id?.toString() ?? null,
            name: user.fullname,
            email: user.email,
            category: user.category,
            active: user.active,
            bonds: user.bondsAllowed,
            username: user.username,
            ssn: user.ssn
        }
        setUserData(obj)
        setEditUserFormOpen(true)
    }
    const closeNewUserForm = () => { fetchData(); setNewUserFormOpen(false); };
    const closeEditUserForm = () => { fetchData(); setEditUserFormOpen(false); };

    const displayData = () => {
        if (data === null) {
            return <Loading />;
        }
        if (data instanceof Error) {
            return <Alert type='error' headText={data.message} />;
        }
        if (isAdmin) {
            //if admin -> column for changing user settings
            return <Table
                columns={[{
                    renderCell: ({ fullname }) => fullname,
                    textAlign: 'left',
                    title: 'Notandi',
                    simpleSortable: ({fullname}) => fullname,
                }, {
                    renderCell: ({ active }) => (active) ? 'virkur' : 'óvirkur',
                    textAlign: 'left',
                    title: 'Staða',
                }, {
                    renderCell: ({ email }) => email,
                    textAlign: 'left',
                    title: 'Netfang',
                    simpleSortable: ({ email }) => email,
                }, {
                    renderCell: ({ category }) => category,
                    textAlign: 'left',
                    title: 'Deild / svið',
                    simpleSortable: ({category}) => category,
                }, {  
                    renderCell: (user) => (
                        <Button
                            buttonType="secondary"
                            size="sm"
                            onClick={() => openEditUserForm(user)}
                        >
                            Breyta
                        </Button>
                    ),
                    textAlign: 'right',
                    title: ''
                }]}
                data={(activeUsers) ? data.filter(x => x.active) : data} 
                tableSize='lg'
            />
        }
        return <Table
            columns={[{
                renderCell: ({ fullname }) => fullname,
                textAlign: 'left',
                title: 'Notandi',
                simpleSortable: ({fullname}) => fullname,
            }, {
                renderCell: ({ active }) => (active) ? 'virkur' : 'óvirkur' ,
                textAlign: 'left',
                title: 'Staða',
            }, {
                renderCell: ({ email }) => email,
                textAlign: 'left',
                title: 'Netfang',
                simpleSortable: ({email}) => email,
            }, {
                renderCell: ({ category }) => category,
                textAlign: 'left',
                title: 'Deild / svið',
                simpleSortable: ({category}) => category,
            }]}
            data={(activeUsers) ? data.filter(x => x.active) : data} 
            tableSize='lg'
        />
    }

    return (
        <div className="notendur">
            <div>
                {contactResponse && (
                    <div className="form__row">
                        <div className="form__col">
                            {contactResponse}
                        </div>
                    </div>
                )}
            </div>
            <div className="section__body">
                <div className="sub__heading">
                    <h3>Notendur</h3>

                    {(isAdmin)
                        ? <Button buttonType="primary" size="lg" onClick={openNewUserForm}>Stofna nýjan notanda</Button>
                        //add cursor none!
                        : <Button style={{cursor: 'default'}} disabled buttonType="primary" size="lg">Stofna nýjan notanda</Button>
                    }
                </div>
                <div className="sub__body">
                    {isAdmin &&
                        <Checkbox 
                            label="Sýna bara virka notendur"
                            name="activeUsers"
                            id="activeUsers"
                            value={activeUsers.toString()}
                            onChange={(e) => setActiveUsers(e.target.checked)}
                        />
                    }
                    {displayData()}
                    {/* NEW USER MODAL */}
                    <Modal
                        show={newUserFormOpen}
                        onHide={closeNewUserForm}
                        hasCloseButton
                        variant="shaded-inside"
                        body={
                            <div className="form">
                                <NotendurForm setContactResponse={setContactResponse} type='new' closeModal={closeNewUserForm} />
                            </div>
                        }
                    />

                    {/* EDIT USER MODAL */}
                    <Modal
                        show={editUserFormOpen}
                        onHide={closeEditUserForm}
                        hasCloseButton
                        variant="shaded-inside"
                        body={
                            <div className="form">
                                <NotendurForm setContactResponse={setContactResponse} type="edit" editUserObject={editUserData} closeModal={closeEditUserForm} />
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default Notendur;