// Package imports:
import React from 'react';
// Component imporst:
import ReportsSubPage from '../../components/ReportsPage/ReportsSubPage/ReportsSubPage';
import Entrypoint from '../Entrypoint';
// Type imports:
import { MyReportsSearchViewModel } from '../../types/MyKeldaTypes';


const ReportsSub: React.FC = ({
    ...props
}) => {
    return (
        <Entrypoint>
            <ReportsSubPage model={props as MyReportsSearchViewModel}/>
        </Entrypoint >

    );
}

export default ReportsSub;