// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import BondPageComp from '../../components/SingleBondPage/SingleBondPage';

interface IOwnProps {
    symbol?: string | null
}

const Bond: React.FC<IOwnProps> = ({
    symbol
}) => {
    return (
        <Entrypoint>
            <BondPageComp symbol={symbol ?? 'ARION CBI 26'} />
        </Entrypoint>
    );
}

export default Bond;