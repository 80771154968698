// Package imports:
import React, { useState } from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../ui-elements/Link/Link';
import Avatar, { AvatarNames } from '../../ui-elements/Avatar/Avatar';
import Filter from '../../ui-elements/Filter/Filter';
import DatePicker from '../../ui-elements/DatePicker/DatePicker';
import Fyrirvari from '../Fyrirvari/Fyrirvari';
import LmdTable, { AlignOptions } from '../../ui-elements/Table/LmdTable';
// Service imports:
import { changeIssuerName, convertToPercentage, displayQuantityShorthand, formatNumber, getAPItoday, getTableDate } from '../../services/utils';
import { useApiLmdDataMappedByString } from '../../services/apiHooks';
import { CUSTOM_REFRESH_RATES } from '../../services/config';
// Type imports:
import { IDefaultProps, SettingsTypeFunds } from '../../types/Types';
import { IApiLmdFundPricePublic } from '../../types/FundsTypes';


type IProps = IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

type SettingsBooleanMap = {
    [key in SettingsTypeFunds]: boolean
}

type FundKey = keyof IApiLmdFundPricePublic;

const getColumnInfoWithPercentage = (key: SettingsTypeFunds, fundKey: FundKey): IColumnInfo => ({
    renderCell: (fund) => {
        const value = fund[fundKey];
        return (typeof value === 'number') ? convertToPercentage(value, true) : value;
    },
    textAlign: 'right',
    simpleSortable: (fund) => fund[fundKey]
})

interface IColumnInfo {
    renderCell: (fund: IApiLmdFundPricePublic) => any,
    textAlign?: AlignOptions,
    overrideTd?: boolean,
    sortable?: (a: IApiLmdFundPricePublic, b: IApiLmdFundPricePublic) => number,
    simpleSortable?: (a: IApiLmdFundPricePublic) => number | string | null,
    overrideTh_class?: string
}

const SETTING_TO_VALUE_MAP: {
    [T in SettingsTypeFunds]: IColumnInfo
} = {
    'Félag': {
        renderCell: ( {issuer_name, issuer_url} ) => (
            <a
                className="logo-wrapper"
                href={issuer_url ?? '#'}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={issuer_name ?? ''}
            >
                <Avatar
                    name={changeIssuerName(issuer_name) as AvatarNames} 
                    size='sm' 
                    color
                    backgroundGrey
                />
            </a>
        ),
        textAlign: 'left',
        overrideTh_class: 'fund-logo-column'
    },
    'Mynt': {
        renderCell: ({ currency }) => (
            currency
        ),
        textAlign: 'left',
        overrideTh_class: 'fund-currency-column'
    },
    'Sjóður': {
        renderCell: ({ name, symbol }) => (
            <Link url={`/Markadir/Sjodir/${symbol}` ?? '#'} linkSize='13' title={name ?? ''}>{name}</Link>
        ),
        textAlign: 'left',
        simpleSortable: ({name}) => name,
        overrideTh_class: 'fund-name-column'
    },
    'Teg.': {
        renderCell: ({ fund_type}) => fund_type,
        overrideTh_class: 'fund-type-column'
    },
    'Dags.': {
        renderCell: ({value_date}) => getTableDate(value_date, 'DD/MM', '.'),
        simpleSortable: ({value_date}) => ((value_date === null) ? 0 : new Date(value_date).getTime())
    },
    'Stærð': {
        renderCell: ({ size }) => displayQuantityShorthand(size),
        simpleSortable: ({size}) => size
    },
    '1M': getColumnInfoWithPercentage('1M', 'change1m'),
    '2M': getColumnInfoWithPercentage('2M', 'change2m'),
    '3M': getColumnInfoWithPercentage('3M', 'change3m'),
    '6M': getColumnInfoWithPercentage('6M', 'change6m'),
    '9M': getColumnInfoWithPercentage('9M', 'change9m'),
    '12M': getColumnInfoWithPercentage('12M', 'change12m'),
    '2 ár': getColumnInfoWithPercentage('2 ár', 'change24m'),
    '3 ár': getColumnInfoWithPercentage('3 ár', 'change36m'),
    '4 ár': getColumnInfoWithPercentage('4 ár', 'change48m'),
    '5 ár': getColumnInfoWithPercentage('5 ár', 'change60m'),
    'F.áram.': getColumnInfoWithPercentage('F.áram.', 'changeytd'),
    'F.stofn.': getColumnInfoWithPercentage('F.stofn.', 'changeitd'),
    'Gildi': {
        renderCell: (fund: IApiLmdFundPricePublic) => formatNumber(fund.last_price),
        textAlign: 'right',
        simpleSortable: ({last_price}) => last_price
    },
    'Flokkur': {
        renderCell: (fund: IApiLmdFundPricePublic) => fund.group_name,
        textAlign: 'left',
        simpleSortable: ({group_name}) => group_name
    },
    'Röð': {
        renderCell: (fund: IApiLmdFundPricePublic) => fund.group_order,
        textAlign: 'right',
        simpleSortable: ({group_order}) => group_order
    }
}

const getInitialSettingsState = (): SettingsBooleanMap => ({
    'Félag': true,
    'Mynt': true,
    'Sjóður': true,
    'Teg.': true,
    'Dags.': true,
    'Stærð': true,
    '1M': false,
    '2M': false,
    '3M': false,
    '6M': false,
    '9M': false,
    '12M': true,
    '2 ár': false,
    '3 ár': false,
    '4 ár': false,
    '5 ár': false,
    'F.áram.': false,
    'F.stofn.': false,
    'Flokkur': false,
    'Röð': false,
    'Gildi': false
});

const Sjodir: React.FC<IProps>= ({
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [ currentDate, setCurrentDate, fundsForDate ] = useApiLmdDataMappedByString<IApiLmdFundPricePublic[]>(
        getAPItoday(),
        (date: string) => `/v1/market_data/v1/funds/*/prices/public?value_date=${date}`,
        accessToken,
        CUSTOM_REFRESH_RATES['SJODIR']
    );

    const [ settings, setSettings ] = useState(getInitialSettingsState());
    const [ disclaimerOpen, setDisclaimerOpen ] = useState(false);
    const openDisclaimer = () => setDisclaimerOpen(true);
    const closeDisclaimer = () => setDisclaimerOpen(false);

    function changeSettings(header: SettingsTypeFunds){
        setSettings({
            ...settings,
            [header]: !settings[header]
        })
    }

    const settingsValues = Object.entries(settings).map(([settingKey, settingValue]) => ({
        text: settingKey,
        selected: settingValue,
        toggleSelected: () => changeSettings(settingKey as SettingsTypeFunds),
        disabled: settingKey === 'Sjóður'
    }))


    const columnsBasedOnSettings = Object.entries(settings).filter(([key, value]) => value).map(([key]) => ({
        title: key,
        ...(SETTING_TO_VALUE_MAP[key as SettingsTypeFunds])
    }));

    const handleData = () => {
        const { data, error } = fundsForDate;
        if (data === null) return fundsForDate;

        const filterData = (groupname: string) => {
            return data.filter(x => x.group_key === groupname && x.fund_type !== 'S');
        }
        const sortFundPrice = (a: IApiLmdFundPricePublic, b: IApiLmdFundPricePublic) => {
            if (a.change12m === null && b.change12m === null) return 0;
            else if (a.change12m === null) return 1;
            else if (b.change12m === null) return -1;
            else return b.change12m - a.change12m;
        }

        return {
            data: {
                "Mesta ávöxtun síðustu 12 mánuði á rekstrarfélag": filterData('top_12m').sort(sortFundPrice),
                "Skammtímasjóðir": filterData('short_term').sort(sortFundPrice),
                "Íslensk skuldabréf - stutt": filterData('is_bonds_short').sort(sortFundPrice),
                "Íslensk skuldabréf - meðallöng": filterData('is_bonds_medium').sort(sortFundPrice),
                "Íslensk skuldabréf - löng": filterData('is_bonds_long').sort(sortFundPrice),
                "Íslensk hlutabréf": filterData('is_equity').sort(sortFundPrice),
                "Erlend hlutabréf": filterData('foreign_equity').sort(sortFundPrice),
                "Erlend hlutabréf - ISK": filterData('foreign_equity_isk').sort(sortFundPrice),
                "Erlend skuldabréf": filterData('foreign_bonds').sort(sortFundPrice),
                "Blandaðir sjóðir": filterData('mixed').sort(sortFundPrice)
            },
            error
        }
    }

    return (
        <DisplayBox
            className="KCL_sjodir"
            title="Sjóðir"
            anchorProps={{ href: '/Markadir/Sjodir' }}
            asideComponent={
                <div className="funds-datepicker">
                    <DatePicker maxDate={new Date()} size="sm" selectedDate={new Date(currentDate)} setSelectedDate={(newDate) => setCurrentDate(getAPItoday(newDate))}> </DatePicker>
                    <Filter itemStyle={{size: 'lg', showCheck: true }} itemValues={settingsValues} columnStyle='grid' heading='' /> 
                </div>
            }
            displayBoxSize="sm"
            footerRight={
                <Fyrirvari
                    show={disclaimerOpen}
                    open={openDisclaimer}
                    close={closeDisclaimer}
                >
                    <p className='paragraph'>Upplýsingar um þá verðbréfa- (auðkenndir með bókstafnum „V“ fyrir aftan nafn sjóðs) og sérhæfða sjóði fyrir almenna fjárfesta (auðkenndir með bókstafnum „A“ fyrir aftan nafn sjóðs) sem birtar eru á vef Keldunnar byggja á upplýsingum frá rekstrarfélögum viðkomandi sjóða og eru settar fram á ábyrgð þeirra. Jafnframt skal tekið fram að þær upplýsingar sem birtast á vefnum geta breyst án fyrirvara og er áskilinn réttur til leiðréttinga.</p>
                    <p className='paragraph'>Upplýsingar sem birtar eru á vef Keldunnar fela á engan hátt í sér ráðleggingar um tilteknar fjárfestingar, sem sagt kaup eða sölu tiltekinna fjármálagerninga, eða ráðleggingar um að ráðast ekki í tilteknar fjárfestingar. Notendur vefsins bera því einir ábyrgð á þeim fjárfestingaákvörðunum sem þeir taka með hliðsjón af upplýsingum sem birtar eru á vefnum. Er notendum bent á að leita ráðleggingar áður en þeir taka ákvörðun varðandi mögulega fjárfestingu.</p>
                    <p className='paragraph'>Ávöxtunartölur miðast við nafnávöxtun sjóða og er ávöxtun umfram 12 mánuði umreiknuð í ávöxtun á ársgrundvelli, en styttri tímabil eru ekki umreiknuð. Varðandi myndræna framsetningu skal tekið fram að stofndagur sjóða er mismunandi og því ekki hægt að bera ávöxtun sjóða saman lengra aftur í tíma en sem nemur stofndegi þess sjóðs sem er yngstur.</p>
                    <p className='paragraph'>Rétt er að benda á að verðbreytingar í fortíð gefa ekki endilega vísbendingu um verðbreytingar í framtíð. Að auki eru sjóðirnir mismunandi að uppbyggingu og því getur áhætta verið mismunandi. Nánari upplýsingar um fjárfestingar sjóðanna er að finna á vefsíðu viðkomandi rekstrarfélaga. Útboðslýsingar eru aðgengilegar á heimasíðum rekstrarfélaganna og hjá söluaðilum. Þeir sem hyggja á fjárfestingar eru hvattir til að kynna sér útboðslýsingar sjóða, sérstaklega umfjöllun um áhættur.</p>
                </Fyrirvari>
            }
            {...props}
        >
            <LmdTable
                id={props.id}
                apiData={handleData()}
                columns={columnsBasedOnSettings}
                expandableRowConfig={{
                    defaultExpandedIndexes: [0],
                    showNumberOfItemsInExpandable: true
                }}
            />
        </DisplayBox>
    );
}

export default Sjodir;