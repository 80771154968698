// Package imports:
import React, { useContext, useEffect, useState } from 'react';
// Component imports:
import AdRotator from '../Ad/AdRotator';
import BondsTable from '../BondsTable/BondsTable';
import BondsChart from '../Charts/BondsChart/BondsChart';
import LatestTrades from '../LatestTrades/LatestTrades';
import TradablesChanges from '../TradablesChanges/TradablesChanges';
import TradablesInformationBonds from '../TradablesInformation/TradablesInformationBonds';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
// Service imports:
import { DEFAULT_REFRESH_RATE, MARKET_REFRESH_RATES } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';
// Context imports:
import { AccessTokenContext } from '../../contexts/AccessTokenContext';

interface IProps {
    symbol: string
}

const SingleBondPage: React.FC<IProps> = ({
    symbol
}) => {
    const accessToken = useContext(AccessTokenContext);
    const [ defaultProps, setDefaultProps ] = useState<IDefaultProps>({
        accessToken,
        refreshRateMs: DEFAULT_REFRESH_RATE
    });

    useEffect(() => {
        setDefaultProps({
            ...defaultProps,
            accessToken
        });
    }, [accessToken]);

    return (
        <div className='main KCL_single-bond-page KCL_market-page'>
            <div className="main__inner-fluid">
                <div className="shell">
                    <HeadSection 
                        hasAds='market'
                        title={symbol}
                    />
                    <div>
                        <div className="grid-items">
                            <div className="grid-item">
                                <BondsTable
                                    symbol={symbol}
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['BONDSTABLE']}
                                    id="SingleBondPage_BondsTable"
                                />
                            </div>
                        </div>
                        <div className='grid-items'>
                            <div className="grid-item">
                                <BondsChart
                                    symbol={symbol}
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['BONDSCHART']}
                                    id="SingleBondPage_Chart"
                                />
                            </div>
                        </div>
                        <AdRotator location="Market1018x360" />
                        <div className="grid-items grid-items--flex">
                            <div className="grid-item grid-item--1of3 grid-item--table-full">
                                <LatestTrades
                                    symbol={symbol}
                                    limit={5}
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['LATESTTRADES']}
                                    id="SingleBondPage_LatestTrades"
                                />
                            </div>
                            <div className="grid-item grid-item--1of3 grid-item--table-full">
                                <TradablesChanges
                                    symbol={symbol}
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['TRADABLESCHANGES']}
                                    id="SingleBondPage_Changes"
                                />
                            </div>
                            <div className="grid-item grid-item--1of3 grid-item--table-full">
                                <AdRotator location="Market310x400" />
                            </div>
                        </div>
                        <div className="grid-items ">
                            <div className="grid-item " id="SingleBondPage_TradablesInformation">
                                <TradablesInformationBonds
                                    symbol={symbol}
                                    accessToken={accessToken}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleBondPage;