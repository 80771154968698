// Package imports:
import React, { useState, useMemo, useEffect, useRef } from 'react'
import cx from 'classnames'
// Component imports:
import Icon from '../../../Header/CustomIcon/CustomIcon';
import SearchResponse from './CompanySearchResponse';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../../services/config';
import { isElementSubChild } from '../../../../services/utils';
import { ErrorMessages } from '../../../../services/errorMessages';
// Type imports:
import { ICompanySearchResults, ICompanySearchResultsItem } from '../../../../types/SearchTypes';

const MINIMUM_SEARCH_LENGTH = 3;

interface IOwnProps {
    focusSearchInputField?: () => void,
    placeholder?: string,
    size? : 'sm' | 'lg',
    termToAdd?: (e: string) => void,
    disabled?: boolean,
}

const CompareSearch: React.FC<IOwnProps> = ({
    focusSearchInputField,
    placeholder,
    size = 'lg',
    termToAdd,
    disabled
}) => {
    // State variables:
    const [isFocused, setIsFocused] = useState(false);
    const [search, setSearch] = useState('');
    // Company search: maps searchTerm to list of companies.
    const [companySearchTermToSearchItemsMap, setCompanySearchTermToSearchItemsMap] = useState<{
        [searchTerm in string]?: ICompanySearchResultsItem[] | Error
    }>({});
    // Refs:
    const searchFieldInputRef = useRef<HTMLInputElement | null>(null);
    const searchResponseChildRef = useRef<HTMLDivElement | null>(null);
    // Memo variables:
    const isSearchResponseOpen = useMemo(() => {
        return isFocused && search.length >= MINIMUM_SEARCH_LENGTH
    }, [isFocused, search]);

    const searchData: ICompanySearchResultsItem[] | Error | undefined = useMemo(() => {
        // Check search term is long enough to use.
        if (search.length < MINIMUM_SEARCH_LENGTH) return [];

        return companySearchTermToSearchItemsMap[search];
    }, [ search, companySearchTermToSearchItemsMap ]);

    // Keep parent element up-to-date if input is focused or not.
    useEffect(() => {
        if (isFocused && focusSearchInputField) focusSearchInputField();
    }, [ isFocused ])

    // Correctly set focus, since the user can still click inside header without removing focus.
    useEffect(() => {
        const handleClickOutsideOfSearch = (e: MouseEvent) => {
            // Only unfocus input if:
            // - click was outside of KCL_search-response.
            // - AND click was outside of input field-control.
            const { target } = e;
            const searchInputElement = searchFieldInputRef.current;
            const searchResponseElement = searchResponseChildRef.current;
            if (target instanceof Element && searchInputElement && searchResponseElement) {
                // If user clicks on element, which is not in header, then turn off focus.
                if (!isElementSubChild(target, searchInputElement) && !isElementSubChild(target, searchResponseElement)) {
                    setIsFocused(false);
                }
            } else {
                setIsFocused(false)
            }
        }
        document.addEventListener('click', handleClickOutsideOfSearch);
        return () => document.removeEventListener('click', handleClickOutsideOfSearch);
    }, [])

    // Companies, unlike other autocomplete items, must be explicitly searched for.
    // This effect takes care of that.
    useEffect(() => {
        const companySearch = async (term: string) => {
            if (term.length > 0) {
                try {
                    const url = `${GET_KELDAN_API_URL()}/Company/SearchRskConnector?searchTerm=${term}`;
                    const response = await fetch(url);
                    if (response.ok) {
                        const body: ICompanySearchResults = await response.json();
                        setCompanySearchTermToSearchItemsMap({
                            ...companySearchTermToSearchItemsMap,
                            [term]: body.results ?? []
                        });
                    } else {
                        setCompanySearchTermToSearchItemsMap({
                            ...companySearchTermToSearchItemsMap,
                            [term]: new Error(ErrorMessages.RequestFailed)
                        });
                    }
                } catch (e) {
                    setCompanySearchTermToSearchItemsMap({
                        ...companySearchTermToSearchItemsMap,
                        [term]: new Error(ErrorMessages.NetworkError)
                    });
                }
            }
        }
        companySearch(search);
    }, [search]);

    return (
        <div
            className={cx('KCL_company-search', 'KCL_compare-search', size,{
                'is-search-response-open': isSearchResponseOpen,
                'is-disabled': disabled
            })}
            id="Company-Search"
        >
            <form id="Company-Search-Form" onSubmit={(e) =>  e.preventDefault()}>
                <div className="field-control">
                    <input
                        disabled={disabled}
                        type="text"
                        name="search"
                        ref={searchFieldInputRef}
                        value={search}
                        placeholder={placeholder ?? "Leitaðu eftir fyrirtæki"}
                        className="search-field"
                        onFocus={() => setIsFocused(true)}
                        onChange={e => setSearch(e.target.value)}
                        autoComplete="off"
                    />
                </div>
                <div className="search__icon">
                    <span><Icon type="search" /></span>
                </div>

                <SearchResponse
                    compareSearch
                    searchFieldInputRef={searchFieldInputRef}
                    searchData={searchData}
                    isOpen={isSearchResponseOpen}
                    search={search}
                    ref={searchResponseChildRef}
                    setSelectedCompany={(e: string) => {
                        //clear search
                        setSearch('')
                        //return searchTerm
                        termToAdd && termToAdd(e)
                    }
                }/>
            </form>
        </div>
    )
}

export default CompareSearch;
