// Package imports:
import React from 'react';
import cx from 'classnames';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type ButtonTypes = 'primary' | 'buy' | 'secondary';
type ButtonSizes = 'sm' | 'lg';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;
type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;
interface IOwnProps {
    buttonType?: ButtonTypes,
    size?: ButtonSizes,
    disabled?: boolean,
    anchorProps?: AnchorProps,
    icon?: IconProp,
    showLoader?: boolean,
    hideTextInMobile?: boolean,
    smallInMobile?: boolean
}

type Props = IOwnProps & ButtonProps;

const Button: React.FC<Props> = ({
    buttonType = 'primary',
    size = 'lg',
    disabled = false,
    className,
    children,
    anchorProps,
    icon,
    showLoader = false,
    hideTextInMobile = false,
    smallInMobile = false,
    ...props
}) => {
    if (anchorProps) {
        return ( 
            <a
                className={cx('KCL_button', buttonType, size, {
                    'disabled': disabled,
                    'with-icon': icon,
                    'hide-text-in-mobile': hideTextInMobile,
                    'smaller-button-in-mobile': smallInMobile
                }, className)}
                {...anchorProps}
            >
                {icon && (
                    <FontAwesomeIcon icon={icon} className="icon-fa" />
                )}
                {children &&
                    <span className="button-text">
                        {children}
                    </span>
                }
            </a>
        )
    }
    return (
        <button
            className={cx('KCL_button', buttonType, size, {
                'disabled': disabled,
                'with-icon': icon,
                'hide-text-in-mobile': hideTextInMobile,
                'smaller-button-in-mobile': smallInMobile
            }, className)}
            {...props}
        >
            {showLoader
                ? <>
                    <div style={{'height': '0px'}}>
                        <Spinner className='loading-spinner' animation="border" />
                    </div>
                    <span style={{'visibility': 'hidden'}}>{children}</span>
                </>
                : <>
                    {icon && (
                        <FontAwesomeIcon icon={icon} className="icon-fa" />
                    )}
                    {children &&
                        <div className='button-text' style={{'display': 'inline-block'}}>{children}</div>
                    }
                </>
            }
        </button>
    )
}

export default Button;