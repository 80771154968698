// Package imports:
import React from "react";
// Component imports:
import ScrollSpy from "../../../ui-elements/ScrollSpy/ScrollSpy";
import ScrollSpySection from "../../../ui-elements/ScrollSpy/ScrollSpySection";
import Link from "../../../ui-elements/Link/Link";

const KeldanAppDisclaimerComp: React.FC = () => {
  return (
    <div className="keldanMarkadir">
      <article className="article-overview">
        <div className="article__body">
          <ScrollSpy
            containerClass="article__content"
            navClass="article__nav"
            parentClass="keldanMarkadir"
            initialStep="veitt-thjonusta"
          >
            <ScrollSpySection
              className="article__section"
              id="veitt-thjonusta"
              label="Veitt þjónusta"
            >
              <h2>Veitt þjónusta</h2>
              <p>
                Keldan veitir áskrifanda aðgang að rauntíma markaðsgögnum í appi
                og veflausn. Í upplýsingaþjónustunni felst aðgangur að íslenskum
                hluta- og skuldabréfaupplýsingum í rauntíma.
              </p>
            </ScrollSpySection>
            <ScrollSpySection
              className="article__section"
              id="askrift"
              label="Áskrift"
            >
              <h2>Áskrift</h2>
              <p>
                Til að gerast áskrifandi verður notandi að kaupa áskrift í
                forritinu eða með því að hafa samband við Kelduna. Áskriftin er
                endurnýjuð mánaðarlega þangað til henni er sagt upp. Áskriftin
                er gjaldfærð fyrir hvern mánuð fyrirfram. Ef ekki tekst að
                gjaldfæra verður áskrift að rauntímaupplýsingum lokað og notandi
                fær aðgang að seinkuðum gögnum.
              </p>
            </ScrollSpySection>
            <ScrollSpySection
              className="article__section"
              id="uppsogn"
              label="Uppsögn á áskrift"
            >
              <h2>Uppsögn á áskrift</h2>
              <p>
                Áskriftir eru rukkaðar í mesta lagi 24 tímum fyrir byrjun næsta
                áskriftartímabils. Ef segja á upp áskrift skal það gert í
                stillingum App Store fyrir iOs eða Play Store fyrir Android eða
                með því að hafa samband við Kelduna. Segja þarf upp áskrift
                minnst 24 tímum áður en næsti gjalddagi rennur upp, að öðrum
                kosti verður áskriftin gjaldfærð og fæst ekki endurgreidd.
                Uppsögn tekur gildi daginn fyrir næsta gjalddaga sem uppsögn
                berst. Lokast þá aðgengi áskrifanda að rauntímagögnum Keldunnar.
              </p>
            </ScrollSpySection>
            <ScrollSpySection
              className="article__section"
              id="endurgreidsla"
              label="Endurgreiðsla"
            >
              <h2>Endurgreiðsla</h2>
              <p>
                Áskrift getur fengist endurgreidd ef að rauntímagögn berast
                áskrifanda ekki vegna galla í Keldu appinu.
              </p>
            </ScrollSpySection>
            <ScrollSpySection
              className="article__section"
              id="verd"
              label="Verð"
            >
              <h2>Verð</h2>
              <p>
                Keldan áskilur sér rétt til breytinga á verði og skulu
                breytingar tilkynntar áskrifanda, með tölvupósti á uppgefið
                tölvupóstfang áskrifanda.
              </p>
            </ScrollSpySection>
            <ScrollSpySection
              className="article__section"
              id="framsal-rettinda"
              label="Framsal réttinda og miðlun upplýsinga"
            >
              <h2>Framsal réttinda og miðlun upplýsinga</h2>
              <p>
                Áskrifanda er ekki heimilt að framselja áskrift sína til þriðja
                aðila án samþykkis Keldunnar. Áskrifanda er ekki heimilt að
                miðla til þriðja aðila upplýsingum sem sóttar eru gegnum App
                Keldunnar eða vef.
              </p>
            </ScrollSpySection>
            <ScrollSpySection
              className="article__section"
              id="flokkun-askrifenda"
              label="Flokkun áskrifenda"
            >
              <h2>Flokkun áskrifenda</h2>
              <div>
                <p>
                  Áskrift að rauntímagögnum í appinu og á vefnum er rukkuð
                  samkvæmt flokkun Kauphallar Íslands á viðskiptavinum.
                  Viðskiptavinir geta talist almennir fjárfestar eða fagaðilar.
                </p>
                <p>
                  Með því að samþykkja þessa skilmála þá staðfestir áskrifandi
                  flokkun.
                </p>
                <p>
                  Í reglum Kauphallar Íslands eru áskrifendur að rauntíma
                  aðgangi flokkaðir í tvo flokka: almennir fjárfestar sem eru
                  ekki fagfjárfestar (e. non-professionals) og fagaðilar (e.
                  professionals). Samkvæmt reglum kauphallarinnar er verðmunur á
                  áskriftarleiðum fagaðila og almennra fjárfesta. Áskrifendur
                  verða að staðfesta hvort þeir flokkist sem almennir fjárfestar
                  eða fagaðilar.
                </p>
                <p>Almennur fjárfestir sem er ekki fagaðili:</p>
                <p>
                  Einstaklingur eða lögaðili sem ekki flokkast sem fagaðili,
                  fagfjárfestir eða viðurkenndur gagnaðili.
                </p>
                <p>Fagaðili, fagfjárfestir og/eða viðurkenndur gagnaðili::</p>
                <p>
                  "A customer who uses market data to carry out a regulated
                  financial service or regulated financial activity or to
                  provide a service for third parties, or who is considered to
                  be a large undertaking, i.e. meeting two of the following size
                  requirements on a company basis: (i) balance sheet total of
                  EUR 20 000 000 (ii) net turnover of EUR 40 000 000 (iii) own
                  funds of EUR 2 000 000."
                </p>
                <p>
                  Einstaklingur sem starfar sem fagaðili t.d. við
                  verðbréfamiðlun eða fjárfestingaráðgjöf.
                </p>
                <p>
                  Ef í ljós kemur að áskrifandi flokkist sem fagaðili verður
                  áskrift lokað og kauphöllin áskilur sér þann rétt að rukka
                  viðkomandi samkvæmt verðskrá fagaðila. Þeir áskrifendur sem að
                  falla undir fagaðila geta sótt um aðgang að KODIAK Pro með því
                  að senda tölvupóst á{" "}
                  <Link url="mailto:help@kodi.is" linkSize="18">
                    help@kodi.is
                  </Link>
                  .
                </p>
                <br />
                <p>KELDAN ehf.</p>
                <p>KT. 491009-0200 - VSK 103750</p>
                <p>Borgartún 25, 2. hæð, 105 Reykjavík</p>
                <p>
                  Sími{" "}
                  <Link url="tel:+3545101050" linkSize="18">
                    +354 510 1050
                  </Link>
                </p>
                <p>
                  Netfang{" "}
                  <Link url="mailto:info@kodi.is" linkSize="18">
                    info@kodi.is
                  </Link>
                </p>
              </div>
            </ScrollSpySection>
          </ScrollSpy>
        </div>
      </article>
    </div>
  );
};

export default KeldanAppDisclaimerComp;
