
// Package imports:
import React, { forwardRef, Fragment, useEffect, useRef } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
// Service imports:
import { getSearchTermsFromSearch } from '../../../services/utils';
// Type imports:
import { IApiLmdAutocompleteItem, ISearchItem } from '../../../types/SearchTypes';

interface IProps {
    searchData: ISearchItem[]
    isOpen: boolean,
    search: string,
    searchFieldInputRef: React.MutableRefObject<HTMLInputElement | null>
}

const SearchResponse = forwardRef<HTMLDivElement, IProps>(({ searchData, isOpen, search, searchFieldInputRef }, ref) => {
    // Refs for search data elements. Will help us with changing focus.
    const searchDataAnchorElementsRef = useRef<(HTMLAnchorElement | undefined)[]>([]);

    // isOpen can't be read by function declared in hook. Use ref to read it.
    const isOpenRef = useRef(isOpen);
    useEffect(() => { isOpenRef.current = isOpen }, [isOpen]);

    // Document listeners for arrow key functionality.
    useEffect(() => {
        const onKeyPress = (e: KeyboardEvent) => {
            // Fetch refs.
            const isOpen = isOpenRef.current;
            const searchDataAnchorElements = searchDataAnchorElementsRef.current;
            // Should only work if the repsonse window is open.
            if (!isOpen) return;
            const currentFocusedElement = document.activeElement;
            const lastElementIndex = searchDataAnchorElements.length - 1;
            switch (e.key) {
                // Down arrow = go to next (should be like tab)
                case 'ArrowDown':
                    e.preventDefault();
                    // If focus is on search field input, move focus to 1st element in list.
                    if (currentFocusedElement === searchFieldInputRef.current) {
                        searchDataAnchorElements[0]?.focus();
                        return;
                    }
                    if (currentFocusedElement === searchDataAnchorElements[lastElementIndex]) {
                        searchFieldInputRef.current?.focus();
                        return;
                    }
                    const i = searchDataAnchorElements.findIndex(ref => ref === currentFocusedElement);
                    searchDataAnchorElements[i+1]?.focus();
                    break;
                // Up arrow = go to previous (should be like shift-tab)
                case 'ArrowUp':
                    e.preventDefault();
                    // If on search field, go to last element in list.
                    if (currentFocusedElement === searchFieldInputRef.current) {
                        searchDataAnchorElements[lastElementIndex]?.focus();
                        return;
                    }
                    // If on first element, focus on search field input.
                    if (currentFocusedElement === searchDataAnchorElements[0]) {
                        searchFieldInputRef.current?.focus();
                        return;
                    }
                    // If focus is on element in list, move to previous. 
                    const j = searchDataAnchorElements.findIndex(ref => ref === currentFocusedElement);
                    searchDataAnchorElements[j-1]?.focus();
                    break;
            }
        }
        document.addEventListener('keydown', onKeyPress);
        return () => document.removeEventListener('keydown', onKeyPress);
    }, []);

    const highlightText = (title: string) => {
        // The search terms we will search and replace in the original string.
        const searchTerms = getSearchTermsFromSearch(search);
        // Lowercase title to search without case. We will still use the normal title to return values.
        const lowercaseTitle = title.toLowerCase();
        // IMPORTANT: We hightlight using a starting and ending index method. This allows for full highlight even if some searchTerms are substrings of themselves.
        const startHighlightIndices: number[] = [];
        const endHighlightIndices: number[] = [];

        // Find the start and end highlight indices for every search term.
        for (let searchTerm of searchTerms) {
            // Constant helper variables.
            const lowercaseSearchTerm = searchTerm.toLowerCase();
            const searchTermLength = lowercaseSearchTerm.length;

            // The starting index from where to search.
            let startingIndex = 0;
            // The index of the found search term.
            let indexOfSearchTerm = lowercaseTitle.indexOf(lowercaseSearchTerm);
            // Until we no longer find search terms.
            while (indexOfSearchTerm !== -1) {
                startingIndex = (indexOfSearchTerm + searchTermLength);
                startHighlightIndices.push(indexOfSearchTerm);
                endHighlightIndices.push(startingIndex);
                indexOfSearchTerm = lowercaseTitle.indexOf(lowercaseSearchTerm, startingIndex);
            }
        }

        startHighlightIndices.sort((a, b) => a - b);
        endHighlightIndices.sort((a, b) => a - b);

        let titleWithMarks = '';
        let nextStartIndex = startHighlightIndices.shift();
        let nextEndIndex = endHighlightIndices.shift();
        for (let i = 0; i <= title.length; i++) {
            
            // Add end mark if endIndex present
            while (nextEndIndex === i) {
                titleWithMarks += '</mark>';
                nextEndIndex = endHighlightIndices.shift();
            }
            // Add start mark if startIndex present
            while (nextStartIndex === i) {
                titleWithMarks += '<mark>';
                nextStartIndex = startHighlightIndices.shift();
            }
            // Add the char (but not last since we go 1 index over string to check last endhighlight)
            if(i !== title.length) titleWithMarks += title[i];
        }

        return titleWithMarks;
    }

    const searchLink = (item: IApiLmdAutocompleteItem) => {
        switch (item.Tickertype) {
            case 'share':
                return `/Markadir/Hlutabref/${item.Symbol}`;
            case 'bond':
                return `/Markadir/Skuldabref/${item.Symbol}`;
            case 'currency':
                return `/Markadir/Gjaldmidlar/${item.Symbol}`;
            case 'fund':
                return `/Markadir/Sjodir/${item.Symbol}`;
            case 'index':
                return `/Markadir/Visitolur/${item.Symbol}`;
            case 'bondIndex':
                return `/Markadir/Visitolur/${item.Symbol}`;
            case 'company':
                return `/Fyrirtaeki/Yfirlit/${item.Symbol}`;
            default:
                return '#'
        }
    }

    const calculateSearchResultFullIndex = (searchDataIndex: number, searchResultIndex: number) => {
        let fullIndex = 0;
        for (let i = 0; i < searchData.length; i++) {
            if (searchDataIndex === i) break;
            const items = searchData[i].items;
            if (items instanceof Error) continue;
            fullIndex += (items?.length ?? 0)
        }
        fullIndex += searchResultIndex;
        return fullIndex;
    }

    const getSearchResultText = (result: IApiLmdAutocompleteItem) => {
        if (result.Tickertype === 'company') {
            return `${result.Name} Kt. ${result.Symbol}`;
        }
        if (result.Symbol !== null && result.Name !== null) {
            return `${result.Symbol} ${result.Name}`;
        }
        return '';
    }

    return (
        <div
            className={cx('KCL_search-response', {'is-open': isOpen})}
            ref={ref}
        >
            <div
                className="search-response__scroll"
                tabIndex={-1}
            >
                {
                    searchData.map((item, searchDataIndex) => (
                        <Fragment key={item.title}>
                            {(item.items instanceof Error) ? (
                                <div className="search-response__heading error">
                                    {item.title}
                                    <span className="error-msg">
                                        <FontAwesomeIcon
                                            icon={faExclamationTriangle}
                                            className="icon"
                                        />
                                        Ekki tókst að sækja gögn
                                    </span>
                                </div>
                            ) : (
                                item.title && (
                                    <div className="search-response__heading">
                                        {item.title}
                                    </div>
                                )
                            )}
                            {item.items && !(item.items instanceof Error) && item.items.map((result, searchResultIndex) => (
                                <div
                                    key={result.Symbol}
                                    className="search-response__text"
                                >
                                    <a
                                        href={searchLink(result)}
                                        ref={ref => {
                                            searchDataAnchorElementsRef.current[calculateSearchResultFullIndex(searchDataIndex, searchResultIndex)] = (ref ?? undefined)
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: `
                                            ${highlightText(getSearchResultText(result))}`
                                        }}
                                    >
                                    </a>
                                </div>
                            ))}
                        </Fragment>
                    ))
                }
            </div>
        </div>
    )
})

export default SearchResponse
