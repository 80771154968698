// Package imports:
import React from 'react';
// Component imports:
import Link from '../../../ui-elements/Link/Link';
import Table from '../../../ui-elements/Table/Table';
import GangverdChart from '../../Charts/GangverdChart/GangverdChart';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import GangverdMap from './GangverdMap';
// Service imports:
import { displayPrice, formatNumber, getTableDate } from '../../../services/utils';
// Type imports:
import { IEventReport } from '../../../types/KeldanTypes';
import { IGangverdSearchResult } from '../../../types/PropertyTypes';

interface IProps {
    report: IEventReport<IGangverdSearchResult>,
    isExample?: boolean
}

const GangverdReportBody: React.FC<IProps> = ({
    report,
    isExample = false
}) => {
    return (
        <>
            <div className='gangverd-head'>
                <div className='gangverd-info'>
                    <h4>Fasteign</h4>
                    <p className='address'>{report.overview.gangverd.info.address}, {report.overview.gangverd.info.zip} {report.overview.gangverd.info.city}</p>
                    <div className='gangverd-info-section'>
                        <span className='info-title'>Stærð:</span>
                        <span className='info-value'>{formatNumber(report.overview.gangverd.core.totalsize, '-', 1)} m²</span>
                    </div>
                    <div className='gangverd-info-section'>
                        <span className='info-title'>Fastanúmer:</span>
                        <span className='info-value'>{report.overview.gangverd.info.fnr}</span>
                    </div>
                    <div className='gangverd-info-section'>
                        <span className='info-title'>Byggingarár:</span>
                        <span className='info-value'>{report.overview.gangverd.core.buildingyear}</span>
                    </div>
                    <div className='gangverd-info-section'>
                        <span className='info-title'>Fasteignamat:</span>
                        <span className='info-value'>{displayPrice(report.overview.gangverd.info.fmr)}</span>
                    </div>
                    <div className='gangverd-info-section'>
                        <span className='info-title'>Verðmat:</span>
                        <span className='info-value'>{displayPrice(report.overview.gangverd.info.latestval)}</span>
                    </div>
                </div>
                <span className='report-aside-component'>
                    <Link
                        onClick={(e) => {
                            e.preventDefault();
                            window.print();
                        }}
                        disabled={isExample}
                        icon='print'
                    >
                        Prenta skýrslu
                    </Link>
                    <div className='italic-text'>
                        Skýrsla keypt: {report.eventDate}
                    </div>
                </span>
            </div>
            <div className='property-page-body'>
                <DisplayBox
                    className='property-report-box'
                    grayBackground={isExample}
                >
                    <div className='report-section'>
                        <h4>Verðþróun eignar</h4>
                        <GangverdChart
                            estimateSeries={report.overview.gangverd.estimateSeries}
                            ciSeries={report.overview.gangverd.ciSeries}
                            similarSeries={report.overview.gangverd.similarSeries}
                            grayBackground={isExample}
                        />
                    </div>
                    <div className='report-section'>
                        <h4>Líkar sölur</h4>
                        <Table
                            data={report.overview.gangverd.similarSeries.data}
                            columns={[{
                                title: 'Heimilisfang',
                                renderCell: ({address}) => address,
                                textAlign: 'left'
                            }, {
                                title: 'Íbúðarstærð',
                                renderCell: ({size}) => `${formatNumber(size, '-', 1)} m²`,
                                textAlign: 'right'
                            }, {
                                title: 'Herbergi',
                                renderCell: ({rooms}) => rooms,
                                textAlign: 'right'
                            }, {
                                title: 'Byggingarár',
                                renderCell: ({buildingyear}) => buildingyear,
                                textAlign: 'right'
                            }, {
                                title: 'Söludagsetning',
                                renderCell: ({date2}) => getTableDate(date2 !== undefined ? new Date(date2) : null, 'DD/MM/YYYY', '.'),
                                textAlign: 'right'
                            }, {
                                title: 'Söluverð',
                                renderCell: ({price}) => displayPrice(price),
                                textAlign: 'right'
                            }, {
                                title: 'kr./m²',
                                renderCell: ({price, size}) => price !== undefined && size !== undefined ? formatNumber(price/size, '-', 2) : '-',
                                textAlign: 'right'
                            }]}
                            tableSize='lg'
                            indexHeader='Lykill'
                        />
                    </div>
                    <div className='report-section map-section'>
                        <h4>Niðurstöður á korti</h4>
                        {!isExample
                            ? <GangverdMap
                                address={report.overview.gangverd.info.address}
                                lng={report.overview.gangverd.info.lon ?? -70.9}
                                lat={report.overview.gangverd.info.lat ?? 42.35}
                                latestval={report.overview.gangverd.info.latestval}
                                similarSeries={report.overview.gangverd.similarSeries}
                            />
                            : <img src="https://cdn.livemarketdata.com/KeldanImages/GangverdExampleMap.png" alt="Kort" width="1428" height="568" />
                        }
                    </div>
                </DisplayBox>
            </div>
        </>
    );
}

export default GangverdReportBody;