// Package imports:
import React from 'react';
// Component imports:
import Table from '../../../ui-elements/Table/Table';
import Button from '../../../ui-elements/Button/Button';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { useBuyingProcess } from '../../../services/buyhook';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { IPropertySearchResult } from '../../../types/PropertyTypes';
import { IKeldanApiResponse } from '../../../types/KeldanTypes';

interface IProps {
    propertylist: IPropertySearchResult,
    bondsAllowed?: boolean,
    documentsAllowed?: boolean
}

interface IBuyFasteignaSkra {
    type: 'property' | 'documents',
    fastanr: string,
    bonds?: boolean | null
}

const PropertyOverviewTable: React.FC<IProps> = ({
    propertylist,
    bondsAllowed,
    documentsAllowed
}) => {
    const { tryPurchaseItem, tryToBuyItem, resetState, ModalsAndToasts } = useBuyingProcess(async (values: IBuyFasteignaSkra, modalReference, modalBonds) => {
        try {
            const { type, fastanr, bonds } = values;
            let url = '';
            let query = new URLSearchParams();
            if (type === 'property') {
                url = `${GET_KELDAN_API_URL()}/Property/PropertySearchFastanr`;
                query = new URLSearchParams({
                    term: fastanr,
                    reference: modalReference,
                    bonds: (bonds === null) ? `${modalBonds}` : `${bonds}`
                });
            } else if (type === 'documents') {
                url = `${GET_KELDAN_API_URL()}/Property/BuyDocumentList`;
                query = new URLSearchParams({
                    fastanr,
                    reference: modalReference,
                    getEventId: 'true'
                });
            }

            const response = await fetch(`${url}?${query.toString()}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                    return;
                }
                const responseBody: IKeldanApiResponse<IPropertySearchResult> = await response.json();
                const { message } = responseBody;
                if (message === ErrorMessages.NoCard) {
                    resetState('no card')
                } else if (message) {
                    resetState({
                        type: 'alert',
                        headText: message
                    });
                } else {
                    resetState('bad request');
                }
            } else {
                resetState('response not ok');
            }
        } catch (e) {
            resetState('network error');
        }
    }, 'fasteignaskrá');
    
    return (
        <div>
            <Table
                tableSize="lg"
                data={propertylist.response?.properties?.properties || null}
                columns={[{
                    title: 'Fastanúmer',
                    renderCell: ({property_number}) => property_number,
                    textAlign: 'left'
                }, {
                    title: 'Heiti',
                    renderCell: () => propertylist.response?.properties?.address?.street,
                    textAlign: 'left'
                }, {
                    title: 'Húsnúmer',
                    renderCell: () => propertylist.response?.properties?.address?.house_number,
                    textAlign: 'left'
                }, {
                    title: 'Merking',
                    renderCell: ({units}) => units[0].tag,
                    textAlign: 'left'
                }, {
                    title: 'Eigandi',
                    renderCell: ({owners}) => {
                        if (!owners) return '';
                        const filteredOwners = owners.filter(o => o.owner_type === 'F');
                        let s = '';
                        for (const element of filteredOwners) {
                            s += `${s === '' ? '' : ' / '}${element.name}`
                        }
                        return s;
                    },
                    textAlign: 'left'
                }, {
                    title: 'Sveitarfélag',
                    renderCell: () => propertylist.response?.properties?.address?.county,
                    textAlign: 'left'
                }, {
                    title: 'Byggð',
                    renderCell: () => propertylist.response?.properties?.address?.city,
                    textAlign: 'left'
                }, {
                    title: 'Fasteignaskrá',
                    renderCell: ({property_number}) => (
                        <Button
                            buttonType="buy"
                            size='sm'
                            showLoader={(
                                tryToBuyItem !== null
                                && tryToBuyItem.fastanr === property_number
                                && tryToBuyItem.type === 'property'
                            )}
                            onClick={() => property_number && tryPurchaseItem({
                                type: 'property',
                                fastanr: property_number,
                                bonds: null
                            }, bondsAllowed
                                ? 'confirm-modal-with-reference-and-bonds'
                                : 'confirm-modal-with-reference')}
                        >
                            Kaupa
                        </Button>
                    )
                }, {
                    title: 'Skjalalisti',
                    renderCell: ({property_number}) => (
                        <Button
                            buttonType="buy"
                            size='sm'
                            showLoader={(
                                tryToBuyItem !== null
                                && tryToBuyItem.fastanr === property_number
                                && tryToBuyItem.type === 'documents'
                            )}
                            onClick={() => property_number && tryPurchaseItem({
                                type: 'documents',
                                fastanr: property_number
                            }, 'confirm-modal-with-reference')}
                            disabled={!documentsAllowed}
                        >
                            Kaupa
                        </Button>
                    )
                }]}
            />
            <ModalsAndToasts />
        </div>
    );
}

export default PropertyOverviewTable;