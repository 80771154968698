// Package imports:
import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
// Component imports:
import Loading from '../../../ui-elements/Loading/Loading';
import CircleLoading from '../../../ui-elements/CircleLoading/CircleLoading';
// Service imports:
import { formatNumber } from '../../../services/utils';

type Language = 'is' | 'en';

export interface IPieChart {
    name: string | undefined, 
    y: number | undefined,
    color?: string
}

interface IProps {
    data: Array<IPieChart>,
    lang?: Language,
    height?: number,
    showHeader?: boolean
    type?: 'assets' | 'income',
    grayBackground?: boolean
}

const PieChart: React.FC<IProps> = ({
    data,
    type,
    grayBackground = false
}) => {
    // Div references.
    const divRef = useRef<HTMLDivElement>(null);

    const calcPiePercentage = (item: number | undefined) => {
        const sumValues = Object.values(data).reduce((a, b) => (b.y !== undefined) ? (a + b.y) : a, 0);
        if(item !== undefined) return Math.round((item / sumValues) * 100) + '%'
        else return '-'
    }
    useEffect(() => {
        if (data.length > 0 && divRef.current !== null) {
            Highcharts.chart(divRef.current, {
                chart: {
                    type: 'pie',
                    width: 250,
                    height: 250,
                    style: {
                        fontFamily: "Roboto"
                    },
                    animation: false,
                    backgroundColor: grayBackground ? '#F8F8FA' : '#FFFFFF'
                },
                plotOptions: {
                    pie: {
                        size: '110%',
                        center: ['50%','50%'],
                        animation: false
                    }
                },
                responsive: {
                    rules: [{
                        condition: {
                            callback: function() {
                                return window.matchMedia('(max-width: 767px)').matches;
                            }
                        },
                    }]
                },
                title: {
                    text: ''
                },
                series: [{
                    type: 'pie',
                    innerSize: '40%',
                    data: data,
                    dataLabels: {
                        enabled: false
                    }
                }],
                exporting: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    pointFormatter: function() {
                        return (type === 'assets') 
                        ? `Eignir: ${formatNumber(this.y, '-', 0)} kr. - ${calcPiePercentage(this.y)}`
                        : `Tekjur: ${formatNumber(this.y, '-', 0)} kr. - ${calcPiePercentage(this.y)}`
                    }
                },
                legend: {
                    enabled: false,
                },
                
            }, () => {});
        }
    }, [data]);
    if(data.length === 0) return (
        <div className='pieChart-loading'>
            <div>
                <CircleLoading />
            </div>
            <div>
                <Loading />
            </div>
        </div>
        )
    return (
        <div className='KCL_pie-chart'>
            <div ref={divRef}></div>
            <div className='list-wrapper'>
                {
                data.map((item, index) => {
                    return ( 
                        <ul key={index}>
                            <li>
                                <div className="listColor" style={{'width': 8, 'height': 18,'background': item.color}}></div>
                                <span>{`${item.name} - ${type === 'assets' ? 'Eignir' : 'Tekjur'}: ${formatNumber(item.y ?? 0, '-', 0)} kr.`}</span>
                            </li>
                        </ul>
                    )
                })}

            </div>
        </div>
    );
}

export default PieChart;