// Package imports:
 import { ForwardedRef, forwardRef, memo } from 'react';
 import { useField } from 'formik';
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faCheck } from '@fortawesome/free-solid-svg-icons'
 import cx from 'classnames'

  
type CheckProps = React.InputHTMLAttributes<HTMLInputElement>;

interface IOwnProps {
    label?: string | JSX.Element,
    labelComponent?: JSX.Element
}

type Props = CheckProps & IOwnProps;

const Checkbox = forwardRef(({
        id,
        label,
        className = '',
        value,
        onChange = () => {},
        labelComponent,
        name,
        ...props
    }: Props,
    ref: ForwardedRef<any>
 ) => {
    const [field, meta] = useField(name ?? '');
 
    return (
        <div
            className={cx('KCL_checkbox', className, {
                'is-disabled': props.disabled,
                'checked': value === 'true'
            })}
        >
            <input
                ref={ref}
                id={id}
                type="checkbox"
                defaultChecked={value === 'true'}
                {...field}
                {...props}
            />
    
            <label htmlFor={id} className="checkbox__label">
                <span className="checkbox__icon">
                    <i>
                        <FontAwesomeIcon icon={faCheck}/>
                    </i>
                </span>
    
                {label && (
                    <span>{label}</span>
                )}
    
                {labelComponent && (
                    <span>{labelComponent}</span>
                )}
            </label>
            {meta.error && meta.touched && <div className="input-error-message">{meta.error}</div>}
        </div>
    );
});
 
export default memo(Checkbox);
 