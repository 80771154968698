// Package imports:
import React, { useEffect } from 'react';
import AOS from "aos";
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
// Component imports:
import Button from '../../ui-elements/Button/Button';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import MarkadirPricing from '../KeldanMarkadirPricingSection/KeldanMarkadirPricingSection';
import GagnaleitPricing from '../GagnaleitPricingSection/GagnaleitPricingSection';

type SelectedSystem = 'Markadir' | 'Gagnaleit' | null;

const PricingPage: React.FC = () => {
    AOS.init();
    const hashId = window.location.hash.slice(1);

    const [selectedSystem, setSelectedSystem] = React.useState<SelectedSystem>(hashId as SelectedSystem);
    const markadirToggle = () => {
        if (selectedSystem === 'Markadir') {
            setSelectedSystem(null);
        }
        else {
            setSelectedSystem('Markadir');
        }
    }
    const gagnaleitToggle = () => {
        if (selectedSystem === 'Gagnaleit') {
            setSelectedSystem(null);
        }
        else {
            setSelectedSystem('Gagnaleit');
        }
    }

    const displayPricingInfo = () => {
        switch (selectedSystem) {
            case 'Markadir':
                return <MarkadirPricing page='pricing' />;
            case 'Gagnaleit':
                return <GagnaleitPricing />;
            default:
                return null;
        }
    }

    function locationHashChanged() {
        const hashId = window.location.hash.slice(1);
        setSelectedSystem(hashId as SelectedSystem);
    }
    window.onhashchange = locationHashChanged;

    useEffect(() => {
        window.location.hash = selectedSystem ?? '';
        if (selectedSystem) {
            document.getElementById(`${selectedSystem}-pricing`)?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [selectedSystem]);

    return (
        <div className="main KCL_pricing-page">
            <div className="main__inner">
                <div className='section--white'>
                    <div className="mini_shell">
                        <HeadSection
                            title='Áskrift að Keldunni'
                            summary={
                                <p>
                                    Keldan býður upp á mismunandi áskriftarleiðir til að mæta ólíkum þörfum viðskiptavina. <br />Veldu þá leið sem hentar þér eða þínu fyrirtæki.
                                </p>
                            }
                            textAlign='center'
                        />
                    </div>
                </div>
                <div className="section section--gray">
                    <div className="mini_shell">
                        <div className='system-selection'>
                            <DisplayBox
                                titleComponent={
                                    <object
                                        data="https://cdn.livemarketdata.com/KeldanImages/Keldan_markadir_logo.svg"
                                        aria-label="Keldan | markaðir"
                                        height={32}
                                    >
                                        Keldan | markaðir
                                    </object>
                                }
                                displayBoxSize='xl'
                                faded={selectedSystem === 'Gagnaleit'}
                                stretch={false}
                            >
                                <p className='paragraph'>
                                    <span className='paragraph--bold'>Aðgangur að rauntímagögnum</span> frá Nasdaq Nordic um gengi hlutabréfa, gjaldmiðla og vísitalna, ásamt Vaktinni, verðbréfavakt í rauntíma.
                                </p>
                                <Button
                                    buttonType="primary"
                                    size="lg"
                                    onClick={markadirToggle}
                                    icon={ selectedSystem === 'Markadir' ? faSquareCheck : faSquare }
                                >
                                    Velja Keldan markaðir
                                </Button>
                            </DisplayBox>
                            <DisplayBox
                                titleComponent={
                                    <object
                                        data="https://cdn.livemarketdata.com/KeldanImages/Keldan_gagnaleit_logo.svg"
                                        aria-label="Keldan | Gagnaleit"
                                        height={40}
                                    >
                                        Keldan | Gagnaleit
                                    </object>
                                }
                                displayBoxSize='xl'
                                faded={selectedSystem === 'Markadir'}
                                stretch={false}
                            >
                                <p className='paragraph'>
                                    <span className='paragraph--bold'>Aðgangur að fjárhagsupplýsingum félaga</span> ásamt skýrslum og skrám sem reknar eru af opinberum aðilum á Íslandi.
                                </p>
                                <Button
                                    buttonType="primary"
                                    size="lg"
                                    onClick={gagnaleitToggle}
                                    icon={ selectedSystem === 'Gagnaleit' ? faSquareCheck : faSquare }
                                >
                                    Velja Keldan gagnaleit
                                </Button>
                            </DisplayBox>
                        </div>
                    </div>
                    {displayPricingInfo()}
                </div>
            </div>
        </div>
    )
}

export default PricingPage;
