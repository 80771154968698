// Package imports:
import React, { useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faInfoCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons';
// Component imports:
import Link from '../../ui-elements/Link/Link';

export type AlertType = 'success' | 'info' | 'alert' | 'error';

interface IProps {
    type: AlertType,
    withCloseIcon?: boolean,
    headText: string,
    text?: JSX.Element | string
    closeAlert?: () => void,
    moreInfoLink?: string
}

const icon = {
    'success': faCheckCircle,
    'info': faInfoCircle,
    'alert': faExclamationCircle,
    'error': faExclamationTriangle
};

const Alert: React.FC<IProps> = ({
    type,
    withCloseIcon,
    headText,
    text,
    closeAlert,
    moreInfoLink
}) => {
    const [show, setShow] = useState(true);
    return (
        <div className={cx('KCL_alert', type, text ? 'lg' : 'sm', { 'dismissed': withCloseIcon && closeAlert === undefined && !show })}>
            <span className="alert-header">
                <div className='icon-and-header'>
                    <FontAwesomeIcon icon={icon[type]} className="icon" />
                    <p className="head-text paragraph-small--bold">{headText}</p>
                </div>
                {withCloseIcon &&
                    <button
                        onClick={closeAlert ?? (() => {setShow(false)})}
                        className="close-button"
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                }
            </span>
            {text &&
                <div className="main-text">
                    <div className='paragraph-small'>
                        {text}
                    </div>
                    {moreInfoLink &&
                        <Link
                            url={moreInfoLink}
                            linkSize="16"
                            targetBlank
                        >
                            Nánari upplýsingar
                        </Link>
                    }
                </div>
            }
        </div>
    );
}

export default Alert;