// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import ViewDocumentListComp from '../../components/Property/ViewDocumentList/ViewDocumentList';
// Type imports:
import { IPropertyViewModel } from '../../types/PropertyTypes';


const ViewDocumentList: React.FC<IPropertyViewModel> = ({
    propertyNumber,
    eventId
}) => {
    return (
        <Entrypoint>
            <ViewDocumentListComp propertyNumber={propertyNumber} eventId={eventId ?? ''} />
        </Entrypoint>
    );
}

export default ViewDocumentList;