// Package imports:
import React from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Tabs from '../../ui-elements/Tabs/Tabs';
import Vixlar from './Vixlar/Vixlar'
import Fyrirtaekjabref from './Fyrirtaekjabref/Fyrirtaekjabref';
import GraenSkuldabref from './GraenSkuldabref/GraenSkuldabref';
import Vaxtaferlar from './Vaxtaferlar/Vaxtaferlar';
import DelayOrClosedLabel from '../DelayOrClosedLabel/DelayOrClosedLabel';
// Service imports:
import { CUSTOM_REFRESH_RATES } from '../../services/config';
// Type imports
import { IDefaultProps } from '../../types/Types';

type Props = IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const Skuldabref: React.FC<Props>= ({
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const defaultProps = {
        accessToken,
        refreshRateMs: CUSTOM_REFRESH_RATES['SKULDABREF']
    };

    return (
        <DisplayBox
            title="Skuldabréf"
            anchorProps={{ href: '/Markadir/Skuldabref' }}
            className='KCL_skuldabref'
            statusComponent={<DelayOrClosedLabel />}
            displayBoxSize="sm"
            {...props}
        >
            <Tabs tabs={[{
                id: `${props.id}-vixlar`,
                label: "Skuldabréf / Víxlar",
                component: <Vixlar {...defaultProps} />
            }, {
                id: `${props.id}-fyrirtaekjabref`,
                label: "Fyrirtækjabréf",
                component: <Fyrirtaekjabref {...defaultProps} />
            }, {
                id: `${props.id}-graen-bref`,
                label: "Græn skuldabréf",
                component: <GraenSkuldabref {...defaultProps} />
            }, {
                id: `${props.id}-vaxtaferlar`,
                label: "Vaxtaferlar",
                component: <Vaxtaferlar {...defaultProps} />
            }]}/>
        </DisplayBox>
    );
}

export default Skuldabref;