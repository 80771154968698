// Package imports:
import React, { useContext, useEffect, useState } from 'react';
// Component imports:
import CompanyTitle from '../CompanyTitle/CompanyTitle';
import CompanyNavBar from '../CompanyNavBar/CompanyNavBar';
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';
import AdRotator from '../../Ad/AdRotator';
// Type imports:
import { IDefaultProps } from '../../../types/Types';
import { ICompanyViewModel } from '../../../types/CompanyTypes';
// Context imports:
import { AccessTokenContext } from '../../../contexts/AccessTokenContext';

interface ICompanySubPage {
    component: JSX.Element,
    title: string,
    subTitle?: string,
    noAd?: boolean,
    showRecentReports?: boolean
}

const CompanySubPage: React.FC<ICompanySubPage & ICompanyViewModel> = ({
    id,
    component,
    title,
    isAuthenticated,
    isSubscriber,
    subTitle,
    noAd,
    showRecentReports = false
}) => {
    const accessToken = useContext(AccessTokenContext);
    const [ defaultProps, setDefaultProps ] = useState<IDefaultProps>({
        accessToken
    });

    useEffect(() => {
        setDefaultProps({
            ...defaultProps,
            accessToken
        });
    }, [accessToken]);

    return (
        <div className='main KCL_company-page'>
            <div className='main__inner-fluid'>
                <CompanyNavBar
                    isAuthenticated={isAuthenticated}
                    isSubscriber={isSubscriber}
                    id={id}
                />
                <div className="shell">
                    <HeadSection
                        title={title}
                        showRecentReports={showRecentReports}
                    />
                    <CompanyTitle
                        id={id}
                        accessToken={accessToken}
                    />
                    {(subTitle) && (
                        <h2>{subTitle}</h2>
                    )}
                    <div className='body'>
                        {component}
                        {!noAd && (
                            <div>
                                <AdRotator location='Market310x400' />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompanySubPage;