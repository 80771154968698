// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import StillingarComp from '../../components/Stillingar/Stillingar';

const Stillingar: React.FC = () => {
    return (
        <Entrypoint>
            <StillingarComp />
        </Entrypoint>
    );
}

export default Stillingar;