// Package imports:
import React from 'react';
// Component imports:
import NewsWatchComponent from '../../components/Frettavakt/Frettavakt';
import Entrypoint from '../Entrypoint';
import { ICompanyViewModel } from '../../types/CompanyTypes';

const NewsWatch: React.FC<ICompanyViewModel> = ({
    isAuthenticated,
    isSubscriber
}) => {
    return (
        <Entrypoint>
            <NewsWatchComponent isAuthenticated={isAuthenticated} isSubscriber={isSubscriber}/>
        </Entrypoint>
    );
}

export default NewsWatch;