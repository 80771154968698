// Package imports:
import React from 'react';
import cx from 'classnames';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons'
import { faArrowRightToBracket, faFile, faFileExcel, faFileImage, faFilePdf, faMailbox, faPhone, faPlus, faPrint } from '@fortawesome/pro-solid-svg-icons';

type LinkIcons = 'forward' | 'back' | 'pdf' | 'excel' | 'document' | 'mail' | 'phone' | 'image' | 'more' | 'print' | 'login';

const iconMap: {[i in LinkIcons]: IconDefinition} = {
    'forward': faAngleRight,
    'back': faAngleLeft,
    'pdf': faFilePdf,
    'excel': faFileExcel,
    'document': faFile,
    'mail': faMailbox,
    'phone': faPhone,
    'image': faFileImage,
    'more': faPlus,
    'print': faPrint,
    'login': faArrowRightToBracket
}

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;
type LinkSize = '12' | '13' | '14' | '15' | '16' | '18' | '20';
interface IProps {
    url?: string,
    disabled?: boolean,
    targetBlank?: boolean,
    wrapTextForMobile?: boolean,
    splitLines?: boolean,
    linkSize?: LinkSize,
    icon?: LinkIcons
}

type Props = AnchorProps & IProps;

const Link: React.FC<Props>= ({
    url,
    children,
    disabled,
    targetBlank,
    wrapTextForMobile,
    splitLines,
    linkSize = '15',
    icon,
    className,
    ...props
}) => {
    return (
        <a
            className={cx(
                "KCL_link",
                className,
                `font-size--${linkSize}`,
                {'is-disabled': disabled},
                {'wrap-text-for-mobile': wrapTextForMobile},
                {'split-lines': splitLines}
            )}
            href={url}
            target={targetBlank ? '_blank' : '_self'}
            rel='noreferrer'
            {...props}
        >
            {icon &&
                <FontAwesomeIcon
                    icon={iconMap[icon]}
                    className={cx("icon-fa", {
                        'arrow-icon' : icon === 'forward' || icon === 'back'
                    })}
                />
            }
            {children}
        </a >
    );
}

export default Link;