// Package imports:
import React from 'react';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
// Component imports:
import FormulaBox from '../FormulaBox/FormulaBox';
import Accordion from '../../ui-elements/Accordion/Accordion';
import Alert from '../../ui-elements/Alert/Alert';
import ScrollSpy from '../../ui-elements/ScrollSpy/ScrollSpy';
import ScrollSpySection from '../../ui-elements/ScrollSpy/ScrollSpySection';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';

const FinanceRefPage: React.FC = () => {
    return (
        <MathJaxContext>
            <div className='main KCL_finance-ref-page'>
                <div className="main__inner">
                    <div className="shell">
                        <article className="article-overview">
                            <HeadSection
                                title="Fjármálahugtök"
                                summary={
                                    <p>Keldan hefur tekið saman helstu hugtök sem notuð eru í fjármálageiranum og birtir hér nokkur dæmi um notkun.</p>
                                }
                            />
                            <div className="article__body">
                                <ScrollSpy
                                    containerClass="article__content"
                                    navClass="article__nav"
                                    parentClass='KCL_finance-ref-page'
                                    initialStep="fjarmalastjornun"
                                >
                                    <ScrollSpySection className="article__section" id="fjarmalastjornun" label="Fjármálastjórnun">
                                        <h2>Fjármálastjórnun</h2>
                                        <p>
                                            Virði fyrirtækis er lykilstærð í allri fjármálastjórnun og ræðst hún einkum af þrennu; (1) <strong>stærð frjálsa fjárstreymisins</strong> (e. free cash flow, FCF)
                                            sem fyrirtækið skapar ásamt (2) <strong>tímasetningu</strong> og (3) <strong>áhættu þessa fjárstreymis</strong>.
                                        </p>
                                        <p>
                                            Áhætta og fjármagnskostnaður haldast að öðru óbreyttu í hendur, en nánar verður fjallað um áhættu og ávöxtun í 4. hluta.
                                            Veginn fjármagnskostnaður (e. weighted average cost of capital, WACC) er sú meðal ávöxtunarkrafa sem allir fjárfestar gera til fyrirtækis.
                                            Virði fyrirtækis má lýsa með eftirfarandi jöfnu:
                                        </p>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="1.1"
                                                name="Virði"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(\\frac{FCF_1}{(1 +WACC)^1} + \\frac{FCF_2}{(1 + WACC)^2} + \\cdots + \\frac{FCF_{\\infty}}{(1 + WACC)^\\infty}\\)`}
                                                    </MathJax>
                                                }
                                            />
                                        </div>
                                        <p>Nánar er vikið að frjálsu fjárstreymi (FCF) í 6. hluta, vegnum fjármagnskostnaði (WACC) í 8. hluta og virði fyrirtækja í 9. hluta.</p>
                                    </ScrollSpySection>
                                    <ScrollSpySection className="article__section" id="vextir" label="Vextir">
                                        <h2>Vextir</h2>
                                        <p>
                                            Vextir eru gjald sem lántakandi greiðir fyrir afnotarétt fjármagns um tiltekinn tíma og eru vextir því tímatengdur kostnaður. Í nær öllum tilfellum eru vextir settir fram á ársgrundvelli og sem hundraðshluti af höfuðstól lánsins.
                                            Vöxtum má skipta í tvær tegundir; (1) <strong>flata vexti</strong> og (2) <strong>vaxtavexti</strong>, en munurinn felst í því hvort vextir eru reiknaðir af áður áföllnum vöxtum eða ekki.
                                        </p>
                                        <h3>Flatir vextir (e. simple interest)</h3>
                                        <p>Ef vextir reiknast eingöngu af höfuðstól er talað um flata vexti eða einfalda vexti.</p>

                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="2.1"
                                                name="Vaxtagreiðsla"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(P \\cdot i \\cdot n\\)`}
                                                    </MathJax>
                                                }
                                            />
                                            <FormulaBox
                                                label="2.2"
                                                name="Höfuðstóll í lok tímabils"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(S = P + (P \\cdot i \\cdot n)\\)`}
                                                    </MathJax>
                                                }
                                            />
                                            
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>P</strong> = höfuðstóll í upphafi tímabils</li>
                                                <li><strong>i</strong> = nafnvextir</li>
                                                <li><strong>n</strong> = tími (í árum)</li>
                                            </ul>
                                        </div>

                                        <h4>Dæmi 2-1</h4>
                                        <p>Gunnar kaupir skuldabréf að nafnverði 100.000 kr. sem ber 6% nafnvexti og með gjalddaga eftir hálft ár. Hver verður vaxtagreiðslan og hvað fær hann endurgreitt?</p>
                                        <Accordion
                                            items={[{
                                                id: 'solution2-1',
                                                title: 'Lausnin',
                                                content: <p>Vaxtagreiðslan verður 100.000 &#183; 6% &#183; 0,5 = 3.000 kr. og Gunnar fær því endurgreiddar 100.000 + 3.000 = 103.000 kr. í lok tímabilsins</p>
                                            }]}
                                        />

                                        <h3>Vaxtavextir (e. compound interest)</h3>
                                        <p>Ef vextir reiknast af höfuðstól ásamt áður áföllnum vöxtum er talað um vaxtavexti eða samsetta vexti.</p>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="2.3"
                                                name="Vaxtagreiðsla"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(P \\cdot ((1+i)^n - 1)\\)`}
                                                    </MathJax>
                                                }
                                            />
                                            <FormulaBox
                                                label="2.4"
                                                name="Höfuðstóll í lok tímabils"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(S = P \\cdot (1 + i)^n\\)`}
                                                    </MathJax>
                                                }
                                            />
                                            
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>P</strong> = höfuðstóll í upphafi tímabils</li>
                                                <li><strong>i</strong> = nafnvextir</li>
                                                <li><strong>n</strong> = tími (í árum)</li>
                                            </ul>
                                        </div>

                                        <h4>Dæmi 2-2</h4>
                                        <p>Jón kaupir skuldabréf að nafnverði 100.000 kr. sem ber 6% nafnvexti og með gjalddaga eftir þrjú ár. Hver verður vaxtagreiðslan og hvað fær hann endurgreitt?</p>
                                        <Accordion
                                            items={[{
                                                id: 'solution2-2',
                                                title: 'Lausnin',
                                                content: <p>Vaxtagreiðslan verður 100.000 &#183; ((1+0,06)<sup>3</sup> - 1) &#8776; 19.102 kr. og Jón fær því endurgreiddar 100.000 + 19.102 = 119.102 kr. (&#8776; 100.000 &#183; (1+0,06)<sup>3</sup>).</p>
                                            }]}
                                        />

                                        <p>Í dæminu um Jón er gert ráð fyrir því að 6% nafnvextirnir reiknist árlega, en það er einmitt venjan ef annað er ekki tekið fram. En þar sem vaxtavextir reiknast bæði af höfuðstól og áður áföllnum vöxtum, skiptir öllu máli hversu oft á hverju tímabili þessir vextir eru reiknaðir. Vaxtavöxtum má því skipta upp í þrjár tegundir; (1) þá sem reiknast árlega (e. annual compounding), sbr. dæmi 2–2 hér að ofan, (2) tímabilsvexti (e. periodic compounding) og (3) samfellda vexti (e. continuous compounding).</p>
                                        
                                        <h3>Tímabilsvextir (e. periodic compounding)</h3>
                                        <p>Þegar vaxtavextir eru reiknaðir oftar en einu sinni á ári er talað um <strong>tímabilsvexti</strong> eða <strong>hlutfallsvexti</strong>. Fjöldi vaxtatímabila segir þá til um hversu oft vextir eru reiknaðir á ári.</p>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="2.5"
                                                name="Vaxtagreiðsla"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(P \\cdot ( ( 1 + \\frac{i}{m})^{n \\cdot m } - 1 )\\)`}
                                                    </MathJax>
                                                }
                                            />
                                            <FormulaBox
                                                label="2.6"
                                                name="Höfuðstóll í lok tímabils"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(S = P \\cdot ( 1 + \\frac{i}{m})^{n \\cdot m}\\)`}
                                                    </MathJax>
                                                }
                                            />
                                            
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>P</strong> = höfuðstóll í upphafi tímabils</li>
                                                <li><strong>i</strong> = nafnvextir</li>
                                                <li><strong>n</strong> = tími (í árum)</li>
                                                <li><strong>m</strong> = fjöldi vaxtatímabila á ári</li>
                                            </ul>
                                        </div>

                                        <h4>Dæmi 2-3</h4>
                                        <p>Einar leggur 100.000 kr. inn á bankabók sem gefur 4% nafnvexti og reiknast þeir hálfsárslega. Hvað fær Einar mikla vexti eftir eitt ár og hver verður innistæðan?</p>
                                        <Accordion
                                            items={[{
                                                id: 'solution2-3',
                                                title: 'Lausnin',
                                                content: <p>Vaxtagreiðslan verður 100.000 &#183; ((1+0,04/2)<sup>1 &#183; 2</sup> - 1) &#8776; 4.040 kr. og innistæðan inni á bankabókinni því 100.000 + 4.040 = 104.040 kr. (&#8776; 100.000 &#183; (1+0,04/2)<sup>1 &#183; 2</sup>).</p>
                                            }]}
                                        />

                                        <h3>Samfelldir vextir (e. continuous compounding)</h3>
                                        <p>Ef 4% nafnvextirnir á bankabókinni hans Einars hefðu reiknast árlega, þá hefði hann aðeins fengið 100.000 &#183; ((1+0,04) – 1) = <strong>4.000 kr</strong>. vexti í lok ársins. Hann fær því <strong>40 kr</strong>. aukalega þar sem vextirnir á bankabókinni reiknast hálfsárslega eða tvisvar sinnum á ári. Hefðu þeir reiknast ársfjórðungslega eða fjórum sinnum á ári, hefði hann fengið <strong>60 kr</strong>. aukalega, og hefðu þeir reiknast mánaðarlega eða tólf sinnum á ári, hefði hann fengið <strong>74 kr</strong>. aukalega. Af þessu er freistandi að draga þá ályktun að því oftar sem vextir eru reiknaðir á ári því hærri verður vaxtagreiðslan. Stærðfræðingurinn Leonhard Euler gerði hins vegar þá merku uppgötvun að svo er ekki. Hann sýndi fram á að <MathJax hideUntilTypeset="first" inline>{`\\(\\lim\\limits_{m \\to \\infty} (1+\\frac{i}{m})^m = e \\approx 2,71828\\)`}</MathJax>, en í dag er þessi mikilvæga tala gjarnan kölluð tala Eulers. Uppgötvun Eulers sýnir okkur að ef nafnvextirnir á bankabókinni hans Einars hefðu reiknast óendanlega oft á ári eða <strong>reiknast samfellt</strong>, þá hefði hann fengið <strong>81 kr</strong>. aukalega. Þessu er betur lýst í dæmi 2–4. Í þessum tilfellum er talað um <strong>samfellda vexti</strong> og er tala Eulers notuð til útreikninga á þeim.</p>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="2.7"
                                                name="Vaxtagreiðsla"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(P \\cdot (e^{i\\cdot n} - 1)\\)`}
                                                    </MathJax>
                                                }
                                            />
                                            <FormulaBox
                                                label="2.8"
                                                name="Höfuðstóll í lok tímabils"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(S = P \\cdot e^{i\\cdot n}\\)`}
                                                    </MathJax>
                                                }
                                            />
                                            
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>P</strong> = höfuðstóll í upphafi tímabils</li>
                                                <li><strong>i</strong> = nafnvextir</li>
                                                <li><strong>n</strong> = tími (í árum)</li>
                                                <li><strong>e</strong> = 2,71828...</li>
                                            </ul>
                                        </div>

                                        <h4>Dæmi 2-4</h4>
                                        <p>Magnús leggur 100.000 kr. inn á bankabók sem gefur 4% nafnvexti og reiknast þeir samfellt. Hvað fær Magnús mikla vexti eftir eitt ár og hver verður innistæðan?</p>
                                        <Accordion
                                            items={[{
                                                id: 'solution2-4',
                                                title: 'Lausnin',
                                                content: <p>Vaxtagreiðslan verður 100.000 &#183; (e<sup>0,04 &#183; 1</sup> - 1) &#8776; 4.081 kr. og innistæðan inni á bankabókinni því 100.000 + 4.081 = 104.081 kr. (&#8776; 100.000 &#183; e<sup>0,04 &#183; 1</sup>).</p>
                                            }]}
                                        />

                                        <h3>Virkir ársvextir (e. effective annual rate)</h3>
                                        <p>Það er ljóst að Magnús fær betri kjör hjá sínum banka heldur en Einar, en engu að síður fá þeir báðir 4% nafnvexti. Til að bera saman fjárfestingar með ólíkum fjölda vaxtatímabila, eru nafnvextir þeirra umreiknaðir eins og þeir væru <strong>reiknaðir árlega</strong>. Þessir umreiknuðu nafnvextir kallast <strong>virkir ársvextir</strong>. Þegar umreikna skal <strong>tímabilsvexti</strong> yfir í virka ársvexti má notast við eftirfarandi jöfnu:</p>

                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="2.9"
                                                name="Virkir ársvextir"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\((1 + \\frac{i}{m})^m - 1\\)`}
                                                    </MathJax>
                                                }
                                            />
                                            
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>i</strong> = nafnvextir</li>
                                                <li><strong>m</strong> = fjöldi vaxtatímabila á ári</li>
                                            </ul>
                                        </div>
                                        
                                        <p>Þegar umreikna skal samfellda vexti yfir í virka ársvexti gildir:</p>

                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="2.10"
                                                name="Virkir ársvextir"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(e^i - 1\\)`}
                                                    </MathJax>
                                                }
                                            />

                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>i</strong> = nafnvextir</li>
                                            </ul>
                                        </div>

                                        <h4>Dæmi 2-5</h4>
                                        <p>Berið saman þá virku ársvexti sem Einari bjóðast úr dæmi 2–3 og þá sem Magnúsi bjóðast úr dæmi 2–4.</p>
                                        <Accordion
                                            items={[{
                                                id: 'solution2-5',
                                                title: 'Lausnin',
                                                content: <p>Virkir ársvextir (Einar) = (1+0,04/2)<sup>2</sup> – 1 = 4,04% <br />Virkir ársvextir (Magnús) = e<sup>0,04</sup> – 1 &#8776; 4,08%</p>
                                            }]}    
                                        />

                                        <Alert
                                            type='info'
                                            headText='Sett upp í Microsoft &#174; Excel'
                                            text={
                                                <p>EFFECT fallið skilar virkum ársvöxtum að gefnum nafnvöxtum og fjölda vaxtatímabila. NOMINAL fallið skilar nafnvöxtum að gefnum virkum ársvöxtum og fjölda vaxtatímabila.</p>
                                            }
                                        />

                                        <h3>Raunvextir (e. real interest rate)</h3>
                                        <p>Með nafnvöxtum (e. nominal interest rate) er átt við vexti sem reiknast án tillits til verðlags, en með raunvöxtum (e. real interest rate) er átt við vexti umfram verðlagsbreytingar á sama tíma. Raunvextir eru því jákvæðir ef nafnvextir eru hærri en verðbólga á tilteknu tímabili. Raunvexti má leiða út frá <strong>jöfnu Fishers</strong> á eftirfarandi hátt:</p>

                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="2.11"
                                                name="Raunvextir"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(\\frac{1+i}{1+\\pi} - 1\\)`}
                                                    </MathJax>
                                                }
                                            />

                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>i</strong> = nafnvextir</li>
                                                <li><strong>&pi;</strong> = verðbólga</li>
                                            </ul>
                                        </div>

                                        <h3>Markaðsvextir (e.market interest rates)</h3>
                                        <p>Markaðsvextir allra skuldatrygginga eru byggðir upp af raunvöxtum ríkisverðbréfa ásamt hinum ýmsu tegundum af vaxtaálagi.</p>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="2.12"
                                                name="Markaðsvextir"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(r = r^* + IP + DRP + LP + MRP = r_{RF} + DRP + LP + MRP\\)`}
                                                    </MathJax>
                                                }
                                            />

                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>r<sup>*</sup></strong> = raunvextir ríkisverðbréfa (e. real risk-free rate of interest)</li>
                                                <li><strong>IP</strong> = verðbólguálag (e. inflation premium)</li>
                                                <li><strong>r<sup>*</sup> + IP</strong> = <strong>r<sub>RF</sub></strong> = vextir af ríkisvíxlum (e. quoted risk-free rate of interest on treasury bill)</li>
                                            </ul>
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>DRP</strong> = greiðslufallsálag (e. default risk premium)</li>
                                                <li><strong>LP</strong> = lausafjárálag (e. liquidity/marketability premium)</li>
                                                <li><strong>MRP</strong> = gjalddagaálag/endurfjárfestingarálag (e. maturity risk premium)</li>
                                            </ul>
                                        </div>
                                    </ScrollSpySection>
                                    <ScrollSpySection className="article__section" id="timavirdi-peninga" label="Tímavirði peninga">
                                        <h2>Tímavirði peninga</h2>
                                        <p>Virði allra fjárfestinga byggist að svo miklu leyti á tímasetningu fjárstreymis að erfitt er að finna greinar innan fjármála sem skipta meira máli en tímavirði peninga (e. time value of money).</p>

                                        <h3>Tímalínan</h3>
                                        <p>Mikilvægasta hjálpartækið við útreikninga á tímavirði peninga er tímalínan.</p>
                                        <img
                                            alt="Tímalínan"
                                            src="https://cdn.livemarketdata.com/KeldanImages/financeref/img-01.png"
                                            width="1446"
                                            height="166"
                                        />

                                        <p>Tími 0 táknar hér tímann í dag. Tími 1 táknar tímann eftir eitt tímabil (eða endann á tímabili 1). Tími 2 táknar tímann eftir tvö tímabil og tími 3 eftir þrjú tímabil. Vextir á tímabili 1 til 3 eru hér sagðir 5%. <strong>Núvirði</strong> (e. present value, PV) er alltaf staðsett á tíma 0, en <strong>framtíðarvirði</strong> (e. future value, FV) er hægt að staðsetja á enda hvers tímabils og er oft táknað FVn, þar sem n táknar viðkomandi tímabil.</p>

                                        <h3>Framtíðarvirði (e. future value)</h3>
                                        <p><strong>Ávöxtun</strong> (e. compounding) kallast sú aðgerð að reikna framtíðarvirði (FV<sub>n</sub>) miðað við gefið núvirði (PV) og gefna vexti. <strong>Framtíðarvirði</strong> (FV<sub>n</sub>) einstakrar greiðslu er:</p>

                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="3.1"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(FV_n = PV \\cdot (1+i)^n\\)`}
                                                    </MathJax>
                                                }
                                            />

                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>FV<sub>n</sub></strong> = framtíðarvirði tímabils n</li>
                                                <li><strong>PV</strong> = núvirði</li>
                                                <li><strong>i</strong> = vextir</li>
                                                <li><strong>n</strong> = fjöldi tímabila</li>
                                            </ul>
                                        </div>

                                        <h4>Dæmi 3-1</h4>
                                        <p>Gunnar ætlar að ávaxta 100.000 kr. í tvö ár. Hann gerir kröfu um að fá 5% ávöxtun á ári. Hvaða fjárhæð fær hann eftir tvö ár?</p>
                                        <Accordion
                                            items={[{
                                                id: 'solution3-1',
                                                title: 'Lausnin',
                                                content: <p>Eftir tvö ár fær hann 100.000 &#183; (1 + 0,05)<sup>2</sup> = 110.250 kr.</p>
                                            }]}
                                        />

                                        <h3>Núvirði (e. present value)</h3>
                                        <p><strong>Afvöxtun</strong> (e. discounting) kallast sú aðgerð að reikna núvirði (PV) miðað við gefið framtíðarvirði (FV<sub>n</sub>) og gefna <strong>fórnarvexti</strong> (e. opportunity cost rate), en það eru vextir af besta mögulega fjárfestingarvalkosti með jafn mikla áhættu. <strong>Núvirði</strong> (PV) einstakrar greiðslu er:</p>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="3.2"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(PV = \\frac{FV_n}{(1+i)^n}\\)`}
                                                    </MathJax>
                                                }
                                            />

                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>FV<sub>n</sub></strong> = framtíðarvirði tímabils n</li>
                                                <li><strong>PV</strong> = núvirði</li>
                                                <li><strong>i</strong> = fórnarvextir</li>
                                                <li><strong>n</strong> = fjöldi tímabila</li>
                                            </ul>
                                        </div>

                                        <h4>Dæmi 3-2</h4>
                                        <p>Magnús þarf að greiða 100.000 kr. eftir tvö og hálft ár. Ef vextir á markaðnum verða 5% á tímabilinu, hvað þarf hann að leggja fyrir í dag til að eiga fyrir greiðslunni?</p>
                                        <Accordion
                                            items={[{
                                                id: 'solution3-2',
                                                title: 'Lausnin',
                                                content: <p>Hann þarf að leggja fyrir 100.000/((1 + 0,05)<sup>2,5</sup>) &#8776; 92.063 kr.</p>
                                            }]}
                                        />

                                        <h3>Jafngreiðslur (e. annuity)</h3>
                                        <p>Jafngreiðslur eru skilgreindar sem runa af jafnháum greiðslum, sem inntar eru af hendi með reglulegu millibili, í tiltekinn tíma. <strong>Venjulegar jafngreiðslur</strong> (e. ordinary annuity) eiga sér stað í enda tímabils, en <strong>framsettar jafngreiðslur</strong> (e. annuity due) eiga sér stað í byrjun tímabils. <strong>Eilífðar jafngreiðslur</strong> (e. perpetuity) er straumur jafnra greiðslna sem er áætlað að eigi sér stað að eilífu.</p>

                                        <h4>Framtíðarvirði jafngreiðsluraðar:</h4>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="3.3"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(FVA_n = PMT \\cdot \\displaystyle\\sum_{t=1}^n (1 + i)^{n-t} = PMT \\cdot (\\frac{(1+i)^n - 1}{i})\\)`}
                                                    </MathJax>
                                                }
                                            />

                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>FVA<sub>n</sub></strong> = framtíðarvirði jafngreiðsluraðar í n tímabil</li>
                                                <li><strong>PMT</strong> = jafngreiðsla</li>
                                                <li><strong>i</strong> = vextir</li>
                                                <li><strong>n</strong> = fjöldi tímabila</li>
                                            </ul>
                                        </div>

                                        <h4>Núvirði jafngreiðsluraðar:</h4>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="3.4"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(PVA_n = PMT \\cdot (\\frac{1 - (1+i)^{-n}}{i})\\)`}
                                                    </MathJax>
                                                }
                                            />

                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>PVA<sub>n</sub></strong> = núvirði jafngreiðsluraðar í n tímabil</li>
                                                <li><strong>PMT</strong> = jafngreiðsla</li>
                                                <li><strong>i</strong> = vextir</li>
                                                <li><strong>n</strong> = fjöldi tímabila</li>
                                            </ul>
                                        </div>

                                        <h4>Núvirði eilífðar jafngreiðslna (e. perpetuity):</h4>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="3.5"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(PVP = \\frac{PMT}{i}\\)`}
                                                    </MathJax>
                                                }
                                            />

                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>PVP</strong> = núvirði eilífðar jafngreiðslna</li>
                                                <li><strong>PMT</strong> = jafngreiðsla</li>
                                                <li><strong>i</strong> = vextir</li>
                                            </ul>
                                        </div>

                                        <Alert
                                            type='info'
                                            headText='Sett upp í Microsoft &#174; Excel'
                                            text={<>
                                                <p>Stærðir sem notaðar eru í útreikninum á tímavirði peninga er hægt að reikna með eftirfarandi föllum:</p>
                                                <ul className="not-blue">
                                                    <li>NPER (= n) reiknar fjölda tímabila.</li>
                                                    <li>RATE (= i) reiknar tímabilsvexti.</li>
                                                    <li>PV reiknar núvirði greiðslna.</li>
                                                    <li>PMT reiknar jafngreiðslu.</li>
                                                    <li>FV reiknar framtíðarvirði.</li>
                                                </ul>
                                                <p>Í hverju þessara fimm falla geta gildi hinna fjögurra verið breytistærðir.</p>
                                            </>}
                                        />
                                    </ScrollSpySection>
                                    <ScrollSpySection className="article__section" id="ahaetta-og-avoxtun" label="Áhætta og ávöxtun">
                                        <h2>Áhætta og ávöxtun</h2>
				                        <p>Harry Markowitz lýsti sambandi áhættu og ávöxtunar í greininni Portfolio Selection árið 1952 og hefur síðan oft verið nefndur <strong>faðir safnakenninganna</strong> (e. modern portfolio theory, MPT). Í greininni lýsti hann því hvernig líta mætti á áhættu í eignasafni sem <strong>sveiflur í ávöxtun</strong> og að hana mætti mæla með staðalfráviki (e. standard deviation) í ávöxtun eignasafnsins.</p>
                                        
                                        <h3>Áhætta verðbréfa</h3><p>Áhættu af stöku verðbréfi er hægt að skipta upp í tvo hluta; (1) <strong>markaðsáhættu</strong> (e. market risk) og (2) <strong>fyrirtækjaáhættu</strong> (e. company-specific risk). Fjárfestar geta varist fyrirtækjaáhættunni með því að velja og fjárfesta í vel dreifðu verðbréfasafni. Áhætta hvers verðbréfasafns (e. portfolio risk, σ<sub>p</sub>) minnkar þess vegna þegar fjöldi vel dreifðra verðbréfa í safninu eykst og stefnir á meðaláhættuna á markaðinum eða markaðsáhættuna (e. market risk, σ<sub>M</sub>) eins og sést á mynd 4–1.</p>
				                        <p>Þegar stöku verðbréfi er bætt við vel dreift verðbréfasafn, hefur því aðeins markaðsáhætta bréfsins áhrif á safnið. Markaðsáhættu verðbréfs er hægt að mæla með <strong>beta stuðli</strong> (b), en betan sýnir hversu mikið verð á verðbréfi breytist í hlutfalli við sveiflur á markaðinum í heild.</p>
                                        <img
                                            src="https://cdn.livemarketdata.com/KeldanImages/financeref/img-02.png"
                                            alt="Beta stuðull"
                                            width="1449"
                                            height="741"
                                        />
                                        <p>Betagildi <strong>markaðssafnsins</strong> (e. market portfolio), sem er safn allra verðbréfa á markaðinum, er ávallt 1, en beta einstakra verðbréfa er hægt að finna með einfaldri <strong>aðhvarfsgreiningu</strong> (e. regression analysis). Betagildi er skilgreint sem línulegur metill þeirrar greiningar.</p>
                                        
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="4.1"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(b_i = (\\frac{\\sigma_i}{\\sigma_M}) \\cdot \\rho_{iM}\\)`}
                                                    </MathJax>
                                                }
                                            />

                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>b<sub>i</sub></strong> = betagildi fyrir verðbréf i</li>
                                                <li><strong>&sigma;<sub>i</sub></strong> = staðalfrávik á ávöxtun verðbréfs i</li>
                                                <li><strong>&sigma;<sub>M</sub></strong> = staðalfrávik á ávöxtun markaðssafnsins</li>
                                                <li><strong>&rho;<sub>iM</sub></strong> = fylgni á milli ávöxtunar verðbréfs i og ávöxtunar markaðssafnsins</li>
                                            </ul>
                                        </div>

                                        <h3>Samband áhættu og ávöxtunar</h3>
				                        <p>Best þekkta og mest notaða líkanið til að lýsa sambandinu á milli áhættu og ávöxtunar er fjármunaverðlíkanið (e. capital asset pricing model, CAPM), en það skýrir fræðilegt samband markaðsáhættu, mældrar með betu, og ávöxtunarkröfu.</p>
                                        
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="4.2"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(r_i = r_{RF} + (r_M - r_{RF}) \\cdot b_i = r_{RF} + (RP_M) \\cdot b_i\\)`}
                                                    </MathJax>
                                                }
                                            />

                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>r<sub>i</sub></strong> = ávöxtunarkrafa verðbréfs i</li>
                                                <li><strong>r<sub>M</sub></strong> = ávöxtunarkrafa markaðssafnsins</li>
                                                <li><strong>r<sub>RF</sub></strong> = vextir af ríkisvíxlum (e. risk-free rate of return)</li>
                                            </ul>
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>RP<sub>M</sub></strong> = r<sub>M</sub> - r<sub>RF</sub> = áhættuþóknun markaðarins</li>
                                                <li><strong>b<sub>i</sub></strong> = betagildi verðbréfs i</li>
                                                <li><strong>(r<sub>M</sub> - r<sub>RF</sub>) &#183; b<sub>i</sub></strong> = áhættuþóknun verðbréfs i</li>
                                            </ul>
                                        </div>

                                        <h4>Dæmi 4-1</h4>
                                        <p>Vextir af ríkisvíxlum eru 6% og áhættuþóknun markaðarins er 5%. Hlutabréf hefur betagildi 0,5. Hver er ávöxtunarkrafa bréfsins?</p>
                                        <Accordion
                                            items={[{
                                                id: 'solution4-1',
                                                title: 'Lausnin',
                                                content: <p>Ávöxtunarkrafan er 6% + 5% &#183; 0,5 = 8,5%</p>
                                            }]}
                                        />

                                        <p>Út frá jöfnu 4–2 er hægt er teikna svokallaða <strong>verðbréfamarkaðslínu</strong> (e. security market line, SML) sem lýsir ávöxtunarkröfu verðbréfs i sem falli af betagildi bréfsins:</p>
                                        <img
                                            src="https://cdn.livemarketdata.com/KeldanImages/financeref/img-03.png"
                                            alt="Verðbréfamarkaðslína"
                                            width="1447"
                                            height="896"
                                        />

                                        <p>Á mynd 4–1 eru vextir af ríkisvíxlum 6% og áhættuþóknun markaðarins 5% og ávöxtunarkrafa markaðssafnsins því 11%. Eins og í dæminu hér að ofan sést einnig hvernig ávöxtunarkrafa bréfsins er 8,5% þegar betagildið er 0,5. Þar að auki má sjá hvernig áhættuþóknun bréfs með betagildi 2 væri 10% og ávöxtunarkrafan því 16%.</p>

                                        <h3>Hagkvæm verðbréfasöfn (e. efficient portfolios)</h3>
                                        <p>Verðbréfasafn er sagt <strong>hagkvæmt</strong> eða skilvirkt ef það er þannig samsett að ekki er hægt að auka ávöxtun án þess að auka um leið áhættu og ekki er heldur hægt að draga úr áhættu án þess að minnka um leið ávöxtun.</p>
                                        
                                        <img
                                            src="https://cdn.livemarketdata.com/KeldanImages/financeref/img-04.png"
                                            alt="Fjármagnsmarkaðslínan"
                                            width="1447"
                                            height="907"
                                        />

                                        <p>Gula svæðið á mynd 4–2 sýnir öll <strong>möguleg söfn</strong> (e. feasible set) verðbréfanna A, H, G, og E. Kúrfan BNME sýnir svokallaða <strong>framlínukúrfu</strong> (e. efficient frontier), en á henni er að finna öll <strong>hagkvæm verðbréfasöfn</strong> (e. efficient portfolios) þessara fjögurra verðbréfa. Allar samsetningar af áhættu og ávöxtun sem veita fjárfesti með ákveðið <strong>áhættuþol</strong> (e. risk tolerance) sömu nytsemi má lýsa með jafngildislínunni I<sub>n</sub>. Ef aðeins verðbréfin fjögur stæðu fjárfestinum til boða myndi hann því hámarka nytsemi sína með því að fjárfesta í verðbréfasafninu N, sem kallast hans <strong>besta safn</strong> (e. optimal portfolio). Ef þessi sami fjárfestir gæti líka keypt áhættulausa ríkisvíxla sem eru í punktinum r<sub>RF</sub>, þá gæti hann aukið nytsemi sína enn frekar með því að kaupa verðbréfasafnið R, sem er blanda af verðbréfasafni M og ríkisvíxlum, enda færist hann þannig úr jafngildislínunni I<sub>1</sub> yfir í I<sub>2</sub></p>
				                        <p>Línan r<sub>RF</sub>MZ er kölluð <strong>fjármagnsmarkaðslína</strong> (e. capital market line, CML) og er hún alltaf hærri en framlínukúrfan, nema í snertipunktinum M. Þess vegna er hagkvæmast fyrir alla fjárfesta að eiga verðbréfasafnið M og kaupa eða selja ríkisvíxla eftir því hversu mikla áhættu þeir þola. Fjármagnsmarkaðslínunni má lýsa með eftirfarandi jöfnu:</p>
                                        
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="4.3"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(r_p = r_{RF} + (\\frac{r_M - r_{RF}}{\\sigma_M}) \\cdot \\sigma_p\\)`}
                                                    </MathJax>
                                                }
                                            />

                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>r<sub>p</sub></strong> = vænt ávöxtun verðbréfasafns p</li>
                                                <li><strong>r<sub>M</sub></strong> = vænt ávöxtun markaðssafnsins</li>
                                                <li><strong>r<sub>RF</sub></strong> = vextir af ríkisvíxlum (e. risk-free rate of return)</li>
                                            </ul>
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>&sigma;<sub>p</sub></strong> = staðalfrávik á ávöxtun verðbréfasafns p</li>
                                                <li><strong>&sigma;<sub>M</sub></strong> = staðalfrávik á ávöxtun markaðssafnsins</li>
                                            </ul>
                                        </div>

                                        <h3>Fama-French three-factor model</h3>
				                        <p>CAPM verðlíkanið sem hér hefur verið lýst notar aðeins eina breytistærð, betuna, til að meta markaðsáhættu verðbréfa. Verðlíkan Eugene Fama og Kenneth French notar hins vegar þrjár breytistærðir, en til viðbótar við áhættuþóknun markaðarins byggjast þær einnig á <strong>markaðsvirði</strong> og <strong>I/V hlutfalli</strong> (sjá 7. hluta um kennitölur) fyrirtækisins.</p>
                                        
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="4.4"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(r_i = r_{RF} + a_i + (r_M - r_{RF}) \\cdot b_i + (r_{SMB}) \\cdot c_i + (r_{HML}) \\cdot d_i\\)`}
                                                    </MathJax>
                                                }
                                            />

                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>r<sub>i</sub></strong> = ávöxtunarkrafa verðbréfs i</li>
                                                <li><strong>r<sub>RF</sub></strong> = vextir af ríkisvíxlum (e. risk-free rate of return)</li>
                                                <li><strong>a<sub>i</sub></strong> = alfa verðbréfs i</li>
                                            </ul>
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>r<sub>M</sub></strong> = ávöxtunarkrafa markaðssafnsins</li>
                                                <li><strong>r<sub>SMB</sub></strong> = ávöxtunarkrafa fyrirtækja með lágt markaðsvirði umfram þeirra með hátt</li>
                                                <li><strong>r<sub>HML</sub></strong> = ávöxtunarkrafa fyrirtækja með hátt I/V hlutfall umfram þeirra með lágt</li>
                                            </ul>
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>c<sub>i</sub></strong> og <strong>d<sub>i</sub></strong> = metlar sem fundnir eru með einfaldri aðhvarfsgreiningu</li>
                                            </ul>
                                        </div>

                                        <h3>Önnur verðlíkön</h3>
				                        <p>Hagfræðingurinn Steven Ross hjá MIT þróaði verðlíkan sem kallast <strong>Arbitrage Pricing Theory</strong>, en það byggist á því að nota eins margar breytistærðir til að meta áhættu og þörf er á hverju sinni. Greinar innan <strong>atferlisfjármála</strong> (e. behavioral finance) byggjast jafnframt á því að verðmeta verðbréf út frá sálfræðilegum greiningum á órökrænni en fyrirsjáanlegri hegðun fjárfesta við ýmsar aðstæður.</p>
                                    </ScrollSpySection>
                                    <ScrollSpySection className="article__section" id="skuldabref" label="Skuldabréf">
                                        <h2>Skuldabréf</h2>
                                        <p>Skuldabréf eru mikilvægasti flokkur verðbréfa hvar sem er í heiminum, enda mótast markaðsvextir hvers lands af viðskiptum með þau í samræmi við lögmál framboðs og eftirspurnar. Skuldabréf eins og önnur verðbréf eru í upphafi seld á <strong>frummarkaði</strong> (e. primary market), en þar sem þau eru framseljanleg ganga þau kaupum og sölum á <strong>eftirmarkaði</strong> (e. secondary market) eftir að þau hafa verið gefin út.</p>
				
                                        <h3>Tegundir skuldabréfa</h3>

                                        <h4>Eingreiðslubréf (e. zero-coupon bond)</h4>
                                        <p>Bera ekki nafnvexti. Endurgreiðast með einni greiðslu höfuðstóls í lok lánstímans.</p>

                                        <p><strong>Dæmi:</strong> Eingreiðslubréf til 10 ára selt á genginu 60.</p>
                                        <img
                                            src="https://cdn.livemarketdata.com/KeldanImages/financeref/img-05.png"
                                            alt="Eingreiðslubréf"
                                            width="1447"
                                            height="787"
                                        />

                                        <h4>Kúlubréf (e. bullet bond)</h4>
                                        <p>Bera nafnvexti sem leggjast reglulega við höfuðstólinn. Endurgreiðast með einni greiðslu upphaflegs höfuðstóls, vaxta og vaxtavaxta.</p>

                                        <p><strong>Dæmi:</strong> Kúlubréf til 10 ára með 5% nafnvöxtum selt á pari.</p>
                                        <img
                                            src="https://cdn.livemarketdata.com/KeldanImages/financeref/img-06.png"
                                            alt="Kúlubréf"
                                            width="1447"
                                            height="785"
                                        />

                                        <h4>Vaxtagreiðslubréf (e. coupon bond)</h4>
                                        <p>Áunnir vextir sérhvers tímabils eru greiddir í lok hvers tímabils. Engin afborgun af höfuðstól fyrr en á lokagjalddaga.</p>

                                        <p><strong>Dæmi:</strong> Vaxtagreiðslubréf til 10 ára með árlegum greiðslum og 20% nafnvöxtum selt á genginu 90.</p>
                                        <img
                                            src="https://cdn.livemarketdata.com/KeldanImages/financeref/img-07.png"
                                            alt="Vaxtagreiðslubréf"
                                            width="1447"
                                            height="784"
                                        />

                                        <h4>Jafngreiðslubréf (e. annuity)</h4>
                                        <p>Allar greiðslur á bréfinu jafn háar. Greiðsla = afborgun + áunnir vextir tímabils.</p>

                                        <p><strong>Dæmi:</strong> Jafngreiðslubréf til 10 ára með 10% nafnvöxtum og selt á genginu 85.</p>
                                        <img
                                            src="https://cdn.livemarketdata.com/KeldanImages/financeref/img-08.png"
                                            alt="Jafngreiðslubréf"
                                            width="1447"
                                            height="784"
                                        />

                                        <h4>Bréf með föstum afborgunum (e. fixed principal bond)</h4>
                                        <p>Allar afborganir höfuðstóls jafn háar. Áunnir vextir sérhvers tímabils greiddir í lok tímabils.</p>

                                        <p><strong>Dæmi:</strong> Bréf með jöfnum afborgunum til 10 ára með 12% nafnvöxtum og selt á genginu 80.</p>
                                        <img
                                            src="https://cdn.livemarketdata.com/KeldanImages/financeref/img-09.png"
                                            alt="Bréf með föstum afborgunum"
                                            width="1447"
                                            height="782"
                                        />

                                        <h4>Eilífðarbréf (e. perpetuity)</h4>
                                        <p>Eilífðarbréf bera fastar reglulegar vaxtagreiðslur að eilífu. Höfuðstóllinn verður aldrei endurgreiddur.</p>

                                        <p><strong>Dæmi:</strong> Eilífðarbréf með 20% nafnvöxtum selt á pari.</p>
                                        <img
                                            src="https://cdn.livemarketdata.com/KeldanImages/financeref/img-10.png"
                                            alt="Eilífðarbréf"
                                            width="1447"
                                            height="785"
                                        />

                                        <h4>Verðtryggð skuldabréf (e. inflation-indexed bonds)</h4>
                                        <p>Ef skuldabréf er óverðtryggt eru ákvæðisvextir nafnvextir bréfsins. Verðbólga hefur því ekki áhrif á fjárstreymi slíkra bréfa. Ef skuldabréf er hins vegar verðtryggt eru ákvæðisvextir raunvextir bréfsins og vaxta- og höfuðstólsgreiðslur tengdar vísitölu neysluverðs (e. consumer price index). Verðbólga hefur því áhrif á fjárstreymi slíkra bréfa. Með verðtryggingu er tryggt að endurgreiðslur í framtíðinni haldi verðgildi sínu frá þeim degi, sem lán er veitt eða sparnaður hefst.</p>

                                        <h3>Virðismat skuldabréfa</h3>
                                        <p>Virði skuldabréfa er reiknað á sama hátt og virði fyrirtækja í jöfnu 1–1, þ.e. að núvirða áætlað fjárstreymi til dagsins í dag miðað við ákveðna ávöxtunarkröfu.</p>

                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="5.1"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(V_B = \\displaystyle\\sum_{t=1}^n \\frac{INT}{(1+r_d)^t} + \\frac{M}{(1+r_d)^n} = INT \\cdot (\\frac{1 - (1+r_d)^{-n}}{r_d}) + \\frac{M}{(1+r_d)^n}\\)`}
                                                    </MathJax>
                                                }
                                            />

                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>V<sub>B</sub></strong> = virði skuldabréfs</li>
                                                <li><strong>INT</strong> = vaxtagreiðslur bréfs á hverju tímabili</li>
                                                <li><strong>r<sub>d</sub></strong> = ávöxtunarkrafa skuldabréfsins</li>
                                            </ul>
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>M</strong> = nafnverð bréfs</li>
                                                <li><strong>n</strong> = fjöldi tímabila</li>
                                            </ul>
                                        </div>

                                        <h4>Dæmi 5-1</h4>
                                        <p>Áhættusæknir ehf. gefa út vaxtagreiðslubréf til 15 ára með árlegum greiðslum og 10% nafnvöxtum. Nafnverð bréfsins er 1.000.000 kr. og ávöxtunarkrafan 15%. Hvert er virði bréfsins?</p>
                                        <Accordion
                                            items={[{
                                                id: 'solution5-1',
                                                title: 'Lausnin',
                                                content: <p>Árleg vaxtagreiðsla er 10% &#183; 1.000.000 = 100.000 kr. og fyrirtækið getur því selt bréfið fyrir 100.000 &#183; ((1 - (1+0,15)<sup>-15</sup>)/0,15) + 1.000.000 / (1+0,15)<sup>15</sup> &#8776; 707.632 kr.</p>
                                            }]}
                                        />

                                        <Alert
                                            type='info'
                                            headText='Sett upp í Microsoft &#174; Excel'
                                            text={
                                                <ul>
                                                    <li>Dæmi 5–1 má einnig leysa með PV fallinu í Excel á eftirfarandi hátt:</li>
                                                    <li>=PV(rate; nper; pmt; fv)</li>
                                                    <li>=PV(15%; 15; -100000; -1000000)</li>
                                                    <li>RATE er ávöxtunarkrafan, NPER tímabilafjöldinn, PMT vaxtagreiðslan og FV nafnverð bréfsins.</li>
                                                </ul>
                                            }
                                        />

                                        <p>Þegar ávöxtunarkrafa skuldabréfa er hærri en nafnvextir þeirra, eins og hjá Áhættusæknum ehf., seljast þau með <strong>afföllum</strong> (e. bond discount). Þegar krafan er hins vegar lægri, þá seljast bréfin á <strong>yfirverði</strong> (e. bond premium).</p>

                                        <h3>Ávöxtun til lokagjalddaga (e. yield to maturity, YTM)</h3>
                                        <p>Sú ávöxtun sem vænst er af skuldabréfi ef því er haldið til lokagjalddaga.</p>

                                        <h4>Dæmi 5-2</h4>
                                        <p>Áhættufælnir ehf. gefa út vaxtagreiðslubréf til 15 ára með árlegum greiðslum og 10% nafnvöxtum. Nafnverð bréfsins er 1.000.000 kr., en bréfið er selt fyrir 1.518.983 kr. Hver er ávöxtun til lokagjalddaga?</p>
                                        <Accordion
                                            items={[{
                                                id: 'solution5-2',
                                                title: 'Lausnin',
                                                content: <p>Ávöxtunarkrafan, r<sub>d</sub>, úr jöfnu 5–1 er nú fundin með notkun RATE fallsins í Excel. =RATE(nper; pmt; pv; fv) =RATE(15; -100000; 1518983; -1000000) skilar <strong>5% ávöxtunarkröfu,</strong> sem er ávöxtunin til lokagjalddaga.</p>
                                            }]}
                                        />

                                        <p>Ólíkt skuldabréfum Áhættusækinna ehf. er ávöxtunarkrafan á skuldabréfum Áhættufælinna ehf. lægri en nafnvextirnir, enda eru bréf þeirra seld á <strong>yfirverði</strong>.</p>

                                        <h3>Ávöxtun til innköllunar (e. yield to call, YTC)</h3>
				                        <p>Skuldabréf með <strong>innköllunarákvæði</strong> (e. call provision) heimilar útgefanda að kalla bréfin inn og greiða upp lán fyrir lokagjalddaga. Ef vextir lækka, þá getur útgefandi þannig endurfjármagnað sig á betri kjörum, en gæti í staðinn þurft að greiða eiganda bréfsins <strong>uppgreiðslugjald</strong> (e. call premium) sem er oftast eins árs afborgun vaxta. <strong>Ávöxtun til innköllunar</strong> má finna með því að leysa r<sub>d</sub> út úr eftirfarandi jöfnu:</p>
                                        
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="5.2"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">
                                                        {`\\(V_B = \\displaystyle\\sum_{t=1}^n \\frac{INT}{(1+r_d)^t} + \\frac{Innköllunarverð}{(1+r_d)^n}\\)`}
                                                    </MathJax>
                                                }
                                            />

                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>V<sub>B</sub></strong> = virði skuldabréfs</li>
                                                <li><strong>INT</strong> = vaxtagreiðslur bréfs á hverju tímabili</li>
                                                <li><strong>r<sub>d</sub></strong> = ávöxtunarkrafa skuldabréfsins</li>
                                            </ul>
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>M</strong> = nafnverð bréfs</li>
                                                <li><strong>n</strong> = fjöldi tímabila</li>
                                                <li><strong>Innköllunarverð</strong> = M + uppgreiðslugjald</li>
                                            </ul>
                                        </div>

                                        <h4>Dæmi 5-3</h4>
                                        <p>Sveigjanlegir ehf. gefa út vaxtagreiðslubréf til 15 ára með árlegum greiðslum og 10% nafnvöxtum. Nafnverð bréfsins er 1.000.000 kr., en bréfið er selt fyrir 1.200.000 kr. Bréfið er innkallanlegt eftir 5 ár og uppgreiðslugjaldið 10% af höfuðstól.</p>
                                        <p><span style={{marginRight: '10px'}}>a) Hver er ávöxtun til lokagjalddaga?</span>
                                        <span>b) Hver er ávöxtun til innköllunar?</span></p>
                                        <Accordion
                                            items={[{
                                                id: 'solution5-3',
                                                title: 'Lausnin',
                                                content: <><p>a) Ávöxtunarkrafan, r<sub>d</sub>, úr jöfnu 5–1 er nú fundin með RATE fallinu. =RATE(15; -100000; 1200000; -1000000) skilar <strong>7,71% ávöxtunarkröfu</strong>, sem er ávöxtunin til lokagjalddaga.</p>
                                                            <p>b) Ávöxtunarkrafan, r<sub>d</sub>, úr jöfnu 5–2 er nú fundin með RATE fallinu. =RATE(5; -100000; 1200000; -1100000) skilar <strong>6,88% ávöxtunarkröfu</strong>, sem er ávöxtunin til innköllunar.</p></>
                                            }]}
                                        />

                                        <h3>Núverandi ávöxtun (e. current yield)</h3>
				                        <p>Skilgreind sem <strong>árleg vaxtagreiðsla</strong> á móti <strong>núverandi verði</strong>. Núverandi ávöxtun er því mælikvarði á fjárstreymi skuldabréfa, en tekur ekki tillit til <strong>gengisávöxtunar</strong> (e. capital gains yield). Heildar ávöxtunarkrafa skuldabréfa, r<sub>d</sub>, eins og hún kemur fyrir í jöfnu 5-1, er því samsett úr bæði <strong>núverandi ávöxtun</strong> og <strong>gengisávöxtun</strong>.</p>

                                        <p>Lánshæfismatsstofnanir meta skuldaraáhættu útgefenda og gefa þeim einkunnir byggða á sínum athugunum. Þrjár stærstu stofnanirnar af þessu tagi eru reknar af (1) <strong>Standard & Poor‘s,</strong> (2) <strong>Moody‘s Corporation,</strong> og (3) <strong>Fitch Group.</strong> Hér má sjá hvernig einkunnir þessara stofnana eru skilgreindar:</p>

                                        <div className="table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2}>Moody's</th>
                                                        <th colSpan={2}>S&P</th>
                                                        <th colSpan={2}>Fitch</th>
                                                        <th rowSpan={2} className="right-column">Lýsing á ensku</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Langtíma</th>
                                                        <th>Skammtíma</th>
                                                        <th>Langtíma</th>
                                                        <th>Skammtíma</th>
                                                        <th>Langtíma</th>
                                                        <th>Skammtíma</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Aaa</td>
                                                        <td rowSpan={6}>P-1</td>
                                                        <td>AAA</td>
                                                        <td rowSpan={4}>A-1+</td>
                                                        <td>AAA</td>
                                                        <td rowSpan={4}>F1+</td>
                                                        <td>Prime</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Aa1</td>
                                                        <td>AA+</td>
                                                        <td>AA+</td>
                                                        <td rowSpan={3}>High grade</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Aa2</td>
                                                        <td>AA</td>
                                                        <td>AA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Aa3</td>
                                                        <td>AA-</td>
                                                        <td>AA-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>A1</td>
                                                        <td>A+</td>
                                                        <td rowSpan={2}>A-1</td>
                                                        <td>A+</td>
                                                        <td rowSpan={2}>F1</td>
                                                        <td rowSpan={3}>Upper medium grade</td>
                                                    </tr>
                                                    <tr>
                                                        <td>A2</td>
                                                        <td>A</td>
                                                        <td>A</td>
                                                    </tr>
                                                    <tr>
                                                        <td>A3</td>
                                                        <td rowSpan={2}>P-2</td>
                                                        <td>A-</td>
                                                        <td rowSpan={2}>A-2</td>
                                                        <td>A</td>
                                                        <td rowSpan={2}>F2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Baa1</td>
                                                        <td>BBB+</td>
                                                        <td>BBB+</td>
                                                        <td rowSpan={3}>Lower medium grade</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Baa2</td>
                                                        <td rowSpan={2}>P-3</td>
                                                        <td>BBB</td>
                                                        <td rowSpan={2}>A-3</td>
                                                        <td>BBB</td>
                                                        <td rowSpan={2}>F3</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Baa3</td>
                                                        <td>BBB-</td>
                                                        <td>BBB</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ba1</td>
                                                        <td rowSpan={14}>Not prime</td>
                                                        <td>BB+</td>
                                                        <td rowSpan={6}>B</td>
                                                        <td>BB+</td>
                                                        <td rowSpan={6}>B</td>
                                                        <td rowSpan={3}>Non-investment grade / speculative / junk</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ba2</td>
                                                        <td>BB</td>
                                                        <td>BB</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ba3</td>
                                                        <td>BB-</td>
                                                        <td>BB-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>B1</td>
                                                        <td>B+</td>
                                                        <td>B+</td>
                                                        <td rowSpan={3}>Highly speculative</td>
                                                    </tr>
                                                    <tr>
                                                        <td>B2</td>
                                                        <td>B</td>
                                                        <td>B</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>B3</td>
                                                        <td>B-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Caa1</td>
                                                        <td>CCC+</td>
                                                        <td rowSpan={5}>C</td>
                                                        <td rowSpan={5}>CCC</td>
                                                        <td rowSpan={5}>C</td>
                                                        <td>Substantial risks</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Caa2</td>
                                                        <td>CCC</td>
                                                        <td>Extremely speculative</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Caa3</td>
                                                        <td>CCC</td>
                                                        <td rowSpan={3}>In default with little prospect for recovery</td>
                                                    </tr>
                                                    <tr>
                                                        <td rowSpan={2}>Ca</td>
                                                        <td>CC</td>
                                                    </tr>
                                                    <tr>
                                                        <td>C</td>
                                                    </tr>
                                                    <tr>
                                                        <td>C</td>
                                                        <td rowSpan={3}>D</td>
                                                        <td rowSpan={3}>/</td>
                                                        <td>DDD</td>
                                                        <td rowSpan={3}>/</td>
                                                        <td rowSpan={3}>In default</td>
                                                    </tr>
                                                    <tr>
                                                        <td>/</td>
                                                        <td>DD</td>
                                                    </tr>
                                                    <tr>
                                                        <td>/</td>
                                                        <td>DD</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </ScrollSpySection>
                                    <ScrollSpySection className="article__section" id="arsreikningurinn" label="Ársreikningurinn">
                                        <h2>Ársreikningurinn</h2>
                                        <p>Efnahagsreikningum fyrirtækja er gjarnan lýst myndrænt eins og á mynd 6–1, en þar sést hvernig <strong>eignir = skuldir + eigið fé</strong> og að eignum megi skipta í veltufjármuni (VF) og fastafjármuni (FF) og skuldum í skammtímaskuldir (SS) og langtímaskuldir (LS).</p>
                                        
                                        <img
                                            src="https://cdn.livemarketdata.com/KeldanImages/financeref/img-11.png"
                                            alt="Ársreikningurinn"
                                            width="2585"
                                            height="3370"
                                        />

                                        <p>Til að finna <strong>rekstrarfjármagn</strong> (e. operating capital, OC) er efnahagsreikningnum skipt upp eins og á mynd 6–2, en þar gildir að <strong>A+B = C+D</strong> og því einnig að <strong>A–C = D–B.</strong></p>
                                        
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="6.1"
                                                name="Rekstrarfjármagn (OC)"
                                                formula={
                                                    <span>A - C = Rekstrartengdar eignir - Rekstrartengdar skuldir</span>
                                                }
                                            />
                                            <FormulaBox
                                                label="6.2"
                                                name="Nettó fjármagn frá fjárfestum"
                                                formula={
                                                    <span>D - B = Brúttó fjármagn frá fjárfestum - Eignir ótengdar rekstri</span>
                                                }
                                            />
                                        </div>

                                        <p><strong>Rekstrarhagnaður eftir skatta</strong> (net operating profit after taxes, NOPAT) er sá hagnaður sem fyrirtæki myndi skila ef það hefði engar skuldir og ætti engar fjáreignir (e. financial assets).</p>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="6.3"
                                                name="Rekstrarhagnaður eftir skatta (NOPAT)"
                                                formula={
                                                    <span>EBIT·(1-skatthlutfall)</span>
                                                }
                                            />
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>EBIT</strong> = Rekstrarhagnaður (earnings before interests and taxes)</li>
                                            </ul>
                                        </div>

                                        <p>Það fjárstreymi sem hægt er að ráðstafa til allra fjárfesta eftir að fyrirtæki hefur fjárfest nægilega mikið í rekstrarfjármagni (OC) til að viðhalda áframhaldandi rekstri, kallast <strong>frjálst fjárstreymi</strong> (e. free cash flow, FCF).</p>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="6.4"
                                                name="Frjálst fjárstreymi (FCF)"
                                                formula={
                                                    <span>NOPAT - nettó fjárfestingar í OC = NOPAT - brúttó fjárfestingar í OC + afskriftir</span>
                                                }
                                            />
                                        </div>

                                        <p>Til að sjá hversu vel fyrirtæki nýtir rekstrarfjármagnið sitt til að skapa hagnað, þá er skoðuð <strong>arðsemi rekstrarfjármagns</strong> (e. return on invested capital, ROIC).</p>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="6.5"
                                                name="Arðsemi rekstrarfjármagns (ROIC)"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">{`\\(\\frac{NOPAT}{OC}\\)`}</MathJax>
                                                }
                                            />
                                        </div>

                                        <p>Í virðisstjórnun er einn algengasti mælikvarðinn <strong>hagrænn virðisauki</strong> (e. economic value added, EVA), en hann mælir áhrif fjárfestinga, fjármagnskostnaðar og rekstrarins á virði fyrirtækja.</p>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="6.6"
                                                name="Hagrænn virðisauki (EVA)"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">{`\\(NOPAT - (OC \\cdot WACC) = OC \\cdot (ROIC-WACC)\\)`}</MathJax>
                                                }
                                            />
                                        </div>

                                        <p>Veginn fjármagnskostnaður (WACC) verður skoðaður nánar í 8. hluta, en í jöfnu 6–6 sést hvernig <strong>fyrirtæki eykur virði sitt aðeins ef ROIC &gt; WACC.</strong></p>
                                    </ScrollSpySection>
                                    <ScrollSpySection className="article__section" id="kennitolur-i-arsreikningum" label="Kennitölur í ársreikningum">
                                        <h2>Kennitölur í ársreikningum</h2>
				                        <p>Kennitölum í ársreikningum verður hér skipt upp í fimm flokka. (1) Arðsemi, (2) greiðsluhæfi eða geta fyrirtækis til að greiða skuldir, (3) skuldsetningarhlutföll eða fjárhagslegur styrkur fyrirtækis og vaxtarmöguleikar, (4) nýting fjármagns, og (5) markaðsvirði.</p>
                                        
                                        <h3>Arðsemi (e. profitability ratios)</h3>

                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.1"
                                                name="Arðsemi eigin fjár (e. return on equity, ROE)"
                                                formula={
                                                    <span>Hagnaður/Meðalstaða eigin fjár</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.2"
                                                name="Arðsemi heildareigna (e. return on assets, ROA)"
                                                formula={
                                                    <span>Hagnaður/Meðalstaða eigna</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.3"
                                                name="Hagnaðarhlutfall (e. profit margin)"
                                                formula={
                                                    <span>Hagnaður/Sala</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.4"
                                                name="Brúttó ágóða hlutfall (e. gross profit margin)"
                                                formula={
                                                    <span>Brúttó ágóði/Sala</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.5"
                                                name="Arðgreiðsluhlutfall (e. payout ratio)"
                                                formula={
                                                    <span>Arður/Hagnaður</span>
                                                }
                                            />
                                        </div>

                                        <h3>Greiðsluhæfi (e. liquidity ratios)</h3>

                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.6"
                                                name="Veltufjárhlutfall (e. current ratio)"
                                                formula={
                                                    <span>Veltufjármunir/Skammtímaskuldir</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.7"
                                                name="Lausafjárhlutfall (e. quick ratio, acid-test)"
                                                formula={
                                                    <span>Kvikir veltufjármunir/Skammtímaskuldir</span>
                                                }
                                            />
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>Kvikir veltufjármunir</strong> = Veltufjármunir - Vörubirgðir</li>
                                            </ul>
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.8"
                                                name="Hlutfall handbærs fjár (e. cash ratio)"
                                                formula={
                                                    <span>(Handbært fé + skammtímaverðbréf)/Skammtímaskuldir</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.9"
                                                name="Sjóðstreymi á móti skammtímaskuldum (e. current cash debt coverage ratio)"
                                                formula={
                                                    <span>Handbært fé frá rekstri/Meðalstaða skammtímaskulda</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.10"
                                                name="Gæði hagnaðar (e. quality of earnings)"
                                                formula={
                                                    <span>Handbært fé frá rekstri/Hagnaður</span>
                                                }
                                            />
                                        </div>

                                        <h3>Skuldsetningarhlutföll (e. coverage ratios)</h3>

                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.11"
                                                name="Hlutfall skulda á móti eignum (e. debt to assets ratio)"
                                                formula={
                                                    <span>Heildarskuldir/Heildareignir</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.12"
                                                name="Eiginfjárhlutfall (e. equity ratio)"
                                                formula={
                                                    <span>Eigið fé/Heildareignir</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.13"
                                                name="Vaxtaþekja (e. times interest earned, TIE)"
                                                formula={
                                                    <span>(Rekstrarhagnaður (EBIT))/Vaxtagjöld</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.14"
                                                name="Skuldaþekja handbærs fjár frá rekstri (e. cash debt coverage ratio)"
                                                formula={
                                                    <span>Handbært fé frá rekstri/Meðalstaða heildarskulda</span>
                                                }
                                            />
                                        </div>

                                        <h3>Nýting fjármagns (e. activity ratios)</h3>

                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.15"
                                                name="Veltuhraði eigna (e. asset turnover ratio)"
                                                formula={
                                                    <span>Rekstrartekjur/Meðalstaða heildareigna</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.16"
                                                name="Veltuhraði birgða (e. inventory turnover ratio)"
                                                formula={
                                                    <span>Kostnaðarverð seldra vara/Meðalstaða birgða</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.17"
                                                name="Veltuhraði viðskiptakrafna (e. receivables turnover ratio)"
                                                formula={
                                                    <span>(Heildarsala - Staðgreiðslusala)/Meðalstaða viðskiptakrafna</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.18"
                                                name="Veltuhraði viðskiptaskulda (e. accounts payable turnover)"
                                                formula={
                                                    <span>Kostnaðarverð seldra vara/Meðalstaða viðskiptaskulda</span>
                                                }
                                            />
                                        </div>

                                        <h3>Markaðsvirði (e. market value ratios)</h3>

                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.19"
                                                name="V/H hlutfall (e. price/earnings ratio, P/E ratio)"
                                                formula={
                                                    <span>Markaðsverð hlutar/Hagnaður á hlut</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.20"
                                                name="V/I hlutfall (e. market/book ratio)"
                                                formula={
                                                    <span>Markaðsvirði/Eigið fé</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.21"
                                                name="Bókfært verð á hlut (e. book value per share, BVPS)"
                                                formula={
                                                    <span>Eigið fé almennra hluthafa/Fjöldi útistandandi almennra hluta</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.22"
                                                name="A/V hlutfall (e. dividend yield)"
                                                formula={
                                                    <span>Arðgreiðsla/Markaðsverð</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.23"
                                                name="Hagnaður á hlut (e. earnings per share, EPS)"
                                                formula={
                                                    <span>Hagnaður/Meðalstaða útistandandi hluta</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.24"
                                                name="Innra virði hlutafjár (e. internal value of shares)"
                                                formula={
                                                    <span>Eigið fé/Hlutafé</span>
                                                }
                                            />
                                        </div>
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="7.25"
                                                name="PEG hlutfall (e. price/earnings to growth ratio, PEG ratio)"
                                                formula={
                                                    <span>(V/H hlutfall)/Vöxtur hagnaðar í %</span>
                                                }
                                            />
                                        </div>
                                    </ScrollSpySection>
                                    <ScrollSpySection className="article__section" id="fjarmagnskostnadur" label="Fjármagnskostnaður">
                                        <h2>Fjármagnskostnaður</h2>
                                        <p><strong>Veginn fjármagnskostnaður</strong> (e. weighted average cost of capital, WACC) er meðalkostnaður fyrirtækja við öflun fjármagnsins sem þau nota til að standa undir rekstri.</p>

                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="8.1"
                                                name="Veginn fjármagnskostnaður (WACC)"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">{`\\(w_d r_d (1-T) + w_{ps} r_{ps} + w_{ce} r_s\\)`}</MathJax>
                                                }
                                            />
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>w<sub>d</sub></strong>, <strong>w<sub>ps</sub></strong> og <strong>w<sub>ce</sub></strong> = vogtölur fyrir skuldir (e. debt, d), forgangshlutabréf (e. preferred stock, ps) og hlutafé (e. common equity, ce). Því gildir að w<sub>d</sub> + w<sub>ps</sub> + w<sub>ce</sub> = 1</li>
                                                <li><strong>r<sub>d</sub></strong> = ávöxtunarkrafa skuldabréfa</li>
                                                <li><strong>T</strong> = skatthlutfall</li>
                                            </ul>
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>r<sub>ps</sub></strong> = ávöxtunarkrafa forgangshlutabréfa</li>
                                                <li><strong>r<sub>s</sub></strong> = ávöxtunarkrafa hlutafjár</li>
                                            </ul>
                                        </div>

                                        <p>Hægt er að reikna r<sub>s</sub> á ýmsa vegu, en hér verða nefndar tvær leiðir. Annars vegar að nota <strong>fjármunaverðlíkanið</strong> (CAPM) og beita jöfnu <strong>verðbréfamarkaðslínunnar</strong> (SML) (sjá jöfnu 4–2):</p>
                                        
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="8.2"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">{`\\(r_s = r_{RF} + (r_M - r_{RF}) \\cdot b_s = r_{RF} + (RP_M) \\cdot b_s\\)`}</MathJax>
                                                }
                                            />
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>r<sub>s</sub></strong> = ávöxtunarkrafa hlutabréfs s</li>
                                                <li><strong>r<sub>M</sub></strong> = ávöxtunarkrafa markaðssafnsins</li>
                                                <li><strong>r<sub>RF</sub></strong> = vextir af ríkisvíxlum (e. risk-free rate of return)</li>
                                            </ul>
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>RPM</strong> = r<sub>M</sub> - r<sub>RF</sub> = áhættuþóknun markaðarins</li>
                                                <li><strong>b<sub>s</sub></strong> = betagildi hlutabréfs s</li>
                                                <li><strong>(r<sub>M</sub> - r<sub>RF</sub> &#183; b<sub>s</sub></strong> = áhættuþóknun hlutabréfs s</li>
                                            </ul>
                                        </div>

                                        <p>Hins vegar að nota <strong>vaxtarlíkan Gordons</strong> (e. Gordon growth model) og beita eftirfarandi jöfnu:</p>

                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="8.3"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">{`\\(r_s = \\frac{D_0 \\cdot (1+g)}{P_0} + g = \\frac{D_1}{P_0} + g\\)`}</MathJax>
                                                }
                                            />
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>D<sub>0</sub></strong> = síðasta arðgreiðsla</li>
                                                <li><strong>D<sub>1</sub></strong> = vænt arðgreiðsla eftir 1 ár</li>
                                                <li><strong>P<sub>0</sub></strong> = verð á hlutabréfi s í dag</li>
                                                <li><strong>g</strong> = væntur vöxtur arðgreiðslna</li>
                                            </ul>
                                        </div>

                                        <h4>Dæmi 8-1</h4>
                                        <p>Hlutabréf í Stórfyrirtæki hf. eru metin á 30 kr. á hlut og greiddi það út arð að fjárhæð 3 kr. á hlut á síðasta ári. Væntur vöxtur arðgreiðslna er 5%. <br />Hver er ávöxtunarkrafa hlutabréfa í Stórfyrirtæki hf.?</p>
                                        <Accordion
                                            items={[{
                                                id: 'solution8-1',
                                                title: 'Lausnin',
                                                content: <p>Notum vaxtalíkan Gordons. D<sub>0</sub> = 3 kr. og D<sub>1</sub> = 3 &#183; (1+5%) = 3,15 kr. Ávöxtunarkrafa hlutabréfa, r<sub>s</sub>, er því: r<sub>s</sub> = 3,15/30 + 5% = 15,5%</p>
                                            }]}
                                        />

                                        <p>Hægt er að afla eigin fjár t.d. með því að <strong>kyrrsetja hagnað</strong> fyrri ára eða <strong>afla nýs hlutafjár á hlutabréfamarkaði.</strong> Seinni leiðinni fylgir þó viðskiptakostnaður (e. floatation cost, F), sem þarf að taka tillit til við útreikning á `r_s`.</p>
                                        
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="8.4"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">{`\\(r_e = \\frac{D_1}{P_0 (1-F)} + g\\)`}</MathJax>
                                                }
                                            />
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>r<sub>e</sub></strong> = ávöxtunarkrafa nýs hlutafjár (e. external equity, e)</li>
                                                <li><strong>D<sub>1</sub></strong> = vænt arðgreiðsla eftir 1 ár</li>
                                                <li><strong>P<sub>0</sub></strong> = verð á hlutabréfi s í dag</li>
                                            </ul>
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>F</strong> = viðskiptakostnaður</li>
                                                <li><strong>g</strong> = væntur vöxtur arðgreiðslna</li>
                                            </ul>
                                        </div>
                                    </ScrollSpySection>
                                    <ScrollSpySection className="article__section" id="verdmat-fyrirtaekja" label="Verðmat fyrirtækja">
                                        <h2>Verðmat fyrirtækja</h2>
                                        <p>Eins og mynd 6–2 sýnir skiptast eignir fyrirtækja í <strong>A, rekstrartengdar eignir</strong> (e. operating assets) og <strong>B, eignir ótengdar rekstri</strong> (e. non-operating assets). <strong>Virði rekstrarins</strong> (e. value of operations, V<sub>op</sub>) er summa alls frjálsa fjárstreymisins (FCF) frá <strong>rekstrartengdu eignunum</strong> núvirt með vegnum fjármagnskostnaði (WACC).</p>
                                        
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="9.1"
                                                formula={
                                                    <MathJax hideUntilTypeset="first">{`\\(V_{op} = \\displaystyle\\sum_{t=1}^\\infty \\frac{FCF_t}{(1+WACC)^t} = \\frac{FCF_1}{(1+WACC)^1} + \\frac{FCF_2}{(1+WACC)^2} + \\cdots + \\frac{FCF_\\infty}{(1+WACC)^\\infty}\\)`}</MathJax>
                                                }
                                            />
                                            <ul className="formula-boxes__descriptions">
                                                <li><strong>D<sub>0</sub></strong> = síðasta arðgreiðsla</li>
                                                <li><strong>D<sub>1</sub></strong> = vænt arðgreiðsla eftir 1 ár</li>
                                                <li><strong>P<sub>0</sub></strong> = verð á hlutabréfi s í dag</li>
                                                <li><strong>g</strong> = væntur vöxtur arðgreiðslna</li>
                                            </ul>
                                        </div>

                                        <p><strong>Heildarvirði fyrirtækis</strong> (e. total value of company) má sjá á mynd 9–1 og er samsett úr <strong>virði rekstrarins</strong>, V<sub>op</sub>, að viðbættu <strong>virði eigna ótengdar rekstri.</strong></p>
                                        
                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="9.2"
                                                name="Heildarvirði fyrirtækis (e. total value of company)"
                                                formula={
                                                    <span>V<sub>op</sub> + virði eigna ótengdar rekstri (e. value of non-operating assets)</span>
                                                }
                                            />
                                        </div>

                                        <p><strong>Markaðsvirði eigin fjár</strong> (e. market value of equity) er síðan <strong>heildarvirði fyrirtækis</strong> að frádregnu <strong>virði skulda og forgangshlutabréfa.</strong></p>

                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="9.3"
                                                name="Markaðsvirði eigin fjár (e. market value of equity)"
                                                formula={
                                                    <span>Heildarvirði fyrirtækis - Virði skulda of forgangshlutabréfa</span>
                                                }
                                            />
                                        </div>

                                        <p><strong>Bókfært virði eigin fjár</strong> má síðan finna í ársreikningi fyrirtækisins, en því má einnig lýsa svona:</p>

                                        <div className="formula-boxes">
                                            <FormulaBox
                                                label="9.4"
                                                name="Bókfært virði eigin fjár (e. book value of equity)"
                                                formula={
                                                    <span>Markaðsvirði eigin fjár - Virðisauki markaðarins (e. market value added, MVA)</span>
                                                }
                                            />
                                        </div>

                                        <img
                                            src="https://cdn.livemarketdata.com/KeldanImages/financeref/img-12.png"
                                            alt="Verðmat fyrirtækja"
                                            width="2585"
                                            height="1801"
                                        />
                                    </ScrollSpySection>
                                </ScrollSpy>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </MathJaxContext>
    );
}

export default FinanceRefPage;