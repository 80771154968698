// Package imports:
import React, { useState } from 'react';
// Component imports:
import Label from '../../../ui-elements/Label/Label';
import Tabs from '../../../ui-elements/Tabs/Tabs';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import CompanyAssetsTab from './CompanyAssetsTab';
// Type imports:
import { ICompanyViewModel } from '../../../types/CompanyTypes';
import ExampleReportButton from '../../ExampleReportButton/ExampleReportButton';

const CompanyAssets: React.FC<ICompanyViewModel> = ({
    id,
    isAuthenticated
}) => {
    const [source, setSource] = useState<string | null>(null);
    const [longSource, setLongSource] = useState<string | null>(null);
    const [reportType, setReportType] = useState<'assets' | 'vehicles-owned' | 'constructions-owned'>('assets');
    const onTabChange = (i: number) => {
        if (i === 0) {
            setSource('Fast.skr');
            setLongSource('Fasteignaskrá');
            setReportType('assets');
        } else if (i === 1) {
            setSource('Ökut.skr');
            setLongSource('Ökutækjaskrá');
            setReportType('vehicles-owned');
        } else if (i === 2) {
            setSource('Vinnuv.skr');
            setLongSource('Vinnuvélaskrá');
            setReportType('constructions-owned');
        } 
    }

    return (
        <DisplayBox
            className='KCL_company-assets'
            title='Eignir'
            asideComponent={<Label labelType='origin' text={source ?? ''} fullText={longSource ?? ''} />}
            footerRight={
                <ExampleReportButton
                    size='sm'
                    reportType={reportType}
                />
            }
        >
            <Tabs
                tabs={[{
                    id: 'properties',
                    label: 'Fasteignir',
                    component: <CompanyAssetsTab
                        assetType='properties'
                        id={id}
                        isAuthenticated={isAuthenticated}
                    />
                }, {
                    id: 'vehicles',
                    label: 'Ökutæki',
                    component: <CompanyAssetsTab
                        assetType='vehicles'
                        id={id}
                        isAuthenticated={isAuthenticated}
                    />
                }, {
                    id: 'constructions',
                    label: 'Vinnuvélar',
                    component: <CompanyAssetsTab
                        assetType='constructions'
                        id={id}
                        isAuthenticated={isAuthenticated}
                    />
                }]}
                onTabChange={onTabChange}
            />
        </DisplayBox>
    );
}

export default CompanyAssets;