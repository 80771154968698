// Package imports:
import React, { useMemo } from 'react';
import ISLocale from 'date-fns/locale/is';
import { format } from 'date-fns';
import { SeriesOptionsType } from 'highcharts';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import DefaultChart from '../DefaultChart/DefaultChart';
// Service imports:
import { formatNumber, getAPItoday } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../../types/Types';
import { IApiHistoryTimeseriesShortDATA, IApiLmdSpecialFundsChart } from '../../../types/MarketTypes';

interface IChartDatum {
    x: number | undefined
    y: number | undefined
};

interface IChartDataInfo {
    name: string,
    data: IChartDatum[]
};


interface IOwnProps {
    symbol?: string,
    title?: string,
    height?: number
};

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const SpecialFundsChart: React.FC<Props> = ({
    symbol,
    title,
    height = 500,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [apiDataStyringA] = useApiLmdData<IApiHistoryTimeseriesShortDATA>(
        `/v1/market_data/v1/funds/isl_styring_a/history_timeseries/?from_date=1972-02-26&to_date=${getAPItoday()}`,
        accessToken,
        refreshRateMs
    );
    const [apiDataStyringB] = useApiLmdData<IApiHistoryTimeseriesShortDATA>(
        `/v1/market_data/v1/funds/isl_styring_b/history_timeseries/?from_date=1972-02-26&to_date=${getAPItoday()}`,
        accessToken,
        refreshRateMs
    );
    const [apiDataStyringC] = useApiLmdData<IApiHistoryTimeseriesShortDATA>(
        `/v1/market_data/v1/funds/isl_styring_c/history_timeseries/?from_date=1972-02-26&to_date=${getAPItoday()}`,
        accessToken,
        refreshRateMs
    );
    const [apiDataStyringD] = useApiLmdData<IApiHistoryTimeseriesShortDATA>(
        `/v1/market_data/v1/funds/isl_styring_d/history_timeseries/?from_date=1972-02-26&to_date=${getAPItoday()}`,
        accessToken,
        refreshRateMs
    );

    const handleChartData = (body: IApiHistoryTimeseriesShortDATA) => {
        let dataToReturn: IApiLmdSpecialFundsChart[] = [];
        for(let datum of body.data){
            dataToReturn.push({
                value_date: datum[0],
                lastprice: datum[1],
            })
        }
        return dataToReturn;
    }
    
    const getStyringAData = () => {
        const chartDataInfoStyringA: IChartDataInfo = {
            name: '',
            data: []
        }
        if (apiDataStyringA.data !== null && !(apiDataStyringA.error instanceof Error)) {
            chartDataInfoStyringA.name = 'Stýring A';
            for (let datum of handleChartData(apiDataStyringA.data)) {
                chartDataInfoStyringA.data.push({
                    x: datum.value_date ?? undefined,
                    y: datum.lastprice ?? undefined
                });
            }
        }
        return chartDataInfoStyringA;
    }
    const getStyringBData = () => {
        const chartDataInfoStyringB: IChartDataInfo = {
            name: '',
            data: []
        }
        if (apiDataStyringB.data !== null && !(apiDataStyringB.error instanceof Error)) {
            chartDataInfoStyringB.name = 'Stýring B';
            for (let datum of handleChartData(apiDataStyringB.data)) {
                chartDataInfoStyringB.data.push({
                    x: datum.value_date ?? undefined,
                    y: datum.lastprice ?? undefined
                });
            }
        }
        return chartDataInfoStyringB;
    }
    const getStyringCData = () => {
        const chartDataInfoStyringC: IChartDataInfo = {
            name: '',
            data: []
        }
        if (apiDataStyringC.data !== null && !(apiDataStyringC.error instanceof Error)) {
            chartDataInfoStyringC.name = 'Stýring C';
            for (let datum of handleChartData(apiDataStyringC.data)) {
                chartDataInfoStyringC.data.push({
                    x: datum.value_date ?? undefined,
                    y: datum.lastprice ?? undefined
                });
            }
        }
        return chartDataInfoStyringC;
    }
    const getStyringDData = () => {
        const chartDataInfoStyringD: IChartDataInfo = {
            name: '',
            data: []
        }
        if (apiDataStyringD.data !== null && !(apiDataStyringD.error instanceof Error)) {
            chartDataInfoStyringD.name = 'Stýring D';
            for (let datum of handleChartData(apiDataStyringD.data)) {
                chartDataInfoStyringD.data.push({
                    x: datum.value_date ?? undefined,
                    y: datum.lastprice ?? undefined
                });
            }
        }
        return chartDataInfoStyringD;
    }
    const series: SeriesOptionsType[] = useMemo(() => {
        //STYRING A 
        const chartDataInfoStyringA = getStyringAData();
        //STYRING B
        const chartDataInfoStyringB = getStyringBData();
        //STYRING C
        const chartDataInfoStyringC = getStyringCData();
        //STYRING D
        const chartDataInfoStyringD = getStyringDData();
        return [{
            type: 'line',
            id: 'A',
            name: 'Stýring A',
            data: chartDataInfoStyringA.data,
            color: '#4569EE'
        }, {
            type: 'line',
            id: 'B',
            name: 'Stýring B',
            data: chartDataInfoStyringB.data,
            color: '#FF7697',
        }, {
            type: 'line',
            id: 'C',
            name: 'Stýring C',
            data: chartDataInfoStyringC.data,
            color: '#48A85D',
        }, {
            type: 'line',
            id: 'D',
            name: 'Stýring D',
            data: chartDataInfoStyringD.data,
            color: '#6CD3FF',
        }]
    }, [ apiDataStyringA, apiDataStyringB, apiDataStyringC, apiDataStyringD]);

    return (
        <DisplayBox
            title={title}
            className='KCL_chart'
            {...props}
        >
            <DefaultChart
                height={height}
                tooltipFormatter={
                    function() {
                        //filtera einungis það sem er visible af því ef það er notað points[0] crashar allt þegar eitthvað series er disable-að
                        return (`<div class="chart-tooltip">
                                    <p class="tooltip-date">${format(this.x, 'EEEE, dd. MMM yyyy', {locale: ISLocale})}</p>
                                    ${this.points?.filter(x => x.point.visible === true).map(x => (
                                        `<p><span style="color: ${x.color}">${x.series.getName()}</span>: ${formatNumber(x.y, '-', 1)}</p>`
                                    ))}
                                </div>`).replace(/>,</g,'><')
                    }
                }
                yAxes={[{
                    title: {
                        text: 'Verð',
                        offset: 52,
                        x: 5
                    },
                    labels: {
                        x: -11,
                        y: 2
                    }
                }]}
                series={series}
                startRange={6}
            />
        </DisplayBox>
    );
}

export default SpecialFundsChart;