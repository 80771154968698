const IconSearch = () => (
    <svg viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.246094 16L4.60547 11.6406C3.83203 10.4805 3.375 9.07422 3.375 7.5625C3.375 3.51953 6.64453 0.25 10.6875 0.25C14.7305 0.25 18 3.51953 18 7.5625C18 11.6055 14.7305 14.875 10.6875 14.875C9.17578 14.875 7.76953 14.418 6.60938 13.6445L2.25 18.0039C1.89844 18.3203 1.37109 18.3203 1.05469 18.0039L0.246094 17.1953C-0.0703125 16.8789 -0.0703125 16.3516 0.246094 16ZM15.1875 7.5625C15.1875 5.06641 13.1836 3.0625 10.6875 3.0625C8.19141 3.0625 6.1875 5.06641 6.1875 7.5625C6.1875 10.0586 8.19141 12.0625 10.6875 12.0625C13.1836 12.0625 15.1875 10.0586 15.1875 7.5625Z" fill="#232530"/>
    </svg>
);

const IconProfile = () => (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 8C9.21875 8 11 6.21875 11 4C11 1.78125 9.21875 0 7 0C4.78125 0 3 1.78125 3 4C3 6.21875 4.78125 8 7 8ZM8.59375 9.5H5.40625C2.4375 9.5 0 11.9375 0 14.9062C0 15.5 0.5 16 1.09375 16H12.9062C13.5 16 14 15.5 14 14.9062C14 11.9375 11.5625 9.5 8.59375 9.5Z" fill="currentColor"/>
    </svg>
);

const IconArrow = ({width = 10, height = 6}) => (
    <svg width={width} height={height} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow-icon">
        <path d="M9.20312 0.53125C9.04688 0.335938 8.73438 0.316406 8.55859 0.492188L5.375 3.42188L2.19141 0.492188C2.01562 0.316406 1.70312 0.335938 1.54687 0.53125L1.11719 1C0.941406 1.19531 0.960937 1.48828 1.15625 1.66406L5.10156 5.27734C5.25781 5.41406 5.49219 5.41406 5.64844 5.27734L9.59375 1.66406C9.78906 1.48828 9.80859 1.19531 9.63281 1L9.20312 0.53125Z" fill="currentColor"/>
    </svg>
);

const IconClose = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <rect width="22.8166" height="2.28166" rx="1.14083" transform="matrix(0.707106 -0.707107 0.707106 0.707107 0 16.1337)" fill="white"/>
        <rect width="22.8166" height="2.28166" rx="1.14083" transform="matrix(0.707107 0.707106 -0.707107 0.707106 1.86621 0.253052)" fill="white"/>
    </svg>
)

interface IProps {
    type: string,
    width?: number,
    height?: number
}

const Icon: React.FC<IProps> = ({ type, width, height }) => {
    switch (type) {
        case 'search':
            return <IconSearch />
        case 'profile':
            return <IconProfile />
        case 'dropdown-arrow':
            return <IconArrow width={width} height={height} />
        case 'close':
            return <IconClose />
        default:
            return <></>
    }
}

export default Icon;
