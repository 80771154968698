// Package imports:
import React, { useState } from 'react';
// Component imports:
import PriceCard from '../../PriceCard/PriceCard';
import SignUpForm from '../SignUpForm/SignUpForm';
import Link from '../../../ui-elements/Link/Link';
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';
import CheckList from '../../../ui-elements/Lists/CheckList';
// Service imports:
import { formatNumber, SUBSCRIPTION_PRICE } from '../../../services/utils';
// Type imports:
import { KeldanSubOption } from '../../../types/SignUpTypes';

interface IProps {
    subscriptionChoice?: KeldanSubOption
}

const SignUpPage: React.FC<IProps> = ({
    subscriptionChoice = 'free'
}) => {
    const price = subscriptionChoice === 'free' ? 0 : SUBSCRIPTION_PRICE;
    const [activeStep, setActiveStep] = useState(0);

    const getStepTitle = () => {
        if (activeStep === 0) {
            return subscriptionChoice === 'free' ? 'Aðgangur að Keldan gagnaleit' : 'Áskrift að Keldan gagnaleit';
        }
        if (activeStep === 1) {
            return 'Yfirfara upplýsingar';
        }
        return 'Velkomin/n á Kelduna';
    }
    const displayPriceCard = () => {
        if (subscriptionChoice === 'free') {
            return <PriceCard
                className="price-card--signup"
                variant="tertiary"
                type="Frítt"
                price={formatNumber(price, null, 0)}
                priceInfo="kr. á mánuði"
                badge="Þitt val"
            >
                <CheckList
                    items={['Aðgangur að öllum helstu skrám samkvæmt verðskrá Keldunnar']}
                    size='sm'
                />
            </PriceCard>
        }
        if (subscriptionChoice === 'premium1') {
            return <PriceCard
                className="price-card--signup"
                showRibbon
                variant="primary"
                type="Áskrift"
                price={formatNumber(price, null, 0)}
                priceInfo="kr. á mánuði + VSK"
                badge="Þitt val"
            >
                <p><strong>Aðgangur fyrir 1-4 notendur</strong></p>
                <CheckList
                    items={[
                        'Aðgangur að öllum helstu skrám',
                        'Ódýrari stakar skýrslur (sjá verðskrá)',
                        'Gögn sýnileg á fyrirtækjasíðum',
                        'Innslegnir ársreikningar',
                        'Samanburðarskýrslur',
                        'Fyrirtæki geta einnig sótt um aðgang að veðböndum',
                        'Fyrirtækjavakt',
                        'Fréttavakt',
                        'Verðmat fyrirtækja'
                    ]}
                    size='sm'
                />
            </PriceCard>
        }
        return <PriceCard
            className="price-card--signup"
            showRibbon
            variant="primary"
            type="Áskrift +"
            price={formatNumber(price, null, 0)}
            priceInfo="kr. á mánuði + VSK"
            badge="Þitt val"
        >
            <p><strong>Aðgangur fyrir allt að 10 notendur</strong></p>
            <CheckList
                items={[
                    'Aðgangur að öllum helstu skrám',
                    'Ódýrari stakar skýrslur (sjá verðskrá)',
                    'Gögn sýnileg á fyrirtækjasíðum',
                    'Innslegnir ársreikningar',
                    'Samanburðarskýrslur',
                    'Fyrirtæki geta einnig sótt um aðgang að veðböndum',
                    'Fyrirtækjavakt',
                    'Fréttavakt',
                    'Verðmat fyrirtækja'
                ]}
                size='sm'
            />
        </PriceCard>
    }
    return (
        <div className='main KCL_sign-up-page'>
            <div className="main__inner">
                <div className="section--signup shell">
                    <HeadSection
                        title={getStepTitle()}
                    />
                    <div className="section__body">
                        <div className="section__body-inner">
                            <aside className="section__aside">
                                {displayPriceCard()}
                                <p className='paragraph-small'>
                                    Ekki rétt valið? <Link url="/Verdskra" linkSize='16'>Farðu til baka til að breyta</Link>
                                </p>
                            </aside>
                            <div className="section__content">
                                <SignUpForm
                                    subscriptionChoice={subscriptionChoice}
                                    price={price}
                                    activeStep={activeStep}
                                    setActiveStep={setActiveStep}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUpPage;