// Package imports:
import React, { useContext } from 'react';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import Loading from '../../../ui-elements/Loading/Loading';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../../ui-elements/Link/Link';
import BulletList from '../../../ui-elements/Lists/BulletList';
// Service imports:
import { useFetchedApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { ICompanyViewModel, ComparableCompany } from '../../../types/CompanyTypes';
// Context imports:
import { AccessTokenContext } from '../../../contexts/AccessTokenContext';


const CompanySimilar: React.FC<ICompanyViewModel> = ({
    id
}) => {
    const accessToken = useContext(AccessTokenContext);
    const data = useFetchedApiLmdData<ComparableCompany[]>(
        `/v1/market_data/v1/keldan/company/similar_companies/ssn/${id}?limit=6`,
        accessToken
    );

    const displayBody = () => {
        if (data instanceof Error) return <Alert type='error' headText={data.message} />;
        if (data === null || data === undefined) return <Loading />;
        return (
            <BulletList
                items={data.map((item) => (
                    <Link
                        key={item.CompanyId}
                        splitLines
                        linkSize='15'
                        url={`/Fyrirtaeki/Yfirlit/${item.CompanyId}`}
                    >
                        <span>{item.Name}</span>
                    </Link>
                ))}
            />
        );
    }

    return (
        <DisplayBox 
            className='KCL_company-similar'
            title="Svipuð fyrirtæki"
        >
            {displayBody()}
        </DisplayBox>
    );
}

export default CompanySimilar;