// Package imports:
import React from 'react';
// Component imports:
import Loading from '../../../ui-elements/Loading/Loading';
import Alert from '../../../ui-elements/Alert/Alert';
// Service imports:
import { insertDash } from '../../../services/utils';
import { useFetchedApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { ICompanyViewModel, KeldanCompanyInfoStripped } from '../../../types/CompanyTypes';

type Props = ICompanyViewModel;

const CompanyTitleMini: React.FC<Props> = ({
    id,
    accessToken,
}) => {
    const keldanCompanyDetailsData = useFetchedApiLmdData<KeldanCompanyInfoStripped>(
        `/v1/market_data/v1/keldan/company/${id}/basic_info`,
        accessToken,
        (body: KeldanCompanyInfoStripped[]) => body[0]
    )

    if (keldanCompanyDetailsData instanceof Error)
        return <Alert type='error' headText={keldanCompanyDetailsData.message} />
    //Loading, waiting for data -> loading
    if (keldanCompanyDetailsData === undefined)
        return <Loading />
    
    return (
        <div className='KCL_company-title mini'>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Fyrirtæki</span>
                    <span className="value companyName">{keldanCompanyDetailsData?.CompanyName}</span>
                </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Kennitala</span>
                    <span className="value">{insertDash(keldanCompanyDetailsData?.CompanyId?.toString() ?? '')}</span>
                    </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Ísat</span>
                    <span className="value">{keldanCompanyDetailsData?.IsatId ?? '-'}</span>
                </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Ísat flokkur</span>
                    <span className="value isatCategory">{keldanCompanyDetailsData?.IsatDescriptionIs ?? '-'}</span>
                </div>
            </div>
        </div>
    );
}

export default CompanyTitleMini;