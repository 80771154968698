// Package imports:
import React from 'react';
import cx from 'classnames';

type ListItem = string | JSX.Element;

interface IProps {
    items: ListItem[],
    size?: 'xsm' | 'sm' | 'lg',
    blackMarker?: boolean
}

const BulletList: React.FC<IProps> = ({
    items,
    size = 'lg',
    blackMarker = false,
}) => {
    const setSize = () => {
        switch (size) {
            case 'xsm':
                return '-xsmall';
            case 'sm':
                return '-small';
            default:
                return '';
        }
    }

    return (
        <ul className={cx('KCL_bullet-list', size)}>
            {items.map((item, index) => (
                <li
                    key={index}
                    className={cx('bullet-list-item', `paragraph${setSize()}`, {'black-marker': blackMarker})}
                    >
                    {item}
                </li>
            ))}
        </ul>
    );
}

export default BulletList;