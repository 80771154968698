// Package imports:
import { Fragment, useEffect, useState } from 'react';
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
// Component imports:
import Loading from '../Loading/Loading';
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert';
// Service imports:
import { gtmPush, idGenerator } from '../../services/utils';
// Type imports:
import { ApiLmdData } from '../../types/Types';

export type AlignOptions = 'left' | 'center' | 'right';
type TableSize = 'sm' | 'lg';
type ExpandableRow<T> = {
    [rowTitle: string]: Array<T>
}

interface ISortingInfo {
    sortColumnIndex: number | null,
    sortColumnTitle: string | JSX.Element | null,
    reverseSort: boolean
}

export interface IColumn<T> {
    title: string | JSX.Element
    renderCell(dataItem: T): any,
    textAlign?: AlignOptions,
    overrideTd?: boolean,
    sortable?: (a: T, b: T) => number,
    simpleSortable?: (a: T) => number | string | null,
    overrideTh_class?: string
}

interface IProps<T> {
    apiData: ApiLmdData<T[] | ExpandableRow<T>>
    columns: Array<IColumn<T>>,
    expandableRowConfig?: {
        defaultExpandedIndexes?: number[],
        showNumberOfItemsInExpandable?: boolean
    },
    dontShowHeaders?: boolean,
    tableSize?: TableSize,
    id?: string
}

const LmdTable = <T extends any>({
    apiData,
    columns,
    expandableRowConfig,
    dontShowHeaders,
    tableSize = 'sm',
    id = '',
}: IProps<T>) => {
    // Set states.
    const [ expandedIndexes, setExpandedIndexes ] = useState(expandableRowConfig?.defaultExpandedIndexes || []);
    const [ sortingInfo, setSortingInfo ] = useState<ISortingInfo>({
        sortColumnIndex: null,
        sortColumnTitle: null,
        reverseSort: false
    });

    // When columns change (due to filter, forexample), check if sort still applies
    useEffect(() => {
        // Check index has same value
        const { sortColumnIndex, sortColumnTitle, reverseSort } = sortingInfo;
        if (sortColumnIndex === null || sortColumnTitle === null) return;
        if (columns[sortColumnIndex]?.title === sortColumnTitle) return;
        // If not, find value.
        const indexOfColumnWithTitle = columns.findIndex((column) => column.title === sortColumnTitle);
        if (indexOfColumnWithTitle >= 0) {
            setSortingInfo({
                sortColumnIndex: indexOfColumnWithTitle,
                sortColumnTitle: columns[indexOfColumnWithTitle].title,
                reverseSort
            });
        }
        // If not found, reset sort
        else {
            setSortingInfo({
                sortColumnIndex: null,
                sortColumnTitle: null,
                reverseSort: false
            });
        }
    }, [columns]);

    // Helper funcy's (AKA Hunkys).
    const isExpandableTable = (data: Array<T> | ExpandableRow<T>): data is ExpandableRow<T> => (!Array.isArray(data));
    const getAlignStyles = (align?: AlignOptions) => ({ textAlign: align ?? 'right' });
    const sortSimple = (simpleSortable: (a: T) => number | string | null, datum1: T, datum2: T, reverse: (returnValue: number) => number) => {
        const simpleDatum1 = simpleSortable(datum1),
              simpleDatum2 = simpleSortable(datum2);
        // Null checks
        if (simpleDatum1 === null && simpleDatum2 === null) return 0;
        if (simpleDatum1 === null) return reverse(-1);
        if (simpleDatum2 === null) return reverse(1);
        // String sort:
        if (typeof simpleDatum1 === 'string' && typeof simpleDatum2 === 'string') {
            if (simpleDatum1 === simpleDatum2) return 0;
            if (simpleDatum1 < simpleDatum2) return reverse(-1);
            return reverse(1);
        }
        // Number sort:
        if (typeof simpleDatum1 === 'number' && typeof simpleDatum2 === 'number') {
            return reverse(simpleDatum1 - simpleDatum2);
        }
    }
    const sortDataArray = (data: T[]) => ([...data].sort((datum1, datum2) => {
        const { sortColumnIndex, reverseSort } = sortingInfo;
        const reverse = (returnValue: number) => (reverseSort) ? (-returnValue) : returnValue;
        if (sortColumnIndex === null) return 0;
        const { sortable, simpleSortable } = columns[sortColumnIndex] ?? {};
        // Start with simpleSortable
        if (simpleSortable !== undefined) {
            return sortSimple(simpleSortable, datum1, datum2, reverse) ?? 0;
        }
        // Else perform sortable sort.
        if (sortable !== undefined) return reverse(sortable(datum1, datum2));
        // Default: Normal sort.
        return 0;
    }));

    // Display functions
    const displayHead = () => {
        if (dontShowHeaders) return null;
        return (
            <thead>
                <tr>
                    {columns.map(({ title, textAlign, sortable, simpleSortable, overrideTh_class }, index) => {
                        const isSortable = (sortable !== undefined || simpleSortable !== undefined);
                        const getCaret = () => {
                            if (!isSortable || sortingInfo.sortColumnIndex !== index) return null;
                            return sortingInfo.reverseSort
                                ? <span className='caret-span is-descending'>
                                    <FontAwesomeIcon icon={faCaretDown} />
                                </span>
                                : <span className='caret-span is-ascending'>
                                    <FontAwesomeIcon icon={faCaretUp} />
                                </span>
                        }
                        return (
                            <th
                                key={index}
                                className={cx(`align-${textAlign}`, {'is-sortable': isSortable}, overrideTh_class)}
                                style={getAlignStyles(textAlign)}
                                onClick={() => setSortingInfo({
                                    sortColumnIndex: (sortingInfo.sortColumnIndex === index && sortingInfo.reverseSort) ? null : index,
                                    sortColumnTitle: (sortingInfo.sortColumnIndex === index && sortingInfo.reverseSort) ? null : title,
                                    reverseSort: (sortingInfo.sortColumnIndex === index) ? !sortingInfo.reverseSort : false
                                })}
                            >
                                <div className='title-span'>
                                    <span className='text-span'>
                                        {getCaret()}
                                        {title}
                                    </span>
                                </div>
                            </th>
                        );
                    })}
                </tr>
            </thead>
        )
    };
    const displayBody = () => {
        const { data } = apiData;
        if (data === null) return null;
        if (isExpandableTable(data)) return displayExpandableBody(data);
        return displayRowBody(data);
    }

    const displayRowBody = (data: Array<T>) => (
        <tbody>
            {sortDataArray(data).map((datum, rowIndex) => (
                <tr key={rowIndex}>
                    {columns.map(({ renderCell, textAlign, overrideTd }, colIndex) => (
                        overrideTd
                        ? <Fragment key={colIndex}>
                            {renderCell(datum)}
                        </Fragment>
                        : <td
                            key={colIndex}
                            style={getAlignStyles(textAlign)}
                            className={cx(`align-${textAlign}`)}
                        >
                            {renderCell(datum)}
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    );

    const clickExpandableRow = (rowIndex: number) => {
        if (expandedIndexes.includes(rowIndex)) {
            const setWithRemovedIndex = new Set(expandedIndexes)
            setWithRemovedIndex.delete(rowIndex);
            setExpandedIndexes(Array.from(setWithRemovedIndex));
        } else {
            setExpandedIndexes([...expandedIndexes, rowIndex]);
        }
    }
    const displayExpandableBody = (data: ExpandableRow<T>) => Object.entries(data).map(([ sectionTitle, sectionData ], index) => {
        const isExpanded = expandedIndexes.includes(index);
        return (
            <Fragment key={index}>
                <tbody>
                    <tr className="trigger-holder">
                        <td colSpan={columns.length}>
                            <button
                                id={`${id + '-' + idGenerator(sectionTitle)}`}
                                className="table__expand-trigger"
                                onClick={() => {clickExpandableRow(index); gtmPush('ExpandableRowClick', `${id + '-' + idGenerator(sectionTitle)}`, (!isExpanded) ? 'Opened' : 'Closed')}}
                            >
                                <i>
                                    <FontAwesomeIcon icon={isExpanded ? faAngleUp : faAngleDown}/>
                                </i>
                                {sectionTitle} {(expandableRowConfig?.showNumberOfItemsInExpandable) ? `(${sectionData.length})` : ''}
                            </button>
                        </td>
                    </tr>
                </tbody>
                {isExpanded && (
                    <tbody className='table__body is-expanded'>
                        {sortDataArray(sectionData).map((datum, rowIndex) => (
                            <tr key={rowIndex}>
                                {columns.map(({ renderCell, textAlign, overrideTd }, colIndex) => (
                                    overrideTd
                                        ? <Fragment key={colIndex}>
                                            {renderCell(datum)}
                                        </Fragment>
                                        : <td
                                            key={colIndex}
                                            style={getAlignStyles(textAlign)}
                                        >
                                            {renderCell(datum)}
                                        </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                )}
            </Fragment>
        )
    });


    const displayMessages = () => {
        const { data, error } = apiData;
        if (data === null) {
            // Case 1. Both are loading.
            if (error === null) {
                return <Loading />;
            }
            // Case 2. Only Error
            else {
                return <ErrorAlert error={error} />;
            }
        }
    }

    return (
        <div className={cx('KCL_table', { 'KCL_table--expandable' : isExpandableTable(apiData.data ?? []) }, tableSize)}>
            <div className='table__body'>
                <table>
                    {displayHead()}
                    {displayBody()}
                </table>
                {displayMessages()}
            </div>
        </div>
    );
}

export default LmdTable;
