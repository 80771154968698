// Package imports:
import React from 'react';
// Component import.
import ExcellentCompaniesComponent from '../../components/ExcellentCompanies/ExcellentCompanies';
import Entrypoint from '../Entrypoint';

const ExcellentCompanies: React.FC = () => {
    return (
        <Entrypoint>
            <ExcellentCompaniesComponent />
        </Entrypoint>
    );
}

export default ExcellentCompanies;