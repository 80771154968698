// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import ViewVehiclesReportComp from '../../components/Company/ViewVehiclesReport/ViewVehiclesReport';
import CompanySubPage from '../../components/Company/CompanySubPage/CompanySubPage';
// Type imports:
import { IAuthenticationWithIdAndEventId } from '../../types/CompanyTypes';

const ViewVehiclesReport: React.FC<IAuthenticationWithIdAndEventId> = ({
    id,
    eventId,
    isAuthenticated,
    isSubscriber
}) => {
    return (
        <Entrypoint>
            <CompanySubPage 
                id={id} 
                title='Ökutæki'
                isAuthenticated={isAuthenticated}
                isSubscriber={isSubscriber}
                subTitle='Ökutæki - Skýrsla'
                noAd
                component={
                    <ViewVehiclesReportComp
                        id={id ?? ''}
                        eventId={eventId ?? ''}
                    />
                }
                showRecentReports
            />
        </Entrypoint>
    );
}

export default ViewVehiclesReport;