// Package imports:
import React from "react";
// Component imports:
import VehiclesOwnedReportBody from "./VehiclesOwnedReportBody";
// Type imports:
import { IVehicleReport } from "../../../types/VehicleTypes";
// Static data imports:
import EXAMPLE_DATA from "../../../assets/staticData/ExampleReports/Vehicle/VehiclesOwnedReport.json";

const VehiclesOwnedReportExample: React.FC = () => {
    return <VehiclesOwnedReportBody vehicleReport={EXAMPLE_DATA as IVehicleReport} isExample />
};

export default VehiclesOwnedReportExample;