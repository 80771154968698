import React, { useEffect, useState } from 'react';
import Table from '../../ui-elements/Table/Table';
import { MAX_RESULTS, ResultStatus, getItemHref } from './SearchPage';
import Button from '../../ui-elements/Button/Button';
import { IApiLmdAutocompleteItem } from '../../types/SearchTypes';
import { Fetched } from '../../types/Types';
import Loading from '../../ui-elements/Loading/Loading';
import { getSearchTermsFromSearch } from '../../services/utils';

const DefaultSearch:React.FC<{
    term: string | null,
    title: 'Hlutabréf' | 'Skuldabréf' | 'Gjaldmiðlar' | 'Sjóðir' | 'Vísitölur' | 'Skuldabréfa Vísitölur' | string,
    data: Fetched<IApiLmdAutocompleteItem[]>;
    onResultsChange: (hasResults: ResultStatus) => void;
}> = ({term, onResultsChange, data, title}) => {

    const [dataForTable, setDataForTable] = useState<IApiLmdAutocompleteItem[]>([]);
    const [isItemLoading, setIsItemLoading] = useState(false);

    useEffect(() => {
        setIsItemLoading(true);
        const filterFetchedData = (data: Fetched<IApiLmdAutocompleteItem[]>) => {
            if (data === null || data instanceof Error) {
                onResultsChange('no results');
                setIsItemLoading(false);
                return;
            }
            const searchTerms = getSearchTermsFromSearch(term);
            const filteredData =  data.filter(item => {
                let symbolContainsAllSearchTerms = searchTerms.every(searchTerm => 
                    item.Symbol?.toLowerCase().includes(searchTerm.toLowerCase())
                );
                let nameContainsAllSearchTerms = searchTerms.every(searchTerm => 
                    item.Name?.toLowerCase().includes(searchTerm.toLowerCase())
                );
                return (symbolContainsAllSearchTerms || nameContainsAllSearchTerms);
            });
            if(filteredData.length > 0) {
                onResultsChange('has results');
                setDataForTable(filteredData);
            } else {
                onResultsChange('no results');
                setDataForTable([]);
            }
            setIsItemLoading(false);
        }
        filterFetchedData(data);
    }, [data, term]);

    if(dataForTable.length === 0){
        return null;
    }
    return (
        <div className='table-section'>
            <h4>{title}</h4>
            {isItemLoading ? <Loading /> : <Table 
                data={dataForTable.slice(0,MAX_RESULTS)}
                columns={[{
                    title: 'Nafn',
                    renderCell: ( datum ) => ( 
                        <a className='clickableRow' href={getItemHref(datum)}>
                            <span className='name'>{datum.Symbol + ' ' + datum.Name}</span>
                        </a>
                    ),
                    textAlign: 'left',
                },{
                    title: '',
                    renderCell: ( datum ) => ( 
                        <a className='anchorWrapForButton' href={getItemHref(datum)}>
                            <span>
                                <Button buttonType='secondary' size='sm'>Skoða</Button>
                            </span>
                        </a>
                    )
                }]}
                tableSize='lg'
            />}
        </div>
    );
} 
export default DefaultSearch;