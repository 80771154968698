// Package imports:
import React from 'react';
import AOS from "aos";
import { SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import Link from '../../ui-elements/Link/Link';
import PriceCard from '../PriceCard/PriceCard';
import Slider from '../Slider/Slider';
import Button from '../../ui-elements/Button/Button';
import Table from '../../ui-elements/Table/Table';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import Loading from '../../ui-elements/Loading/Loading';
import Alert from '../../ui-elements/Alert/Alert';
import SubscriptionPricingTable from '../../ui-elements/SubscriptionPricingTable/SubscriptionPricingTable';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
// Service imports:
// Type imports:
import { IKeldanApiPricingViewModel } from '../../types/HomeTypes';

const PRICES = {
    "products": {
        "Fyrirtæki": [{
            "name": "Innslegnir ársreikningar",
            "subPrice": 0,
            "freePrice": 1190
        }, {
            "name": "Samanburðarskýrslur",
            "subPrice": 0,
            "freePrice": 4900
        }, {
            "name": "Stofngögn, samþykktir og aukatilkynningar",
            "subPrice": 1900,
            "freePrice": 2290
        }],
        "Hlutafélagaskrá": [{
            "name": "Aðild/Hlutafélagaþátttaka einstaklinga",
            "subPrice": 0,
            "freePrice": 1350
        }, {
            "name": "Aðild/Hlutafélagaþátttaka einstaklinga m/heimilisföngum",
            "subPrice": 0,
            "freePrice": 1370
        }, {
            "name": "Gildandi skráning (KYC)",
            "subPrice": 0,
            "freePrice": 950
        }, {
            "name": "Gildandi skráning (KYC) m/raunverulegum eigendum",
            "subPrice": 0,
            "freePrice": 1090
        }],
        "Fasteignir": [{
            "name": "Fasteignaskrá",
            "subPrice": 185,
            "freePrice": 295
        }, {
            "name": "Eignastaða m/eignasögu",
            "subPrice": 2290,
            "freePrice": 2850
        }, {
            "name": "Eignastaða eftir kennitölu",
            "subPrice": 1290,
            "freePrice": 1790
        }, {
            "name": "Veðbönd",
            "subPrice": 1290,
            "freePrice": null
        }, {
            "name": "Verðvísir Gangverðs",
            "subPrice": 3290,
            "freePrice": 4190
        }, {
            "name": "Yfirlitsmynd",
            "subPrice": 110,
            "freePrice": 150
        }, {
            "name": "Skjalalisti (þinglýst skjöl)",
            "subPrice": 110,
            "freePrice": null
        }, {
            "name": "Þinglýst skjal",
            "subPrice": 410,
            "freePrice": null
        }],
        "Ökutæki": [{
            "name": "Ökutækjaskrá",
            "subPrice": 36,
            "freePrice": 150
        }, {
            "name": "Ferilskrá",
            "subPrice": 110,
            "freePrice": 190
        }, {
            "name": "Eignastaða eftir kennitölu",
            "subPrice": 950,
            "freePrice": 1290
        }, {
            "name": "Veðbönd",
            "subPrice": 1290,
            "freePrice": null
        }, {
            "name": "Vinnuvélaskrá",
            "subPrice": 25,
            "freePrice": 60
        }, {
            "name": "Vinnuvélaskrá eftir kennitölu",
            "subPrice": 790,
            "freePrice": 990
        }],
        "Annað": [{
            "name": "Þjóðskrá",
            "subPrice": 16,
            "freePrice": 28
        }, {
            "name": "Lögbirtingar",
            "subPrice": 9,
            "freePrice": 14
        }, {
            "name": "Markhópalistar - grunngjald",
            "subPrice": 30000,
            "freePrice": 40000
        }, {
            "name": "Fyrirmyndarfyrirtæki - vottun",
            "subPrice": 99900,
            "freePrice": 99900
        }, {
            "name": "API vefþjónustuaðgangur (mánaðargjald)",
            "subPrice": 9900,
            "freePrice": 9900
        }],
        "Tilkynningarskyldir aðilar": [{
            "name": "Stjórnmálaleg tengsl (PEP) eftir kennitölu",
            "subPrice": -1,
            "freePrice": null
        }, {
            "name": "Uppfletting í alþjóðlegum válistum - einstaklingar",
            "subPrice": 195,
            "freePrice": null
        }, {
            "name": "Uppfletting í alþjóðlegum válistum - fyrirtæki",
            "subPrice": 255,
            "freePrice": null
        }, {
            "name": <Link url="/Areidanleikakannanir" splitLines>Áreiðanleikakönnun á lögaðilum + PEP uppfletting</Link>,
            "subPrice": 1190,
            "freePrice": null
        }, {
            "name": <Link url="/Areidanleikakannanir" splitLines>Áreiðanleikakönnun á einstaklingum + PEP uppfletting</Link>,
            "subPrice": 590,
            "freePrice": null
        }]
    }
}

const NEW_PRICING_PLAN_TABLE = [{
    label: "Verð á mánuði" ,
    forFreeUser: "0 kr.",
    forSubscriber: "5.490 kr."
}, {
    label: "Fjöldi aðganga",
    forFreeUser: "1",
    forSubscriber: "4"
}, {
    label: "Skannaðir ársreikningar",
    forFreeUser: <FontAwesomeIcon icon={faCheck} className="icon-fa-check"/>,
    forSubscriber: <FontAwesomeIcon icon={faCheck} className="icon-fa-check"/>
}, {
    label: "Innslegnir ársreikningar",
    forFreeUser: "",
    forSubscriber: <FontAwesomeIcon icon={faCheck} className="icon-fa-check"/>
}, {
    label: "Raunverulegir eigendur",
    forFreeUser: "",
    forSubscriber: <FontAwesomeIcon icon={faCheck} className="icon-fa-check"/>
}, {
    label: "Fyrirtækjavakt",
    forFreeUser: "",
    forSubscriber: <FontAwesomeIcon icon={faCheck} className="icon-fa-check"/>
}, {
    label: "Fréttavakt",
    forFreeUser: "",
    forSubscriber: <FontAwesomeIcon icon={faCheck} className="icon-fa-check"/>
}, {
    label: "Samanburðar skýrslur",
    forFreeUser: "",
    forSubscriber: <FontAwesomeIcon icon={faCheck} className="icon-fa-check"/>
}, {
    label: "Verðmat fyrirtækja",
    forFreeUser: "",
    forSubscriber: <FontAwesomeIcon icon={faCheck} className="icon-fa-check"/>
}]

const NewPricingPage: React.FC = () => {
    AOS.init();

    const data: IKeldanApiPricingViewModel = PRICES;

    const displayPricingTable = () => {
        if (data === null) return <Loading />;
        if (data instanceof Error) return <Alert type='error' headText={data.message} />;
        return <SubscriptionPricingTable groups={{products: data.products}} />;
    }
    
    return (
        <div className='main KCL_pricing-page'>
            <div className='main__inner'>
                <div className='section--white'>
                    <div className='mini_shell'>
                        <HeadSection
                            title='Áskriftarleiðir'
                            summary={
                                <p>
                                    Keldan veitir þér aðgang að öllum helstu skrám sem reknar eru af opinberum aðilum á Íslandi. Athugið að keyptar skýrslur eru samkvæmt verðskrá. 
                                    Veldu þá leið sem hentar þér eða þínu fyrirtæki.
                                </p>
                            }
                            description={<p>Eftirfarandi verðskrárbreytingar taka gildi 1. janúar 2025.</p>}
                            actions={
                                <Button
                                    buttonType="primary"
                                    size="lg"
                                    anchorProps={{ href: '#verdskra' }}
                                >
                                    Skoða verðskrá
                                </Button>
                            }
                            textAlign='center'
                        />
                    </div>
                </div>
                <div id='Gagnaleit-pricing' className='section mini_shell'>
                    <div className='section__body'>
                        <div className='section__inner'>
                            <div 
                                className="section__list"
                                data-aos="fade-in"
                                data-aos-delay="50"
                            >
                                <div className="subscription-plans">
                                    <div className="subscription-plans__slider">
                                        <Slider
                                            perView={3}
                                            centerSlides={true}
                                            loop={false}
                                            hideActions={true}
                                            initialSlide={1}
                                            breakpointOptions={{
                                                320: {
                                                    slidesPerView: 1,
                                                    noSwiping: false
                                                },
                                                768: {
                                                    slidesPerView: 3,
                                                    noSwiping: true
                                                }
                                            }}
                                            onSlideChange={() => {}}
                                            onInit={() => {}}
                                            className="price-cards__slider swiper-no-swiping"
                                        >
                                            <SwiperSlide>
                                                <PriceCard
                                                    variant="tertiary"
                                                    type="Frítt"
                                                    price="0"
                                                    priceInfo="kr. á mánuði "
                                                    key="Frítt"
                                                >
                                                    <div className="price-card__info">
                                                        <p>Fyrir þá sem vilja skoða gögn og kaupa einstakar skýrslur.</p>
                                                    </div>
                                                    <div className="price-card__actions">
                                                        <Button anchorProps={{href: "/Nyskraning"}}>
                                                            Stofna aðgang
                                                        </Button>
                                                    </div>
                                                </PriceCard>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <PriceCard
                                                    variant="primary"
                                                    type="Áskrift"
                                                    price='5.490'
                                                    priceInfo="kr. á mánuði + VSK"
                                                    key="Áskrift"
                                                    showRibbon
                                                >                            
                                                    <div className="price-card__info">
                                                        <p>Fyrir þá sem vilja skoða gögn, vakta fyrirtæki og kaupa ódýrari skýrslur.<br/> Fyrir allt að 4 notendur.</p>
                                                    </div>
                            
                                                    <div className="price-card__actions">
                                                        <Button buttonType='buy' anchorProps={{
                                                            href: `/Askrift`
                                                            }}
                                                        >
                                                            Kaupa áskrift
                                                        </Button>
                                                    </div>
                                                </PriceCard>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <PriceCard
                                                    variant="secondary"
                                                    type="Sérsamningur"
                                                    priceText="Umsamið"
                                                    key="Sérsamningur"
                                                >
                                                    <div className="price-card__info">
                                                        <p>Fyrir kröfuharða notendur sem þurfa aðgang fyrir fimm eða fleiri í sínu fyrirtæki.</p>
                                                    </div>
                                                    <div className="price-card__actions">
                                                        <Button anchorProps={{href: "/Hafa-Samband"}}>
                                                            Hafðu samband
                                                        </Button>
                                                    </div>
                                                </PriceCard>
                                            </SwiperSlide>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                            <div 
                                className="section__group"
                                data-aos="fade-in"
                                data-aos-delay="50"
                            >
                                <Table 
                                    columns={[{
                                        title: '',
                                        renderCell: ({label}) => label,
                                        textAlign: 'left'
                                    }, {
                                        title: 'Frítt',
                                        renderCell: ({forFreeUser}) => forFreeUser,
                                        textAlign: 'center'
                                    }, {
                                        title: 'Áskrift',
                                        renderCell: ({forSubscriber}) => forSubscriber,
                                        textAlign: 'center'
                                    }]}
                                    data={NEW_PRICING_PLAN_TABLE}
                                    className="subscription-plans-table"
                                />
                            </div>
                            <DisplayBox
                                displayBoxSize='xl'
                                id='Verdskra'
                            >
                                <h2>Verðskrá gagna</h2>
                                {displayPricingTable()}
                                    
                                <div className="section__footnote">
                                    <p className='paragraph-small--italic'>
                                        Allar skýrslur sem innskráður notandi kaupir eru aðgengilegar á síðunni Keyptar skýrslur án frekari kostnaðar.
                                    </p>
                                    <p className='paragraph-small--italic'>
                                        Þar er einnig að finna nákvæmt yfirlit yfir allan kostnað vegna kaupa á skýrslum.
                                    </p>
                                </div>
                            </DisplayBox>
                        </div>
                    </div>
                </div>
                <div id="KYC" className='section--gray' style={{paddingTop: '100px', paddingBottom: '100px'}}>
                    <div className='shell' style={{maxWidth: '1374px'}}>
                        <PriceCard
                            variant="overview"
                            type="Verðskrá KYC kerfisins "
                            style={{maxWidth: '700px'}}
                        >
                            <div className="price-card__details">
                                <dl>
                                    <dt><strong>Mánaðargjald</strong></dt>
                                    <dd>5.490 kr.</dd>
                                    
                                </dl>
                                <p style={{marginBottom: '12px'}}><em>Áskrift að Keldunni er innifalin.</em></p>
                                <div className='content-wrapper'>
                                    <dl>
                                        <dt>Framkvæmd áreiðanleikakönnun á lögaðilum + PEP uppfletting</dt>
                                        <dd>1.490 kr.</dd>
                                    </dl>
                                    <dl>
                                        <dt>Framkvæmd áreiðanleikakönnun á einstaklingum + PEP uppfletting</dt>
                                        <dd>590 kr.</dd>
                                    </dl>
                                    <dl>
                                        <dt>Uppfletting í alþjóðlegum válistum</dt>
                                        <dd>195 kr.</dd>
                                    </dl>
                                </div>
                                <p><em>Athugið, verðin eru án VSK.</em></p>
                            </div>
                        </PriceCard>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewPricingPage;