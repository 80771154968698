// Package imports:
import React, { useState } from 'react';
// Component imports:
import Tabs from '../../ui-elements/Tabs/Tabs';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import DomesticPepSearch from './DomesticSearch'
import ForeignPepSearch, { IForeignSearchParams } from './ForeignSearch';
// Type imports:
import { Subscription } from '../../types/KeldanTypes';
import ForeignSearchCompany from './ForeignSearchCompany';

interface IProps {
    isAuthenticated: boolean,
    subscriptions: Subscription[]
}

export interface IPepArray {
    name: string,
    connection: string,
    status: string,
    source: string | JSX.Element | null,
}

export interface IPepData {
    connected: boolean,
    name: string | undefined,
    ssn: string | undefined,
    entries: IPepArray[],
}

export interface IPepResults extends IPepData {
    event: {
        id: number,
        date: string,
    }
}
export function formatCategories(strings?: string[]): JSX.Element {
    if (strings === undefined) return <></>;
    const groups: { [key: string]: string[] } = {};
  
    strings.forEach((str) => {
      const [prefix, suffix] = str.split(" - ");
      if (!groups[prefix]) {
        groups[prefix] = [];
      }
      if (suffix) {
        groups[prefix].push(suffix);
      }
    });
  
    const elements: JSX.Element[] = [];
    Object.entries(groups).forEach(([prefix, suffixes], index) => {
      if (index !== 0) {
        elements.push(<span>, </span>);
      }
      elements.push(<strong>{prefix}</strong>);
      if (suffixes.length > 0) {
        elements.push(<span>: {suffixes.join(", ")}</span>);
      }
    });
  
    return <span>{elements}</span>;
  }
const PEPSearchPage: React.FC<IProps> = ({
    isAuthenticated,
    subscriptions
}) => {
    const [searchParams, setSearchParams] = useState<IForeignSearchParams>({
        firstName: undefined,
        middleName: undefined,
        lastName: undefined,
        dob: undefined
    });
    return (
        <div className="KCL_PEPSearchPage">
            <div className='shell'>
                <HeadSection
                    title="PEP listi - stjórnmálaleg tengsl"
                    showRecentReports={isAuthenticated}
                />
            </div>
            <Tabs
                tabsSize="lg"
                saveTabToUrl
                shellHead
                tabs={[{
                    id: "Island",
                    label: "Íslenskur PEP listi",
                    component: <DomesticPepSearch
                        isAuthenticated={isAuthenticated}
                        subscriptions={subscriptions}
                        setParamsForForeignSearch={setSearchParams}
                    />
                }, {
                    id: "Erlent",
                    label:"Alþjóðlegir válistar",
                    component: <ForeignPepSearch
                        isAuthenticated={isAuthenticated}
                        subscriptions={subscriptions}
                        searchParams={searchParams}
                    />
                }, {
                    id: "Erlent-company",
                    label:"Alþjóðlegir válistar - lögaðilar",
                    component: <ForeignSearchCompany
                        isAuthenticated={isAuthenticated}
                        subscriptions={subscriptions}
                    />
                }]}
            />
        </div>
    )
}

export default PEPSearchPage;