// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import SpecialFundsPage from '../../components/SpecialFundsPage/SpecialFundsPage';

const SpecialFunds: React.FC = () => {
    return (
        <Entrypoint>
            <SpecialFundsPage />
        </Entrypoint>
    );
}

export default SpecialFunds;