// Package imports:
import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import Loading from '../../../ui-elements/Loading/Loading';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import ConstructionReportBody from './ConstructionReportBody';
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { ConstructionViewModel } from '../../../types/VehicleTypes';
import { Fetched } from '../../../types/Types';

interface IProps {
	model: ConstructionViewModel;
}

const ViewConstructionProfile: React.FC<IProps> = ({
	model
}) => {
    const [data, setData] = useState<Fetched<ConstructionViewModel>>(model);

    const fetchData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Vehicle/GetConstructionReport/${model?.id}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                }
                const body : ConstructionViewModel = await response.json();
                setData(body);
            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setData(new Error(ErrorMessages.NetworkError));
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const displayBody = () => {
        if (data instanceof Error) {
            return <DisplayBox>
                <Alert type='error' headText={data.message} />
            </DisplayBox>
        }
        if (data === undefined || data === null) {
            return <DisplayBox>
                <Loading />
            </DisplayBox>
        }
        if (data.report) {
            return <ConstructionReportBody eventId={model.id.toString()} data={data.report} />
        }
        if (data.reportOld) {
            return <ConstructionReportBody eventId={model.id.toString()} dataOld={data.reportOld} />
        }
        return null
    }
    return (
        <div className="main KCL_construction-profile">
            <div className='shell'>
                <HeadSection
                    title='Vinnuvélaskýrsla'
                    showRecentReports
                />
                {displayBody()}
            </div>
        </div>
    )
}
export default ViewConstructionProfile;