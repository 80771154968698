// Package imports:
import React, { useEffect, useRef } from 'react';
// @ts-ignore
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// Service imports:
import { displayPrice, formatNumber, getTableDate } from '../../../services/utils';
// Type imports:
import { IGangverdProperty, IGangverdSeries } from '../../../types/PropertyTypes';

mapboxgl.accessToken = 'pk.eyJ1IjoiZ2FuZ3ZlcmQiLCJhIjoia1pQb2lITSJ9.h8yCORK0eQlDJ9ZrOh469A';

interface IProps {
    address?: string,
    lng: number,
    lat: number,
    latestval?: number,
    similarSeries?: IGangverdSeries<IGangverdProperty>
}

const GangverdMap: React.FC<IProps> = ({
    address,
    lng,
    lat,
    latestval,
    similarSeries
}) => {
    const mapContainer = useRef(null);

    class LegendControl {
        _map: mapboxgl.Map
        _container?: HTMLDivElement

        onAdd(map: mapboxgl.Map) {
            this._map = map;
            this._container = document.createElement('div');
            this._container.className = 'mapboxgl-ctrl legend-ctrl';
            this._container.innerHTML = '<b>Verðmunur</b><div class="range">50%<br/>40%<br/>30%<br/>20%<br/>10%<br/>0%<br/>-10%<br/>-20%<br/>-30%<br/>-40%<br/>-50%</div>';
            return this._container;
        }
        onRemove() {
            this._container?.parentNode?.removeChild(this._container);
            this._map = undefined;
        }
    }
    
    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: 14,
            attributionControl: false
        });

        map.addControl(new mapboxgl.NavigationControl(), "top-left");

        map.addControl(new LegendControl(), "top-right");

        new mapboxgl.Marker()
            .setLngLat([lng, lat])
            .setPopup(new mapboxgl.Popup().setHTML(`<b>${address}</b><br />Nýjasta verðmat: ${displayPrice(latestval)}`))
            .addTo(map);

        const center = new mapboxgl.LngLat(lng, lat);
        let maxDist = 50;

        similarSeries?.data.forEach((s, i) => {
            const el = document.createElement('div')
            el.className = 'marker';
            el.style.backgroundColor = similarSeries.colorMap[i];
            const marker = new mapboxgl.Marker(el)
                .setLngLat([s.lon, s.lat])
                .setPopup(new mapboxgl.Popup().setHTML(`<b>${s.address}</b><br />Stærð: ${formatNumber(s.size, '-', 1)} m²<br />Söluverð: ${displayPrice(s.price)}<br />Dags: ${getTableDate(s.date2 !== undefined ? new Date(s.date2) : null, 'DD/MM/YYYY', '.')}`))
                .addTo(map);
            
            const dist = center.distanceTo(marker.getLngLat());
            if (dist > maxDist) maxDist = dist;
        });

        return () => map.remove();
    }, []);

    return (
        <div className='KCL_gangverd-map'>
            <div ref={mapContainer} className="map-container" style={{height: '568px'}} />
        </div>
    );
}

export default GangverdMap;