// Package imports:
import React from 'react';
import { Helmet } from 'react-helmet-async';
interface IProps {
    title?: string,
    description?: string,
    keyword?: string,
}
const SEO: React.FC<IProps> = ({
    title,
    description,
    keyword
}) => {
    return (
        <Helmet>
            {title && <title>{title}</title>}
            {description && <meta name="description" content={description} />}
            {keyword && <meta name="keywords" content={keyword} />}
        </Helmet>
    );
}
export default SEO;