// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import AboutSubscriptionPage from '../../components/AboutSubscription/AboutSubscription';

const About: React.FC = () => {
    return (
        <Entrypoint>
            <AboutSubscriptionPage />
        </Entrypoint>
    );
}

export default About;