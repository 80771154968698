// Package imports:
import React from 'react';
// Component imports:
import CookieConsentFormComp from '../../components/CookieConsentForm/CookieConsentForm';

const CookieConsentForm: React.FC = () => {
    return (
        <CookieConsentFormComp />
    );
}

export default CookieConsentForm;