// Package imports:
import React, { useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
// Component imports:
import Input from '../../../ui-elements/Input/Formik/Input';
import Button from '../../../ui-elements/Button/Button';
import Alert from '../../../ui-elements/Alert/Alert';
import DropSelect from '../../../ui-elements/DropSelect/DropSelect';
import Recaptcha from '../../../ui-elements/Recaptcha/Recaptcha';
// Service imports:
import useSendEmailHook from '../../../services/apiHooks';
// Type imports:
import { Fetched } from '../../../types/Types';

const ListarSerpontunContactForm: React.FC = () => {
    const [listSize, setListSize] = useState<string | null>(null);
    const [companyGroupType, setCompanyGroupType] = useState<string | null>(null);
    const [sizeGroupType, setSizeGroupType] = useState<string | null>(null);
    const [captchaToken, setCaptchaToken] = useState<string | null>('');
    const { loading, contactResponse, handleSubmit } = useSendEmailHook(
        'Email/MarkhopalistarSerpontunContact',
        captchaToken,
            {
                listSize: listSize ?? '',
                companyGroupType: companyGroupType ?? '',
                sizeGroupType: sizeGroupType ?? ''
            }
        );

    const subFormComponent = useMemo(() => {
        return ((props: { values: IFormValues }) => (
            <SubFormComponent
                loading={loading}
                contactResponse={contactResponse}
                values={props.values}
                listSize={listSize}
                setListSize={setListSize}
                companyGroupType={companyGroupType}
                setCompanyGroupType={setCompanyGroupType}
                sizeGroupType={sizeGroupType}
                setSizeGroupType={setSizeGroupType}
                setCaptchaToken={(e) => setCaptchaToken(e)}
            />
        ))
    }, [ contactResponse, listSize, companyGroupType, sizeGroupType, loading ]);

    return (
        <div className='KCL_contact-form'>
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    company: '',
                    buyer: '',
                    message: '',
                }}
                validationSchema={Yup.object({
                    name: Yup.string()
                        .required('Nafn tengiliðar verður að fylgja'),
                    email: Yup.string()
                        .email('Ógilt netfang')
                        .required('Netfang verður að fylgja'),
                    company: Yup.string()
                        .required('Nafn fyrirtækis verður að fylgja'),
                    buyer: Yup.string()
                        .required('Kennitala greiðanda verður að fylgja')
                        .matches(/^(\d{6}-\d{4})|^(\d+)$/, 'Kennitala má einungis innihalda tölustafi')
                        .min(10, 'Kennitala þarf að innihalda 10 tölustafi')
                        .max(11, 'Kennitala má ekki vera meira en 10 tölustafir'),
                    message: Yup.string()
                })}
                onSubmit={handleSubmit}
                component={subFormComponent}
            />
        </div>
    );
}

export default ListarSerpontunContactForm;

interface IFormValues {
    name: string,
    email: string,
    company: string,
    buyer: string,
    message: string,
}


interface ISubFormProps {
    contactResponse: Fetched<string>
    values: IFormValues,
    listSize: string | null,
    setListSize: (e: string) => void,
    companyGroupType: string | null,
    setCompanyGroupType: (e: string) => void,
    sizeGroupType: string | null,
    setSizeGroupType: (e: string) => void,
    loading: boolean,
    setCaptchaToken: (e: string | null) => void
}

const SubFormComponent: React.FC<ISubFormProps> = ({
    contactResponse,
    values,
    listSize,
    setListSize,
    companyGroupType,
    setCompanyGroupType,
    sizeGroupType,
    setSizeGroupType,
    loading,
    setCaptchaToken
}) => {
        return (
            <Form>
                <div className="form__body">
                    <div className="form__section">
                        <h3>Þínar upplýsingar</h3>
                        <div className="form__row">
                            <div className="form__col">
                                <Input 
                                    label="Nafn"
                                    name="name"
                                    id="name"
                                    value={values.name}
                                />
                            </div>
                            <div className="form__col">
                                <Input
                                    label="Netfang"
                                    name="email"
                                    id="email"
                                    value={values.email}
                                />
                            </div>
                        </div>
                        <div className="form__row">
                            <div className="form__col">
                                <Input 
                                    label="Fyrirtæki"
                                    name="company"
                                    id="company"
                                    value={values.company}
                                />
                            </div>
                            <div className="form__col">
                                <Input
                                    label="Kennitala greiðanda"
                                    name="buyer"
                                    id="buyer"
                                    value={values.buyer}
                                />
                            </div>
                        </div>
                        
                        <h4>Markhópalistinn</h4>
                        <div className="form__row">
                            <div className="form__col">
                                <DropSelect
                                    options={[{
                                        label: '100 stærstu fyrirtækin',
                                        value: '100'
                                    }, {
                                        label: '300 stærstu fyrirtækin',
                                        value: '300'
                                    }, {
                                        label: '500 stærstu fyrirtækin',
                                        value: '500'
                                    }]}
                                    label='Stærð lista'
                                    size='lg'
                                    defaultValue={listSize}
                                    onChange={(option) => { if (typeof option === 'string') setListSize(option) }}
                                />
                            </div>
                            <div className="form__col">
                                <DropSelect
                                    options={[{
                                        label: 'ÍSAT flokki',
                                        value: 'ISAT'
                                    }, {
                                        label: 'Póstnúmeri / sveitarfélagi',
                                        value: 'ZIP'
                                    }, {
                                        label: 'Annað',
                                        value: 'Annad'
                                    }]}
                                    label='Fyrirtæki eftir'
                                    size='lg'
                                    defaultValue={companyGroupType}
                                    onChange={(option) => { if (typeof option === 'string') setCompanyGroupType(option) }}
                                />
                            </div>
                            <div className="form__col">
                                <DropSelect
                                    options={[{
                                        label: 'Tekjum',
                                        value: 'Tekjum'
                                    }, {
                                        label: 'Eignum',
                                        value: 'Eignum'
                                    }, {
                                        label: 'Annað',
                                        value: 'Annad'
                                    }]}
                                    label='Stærð út frá'
                                    size='lg'
                                    defaultValue={sizeGroupType}
                                    onChange={(option) => { if (typeof option === 'string') setSizeGroupType(option) }}
                                />
                            </div>
                        </div>

                        <h5>Annað sem þarf að taka fram?</h5>
                        <div className="form__row">
                            <div className="form__col">
                                <Input
                                    inputType="textArea"
                                    label="Athugasemdir"
                                    name="message"
                                    id="message"
                                    value={values.message}
                                />
                            </div>
                        </div>

                        {(contactResponse === null)
                            ? null
                            : (
                                <div className="form__row">
                                    <div className="form__col">
                                        {(contactResponse instanceof Error)
                                        ? <Alert type='error' headText={contactResponse.message} />
                                        : <Alert type='info' headText={contactResponse} />}
                                    </div>
                                </div>
                            )
                        }
                        <Recaptcha position='center' setToken={(e: string | null) => setCaptchaToken(e)} />
                    </div>
                </div>
                <div className="form__actions">
                    <Button showLoader={loading} buttonType="primary" size="lg">Fá tilboð</Button>
                </div>
            </Form>
        );
    }
