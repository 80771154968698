// Package imports:
import React, { useEffect, useMemo, useRef } from 'react';
import Highcharts from 'highcharts';
// Service imports:
import { formatNumber, convertToPercentage } from '../../services/utils';
// Type imports:
import { IApiLmdGenderLensSnapshot } from '../../types/HlutabrefTypes';

type Language = 'is' | 'en';

interface IProps {
    data: IApiLmdGenderLensSnapshot[],
    lang?: Language,
    height?: number,
    showHeader?: boolean
}

const getColor = (pct: number | null) => {
    if (pct === null) return '#F1F2F5';
    if (pct < 0.1) return '#4353D8';
    if (pct < 0.2) return '#5A8EEE';
    if (pct < 0.3) return '#55CCA1';
    if (pct < 0.4) return '#FBD825';
    if (pct < 0.5) return '#FAB530';
    return '#EB5CA4';
}

const GEMMAQChart: React.FC<IProps> = ({
    data,
    lang = 'is',
    height = 300,
    showHeader
}) => {
    // Div references.
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (divRef.current === null) return;
        if (data.length > 0) {
            Highcharts.chart(divRef.current, {
                chart: {
                    type: 'pie',
                    height: 305,
                    style: {
                        fontFamily: "Roboto"
                    },
                    animation: false
                },
                title: {
                    text: (lang === 'is' ? formatNumber(data[0].GenderLensScore, null, 1) : data[0].GenderLensScore?.toString()) ?? undefined,
                    align: 'center',
                    x: 0,
                    floating: true,
                    y: 152.5,
                    style: {
                        fontSize: '40px',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto'
                    }
                },
                subtitle: showHeader ? {
                    text: data[0].IssuerName ?? '',
                    verticalAlign: 'top',
                    style: {
                        fontSize: '18px',
                        color: '#000'
                    }
                } : {},
                plotOptions: {
                    pie: {
                        size: showHeader ? '80%' : '90%',
                        center: ['50%','50%'],
                        animation: false
                    }
                },
                responsive: {
                    rules: [{
                        condition: {
                            callback: function() {
                                return window.matchMedia('(max-width: 767px)').matches;
                            }
                        },
                        chartOptions: {
                            chart: {
                                height: 265
                            },
                            title: {
                                style: {
                                    fontSize: '30px'
                                },
                                y: 132.5
                            }
                        }
                    }]
                },
                series: [{
                    type: 'pie',
                    innerSize: '40%',
                    data: [{
                        name: '',
                        y: 1,
                        color: data[0].ChairmanOfTheBoard === 'male' ? getColor(0) : getColor(1)
                    }, {
                        name: '',
                        y: 1,
                        color: getColor(data[0].WomenOnBoardPer)
                    }, {
                        name: '',
                        y: 1,
                        color: getColor(data[0].WomenDirectorsPer)
                    }, {
                        name: '',
                        y: 1,
                        color: data[0].Ceo === 'male' ? getColor(0) : getColor(1)
                    }],
                    dataLabels: {
                        enabled: false
                    }
                }],
                exporting: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    enabled: false
                },
                legend: {
                    enabled: false
                }
            }, () => {});
            return;
        }
        Highcharts.chart(divRef.current, {
            chart: {
                type: 'pie',
                height: height,
                style: {
                    fontFamily: "Roboto"
                },
                animation: false
            },
            title: {
                text: lang === 'is' ? 'Gögn vantar' : 'No data',
                align: 'center',
                x: 0,
                floating: true,
                y: 0.48*height,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    fontFamily: 'Roboto'
                }
            },
            plotOptions: {
                pie: {
                    size: '90%',
                    center: ['50%','50%'],
                    animation: false
                }
            },
            series: [{
                type: 'pie',
                innerSize: '40%',
                data: [{
                    name: '',
                    y: 1,
                    color: getColor(null)
                }, {
                    name: '',
                    y: 1,
                    color: getColor(null)
                }, {
                    name: '',
                    y: 1,
                    color: getColor(null)
                }, {
                    name: '',
                    y: 1,
                    color: getColor(null)
                }],
                dataLabels: {
                    enabled: false
                }
            }],
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            tooltip: {
                enabled: false
            },
            legend: {
                enabled: false
            }
        }, () => {});
    }, []);

    const { ceoGender, ceoColor } = useMemo(() => {
        if (data.length === 0) return { ceoGender: '-', ceoColor: null };
        if (lang === 'is') {
            if (data[0].Ceo === 'male') return { ceoGender: 'Karl', ceoColor: 0 };
            if (data[0].Ceo === 'female') return { ceoGender: 'Kona', ceoColor: 1 };
            return { ceoGender: '-', ceoColor: null };
        }
        if (data[0].Ceo === 'male') return { ceoGender: 'Male', ceoColor: 0 };
        if (data[0].Ceo === 'female') return { ceoGender: 'Female', ceoColor: 1 };
        return { ceoGender: '-', ceoColor: null };
    }, [data, lang]);

    const { chairmanGender, chairmanColor } = useMemo(() => {
        if (data.length === 0) return { chairmanGender: '-', chairmanColor: null };
        if (lang === 'is') {
            if (data[0].ChairmanOfTheBoard === 'male') return { chairmanGender: 'Karl', chairmanColor: 0 };
            if (data[0].ChairmanOfTheBoard === 'female') return { chairmanGender: 'Kona', chairmanColor: 1 };
            return { chairmanGender: '-', chairmanColor: null };
        }
        if (data[0].ChairmanOfTheBoard === 'male') return { chairmanGender: 'Male', chairmanColor: 0 };
        if (data[0].ChairmanOfTheBoard === 'female') return { chairmanGender: 'Female', chairmanColor: 1 };
        return { chairmanGender: '-', chairmanColor: null };
    }, [data, lang]);

    const dict = lang === 'is' ? {
        ceo: 'Forstjóri',
        chairman: 'Stjórnarformaður',
        top_execs: 'Hlutfall kvenna í\nframkvæmdastjórn',
        board: 'Hlutfall kvenna í\nstjórn'
    } : {
        ceo: 'CEO',
        chairman: 'Chairman of the board',
        top_execs: 'Top executives',
        board: 'Board of directors'
    }

    return (
        <div className='KCL_GEMMAQ-chart'>
            <div ref={divRef}></div>
            <ul className="GEMMAQ-chart__legend">
                <li>
                    <p>{dict.ceo}</p>
                    <p>
                        <strong>
                            {ceoGender}
                        </strong>
                        <span className="color" style={{
                            background: getColor(ceoColor)
                        }}></span>
                    </p>
                </li>
                <li>
                    <p>{dict.chairman}</p>
                    <p>
                        <strong>
                            {chairmanGender}
                        </strong>
                        <span className="color" style={{
                            background: getColor(chairmanColor)
                        }}></span>
                    </p>
                </li>
                <li>
                    <p>{dict.top_execs}</p>
                    <p>
                        <strong>{convertToPercentage(data[0]?.WomenDirectorsPer ?? null, true, 0)}</strong>
                        <span className="color" style={{
                            background: getColor(data[0]?.WomenDirectorsPer ?? null)
                        }}></span>
                    </p>
                </li>
                <li>
                    <p>{dict.board}</p>
                    <p>
                        <strong>{convertToPercentage(data[0]?.WomenOnBoardPer ?? null, true, 0)}</strong>
                        <span className="color" style={{
                            background: getColor(data[0]?.WomenOnBoardPer ?? null)
                        }}></span>
                    </p>
                </li>
            </ul>
        </div>
    );
}

export default GEMMAQChart;