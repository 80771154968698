// Package imports:
import React from 'react';
// Component imports:
import Alert from '../../ui-elements/Alert/Alert';

interface IOwnProps {
    error: Error,
    useErrorFromApi?: boolean,
}

const ErrorAlert: React.FC<IOwnProps> = ({
    error,
    useErrorFromApi
}) => {
    if (useErrorFromApi) 
        return  (
            <Alert
                type='error'
                headText={error.name}
                text={<p>{error.message}</p>}
            />
        )
    else 
        return(
            <Alert
                type='alert'
                headText='Ekki tókst að sækja gögn'
            />
        )
}

export default ErrorAlert