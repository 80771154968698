// Package imports:
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUser } from '@fortawesome/pro-solid-svg-icons';
import ScrollLock from 'react-scrolllock';
import {use100vh} from 'react-div-100vh'
// Component imports:
import NavDropdown, { INavItem } from '../Nav/NavDropdown';
// Type imports:
import { IUserInfo } from '../../../types/Types';

interface IOwnProps {
    isMobileNavMenuOpen?: boolean,
    userInfo: IUserInfo,
    highlighted?: boolean
}

const LoggedIn: React.FC<IOwnProps> = ({
    isMobileNavMenuOpen = false,
    userInfo,
    highlighted
}) => {
    const height = use100vh();

    const getNavItems = () => {
        let navItems: INavItem[][] = [[{
            link: '/Min-Kelda/Keyptar-Skyrslur',
            label: 'Keyptar skýrslur'
        }]]
        if (userInfo.isSubscriber) {
            navItems[0].push({
                link: '/Min-Kelda/Voktun',
                label: 'Vöktun'
            })
        }
        if (userInfo.isAdmin) {
            navItems[0].push({
                link: '/Min-Kelda/Reikningar',
                label: 'Reikningar'
            })
        }
        navItems[0].push({
            link: '/Min-Kelda/Stillingar',
            label: 'Stillingar'
        })
        navItems[0].push({
            link: '/Utskraning',
            label: <>
                <span className="logout-icon"><FontAwesomeIcon icon={faSignOutAlt}/></span>
                Útskrá
            </>
        })
        return navItems;
    }

    return (
        <ScrollLock isActive={isMobileNavMenuOpen}>
            <div style={isMobileNavMenuOpen ? {height: height?.toString() + 'px'} : {}} className='KCL_nav-container KCL_logged-in'>
                <ul className='nav__main'>
                    <NavDropdown
                        label={<span>
                            <FontAwesomeIcon icon={faUser} className='user-icon' />
                            <span className='username'>{userInfo.name}</span>
                        </span>}
                        navItems={getNavItems()}
                        highlighted={highlighted}
                        openByDefault={isMobileNavMenuOpen}
                    />
                </ul>
            </div>
        </ScrollLock>
    );
}

export default LoggedIn;