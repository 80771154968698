// Package imports:
import React from 'react';
// Component imports:
import SingleNewsPage from '../../components/SingleNewsPage/SingleNewsPage';
import Entrypoint from '../Entrypoint';
import { IAuthenticationWithId } from '../../types/Types';

const NewsWatch: React.FC<IAuthenticationWithId> = ({
    isAuthenticated,
    isSubscriber,
    id
}) => {
    return (
        <Entrypoint>
            <SingleNewsPage id={id} isAuthenticated={isAuthenticated} isSubscriber={isSubscriber}/>
        </Entrypoint>
    );
}

export default NewsWatch;