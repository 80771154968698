// Package imports:
import React, { useContext, useEffect, useState } from 'react';
// Component imports:
import AdRotator from '../Ad/AdRotator';
import SpecialFundsTable from '../SpecialFundsTable/SpecialFundsTable';
import SpecialFundsChart from '../Charts/SpecialFundsChart/SpecialFundsChart';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
// Service imports:
import { DEFAULT_REFRESH_RATE, MARKET_REFRESH_RATES } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';
// Context imports:
import { AccessTokenContext } from '../../contexts/AccessTokenContext';


const SpecialFundsPage: React.FC = () => {
    const accessToken = useContext(AccessTokenContext);
    const [ defaultProps, setDefaultProps ] = useState<IDefaultProps>({
        accessToken,
        refreshRateMs: DEFAULT_REFRESH_RATE
    });

    useEffect(() => {
        setDefaultProps({
            ...defaultProps,
            accessToken
        });
    }, [accessToken]);

    return (
        <div className='main KCL_special-funds-page KCL_market-page'>
            <div className='main__inner-fluid'>
                <div className="shell">
                    <HeadSection 
                        hasAds='market'
                        title='Séreignarsjóðir'
                    /> 
                    <div>
                        <div className='grid-items'>
                            <div className='grid-item'>
                                <SpecialFundsTable
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['SPECIALFUNDS']}
                                    id="SpecialFundsPage_Table"
                                />
                            </div>
                        </div>
                        <div className='middle-ad-section'>
                            <AdRotator location='Market1018x360' />
                        </div>
                        <div className='grid-items'>
                            <div className='grid-item'>
                                <SpecialFundsChart
                                    title='Samanburður'
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['SPECIALFUNDS']}
                                    id="SpecialFundsPage_Chart"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SpecialFundsPage;