// Package imports:
import React, { useState } from 'react';
// Component imports:
import Avatar, { AvatarNames } from '../../ui-elements/Avatar/Avatar';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../ui-elements/Link/Link';
import Fyrirvari from '../Fyrirvari/Fyrirvari';
import LmdTable from '../../ui-elements/Table/LmdTable';
// Service imports:
import { changeIssuerName, convertToPercentage, getLastDayOfLastMonth, getTableDate } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
import { CUSTOM_REFRESH_RATES } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdFundPrice } from '../../types/FundsTypes';

type Props = IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const Sereignarsjodir: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    ...props
}) => {

    const [ specialFunds ] = useApiLmdData<IApiLmdFundPrice[]>(
        `/v1/market_data/v1/specialfunds/keldan/by_date?date=${getLastDayOfLastMonth()}`,
        accessToken,
        CUSTOM_REFRESH_RATES['SEREIGNASJODIR']
    );
    
    const [disclaimerOpen, setDisclaimerOpen] = useState(false);
    const openDisclaimer = () => setDisclaimerOpen(true);
    const closeDisclaimer = () => setDisclaimerOpen(false);

    const handleData = () => {
        const { data, error } = specialFunds;
        if (data === null) return specialFunds;
        const filterData = (groupname: string) => {
            return data.filter(x => x.Symbol?.startsWith(groupname));
        }

        return {
            data: {
                "Frjálsi lífeyrissjóðurinn": filterData('frjalsi'),
                "Íslandsbanki": filterData('isl'),
                "Íslenski lífeyrissjóðurinn": filterData('lif')
            },
            error
        }
    }
    
    return (
        <DisplayBox
            className="KCL_sereignarsjodir"
            title="Séreignarsjóðir"
            anchorProps={{ href: '/Markadir/Sereignarsjodir' }}
            displayBoxSize="sm"
            footerRight={
                <Fyrirvari
                    show={disclaimerOpen}
                    open={openDisclaimer}
                    close={closeDisclaimer}
                >
                    <p className='paragraph'>Upplýsingar um þá verðbréfa- (auðkenndir með bókstafnum „V“ fyrir aftan nafn sjóðs) og sérhæfða sjóði fyrir almenna fjárfesta (auðkenndir með bókstafnum „A“ fyrir aftan nafn sjóðs) sem birtar eru á vef Keldunnar byggja á upplýsingum frá rekstrarfélögum viðkomandi sjóða og eru settar fram á ábyrgð þeirra. Jafnframt skal tekið fram að þær upplýsingar sem birtast á vefnum geta breyst án fyrirvara og er áskilinn réttur til leiðréttinga.</p>
                    <p className='paragraph'>Upplýsingar sem birtar eru á vef Keldunnar fela á engan hátt í sér ráðleggingar um tilteknar fjárfestingar, sem sagt kaup eða sölu tiltekinna fjármálagerninga, eða ráðleggingar um að ráðast ekki í tilteknar fjárfestingar. Notendur vefsins bera því einir ábyrgð á þeim fjárfestingaákvörðunum sem þeir taka með hliðsjón af upplýsingum sem birtar eru á vefnum. Er notendum bent á að leita ráðleggingar áður en þeir taka ákvörðun varðandi mögulega fjárfestingu.</p>
                    <p className='paragraph'>Ávöxtunartölur miðast við nafnávöxtun sjóða og er ávöxtun umfram 12 mánuði umreiknuð í ávöxtun á ársgrundvelli, en styttri tímabil eru ekki umreiknuð. Varðandi myndræna framsetningu skal tekið fram að stofndagur sjóða er mismunandi og því ekki hægt að bera ávöxtun sjóða saman lengra aftur í tíma en sem nemur stofndegi þess sjóðs sem er yngstur.</p>
                    <p className='paragraph'>Rétt er að benda á að verðbreytingar í fortíð gefa ekki endilega vísbendingu um verðbreytingar í framtíð. Að auki eru sjóðirnir mismunandi að uppbyggingu og því getur áhætta verið mismunandi. Nánari upplýsingar um fjárfestingar sjóðanna er að finna á vefsíðu viðkomandi rekstrarfélaga. Útboðslýsingar eru aðgengilegar á heimasíðum rekstrarfélaganna og hjá söluaðilum. Þeir sem hyggja á fjárfestingar eru hvattir til að kynna sér útboðslýsingar sjóða, sérstaklega umfjöllun um áhættur.</p>
                </Fyrirvari>
            }
            {...props}
        >
            <LmdTable
                id={props.id}
                apiData={handleData()}
                columns={[{
                    title: 'Félag',
                    renderCell: ({ Symbol, IssuerUrl, IssuerName }) => (Symbol === null)
                        ? null
                        : (
                            <a
                                href={IssuerUrl ?? ''}
                                target="_blank"
                                rel='noreferrer'
                                aria-label={IssuerName ?? ''}
                            >
                                <Avatar
                                    name={changeIssuerName(IssuerName) as AvatarNames}
                                    size="sm"
                                    color
                                    backgroundGrey
                                />
                            </a>
                        ),
                    textAlign: 'left'
                }, {
                    title: 'Sjóður',
                    renderCell: ({ Symbol, Name }) => (
                        <Link
                            wrapTextForMobile
                            url={`/Markadir/Sjodir/${Symbol}`}
                            linkSize='13'
                        >
                            {Name}
                        </Link>
                    ),
                    textAlign: 'left',
                    simpleSortable: ({Name}) => Name
                }, {
                    title: 'Dags.',
                    renderCell: ({ValueDate}) => {
                        return getTableDate(ValueDate, 'DD/MM/YY', '.')
                    },
                    simpleSortable: ({ValueDate}) => (ValueDate === null) ? null : new Date(ValueDate).getTime()
                }, {
                    title: '12M',
                    renderCell: ({Change12m}) => {
                        return convertToPercentage(Change12m, true)
                    },
                    simpleSortable: ({Change12m}) => Change12m
                }, {
                    title: '3ár',
                    renderCell: ({Change36m}) => {
                        return convertToPercentage(Change36m, true)
                    },
                    simpleSortable: ({Change36m}) => Change36m
                }, {
                    title: '10ár',
                    renderCell: ({Change120m}) => {
                        return convertToPercentage(Change120m, true)
                    },
                    simpleSortable: ({Change120m}) => Change120m
                }]}
                expandableRowConfig={{
                    defaultExpandedIndexes: [0,1,2],
                    showNumberOfItemsInExpandable: true
                }}
            />
        </DisplayBox>
    );
}

export default Sereignarsjodir;