// Package imports:
import React from 'react';
// Component imports:
import GjaldmidlarPage from '../../components/GjaldmidlarPage/GjaldmidlarPage';
import Entrypoint from '../Entrypoint';

interface IOwnProps {
    symbol?: string
}

const Currencies: React.FC<IOwnProps> = ({
    symbol
}) => {
    return (
        <Entrypoint>
            <GjaldmidlarPage defaultSymbol={symbol} />
        </Entrypoint>
    );
}

export default Currencies;