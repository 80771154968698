// Package imports:
import React, { createContext, useEffect, useRef, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import jwt_decode from 'jwt-decode';
// Service imports;
import { GET_KELDAN_API_URL } from '../services/config';
// Type imports:
import { Fetched } from '../types/Types';

const REFRESH_TOKEN_CHECK_INTERVAL_MS = 1*60*60*1000; // every hour.

interface IDecodedToken {
    username: string,
    subscriptions?: Array<string>,
    iss: string,
    system?: any,
    exp: number,
    iat: number
}

interface IAccessTokenResponse {
    accessToken: string
}

interface IProps {
    accessToken?: string
}

export const AccessTokenContext = createContext<string | undefined>(undefined);

const AccessTokenContextProvider: React.FC<IProps> = ({
    children
}) => {
    const [ accessTokenState, setAccessTokenState ] = useState<Fetched<string>>(null);
    const [ isTabActive, setIsTabActive ] = useState(true); 
    const decodedTokenRef = useRef<IDecodedToken | null>(null);
    const accessTokenRef = useRef(accessTokenState);

    // Get new token request.
    const fetchNewToken = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Home/GetLiveMarketDataToken`;
            const response = await fetch(url);
            if (response.ok) {
                const body: IAccessTokenResponse = await response.json();
                setAccessTokenState(body.accessToken)
            } else {
                setAccessTokenState(new Error('Token Request Failed'));
            }
        } catch (e) {
            setAccessTokenState(new Error('Token Network Error'));
        }
    }

    const checkIfExpiredAndFetchNewToken = () => {
        const decodedToken = decodedTokenRef.current;
        if (decodedToken === null) return;
        const tokenExpiresAt = new Date(decodedToken.exp * 1000); // js uses millisecond.
        const thresholdTimeForRefresh = new Date(Date.now() + (2 * REFRESH_TOKEN_CHECK_INTERVAL_MS));
        const isTokenGoingToExpire = (tokenExpiresAt < thresholdTimeForRefresh);
        if (isTokenGoingToExpire) {
            fetchNewToken();
        }
    }

    const decodeToken = (accessToken: Fetched<string>): IDecodedToken | null => {
        try {
            if (accessToken === null) throw new Error('No Token')
            if (accessToken instanceof Error) throw accessToken;
            const decodedToken = jwt_decode(accessToken);
            return decodedToken as IDecodedToken;
        }
        catch (e) {
            if (e instanceof Error && e.message !== 'No Token') Bugsnag.notify(e);
            return null;
        }
    }

    // Update isTabActive, so that we know when the user returns to the site after inactivity.
    useEffect(() => {
        const setActive = () => { setIsTabActive(true); }
        const setInactive = () => { setIsTabActive(false); }
        window.addEventListener('focus', setActive);
        window.addEventListener('blur', setInactive);
        return () => {
            window.removeEventListener('focus', setActive);
            window.removeEventListener('blur', setInactive);
        }
    }, []);

    // Check if expired when tab becomes active/inactive
    useEffect(() => {
        if (accessTokenState) checkIfExpiredAndFetchNewToken();
    }, [isTabActive])

    // Update refs for interval check. Intervals can't read state accurately.
    useEffect(() => {
        accessTokenRef.current = accessTokenState;
        decodedTokenRef.current = decodeToken(accessTokenState);
    }, [accessTokenState]);

    // Set interval check to check if token has expired.
    useEffect(() => {
        const intervalId = window.setInterval(() => {
            checkIfExpiredAndFetchNewToken();
        }, REFRESH_TOKEN_CHECK_INTERVAL_MS);
        return () => window.clearInterval(intervalId);
    });

    // Initial Fetch.
    useEffect(() => {
        fetchNewToken();
    }, []);

    return (
        <AccessTokenContext.Provider value={(typeof accessTokenState === 'string') ? accessTokenState : undefined}>
            {children}
        </AccessTokenContext.Provider>
    );
}

export default AccessTokenContextProvider;
