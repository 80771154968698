// Package imports:
import React, { useMemo, useState } from 'react';
import cx from 'classnames';
// Component imports:
import Alert from '../../ui-elements/Alert/Alert';
import Loading from '../../ui-elements/Loading/Loading';
import HeatMap, { HeatMapColor, IHeatMapData, TimePeriod } from './HeatMap';
// Service imports:
import { formatNumber, convertToPercentage } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IApiLmdStatistics } from '../../types/HlutabrefTypes';
import { IApiLmdSnapshot, IApiLmdTradables } from '../../types/SkuldabrefTypes';
import { IDefaultProps } from '../../types/Types';

type Props = IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const BondsHeatMap: React.FC<Props> = ({
    refreshRateMs,
    accessToken,
    ...props
}) => {
    const [statistics] = useApiLmdData<IApiLmdStatistics[]>(
        '/v1/market_data/v1/exchanges/XICE/markets/ICECB/tradables/*/statistics',
        accessToken,
        refreshRateMs
    );
    const [timePeriod, setTimePeriod] = useState<TimePeriod>('intraday');
    const [activeBonds] = useApiLmdData<IApiLmdSnapshot[]>(
        '/v1/market_data/v1/exchanges/XICE/active/bonds/delayed_snapshot',
        accessToken,
        refreshRateMs
    );
    const [staticDataBonds] = useApiLmdData<IApiLmdTradables[]>(
        '/v1/static_data/v1/exchanges/XICE/tradables/*?skip_expired=true&bond=true&share=false',
        accessToken,
        refreshRateMs
    );

    const staticEval = (symbol: string | null) => {
        const { data } = staticDataBonds;
        if (symbol === null || data === null) return false;
        const staticEntry = data.find(e => e.Symbol === symbol);
        if (staticEntry?.ListingDate && staticEntry?.ListingDate > periodStartDate) return true;
        return undefined;
    };

    const marketEval = (stat: IApiLmdStatistics) => {
        if (timePeriod === 'intraday') return false;
        const tradeCount = stat[`${timePeriod}_trade_count`];
        if (tradeCount && tradeCount > 0) return true;
        return undefined;
    };

    const periodStartDate = useMemo(() => {
        const today = new Date();
        if (timePeriod === 'weeks4') return new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        if (timePeriod === 'months3') return new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
        if (timePeriod === 'year') return new Date(today.getFullYear());
        return new Date();
    }, [timePeriod]);

    const getIntradayData = () => {
        if (activeBonds.data === null) return [];
        return activeBonds.data.map(entry => {
            const changeVal = entry.last_yield_diff === null ? 0 : entry.last_yield_diff * 10000;
            let color: HeatMapColor = 'neutral';
            if (changeVal !== null && changeVal < 0) color = 'pos';
            if (changeVal !== null && changeVal > 0) color = 'neg';
            return {
                symbol: entry.symbol,
                color: color,
                value: entry.last_yield ?? 0,
                changeValue: changeVal
            };
        }) as IHeatMapData[];
    }

    const getHistoricalData = () => {
        if (timePeriod === 'intraday') return getIntradayData();
        if (staticDataBonds.data === null || statistics.data === null) return [];
        const newData: IHeatMapData[] = [];
        staticDataBonds.data.forEach(entry => {
            const statsEntry = statistics.data?.find(e => e.symbol === entry.Symbol);
            const static_eval = staticEval(entry.Symbol);
            const market_eval = statsEntry && marketEval(statsEntry);
            if ((static_eval === false || !market_eval) && (!static_eval || market_eval === false)) return;
            const yieldVal = statsEntry ? statsEntry[`${timePeriod}_yield`] : 0;
            const changeVal = !statsEntry?.last_yield || yieldVal === null ? 0 : (statsEntry?.last_yield - yieldVal) * 10000;
            let color: HeatMapColor = 'neutral';
            if (changeVal !== null && changeVal < 0) color = 'pos';
            if (changeVal !== null && changeVal > 0) color = 'neg';
            newData.push({
                symbol: entry.Symbol,
                color: color,
                value: statsEntry?.last_yield ?? 0,
                changeValue: changeVal
            });
        });
        return newData;
    }

    const displayData = useMemo(() => {
        const newData = timePeriod === 'intraday'
            ? getIntradayData()
            : getHistoricalData();
        newData.sort((a, b) => {
            const aval = a.changeValue;
            const bval = b.changeValue;
            if (aval === null && bval === null) return 0;
            if (aval === null) return -1;
            if (bval === null) return 1;
            return aval - bval;
        });
        return newData;
    }, [statistics, staticDataBonds, activeBonds, timePeriod]);
    const dataLength = displayData.length;

    const displayBody = () => {
        if (statistics.error instanceof Error) {
            return <Alert type='error' headText={statistics.error.message}/>
        }
        if (statistics === null) {
            return <Loading />
        }
        if (activeBonds.error instanceof Error) {
            return <Alert type='error' headText={activeBonds.error.message}/>
        }
        if (activeBonds.data === null) {
            return <Loading />
        }
        return <div className="heat-map-body">
            <div className='heat-map-container'>
                {displayData.map((datum, i) => {
                    return <a key={datum.symbol}
                        href={`/Markadir/Skuldabref/${datum.symbol}`}
                        className={cx(
                            "heat-map-item",
                            datum.color,
                            { 'first': i === 0, 'last': i === dataLength - 1 }
                        )}
                    >
                        <span className="symbol">{datum.symbol}</span>
                        <span className="values">
                            {convertToPercentage(datum.value, true)} ({formatNumber(datum.changeValue, null, 0)} pkt.)
                        </span>
                    </a>
                })}
            </div>
        </div>
    }

    return (
        <HeatMap
            timePeriod={timePeriod}
            setTimePeriod={setTimePeriod}
            displayBody={displayBody}
            {...props}
        />
    );
}

export default BondsHeatMap;