// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import CompanySubPage from '../../components/Company/CompanySubPage/CompanySubPage';
import CompanyPapersLarge from '../../components/Company/CompanyPapers/CompanyPapersLarge';
// Type imports:
import { ICompanyViewModel } from '../../types/CompanyTypes';

const CompanyPapersPage: React.FC<ICompanyViewModel> = ({
    id,
    isAuthenticated,
    isSubscriber
}) => {
    return (
        <Entrypoint>
            <CompanySubPage 
                id={id} 
                title='Fyrirtækjaskrá'
                isAuthenticated={isAuthenticated}
                isSubscriber={isSubscriber}
                component={
                    <CompanyPapersLarge isAuthenticated={isAuthenticated}  id={id} />
                } 
            />
        </Entrypoint>
    );
}

export default CompanyPapersPage;