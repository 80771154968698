// Package imports:
import React, { useEffect, useState } from 'react';
// Component imports:
import Label from '../../ui-elements/Label/Label';

const DelayOrClosedLabel: React.FC = () => {
    const openTime = new Date();
    const closeTime = new Date();
    openTime.setUTCHours(9);
    openTime.setUTCMinutes(30);
    closeTime.setUTCHours(15);
    closeTime.setUTCMinutes(30);
    const [isOpen, setIsOpen] = useState(openTime.getTime() < Date.now() && Date.now() < closeTime.getTime());

    useEffect(() => {
        const intervalId = window.setInterval(() => {
            setIsOpen(openTime.getTime() < Date.now() && Date.now() < closeTime.getTime())
        }, 60000);
        // Clean up: clear interval to avoid memory leaks
        return () => window.clearInterval(intervalId);
    })
    return (
        <div className='KCL_delay-or-closed'>
            {isOpen
                ? <Label labelType="delay" text="15m" fullText="15 mín. seinkun" />
                : <Label labelType="closed" text="Lokað í kauphöll" />
            }
        </div>
    );
}

export default DelayOrClosedLabel;