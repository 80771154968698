// Package imports:
import React from "react";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
// Component imports:
import Modal from "../../ui-elements/Modal/Modal";
import Button from "../../ui-elements/Button/Button";
import Link from "../../ui-elements/Link/Link";
import Input from "../../ui-elements/Input/Formik/Input";

interface IProps {
    show: boolean,
    close(): void,
    submit(reference: string): void,
    dataSourceText: string,
    customText?: string | JSX.Element,
    customHeader?: string,
    initialReference?: string
};

interface IFormProps {
    values: IFormValues
}
interface IFormValues {
    reference: string
}

const SearchConfirmModalWithReference: React.FC<IProps> = ({
    show,
    close,
    submit,
    dataSourceText,
    customText,
    customHeader,
    initialReference = ''
}) => {
    const handleSubmit = (values: IFormValues) => {
        if (show) {
            submit(values.reference);
        }
    }

    const handleCancel = () => {
        if (show) {
            close();
        }
    }

    const form: React.FC<IFormProps> = ({
        values
    }) => {
        return <Form>
            <div className="form__section">
                <div className="form__body">
                    <div className="form__row">
                        <div className="form__col">
                            <Input
                                label="Tilvísun á reikning"
                                name="reference"
                                id="reference"
                                value={values.reference}
                                autoFocus
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="form__actions">
                <Button
                    buttonType='secondary'
                    type='reset'
                >
                    Hætta við
                </Button>
                <Button
                    type="submit"
                > 
                    Halda áfram
                </Button>
            </div>
        </Form>
    }

    return (
        <Modal
            className="KCL_search-confirm-modal"
            show={show}
            onHide={close}
            header={customHeader ?? `Kaupa gögn úr ${dataSourceText}`}
            body={
                <>
                    <div className="infoSection">
                        {(customText)
                            ? customText
                            : (
                                <p className="paragraph">
                                    Þú ert að fara að kaupa gögn úr {dataSourceText}. Gögn eru samkvæmt
                                    <Link className='VerdskraLink' linkSize='18' url="/Verdskra#verdskra">verðskrá Keldunnar</Link>.
                                </p>
                            )
                        }
                        <p className="paragraph">Þú getur sett tilvísun á reikninginn hér fyrir neðan:</p>
                    </div>
                    <Formik
                        initialValues={{
                            reference: initialReference
                        }}
                        validationSchema={Yup.object({
                            reference: Yup.string()
                        })}
                        onSubmit={handleSubmit}
                        onReset={handleCancel}
                        component={form}
                    />
                </>
            }
        />
    );
}

export default SearchConfirmModalWithReference;
