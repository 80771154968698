// Package imports:
import React, { useState } from 'react';
// Component imports:
import Link from '../../../ui-elements/Link/Link';
import Modal from '../../../ui-elements/Modal/Modal';


const EbitdaMultiplierInfo: React.FC = () => {
    const [ modalOpen, setModalOpen ] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    return (
        <>
            <Link
                className='ebitda-multiplier-link'
                url=''
                linkSize='16'
                onClick={(e) => {
                    e.preventDefault();
                    openModal();
                }}
                icon='more'
            >
                Nánar um EV/EBITDA margfaldara
            </Link>
            <Modal
                show={modalOpen}
                hasCloseButton
                onHide={closeModal}
                header='EV/EBITDA margfaldarar'
                body={<div>
                    <p className='paragraph'>
                        Það getur verið gagnlegt að nota EV/EBITDA margfaldara sem byggður er á gögnum um fyrirtæki sem skráð eru á skipulagðan verðbréfamarkað til að meta einkafyrirtæki sem ekki eru skráð á markað. En hafa ber í huga fjölmörg atriði þegar farið er yfir slíkt verðmat:
                    </p>
                    <p className='paragraph'>
                        <span className='paragraph--bold'>Skortur á samanburði:</span> Fyrirtæki skráð á markað og einkafyrirtæki hafa oft mismunandi áhættu, vaxtarmöguleika og fjármála strúktúr. Beinn samanburður þeirra gefur mögulega ekki rétta mynd.
                    </p>
                    <p className='paragraph'>
                        <span className='paragraph--bold'>Seljanleiki:</span> Verðmat skráðra félaga sveiflast með markaðnum og seljanleika á markaði. Einkafyrirtæki skortir þennan seljanleika sem hefur áhrif á verð þeirra.
                    </p>
                    <p className='paragraph'>
                        Auk þessara tveggja atriða má nefna <span className='paragraph--bold'>upplýsingagjöf, stærðar hagræði, aðgang að fjármagni, markaðsaðstæður, takmörkuð gögn, einkafyrirtækja atriði, ráðandi hluti og tíma- og markaðsþætti</span> sem geta skekkt samanburð á milli skráðra og óskráðra félaga.
                    </p>
                    <p className='paragraph'>
                        Til þess að draga úr áhrifum þessara atriða sem að ofan eru nefnd, auk annarra sem ekki hefur verið getið hér, er nauðsynlegt að nálgast niðurstöðurnar af varkárni og nota einnig aðrar matsaðferðir til samanburðar.
                    </p>
                </div>}
                className='KCL_ebitda-multiplier-info'
            />
        </>
    );
}

export default EbitdaMultiplierInfo;