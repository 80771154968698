// Package imports:
import React from 'react';
// Component imports:
import Modal from '../../ui-elements/Modal/Modal';
import { PepResult } from '../../types/PepTypes';
import Link from '../../ui-elements/Link/Link';
import Tabs from '../../ui-elements/Tabs/Tabs';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Alert from '../../ui-elements/Alert/Alert';
import { formatCategories } from './PepPage';

interface IProps {
    show: boolean,
    close(): void,
    data: PepResult | undefined
};

const customStyle = {
    ...vscDarkPlus,
    'property': {
        fontWeight: 'bold'
    }
};
const ForeignPepModal: React.FC<IProps> = ({show, close, data}) =>  {
    
    const acceptedKeys: (keyof PepResult)[] = [
        "category", "categories", "match_rate", "hitRating", "keyWordHitRating", "matchedFields", "flag", "lookupType", 
        "uniqueId", "subcategory", "gender", "deceased", "deceasedDate", "title", "firstName", "companyName", "middleName", 
        "lastName", "fullName", "images", "position", "dateOfBirth", "placeOfBirth", "primaryLocation"
    ];
    const renderValue = (value: any, key?: keyof PepResult ) => {
        //image link
        if(key === 'images') return <Link splitLines wrapTextForMobile url={value} linkSize='15'>{value}</Link>
        //special formatin forr text
        if(key === 'categories') return formatCategories(value)
        //boolean
        if(key === 'deceased') return value.toString()
        return value === "" || value === null ? '-' : value;
    }
    const renderTitle = (key?: keyof PepResult ) => {
        if(key === 'match_rate') return 'Match rate'
        return key
    }

    const modalBody = () => {
        if(!data) return (<Alert type={'alert'} headText={'Engin gögn fundust'} />);
        return (
            <Tabs tabs={[{
                id: "tab1",
                label: "Niðurstöður",
                component: <div className='KCL_foreignPepModal'>
                {Object.keys(data).map((key, index) => {
                    if (acceptedKeys.includes(key as keyof PepResult)) {
                        return <div className='item_container' key={index}>
                            <span className='item_key'>{renderTitle(key as keyof PepResult)}</span>
                            <span className='item_value'>{renderValue(data[key as keyof PepResult], key as keyof PepResult)}</span>
                        </div>;
                    } else {
                        return null
                    }
                })}
            </div>
            }, {
                id: "tab2",
                label:"JSON",
                component: <div style={{overflow: 'auto'}}>
                    <SyntaxHighlighter language="json" style={customStyle}>
                        {JSON.stringify(data, null, 2)}
                    </SyntaxHighlighter>
                </div>
            }]} />
        );
    }
    return <Modal
        show={show}
        onHide={close}
        hasCloseButton
        header={data?.fullName || '-'}
        body={modalBody()}
    />
}

export default ForeignPepModal;
