// Package imports:
import React from 'react';
// Component imports:
import GangverdReportBody from './GangverdReportBody';
// Type imports:
import { IEventReport } from '../../../types/KeldanTypes';
import { IGangverdSearchResult } from '../../../types/PropertyTypes';
// Static data imports:
import EXAMPLE_DATA from '../../../assets/staticData/ExampleReports/Property/GangverdReport.json';

const GangverdReportExample: React.FC = () => {
    return (
        <GangverdReportBody report={EXAMPLE_DATA as IEventReport<IGangverdSearchResult>} isExample />
    );
}

export default GangverdReportExample;