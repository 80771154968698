// Package imports:
import React, { useMemo, useState } from 'react';
import cx from 'classnames';
// Component imports:
import Alert from '../../ui-elements/Alert/Alert';
import Loading from '../../ui-elements/Loading/Loading';
import HeatMap, { HeatMapColor, IHeatMapData, TimePeriod } from './HeatMap';
// Service imports:
import { formatNumber, convertToPercentage } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IApiLmdStatistics } from '../../types/HlutabrefTypes';
import { IApiLmdSnapshot } from '../../types/SkuldabrefTypes';
import { IDefaultProps } from '../../types/Types';

type Props = IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const SharesHeatMap: React.FC<Props> = ({
    refreshRateMs,
    accessToken,
    ...props
}) => {
    const [statistics] = useApiLmdData<IApiLmdStatistics[]>(
        '/v1/market_data/v1/keldan/exchanges/[XICE;FNIS]/statistics',
        accessToken,
        refreshRateMs
    );
    const [timePeriod, setTimePeriod] = useState<TimePeriod>('intraday');
    const [sharesData] = useApiLmdData<IApiLmdSnapshot[]>(
        '/v1/market_data/v1/keldan/exchanges/[XICE;FNIS]/equities/delayed_snapshot',
        accessToken,
        refreshRateMs
    );

    const getIntradayData = () => {
        if (sharesData.data === null) return [];
        return sharesData.data.map(entry => {
            let color: HeatMapColor = 'neutral';
            if (entry.intraday_per_change !== null && entry.intraday_per_change > 0) {
                color = 'pos';
            }
            if (entry.intraday_per_change !== null && entry.intraday_per_change < 0) {
                color = 'neg';
            }
            return {
                symbol: entry.symbol,
                color: color,
                value: entry.last_price,
                changeValue: entry.intraday_per_change
            }
        });
    }

    const getHistoricalData = () => {
        if (timePeriod === 'intraday') return getIntradayData();
        if (statistics.data === null || sharesData.data === null) return [];
        return statistics.data.filter(s => s.security_type === 'share').map(stat => {
            // @ts-ignore
            const dataEntry = sharesData.data.find(e => e.symbol === stat.symbol);
            const changeVal = ((dataEntry?.last_price ?? 0) - (stat[`${timePeriod}_price`] ?? 0))/(stat[`${timePeriod}_price`] ?? 1);
            let color: HeatMapColor = 'neutral';
            if (changeVal !== null && changeVal > 0) color = 'pos';
            if (changeVal !== null && changeVal < 0) color = 'neg';
            return {
                symbol: stat.symbol,
                color: color,
                value: dataEntry?.last_price ?? null,
                changeValue: changeVal
            };
        });
    }

    const displayData = useMemo(() => {
        const newData: IHeatMapData[] = timePeriod === 'intraday'
            ? getIntradayData()
            : getHistoricalData();
        newData.sort((a, b) => {
            const aval = a.changeValue;
            const bval = b.changeValue;
            if (aval === null && bval === null) return 0;
            if (aval === null) return 1;
            if (bval === null) return -1;
            return bval - aval;
        });
        return newData;
    }, [statistics, sharesData, timePeriod]);
    const dataLength = displayData.length;

    const displayBody = () => {
        if (statistics instanceof Error) {
            return <Alert type='error' headText={statistics.message}/>;
        }
        if (statistics === null) {
            return <Loading />;
        }
        return <div className="heat-map-body">
            <div className='heat-map-container'>
                {displayData.map((datum, i) => {
                    return <a key={datum.symbol}
                        href={`/Markadir/Hlutabref/${datum.symbol}`}
                        className={cx(
                            "heat-map-item",
                            datum.color,
                            { 'first': i === 0, 'last': i === dataLength - 1 }
                        )}
                    >
                        <span className="symbol">{datum.symbol}</span>
                        <span className="values">
                            {formatNumber(datum.value, null, 2)} ({convertToPercentage(datum.changeValue, true)})
                        </span>
                    </a>
                })}
            </div>
        </div>
    }

    return (
        <HeatMap
            timePeriod={timePeriod}
            setTimePeriod={setTimePeriod}
            displayBody={displayBody}
            {...props}
        />
    );
}

export default SharesHeatMap;