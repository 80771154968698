// Package imports:
import React from 'react';
// Component imports:
import Flag, { FlagNames } from '../../../ui-elements/Flag/Flag';
import LmdTable from '../../../ui-elements/Table/LmdTable';
// Service imports:
import { formatNumber, getTableDate } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../../types/Types';
import { IApiLmdCurrency } from '../../../types/CurrencyTypes';

const CURRENCIES = ['CAD','CHF','DKK','EUR','GBP','JPY','NOK','SEK','USD','XDR'];

type Props = IDefaultProps;

const Sedlabankinn: React.FC<Props> = ({
    accessToken,
    refreshRateMs
}) => {
    const [ sedlabankinnCurrencies ] = useApiLmdData<IApiLmdCurrency[]>(
        `/v1/market_data/v1/keldan/base_currency/CBI/cross_currency_list/[${CURRENCIES.join(';')}]/snapshot`,
        accessToken,
        refreshRateMs
    );

    const handleData = () => {
        const { data, error } = sedlabankinnCurrencies;
        if (data === null) {
            return sedlabankinnCurrencies;
        } else {
            const sortedData = [...data].sort((a, b) => {
                if (a.Crosscurrency === null && b.Crosscurrency === null) return 0;
                if (a.Crosscurrency === null) return -1;
                if (b.Crosscurrency === null) return 1;
                return CURRENCIES.indexOf(a.Crosscurrency) - CURRENCIES.indexOf(b.Crosscurrency);
            });
            return {
                data: sortedData,
                error
            }
        }
    }
    
    return (
        <div className='KCL_sedlabankinn'>
            <LmdTable
                apiData={handleData()}
                columns={[{
                    title: 'Gjaldmiðill',
                    renderCell: ({Crosscurrency}) => (
                        <div className='avatar-and-text'>
                            <Flag name={Crosscurrency as FlagNames} />
                            <span>{Crosscurrency}</span>
                        </div>
                    ),
                    textAlign: 'left',
                    simpleSortable: ({Crosscurrency}) => Crosscurrency
                }, {
                    title: 'Miðgengi',
                    renderCell: ({Averagerate}) => formatNumber(Averagerate),
                    simpleSortable: ({Averagerate}) => Averagerate
                }, {
                    title: 'Dags.',
                    renderCell: ({Datetimerate}) => getTableDate(Datetimerate, 'DD/MM/YY', '.'),
                    simpleSortable: ({Datetimerate}) => (Datetimerate === null) ? null : new Date(Datetimerate.replace(/ /g, "T")).getTime()
                }]}
            />
        </div>
    );
}

export default Sedlabankinn;