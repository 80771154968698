// Package imports:
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import {
    addMonths, 
    subMonths,
    format,
    getYear,
    getMonth,
    setYear,
    setMonth,
    isSameMonth,
    isSameYear
} from "date-fns";
import ISLocale from 'date-fns/locale/is'
// Component imports:
import DropSelect from '../../ui-elements/DropSelect/DropSelect';

// Constants:
const MONTH_YEAR_FORMAT = 'MMMM yyyy';

const getYearOptions = () => {
    const y = [];
    const currYear = getYear(new Date());
    for (let i = currYear - 2; i < currYear + 5; i++) {
        y.push({
            label: i.toString(),
            value: i.toString()
        });
    }
    return y;
}

const monthNames = Array.from({length: 12}, (e, i) => {
    return {
        label: format(new Date(0, i + 1, 0), 'MMMM', { locale: ISLocale}),
        value: i.toString(),
    };
});

interface IProps {
    currentDate: Date,
    onDateChange(d: Date): void
}

const EventsCalendarHeader: React.FC<IProps> = ({
    currentDate = new Date(),
    onDateChange = (d: Date) => {}
}) => {
    const [localDate, setLocalDate] = useState({
        year: getYear(currentDate),
        month: getMonth(currentDate)
    })

    const updateLocalDate = (type: any, value: any) => {
        setLocalDate((prevLocalDate) => ({
            ...prevLocalDate,
            [type]: value
        }))
    }
    const onNextMonth = () => {
        onDateChange(addMonths(currentDate, 1))
    };
    
    const onPrevMonth = () => {
        onDateChange(subMonths(currentDate, 1))
    };

    useEffect(() => {
        const dateChangedMonth = setMonth(currentDate, localDate.month);

        const changedDate = setYear(dateChangedMonth, localDate.year)

        if(!isSameMonth(currentDate, changedDate) || !isSameYear(currentDate, changedDate)) {
            onDateChange(changedDate)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localDate])

    useEffect(() => {
        setLocalDate({
            year: getYear(currentDate),
            month: getMonth(currentDate)
        })
    }, [currentDate])

    return (
        <div className="events-calendar__header">
            <div className="events-calendar__month">
                <div className="events-calendar__actions">
                    <button
                        className="events-calendar__btn"
                        onClick={onPrevMonth}
                        title="Fyrri mánuður"
                    >
                        <span className="icon">
                            <i className="icon-fa">
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </i>
                        </span>
                    </button>

                    <button
                        className="events-calendar__btn"
                        onClick={onNextMonth}
                        title="Næsti mánuður"
                    >
                        <span className="icon">
                            <i className="icon-fa">
                                <FontAwesomeIcon icon={faAngleRight} />
                            </i>
                        </span>
                    </button>
                </div>

                <h1 className="events-calendar__month-name">{format(currentDate, MONTH_YEAR_FORMAT, { locale: ISLocale})}</h1>
            </div>    

            <div className="events-calendar__date-changer">
                <DropSelect
                    className="drop-select--year"
                    label="Ár"
                    defaultValue={localDate.year.toString()}
                    options={getYearOptions()}
                    onChange={val => updateLocalDate('year', val)}
                    size='lg'
                />

                <DropSelect
                    className="drop-select--month"
                    label="Mánuður"
                    defaultValue={localDate.month.toString()}
                    options={monthNames}
                    onChange={val => updateLocalDate('month', val)}
                    size='lg'
                />
            </div>
        </div>
    )
}

export default EventsCalendarHeader
