// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import AreidanleikakannanirPage from '../../components/AreidanleikakannanirPage/AreidanleikakannanirPage';

const Areidanleikakannanir: React.FC = () => {
    return (
        <Entrypoint>
            <AreidanleikakannanirPage />
        </Entrypoint>
    );
}

export default Areidanleikakannanir;