// Package imports:
import React from "react";
import * as Yup from 'yup';
import { Form, Formik } from "formik";
import Bugsnag from "@bugsnag/js";
// Component imports:
import Input from '../../../ui-elements/Input/Formik/Input';
import Button from '../../../ui-elements/Button/Button';
import Alert from "../../../ui-elements/Alert/Alert";
// Service imports:
import { isSSN } from '../../../services/utils';
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from "../../../services/errorMessages";
// Type imports:
import { IMyKeldaSettingsResponse, MySettingsViewModel } from "../../../types/MyKeldaTypes";

interface IProps {
    model: MySettingsViewModel | null,
    setContactResponse: (a: JSX.Element) => void,
}
interface IFormProps {
    values: IFormValues
}
interface IFormValues {
    customerSsn: string,
    customerName: string,
    customerAddress: string,
    customerZip: string,
    customerCity: string,
    customerMobile: string,
}

const GreidsluupplysingarChangeForm: React.FC<IProps> = ({
    model,
    setContactResponse
}) => {

    const handleSubmit = async (values: IFormValues) => {
        try {

            setContactResponse(<></>);
            const requestQuery = new URLSearchParams({
                    customerName: values.customerName,
                    customerAddress: values.customerAddress,
                    customerZip: values.customerZip,
                    customerCity: values.customerCity,
                    customerMobile: values.customerMobile,
            });
            if (values.customerSsn) requestQuery.append('customerSsn', values.customerSsn);
            const url = `${GET_KELDAN_API_URL()}/MyKelda/UpdateUserInfo`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const responseBody: IMyKeldaSettingsResponse = await response.json();
                    if (responseBody.saved) {
                        setContactResponse(
                            <Alert
                                closeAlert={() => setContactResponse(<></>)}
                                withCloseIcon
                                type="success"
                                headText="Breytingar hafa verið vistaðar"
                            />
                        );
                    } 
                    else if (responseBody.info) {
                        setContactResponse(
                            <Alert
                                closeAlert={() => setContactResponse(<></>)}
                                withCloseIcon
                                type="alert"
                                headText={responseBody.info}
                            />
                        );
                    } 
                    else if (!responseBody.saved) {
                        setContactResponse(
                            <Alert
                                closeAlert={() => setContactResponse(<></>)}
                                withCloseIcon
                                type="alert"
                                headText="Breytingar hafa ekki verið vistaðar"
                            />
                        );
                    }
                }
            } else {
                setContactResponse(
                    <Alert
                        closeAlert={() => setContactResponse(<></>)}
                        withCloseIcon
                        type="error"
                        headText={ErrorMessages.RequestFailed}
                    />
                );
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setContactResponse(
                <Alert
                    closeAlert={() => setContactResponse(<></>)}
                    withCloseIcon
                    type="error"
                    headText={ErrorMessages.NetworkError}
                />
            );
        }
    }

    const form: React.FC<IFormProps> = ({
        values
    }) => {
        return <Form>
            <h3>Greiðandi</h3>
            <div className="form__body">
                <div className="form__row">
                    <div className="form__col">
                        <Input 
                            name="customerSsn"
                            id="customerSsn"
                            className="field-input" 
                            label="Kennitala" 
                            value={values.customerSsn}
                            disabled={isSSN(model?.settingsResponse?.customerSsn) || !model?.isAdmin}
                        />
                    </div>
                    <div className="form__col">
                        <Input 
                            name="customerName"
                            id="customerName"
                            disabled 
                            className="field-input" 
                            label="Nafn" 
                            value={values.customerName}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__col">
                        <Input 
                            name="customerAddress"
                            id="customerAddress"
                            className="field-input" 
                            label="Heimilisfang" 
                            value={values.customerAddress}
                            disabled={!model?.isAdmin}
                        />
                    </div>
                    <div className="form__col zip-and-city">
                        <Input 
                            name="customerZip"
                            id="customerZip"
                            className="field-input" 
                            label="Póstnúmer"
                            shortLabel="Póstnr."
                            value={values.customerZip}
                            disabled={!model?.isAdmin}
                        />
                        <Input 
                            name="customerCity"
                            id="customerCity"
                            className="field-input" 
                            label="Sveitarfélag" 
                            value={values.customerCity}
                            disabled={!model?.isAdmin}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__col">
                        <Input 
                            name="customerMobile"
                            id="customerMobile"
                            className="field-input" 
                            label="Farsími" 
                            value={values.customerMobile}
                            disabled={!model?.isAdmin}
                        />
                    </div>
                    <div className="form__col"></div>
                </div>
                <div className="form__actions">
                    <Button
                        type="submit"
                        buttonType="primary"
                        disabled={!model?.isAdmin}
                    >
                        Vista breytingar
                    </Button>
                </div>
            </div>
        </Form>
    }
    return (
        <div className="KCL_GreidsluupplysingarChangeForm">
            <Formik
                initialValues={{
                    customerSsn: model?.settingsResponse?.customerSsn && isSSN(model.settingsResponse.customerSsn) ? model.settingsResponse.customerSsn : '',
                    customerName: model?.settingsResponse?.customerName ?? '',
                    customerAddress: model?.settingsResponse?.customerAddress ?? '',
                    customerZip: model?.settingsResponse?.customerZip ?? '',
                    customerCity: model?.settingsResponse?.customerCity ?? '',
                    customerMobile: model?.settingsResponse?.customerPhoneNumber ?? '',
                }}
                validationSchema={Yup.object({
                    customerSsn: Yup.string()
                        .matches(/^(\d{6}-\d{4})|^(\d+)$/, 'Kennitala má einungis innihalda tölustafi')
                        .min(10, 'Kennitala þarf að innihalda 10 tölustafi')
                        .max(11, 'Kennitala má ekki vera meira en 10 tölustafir')
                        .test('ssn-test', 'Ógild kennitala', (value) => isSSN(value, true)),
                    name: Yup.string(),
                    customerAddress: Yup.string(),
                    customerZip: Yup.string(),
                    customerCity: Yup.string(),
                    customerMobile: Yup.string(),
                })}
                onSubmit={handleSubmit}
                component={form}
            />
        </div>
    )
}

export default GreidsluupplysingarChangeForm;