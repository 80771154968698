// Package imports:
import React from 'react';
import cx from 'classnames';
import BootstrapModal from 'react-bootstrap/Modal';

interface IOwnProps {
    // States:
    show: boolean;
    onHide: () => void;
    // Content:
    header?: JSX.Element | React.ReactNode;
    body?: JSX.Element | React.ReactNode;
    footer?: JSX.Element | React.ReactNode;
    // Styling changes:
    className?: string;
    hasCloseButton?: boolean;
    variant?: 'normal' | 'shaded-inside';
}

const Modal: React.FC<IOwnProps> = ({
    show,
    onHide,
    header,
    body,
    footer,
    hasCloseButton,
    className,
    variant = 'normal'
}) => {
    return (
        <BootstrapModal
            show={show}
            onHide={onHide}
            scrollable
            className={cx('KCL_modal', className, {
                'shaded-inside': (variant === 'shaded-inside'),
                'has-close-button': hasCloseButton
            })}
        >
            <BootstrapModal.Header
                className={cx({
                    'no-header': (header === undefined)
                })}
                closeButton={hasCloseButton}
            >
                {header && (typeof header !== 'string')
                    ? header
                    : (
                        <BootstrapModal.Title>
                            <h3>{header}</h3>
                        </BootstrapModal.Title>
                    )
                }
            </BootstrapModal.Header>
            {body && (
                <BootstrapModal.Body>
                    {body}
                </BootstrapModal.Body>
            )}
            {footer && (
                <BootstrapModal.Footer>
                    {footer}
                </BootstrapModal.Footer>
            )}
        </BootstrapModal>
    );
}

export default Modal;