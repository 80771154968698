// Package imports:
import React from 'react';
// Component imports:
import GEMMAQContactForm from '../ContactForms/GEMMAQContactForm/GEMMAQContactForm';
import CheckList from '../../ui-elements/Lists/CheckList';

const GEMMAQLicense: React.FC = () => {
    return (
        <div className='main KCL_GEMMAQ-license'>
            <div className='section section--contact'>
                <div className="shell">
                    <div className='section__inner'>
                        <div className='section__head'>
                            <h1>GEMMAQ notkunarleyfi</h1>
                            <p className='summary-paragraph'>GEMMAQ notkunarleyfið er 12 mánaða samningur milli fyrirtækis og Keldunnar.</p>
                            <p className='list-heading paragraph'>Í leyfinu felst meðal annars:</p>
                            <CheckList
                                items={[
                                    'Heimild til birtingar á kvarðanum á vefsíðu fyrirtækis, sem uppfærist sjálfkrafa ef kynjahlutföll breytast í stjórn eða framkvæmdastjórn þess og/eða GEMMAQ einkunn þess hækkar eða lækkar.',
                                    'Heimild til notkunar á kvarðanum í kynningarefni og hvers kyns markaðsefni.',
                                    'Heimild til notkunar á kvarðanum í greiningarvinnu og greiningarefni innan fyrirtækis og/eða til birtingar, sem sagt í samfélagsskýrslum eða ársskýrslu fyrirtækis.'
                                ]}
                                size='lg'
                            />
                            <p className='price paragraph'>Verð: 180.000 kr. á ári.</p>
                        </div>

                        <div className="section__body">
                            <div className='section__content form form--contact'>
                                <GEMMAQContactForm publishing={false} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GEMMAQLicense;