// Package imports:
import React, { useState } from 'react';
import cx from 'classnames'
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import Icon from '../CustomIcon/CustomIcon';
import Label from '../../../ui-elements/Label/Label';
import Button from '../../../ui-elements/Button/Button';
import AnimateHeight from 'react-animate-height';

export interface INavItem {
    link: string
    label: string | JSX.Element
    lock?: boolean
    isNew?: boolean
    disabled?: boolean
}

interface IButtonItem {
    label: string
    link: string
    icon: IconDefinition
}
interface INavItemWithHeader { [key: string]: INavItem[] }

interface IProps {
    label: string | JSX.Element,
    navItems: INavItem[][] | INavItemWithHeader,
    buttonItems?: IButtonItem[],
    highlighted?: boolean,
    openByDefault?: boolean
    isMobileNavMenuOpen?: boolean
}

const NavDropdown: React.FC<IProps> = ({
    label,
    navItems,
    buttonItems,
    highlighted = false,
    openByDefault = false,
    isMobileNavMenuOpen
}) => {
    const [isOpen, setIsOpen] = useState(openByDefault);
    const [activeHeader, setActiveHeader] = useState<string>('');
    
    const displayLists = (navItems: INavItem[][] | INavItemWithHeader) => {
      const renderButtonItems = () => (
        <div className='call-to-action-wrapper'>
          {buttonItems?.map(({ label, link, icon }, index) => (
            <Button anchorProps={{ href: link }} icon={icon} key={index}>
              {label}
            </Button>
          ))}
        </div>
      );

        const renderNavItem = ({ link, label, lock, isNew, disabled }: INavItem, itemIndex: number) => (
        <li key={itemIndex}>
            <a href={link} aria-disabled={disabled} className={cx({ 'is-disabled': lock || disabled})}>
                {label}
                {lock && (
                    <span className="lock-icon">
                        <FontAwesomeIcon icon={faLock} />
                    </span>
                )}
                {isNew && <Label labelType="new" text="Nýtt" />}
            </a>
        </li>
    );
      const renderNavItemList = (items: INavItem[]) => (
        <div className='list-wrapper'>
          {items.map(({ link, label, lock, isNew, disabled }, itemIndex) => (
            renderNavItem({ link, label, lock, isNew, disabled }, itemIndex)
          ))}
        </div>
      );
      const isNavItemArray = (items: any): items is INavItem[][] => {
        return Array.isArray(items) && Array.isArray(items[0]) && items[0][0]?.link !== undefined;
      };
      
      const isNavItemWithHeaderArray = (items: any): items is INavItemWithHeader => {
        return typeof items === 'object' && !Array.isArray(items) && items !== null && Object.keys(items).every(key => Array.isArray(items[key]));
      };
    
      if (isNavItemArray(navItems)) {
        return navItems.map((navItem, index) => (
          <li className='nav__sub-col' key={index}>
            <ul>
              {renderNavItemList(navItem)}
              {(index === navItems.length - 1 && buttonItems) && renderButtonItems()}
            </ul>
          </li>
        ));
      } else if (isNavItemWithHeaderArray(navItems)) {
        return Object.keys(navItems).map((header, headerIndex) => (
          <li className={cx('nav__sub-col', {'active': activeHeader === header})} key={headerIndex}>
            <ul>
              <div className={'itemWrapper'}>
                {
                  isMobileNavMenuOpen ? (
                    <>
                    <div className='headWrapper' onClick={() => setActiveHeader(header === activeHeader ? '' : header)} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                      <span className='list-heading'>{header}</span>

                      <span className="icon">
                          <Icon type="dropdown-arrow" />
                      </span>
                    </div>
                    <AnimateHeight
                      className="list-item__content"
                      duration={300}
                      height={activeHeader === header ? 'auto' : 0}
                  >
                      <div className="list-item__body">
                        {renderNavItemList(navItems[header])}
                      </div>
                  </AnimateHeight>
                    </>
                  ) : (
                    <><span className='list-heading'>{header}</span>{renderNavItemList(navItems[header])}</>
                  )
                }
              </div>
              {(headerIndex === Object.keys(navItems).length - 1 && buttonItems) && renderButtonItems()}
            </ul>
          </li>
        ));
      }
      return null;
    };
    return (
        <li className={cx('nav__main-list', {'is-open': isOpen, 'highlighted': highlighted, 'mobile': isMobileNavMenuOpen})}>
            <button type="button" onClick={() => setIsOpen(!isOpen || openByDefault)}>
                {label}
                {!openByDefault &&
                    <span className="icon">
                        <Icon type="dropdown-arrow" />
                    </span>
                }
            </button>
            <ul className='nav__sub'>
                {displayLists(navItems)}
            </ul>
        </li>
    );
}

export default NavDropdown;