// Package imports:
import React, { useState } from 'react';
// Component imports:
import Link from '../../../ui-elements/Link/Link';
import Modal from '../../../ui-elements/Modal/Modal';
import BulletList from '../../../ui-elements/Lists/BulletList';


const ValuationUnsuccessful: React.FC = () => {
    const [ explanationOpen, setExplanationOpen ] = useState(false);
    const openExplanation = () => setExplanationOpen(true);
    const closeExplanation = () => setExplanationOpen(false);

    return (
        <>
            <Link
                className='more-link'
                url=''
                linkSize='14'
                onClick={(e) => {
                    e.preventDefault();
                    openExplanation();
                }}
                icon='more'
            >
                Hvers vegna?
            </Link>
            <Modal
                show={explanationOpen}
                hasCloseButton
                onHide={closeExplanation}
                header='Ekki tókst að verðmeta fyrirtæki'
                body={<div>
                    <p className='paragraph'>
                        Keldan styðst við ýmsar forsendur við gerð verðmatsins.<br />
                        Eftirfarandi ástæður gætu valdið því að ekki tekst að verðmeta fyrirtæki:
                    </p>
                    <BulletList
                        size='lg'
                        items={[
                            'Ársreikningar eru of gamlir eða of fáir.',
                            'Gögn vantar við útreikning ávöxtunarkröfu.',
                            'Gögn vantar við útreikning frjáls sjóðstreymis.',
                            'Atvinnugrein fyrirtækis bendir til þess að verðmatsaðferð eigi ekki við.',
                            'Niðurstöður verðmats eru utan þess sem eðlilegt gæti talist.',
                            'Ársreikningar eru í öðrum gjaldmiðli en ISK.'
                        ]}
                    />
                </div>}
                className='KCL_no-valuation'
            />
        </>
    );
}

export default ValuationUnsuccessful;