// Package imports:
import React, { useState } from 'react';
// Component imports:
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import Button from '../../ui-elements/Button/Button';
import FullScreenImage from '../../ui-elements/FullScreenImage/FullScreenImage';
import TextImageBlock from '../TextImageBlock/TextImageBlock';
import BulletList from '../../ui-elements/Lists/BulletList';
import PriceCard from '../PriceCard/PriceCard';
import TilkynningarContactForm from '../ContactForms/TilkynningarContactForm/TilkynningarContactForm';
import GrayAccordion from '../../ui-elements/GrayAccordion/GrayAccordion';
import Label from '../../ui-elements/Label/Label';

const AboutNewswire: React.FC = () => {
    const [ fullScreenImageUrl, setFullScreenImageUrl ] = useState<string | null>(null);

    return (
        <div className='main KCL_about-newswire'>
            {fullScreenImageUrl && (
                <FullScreenImage
                    imgUrl={fullScreenImageUrl}
                    imgAlt="Fullscreen Tilkynningar screenshot"
                    onExit={() => setFullScreenImageUrl(null)}
                />
            )}
            <div className='shell'>
                <HeadSection
                    title={
                        <object
                            data="https://cdn.livemarketdata.com/KeldanImages/Keldan_tilkynningar_logo.svg"
                            aria-label="Keldan | Tilkynningar"
                        >
                            Keldan | Tilkynningar
                        </object>
                    }
                    summary={
                        <p>Þægileg og fagleg leið til að miðla upplýsingum beint til fjárfesta, greiningaraðila, fjölmiðla og annarra hagsmunaaðila.</p>
                    }
                    actions={
                        <>
                            <Button
                                buttonType='secondary'
                                size='lg'
                                anchorProps={{
                                    href: 'https://newswire.keldan.is/Innskraning',
                                    target: '_blank'
                                }}
                            >
                                Innskráning
                            </Button>
                            <Button
                                buttonType='primary'
                                size='lg'
                                onClick={() =>
                                    document
                                        .getElementById("Askrift")
                                        ?.scrollIntoView({ behavior: "smooth" })
                                }
                            >
                                Áskrift
                            </Button>
                        </>
                    }
                    textAlign='center'
                />
            </div>
            <div className='section__body'>
                <div className='shell'>
                    <TextImageBlock
                        imgSrc="https://cdn.livemarketdata.com/KeldanImages/Tilkynningar1.png"
                        imgSide="right"
                    >
                        <h2 id="Tilkynningar_Easy">Einfalt og öruggt</h2>
                        <div className='text-image-block__entry'>
                            <p className='paragraph'>
                                <span className='paragraph--bold'>Keldan tilkynningar</span> er tilkynningakerfi fyrir fyrirtæki, stofnanir og aðra sem þurfa að koma fréttatilkynningum á framfæri.
                            </p>
                            <p className='paragraph'>
                                Kerfið tryggir örugga frumbirtingu á Keldunni og dreifir tilkynningum í takt við þarfir viðskiptavina hverju sinni og eru fleiri dreifileiðir væntanlegar á árinu.
                            </p>
                            <p className='paragraph'>
                                Kerfið er einfalt og öruggt í notkun. Notendur sem fá aðgang fyrir hönd fyrirtækis geta skráð sig inn í kerfið og unnið saman að smíði fréttatilkynninga.
                            </p>
                        </div>
                    </TextImageBlock>
                    <TextImageBlock
                        imgSrc="https://cdn.livemarketdata.com/KeldanImages/Tilkynningar2.png"
                        imgSide="left"
                    >
                        <h2 id='Tilkynningar_Included'>Innifalið í áskrift</h2>
                        <BulletList
                            items={[
                                'Aðgangur að tilkynningakerfinu fyrir tvo notendur.',
                                'Frumbirting fréttatilkynninga á Keldunni.',
                                'Birting tilkynninga á forsíðu Keldunnar.'
                            ]}
                        />
                    </TextImageBlock>
                    <div className='text-image-block-list'>
                        <TextImageBlock
                            imgSide='right'
                            className='distribution-block'
                        >
                            <h3 id="Tilkynningar_Distribution">Dreifileiðir</h3>
                            <GrayAccordion
                                items={[{
                                    id: 'keldan',
                                    title: <>
                                        <p>Keldan.is</p>
                                        <Label labelType='delay' text='Tenging tilbúin' />
                                    </>,
                                    content: <p className='paragraph-small'>
                                        Frumbirting fréttatilkynninga og dreifing í gegnum viðskiptafréttir á forsíðu Keldunnar.
                                    </p>
                                }, {
                                    id: 'isl_media',
                                    title: <>
                                        Íslenskir fjölmiðlar
                                        <Label labelType='new' text='Væntanlegt' />
                                    </>,
                                    content: <p className='paragraph-small'>
                                        Fréttatilkynning send á valda íslenska fjölmiðla til frekari dreifingar.
                                    </p>
                                }, {
                                    id: 'nasdaq_ice',
                                    title: <>
                                        Kauphöll NASDAQ Iceland
                                        <Label labelType='new' text='Væntanlegt' />
                                    </>,
                                    content: <p className='paragraph-small'>
                                        Útgefendur verðbréfa geta sent kauphallartilkynningar sem þurfa að berast NASDAQ Iceland og þurfa að uppfylla skilyrði kauphallarinnar.
                                    </p>
                                }, {
                                    id: 'cbi',
                                    title: <>
                                        Fjármálaeftirlit Seðlabankans
                                        <Label labelType='new' text='Væntanlegt' />
                                    </>,
                                    content: <p className='paragraph-small'>
                                        Útgefendur verðbréfa geta sent kauphallartilkynningar sem uppfylla kröfur um birtingu og vörslu í gagnagrunni Seðlabankans.
                                    </p>
                                }, {
                                    id: 'foreign_media',
                                    title: <>
                                        Erlendir fjölmiðlar
                                        <Label labelType='new' text='Væntanlegt' />
                                    </>,
                                    content: <p className='paragraph-small'>
                                        Fréttatilkynning send á valda erlenda fjölmiðla til frekari dreifingar.
                                    </p>
                                }]}
                                openId='keldan'
                            />
                        </TextImageBlock>
                        <TextImageBlock
                            imgSide='left'
                        >
                            <h3 id="Tilkynningar_RSS">RSS fréttasöfnun og birting</h3>
                            <p className='paragraph'>
                                Keldan getur sett upp fréttasafnara fyrir áskrifendur að tilkynningaþjónustu Keldunnar, sem sækir almennar fréttatilkynningar beint af vefsíðum fyrirtækja og birtir þær á Keldunni.
                                Áskrifendur fá einnig aðgang að tilkynningakerfinu þar sem þeir geta séð upplýsingar um söfnun og birtingu RSS tilkynninga.
                            </p>
                        </TextImageBlock>
                    </div>
                </div>
            </div>
            <div className="section section--gray section--contact" id="Askrift">
                <div className='shell'>
                    <div className='section__inner'>
                        <div className='section__head'>
                            <h2 id="Tilkynningar_Subscribe">Áskrift að tilkynningaþjónustu Keldunnar</h2>
                            <p className='summary-paragraph'>
                                Hafðu samband til að koma í áskrift, en einnig getur þú sent fyrirspurn hafir þú einhverjar spurningar.
                            </p>
                            <TilkynningarContactForm />
                        </div>
                        <div className='section__body'>
                            <PriceCard
                                variant='overview'
                                type='Áskrift - Tilkynningaþjónusta Keldunnar'
                            >
                                <p className='paragraph--bold'>Mánaðargjald</p>
                                <div className="price-card__details">
                                    <dl>
                                        <dt>Aðgangur fyrir tvo notendur</dt>
                                        <dd>29.000 kr.</dd>
                                    </dl>
                                    <dl>
                                        <dt>Auka notandi</dt>
                                        <dd>4.900 kr.</dd>
                                    </dl>
                                    <p className='paragraph-small--italic'>Athugið, verðin eru án VSK.</p>
                                </div>
                            </PriceCard>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutNewswire;