// Package imports:
import React, { useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
// Component imports:
import Input from '../../../ui-elements/Input/Formik/Input';
import Button from '../../../ui-elements/Button/Button';
import DropSelect from '../../../ui-elements/DropSelect/DropSelect';
import Alert from '../../../ui-elements/Alert/Alert';
import Recaptcha from '../../../ui-elements/Recaptcha/Recaptcha';
// Service imports:
import useSendEmailHook from '../../../services/apiHooks';
// Type imports:
import { Fetched, ContactTopic } from '../../../types/Types';

const REASON_QUESTION = 'Almenn fyrirspurn á Keldunni';
const REASON_AD_REQUEST = 'Fyrirspurn um að auglýsa á Keldunni';
const REASON_INVOICE = 'Fyrirspurn um reikning frá Keldunni';
const REASON_KELDANAPP = 'Fyrirspurn um Keldan App';
const REASON_MORE_USERS = 'Fyrirspurn um fleiri notendur í áskrift';
const REASON_KELDANAPI = 'Fyrirspurn um Vefþjónustu Keldunnar';
const REASON_PEPLIST = 'Fyrirspurn um aðgang að PEP lista Keldunnar';
const REASON_KELDANMARKADIR = 'Fyrirspurn um aðgang að Keldan markaðir';
const REASON_KELDANMARKADIR_TEST = 'Fyrirspurn um prufu aðgang að Keldan markaðir';

const REASONS: { label: string; value: ContactTopic }[] = [
  {
    label: REASON_QUESTION,
    value: "question",
  },
  {
    label: REASON_AD_REQUEST,
    value: "advertise",
  },
  {
    label: REASON_INVOICE,
    value: "invoice",
  },
  {
    label: REASON_KELDANAPP,
    value: "app",
  },
  {
    label: REASON_MORE_USERS,
    value: "moreusers",
  },
  {
    label: REASON_KELDANAPI,
    value: "api",
  },
  {
    label: REASON_PEPLIST,
    value: "pep",
  },
  {
    label: REASON_KELDANMARKADIR,
    value: "keldanmarkadir",
  },
  {
    label: REASON_KELDANMARKADIR_TEST,
    value: "keldanmarkadir-prufa",
  },
];

interface IProps {
    selectedTopic: ContactTopic,
}

const HafaSambandContactForm: React.FC<IProps> = ({
    selectedTopic
}) => {
    const [topic, setTopic] = useState<string>(selectedTopic);
    const [captchaToken, setCaptchaToken] = useState<string | null>('');
    
    const { loading, contactResponse, handleSubmit } = useSendEmailHook('Email/HafaSamband', captchaToken, {topic: topic});

    const subFormComponent = useMemo(() => {
        return ((props: { values: IFormValues }) => (
            <SubFormComponent
                topic={topic}
                setTopic={setTopic}
                contactResponse={contactResponse}
                values={props.values}
                setCaptchaToken={(e: string | null )=>setCaptchaToken(e)}
                loading={loading}
            />
        ))
    }, [ topic, contactResponse, loading ]);

    return (
        <div className='KCL_contact-form'>
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    company: '',
                    phone: '',
                    message: '',
                }}
                validationSchema={Yup.object({
                    name: Yup.string()
                        .required('Nafn verður að fylgja'),
                    email: Yup.string()
                        .email('Ógilt netfang').required('Netfang verður að fylgja'),
                    company: (topic === 'advertise')
                        ? Yup.string()
                            .required('Fyrirtæki má ekki vanta í fyrirspurn um auglýsingar')
                        : Yup.string(),
                    phone: Yup.string()
                        .required('Símanúmer verður að fylgja')
                        .matches(/^[+]?\d+$/, 'Símanúmer má einungis innihalda tölustafi')
                        .min(7, 'Lágmarkslengd eru 7 tölustafir')
                        .max(11, 'Hámarkslengd eru 11 tölustafir'),
                    message: Yup.string()
                        .required("Skilaboð verða að fylgja"),
                })}
                onSubmit={handleSubmit}
                component={subFormComponent}
            />
        </div>
    );
}

export default HafaSambandContactForm;

interface IFormValues {
    name: string,
    email: string,
    company: string,
    phone: string,
    message: string
}

interface ISubFormProps {
    topic: string,
    setTopic(topic: string): void,
    contactResponse: Fetched<string>,
    values: IFormValues,
    loading: boolean,
    setCaptchaToken: (e: string | null) => void
}

const SubFormComponent: React.FC<ISubFormProps> = ({
    topic,
    setTopic,
    contactResponse,
    values,
    loading,
    setCaptchaToken
}) => {
    return (
        <Form>
            <div className="form__body">
                <div className="form__section">
                    <h3>Þínar upplýsingar</h3>
                    <div className="form__row">
                        <div className="form__col">
                            <Input 
                                label="Nafn"
                                name="name"
                                id="name"
                                value={values.name}
                            />
                        </div>
                        <div className="form__col">
                            <Input
                                label="Netfang"
                                name="email"
                                id="email"
                                value={values.email}
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__col">
                            <Input 
                                label="Fyrirtæki"
                                name="company"
                                id="company"
                                value={values.company}
                            />
                        </div>
                        <div className="form__col">
                            <Input
                                label="Sími"
                                name="phone"
                                id="phone"
                                value={values.phone}
                            />
                        </div>
                    </div>
                    <h3>Hvað getum við aðstoðað þig með?</h3>
                    <div className="form__row">
                        <div className="form__col">
                            {/* ADD DROPSELECT IMPLEMENTATION TO FORMIK */}
                            <DropSelect
                                options={REASONS}
                                defaultValue={topic}
                                onChange={(option) => {
                                    if (typeof option === 'string') setTopic(option);
                                }}
                                size='lg'
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__col">
                            <Input
                                inputType="textArea"
                                label="Skilaboð"
                                name="message"
                                id="message"
                                value={values.message}
                            />
                        </div>
                    </div>
                    {(contactResponse === null)
                        ? null
                        : (
                            <div className="form__row">
                                <div className="form__col">
                                    {(contactResponse instanceof Error)
                                    ? <Alert type='error' headText={contactResponse.message} />
                                    : <Alert type='info' headText={contactResponse} />}
                                </div>
                            </div>
                        )}
                    <Recaptcha position='center' setToken={(e: string | null) => setCaptchaToken(e) } />
                </div>
            </div>
            <div className="form__actions">
                <Button showLoader={loading} buttonType="primary" size="lg">Senda</Button>
            </div>
        </Form>
    );
}
