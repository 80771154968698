// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import SharePageComp from '../../components/SingleSharePage/SingleSharePage';

interface IOwnProps {
    symbol?: string | null
}

const Share: React.FC<IOwnProps> = ({
    symbol
}) => {
    return (
        <Entrypoint>
            <SharePageComp symbol={symbol ?? 'MAREL'} />
        </Entrypoint>
    );
}

export default Share;