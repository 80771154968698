// Package imports:
import React from "react";
// Component imports:
import ViewMembershipBody from "./ViewMembershipBody";
// Type imports:
import { IndividualMembership } from "../../../types/IndividualTypes";
import { IEventReport } from "../../../types/KeldanTypes";
// Static data imports:
import EXAMPLE_DATA from '../../../assets/staticData/ExampleReports/Individual/Hlutafelagathatttaka.json';

const MembershipReportExample: React.FC = () => {
    return <ViewMembershipBody report={EXAMPLE_DATA as IEventReport<IndividualMembership>} isExample />
}

export default MembershipReportExample;