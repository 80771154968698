// Package imports:
import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import ISLocale from 'date-fns/locale/is';
import { format } from 'date-fns';
// Service imports:
import { displayPrice, displayQuantityShorthand } from '../../../services/utils';
import { SHORT_MONTHS } from '../../../services/config';
// Type imports:
import { IGangverdProperty, IGangverdSeries } from '../../../types/PropertyTypes';

interface IChartDatum {
    x: number | undefined,
    y: number | undefined,
    address?: string
}

interface IChartDataInfo {
    name: string,
    data: IChartDatum[]
}

interface IProps {
    estimateSeries: IGangverdSeries<number[]>,
    ciSeries: IGangverdSeries<number[]>,
    similarSeries: IGangverdSeries<IGangverdProperty>,
    grayBackground?: boolean
}

const GangverdChart: React.FC<IProps> = ({
    estimateSeries,
    ciSeries,
    similarSeries,
    grayBackground = false
}) => {
    const chartDivRef = useRef<HTMLDivElement>(null);
    const printChartDivRef = useRef<HTMLDivElement>(null);

    highchartsMore(Highcharts);

    useEffect(() => {
        const chartDataInfoEstimate: IChartDataInfo = {
            name: estimateSeries.name,
            data: []
        };
        for (let datum of estimateSeries.data) {
            chartDataInfoEstimate.data.push({
                x: datum[0],
                y: datum[1]
            });
        }

        const chartDataInfoSimilar: IChartDataInfo = {
            name: 'Lík sala',
            data: []
        };
        for (let datum of similarSeries.data) {
            chartDataInfoSimilar.data.push({
                x: datum.date2,
                y: datum.price,
                address: datum.address
            });
        }

        const chartDataInfoCi: {name: string, data: number[][]} = {
            name: ciSeries.name,
            data: []
        };
        for (let datum of ciSeries.data) {
            chartDataInfoCi.data.push(datum);
        }

        Highcharts.setOptions({
            lang: {
                shortMonths: SHORT_MONTHS
            }
        });

        const backgroundColor1 = grayBackground ? '#F8F8FA' : '#FFFFFF';
        const backgroundColor2 = grayBackground ? '#FFFFFF' : '#F8F8FA';

        if (chartDivRef.current !== null) {
            Highcharts.chart(chartDivRef.current, {
                chart: {
                    height: 362,
                    marginTop: 40,
                    reflow: true,
                    style: {
                        fontFamily: "Roboto"
                    },
                    plotBackgroundColor: {
                        linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                        stops: [
                            [0.05, backgroundColor1],
                            [0.95, backgroundColor2]
                        ]
                    },
                    backgroundColor: backgroundColor1
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: '',
                    align: 'center',
                    x: 60,
                    margin: -14
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                xAxis: [{
                    type: 'datetime',
                    title: {
                        text: null
                    },
                    minTickInterval: 1000*60*60*24*30*6,
                    tickWidth: 0
                }],
                yAxis: [{
                    title: {
                        text: 'Verð (kr.)'
                    },
                    labels: {
                        formatter: function() {
                            return displayQuantityShorthand(typeof this.value === 'string' ? parseFloat(this.value) : this.value);
                        },
                        x: -11,
                        y: 2
                    },
                    gridLineDashStyle: 'Dash',
                    gridLineWidth: 1,
                    gridLineColor: '#D3D3D6',
                    opposite: false
                }],
                plotOptions: {
                    series: {
                        color: '#4569EE',
                        enableMouseTracking: true,
                        marker: {
                            enabled: true,
                            radius: 5.5
                        },
                        animation: false,
                        lineWidth: 3
                    }
                },
                legend: {
                    enabled: true,
                    align: 'center',
                    verticalAlign: 'bottom',
                    itemStyle: {
                        color: '#232530',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '26px'
                    },
                    x: 5,
                    y: 10
                },
                rangeSelector: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                           callback: function() {
                               return window.matchMedia('(max-width: 767px)').matches;
                           }
                        },
                        chartOptions: {
                            chart: {
                                spacingLeft: 0,
                                spacingRight: 5,
                                marginTop: 60
                            },
                            legend: {
                                x: 10,
                                y: -10,
                                itemStyle: {
                                    fontSize: '12px'
                                }
                            },
                            xAxis: {
                                labels: {
                                    style: {
                                        fontSize: '9px'
                                    }
                                }
                            },
                            yAxis: {
                                labels: {
                                    style: {
                                        fontSize: '9px'
                                    },
                                    x: -1
                                },
                                title: {
                                    offset: 27,
                                    x: 0,
                                    style: {
                                        fontSize: '10px'
                                    }
                                }
                            }
                        }
                    }]
                },
                series: [{
                    name: chartDataInfoEstimate.name,
                    type: 'line',
                    id: 'estimate',
                    data: chartDataInfoEstimate.data,
                    tooltip: {
                        headerFormat: '',
                        pointFormatter: function() {
                            return `<div class="chart-tooltip">
                                <p class="tooltip-date">${format(this.x, 'MMM yyyy', {locale: ISLocale})}</p><br />
                                <p class="tooltip-value"><span style="color: ${this.color}">${this.series.name}</span>: ${displayPrice(this.y)}</p>
                            </div>`;
                        }
                    },
                    zIndex: 1
                }, {
                    name: chartDataInfoCi.name,
                    type: 'arearange',
                    id: 'ci',
                    data: chartDataInfoCi.data,
                    marker: {
                        enabled: false
                    },
                    enableMouseTracking: false,
                    lineWidth: 0,
                    linkedTo: ':previous',
                    fillOpacity: 0.1,
                    zIndex: 0
                }, {
                    name: chartDataInfoSimilar.name,
                    type: 'scatter',
                    id: 'similar',
                    data: chartDataInfoSimilar.data,
                    color: '#4F5059',
                    marker: {
                        symbol: 'circle'
                    },
                    lineWidth: 0,
                    tooltip: {
                        headerFormat: '',
                        pointFormatter: function() {
                            // @ts-ignore
                            return `<div class="chart-tooltip"><b style="color: ${this.color}">${this.address}</b><br />
                                Söluverð: ${displayPrice(this.y)}<br />
                                Dags.: ${format(this.x, 'dd. MMM yyyy', {locale: ISLocale})}
                            </div>`
                        }
                    },
                    zIndex: 1
                }],
            }, () => {});
        }
        // Draw for printing purposes
        if (printChartDivRef.current !== null) {
            Highcharts.chart(printChartDivRef.current, {
                chart: {
                    height: 362,
                    marginTop: 40,
                    reflow: true,
                    style: {
                        fontFamily: "Roboto"
                    },
                    plotBackgroundColor: {
                        linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                        stops: [
                            [0.05, '#FFF'],
                            [0.95, '#F8F8FA']
                        ]
                    }
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: '',
                    align: 'center',
                    x: 60,
                    margin: -14
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                xAxis: [{
                    type: 'datetime',
                    title: {
                        text: null
                    },
                    minTickInterval: 1000*60*60*24*30*6,
                    tickWidth: 0
                }],
                yAxis: [{
                    title: {
                        text: 'Verð (kr.)'
                    },
                    labels: {
                        formatter: function() {
                            return displayQuantityShorthand(typeof this.value === 'string' ? parseFloat(this.value) : this.value);
                        },
                        x: -11,
                        y: 2
                    },
                    gridLineDashStyle: 'Dash',
                    gridLineWidth: 1,
                    gridLineColor: '#D3D3D6',
                    opposite: false
                }],
                plotOptions: {
                    series: {
                        color: '#4569EE',
                        enableMouseTracking: true,
                        marker: {
                            enabled: true,
                            radius: 2.5
                        },
                        animation: false,
                        lineWidth: 2,
                    }
                },
                legend: {
                    enabled: true,
                    align: 'center',
                    verticalAlign: 'bottom',
                    itemStyle: {
                        color: '#232530',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '26px'
                    },
                    x: 5,
                    y: 10
                },
                rangeSelector: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                           callback: function() {
                               return window.matchMedia('(max-width: 767px)').matches;
                           }
                        },
                        chartOptions: {
                            chart: {
                                spacingLeft: 0,
                                spacingRight: 5,
                                marginTop: 60
                            },
                            legend: {
                                x: 10,
                                y: -10,
                                itemStyle: {
                                    fontSize: '12px'
                                }
                            },
                            xAxis: {
                                labels: {
                                    style: {
                                        fontSize: '9px'
                                    }
                                }
                            },
                            yAxis: {
                                labels: {
                                    style: {
                                        fontSize: '9px'
                                    },
                                    x: -1
                                },
                                title: {
                                    offset: 27,
                                    x: 0,
                                    style: {
                                        fontSize: '10px'
                                    }
                                }
                            }
                        }
                    }]
                },
                series: [{
                    name: chartDataInfoEstimate.name,
                    type: 'line',
                    id: 'estimate',
                    data: chartDataInfoEstimate.data,
                    tooltip: {
                        headerFormat: '',
                        pointFormatter: function() {
                            return `<div class="chart-tooltip">
                                <p class="tooltip-date">${format(this.x, 'MMM yyyy', {locale: ISLocale})}</p><br />
                                <p class="tooltip-value"><span style="color: ${this.color}">${this.series.name}</span>: ${displayPrice(this.y)}</p>
                            </div>`;
                        }
                    },
                    zIndex: 1
                }, {
                    name: chartDataInfoCi.name,
                    type: 'arearange',
                    id: 'ci',
                    data: chartDataInfoCi.data,
                    marker: {
                        enabled: false
                    },
                    enableMouseTracking: false,
                    lineWidth: 0,
                    linkedTo: ':previous',
                    fillOpacity: 0.1,
                    zIndex: 0
                }, {
                    name: chartDataInfoSimilar.name,
                    type: 'scatter',
                    id: 'similar',
                    data: chartDataInfoSimilar.data,
                    color: '#4F5059',
                    marker: {
                        symbol: 'circle'
                    },
                    lineWidth: 0,
                    tooltip: {
                        headerFormat: '',
                        pointFormatter: function() {
                            // @ts-ignore
                            return `<div class="chart-tooltip"><b style="color: ${this.color}">${this.address}</b><br />
                                Söluverð: ${displayPrice(this.y)}<br />
                                Dags.: ${format(this.x, 'dd. MMM yyyy', {locale: ISLocale})}
                            </div>`
                        }
                    },
                    zIndex: 1
                }],
            }, () => {});
        }
    }, [estimateSeries, similarSeries, chartDivRef.current]);

    return (
        <div className='KCL_chart'>
            <div
                className='chart'
                ref={chartDivRef}
            />
            <div
                className='print-chart'
                ref={printChartDivRef}
                style={{
                    width: 600
                }}
            />
        </div>
    );
}

export default GangverdChart;