// Package imports:
import React, { useMemo } from "react";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Bugsnag from "@bugsnag/js";
// Component imports:
import Alert from "../../../ui-elements/Alert/Alert";
import Table from "../../../ui-elements/Table/Table";
import DropSelect from "../../../ui-elements/DropSelect/DropSelect";
import LogbirtingaVaktinForm from "../../Watch/LogbirtingaVaktin/LogbirtingaVaktinForm"
import Button from "../../../ui-elements/Button/Button";
import Checkbox from "../../../ui-elements/Input/Checkbox";
import Tooltip from "../../../ui-elements/Tooltip/Tooltip";
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { usePageCounter } from '../../../services/hooks';
import { ErrorMessages } from "../../../services/errorMessages";
import SEO from "../../../services/SEO";
// Type imports:
import { Watch } from "../../../types/MyKeldaTypes";

interface IProps {
    watches?: { [key: string]: Watch[]; } | null,
    setWatches(a: { [key: string]: Watch[]; }): void,
    isSubscriber?: boolean
}

interface IWatchItem {
    name: string | null,
    term: string,
    id: number,
    active: boolean
}

const LogbirtingaVaktin: React.FC<IProps> = ({
    watches,
    setWatches,
    isSubscriber = false
}) => {
    const {
        itemsPerPage,
        setItemsPerPage,
        setItemCount,
        currentPageIndex,
        PageCounter
    } = usePageCounter();

    const displayData = useMemo(() => {
        if (!watches) return [];
        let data: IWatchItem[] = [];
        const logWatches = watches['LOG'];
        logWatches.forEach((watch) => {
            // If watch exists with same term, edit existing item, otherwise add new item
            let existingWatch = data.findIndex(x => `${x.term.substring(0,6)}-${x.term.substring(6)}` === watch.watchMe);
            if (existingWatch !== -1) {
                data[existingWatch] = {
                    ...data[existingWatch],
                    id: watch.id,
                    active: watch.active
                };
            } else {
                data.push({
                    name: watch.name,
                    term: watch.watchMe,
                    id: watch.id,
                    active: watch.active
                });
            }
        });
        let to = (currentPageIndex + 1) * itemsPerPage;
        let from = to - itemsPerPage;
        
        setItemCount(data.length);
        return data.slice(from, to);
    }, [watches, currentPageIndex, itemsPerPage]);
    
    const handleEdit = async ( id: number | undefined, active: boolean | undefined) => {
        if (id === undefined || active === undefined) return;
        try {
            const requestQuery = new URLSearchParams({
                id: id.toString(),
                active: active.toString(),
            });
            const url = `${GET_KELDAN_API_URL()}/MyKelda/EditWatch`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (!response.ok) {
                throw new Error(ErrorMessages.RequestFailed);
            }
            if (response.redirected) {
                window.location.href = response.url;
            }
            const responseBody: { [key: string]: Watch[]; } = await response.json();
            if (responseBody) {
                setWatches(responseBody)
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
        }
    }

    const handleDelete = async (ids: string) => {
        try {
            const requestQuery = new URLSearchParams({
                ids: ids
            });
            const url = `${GET_KELDAN_API_URL()}/MyKelda/DeleteWatches`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery,
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const responseBody: { [key: string]: Watch[]; } = await response.json();
                    if (responseBody) {
                        setWatches(responseBody);
                    }
                }
            } else {
                throw new Error(ErrorMessages.RequestFailed);
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
        }
    }

    const displayForm = (type: 'enabled' | 'disabled') => {
        return <LogbirtingaVaktinForm
            setData={(data) => setWatches(data)}
            disabled={type === 'disabled'}
        />
    }

    return (
        <div className="KCL_watch-tab">
            <SEO
               title="Lögbirtingavaktin"
               description="Settu fyrirtæki í vöktun og fáðu tilkynningu þegar nýjar niðurstöður birtast í Lögbirtingablaðinu"
               keyword="fyrirtækjavakt, lögbirting, lögbirtingar, lögbirtingablaðið, lögbirtingavakt, dómsbirtingar, dómsmálaauglýsingar"
            />
            <div className="upper-section">
                <div className="left-side">
                    <h3>Lögbirtingavaktin</h3>
                    <p className="description paragraph">
                        Settu í vöktun tiltekin orð eins og nafn eða kennitölu og fáðu tilkynningu þegar þau birtast í Lögbirtingablaðinu.
                    </p>
                    {isSubscriber
                        ? displayForm('enabled')
                        : <Tooltip
                            tooltip={<span>Aðeins í boði fyrir áskrifendur</span>}
                            text={displayForm('disabled')}
                        />
                    }
                </div>
                <Alert
                    type="info"
                    headText="Athugið"
                    text={"Keldan býður upp á vöktun á tilteknum upplýsingum sem birtast í ársreikningaskrá, hlutafélagaskrá og Lögbirtingablaði. " + 
                                "Þjónustan er til hagræðis fyrir notendur en Keldan ábyrgist ekki að vöktunin sé algild eða hnökralaus."}
                />
            </div>
            {isSubscriber && <>
                <div className="middle-section marginTop75px">
                    <h3 className="table-title">Lögbirtingar í vöktun</h3>
                    <div className="page-size-selector">
                        <span className="paragraph-small--bold">Sýna:</span>
                        <DropSelect
                            options={[{
                                label: '10 á síðu',
                                value: '10'
                            }, {
                                label: '50 á síðu',
                                value: '50'
                            }, {
                                label: '100 á síðu',
                                value: '100'
                            }]}
                            defaultValue={itemsPerPage.toString()}
                            size='lg'
                            onChange={(option) => { if (typeof option === 'string') setItemsPerPage(parseInt(option)); }}
                            className='page-size-select'
                        />
                    </div>
                </div>
                <Table
                    data={displayData}
                    columns={[{
                        title: 'í vöktun',
                        renderCell: ({term}) => term,
                        textAlign: 'left'
                    }, {
                        title: 'staða',
                        renderCell: ({active, term, id}) => (
                            <Checkbox 
                                label={(active) ? 'Virk' : 'Óvirk'}
                                name="status"
                                id={`status${term}`}
                                value={active ? 'true' : 'false'}
                                className="statusCheckbox"
                                onClick={() => handleEdit(id, !active)}
                            />
                        ),
                        textAlign: 'left'
                    }, {
                        title: '',
                        renderCell: ({id}) => (
                            <Button
                                size="sm"
                                buttonType="secondary"
                                onClick={() => {
                                    handleDelete(id.toString());
                                }}
                                icon={faTrashCan}
                            >
                                Eyða
                            </Button> 
                        ),
                    }]}
                    tableSize='lg'
                />
                <PageCounter />
            </>}
        </div>
    )
}

export default LogbirtingaVaktin;
