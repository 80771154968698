// Package imports:
import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Loading from '../../ui-elements/Loading/Loading';
import Alert from '../../ui-elements/Alert/Alert';
// Service imports:
import { getTableDate, convertToPercentage } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdShareholder } from '../../types/HlutabrefTypes';

const COLORS = ['#4569EE','#6CD3FF','#94EFB3','#FFF06B','#FF7697','#B07EF0'];

interface IChartDatum {
    name: string | undefined,
    y: number | undefined
}

interface IOwnProps {
    symbol: string,
    limit?: number
}

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const Shareholders: React.FC<Props> = ({
    symbol,
    limit = 5,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [data] = useApiLmdData<IApiLmdShareholder[]>(
        `/v1/market_data/v1/${symbol}/shareholders`,
        accessToken,
        refreshRateMs
    );
    const [chartData, setChartData] = useState<IChartDatum[]>([]);
    // Div references.
    const divRef = useRef<HTMLDivElement>(null);

    const handleData = (data: IApiLmdShareholder[]) => {
        const newChartData: IChartDatum[] = [];

        data.sort((a, b) => {
            if (a.Percentage === null && b.Percentage === null) return 0;
            if (a.Percentage === null) return 1;
            if (b.Percentage === null) return -1;
            return b.Percentage - a.Percentage;
        });
        let sum = 0;
        for (let datum of data.slice(0,limit)) {
            newChartData.push({
                name: datum.Owner ?? undefined,
                y: datum.Percentage ?? undefined
            });
            sum += datum.Percentage ?? 0;
        }
        if (newChartData.length === limit) {
            newChartData.push({
                name: 'Aðrir',
                y: 1 - sum
            });
        }
        setChartData(newChartData);
    }

    useEffect(() => {
        if (divRef.current === null) return;
        if (data.data === null || data.error instanceof Error) return;

        handleData(data.data);

        Highcharts.chart(divRef.current, {
            chart: {
                type: 'pie',
                height: 280,
                style: {
                    fontFamily: "Roboto"
                },
                animation: false
            },
            title: {
                text: '',
            },
            plotOptions: {
                pie: {
                    size: '100%',
                    center: ['50%','50%'],
                    animation: false
                }
            },
            series: [{
                type: 'pie',
                innerSize: '40%',
                data: chartData,
                dataLabels: {
                    enabled: false
                },
                showInLegend: true,
                colors: COLORS
            }],
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            tooltip: {
                enabled: false
            },
            legend: {
                enabled: false
            }
        }, () => {});
    }, [data, divRef.current]);

    const displayBody = () => {
        if (data.error instanceof Error) {
            return <Alert type="error" headText={data.error.message} />;
        } else if (data.data === null) {
            return <Loading />;
        } else if (data.data.length === 0) {
            return <Alert type='info' headText='Gögn vantar' />;
        } else {
            return <>
                <div className="shareholders-chart" ref={divRef}></div>
                <div className="shareholders-legend">
                    {chartData.map((d, i) => (
                        <div className="legend-item" key={d.name}>
                            <span className="color" style={{background: COLORS[i]}}></span>
                            <span className="text">{d.name} {convertToPercentage(d.y ?? null, true)}</span>
                        </div>
                    ))}
                </div>
            </>
        }
    }
    
    return (
        <DisplayBox
            className='KCL_shareholders'
            title="Hluthafar"
            asideComponent={data.error instanceof Error || data.data === null || data.data.length === 0 ? undefined
                : <span className="extra-info">
                    Uppfært {getTableDate(data.data[0].ValueDate, 'DD/MM/YYYY', '.')}
                </span>
            }
            {...props}
        >
            {displayBody()}
        </DisplayBox>
    );
}

export default Shareholders;