// Package imports:
import React, { useMemo } from 'react';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';
import Link from '../../../ui-elements/Link/Link';
import Loading from '../../../ui-elements/Loading/Loading';
import Table from '../../../ui-elements/Table/Table';
import VerticalTable from '../../../ui-elements/Table/VerticalTable';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import PropertyNavBar from '../PropertyNavBar/PropertyNavBar';
import Button from '../../../ui-elements/Button/Button';
// Service imports:
import { convertToPercentage, formatNumber } from '../../../services/utils';
import { useBuyingProcessUrl } from '../../../services/buyhook';
import { GET_KELDAN_API_URL } from '../../../services/config';
// Type imports:
import { IEventReport } from '../../../types/KeldanTypes';
import { IPropertySearchResultOld } from '../../../types/PropertyTypes';

interface IProps {
    id: string,
    report: IEventReport<IPropertySearchResultOld>,
    documentsAllowed: boolean
}

interface IBuyDocument {
    docnr: string,
    onr: string,
    fastanr: string
}

const ViewReportOld: React.FC<IProps> = ({
    id,
    report,
    documentsAllowed
}) => {
    const propertyNumber = useMemo(() => {
        if (report instanceof Error) return undefined;
        if (!report?.overview.landonly) {
            return report?.overview?.property?.property?.property?.property_number
        } else {
            return undefined;
        }
    }, [report]);

    const nameNumber = useMemo(() => {
        if (report instanceof Error) return undefined;
        if (!report?.overview.landonly) {
            return report?.overview.property?.property?.property?.name_number;
        } else {
            return undefined;
        }
    }, [report]);

    const padDateString = (date?: string) => {
		if (!date) {
            return '-';
        }
        date = date.replaceAll('/', '.')
		if (date[2] !== '.') date = date.padStart(date.length + 1, '0')
		if (date[5] !== '.') date = date.substring(0,3) + '0' + date.substring(3)
		return date;
	}

    const displayAddress = (address: string | undefined, city: string | undefined) => {
        if (address === undefined || city === undefined) return '';
        return `${address}, ${city}`;
    }

    const info = useMemo(() => {
        if (report instanceof Error) return null;
        return report?.overview.property?.property?.property;
    }, [report]);

    const { tryPurchaseItem, isItemLoading, ModalsAndToasts } = useBuyingProcessUrl(
        (doc: IBuyDocument, modalReference: string) => {
            const url = `${GET_KELDAN_API_URL()}/Pdf/Thinglyst-Skjal-Kaupa?docnr=${doc.docnr}&onr=${doc.onr}&fastanr=${doc.fastanr}&reference=${modalReference}`;
            return url;
        },
        () => {},
        'fasteignaskrá'
    );

    const displayReport = () => {
        if (report instanceof Error) {
            return <Alert type='error' headText={report.message} />;
        }
        if (report === undefined || report === null) {
            return <Loading />;
        }
        if (!report.overview.landonly) {
            return <>
                <PropertyNavBar propertyNumber={propertyNumber} />
                <div className='shell'>
                    <HeadSection
                        title={displayAddress(info?.name, info?.city)}
                        showRecentReports
                    />
                    <div className='info-col-wrapper'>
                        <div className='info-col'>
                            <div className='info-item'>
                                <span className='title'>
                                    Fastanúmer
                                </span>
                                <span className='value'>
                                    {propertyNumber}
                                </span>
                            </div>
                        </div>
                        <div className='info-col'>
                            <div className='info-item'>
                                <span className='title'>
                                    Merking
                                </span>
                                <span className='value'>
                                    {info?.units ? info?.units[0].tag : '-'}
                                </span>
                            </div>
                        </div>
                        <div className='info-col'>
                            <div className='info-item'>
                                <span className='title'>
                                    Heitisnúmer
                                </span>
                                <span className='value'>
                                    {nameNumber}
                                </span>
                            </div>
                        </div>
                        <div className='info-col'>
                            <div className='info-item'>
                                <span className='title'>
                                    Skýring
                                </span>
                                <span className='value'>
                                    {info?.units ? info?.units[0].description : '-'}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='property-page-body'>
                        <DisplayBox
                            title='Grunnupplýsingar'
                            asideComponent={
                                <div className='report-aside-component'>
                                    <div className='link-wrapper'>
                                        <Link
                                            targetBlank
                                            url={`/Pdf/Fasteignaskra-Gamalt/${id}`}
                                            icon='pdf'
                                        >
                                            Sækja skýrslu
                                        </Link>
                                    </div>
                                    <div className='italic-text'>
                                        Skýrsla keypt: {report.eventDate}
                                    </div>
                                </div>
                            }
                            className='property-report-box'
                        >
                            <div className='report-section'>
                                <div className='vertical-table-wrapper'>
                                    <VerticalTable
                                        fallbackSymbol
                                        data={[report.overview.property?.property?.property]}
                                        columns={[{
                                            title: 'Notkun',
                                            renderCell: (property) => property?.usage
                                        }]}
                                        tableSize='lg'
                                    />
                                    <VerticalTable
                                        fallbackSymbol
                                        data={[report.overview.property?.property?.property]}
                                        columns={[{
                                            title: 'Tryggingafélag',
                                            renderCell: (property) => property?.insurance
                                        }]}
                                        tableSize='lg'
                                    />
                                </div>
                            </div>

                            {report.overview.property?.property?.property?.owners &&
                                <div className='report-section'>
                                    <h4>Eigendur</h4>
                                    <Table
                                        data={report.overview.property.property.property.owners}
                                        columns={[{
                                            title: 'Nafn',
                                            renderCell: ({name}) => name,
                                            textAlign: 'left'
                                        }, {
                                            title: 'Kennitala',
                                            renderCell: ({ssn}) => ssn,
                                            textAlign: 'left'
                                        }, {
                                            title: 'Hlutfall',
                                            renderCell: ({owned_part}) => convertToPercentage(owned_part == null ? null : owned_part/100, true, 1),
                                            textAlign: 'right'
                                        }, {
                                            title: 'Kaup',
                                            renderCell: ({buy_date}) => padDateString(buy_date),
                                            textAlign: 'right'
                                        }, {
                                            title: 'Afhending',
                                            renderCell: ({receive_date}) => padDateString(receive_date),
                                            textAlign: 'right'
                                        }]}
                                        tableSize='lg'
                                    />
                                </div>
                            }

                            {report.overview.property?.property?.property?.units &&
                                <>
                                    {report.overview.property.property.property.units.map((u, index) => (
                                        <div className='report-section' key={`unit_${index}`}>
                                            <h4>{u.usage}</h4>
                                            <div className='vertical-table-wrapper'>
                                                <VerticalTable
                                                    data={[u]}
                                                    columns={[{
                                                        title: 'Fastanúmer',
                                                        renderCell: ({property_number}) => property_number
                                                    }, {
                                                        title: 'Landnúmer',
                                                        renderCell: ({land_number}) => land_number
                                                    }, {
                                                        title: 'Heitisnúmer',
                                                        renderCell: ({name_number}) => name_number
                                                    }, {
                                                        title: 'Merking',
                                                        renderCell: ({tag}) => tag
                                                    }, {
                                                        title: 'Flatarmál',
                                                        renderCell: ({area, area_unit}) => `${area} ${area_unit}`
                                                    }, {
                                                        title: 'Byggingarár',
                                                        renderCell: ({building_year}) => building_year
                                                    }, {
                                                        title: 'Herbergi',
                                                        renderCell: ({rooms}) => rooms
                                                    }, {
                                                        title: 'Byggingarefni',
                                                        renderCell: ({building_material}) => building_material
                                                    }]}
                                                    tableSize='lg'
                                                />
                                                <VerticalTable
                                                    data={[u]}
                                                    columns={[{
                                                        title: 'Byggingarstig',
                                                        renderCell: ({building_status}) => building_status
                                                    }, {
                                                        title: 'Húsmat',
                                                        renderCell: ({house_estimation}) => `${formatNumber(house_estimation, '-', 0)} kr.`
                                                    }, {
                                                        title: 'Lóðarhlutamat',
                                                        renderCell: ({land_estimation}) => `${formatNumber(land_estimation, '-', 0)} kr.`
                                                    }, {
                                                        title: 'Fasteignamat',
                                                        renderCell: ({property_estimation}) => `${formatNumber(property_estimation, '-', 0)} kr.`
                                                    }, {
                                                        title: 'Endurstofnverð',
                                                        renderCell: ({reform_price}) => `${formatNumber(reform_price, '-', 0)} kr.`
                                                    }, {
                                                        title: 'Brunabótamat',
                                                        renderCell: ({burn_estimation}) => `${formatNumber(burn_estimation, '-', 0)} kr.`
                                                    }, {
                                                        title: 'Byggingarvísit.d.',
                                                        renderCell: ({building_index_date}) => padDateString(building_index_date)
                                                    }]}
                                                    tableSize='lg'
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </>
                            }

                        </DisplayBox>

                        {report.overview.bondDetails && 
                            <>
                                <h2 className='bonds-heading'>Veðbandayfirlit</h2>
                                <DisplayBox
                                    className='property-report-box'
                                >
                                    {report.overview.bondDetails.bondDetails?.bondDetails?.owners?.map((owner, index) => (
                                        <div className='report-section' key={`bondowner_${index}`}>
                                            <h4>{owner.name}</h4>
                                            <div className='vertical-table-wrapper'>
                                                <VerticalTable
                                                    data={[owner]}
                                                    columns={[{
                                                        title: 'Kennitala',
                                                        renderCell: ({ssn}) => ssn
                                                    }, {
                                                        title: 'Málsaðili',
                                                        renderCell: ({name}) => name
                                                    }, {
                                                        title: 'Eignarhlutfall',
                                                        renderCell: ({owned_part}) => convertToPercentage(owned_part === undefined ? null : owned_part/100, true, 1)
                                                    }, {
                                                        title: 'Tegund skjals',
                                                        renderCell: ({document_type}) => document_type
                                                    }, {
                                                        title: 'Númer',
                                                        renderCell: ({document_number}) => document_number
                                                    }]}
                                                    tableSize='lg'
                                                />
                                                <VerticalTable
                                                    data={[owner]}
                                                    columns={[{
                                                        title: 'Staða skjals',
                                                        renderCell: ({document_status}) => document_status
                                                    }, {
                                                        title: 'Útgáfudagur',
                                                        renderCell: ({document_published}) => padDateString(document_published)
                                                    }, {
                                                        title: 'Móttökudagur',
                                                        renderCell: ({document_received}) => padDateString(document_received)
                                                    }, {
                                                        title: 'Embættisnúmer',
                                                        renderCell: ({office_number}) => office_number
                                                    }, {
                                                        title: 'Skjal',
                                                        renderCell: ({document_number, office_number}) => (
                                                            <Button
                                                                size='sm'
                                                                buttonType='buy'
                                                                showLoader={isItemLoading({ docnr: document_number ?? '', onr: office_number ?? '', fastanr: propertyNumber ?? '' })}
                                                                onClick={() => tryPurchaseItem({
                                                                    docnr: document_number ?? '',
                                                                    onr: office_number ?? '',
                                                                    fastanr: propertyNumber ?? ''
                                                                }, 'confirm-modal-with-reference')}
                                                                style={{marginTop: '-5px', marginBottom: '-5px'}}
                                                                disabled={!documentsAllowed}
                                                            >
                                                                Kaupa
                                                            </Button>
                                                        )
                                                    }]}
                                                    tableSize='lg'
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    {report.overview.bondDetails.bondDetails?.bondDetails?.bonds?.map((bond, index) => (
                                        <div className='report-section' key={`bond_${index}`}>
                                            <h4>{bond.number}. veðréttur</h4>
                                            <div className='vertical-table-wrapper'>
                                                <VerticalTable
                                                    data={[bond]}
                                                    columns={[{
                                                        title: 'Upphæð',
                                                        renderCell: ({amount, currency}) => `${amount} ${currency}`
                                                    }, {
                                                        title: 'Myntgengi',
                                                        renderCell: ({currency_rate}) => currency_rate
                                                    }, {
                                                        title: 'Númer',
                                                        renderCell: ({id}) => id
                                                    }, {
                                                        title: 'Tegund',
                                                        renderCell: ({type}) => type
                                                    }, {
                                                        title: 'Verðbinding',
                                                        renderCell: ({index_status}) => index_status
                                                    }]}
                                                    tableSize='lg'
                                                />
                                                <VerticalTable
                                                    data={[bond]}
                                                    columns={[{
                                                        title: 'Vísitölustig',
                                                        renderCell: ({index_state}) => index_state
                                                    }, {
                                                        title: 'Veðhafi',
                                                        renderCell: ({loaner}) => loaner
                                                    }, {
                                                        title: 'Útgáfudagur',
                                                        renderCell: ({published}) => padDateString(published)
                                                    }, {
                                                        title: 'Móttökudagur',
                                                        renderCell: ({received}) => padDateString(received)
                                                    }, {
                                                        title: 'Skjal',
                                                        renderCell: ({id, office_number}) => (
                                                            <Button
                                                                size='sm'
                                                                buttonType='buy'
                                                                showLoader={isItemLoading({ docnr: id ?? '', onr: office_number ?? '', fastanr: propertyNumber ?? '' })}
                                                                onClick={() => tryPurchaseItem({
                                                                    docnr: id ?? '',
                                                                    onr: office_number ?? '',
                                                                    fastanr: propertyNumber ?? ''
                                                                }, 'confirm-modal-with-reference')}
                                                                style={{marginTop: '-5px', marginBottom: '-5px'}}
                                                                disabled={!documentsAllowed}
                                                            >
                                                                Kaupa
                                                            </Button>
                                                        ),
                                                        overrideDefaultStyle: true
                                                    }]}
                                                    tableSize='lg'
                                                />
                                            </div>
                                        </div>  
                                    ))}
                                    {report.overview.bondDetails.bondDetails?.bondDetails?.primary_allowances &&
                                        <div className='report-section'>
                                            <h4>Frumheimildir</h4>
                                            <Table
                                                data={report.overview.bondDetails.bondDetails.bondDetails.primary_allowances}
                                                columns={[{
                                                    title: 'Númer',
                                                    renderCell: ({document_number}) => document_number,
                                                    textAlign: 'left'
                                                }, {
                                                    title: 'Staða',
                                                    renderCell: ({document_status}) => document_status,
                                                    textAlign: 'left'
                                                }, {
                                                    title: 'Tegund',
                                                    renderCell: ({document_type}) => document_type,
                                                    textAlign: 'left'
                                                }, {
                                                    title: 'Útgáfudagur',
                                                    renderCell: ({document_published}) => padDateString(document_published),
                                                    textAlign: 'right'
                                                }, {
                                                    title: 'Móttökudagur',
                                                    renderCell: ({document_received}) => padDateString(document_received),
                                                    textAlign: 'right'
                                                }, {
                                                    title: 'Skjal',
                                                    renderCell: ({document_number, office_number}) => (
                                                        <Button
                                                            size='sm'
                                                            buttonType='buy'
                                                            showLoader={isItemLoading({ docnr: document_number ?? '', onr: office_number ?? '', fastanr: propertyNumber ?? '' })}
                                                            onClick={() => tryPurchaseItem({
                                                                docnr: document_number ?? '',
                                                                onr: office_number ?? '',
                                                                fastanr: propertyNumber ?? ''
                                                            }, 'confirm-modal-with-reference')}
                                                            disabled={!documentsAllowed}
                                                        >
                                                            Kaupa
                                                        </Button>
                                                    )
                                                }]}
                                                tableSize='lg'
                                            />
                                        </div>
                                    }
                                    {report.overview.bondDetails.bondDetails?.bondDetails?.contracts &&
                                        <div className='report-section'>
                                            <h4>Efnisatriði</h4>
                                            <Table
                                                data={report.overview.bondDetails.bondDetails.bondDetails.contracts}
                                                columns={[{
                                                    title: 'Númer',
                                                    renderCell: ({document_number}) => document_number,
                                                    textAlign: 'left'
                                                }, {
                                                    title: 'Staða',
                                                    renderCell: ({document_status}) => document_status,
                                                    textAlign: 'left'
                                                }, {
                                                    title: 'Embættisnúmer',
                                                    renderCell: ({office_number}) => office_number,
                                                    textAlign: 'left'
                                                }, {
                                                    title: 'Tegund',
                                                    renderCell: ({document_type}) => document_type,
                                                    textAlign: 'left'
                                                }, {
                                                    title: 'Lýsing',
                                                    renderCell: ({description}) => description,
                                                    textAlign: 'left'
                                                }, {
                                                    title: 'Skjal',
                                                    renderCell: ({document_number, office_number}) => (
                                                        <Button
                                                            size='sm'
                                                            buttonType='buy'
                                                            showLoader={isItemLoading({ docnr: document_number ?? '', onr: office_number ?? '', fastanr: propertyNumber ?? '' })}
                                                            onClick={() => tryPurchaseItem({
                                                                docnr: document_number ?? '',
                                                                onr: office_number ?? '',
                                                                fastanr: propertyNumber ?? ''
                                                            }, 'confirm-modal-with-reference')}
                                                            disabled={!documentsAllowed}
                                                        >
                                                            Kaupa
                                                        </Button>
                                                    )
                                                }]}
                                                tableSize='lg'
                                            />
                                        </div>
                                    }
                                </DisplayBox>
                                <ModalsAndToasts />
                            </>
                        }
                    </div>
                    <Alert
                        type='info'
                        headText='Athugið' 
                        text={
                            <>
                                <p>Birtar upplýsingar eru sóttar með tengingu við Fasteignaskrá Íslands. Allar upplýsingar eru veittar samkvæmt starfsreglum Fasteignaskrár og í samræmi við lög og reglur um persónuvernd og meðferð persónuupplýsinga.</p>
                                <p>Reynt er að tryggja að upplýsingar séu réttar en Keldan getur ekki ábyrgst áreiðanleika þeirra.</p>
                            </>
                        }
                    />
                </div>
            </>
        }
        return <div className='shell'>
            <HeadSection
                title={`${report.overview.property?.property?.land?.name}, ${report.overview.property?.property?.land?.city}`}
                showRecentReports
            />
            <div className='info-col-wrapper'>
                <div className='info-col'>
                    <div className='info-item'>
                        <span className='title'>
                            Lóðarnúmer
                        </span>
                        <span className='value'>
                            {report.overview.property?.property?.land?.plot_number}
                        </span>
                    </div>
                </div>
                <div className='info-col'>
                    <div className='info-item'>
                        <span className='title'>
                            Heitisnúmer
                        </span>
                        <span className='value'>
                            {report.overview.property?.property?.land?.name_number}
                        </span>
                    </div>
                </div>
            </div>
            <div className='property-page-body'>
                <DisplayBox
                    title='Grunnupplýsingar'
                    asideComponent={
                        <div className='report-aside-component'>
                            <div className='link-wrapper'>
                                <Link
                                    targetBlank
                                    url={`/Pdf/Fasteignaskra-Land-Gamalt/${id}`}
                                    icon='pdf'
                                >
                                    Sækja skýrslu
                                </Link>
                            </div>
                            <div className='italic-text'>
                                Skýrsla keypt: {report.eventDate}
                            </div>
                        </div>
                    }
                    className='property-report-box'
                >
                    <div className='report-section'>
                        <div className='vertical-table-wrapper'>
                            <VerticalTable
                                data={[report.overview.property?.property?.land]}
                                columns={[{
                                    title: 'Landnúmer',
                                    renderCell: (land) => land?.land_number
                                }, {
                                    title: 'Heitisnúmer',
                                    renderCell: (land) => land?.name_number
                                }, {
                                    title: 'Byggð',
                                    renderCell: (land) => land?.city
                                }, {
                                    title: 'Lóðarnúmer',
                                    renderCell: (land) => land?.plot_number
                                }]}
                                tableSize='lg'
                            />
                            <VerticalTable
                                data={[report.overview.property?.property?.land]}
                                columns={[{
                                    title: 'Flatarmál',
                                    renderCell: (land) => `${land?.area} ${land?.area_unit}`
                                }, {
                                    title: 'Lóðarmat',
                                    renderCell: (land) => `${formatNumber(land?.land_estimation, '-', 0)} kr.`
                                }, {
                                    title: 'Notkun',
                                    renderCell: (land) => land?.usage
                                }]}
                                tableSize='lg'
                            />
                        </div>
                    </div>
                    {report.overview.property?.property?.land?.owners &&
                        <div className='report-section'>
                            <h4>Eigendur</h4>
                            <Table
                                data={report.overview.property.property.land.owners}
                                columns={[{
                                    title: 'Nafn',
                                    renderCell: ({name}) => name,
                                    textAlign: 'left'
                                }, {
                                    title: 'Kennitala',
                                    renderCell: ({ssn}) => ssn,
                                    textAlign: 'left'
                                }, {
                                    title: 'Hlutfall',
                                    renderCell: ({owned_part}) => convertToPercentage(owned_part === undefined || owned_part === null ? null : owned_part/100, true, 1),
                                    textAlign: 'right'
                                }, {
                                    title: 'Kaup',
                                    renderCell: ({buy_date}) => padDateString(buy_date),
                                    textAlign: 'right'
                                }, {
                                    title: 'Afhending',
                                    renderCell: ({receive_date}) => padDateString(receive_date),
                                    textAlign: 'right'
                                }]}
                                tableSize='lg'
                            />
                        </div>
                    }
                </DisplayBox>

                {report.overview.bondDetails && 
                    <>
                        <h2 className='bonds-heading'>Veðbandayfirlit</h2>
                        <DisplayBox
                            className='property-report-box'
                        >
                            {report.overview.bondDetails.bondDetails?.bondDetails?.owners?.map((owner, index) => (
                                <div className='report-section' key={`bondowner_${index}`}>
                                    <h4>{owner.name}</h4>
                                    <div className='vertical-table-wrapper'>
                                        <VerticalTable
                                            data={[owner]}
                                            columns={[{
                                                title: 'Kennitala',
                                                renderCell: ({ssn}) => ssn
                                            }, {
                                                title: 'Málsaðili',
                                                renderCell: ({name}) => name
                                            }, {
                                                title: 'Eignarhlutfall',
                                                renderCell: ({owned_part}) => convertToPercentage(owned_part === undefined ? null : owned_part/100, true, 1)
                                            }, {
                                                title: 'Tegund skjals',
                                                renderCell: ({document_type}) => document_type
                                            }, {
                                                title: 'Númer',
                                                renderCell: ({document_number}) => document_number
                                            }]}
                                            tableSize='lg'
                                        />
                                        <VerticalTable
                                            data={[owner]}
                                            columns={[{
                                                title: 'Staða skjals',
                                                renderCell: ({document_status}) => document_status
                                            }, {
                                                title: 'Útgáfudagur',
                                                renderCell: ({document_published}) => padDateString(document_published)
                                            }, {
                                                title: 'Móttökudagur',
                                                renderCell: ({document_received}) => padDateString(document_received)
                                            }, {
                                                title: 'Embættisnúmer',
                                                renderCell: ({office_number}) => office_number
                                            }]}
                                            tableSize='lg'
                                        />
                                    </div>
                                </div>
                            ))}
                            {report.overview.bondDetails.bondDetails?.bondDetails?.bonds?.map((bond, index) => (
                                <div className='report-section' key={`bond_${index}`}>
                                    <h4>{bond.number}. veðréttur</h4>
                                    <div className='vertical-table-wrapper'>
                                        <VerticalTable
                                            data={[bond]}
                                            columns={[{
                                                title: 'Upphæð',
                                                renderCell: ({amount, currency}) => `${amount} ${currency}`
                                            }, {
                                                title: 'Myntgengi',
                                                renderCell: ({currency_rate}) => currency_rate
                                            }, {
                                                title: 'Númer',
                                                renderCell: ({id}) => id
                                            }, {
                                                title: 'Tegund',
                                                renderCell: ({type}) => type
                                            }, {
                                                title: 'Verðbinding',
                                                renderCell: ({index_status}) => index_status
                                            }]}
                                            tableSize='lg'
                                        />
                                        <VerticalTable
                                            data={[bond]}
                                            columns={[{
                                                title: 'Vísitölustig',
                                                renderCell: ({index_state}) => index_state
                                            }, {
                                                title: 'Veðhafi',
                                                renderCell: ({loaner}) => loaner
                                            }, {
                                                title: 'Útgáfudagur',
                                                renderCell: ({published}) => padDateString(published)
                                            }, {
                                                title: 'Móttökudagur',
                                                renderCell: ({received}) => padDateString(received)
                                            }]}
                                            tableSize='lg'
                                        />
                                    </div>
                                </div>  
                            ))}
                            {report.overview.bondDetails.bondDetails?.bondDetails?.primary_allowances &&
                                <div className='report-section'>
                                    <h4>Frumheimildir</h4>
                                    <Table
                                        data={report.overview.bondDetails.bondDetails.bondDetails.primary_allowances}
                                        columns={[{
                                            title: 'Númer',
                                            renderCell: ({document_number}) => document_number,
                                            textAlign: 'left'
                                        }, {
                                            title: 'Staða',
                                            renderCell: ({document_status}) => document_status,
                                            textAlign: 'left'
                                        }, {
                                            title: 'Tegund',
                                            renderCell: ({document_type}) => document_type,
                                            textAlign: 'left'
                                        }, {
                                            title: 'Útgáfudagur',
                                            renderCell: ({document_published}) => padDateString(document_published),
                                            textAlign: 'right'
                                        }, {
                                            title: 'Móttökudagur',
                                            renderCell: ({document_received}) => padDateString(document_received),
                                            textAlign: 'right'
                                        }]}
                                        tableSize='lg'
                                    />
                                </div>
                            }
                            {report.overview.bondDetails.bondDetails?.bondDetails?.contracts &&
                                <div className='report-section'>
                                    <h4>Efnisatriði</h4>
                                    <Table
                                        data={report.overview.bondDetails.bondDetails.bondDetails.contracts}
                                        columns={[{
                                            title: 'Númer',
                                            renderCell: ({document_number}) => document_number,
                                            textAlign: 'left'
                                        }, {
                                            title: 'Staða',
                                            renderCell: ({document_status}) => document_status,
                                            textAlign: 'left'
                                        }, {
                                            title: 'Embættisnúmer',
                                            renderCell: ({office_number}) => office_number,
                                            textAlign: 'left'
                                        }, {
                                            title: 'Tegund',
                                            renderCell: ({document_type}) => document_type,
                                            textAlign: 'left'
                                        }, {
                                            title: 'Lýsing',
                                            renderCell: ({description}) => description,
                                            textAlign: 'left'
                                        }]}
                                        tableSize='lg'
                                    />
                                </div>
                            }
                        </DisplayBox>
                    </>
                }
            </div>
            <Alert
                withCloseIcon
                type='info'
                headText='Athugið' 
                text={
                    <>
                        <p>Birtar upplýsingar eru sóttar með tengingu við Fasteignaskrá Íslands. Allar upplýsingar eru veittar samkvæmt starfsreglum Fasteignaskrár og í samræmi við lög og reglur um persónuvernd og meðferð persónuupplýsinga.</p>
                        <p>Reynt er að tryggja að upplýsingar séu réttar en Keldan getur ekki ábyrgst áreiðanleika þeirra.</p>
                    </>
                }
            />
        </div>
    }

    return (
        <div className='main KCL_property-page'>
            <div className='main__inner fluid'>
                {displayReport()}
            </div>
        </div>
    );
}

export default ViewReportOld;