// Package imports:
import { Fragment, useEffect, useMemo, useState } from 'react';
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
// Component imports:
import Input from '../Input/Input';
import DropSelect from '../DropSelect/DropSelect';

export type AlignOptions = 'left' | 'center' | 'right';
type TableSize = 'sm' | 'lg';
const ALL_OPTIONS_VALUE = 'Allt';

interface ISortingInfo {
    sortColumnIndex: number | null,
    sortColumnTitle: string | JSX.Element | null,
    reverseSort: boolean
}

type FilteringInfo = {
    [filterId in string]?: IStringFilter | INumberFilter
}

interface IStringFilter {
    type: 'select' | 'string',
    value: string
}

interface INumberFilter {
    type: 'number-range',
    lowValue: number | null
    highValue: number | null
}

type ColumnFilter<T> = {
    id: string,
    forcedValue?: string
    onChange?: (value: string) => void;
} & ({
    type: string[] | 'string',
    value: (a: T) => string | null,
} | {
    type: 'number-range',
    value: (a: T) => number | null,
})

export interface IColumn<T> {
    title: string | JSX.Element
    renderCell(dataItem: T, rowIndex: number, colIndex: number): any,
    textAlign?: AlignOptions,
    overrideTd?: boolean,
    sortable?: (a: T, b: T) => number,
    simpleSortable?: (a: T) => number | string | null,
    filter?: ColumnFilter<T>,
    overrideTh_class?: string,
    maxColumnWidth?: string,
}

type ExpandableRow<T> = {
    [rowTitle: string]: Array<T>
}

interface IProps<T> {
    data: Array<T> | ExpandableRow<T> | null
    columns: IColumn<T>[]
    expandableRowConfig?: {
        defaultExpandedIndexes?: number[],
        showNumberOfItemsInExpandable?: boolean
    },
    rowClassName?: (dataItem: T) => string | undefined
    dontShowHeaders?: boolean,
    tableSize?: TableSize,
    indexHeader?: string,
    renderUnderRowComponent?: (datum: T, index: number) => JSX.Element | null,
    emptyTableText?: string,
    showEmptyTableText?: boolean,
    className?: string 
}

const Table = <T extends any>({
    data,
    columns,
    expandableRowConfig,
    rowClassName,
    dontShowHeaders,
    tableSize = 'sm',
    indexHeader,
    renderUnderRowComponent,
    emptyTableText,
    showEmptyTableText,
    className
}: IProps<T>) => {
    // Set states.
    const [ expandedIndexes, setExpandedIndexes ] = useState(expandableRowConfig?.defaultExpandedIndexes ?? []);
    const [ sortingInfo, setSortingInfo ] = useState<ISortingInfo>({
        sortColumnIndex: null,
        sortColumnTitle: null,
        reverseSort: false
    });

    const [ filterInfoMap, setFilterInfoMap ] = useState<FilteringInfo>({});
    const isFilterable = useMemo(() => columns.some(col => (col.filter !== undefined)), [ columns ]);
    
    // When columns change (due to filter, forexample), check if sort still applies
    useEffect(() => {
        // Check index has same value
        const { sortColumnIndex, sortColumnTitle, reverseSort } = sortingInfo;
        if (sortColumnIndex === null || sortColumnTitle === null) return;
        if (columns[sortColumnIndex]?.title === sortColumnTitle) return;
        // If not, find value.
        const indexOfColumnWithTitle = columns.findIndex((column) => column.title === sortColumnTitle);
        if (indexOfColumnWithTitle >= 0) {
            setSortingInfo({
                sortColumnIndex: indexOfColumnWithTitle,
                sortColumnTitle: columns[indexOfColumnWithTitle].title,
                reverseSort
            });
        }
        // If not found, reset sort
        else {
            setSortingInfo({
                sortColumnIndex: null,
                sortColumnTitle: null,
                reverseSort: false
            });
        }
    }, [columns]);

    // Helper funcy's (AKA Hunkys).
    const isExpandableTable = (data: Array<T> | ExpandableRow<T> | null): data is ExpandableRow<T> => (data !== undefined && data !== null && !Array.isArray(data));
    const getAlignStyles = (align?: AlignOptions) => ({ textAlign: align ?? 'right' });
    const sortSimple = (simpleSortable: (a: T) => number | string | null, datum1: T, datum2: T, reverse: (returnValue: number) => number) => {
        const simpleDatum1 = simpleSortable(datum1),
              simpleDatum2 = simpleSortable(datum2);
        // Null checks
        if (simpleDatum1 === null && simpleDatum2 === null) return 0;
        if (simpleDatum1 === null) return reverse(-1);
        if (simpleDatum2 === null) return reverse(1);
        // String sort:
        if (typeof simpleDatum1 === 'string' && typeof simpleDatum2 === 'string') {
            if (simpleDatum1 === simpleDatum2) return 0;
            if (simpleDatum1 < simpleDatum2) return reverse(-1);
            return reverse(1);
        }
        // Number sort:
        if (typeof simpleDatum1 === 'number' && typeof simpleDatum2 === 'number') {
            return reverse(simpleDatum1 - simpleDatum2);
        }
    }
    const sortDataArray = (data: T[]) => ([...data].sort((datum1, datum2) => {
        const { sortColumnIndex, reverseSort } = sortingInfo;
        const reverse = (returnValue: number) => (reverseSort) ? (-returnValue) : returnValue;
        if (sortColumnIndex === null) return 0;
        const { sortable, simpleSortable } = columns[sortColumnIndex] ?? {};
        // Start with simpleSortable
        if (simpleSortable !== undefined) {
            return sortSimple(simpleSortable, datum1, datum2, reverse) ?? 0;
        }
        // Else perform sortable sort.
        if (sortable !== undefined) return reverse(sortable(datum1, datum2));
        // Default: Normal sort.
        return 0;
    }));

    const filterDataArray = (data: T[]) => {
        let dataArray = data;
        for (const {filter} of columns) {
            if (filter === undefined) continue;
            const singleFilterInfo = filterInfoMap[filter.id];
            if (singleFilterInfo === undefined) continue;
            // Type string: search for substring.
            if (filter.type === 'string' && singleFilterInfo.type === 'string' && singleFilterInfo.value !== '') {
                dataArray = dataArray.filter(datum => {
                    const stringvalue = filter.value(datum)?.toLowerCase();
                    const val = singleFilterInfo.value.toLowerCase();
                    const includes = stringvalue?.includes(val);
                    return includes;
                });
            }
            else if (filter.type === 'number-range' && singleFilterInfo.type === 'number-range') {
                const { lowValue, highValue } = singleFilterInfo;
                dataArray = dataArray.filter(datum => {
                    const numbervalue = filter.value(datum);
                    if (numbervalue === null) return false;
                    if (lowValue === null) {
                        if (highValue === null) return true;
                        return (numbervalue < highValue);
                    } else {
                        if (highValue === null) return (numbervalue > lowValue);
                        return (numbervalue > lowValue && numbervalue < highValue);
                    }
                });
            }
            // Type array: search for exact match.
            else if (Array.isArray(filter.type) && singleFilterInfo.type === 'select') {
                if (singleFilterInfo.value === ALL_OPTIONS_VALUE) continue;
                dataArray = dataArray.filter(datum => filter.value(datum) === singleFilterInfo.value)
            }
        }
        return dataArray;
    }


    // Display functions
    const displayNumberRangeFilter = (filter: ColumnFilter<T>) => {
        if (filter.type !== 'number-range') return null;
        const singleFilterInfo = filterInfoMap[filter.id];
        let lowValue: number | null = null
        let highValue: number | null = null;
        if (singleFilterInfo?.type === 'number-range') {
            lowValue = singleFilterInfo.lowValue;
            highValue = singleFilterInfo.highValue;
        }

        return (
            <div className='number-range-wrapper'>
                <Input
                    inputType='numberFormat'
                    inputSize='sm'
                    label='Min'
                    value={lowValue ?? undefined}
                    onValueChange={(numberFormatValue) => {
                        const { floatValue, value } = numberFormatValue;
                        if (filter.onChange) filter.onChange(value);
                        setFilterInfoMap({
                            ...filterInfoMap,
                            [filter.id]: {
                                type: 'number-range',
                                lowValue: floatValue ?? null,
                                highValue
                            }
                        })
                    }}
                />
                <Input
                    inputType='numberFormat'
                    inputSize='sm'
                    label='Max'
                    value={highValue ?? undefined}
                    onValueChange={(numberFormatValue) => {
                        const { floatValue, value } = numberFormatValue;
                        if (filter.onChange) filter.onChange(value);
                        setFilterInfoMap({
                            ...filterInfoMap,
                            [filter.id]: {
                                type: 'number-range',
                                lowValue,
                                highValue: floatValue ?? null
                            }
                        })
                    }}
                />
            </div>
        )
    }

    const displayHead = () => {
        if (dontShowHeaders) return null;
        return <thead>
            <tr>
                {indexHeader &&
                    <th
                        key={0}
                        style={getAlignStyles('left')}
                    >
                        {indexHeader}
                    </th>
                }
                {columns.map(({ title, textAlign, sortable, simpleSortable, filter, overrideTh_class }, index) => {
                    const isSortable = (sortable !== undefined || simpleSortable !== undefined);
                    const getCaret = () => {
                        if (!isSortable || sortingInfo.sortColumnIndex !== index) return null;
                        return sortingInfo.reverseSort
                            ? <span className='caret-span is-descending'>
                                <FontAwesomeIcon icon={faCaretDown} />
                            </span>
                            : <span className='caret-span is-ascending'>
                                <FontAwesomeIcon icon={faCaretUp} />
                            </span>
                    }
                    const getFilter = () => {
                        if (filter === undefined) return null;
                        if (filter.type === 'string') {
                            return <Input
                                inputSize='sm'
                                value={filter.forcedValue}
                                onChange={(e) => {
                                    if (filter.onChange) filter.onChange(e.target.value);
                                    setFilterInfoMap({
                                        ...filterInfoMap,
                                        [filter.id]: {
                                            type: 'string',
                                            value: e.target.value
                                        }
                                    })
                                }}
                                aria-label={typeof title === 'string' ? title : ''}
                            />
                        }
                        if (filter.type === 'number-range') {
                            return displayNumberRangeFilter(filter)
                        }
                        return <DropSelect
                            size='xs'
                            defaultValue={filter.forcedValue ?? ALL_OPTIONS_VALUE}
                            options={[ALL_OPTIONS_VALUE].concat(filter.type).map(str => ({
                                id: str,
                                label: str,
                                value: str
                            }))}
                            onChange={(str) => {
                                if (typeof str === 'string') {
                                    if (filter.onChange) filter.onChange(str);
                                    setFilterInfoMap({
                                        ...filterInfoMap,
                                        [filter.id]: {
                                            type: 'select',
                                            value: str
                                        }
                                    })
                                }
                            }}
                            aria-label={typeof title === 'string' ? title : ''}
                        />
                    }
                    return (
                        <th
                            key={index+1}
                            className={cx(`align-${textAlign}`, {'is-sortable': isSortable})}
                            style={getAlignStyles(textAlign)}
                            onClick={() => (filter !== undefined) ? undefined : setSortingInfo({
                                sortColumnIndex: (sortingInfo.sortColumnIndex === index && sortingInfo.reverseSort) ? null : index,
                                sortColumnTitle: (sortingInfo.sortColumnIndex === index && sortingInfo.reverseSort) ? null : title,
                                reverseSort: (sortingInfo.sortColumnIndex === index) ? !sortingInfo.reverseSort : false
                            })}
                        >
                            <span className='title-span'
                                onClick={() => (filter === undefined) ? undefined : setSortingInfo({
                                    sortColumnIndex: (sortingInfo.sortColumnIndex === index && sortingInfo.reverseSort) ? null : index,
                                    sortColumnTitle: (sortingInfo.sortColumnIndex === index && sortingInfo.reverseSort) ? null : title,
                                    reverseSort: (sortingInfo.sortColumnIndex === index) ? !sortingInfo.reverseSort : false
                                })}
                            >
                                <span className={cx('text-span', overrideTh_class)}>
                                    {getCaret()}
                                    {title}
                                </span>
                            </span>
                            <div className={cx('form-wrapper', textAlign ?? 'right')}>
                                {getFilter()}
                            </div>
                        </th>
                    );
                })}
            </tr>
        </thead>
    }

    const displayBody = () => {
        if (data === undefined || data === null || data.length === 0) {
            if (showEmptyTableText) return emptyBody();
            return null;
        }
        if (isExpandableTable(data)) return displayExpandableBody(data);
        return displayRowBody(data);
    };

    const emptyBody = () => {
        return <tbody><tr><td colSpan={99}>{emptyTableText ?? 'Engin gögn fundust'}</td></tr></tbody>
    }

    const displayRowBody = (data: Array<T>) => (
        <tbody>
            {sortDataArray(filterDataArray(data)).map((datum, rowIndex) => (
                <Fragment key={rowIndex}>
                    <tr className={rowClassName && rowClassName(datum)}>
                        {indexHeader &&
                            <td>{rowIndex + 1}</td>
                        }
                        {columns.map(({ renderCell, textAlign, overrideTd, maxColumnWidth }, colIndex) => (
                            overrideTd
                                ? <Fragment key={colIndex}>
                                    {renderCell(datum, rowIndex, colIndex)}
                                </Fragment>
                                : <td
                                    className={`align-${textAlign}`}
                                    key={colIndex}
                                    style={{ ...getAlignStyles(textAlign), maxWidth: maxColumnWidth }}
                                >
                                    {renderCell(datum, rowIndex, colIndex)}
                                </td>
                        ))}
                    </tr>
                    {renderUnderRowComponent && renderUnderRowComponent(datum, rowIndex)}
                </Fragment>
            ))}
        </tbody>
    );

    const clickExpandableRow = (rowIndex: number) => {
        if (expandedIndexes.includes(rowIndex)) {
            const setWithRemovedIndex = new Set(expandedIndexes)
            setWithRemovedIndex.delete(rowIndex);
            setExpandedIndexes(Array.from(setWithRemovedIndex));
        } else {
            setExpandedIndexes([...expandedIndexes, rowIndex]);
        }
    }

    const displayExpandableBody = (data: ExpandableRow<T>) => {
        return Object.entries(data).map(([ sectionTitle, sectionData ], sectionIndex) => {
            const isExpanded = expandedIndexes.includes(sectionIndex);
            return (
                <Fragment key={sectionIndex}>
                    <tbody>
                        <tr
                            className="trigger-holder"
                        >
                            <td colSpan={columns.length}>
                                <button
                                    className="table__expand-trigger"
                                    onClick={() => clickExpandableRow(sectionIndex)}
                                >
                                    <i>
                                        <FontAwesomeIcon icon={isExpanded ? faAngleUp : faAngleDown}/>
                                    </i>
                                    {sectionTitle} {(expandableRowConfig?.showNumberOfItemsInExpandable) ? `(${sectionData.length})` : ''}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    {isExpanded && (
                        <tbody className='table__body is-expanded'>
                            {sortDataArray(filterDataArray(sectionData)).map((datum, rowIndex) => (
                                <tr key={rowIndex}>
                                    {columns.map(({ renderCell, textAlign, overrideTd }, colIndex) => (
                                        overrideTd
                                            ? <Fragment key={colIndex}>
                                                {renderCell(datum, rowIndex, colIndex)}
                                            </Fragment>
                                            : <td
                                                className={`align-${textAlign}`}
                                                key={colIndex}
                                                style={getAlignStyles(textAlign)}
                                            >
                                                {renderCell(datum, rowIndex, colIndex)}
                                            </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    )}
                </Fragment>
            )
        });
    }

    return (
        <div className={cx('KCL_table', tableSize, className, {
            'KCL_table--expandable' : isExpandableTable(data),
            'is-filterable': isFilterable
        })}>
            <div className='table__body'>
                <table>
                    {displayHead()}
                    {displayBody()}
                </table>
                {(isFilterable) && <div className='table-shadow' />}
            </div>
        </div>
    );
}

export default Table;
