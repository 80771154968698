// Package imports:
import React from 'react';
// Component imporst:
import Reikningar from '../../components/Reikningar/Reikningar';
import Entrypoint from '../Entrypoint';

const InvoicesPage: React.FC = () => {
    return (
        <Entrypoint>
            <Reikningar />
        </Entrypoint>
    );
}

export default InvoicesPage;