// Package imports:
import React from 'react';
import { NumberFormatProps } from 'react-number-format'
import { faRotateRight } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import SliderInput, { SliderInputProps } from './SliderInput';
import Input from '../Input/Input';
import Button from '../Button/Button';
// Service imports:
// Type imports:

interface IProps {
    sliderProps: SliderInputProps,
    inputProps: NumberFormatProps,
    onInput(): void,
    reset(): void,
    usePercentage?: boolean
}

const SliderVariableUnit: React.FC<IProps> = ({
    sliderProps,
    inputProps,
    onInput,
    reset,
    usePercentage
}) => {
    return (
        <div className='KCL_slider-variable-unit'>
            <SliderInput
                min={sliderProps.min}
                max={sliderProps.max}
                value={sliderProps.value}
                onChange={sliderProps.onChange}
                onInput={sliderProps.onInput}
                usePercentage={usePercentage}
            />
            <div className='input-and-button'>
                <div className='slider-input'>
                    <Input
                        className='slider-value'
                        inputType='numberFormat'
                        inputSize='lg'
                        textAlign='left'
                        autoComplete='off'
                        value={inputProps.value ?? undefined}
                        thousandSeparator='.'
                        decimalSeparator=','
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowLeadingZeros={false}
                        onValueChange={inputProps.onValueChange}
                        onInput={onInput}
                        allowNegative={false}
                        isAllowed={inputProps.isAllowed}
                    />
                    {usePercentage && <span className='percent-symbol'>%</span>}
                </div>
                <Button
                    size='lg'
                    buttonType='secondary'
                    icon={faRotateRight}
                    onClick={reset}
                />
            </div>
        </div>
    );
}

export default SliderVariableUnit;