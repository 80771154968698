// Package imports:
import React from 'react';
import cx from 'classnames';
// Service imports:
import { formatNumber } from '../../services/utils';

interface IProps {
    low: number | null,
    high: number | null,
    value: number | null,
    size?: 'sm' | 'lg'
}

const PriceRange: React.FC<IProps> = ({
    low,
    high,
    value,
    size = 'lg'
}) => {
    const formattedLowNumber = formatNumber(low, '-', 1);
    const formattedHighNumber = formatNumber(high, null, 1);
    const formattedValue = formatNumber(value, '-', 1) ?? undefined;

    // Null checks
    if (low === null || high === null || value === null) {
        // Returns grayed out price range.
        return (
            <div className={cx('KCL_price-range', size)}>
                <input
                    type="range"
                    min={0}
                    max={2}
                    value={1}
                    step="0.01"
                    style={{
                        background: `linear-gradient(to right, #BDBEC1 0%,#BDBEC1 50%, #BDBEC1 50%, #BDBEC1 100%)`
                    }}
                    readOnly
                    title={formattedValue}
                />
                <div className="end-values">
                    <span className="low-value">{'-'}</span>
                    <span className="high-value">{'-'}</span>
                </div>
            </div>
        )
    }

    let percent = (value-low)/(high-low)*100;
    
    // Equal check edge case.
    if (formattedLowNumber === formattedHighNumber) {
        // Just for the range input. Doesn't affect values visible to the user.
        low = 0;
        value = 1;
        high = 2;
        percent = 50;
    }
    
    return (
        <div className={cx('KCL_price-range', size)}>
            <input
                type="range"
                min={low}
                max={high}
                value={value}
                step="0.01"
                style={{
                    background: `linear-gradient(to right, #038600 0%, #038600 ${percent}%, #DB0033 ${percent}%, #DB0033 100%)`
                }}
                readOnly
                title={formattedValue}
            />
            <div className="end-values">
                <span className="low-value">{formattedLowNumber}</span>
                <span className="high-value">{formattedHighNumber}</span>
            </div>
        </div>
    );
}

export default PriceRange;