// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import PropertyProfile from '../../components/Property/PropertyProfile/PropertyProfile';
// Type imports:
import { IPropertyViewModel } from '../../types/PropertyTypes';

const Profile: React.FC<IPropertyViewModel> = ({
    propertyNumber,
    bondsAllowed
}) => {
    return (
        <Entrypoint>
            <PropertyProfile propertyNumber={propertyNumber} bondsAllowed={bondsAllowed} />
        </Entrypoint>
    );
}

export default Profile;