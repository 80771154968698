// Package imports:
import React from 'react';
import cx from 'classnames';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Table from '../../../ui-elements/Table/Table';
import Link from '../../../ui-elements/Link/Link';
import Alert from '../../../ui-elements/Alert/Alert';
import Button from '../../../ui-elements/Button/Button';
// Service imports:
import { useBuyingProcessUrl } from '../../../services/buyhook';
import { GET_KELDAN_API_URL } from '../../../services/config';
// Type imports:
import { IPropertyReport } from '../../../types/PropertyTypes';

interface IProps {
    id?: string,
    eventId?: string,
    propertyReport: IPropertyReport,
    isExample?: boolean
}

interface IBuyDocument {
    docnr: string,
    onr: string,
    fastanr: string
}

const AssetsReportBody: React.FC<IProps> = ({
    id,
    eventId,
    propertyReport,
    isExample
}) => {
    const displayProperties = (type: 'owned' | 'no longer owned') => {
        const filteredProperties = (type === 'owned')
            ? propertyReport.properties.filter(property => property.allowance !== null)
            : propertyReport.properties.filter(property => property.history !== null);

        if (filteredProperties.length === 0) {
            if (type === 'owned') {
                return (
                    <Alert
                        type='info'
                        headText='Engar fasteignir í eigu'
                    />
                )
            }
            return (
                <Alert
                    type='info'
                    headText='Engar fasteignir ekki lengur í eigu'
                />
            )
        }
        
        return filteredProperties.map(({
            name,
            description,
            allowance,
            history,
            registry
        }, index) => (
            <div
                className={cx('property-card', { 'first': (index === 0) })}
                key={registry.id}
            >
                <div className='title paragraph--bold'>
                    {name}
                </div>
                <div className='information paragraph-small'>
                    <div className='description'>
                        Lýsing:
                        <span className='paragraph-small--bold'>{description}</span>
                    </div>
                    <div className='registry-id'>
                        Fastanúmer:
                        <span className='paragraph-small--bold'>{registry.id}</span>
                    </div>
                </div>
                <Table
                    data={
                        [...((type === 'owned' ? allowance : history) ?? [])]
                        .sort((a, b) => {
                            const aParts = a.properDate.split('.');
                            const bParts = b.properDate.split('.');
                            const aDate = new Date(parseInt(aParts[2]), parseInt(aParts[1])-1, parseInt(aParts[0]));
                            const bDate = new Date(parseInt(bParts[2]), parseInt(bParts[1])-1, parseInt(bParts[0]));
                            return aDate.getTime() - bDate.getTime();
                        })
                    }
                    tableSize='lg'
                    columns={[{
                        title: 'Tegund skjals',
                        textAlign: 'left',
                        renderCell: ({ typeName, type }) => typeName ?? type
                    },{
                        title: 'Staða skjals',
                        textAlign: 'left',
                        renderCell: ({ statusName, status }) => statusName ?? status
                    },{
                        title: 'Númer skjals',
                        renderCell: ({ document }) => document
                    },{
                        title: 'Útgáfudagur',
                        renderCell: ({ properDate }) => properDate
                    },{
                        title: 'Skjal',
                        renderCell: ({ document, office }) => (
                            <Button
                                size='sm'
                                buttonType='buy'
                                showLoader={isItemLoading({ docnr: document, onr: office, fastanr: registry.id ?? '' })}
                                onClick={() => tryPurchaseItem({
                                    docnr: document,
                                    onr: office,
                                    fastanr: registry.id ?? ''
                                }, 'confirm-modal-with-reference')}
                                disabled={!propertyReport.documentsAllowed}
                            >
                                Kaupa
                            </Button>
                        )
                    }]}
                />
            </div>
        ));
    }

    const { tryPurchaseItem, isItemLoading, ModalsAndToasts } = useBuyingProcessUrl(
        (doc: IBuyDocument, modalReference: string) => {
            const url = `${GET_KELDAN_API_URL()}/Pdf/Thinglyst-Skjal-Kaupa?docnr=${doc.docnr}&onr=${doc.onr}&fastanr=${doc.fastanr}&reference=${modalReference}`;
            return url;
        },
        () => {},
        'fasteignaskrá'
    );

    return (
        <DisplayBox
            title='Fasteignir í eigu'
            className='KCL_ViewCompanyAssetsReport'
            asideComponent={
                <div className='report-aside-component'>
                    <div className='link-wrapper'>
                        <Link
                            targetBlank
                            linkSize='15'
                            url={`/Pdf/Properties?ssn=${id}&eventId=${eventId}`}
                            disabled={isExample}
                            icon='pdf'
                        >
                            Sækja skýrslu
                        </Link>
                    </div>
                    <div className='italic-text'>
                        Skýrsla keypt: {propertyReport.eventDate}
                    </div>
                </div>
            }
            grayBackground={isExample}
        >
            <div className='main'>
                {displayProperties('owned')}
                {propertyReport.showHistory &&
                    <>
                        <h4 className="middle-h4">Fasteignir ekki lengur í eigu</h4>
                        {displayProperties('no longer owned')}
                    </>
                }
                <ModalsAndToasts />
            </div>
        </DisplayBox>
    );
}

export default AssetsReportBody;