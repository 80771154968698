// Package imports:
import React, { useState } from "react";
// Component imports:
import Alert from "../../../ui-elements/Alert/Alert";
import LykilordForm from "./LykilordForm";
import Link from "../../../ui-elements/Link/Link";

const Lykilord: React.FC = () => {
    const [contactResponse, setContactResponse] = useState<JSX.Element>();

    return (
        <div className="Lykilord">
            <div>
                {(contactResponse) &&
                    (
                        <div className="form__row">
                            <div className="form__col">
                                {contactResponse}
                            </div>
                        </div>
                    )
                }
            </div>
            <div className="section__body">
                <div className="left-section no-border">
                    <h3>Endursetja lykilorð</h3>
                    <LykilordForm contactResponse={(response: JSX.Element) => setContactResponse(response) }/>
                    <p className="passwordDisclaimer paragraph-small">
                        Athugaðu að nýtt lykilorð á við um eftirfarandi kerfi Kóða ehf.
                    </p>
                    <div className='keldan__links'>
                        <Link linkSize='18' url="/" target="_blank">Keldan.is</Link>
                        <Link linkSize='18' url="/Keldan-Markadir" target="_blank">Keldan markaðir</Link>
                        <Link linkSize='18' url="https://kodiak.is/kodiak-pro.html" target="_blank">KODIAK Pro</Link>
                    </div>
                </div>
                <div className="right-section">
                    <Alert
                        type="info"
                        headText="Athugið"
                        text={<p>Lykilorð þarf að innihalda a.m.k. 12 stafi; einn tölustaf, einn hástaf, og einn lágstaf</p>}
                    />
                </div>
            </div>
        </div>
    )
}

export default Lykilord;