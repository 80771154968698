// Package imports:
import Bugsnag from '@bugsnag/js';
import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
// Component imports:
import Button from '../../../ui-elements/Button/Button';
import Checkbox from '../../../ui-elements/Input/Formik/Checkbox';
import Input from '../../../ui-elements/Input/Formik/Input';
import Link from '../../../ui-elements/Link/Link';
import Loading from '../../../ui-elements/Loading/Loading';
import Table from '../../../ui-elements/Table/Table';
import Tooltip from '../../../ui-elements/Tooltip/Tooltip';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import ExampleReportButton from '../../ExampleReportButton/ExampleReportButton';
import SearchResults from '../../../ui-elements/SearchResults/SearchResults';
// Service imports:
import { useBuyingProcess } from '../../../services/buyhook';
import { GET_KELDAN_API_URL } from '../../../services/config';
import { isSSN, stripSSN } from '../../../services/utils';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { IndividualSearch, IndividualSearchResult } from '../../../types/IndividualTypes';
import { IKeldanApiResponse } from '../../../types/KeldanTypes';
import { IisAuthenticated } from '../../../types/Types';

interface IPurchaseMembershipInformation {
    ssn: string,
    whereClick: 'form' | 'list'
}

const MembershipSearch: React.FC<IisAuthenticated> = ({
    isAuthenticated,
    isSubscriber = false
}) => {
    const [ searchResults, setSearchResults] = useState<IndividualSearchResult[]>();
    const [ searchTerm, setSearchTerm] = useState('');
    const searchResultsRef = useRef<HTMLDivElement | null>(null);

    const { tryPurchaseItem, tryToBuyItem, resetState, setToastError, ModalsAndToasts } = useBuyingProcess(async (purchaseMembershipInfo: IPurchaseMembershipInformation, modalReference, modalBool) => {
        const { ssn: rawSsn } = purchaseMembershipInfo;
        const ssn = stripSSN(rawSsn);
        try {
            if (!ssn || !isSSN(ssn)) {
                resetState({
                    type: 'alert',
                    headText: 'Ekki gild kennitala'
                });
                return;
            }
            // if submit:
            const requestQuery = new URLSearchParams({
                id: ssn ?? '',
                reference: modalReference,
                address: modalBool.toString()
            })
            const url = `${GET_KELDAN_API_URL()}/Individual/BuyMembershipOnly`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if(response.ok){
                if (response.redirected) {
                    window.location.href = response.url;
                    return;
                }
                const responseBody: IKeldanApiResponse = await response.json();
                const { message } = responseBody;
                if (message === ErrorMessages.NoCard) {
                    resetState('no card');
                }
                else if (message) {
                    resetState({
                        type: 'alert',
                        headText: message
                    });
                } else {
                    resetState('bad request');
                }
            } else {
                resetState('response not ok');
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            resetState('network error')
        }
    }, 'hlutafélagaskrá');

    const handleSearch = async (values: ISearchFormValues) => {
        const { name } = values;
        setSearchResults(undefined);
        setSearchTerm(name);
        try {
            const requestQuery = new URLSearchParams({
                term: name
            });
            const url = `${GET_KELDAN_API_URL()}/Individual/Search`;
            const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: requestQuery
            });
            if (!response.ok) {
                setToastError('response not ok');
                return;
            }
            if (response.redirected) {
                window.location.href = response.url;
            }
            const responseBody: IKeldanApiResponse<IndividualSearch> = await response.json();
            if (responseBody.success) {
                if (responseBody.result) {
                    setSearchResults(responseBody.result.list.list.list);
                    searchResultsRef.current?.scrollIntoView();
                }
                return;
            }
            const { message } = responseBody;
            if (message === ErrorMessages.NoCard) {
                setToastError('no card');
            } else if (message) {
                setToastError({
                    type: 'alert',
                    headText: message
                });
            } else {
                setToastError('bad request');
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setToastError('network error');
        }
    }

    const displayForms = (status: 'enabled' | 'disabled') => {
        const disabled = (status === 'disabled');
        return (
            <div>
                <Formik
                    initialValues={{
                        ssn: '',
                        reference: '',
                        withAddress: false,
                    }}
                    validationSchema={Yup.object({
                        ssn: Yup.string()
                            .required('Kennitala má ekki vera tóm')
                            .min(10, 'Kennitala þarf að innihalda 10 tölustafi')
                            .max(11, 'Kennitala má ekki vera meira en 10 tölustafir')
                            .matches(/^(\d{6}-\d{4})|^(\d+)$/, 'Kennitala má einungis innihalda tölustafi'),
                        reference: Yup.string()
                    })}
                    onSubmit={({ssn, reference, withAddress}) => tryPurchaseItem(
                        { ssn, whereClick: 'form' },
                        isSubscriber ? undefined : 'confirm-modal-with-reference-and-addresses',
                        reference,
                        withAddress
                    )}
                    component={(props: { values: IPurchasingFormValues }) => (
                        <PurchasingSubFormComponent
                            values={props.values}
                            loading={tryToBuyItem !== null && tryToBuyItem.whereClick === 'form'}
                            disabled={disabled}
                            isSubscriber={isSubscriber}
                        />
                    )}
                />
                <p className='paragraph'>Einnig er hægt að leita eftir nafni:</p>
                <Formik
                    initialValues={{
                        name: ''
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required('Nafn má ekki vera tómt')
                    })}
                    onSubmit={(values) => handleSearch(values)}
                    component={(props: { values: ISearchFormValues }) => (
                        <SearchSubFormComponent
                            values={props.values}
                            loading={(searchResults === undefined && searchTerm.length > 0)}
                            disabled={disabled}
                        />
                    )}
                />
            </div>
        )
    }

    const displaySearchResults = () => {
        if (searchResults === undefined) {
            return <Loading />;
        }
        if (searchResults.length === 0) {
            return <p>Engar niðurstöður fundust fyrir leitarorð: {searchTerm}</p>;
        }
        return (
            <Table
                tableSize='lg'
                data={searchResults}
                columns={[{
                    title: 'Nafn',
                    renderCell: ({ name }) => name,
                    textAlign: 'left',
                    simpleSortable: ({ name }) => name,
                }, {
                    title: 'Heimili',
                    renderCell: ({ address }) => address,
                    textAlign: 'left',
                    simpleSortable: ({ address }) => address
                }, {
                    title: '',
                    renderCell: ({ ssn }) => (
                        isSubscriber
                        ? <Button
                            size='sm'
                            buttonType='primary'
                            onClick={() => tryPurchaseItem({ ssn, whereClick: 'list' })}
                            showLoader={(
                                tryToBuyItem !== null
                                && ssn === tryToBuyItem.ssn
                                && tryToBuyItem.whereClick === 'list'
                            )}
                        >
                            Sækja
                        </Button>
                        : <Button
                            onClick={() => tryPurchaseItem({
                                ssn,
                                whereClick: 'list'
                            }, 'confirm-modal-with-reference-and-addresses')}
                            showLoader={(
                                tryToBuyItem !== null
                                && ssn === tryToBuyItem.ssn
                                && tryToBuyItem.whereClick === 'list'
                            )}
                            size='sm'
                            buttonType='buy'
                        >
                            Kaupa
                        </Button>
                    )
                }]}
            />
        );
    }

    return (
        <div className='KCL_membership-search'>
            <div className="shell">
                <h3>Aðild - Hlutafélagaþátttaka</h3>
                <p className='summary-paragraph'>Hér getur þú flett upp hlutafélagaþátttöku einstaklinga.</p>
                {!isSubscriber && (
                    <p className='paragraph--bold'>
                        Með því að slá inn kennitölu er um kaup að ræða. 
                        <Link style={{marginLeft: '5px'}} linkSize='18' url='/Verdskra#verdskra'>Sjá verðskrá.</Link>
                    </p>
                )}
                <div>
                    {(isAuthenticated)
                        ? displayForms('enabled')
                        : (
                            <Tooltip
                                tooltip={
                                    <span>
                                        Þú þarft að skrá þig inn og vera með skráð kreditkort til að nýta þessa þjónustu.
                                    </span>
                                }
                                text={displayForms('disabled')}
                            />
                        )
                    }
                    <ExampleReportButton size='lg' reportType='individual-membership' />
                </div>
            </div>
            <SearchResults
                title={`Leitarniðurstöður fyrir ${searchTerm}`}
                show={searchTerm.length > 0}
                closeResults={() => setSearchTerm('')}
                ref={searchResultsRef}
                component={
                    <DisplayBox>
                        {displaySearchResults()}
                    </DisplayBox>
                }
            />
            <ModalsAndToasts />
        </div>
    );
}

export default MembershipSearch;

interface IPurchasingFormValues {
    ssn: string,
    reference: string,
    withAddress: boolean,
}

interface IPurchasingSubFormProps {
    values: IPurchasingFormValues,
    loading: boolean
    disabled: boolean,
    isSubscriber: boolean
}

const PurchasingSubFormComponent: React.FC<IPurchasingSubFormProps> = ({
    values,
    loading,
    disabled,
    isSubscriber
}) => {
    return (
        <Form>
            <div className="form__body">
                <div className="form__section">
                    <div className="form__row">
                        <div className="form__col searchInput smallInput">
                            <Input
                                label='Kennitala'
                                name="ssn"
                                id="ssn"
                                value={values.ssn}
                                disabled={disabled}
                            />
                        </div>
                        {!isSubscriber && (
                            <div className="form__col searchInput">
                                <Input
                                    label='Tilvísun á reikning'
                                    name="reference"
                                    id="reference"
                                    value={values.reference}
                                    disabled={disabled}
                                />
                            </div>
                        )}
                        <div className="form__col actions">
                            <div className='form__actions'>
                                <Button
                                    buttonType={isSubscriber ? 'primary' : 'buy'}
                                    showLoader={loading}
                                    size="lg"
                                    disabled={disabled}
                                >
                                    {isSubscriber ? 'Sækja' : 'Kaupa'}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='form__row checkbox-row'>
                        <div className='form__col'>
                            <Checkbox 
                                label="Með heimilisfangi"
                                name="withAddress"
                                id="withAddress"
                                value={values.withAddress.toString()}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
}


interface ISearchFormValues {
    name: string,
}

interface ISearchSubFormProps {
    values: ISearchFormValues,
    loading: boolean
    disabled: boolean
}

const SearchSubFormComponent: React.FC<ISearchSubFormProps> = ({
    values,
    loading,
    disabled
}) => {
    return (
        <Form>
            <div className="form__body">
                <div className="form__section">
                    <div className="form__row nameSearchWrapper">
                        <div className="form__col searchInput smallInput">
                            <Input
                                label='Nafn'
                                name="name"
                                id="name"
                                value={values.name}
                                disabled={disabled}
                            />
                        </div>
                        <div className="form__col actions">
                            <div className='form__actions'>
                                <Button
                                    showLoader={loading}
                                    size="lg"
                                    disabled={disabled}
                                >
                                    Leita
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
}

