// Package imports:
import React, { useMemo } from 'react';
import cx from 'classnames';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../ui-elements/Link/Link';
import CalculatedColoredNumber from '../../ui-elements/CalculatedColoredNumber/CalculatedColoredNumber';
import DelayOrClosedLabel from '../DelayOrClosedLabel/DelayOrClosedLabel';
import DatePicker from '../../ui-elements/DatePicker/DatePicker';
import PriceRange from '../../ui-elements/PriceRange/PriceRange';
import LmdTable from '../../ui-elements/Table/LmdTable';
import LmdSingleLineTable from '../../ui-elements/Table/LmdSingleLineTable';
// Service imports:
import { formatNumber, displayQuantityShorthand, getAPItoday, getTableDate } from '../../services/utils';
import { useApiLmdDataMappedByString, useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IApiLmdSnapshot, IApiLmdStatistics, IApiLmdHistory1 } from '../../types/HlutabrefTypes';
import { ApiLmdData, IDefaultProps } from '../../types/Types';

interface IOwnProps {
    symbol?: string,
    exchange?: string,
    market?: string,
    showDatepicker?: boolean
}

interface ICombinedData {
    symbol: string | null,
    last_price: number | null,
    bid: number | null,
    ask: number | null,
    intraday_accumulated_turnover: number | null,
    intraday_no_of_trades: number | null,
    last_price_diff: number | null,
    weeks4_price_change_per?: number | null,
    year_price_change_per?: number | null,
    months12_price_change_per?: number | null,
    last_trade_dateutc?: string | null,
    months12_high_price?: number | null,
    months12_low_price?: number | null
}

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const SharesTable: React.FC<Props> = ({
    symbol,
    exchange = '*',
    market = '*',
    showDatepicker,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [data] = useApiLmdData<IApiLmdSnapshot[]>(
        (symbol
            ? `/v1/market_data/v1/exchanges/${exchange}/markets/${market}/equities/${symbol}/delayed_snapshot`
            : '/v1/market_data/v1/keldan/exchanges/[XICE;FNIS]/equities/delayed_snapshot'
        ),
        accessToken,
        refreshRateMs
    );
    const [currentDate, setCurrentDate, statistics] = useApiLmdDataMappedByString<IApiLmdStatistics[]>(
        getAPItoday(),
        (date: string) => (
            symbol
                ? `/v1/market_data/v1/exchanges/${exchange}/markets/${market}/tradables/${symbol}/statistics_delayed?trading_date=${date}`
                :`/v1/market_data/v1/keldan/exchanges/[XICE;FNIS]/statistics_delayed?security_type=share&trading_date=${date}`
        ),
        accessToken,
        refreshRateMs
    );
    const [, setHistoryDate, history] = useApiLmdDataMappedByString<IApiLmdHistory1[]>(
        getAPItoday(),
        (date: string) => `/v1/market_data/v1/keldan/exchanges/[XICE;FNIS]/closingprices?from_date=${date}&to_date=${date}`,
        accessToken
    )

    const isToday = useMemo(() => currentDate === getAPItoday(), [currentDate]);

    const displayData = useMemo(() => {
        const newData: ICombinedData[] = [];
        if (isToday) {
            if (data.data === null || data.error instanceof Error || statistics.data === undefined || statistics.error instanceof Error) {
                return newData;
            }
            for (let entry of data.data) {
                const statsEntry = statistics.data?.find(e => e.symbol === entry.symbol);
                newData.push({
                    symbol: entry.symbol,
                    last_price: entry.last_price,
                    bid: entry.bid,
                    ask: entry.ask,
                    intraday_accumulated_turnover: entry.intraday_accumulated_turnover,
                    intraday_no_of_trades: entry.intraday_no_of_trades,
                    last_price_diff: entry.intraday_per_change,
                    weeks4_price_change_per: statsEntry?.weeks4_price_change_per,
                    year_price_change_per: statsEntry?.year_price_change_per,
                    months12_price_change_per: statsEntry?.months12_price_change_per,
                    last_trade_dateutc: entry.last_trade_dateutc,
                    months12_high_price: statsEntry?.months12_high_price,
                    months12_low_price: statsEntry?.months12_low_price
                });
            }
        } else {
            const historyForDate = history.data?.filter(x => !x.MarketName?.includes('Bond'));
            if (historyForDate === undefined || historyForDate instanceof Error || historyForDate === null || statistics?.data === undefined || statistics.error instanceof Error) {
                return newData;
            }
            for (let entry of historyForDate) {
                const statsEntry = statistics.data?.find(e => e.symbol === entry.Symbol);
                newData.push({
                    symbol: entry.Symbol,
                    last_price: entry.Close,
                    bid: null,
                    ask: null,
                    intraday_accumulated_turnover: entry.Volume,
                    intraday_no_of_trades: entry.NumberOfTrades,
                    last_price_diff: null,
                    weeks4_price_change_per: statsEntry?.weeks4_price_change_per,
                    year_price_change_per: statsEntry?.year_price_change_per,
                    months12_price_change_per: statsEntry?.months12_price_change_per,
                    last_trade_dateutc: entry.TradingDate,
                    months12_high_price: statsEntry?.months12_high_price,
                    months12_low_price: statsEntry?.months12_low_price
                });
            }
        }
        return newData.sort((a, b) => {
            if (a.symbol === null && b.symbol === null) return 0;
            if (a.symbol === null) return -1;
            if (b.symbol === null) return 1;
            return a.symbol > b.symbol ? 1 : -1;
        });
    }, [data, statistics, history]);

    const apiData: ApiLmdData<ICombinedData[]> = {
        data: displayData,
        error: data.error ?? statistics.error ?? history.error ?? null
    }
    
    return (
        <DisplayBox
            className={cx('KCL_shares-table', {'hide-symbol': symbol})}
            title={symbol ?? "Hlutabréf"}
            statusComponent={<DelayOrClosedLabel />}
            asideComponent={showDatepicker
                ? <DatePicker
                    maxDate={new Date()}
                    size="lg"
                    selectedDate={new Date(currentDate)}
                    setSelectedDate={(newDate) => { setCurrentDate(getAPItoday(newDate)); setHistoryDate(getAPItoday(newDate)); }}
                />
                : undefined
            }
            {...props}
        >
            {symbol
                ? <LmdSingleLineTable
                    apiData={{ data: apiData.data?.[0] ?? null, error: apiData.error }}
                    columns={[{
                        title: 'Félag',
                        renderCell: ({symbol}) => <Link url={`/Markadir/Hlutabref/${symbol}`} linkSize='15'>{symbol}</Link>,
                        textAlign: 'left',
                    }, {
                        title: 'Verð',
                        renderCell: ({last_price}) => formatNumber(last_price),
                        textAlign: 'left',
                    }, {
                        title: 'Kaup',
                        renderCell: ({bid}) => formatNumber(bid),
                        textAlign: 'left',
                    }, {
                        title: 'Sala',
                        renderCell: ({ask}) => formatNumber(ask),
                        textAlign: 'left',
                    }, {
                        title: 'Velta',
                        renderCell: ({intraday_accumulated_turnover}) => displayQuantityShorthand(intraday_accumulated_turnover),
                        textAlign: 'left',
                    }, {
                        title: 'Fj. viðsk.',
                        renderCell: ({intraday_no_of_trades}) => intraday_no_of_trades,
                        textAlign: 'left',
                    }, {
                        title: 'Dagsbreyting',
                        renderCell: ({last_price_diff}) => (
                            <CalculatedColoredNumber
                                currentValue={last_price_diff}
                                formatting='percent'
                                hasArrow
                                hasColor
                            />
                        ),
                        textAlign: 'left',
                    }, {
                        title: '1 mán.',
                        renderCell: ({weeks4_price_change_per}) => (
                            <CalculatedColoredNumber
                                currentValue={weeks4_price_change_per ?? null}
                                formatting='percent'
                                hasArrow
                                hasColor
                            />
                        ),
                        textAlign: 'left',
                    }, {
                        title: 'Innan árs',
                        renderCell: ({year_price_change_per}) => (
                            <CalculatedColoredNumber
                                currentValue={year_price_change_per ?? null}
                                formatting='percent'
                                hasArrow
                                hasColor
                            />
                        ),
                        textAlign: 'left',
                    }, {
                        title: '1 ár',
                        renderCell: ({months12_price_change_per}) => (
                            <CalculatedColoredNumber
                                currentValue={months12_price_change_per ?? null}
                                formatting='percent'
                                hasArrow
                                hasColor
                            />
                        ),
                        textAlign: 'left',
                    }, {
                        title: 'Síð. viðsk.',
                        renderCell: ({last_trade_dateutc}) => getTableDate(last_trade_dateutc ?? null, 'HH:MM:SS', '.'),
                        textAlign: 'left'
                    }, {
                        title: 'Árs bil',
                        renderCell: ({months12_low_price, months12_high_price, last_price}) => (
                            <PriceRange low={months12_low_price ?? 0} high={months12_high_price ?? 0} value={last_price ?? 0} />
                        ),
                    }]}
                    tableSize="lg"
                />
                : <LmdTable
                    apiData={apiData}
                    columns={[{
                        title: 'Félag',
                        renderCell: ({symbol}) => <Link url={`/Markadir/Hlutabref/${symbol}`} linkSize='15'>{symbol}</Link>,
                        textAlign: 'left',
                        simpleSortable: ({symbol}) => symbol
                    }, {
                        title: 'Verð',
                        renderCell: ({last_price}) => formatNumber(last_price),
                        textAlign: 'right',
                        simpleSortable: ({last_price}) => last_price
                    }, {
                        title: 'Kaup',
                        renderCell: ({bid}) => formatNumber(bid),
                        textAlign: 'right',
                        simpleSortable: ({bid}) => bid
                    }, {
                        title: 'Sala',
                        renderCell: ({ask}) => formatNumber(ask),
                        textAlign: 'right',
                        simpleSortable: ({ask}) => ask
                    }, {
                        title: 'Velta',
                        renderCell: ({intraday_accumulated_turnover}) => displayQuantityShorthand(intraday_accumulated_turnover),
                        textAlign: 'right',
                        simpleSortable: ({intraday_accumulated_turnover}) => intraday_accumulated_turnover
                    }, {
                        title: 'Fj. viðsk.',
                        renderCell: ({intraday_no_of_trades}) => intraday_no_of_trades,
                        textAlign: 'right',
                        simpleSortable: ({intraday_no_of_trades}) => intraday_no_of_trades
                    }, {
                        title: 'Dagsbreyting',
                        renderCell: ({last_price_diff}) => (
                            <CalculatedColoredNumber
                                currentValue={last_price_diff}
                                formatting='percent'
                                hasArrow
                                hasColor
                            />
                        ),
                        textAlign: 'right',
                        simpleSortable: ({last_price_diff}) => last_price_diff
                    }, {
                        title: '1 mán.',
                        renderCell: ({weeks4_price_change_per}) => (
                            <CalculatedColoredNumber
                                currentValue={weeks4_price_change_per ?? null}
                                formatting='percent'
                                hasArrow
                                hasColor
                            />
                        ),
                        textAlign: 'right',
                        simpleSortable: ({weeks4_price_change_per}) => weeks4_price_change_per ?? null
                    }, {
                        title: 'Innan árs',
                        renderCell: ({year_price_change_per}) => (
                            <CalculatedColoredNumber
                                currentValue={year_price_change_per ?? null}
                                formatting='percent'
                                hasArrow
                                hasColor
                            />
                        ),
                        textAlign: 'right',
                        simpleSortable: ({year_price_change_per}) => year_price_change_per ?? null
                    }, {
                        title: '1 ár',
                        renderCell: ({months12_price_change_per}) => (
                            <CalculatedColoredNumber
                                currentValue={months12_price_change_per ?? null}
                                formatting='percent'
                                hasArrow
                                hasColor
                            />
                        ),
                        textAlign: 'right',
                        simpleSortable: ({months12_price_change_per}) => months12_price_change_per ?? null
                    }, {
                        title: 'Síð. viðsk.',
                        renderCell: ({last_trade_dateutc}) => getTableDate(last_trade_dateutc ?? null, 'HH:MM:SS', '.'),
                        textAlign: 'right'
                    }, {
                        title: 'Árs bil',
                        renderCell: ({months12_low_price, months12_high_price, last_price}) => (
                            <PriceRange low={months12_low_price ?? 0} high={months12_high_price ?? 0} value={last_price ?? 0} />
                        ),
                        simpleSortable: ({months12_low_price, last_price, months12_high_price}) => {
                            let yearRange = (months12_high_price ?? 0) - (months12_low_price ?? 0);
                            if (yearRange === 0) yearRange = 1;
                            return ((last_price ?? 0) - (months12_low_price ?? 0)) / yearRange;
                        }
                    }]}
                    tableSize="lg"
                />
            }
        </DisplayBox>
    );
}

export default SharesTable;