// Package imports:
import React from 'react';
// Component imports:
import Icon from '../CustomIcon/CustomIcon';

interface IOwnProps {
    onClick?: () => void;
    isOpen?: boolean;
}

const MobileHamburgerMenu: React.FC<IOwnProps> = ({
    onClick,
    isOpen = false
}) => {
    return (
        <div
            className='KCL_mobile-hamburger-menu'
            onClick={onClick}
        >
            {isOpen
                ? <Icon type='close' />
                : <span className='hamburger-icon'>
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
            }
        </div>
  );
}

export default MobileHamburgerMenu;
