// Package imports:
import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
// Component imports:
import ErrorAlert from '../../ErrorAlert/ErrorAlert';
import Loading from '../../../ui-elements/Loading/Loading';
// Service imports:
import { convertToPercentage } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { IApiLmdBond, IApiLmdSnapshot } from '../../../types/SkuldabrefTypes';
import { IDefaultProps } from '../../../types/Types';

interface IChartDatum {
    name: string,
    x: number | undefined
    y: number | undefined
}
interface IChartDataInfo {
    name: string,
    data: IChartDatum[]
}

interface IOwnProps {
    indexed: boolean
}

type Props = IOwnProps & IDefaultProps;

const YieldCurve: React.FC<Props>= ({
    indexed,
    accessToken,
    refreshRateMs
}) => {
    const [ bonds ] = useApiLmdData<IApiLmdBond[]>(
        `/v1/static_data/v1/exchanges/XICE/bonds/${indexed ? 'RIKS*' : 'RIKB*'}/`,
        accessToken,
        refreshRateMs
    );

    const [ snapshots ] = useApiLmdData<IApiLmdSnapshot[]>(
        `/v1/market_data/v1/keldan/exchange/IS/market/*/symbol/${indexed ? 'RIKS*' : 'RIKB*'}/type/bonds_bills/delayed_bonds`,
        accessToken,
        refreshRateMs
    );

    // Div references.
    const divRef = useRef<HTMLDivElement>(null);

    const getChartData = (bondsData: IApiLmdBond[], snapshotsData: IApiLmdSnapshot[]) => {
        const chartDataInfo: IChartDataInfo = {
            name: indexed ? 'Verðtryggður' : 'Óverðtryggður',
            data: []
        }
        for (let bond of bondsData) {
            if (bond.UpdateCode !== 'Deleted') {
                chartDataInfo.data.push({
                    name: bond.Symbol || 'Symbol Not Found',
                    x: (bond.MaturityDate !== null) ? Date.parse(bond.MaturityDate.replace(/ /g, "T")) : undefined,
                    y: undefined
                })
            }
        }
        for (let datum of snapshotsData) {
            for (let chartDatum of chartDataInfo.data) {
                if (chartDatum.name === datum.symbol) {
                    chartDatum.y = datum.last_yield ?? undefined;
                }
            }
        }
        return chartDataInfo;
    }
    // Chart render.
    useEffect(() => {
        if (divRef.current === null) return;

        const { data: bondsData } = bonds;
        const { data: snapshotsData } = snapshots;

        if (snapshotsData === null || bondsData === null) return;

        const chartDataInfo = getChartData(bondsData, snapshotsData);
        
        Highcharts.chart(divRef.current, {
            chart: {
                animation: false,
                height: 300,
                reflow: true,
                style: {
                    fontFamily: "Roboto"
                },
                plotBackgroundColor: {
                    linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                    stops: [
                        [0.05, '#FFF'],
                        [0.95, '#F8F8FA']
                    ]
                }
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            title: {
                text: '',
                align: 'center',
                x: 60,
                margin: -14
            },
            navigator: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            tooltip: {
                enabled: true,
                formatter: function() {
                    return `<b>${this.point.name}</b> ${new Date(this.x).getUTCFullYear()} Krafa : ${convertToPercentage(this.y, true, 2)}`;
                },
                borderWidth: 0,
                shadow: false
            },
            yAxis: {
                title: {
                    text: 'Krafa'
                },
                labels: {
                    formatter: function() {
                        return convertToPercentage(this.value as number, true, 2) ?? this.value.toString();
                    }
                },
                gridLineDashStyle: 'Dash',
                gridLineWidth: 1,
                gridLineColor: '#D3D3D6'
            },
            xAxis: [{
                type: 'datetime',
                title: {
                    text: null
                },
                tickWidth: 0
            }],
            plotOptions: {
                series: {
                    color: '#4569EE',
                    animation: false
                }
            },
            series: [{ type: 'line', ...chartDataInfo }]
        }, () => {});
    }, [ bonds, snapshots, divRef.current ]);

    if (bonds.data === null || snapshots.data === null) {
        // Error case.
        if (bonds.error !== null || snapshots.error !== null) {
            return (
                <ErrorAlert error={bonds.error ?? snapshots.error ?? new Error(ErrorMessages.RequestFailed)} />
            )
        }
        // Loading case.
        return <Loading />;
    }

    return (
        <div className='KCL_yield-curve KCL_chart'>
            <div ref={divRef}></div>
        </div>
    );
}

export default YieldCurve;