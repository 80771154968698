// Package imports:
import React, { useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import AnimateHeight from 'react-animate-height';


interface IAccordionItem {
    id: string,
    title?: JSX.Element,
    content?: JSX.Element
}

interface IProps {
    items?: IAccordionItem[],
    openId?: string
}

const GrayAccordion: React.FC<IProps> = ({
    items,
    openId
}) => {
    const [activeId, setActiveId] = useState(openId ?? null);

    return (
        <div className="KCL_gray-accordion">
            {items && items.map(item => (
                <div
                    key={item.id}
                    className={cx('accordion__section', {'is-active': activeId === item.id})}
                >
                    <button type="button" className="accordion__trigger" onClick={() => setActiveId(activeId === item.id ? null : item.id)}>
                        <div className="accordion__head">
                            <span className='section-title'>{item.title}</span>
                            <FontAwesomeIcon icon={activeId === item.id ? faAngleUp : faAngleDown} className="accordion__icon icon-fa" />
                        </div>
                        <AnimateHeight
                            duration={300}
                            height={activeId === item.id ? 'auto' : 0}
                        >
                            <div className="accordion__body">
                                <div className="accordion__content">
                                    {item.content}
                                </div>
                            </div>
                        </AnimateHeight>
                    </button>
                </div>
            ))}
        </div>
    )
}

export default GrayAccordion;