// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import VehicleProfilePage from '../../components/Okutaeki/ViewVehicleProfile';
// Type imports:
import { VehicleViewModel } from '../../types/VehicleTypes';

const ViewVehicleProfilePage: React.FC = ({...props}) => {
    return (
        <Entrypoint>
            <VehicleProfilePage model={props as VehicleViewModel}/>
        </Entrypoint>
    );
}

export default ViewVehicleProfilePage;