// Package imports:
import React, { useMemo } from 'react';
import { SeriesOptionsType } from 'highcharts/highstock';
import ISLocale from 'date-fns/locale/is';
import { format } from 'date-fns';
// Component imports:
import DefaultChart from '../DefaultChart/DefaultChart';
// Service imports:
import { getAPItoday, formatNumber } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IChartDataInfo, IDefaultProps } from '../../../types/Types';
import { IApiHistoryTimeseriesShort, IApiHistoryTimeseriesShortDATA } from '../../../types/MarketTypes';

interface IOwnProps {
    symbol?: string,
    height?: number
};

type Props = IOwnProps & IDefaultProps;

const VolumeByPriceChart: React.FC<Props> = ({
    symbol,
    height = 500,
    accessToken,
    refreshRateMs
}) => {
    const today = new Date();
    const from = new Date(today.getFullYear() - 5, today.getMonth() + 1, today.getDate());
    const [apiData] = useApiLmdData<IApiHistoryTimeseriesShortDATA>(
        `/v1/market_data/v1/tradables/${symbol}/history_timeseries_short/?from_date=${getAPItoday(from)}&to_date=${getAPItoday(today)}}`,
        accessToken,
        refreshRateMs
    );
    
    const handleData = () => {
        if (apiData.data === null || apiData.error) return null;
        let dataToReturn: IApiHistoryTimeseriesShort[] = [];
        for (let datum of apiData.data.data) {
            dataToReturn.push({
                trading_date: datum[0],
                open: datum[1],
                open_yield: datum[2],
                high: datum[3],
                high_yield: datum[4],
                low: datum[5],
                low_yield: datum[6],
                close: datum[7],
                close_yield: datum[8],
                volume: datum[9],
                open_corrected: datum[10],
                high_corrected: datum[11],
                low_corrected: datum[12],
                close_corrected: datum[13],
                volume_corrected: datum[14],
            })
        }
        return dataToReturn;
    };

    const { series, name }: { series: SeriesOptionsType[], name: string } = useMemo(() => {
        const chartDataInfo: IChartDataInfo = {
            name: '',
            data: []
        }

        const chartDataVolumeInfo: IChartDataInfo = {
            name: '',
            data: []
        }

        const data = handleData();
        if (data === null) return { series: [], name: '' };
        
        chartDataInfo.name = symbol ?? '';
        chartDataVolumeInfo.name = symbol ?? '';

        for (let datum of data) {
            if (datum.trading_date === null) continue;
            chartDataInfo.data.push({
                x: datum.trading_date,
                y: datum.close_corrected ?? datum.close ?? undefined
            });
            chartDataVolumeInfo.data.push({
                x: datum.trading_date,
                y: datum.volume ?? undefined
            });
        }

        return {
            series: [{
                type: 'line',
                id: 'AAPL',
                name: chartDataInfo.name,
                data: chartDataInfo.data,
                tooltip: {
                    valueDecimals: 2
                },
                yAxis: 0
            }, {
                type: 'column',
                id: 'volume',
                name: 'Magn',
                data: chartDataVolumeInfo.data,
                yAxis: 1,
                color: '#393A45',
                tooltip: {
                    valueDecimals: 2
                }
            }],
            name: chartDataInfo.name
        };
    }, [apiData]);
    return (
        <DefaultChart
            height={height}
            tooltipFormatter={
                function() {
                    return `
                        <div class="chart-tooltip">
                            ${this.points?.reduce(function (s, point) {
                                if (point.series.name === 'Magn') {
                                    return `${s}<p class="tooltip-volume"><span style="color: ${point.color}">Magn</span>: ${formatNumber(point.y, null, 0)}</p>`;
                                } else {
                                    return `${s}<p class="tooltip-value"><span style="color: ${point.color}">${name}</span>: ${formatNumber(point.y, null, 3)}</p>`
                                }
                            }, `<p class="tooltip-date">${format(this.x, 'EEEE, dd. MMM yyyy', {locale: ISLocale})}</p>`)}
                        </div>`;
                }
            }
            yAxes={[{
                title: {
                    text: 'Verð',
                    offset: 52,
                    x: 5
                },
                height: '70%',
                labels: {
                    x: -11,
                    y: 2
                }
            }, {
                title: {
                    text: 'Magn',
                    offset: 52,
                    x: 5
                },
                top: '76%',
                height: '24%',
                offset: 0,
                labels: {
                    x: -11,
                    y: 2
                }
            }]}
            series={series}
        />
    );
}

export default VolumeByPriceChart;