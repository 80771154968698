// Package imports:
import React from "react";
// Component imports:
import VedbondForm from './VedbondForm';
import Link from '../../../ui-elements/Link/Link';
//Type imports:
import { MySettingsViewModel } from "../../../types/MyKeldaTypes";

interface IProps {
    model?: MySettingsViewModel
}
const Vedbond: React.FC<IProps> = ({
    model,
}) => {
    return (
        <div className="Vedbond">
            <div className="section__body">
                <h3>Veðbönd</h3>
                <p className="paragraph">Þegar óskað er eftir aðgangi að þinglýsingarbókum (veðböndum) þarf að gefa skýringu á hvers vegna og staðfesta að aðgangur sé nauðsynlegur vegna starfsemi sinnar.</p>
                <p className="paragraph">Keldan áskilur sér þann rétt að sannreyna þær skýringar sem gefnar eru upp og veita öðrum upplýsingar um þær eftir því sem lög og reglur mæla fyrir um.</p>
                <Link
                    url="/Skilmalar"
                    linkSize="16"
                    icon='forward'
                >
                    Nánar um veðbönd í skilmálum Keldunnar
                </Link>
                <VedbondForm model={model} />
            </div>
        </div>
    )
}

export default Vedbond;