// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import VehiclePage from '../../components/Okutaeki/Okutaeki';

interface IProps {
    isAuthenticated: boolean,
    bondsAllowed: boolean,
    individualAssetsAllowed: boolean
}

const Okutaekjaskra: React.FC<IProps> = ({
    isAuthenticated,
    bondsAllowed,
    individualAssetsAllowed
}) => {
    return (
        <Entrypoint>
            <VehiclePage
                isAuthenticated={isAuthenticated}
                bondsAllowed={bondsAllowed}
                individualAssetsAllowed={individualAssetsAllowed}
            />
        </Entrypoint>
    );
}

export default Okutaekjaskra;