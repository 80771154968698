// Package imports:
import ContentLoader from "react-content-loader"


const ChartLoading = (props: any) => (
    <ContentLoader 
        speed={2}
        width="100%"
        height={110}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        className="KCL_chart-loading"
        {...props}
    >
        <rect x="0" y="0" rx="8" ry="8" width="100%" height="110" />
    </ContentLoader>
)

export default ChartLoading;