// Package imports:
import React from 'react';
// Component imports:
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import HafaSambandContactForm from '../ContactForms/HafaSambandContactForm/HafaSambandContactForm';
// Type imports:
import { ContactTopic } from '../../types/Types';

const HafaSamband: React.FC<{ topic: ContactTopic }> = ({
    topic
}) => {
    return (
        <div className="KCL_hafa-samband">
            <div className="main__inner">
                <div className="mini_shell">
                    <HeadSection
                        title="Hafa samband"
                    />
                    <div className="section__body">
                        <div className="section__body-inner">
                            <div className="section__content form form--contact">
                                <HafaSambandContactForm selectedTopic={topic} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HafaSamband;
