// Package imports:
import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Form, FormikErrors } from 'formik';
import * as Yup from 'yup';
import Bugsnag from '@bugsnag/js';
// Component imports:
import Input from '../../../ui-elements/Input/Formik/Input';
import Button from '../../../ui-elements/Button/Button';
import Checkbox from "../../../ui-elements/Input/Formik/Checkbox";
// Service imports:
import { isCompany } from '../../../services/utils';
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Watch } from '../../../types/MyKeldaTypes';

interface IFormValues {
    arsskyrslur: boolean,
    hlutafelog: boolean,
    logbirtingar: boolean,
    term: string,
    types?: boolean
}

interface IOwnProps {
    setData(a: { [key: string]: Watch[]; }): void,
    disabled?: boolean
}


const FyrirtaekjaVaktinForm: React.FC<IOwnProps> = ({
    setData,
    disabled = false
}) => {
    const [ loading, setLoading ] = useState(false);

    const handleSubmit = async (values: IFormValues) => {
        setLoading(true);
        try {
            const requestQuery = new URLSearchParams({
                term: values.term
            });
            let types = [];
            if (values.arsskyrslur) types.push('ARS');
            if (values.hlutafelog) types.push('HLT');
            if (values.logbirtingar) types.push('LOG');
            requestQuery.append('types', types.join(';'));
            
            const url = `${GET_KELDAN_API_URL()}/MyKelda/AddWatch`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (response.ok) {
                setLoading(false);
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const responseBody : { [key: string]: Watch[]; } & {message: string} = await response.json();
                    if (responseBody) {
                        setData(responseBody);
                    } else {
                        throw new Error(ErrorMessages.RequestFailed)
                    }
                }
            } else {
                setLoading(false);
                throw new Error(ErrorMessages.NetworkError)
            }
        } catch (e) {
            setLoading(false);
            if (e instanceof Error) Bugsnag.notify(e)
        }
    }

    const subFormComponent = useMemo(() => {
        return ((props: { values: IFormValues, errors: FormikErrors<IFormValues> }) => (
            <SubFormComponent
                values={props.values}
                errors={props.errors}
                loading={loading}
                disabled={disabled}
            />
        ))
    }, [loading]);

    return (
        <div className='KCL_watch-form'>
            <Formik
                initialValues={{
                    arsskyrslur: false,
                    hlutafelog: false,
                    logbirtingar: false,
                    term: '',
                    types: undefined
                }}
                validationSchema={Yup.object({
                    arsskyrslur: Yup.boolean(),
                    hlutafelog: Yup.boolean(),
                    logbirtingar: Yup.boolean(),
                    term: Yup.string()
                        .required('Vinsamlegast sláðu inn kennitölu fyrirtækis')
                        .test('ssn-test', 'Vinsamlegast sláðu inn kennitölu fyrirtækis', (value) => isCompany(value)),
                    types: Yup.boolean()
                        .required()
                        .oneOf([true], 'Vantar skýrslutegundir, s.s. Lögbirtingar, Hlutafélög o.s.frv.')
                })}
                onSubmit={(values, { resetForm }) => {
                    handleSubmit(values);
                    resetForm();
                }}
                component={subFormComponent}
            />
        </div>
    );
}

export default FyrirtaekjaVaktinForm;

interface ISubFormProps {
    values: IFormValues,
    errors: FormikErrors<IFormValues>,
    loading: boolean,
    disabled: boolean
}

const SubFormComponent: React.FC<ISubFormProps> = ({
    values,
    errors,
    loading,
    disabled
}) => {
    const validTerm = useMemo(() => isCompany(values.term), [values.term]);
    useEffect(() => {
        values.types = !validTerm || values.arsskyrslur || values.hlutafelog || values.logbirtingar;
    }, [validTerm, values.arsskyrslur, values.hlutafelog, values.logbirtingar])
    return (
        <Form>
            <div className="form__body">
                <div className="form__section">
                    <Input
                        placeholder='Sláðu inn kennitölu fyrirtækis'
                        name="term"
                        value={values.term}
                        disabled={disabled}
                    />
                    <div className='checkboxes'>
                        <Checkbox 
                            label="Ársreikningar"
                            name="arsskyrslur"
                            id="arsskyrslur"
                            value={values.arsskyrslur.toString()}
                            disabled={!validTerm}
                        />
                        <Checkbox 
                            className='middle-checkbox'
                            label="Hlutafélög"
                            name="hlutafelog"
                            id="hlutafelog"
                            value={values.hlutafelog.toString()}
                            disabled={!validTerm}
                        />
                        <Checkbox 
                            label="Lögbirtingar"
                            name="logbirtingar"
                            id="logbirtingar"
                            value={values.logbirtingar.toString()}
                            disabled={!validTerm}
                        />
                    </div>
                </div>
                <span className='checkBox-alert-msg'>{!values.types && errors.types}</span>
                <div className="form__actions">
                    <Button 
                        showLoader={loading}
                        disabled={!validTerm || !values.types} 
                        type='submit'
                        buttonType="primary"
                        size="lg"
                    >
                        Skrá vöktun
                    </Button>
                </div>
            </div>
        </Form>
    );
}
