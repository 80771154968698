// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import LoginComp from '../../components/Login/Login';

interface IOwnProps {
    username?: string | null;
    password?: string | null;
}

const Login: React.FC<IOwnProps> = ({
    username = null,
    password = null
}) => {
    const urlParams = new URLSearchParams(window.location.search);
    const props = {
        username,
        password,
        redirectUrl: urlParams.get('ReturnUrl')
    }

    return (
        <Entrypoint>
            <LoginComp {...props} />
        </Entrypoint>
    );
}

export default Login;