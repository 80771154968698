// Package imports:
import React from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import LmdTable from '../../ui-elements/Table/LmdTable';
// Service imports:
import { formatNumber, getTableDate, getTimeFromDate, isDateToday } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
// Types imports:
import { IApiTrade } from '../../types/MarketTypes';
import { IDefaultProps } from '../../types/Types';

type Props = IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const DetailsLatestTrades: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [latestTrades] = useApiLmdData<IApiTrade[]>(
        '/v1/market_data/v1/kodix/all_latest_trades?limit=5',
        accessToken,
        refreshRateMs
    );

    return (
        <div className="KCL_detailsLatestTrades">
            <DisplayBox
                title="Nýjustu viðskipti"
                {...props}
            >
                <LmdTable 
                    apiData={latestTrades} 
                    columns={[{
                        title: 'Auðkenni',
                        renderCell: ({Symbol}) => Symbol?.replace(':KELD', ''),
                        textAlign: 'left',
                    }, {
                        title: 'Tími',
                        renderCell: ({TimeExecuted}) => (isDateToday(new Date(TimeExecuted))
                                                          ? (getTimeFromDate(TimeExecuted.toString(), 'HH:MM:SS'))
                                                          : (getTableDate(TimeExecuted.toString(),"DD/MM/YYYY", "."))),
                        textAlign: 'left',
                    }, {
                        title: 'Verð',
                        renderCell: ({Price}) => formatNumber(Price),
                    }, {
                        title: 'Mynt',
                        renderCell: ({Volume}) => formatNumber(Volume, '-', 0),
                    }, {
                        title: 'ISK',
                        renderCell: ({Price, Volume}) => formatNumber(Price * Volume),
                    },]}
                    tableSize='lg'
                />
            </DisplayBox>
        </div>
    )
}

export default DetailsLatestTrades;