// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import CompanySubPage from '../../components/Company/CompanySubPage/CompanySubPage';
import CompanyStatements from '../../components/Company/CompanyStatements/CompanyStatements';
// Type imports:
import { ICompanyViewModel } from '../../types/CompanyTypes';

const CompanyStatementsPage: React.FC<ICompanyViewModel> = ({
    id,
    isAuthenticated,
    isSubscriber
}) => {
    return (
        <Entrypoint>
            <CompanySubPage 
                id={id} 
                title='Ársreikningaskrá'
                isAuthenticated={isAuthenticated}
                isSubscriber={isSubscriber}
                component={
                    <CompanyStatements isAuthenticated={isAuthenticated} large id={id} />
                } 
            />
        </Entrypoint>
    );
}

export default CompanyStatementsPage;