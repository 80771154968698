// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import PricingPageComp from '../../components/PricingPage/PricingPage';

const PricingPage: React.FC = () => {
    return (
        <Entrypoint>
            <PricingPageComp />
        </Entrypoint>
    );
}

export default PricingPage;