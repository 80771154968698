// Package imports:
import React, { useContext, useEffect, useState } from 'react'
import cx from 'classnames';
import { 
    format, 
    startOfWeek,
    endOfWeek,
    startOfMonth,
    endOfMonth,
    addDays,
    isSameMonth,
    isSameDay,
    parseISO
} from "date-fns";
// Component imports:
import CalendarHeader from './CalendarHeader';
import CalendarItem from './CalendarItem';
import CalendarWeekdays from './CalendarWeekdays';
import AdRotator from '../Ad/AdRotator';
// Service imports:
import { GET_API_LMD_URL } from '../../services/config';
import { getAPItoday } from '../../services/utils';
import { ErrorMessages } from '../../services/errorMessages';
// Type imports:
import { Fetched, IDefaultProps, IKeldanCalendarEvent } from '../../types/Types';
// Context imports:
import { AccessTokenContext } from '../../contexts/AccessTokenContext';

const DAY_FORMAT = 'd';

const EventsCalendar: React.FC = () => {

    const [currentDate, setCurrentDate ] = useState(new Date());
    const [events, setEvents] = useState<Fetched<IKeldanCalendarEvent[]>>(null);
    
    const accessToken = useContext(AccessTokenContext);
    const [ defaultProps, setDefaultProps ] = useState<IDefaultProps>({
        accessToken,
        refreshRateMs: 60*60*1000
    });

    useEffect(() => {
        setDefaultProps({
            ...defaultProps,
            accessToken
        });
    }, [accessToken]);

    useEffect(() => {
        fetchEvents();
    }, [currentDate, accessToken]);

    const fetchEvents = async () => {
        try {
            const url = `${GET_API_LMD_URL()}/v1/market_data/v1/keldan/calendar/events?start_date=${getAPItoday(startOfMonth(currentDate))}&start=0&limit=200`;
            const headers = { Authorization: `Bearer ${accessToken}` };
            const response = await fetch(url, { headers });
            if (response.ok) {
                const body = await response.json();
                setEvents(body);
            } else {
                setEvents(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setEvents(new Error(ErrorMessages.NetworkError));
        }
    };

    const getEventsForDay = (day: Date) => {
        if (events === null || events instanceof Error) return [];
        return events.filter(event => event.startdate_utc !== null && isSameDay(day, parseISO(event.startdate_utc)));
    }

    const renderCalendarCells = () => {
        const monthStart = startOfMonth(currentDate);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
        const rows = [];
    
        let days = [];
        let day = startDate;
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                const formattedDate = format(day, DAY_FORMAT);
                const dayEvents = getEventsForDay(day);
                days.push(
                    <div
                        key={day.toString()}
                        className={cx(
                            'calendar-col calendar-cell', 
                            {
                                'disabled': !isSameMonth(day, monthStart),
                                'selected': isSameDay(day, new Date()),
                                'has-events': dayEvents.length > 0
                            }
                        )}
                    >
                        <h4 className="cell__date">{formattedDate}</h4>

                        {dayEvents.length > 0 && (
                            <ul className="list-events">
                                {dayEvents.map((dayEvent) => (
                                    <CalendarItem 
                                        key={dayEvent.id}
                                        url={dayEvent.url}
                                        title={dayEvent.title}
                                        time={dayEvent.startdate_utc}
                                        allDay={dayEvent.allday}
                                        source={dayEvent.calendar_title}
                                    />
                                ))}
                            </ul>
                        )}
                    </div>
                );

                day = addDays(day, 1);
            }

            rows.push(
                <div className="calendar-row" key={day.toString()}>
                    {days}
                </div>
            );
            days = [];
        }
        
        return <div className="events-calendar__body">
            <CalendarWeekdays />
            {rows}
            </div>;
    }

    return (
        <div className="main KCL_calendar-page">
            <div className="main__inner">
                {window.matchMedia('(max-width: 768px)').matches 
                    ? <AdRotator location="Head400x80" />
                    : <AdRotator location="Head1360x180" />
                }
                <div className="shell">
                    <CalendarHeader 
                        currentDate={currentDate}
                        onDateChange={date => setCurrentDate(date)}
                    />
                    {renderCalendarCells()}
                </div>
            </div>
        </div>
    )
}

export default EventsCalendar