// Package imports:
import React, { useMemo } from "react";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Bugsnag from "@bugsnag/js";
// Component imports:
import Alert from "../../../ui-elements/Alert/Alert";
import Table from "../../../ui-elements/Table/Table";
import FyrirtaekjaVaktinForm from "../../Watch/FyrirtaekjaVaktin/FyrirtaekjaVaktinForm"
import Button from "../../../ui-elements/Button/Button";
import DropSelect from "../../../ui-elements/DropSelect/DropSelect";
import Link from "../../../ui-elements/Link/Link";
import Checkbox from "../../../ui-elements/Input/Checkbox";
import Tooltip from "../../../ui-elements/Tooltip/Tooltip";
// Service imports:
import { insertDash, isCompany } from "../../../services/utils";
import { GET_KELDAN_API_URL } from '../../../services/config';
import { usePageCounter } from '../../../services/hooks';
import { ErrorMessages } from "../../../services/errorMessages";
import SEO from "../../../services/SEO";
// Type imports:
import { Watch } from "../../../types/MyKeldaTypes";


interface IProps {
    watches?: { [key: string]: Watch[]; } | null,
    setWatches(a: { [key: string]: Watch[]; }): void,
    isSubscriber?: boolean
}

interface IWatchItem {
    name: string | null,
    term: string,
    ars?: {
        id: number,
        active: boolean
    },
    hlt?: {
        id: number,
        active: boolean
    },
    log?: {
        id: number,
        active: boolean
    }
}

const FyrirtaekjaVaktin: React.FC<IProps> = ({
    watches,
    setWatches,
    isSubscriber = false
}) => {
    const {
        itemsPerPage,
        setItemsPerPage,
        setItemCount,
        currentPageIndex,
        PageCounter
    } = usePageCounter();

    const displayData = useMemo(() => {
        if (!watches) return [];
        let data: IWatchItem[] = [];
        // Add ARS watches
        watches['ARS'].forEach((watch) => {
            data.push({
                name: watch.name,
                term: watch.watchMe,
                ars: {
                    id: watch.id,
                    active: watch.active
                }
            });
        });
        // Add HLT watches
        watches['HLT'].forEach((watch) => {
            // If same term exists in ARS, add to existing item, otherwise add new item
            let existingWatch = data.find(x => x.term === watch.watchMe);
            if (existingWatch) {
                existingWatch.hlt = {
                    id: watch.id,
                    active: watch.active
                };
            } else {
                data.push({
                    name: watch.name,
                    term: watch.watchMe,
                    hlt: {
                        id: watch.id,
                        active: watch.active
                    }
                });
            }
        });
        // Add LOG watches
        const logWatches = watches['LOG']?.filter(x => isCompany(x.watchMe));
        logWatches.forEach((watch) => {
            // If same term exists in ARS or HLT, add to existing item, otherwise add new item
            //                      ssn format is different for LOG, must have dash
            let f = data.find(x => `${x.term.substring(0,6)}-${x.term.substring(6)}` === watch.watchMe);
            if (f) {
                f.log = {
                    id: watch.id,
                    active: watch.active
                };
            } else {
                data.push({
                    name: watch.name,
                    term: watch.watchMe,
                    log: {
                        id: watch.id,
                        active: watch.active
                    }
                });
            }
        });
        let from = currentPageIndex * itemsPerPage;
        let to = from + itemsPerPage;

        setItemCount(data.length);
        return data.slice(from, to);
    }, [watches, currentPageIndex, itemsPerPage]);
    
    const handleAdd = async ( term: string, type: string ) => {
        try {
            const requestQuery = new URLSearchParams({
                term,
                types: type
            });
            const url = '/MyKelda/AddWatch';
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                }
                const responseBody: { [key: string]: Watch[]; } = await response.json();
                if (responseBody) {
                    setWatches(responseBody);
                }
            } else {
                throw new Error(ErrorMessages.RequestFailed);
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
        }
    }
    
    const handleEdit = async ( id: number | undefined, active: boolean | undefined) => {
        if (id === undefined || active === undefined) return;
        try {
            const requestQuery = new URLSearchParams({
                id: id.toString(),
                active: active.toString(),
            });
            const url = `${GET_KELDAN_API_URL()}/MyKelda/EditWatch`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (!response.ok) {
                throw new Error(ErrorMessages.RequestFailed);
            }
            if (response.redirected) {
                window.location.href = response.url;
            }
            const responseBody: { [key: string]: Watch[]; } = await response.json();
            if (responseBody) {
                setWatches(responseBody)
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
        }
    }

    const handleDelete = async (ids: string) => {
        try {
            const requestQuery = new URLSearchParams({
                ids: ids
            });
            const url = `${GET_KELDAN_API_URL()}/MyKelda/DeleteWatches`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery,
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const responseBody: { [key: string]: Watch[]; } = await response.json();
                    if (responseBody) {
                        setWatches(responseBody);
                    }
                }
            } else {
                throw new Error(ErrorMessages.RequestFailed);
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
        }
    }

    const displayForm = (type: 'enabled' | 'disabled') => {
        return <FyrirtaekjaVaktinForm
            setData={(data) => setWatches(data)}
            disabled={type === 'disabled'}
        />
    }
    
    return (
        <div className="KCL_watch-tab">
            <SEO
                title="Fyrirtækjavaktin"
                description="Settu fyrirtæki í vöktun og fáðu tilkynningu þegar nýjar niðurstöður birtast í ársreikningum, hlutafélagaskráningu eða lögbirtingum."
                keyword="fyrirtækjavakt, fyrirtækjavöktun, eftirlit með fyrirtækjum, vakta félög, lögbirtingablaðið, breytingar í fyrirtækjaskrá, nýr ársreikningur, breytingar í hlutafélagaskrá, ný stjórn, nýr ársreikningur, lögbirtingar"
            />
            <div className="upper-section">
                <div className="left-side">
                    <h3>Fyrirtækjavaktin</h3>
                    <p className="description paragraph">
                        Settu fyrirtæki í vöktun og fáðu tilkynningu þegar þau birtast í ársreikningaskrá eða hlutafélagaskrá og/eða Lögbirtingablaðinu.
                    </p>
                    {isSubscriber
                        ? displayForm('enabled')
                        : <Tooltip
                            tooltip={<span>Aðeins í boði fyrir áskrifendur</span>}
                            text={displayForm('disabled')}
                        />
                    }
                </div>
                <Alert
                    type="info"
                    headText="Athugið"
                    text={"Keldan býður upp á vöktun á tilteknum upplýsingum sem birtast í ársreikningaskrá, hlutafélagaskrá og Lögbirtingablaði. " + 
                              "Þjónustan er til hagræðis fyrir notendur en Keldan ábyrgist ekki að vöktunin sé algild eða hnökralaus."}
                />
            </div>
            {isSubscriber && <>
                <div className="middle-section">
                    <h3 className="table-title">Fyrirtæki í vöktun</h3>
                    <div className="page-size-selector">
                        <span className="paragraph-small--bold">Sýna:</span>
                        <DropSelect
                            options={[{
                                label: '10 á síðu',
                                value: '10'
                            }, {
                                label: '50 á síðu',
                                value: '50'
                            }, {
                                label: '100 á síðu',
                                value: '100'
                            }]}
                            defaultValue={itemsPerPage.toString()}
                            size='lg'
                            onChange={(option) => { if (typeof option === 'string') setItemsPerPage(parseInt(option)); }}
                            className='page-size-select'
                        />
                    </div>
                </div>
                <Table
                    data={displayData}
                    columns={[{
                        title: 'Nafn',
                        renderCell: ({name, term}) => <Link linkSize="15" url={`/Fyrirtaeki/Yfirlit/${term}`}>{name}</Link>,
                        textAlign: 'left'
                    },{
                        title: 'Kennitala',
                        renderCell: ({term}) => insertDash(term),
                        textAlign: 'left'
                    }, {
                        title: 'Ársreikningar',
                        renderCell: ({term, ars}) => (
                            <Checkbox 
                                name="arsreikningar"
                                id={`arsreikningar${term}`}
                                value={ars?.active === true ? 'true' : 'false'}
                                onClick={() => {
                                    if (ars) {
                                        handleEdit(ars.id, !ars.active);
                                    } else {
                                        handleAdd(term, 'ARS');
                                    }
                                }}
                            />
                        ),
                        textAlign: 'center'
                    }, {
                        title: 'Hlutafélög',
                        renderCell: ({term, hlt}) => (
                            <Checkbox 
                                name="hlutafelog"
                                id={`hlutafelog${term}`}
                                value={hlt?.active === true ? 'true' : 'false'}
                                onClick={() => {
                                    if (hlt) {
                                        handleEdit(hlt.id, !hlt.active);
                                    } else {
                                        handleAdd(term, 'HLT');
                                    }
                                }}
                            />
                        ),
                        textAlign: 'center'
                    }, {
                        title: 'Lögbirtingar',
                        renderCell: ({term, log}) => (
                            <Checkbox 
                                name="logbirtingar"
                                id={`logbirtingar${term}`}
                                value={log?.active === true ? 'true' : 'false'}
                                onClick={() => {
                                    if (log) {
                                        handleEdit(log.id, !log.active);
                                    } else {
                                        handleAdd(term, 'LOG');
                                    }
                                }}
                            />
                        ),
                        textAlign: 'center'
                    }, {
                        title: 'staða',
                        renderCell: ({term, ars, hlt, log}) => {
                            const active = ars?.active || hlt?.active || log?.active;
                            return <Checkbox 
                                label={active ? 'Virk' : 'Óvirk'}
                                name="status"
                                id={`status${term}`}
                                value={active ? 'true' : 'false'}
                                className="statusCheckbox"
                                onClick={() => {
                                    if (ars) {
                                        if (ars.active !== !active) {
                                            handleEdit(ars.id, !active);
                                        }
                                    } else if (!active) {
                                        handleAdd(term, 'ARS');
                                    }
                                    if (hlt) {
                                        if (hlt.active !== !active) {
                                            handleEdit(hlt.id, !active);
                                        }
                                    } else if (!active) {
                                        handleAdd(term, 'HLT');
                                    }
                                    if (log) {
                                        if (log.active !== !active) {
                                            handleEdit(log.id, !active);
                                        }
                                    } else if (!active) {
                                        handleAdd(term, 'LOG');
                                    }
                                }}
                            />;
                        },
                        textAlign: 'left'
                    }, {
                        title: '',
                        renderCell: ({ars, hlt, log}) => (
                            <Button
                                size="sm"
                                buttonType="secondary"
                                onClick={() => {
                                    let ids = [ars?.id, hlt?.id, log?.id];
                                    handleDelete(ids.join(';'));
                                }}
                                icon={faTrashCan}
                            >
                                Eyða
                            </Button>
                        ),
                    }]}
                    tableSize='lg'
                />
                <PageCounter />
            </>}
        </div>
    )
}

export default FyrirtaekjaVaktin;
