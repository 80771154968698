// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import ViewOverviewComp from '../../components/Property/ViewOverview/ViewOverview';
// Type imports:
import { IPropertyViewModel } from '../../types/PropertyTypes';


const ViewOverview: React.FC<IPropertyViewModel> = ({
    eventId,
    bondsAllowed
}) => {
    return (
        <Entrypoint>
            <ViewOverviewComp eventId={eventId ?? ''} bondsAllowed={bondsAllowed} />
        </Entrypoint>
    );
}

export default ViewOverview;