// Package imports:
import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import { format } from 'date-fns';
import ISLocale from 'date-fns/locale/is';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import Loading from '../../../ui-elements/Loading/Loading';
// Service imports:
import { displayQuantityShorthand, formatNumber } from '../../../services/utils';
import { GET_API_LMD_URL } from '../../../services/config';
// Type imports:
import { IDefaultProps } from '../../../types/Types';
import { ReportFullResult } from '../../../types/CompanyTypes';

type IChartTypes = 'balanceSheet' | 'incomeStatement' | 'cashFlow';

interface IChartDatum {
    x: number
    y: number,
    name?: string
};

interface IChartDataInfo {
    name: string,
    data: IChartDatum[]
    color: string,
};

interface IOwnProps {
    chartType: IChartTypes,
    companyInfoID?: number | null,
    title?: string,
    height?: number,
};


type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const CompanyReportsChart: React.FC<Props> = ({
    chartType,
    companyInfoID,
    title,
    height = 255,
    accessToken,
    ...props
}) => {
    const [series1, setSeries1] = useState<ReportFullResult[]>([]);
    const [series2, setSeries2] = useState<ReportFullResult[]>([]);
    const [series3, setSeries3] = useState<ReportFullResult[]>([]);
    const [series4, setSeries4] = useState<ReportFullResult[]>([]);
    const [noData, setNoData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [yearToRateMap, setYearToRateMap] = useState<{ [year in number]: number | null}>({});

    const fetchData = async (key: string) => {
        setLoading(true)
        try {
            if (companyInfoID === undefined) return;
            if (companyInfoID === null) {
                setNoData(true);
                setLoading(false);
                return;
            }
            const url = `${GET_API_LMD_URL()}/v1/market_data/v1/company_reports/key_timeseries?key=${key}&id=${companyInfoID}&period_name=Y`;
            const headers = {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${accessToken}`
            };
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (!response.ok) return;
            const responseBody: ReportFullResult[] = await response.json();
            setLoading(false);
            if (!responseBody) return;

            switch(key) {
                /* Balance sheet keys */
                case '200000': 
                    setSeries1(responseBody);
                    break;
                case '300000':
                    setSeries2(responseBody);
                    break;
                case '400000':
                    setSeries3(responseBody);
                    break;
                /* Cash flow keys */
                case '510000':
                    setSeries1(responseBody);
                    break;
                case '520000':
                    setSeries2(responseBody);
                    break;
                case '530000':
                    setSeries3(responseBody);
                    break;
                case '950900':
                    setSeries4(responseBody);
                    break;
                /* Income statement keys */
                case '910100':
                    setSeries1(responseBody);
                    break;
                case '910400':
                    setSeries2(responseBody);
                    break;
                case '910700':
                    setSeries3(responseBody);
                    break;
                default: break;
            }
        } catch (e) {
            //HANDLE ERROR
        }
    }

    const fetchCurrencyInfo = async (year: number, currency: string) => {
        const url = `${GET_API_LMD_URL()}/v1/market_data/v1/keldan/base/ISK/cross_currency/[${currency}]/sources/[CBI]/currency_timeseries?date_from=${year}-01-05&date_to=${year}-01-07`;
        fetch(url, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(res => {
            if (res.ok) return res.json();
            else throw new Error(`${res.status}: ${res.statusText}`);
        })
        .then(resBody => {
            if (resBody && resBody.length > 0) {
                setYearToRateMap((yearToRateMap) => ({
                    ...yearToRateMap,
                    [year]: resBody[0].Averagerate
                }))
            }
        })
        .catch(err => {
            setYearToRateMap((yearToRateMap) => ({
                ...yearToRateMap,
                [year]: null
            }))
        })
    }
    
    useEffect(() => {
        const firstCurrency = series1[0]?.Currency;
        const differentCurrencyIndex = series1.findIndex(x => x.Currency !== firstCurrency);
        if (differentCurrencyIndex !== -1) {
            series1.forEach((x) => {
                if (x.Currency !== 'ISK') {
                    fetchCurrencyInfo(x.FiscalYear, x.Currency);
                }
            })
        }
    }, [series1])

    useEffect(() => {
        switch(chartType) {
            case 'balanceSheet': {
                fetchData('200000')
                fetchData('300000')
                fetchData('400000')
                break
            }
            case 'cashFlow': {
                fetchData('510000')
                fetchData('520000')
                fetchData('530000')
                fetchData('950900')
                break
            }
            case 'incomeStatement': {
                fetchData('910100')
                fetchData('910400')
                fetchData('910700')
                break
            }
            default: break;
        }
    }, [companyInfoID, accessToken])

    const divRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const colors = ['#F8C513', '#48A85D', '#FF7697', '#4569EE'];
        const combinedData = [];
        setNoData(false);

        const seriesCollection: ReportFullResult[][] = [series1, series2, series3, series4];
        for (let series of seriesCollection.filter(x => x.length > 0)) {
            //initiate an array for every serie
            const chartDataInfo: IChartDataInfo = {
                color: colors.pop() ?? '#000',
                name: series[0].IsName,
                data: []
            }
            for (let item of series) {
                const currencyRate = yearToRateMap[item.FiscalYear] ?? 1;
                chartDataInfo.data.push({
                    x: new Date(item.FiscalYear.toString()).getTime(),
                    y: item.KeyValueInverted * item.NumbersIn * currencyRate,
                    name: `${formatNumber(item.KeyValueInverted * item.NumbersIn, '-', 0)} ${item.Currency}`
                });
            }
            combinedData.push(chartDataInfo)
        }
        if (combinedData.length === 0
            && series1.length === 0 
            && series2.length === 0
            && series3.length === 0) setNoData(true)

        const sortData = (dataToSort: IChartDatum[]) => {
            return dataToSort.sort((a, b) => a.x - b.x);
        }
        let allSeries: Highcharts.SeriesColumnOptions[] = combinedData.map((s, i) => {
            return ({
               type: 'column',
               name: s.name,
               color: s.color,
               data: sortData(s.data)
           })
        })

        if (divRef.current !== null) {
            Highcharts.stockChart(divRef.current, {
                chart: {
                    height: height,
                    reflow: true,
                    style: {
                        fontFamily: "Roboto"
                    },
                    plotBackgroundColor: {
                        linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                        stops: [
                            [0.05, '#FFF'],
                            [0.95, '#F8F8FA']
                        ]
                    }
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            x: 30,
                            y: 36
                        }
                    }
                },
                credits: {
                    enabled: false,
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                tooltip: {
                    enabled: true,
                    formatter: function() {
                        //filtera einungis það sem er visible af því ef það er notað points[0] crashar allt þegar eitthvað series er disable-að
                        return (
                            `<div class="chart-tooltip">
                                <p class="tooltip-date">${format(this.x, 'yyyy', {locale: ISLocale})}</p>
                                ${this.points?.filter(x => x.point.visible === true).map(x => (
                                    `<p><span style="color: ${x.color}">${x.series.getName()}</span>: ${x.point.name}</p>`
                                ))}
                            </div>`
                        ).replace(/>,</g,'><')
                    },
                    borderWidth: 0,
                    shadow: false,
                    useHTML: true,
                    shared: true
                },
                xAxis: [{
                    type: 'datetime',
                    title: {
                        text: null
                    },
                    minTickInterval: 365*24*60*60*1000,
                    tickWidth: 0,
                    width: '100%'
                }],
                yAxis: [{
                    labels: {
                        formatter: function() {
                            return displayQuantityShorthand(this.value as number) ?? this.value.toString();
                        },
                        // y: 2
                    },
                    gridLineDashStyle: 'Dash',
                    gridLineWidth: 1,
                    gridLineColor: '#D3D3D6',
                    opposite: false,
                    height: '100%',
                }],
                legend: {
                    enabled: true,
                    align: 'left',
                    verticalAlign: 'bottom',
                    itemStyle: {
                        color: '#4F5059',
                        fontSize: '13px',
                        fontWeight: '400',
                        lineHeight: '15px',
                    },
                    symbolWidth: 10,
                    x: 5,
                    y: 20
                },
                rangeSelector: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            callback: function() {
                                return window.matchMedia('(max-width: 767px)').matches;
                            }
                        },
                        chartOptions: {
                            chart: {
                                spacingLeft: 0,
                                spacingRight: 5,
                                marginBottom: chartType === 'cashFlow' ? 80 : 40
                            },
                            legend: {
                                y: 20,
                                itemStyle: {
                                    fontSize: '11px',
                                    lineHeight: '13px'
                                }
                            },
                            xAxis: {
                                labels: {
                                    style: {
                                        fontSize: '9px'
                                    }
                                }
                            },
                            yAxis: {
                                labels: {
                                    style: {
                                        fontSize: '9px'
                                    },
                                    x: -1
                                }
                            }
                        }
                    }]
                },
                series: allSeries
            }, () => {});
        }
    }, [series1, series2, series3, series4, divRef.current, yearToRateMap]);
    if(loading) return <Loading/>
    else if(noData) return <Alert type='info' headText='Engin gögn í boði' />
    return (
        <div className="chart" ref={divRef}></div>
    );
}

export default CompanyReportsChart;