// Package imports:
import React, { useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Toast, ToastContainer } from 'react-bootstrap';
// Component imports:
import Input from '../../../ui-elements/Input/Formik/Input';
import Button from '../../../ui-elements/Button/Button';
// import DropSelect from '../../../ui-elements/DropSelect/DropSelect';
import Alert from '../../../ui-elements/Alert/Alert';
import Tooltip from '../../../ui-elements/Tooltip/Tooltip';
import Link from '../../../ui-elements/Link/Link';
// Service imports:
import { GET_KELDAN_API_URL, TOAST_DELAY } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { IKeldanApiResponse } from '../../../types/KeldanTypes';

// const REASON_SUBSCRIPTION0 = 'Strax og orð finnst í fréttamiðli';
// const REASON_SUBSCRIPTION1 = 'Daglega kl. 16';
// const REASON_SUBSCRIPTION2 = 'Mánudögum kl. 16';
// const REASON_SUBSCRIPTION3 = 'Þriðjudögum kl. 16';
// const REASON_SUBSCRIPTION4 = 'Miðvikudögum kl. 16';
// const REASON_SUBSCRIPTION5 = 'Fimmtudögum kl. 16';
// const REASON_SUBSCRIPTION6 = 'Föstudögum kl. 16';
// const REASON_SUBSCRIPTION7 = 'Laugardögum kl. 16';
// const REASON_SUBSCRIPTION8 = 'Sunnudögum kl. 16';

// const REASON_QUESTION0 = 'Viðskipti';
// const REASON_QUESTION1 = 'Almennar';
// const REASON_QUESTION2 = 'Íþróttir';
// const REASON_QUESTION3 = 'Skop';
// const REASON_QUESTION4 = 'Hlaðvarp';

interface IStockwatcherResponse {
    error?: string,
    search_term?: string,
    system?: string,
}
interface IOwnProps {
    isAuthenticated: boolean | undefined,
    isSubscriber:boolean | undefined
}
const NewsWatcher: React.FC<IOwnProps> = ({
    isAuthenticated,
    isSubscriber
}) => {
    const [contactResponse, setContactResponse] = useState<JSX.Element>(<></>);
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    //will be used later.
    // const [category, setCategory] = useState<string>('');
    // const [emailSchedule, setEmailSchedule] = useState<string>('');

    const registerWatch = async (values: IFormValues, cb: () => void) => {
        setLoading(true);

        // POST /workspace/v1/stockwatcher/news_search_terms - Bæta við search term fyrir user, body = { search_term: ""}
        try {
            setContactResponse(<></>);
            const url = `${GET_KELDAN_API_URL()}/news/RegisterWatchTerm?term=${values.word}`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
            });
            if (!response.ok) {
                setLoading(false);
                setShowToast(true)
                setContactResponse(<Alert type={'error'} headText={ErrorMessages.RequestFailed} />);
                return;
            }
            setLoading(false);
            if (response.redirected) window.location.href = response.url;
            const responseBody: IKeldanApiResponse<IStockwatcherResponse> = await response.json();
            //request return 200
            if (!responseBody.success) {
                setLoading(false)
                setShowToast(true)
                setContactResponse(<Alert type={'error'} headText={ErrorMessages.Other} />)
                return;
            }
            // result includes error object. So if that is undefined no error occured
            if (responseBody.result?.error) {
                const { error } = responseBody.result;
                setShowToast(true)
                // already exist error translating
                if (error === "news_search_term_already_exists") {
                    setContactResponse(<Alert type={'alert'} headText={`${values.word} er nú þegar skráð í vöktun`} />);
                } else if (error === "news_search_terms_limit_reached") {
                    setContactResponse(<Alert type={'alert'} headText={`Ekki er hægt að skrá fleiri en 10 orð í vöktun`} />);
                } else {
                    setContactResponse(<Alert type={'alert'} headText={error} />);
                }
            } else {
                setShowToast(true)
                //success message
                setContactResponse(<Alert type={'success'} headText={`${values.word} hefur verið skráð í vöktun`} />);
                cb();
            }
        } catch (e) {
            setLoading(false);
            setShowToast(true)
            setContactResponse(<Alert type={'error'} headText={ErrorMessages.NetworkError} />);
        }
    }

    const subFormComponent = useMemo(() => {
        return ((props: { values: IFormValues }) => (
            <SubFormComponent
                contactResponse={contactResponse}
                loading={loading}
                values={props.values}
                isAuthenticated={isAuthenticated}
                isSubscriber={isSubscriber}
                // setCategory={(e) => setCategory(e)}
                // setEmailSchedule={(e) => setEmailSchedule(e)}
                // category={category}
                // emailSchedule={emailSchedule}
            />
        ))
    }, [ contactResponse, loading ]);

    return (
        <>
            <div className='KCL_news-watcher'>
                <Formik
                    initialValues={{
                        word: '',
                    }}
                    validationSchema={Yup.object({
                        word: Yup.string()
                            .required('Orð verður að fylgja'),
                    })}
                    onSubmit={(values, {resetForm}) => {
                        registerWatch(values, resetForm);
                    }}
                    component={subFormComponent}
                />
            </div>
            {!isSubscriber &&
                <Link
                    linkSize='16'
                    url='/Askrift'
                    icon='forward'
                >
                    Komdu í áskrift
                </Link>
            }
            <ToastContainer className='toastContainer' >
                <Toast
                    show={showToast}
                    autohide
                    delay={TOAST_DELAY}
                    onClose={() => setShowToast(false)}
                >
                    {contactResponse}
                </Toast>
            </ToastContainer>
        </>
    );
}

export default NewsWatcher;

interface IFormValues {
    word: string,
}

interface ISubFormProps {
    contactResponse: JSX.Element,
    loading: boolean,
    values: IFormValues,
    isAuthenticated: boolean | undefined,
    isSubscriber:boolean | undefined
    // setCategory: (e: string) => void,
    // setEmailSchedule: (e: string) => void,
    // category: string,
    // emailSchedule: string,
}

const SubFormComponent: React.FC<ISubFormProps> = ({
    loading,
    values,
    isAuthenticated,
    isSubscriber
    // setCategory,
    // setEmailSchedule,
    // category,
    // emailSchedule,
}) => {
    return (
        <Form>
            <div className="form__body">
                <div className="form__section">
                    {!(isAuthenticated && isSubscriber)
                        ? <Tooltip
                            text={
                                <div className="form__row">
                                    <div className="form__col">
                                        <Input
                                            disabled
                                            label="Veldu orð til að vakta"
                                            name="word"
                                            id="word"
                                            value={values.word}
                                        />
                                        {/* <p className='instructoryText'>
                                            Aðgreinið orð með kommu á milli.
                                        </p> */}
                                    </div>
                                    <div className="form__col form__actions">
                                        <Button
                                            disabled
                                            type='submit'
                                            showLoader={loading}
                                            buttonType="primary"
                                            size="lg"
                                        >
                                            Skrá vöktun
                                        </Button>
                                    </div>
                                </div>
                            }
                            tooltip={<>Aðeins í boði fyrir áskrifendur</>}
                        />
                        : <div className="form__row">
                            <div className="form__col">
                                <Input
                                    label="Veldu orð til að vakta"
                                    name="word"
                                    id="word"
                                    value={values.word}
                                />
                                {/* <p className='instructoryText'>
                                    Aðgreinið orð með kommu á milli.
                                </p> */}
                            </div>
                            <div className="form__col form__actions">
                                <Button
                                    type='submit'
                                    showLoader={loading}
                                    buttonType="primary"
                                    size="lg"
                                >
                                    Skrá vöktun
                                </Button>
                            </div>
                        </div>
                    }
                </div>
                {/* <div className="form__col">
                        <DropSelect
                            label='Flokkur'
                            options={[{
                                label: REASON_QUESTION0,
                                value: REASON_QUESTION0
                            }, {
                                label: REASON_QUESTION1,
                                value: REASON_QUESTION1
                            }, {
                                label: REASON_QUESTION2,
                                value: REASON_QUESTION2
                            }, {
                                label: REASON_QUESTION3,
                                value: REASON_QUESTION3
                            }, {
                                label: REASON_QUESTION4,
                                value: REASON_QUESTION4
                            }]}
                            onChange={(e) => setCategory(e as string)}
                            defaultValue={category}
                            size='lg'
                        />
                    </div>
                    <div className="form__col">
                        <DropSelect
                            label='Fá tölvupóst'
                            onChange={(e) => setEmailSchedule(e as string)}
                            options={[{
                                label: REASON_SUBSCRIPTION0,
                                value: REASON_SUBSCRIPTION0
                            }, {
                                label: REASON_SUBSCRIPTION1,
                                value: REASON_SUBSCRIPTION1
                            }, {
                                label: REASON_SUBSCRIPTION2,
                                value: REASON_SUBSCRIPTION2
                            }, {
                                label: REASON_SUBSCRIPTION3,
                                value: REASON_SUBSCRIPTION3
                            }, {
                                label: REASON_SUBSCRIPTION4,
                                value: REASON_SUBSCRIPTION4
                            }, {
                                label: REASON_SUBSCRIPTION5,
                                value: REASON_SUBSCRIPTION5
                            }, {
                                label: REASON_SUBSCRIPTION6,
                                value: REASON_SUBSCRIPTION6
                            }, {
                                label: REASON_SUBSCRIPTION7,
                                value: REASON_SUBSCRIPTION7
                            }, {
                                label: REASON_SUBSCRIPTION8,
                                value: REASON_SUBSCRIPTION8
                            }]}
                            defaultValue={emailSchedule}
                            size='lg'
                        />
                    </div> */}
            </div>
        </Form>
    );
}
