// Package imports:
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
// Component imports:
import Entrypoint from '../Entrypoint';
import WatchPage from '../../components/Watch/Watch';
// Type imports:
import { IisAuthenticated } from '../../types/Types';


const Watch: React.FC<IisAuthenticated> = ({
    isAuthenticated,
    isSubscriber
}) => {
    const helmetContext = {};
    return (
        <HelmetProvider context={helmetContext}>
            <Entrypoint>
                <WatchPage isAuthenticated={isAuthenticated} isSubscriber={isSubscriber} />
            </Entrypoint>
        </HelmetProvider>
    );
}

export default Watch;