// Package imports:
import React, { useEffect, useState } from 'react';
import { faMegaphone } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import Button from '../../ui-elements/Button/Button';
import Link from '../../ui-elements/Link/Link';
import Label from '../../ui-elements/Label/Label';
import Alert from '../../ui-elements/Alert/Alert';
import Loading from '../../ui-elements/Loading/Loading';
// Service imports:
import { getFullDateAndTime } from '../../services/utils';
import { ErrorMessages } from '../../services/errorMessages';
import { GET_NEWS_LMD_URL } from '../../services/config';
// Type imports:
import { IAuthenticationWithId, Fetched } from '../../types/Types';
import { INewsLmdResponse } from '../../types/NewsTypes';

const SingleNewsPage: React.FC<IAuthenticationWithId> = ({
    isAuthenticated,
    isSubscriber,
    id
}) => {
    const [data, setData ] = useState<Fetched<INewsLmdResponse>>();


    const fetchData = async () => {
        try {
            const url = `${GET_NEWS_LMD_URL()}/search/get/${id}`
            
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {

                const responseBody: Fetched<INewsLmdResponse> = await response.json();
                setData(responseBody)

            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setData(new Error(ErrorMessages.OtherShort));
        }
    }
    useEffect(() => {
        fetchData()
    }, [])
    
    if (data instanceof Error) {
        return (
            <div className='main KCL_single-news-page'>
                <div className="main__inner-fluid">
                    <div className="mini_shell">
                        <HeadSection 
                            hasAds='main'
                            title={
                                <div className='headerSectionWrapper'>
                                    <div style={{display: 'flex'}}>
                                        <Button anchorProps={{href: "/Frettir"}}>
                                            Fréttavakt Keldunnar
                                        </Button>
                                        <Button
                                            anchorProps={{href: "/Min-Kelda/Voktun#FrettaVaktin"}}
                                            buttonType='secondary'
                                            icon={faMegaphone}
                                        >
                                            Mín fréttavakt
                                        </Button>
                                    </div>
                                </div>
                            }
                        />
                        <Alert type={'error'} headText={data.message} />
                    </div>
                </div>
            </div>
        )
    } else if (data && data.feedItems !== null && data.feedItems.length > 0) {
        return (
            <div className='main KCL_single-news-page'>
                <div className="main__inner-fluid">
                    <div className="mini_shell">
                        <HeadSection 
                            hasAds='main'
                            title={<>
                                <div className='title-and-source-wrapper'>
                                    <h1>{data.feedItems[0].title}</h1>
                                    <div className='time-and-source'>
                                        <span>
                                            {`${getFullDateAndTime(new Date(data.feedItems[0].publish_timestamp ?? 0))}`}
                                        </span>
                                        <Label text={data.feedItems[0].fullSource ?? ''} labelType='origin' />
                                    </div>
                                </div>
                                <div className='button-wrapper desktop'>
                                    <Button anchorProps={{ href: '/Frettir' }}>
                                        Fréttavakt Keldunnar
                                    </Button>
                                    <Button
                                        anchorProps={{ href: '/Min-Kelda/Voktun#FrettaVaktin' }}
                                        buttonType='secondary'
                                        icon={faMegaphone}
                                    >
                                        Mín fréttavakt
                                    </Button>
                                </div>
                            </>}
                        />
                        <div className='description-wrapper '>
                            <div className="news-item__entry paragraph" dangerouslySetInnerHTML={{__html: data?.feedItems[0].body ?? ''}} />
                        </div>
                        <Link url={data.feedItems[0].link ?? '#'} linkSize='16' icon='forward'>
                            Skoða frétt
                        </Link>
                        <div className='button-wrapper mobile'>
                            <Button
                                size="sm"
                                anchorProps={{href: "/Frettir"}}
                            >
                                Fréttavakt Keldunnar
                            </Button>
                            <Button
                                size="sm"
                                anchorProps={{href: "/Min-Kelda/Voktun#FrettaVaktin"}}
                                buttonType='secondary'
                                icon={faMegaphone}
                            >
                                Mín fréttavakt
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='main KCL_single-news-page'>
                <div className="main__inner-fluid">
                    <div className="mini_shell">
                        <HeadSection 
                            hasAds='main'
                            title={
                                <div className='headerSectionWrapper'>
                                    <div style={{display: 'flex'}}>
                                        <Button anchorProps={{href: "/Frettir"}}>
                                            Fréttavakt Keldunnar
                                        </Button>
                                        <Button
                                            anchorProps={{href: "/Min-Kelda/Voktun#FrettaVaktin"}}
                                            buttonType='secondary'
                                            icon={faMegaphone}
                                        >
                                            Mín fréttavakt
                                        </Button>
                                    </div>
                                </div>
                            }
                        />
                        {(data && data.feedItems && data.feedItems.length === 0)
                            ? <Alert type={'alert'} headText={'Ekki fundust gögn fyrir þessa frétt'} />
                            : <Loading /> 
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default SingleNewsPage;
