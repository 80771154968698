// Package imports:
import React from 'react';
// Component imports:
import FundsPage from '../../components/FundsPage/FundsPage';
// Context imports:
import AccessTokenContextProvider from '../../contexts/AccessTokenContext';

const Funds: React.FC = () => {
    return (
        <AccessTokenContextProvider>
            <FundsPage />
        </AccessTokenContextProvider>
    );
}

export default Funds;