// Package imports:
import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
// Component imports:
import Tabs from '../../ui-elements/Tabs/Tabs';
import LogbirtingaVaktin from './LogbirtingaVaktin/LogbirtingaVaktin';
import FyrirtaekjaVaktin from './FyrirtaekjaVaktin/FyrirtaekjaVaktin';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import Alert from '../../ui-elements/Alert/Alert';
import FrettaVaktin from './FrettaVaktin/FrettaVaktin';
// Service imports:
import { GET_KELDAN_API_URL } from '../../services/config';
import { ErrorMessages } from '../../services/errorMessages';
// Type imports:
import { Watch } from '../../types/MyKeldaTypes';
import { Fetched, IisAuthenticated } from '../../types/Types';

const WatchPage: React.FC<IisAuthenticated> = ({
	isAuthenticated,
	isSubscriber
}) => {
	const [watches, setWatches] = useState<Fetched<{ [key: string]: Watch[]; }>>(null);

	const fetchData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/MyKelda/WatchList`;
            const response = await fetch(url);
            if (response.ok) {
				if (response.redirected) {
                    window.location.href = response.url;
                } else {
					const body : { [key: string]: Watch[]; } = await response.json();
					setWatches(body);
				}
            } else {
                setWatches(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setWatches(new Error(ErrorMessages.NetworkError));
        }
    };

    useEffect(() => {
        if (isSubscriber) fetchData();
    }, [isSubscriber]);

	return (
		<div className="main KCL__Watch">
			<div className='section__body'>
				<div className='shell'>
					<div className='row'>
						<div className='col-sm-12'>
							<HeadSection
								title='Vaktin'
								showRecentReports={isAuthenticated}
							/>
						</div>
					</div>
					{	
						<Tabs
							saveTabToUrl
							tabs={[{
								id: "Fyrirtaekjavaktin",
								label:"Fyrirtækjavaktin",
								component: watches instanceof Error 
									? <Alert type="alert" headText='Error' /> 
									: <FyrirtaekjaVaktin watches={watches} setWatches={setWatches} isSubscriber={isSubscriber} />
							}, {
								id: "FrettaVaktin",
								label:"Fréttavaktin",
								component: <FrettaVaktin isSubscriber={isSubscriber} />
							}, {
								id: "LogbirtingaVaktin",
								label:"Lögbirtingavaktin",
								component: watches instanceof Error
									? <Alert type="alert" headText='Error' />
									: <LogbirtingaVaktin watches={watches} setWatches={setWatches} isSubscriber={isSubscriber} />
							}]}
							tabsSize="lg"
						/>
					}
				</div>
			</div>
		</div>
	)
}

export default WatchPage;