// Package imports:
import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Bugsnag from '@bugsnag/js';
// Component imports:
import Input from '../../../ui-elements/Input/Formik/Input';
import Button from '../../../ui-elements/Button/Button';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Watch } from '../../../types/MyKeldaTypes';

interface IFormValues {
    term: string,
}

interface IOwnProps {
    setData(a: { [key: string]: Watch[]; }): void,
    disabled?: boolean
}

const LogbirtingarVaktinForm: React.FC<IOwnProps> = ({
    setData,
    disabled = false
}) => {

    const handleSubmit = async (values: IFormValues) => {
        try {
           
            const requestQuery = new URLSearchParams({
                term: values.term,
                types: 'LOG'
            });
            
            const url = `${GET_KELDAN_API_URL()}/MyKelda/AddWatch`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const responseBody : { [key: string]: Watch[]; } = await response.json();
                    if (responseBody) {
                        setData(responseBody);
                    } else {
                        throw new Error('Unable to unwrap Json')
                    }
                }
            } else {
                throw new Error(ErrorMessages.RequestFailed)
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e)
        }
    }

    const subFormComponent = useMemo(() => {
        return ((props: { values: IFormValues }) => (
            <SubFormComponent
                values={props.values}
                disabled={disabled}
            />
        ))
    }, []);

    return (
        <div className='KCL_watch-form'>
            <Formik
                initialValues={{
                    term: ''
                }}
                validationSchema={Yup.object({
                    term: Yup.string().required('Vinsamlegast sláðu inn orð')
                })}
                onSubmit={(values, { resetForm }) => {
                    handleSubmit(values);
                    resetForm();
                }}
                component={subFormComponent}
            />
        </div>
    );
}

export default LogbirtingarVaktinForm;

interface ISubFormProps {
    values: IFormValues,
    disabled: boolean
}

const SubFormComponent: React.FC<ISubFormProps> = ({
    values,
    disabled
}) => {
        return (
            <Form>
                <div className="form__body">
                    <div className="form__section">
                        <Input
                            placeholder='Sláðu inn orð'
                            name="term"
                            id="term"
                            value={values.term}
                            disabled={disabled}
                        />
                    </div>
                    <div className="form__actions">
                        <Button disabled={values.term.length < 1} type='submit' buttonType="primary" size="lg">Skrá vöktun</Button>
                    </div>
                </div>
            </Form>
        );
    }
