// Package imports:
import React from 'react';
import cx from 'classnames';

type PriceCardVariant = 'base' | 'overview' | 'primary' | 'secondary' | 'tertiary';

type DivProps = React.HTMLAttributes<HTMLDivElement>;

interface IProps {
    variant?: PriceCardVariant,
    type?: string,
    price?: string | null,
    priceInfo?: string,
    priceText?: string,
    badge?: string,
    notice?: string,
    showRibbon?: boolean,
    noPriceLabel?: boolean,
    keldanMarkadirPriceCard?: boolean
}

type Props = DivProps & IProps;

const PriceCard: React.FC<Props> = ({
    variant = 'base',
    type,
    price,
    priceInfo,
    priceText,
    badge,
    notice,
    noPriceLabel,
    keldanMarkadirPriceCard,
    showRibbon,
    className,
    children,
    ...props
}) => {
    return (
        <div className={cx('KCL_price-card', `price-card--${variant}`, className, {'keldanMarkadirPriceCard': keldanMarkadirPriceCard})} {...props}>
            {badge && (
                <div className="price-card__badge">
                    {badge}
                </div>
            )}

            {showRibbon && (
                <div className="price-card__ribbon">
                    <div className="price-card__ribbon-content">
                        <span>Vinsælt</span>
                    </div>
                </div>
            )}

            {notice && (
                <div className="price-card__notice">
                    {notice}
                </div>
            )}
            
            <div className={cx("price-card__type")}>{type} </div>

            {price ? (
                <div className="price-card__price">
                    {
                        !noPriceLabel &&
                        (<span>Verð:</span>)
                    }
                    <p>
                        <strong>{price}</strong>
                        <span>{priceInfo}</span>
                    </p>
                </div>
            ) : (
                <>
                    { priceText && (
                        <div className="price-card__price-text">
                            <span>Verð:</span>

                            <strong>{priceText}</strong>
                        </div>
                    )}
                </>
            )}

            <div className="price-card__content">
                {children}
            </div>
        </div>
    );
}

export default PriceCard;