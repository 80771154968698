// Package imports:
import React, { useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
// Component imports:
import Input from '../../../ui-elements/Input/Formik/Input';
import DropSelect from '../../../ui-elements/DropSelect/DropSelect';
import Button from '../../../ui-elements/Button/Button';
import Alert from '../../../ui-elements/Alert/Alert';
import Recaptcha from '../../../ui-elements/Recaptcha/Recaptcha';
// Service imports:
import useSendEmailHook from '../../../services/apiHooks';
import { Fetched } from '../../../types/Types';

interface IFormValues {
    name: string,
    email: string,
    message: string,
    // captcha: string
}
const REASON_KYC = 'Ég vil fá aðgang að KYC kerfi Hluthafaskrár';
const REASON_PEP_API = 'Ég vil fá PEP vefþjónustu';


const DueDiligenceForm: React.FC = () => {
    const [reason, setReason] = useState<string>(REASON_KYC)
    const [captchaToken, setCaptchaToken] = useState<string | null>('');
    
    const { loading, contactResponse, handleSubmit } = useSendEmailHook(
        'Email/DueDiligenceContactForm',
        captchaToken,
        {
            subject: reason
        });

    const subFormComponent = useMemo(() => {
        return ((props: { values: IFormValues }) => (
            <SubFormComponent
                contactResponse={contactResponse}
                values={props.values}
                setCaptchaToken={(e: string | null ) => setCaptchaToken(e)}
                loading={loading}
                setReason={(e) => setReason(e)}
                reason={reason}
            />
        ))
    }, [ reason, contactResponse, loading ]);
    return(
        <div className="KCL_notendur-form modal-form">
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    message: ''
                }}
                validationSchema={Yup.object({
                    name: Yup.string().required('Nafn má ekki vanta'),
                    email: Yup.string().email('Ógilt netfang').required('Netfang verður að fylgja'),
                    message: Yup.string().required("Skilaboð má ekki vanta")
                })}
                onSubmit={handleSubmit}
                component={subFormComponent}
            />
        </div>
    )
}

export default DueDiligenceForm;

interface IFormValues {
    name: string,
    email: string,
    message: string
}

interface ISubFormProps {
    contactResponse: Fetched<string>,
    values: IFormValues,
    loading: boolean,
    setCaptchaToken: (e: string | null) => void,
    setReason: (e: string) => void,
    reason: string
}

const SubFormComponent: React.FC<ISubFormProps> = ({
    contactResponse,
    values,
    loading,
    setCaptchaToken,
    setReason,
    reason
}) => {
    return <Form>
    <div className="form__body">
        <div className="form__row">
            <div className="form__col">
                <Input
                    label="Nafn"
                    name="name"
                    id="name"
                    value={values.name}
                />
            </div>
            <div className="form__col">
                <Input
                    label="Netfang"
                    name="email"
                    id="email"
                    value={values.email}
                />
            </div>
        </div>
        <div className="form__row">
            <div className="form__col">
                <DropSelect
                    options={[{
                        label: REASON_KYC,
                        value: REASON_KYC
                    }, {
                        label: REASON_PEP_API,
                        value: REASON_PEP_API
                    }]}
                    onChange={(e) => {
                        if (typeof e === 'string') setReason(e)
                    }}
                    defaultValue={reason}
                    size='lg'
                />
            </div>
        </div>
        <div className="form__row">
            <div className="form__col">
                <Input
                    inputType="textArea"
                    label="Skilaboð"
                    name="message"
                    id="message"
                    value={values.message}
                />
            </div>
        </div>
        {(contactResponse === null)
        ? null
        : (
            <div className="form__row">
                <div className="form__col">
                    {(contactResponse instanceof Error)
                    ? <Alert type='error' headText={contactResponse.message} />
                    : <Alert type='info' headText={contactResponse} />}
                </div>
            </div>
        )}
    </div>
    <Recaptcha position="left" setToken={(e: string | null) => setCaptchaToken(e)}/>
    <div className="form__actions">
        <Button type='submit' showLoader={loading} buttonType="primary" size="lg">Senda</Button>
    </div>
</Form>
}
