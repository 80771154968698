// Package imports:
import React, { useState, useRef, useEffect, useMemo } from "react";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { getSearchTermsFromSearch } from "../../../services/utils";

type SelectSize = "sm" | "lg" | "xs";

export interface IOptionProps {
  id?: string;
  label: string;
  value: string;
}

interface IProps {
  label?: string;
  disabled?: boolean;
  options?: IOptionProps[];
  size?: SelectSize;
  className?: string | React.HTMLAttributes<HTMLElement>;
  defaultValue?: number | string | string[] | null;
  onChange?(s?: string | string[] | null): void;
  error?: string;
}

const DropSelectSearch: React.FC<IProps> = ({
  label,
  size = "sm",
  disabled,
  options = [],
  className = "",
  defaultValue = "",
  onChange = () => {},
  error,
  children,
}) => {
  const dropdown = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue?.toString());
  const [search, setSearch] = useState("");

  const defaultValueRef = useRef(defaultValue);
  useEffect(() => {
    defaultValueRef.current = defaultValue;
  }, [defaultValue]);

  const toggleOpen = () => setIsOpen(!isOpen);

  const hasValue = useMemo(() => {
    return (
      (selectedValue && selectedValue.toString().length >= 1) ||
      (search && search.length >= 1)
    );
  }, [selectedValue, search]);

  const handleOutsideClick = (event: any) => {
    if (dropdown.current) {
      if (!dropdown.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    setSelectedValue(defaultValueRef.current?.toString());
  }, [defaultValue, defaultValueRef.current]);

  useEffect(() => {
    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsOpen(false);

    setSearch("");

    onChange(selectedValue);
  }, [selectedValue]);

  const selectedOption = useMemo(() => {
    return options.find(
      (opt) =>
        selectedValue && opt.value.toString() === selectedValue.toString()
    );
  }, [selectedValue, options]);

  const currentOptions = useMemo(() => {
    return options.filter(({ label }) =>
      label.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, options]);

  // Refs for search input and data elements. Will help us with changing focus.
  const searchFieldInputRef = useRef<HTMLInputElement | null>(null);
  const searchDataAnchorElementsRef = useRef<(HTMLButtonElement | undefined)[]>(
    []
  );

  const isOpenRef = useRef(isOpen);
  useEffect(() => {
    isOpenRef.current = isOpen;
  }, [isOpen]);

  // Document listeners for arrow key functionality.
  const onKeyPress = (e: KeyboardEvent) => {
    // Fetch refs.
    const isOpen = isOpenRef.current;
    const searchDataAnchorElements = searchDataAnchorElementsRef.current;
    // Should only work if the repsonse window is open.
    if (!isOpen) return;
    const currentFocusedElement = document.activeElement;
    const lastElementIndex = searchDataAnchorElements.length - 1;
    switch (e.key) {
      // Down arrow = go to next (should be like tab)
      case "ArrowDown":
        e.preventDefault();
        // If focus is on search field input, move focus to 1st element in list.
        if (currentFocusedElement === searchFieldInputRef.current) {
          searchDataAnchorElements[0]?.focus();
          return;
        }
        // If on last element, focus on search field input.
        if (
          currentFocusedElement === searchDataAnchorElements[lastElementIndex]
        ) {
          searchFieldInputRef.current?.focus();
          return;
        }
        // If focus is on element in list, move to next.
        const i = searchDataAnchorElements.findIndex(
          (ref) => ref === currentFocusedElement
        );
        searchDataAnchorElements[i + 1]?.focus();
        break;
      // Up arrow = go to previous (should be like shift-tab)
      case "ArrowUp":
        e.preventDefault();
        // If on search field, go to last element in list.
        if (currentFocusedElement === searchFieldInputRef.current) {
          searchDataAnchorElements[lastElementIndex]?.focus();
          return;
        }
        // If on first element, focus on search field input.
        if (currentFocusedElement === searchDataAnchorElements[0]) {
          searchFieldInputRef.current?.focus();
          return;
        }
        // If focus is on element in list, move to previous.
        const j = searchDataAnchorElements.findIndex(
          (ref) => ref === currentFocusedElement
        );
        searchDataAnchorElements[j - 1]?.focus();
        break;
      // if the user presses enter, we want to prevent the form from submitting
      // if the dropdown is open
      case "Enter":
        e.preventDefault();
        //find the selected item and set the value
        const selectedItem = options.find(
          (item) => item.label === currentFocusedElement?.textContent
        );
        setSelectedValue(selectedItem?.value.toString() ?? "");
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", onKeyPress);
    return () => document.removeEventListener("keydown", onKeyPress);
  }, []);

  const highlightText = (title: string) => {
    // The search terms we will search and replace in the original string.
    const searchTerms = getSearchTermsFromSearch(search);
    // Lowercase title to search without case. We will still use the normal title to return values.
    const lowercaseTitle = title.toLowerCase();
    // IMPORTANT: We hightlight using a starting and ending index method. This allows for full highlight even if some searchTerms are substrings of themselves.
    const startHighlightIndices: number[] = [];
    const endHighlightIndices: number[] = [];

    // Find the start and end highlight indices for every search term.
    for (let searchTerm of searchTerms) {
      // Constant helper variables.
      const lowercaseSearchTerm = searchTerm.toLowerCase();
      const searchTermLength = lowercaseSearchTerm.length;

      // The starting index from where to search.
      let startingIndex = 0;
      // The index of the found search term.
      let indexOfSearchTerm = lowercaseTitle.indexOf(lowercaseSearchTerm);
      // Until we no longer find search terms.
      while (indexOfSearchTerm !== -1) {
        startingIndex = indexOfSearchTerm + searchTermLength;
        startHighlightIndices.push(indexOfSearchTerm);
        endHighlightIndices.push(startingIndex);
        indexOfSearchTerm = lowercaseTitle.indexOf(
          lowercaseSearchTerm,
          startingIndex
        );
      }
    }

    startHighlightIndices.sort((a, b) => a - b);
    endHighlightIndices.sort((a, b) => a - b);

    let titleWithMarks = "";
    let nextStartIndex = startHighlightIndices.shift();
    let nextEndIndex = endHighlightIndices.shift();
    for (let i = 0; i <= title.length; i++) {
      // Add end mark if endIndex present
      while (nextEndIndex === i) {
        titleWithMarks += "</mark>";
        nextEndIndex = endHighlightIndices.shift();
      }
      // Add start mark if startIndex present
      while (nextStartIndex === i) {
        titleWithMarks += "<mark>";
        nextStartIndex = startHighlightIndices.shift();
      }
      // Add the char (but not last since we go 1 index over string to check last endhighlight)
      if (i !== title.length) titleWithMarks += title[i];
    }

    return titleWithMarks;
  };
  return (
    <>
      <div
        className={cx(
          "KCL_drop-select drop-select--search",
          className.toString(),
          size,
          {
            "is-open": isOpen,
            "has-value": hasValue,
            disabled: disabled,
            "has-error": error,
          }
        )}
        ref={dropdown}
      >
        {label && (
          <span className="drop-select__label">
            <span>{label}</span>
          </span>
        )}

        <input
          disabled={disabled}
          className="drop-select__trigger"
          type="text"
          value={search}
          onClick={() => setIsOpen(true)}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={selectedOption?.label}
          autoComplete="off"
          ref={searchFieldInputRef}
        />
        <i onClick={toggleOpen}>
          <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} />
        </i>

        <div className="drop-select__overlay">
          <div className="drop-select__inner">
            {children ? (
              <div>{children}</div>
            ) : (
              currentOptions.length > 0 &&
              currentOptions.map((option, i) => (
                <button
                  key={option.label}
                  type="button"
                  className={cx("drop-select__item", {
                    "is-selected":
                      selectedValue &&
                      option.value.toString() === selectedValue.toString(),
                  })}
                  onClick={() => setSelectedValue(option.value)}
                  dangerouslySetInnerHTML={{
                    __html: `${highlightText(option.label)}`,
                  }}
                  ref={(ref) => {
                    searchDataAnchorElementsRef.current[i] = ref ?? undefined;
                  }}
                />
              ))
            )}
          </div>
        </div>
      </div>
      {error && <span className="KCL_drop-select-error-message">{error}</span>}
    </>
  );
};

export default DropSelectSearch;
