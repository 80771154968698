// Package imports:
import React, { useState } from 'react';
import { SwiperSlide } from 'swiper/react';
// Component imports:
import Button from '../../ui-elements/Button/Button';
import TextImageBlock from '../TextImageBlock/TextImageBlock';
import PriceCard from '../PriceCard/PriceCard';
import Slider from '../Slider/Slider';
import FullScreenImage from '../../ui-elements/FullScreenImage/FullScreenImage';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import DueDiligenceForm from '../ContactForms/DueDiligenceForm/DueDiligenceForm';
import Link from '../../ui-elements/Link/Link';
import BulletList from '../../ui-elements/Lists/BulletList';
// Service imports:
import { getSubscriptionPrice } from '../../services/utils';

const AREIDANLEIKAKANNANIR_IMAGES_URLS = [
    "https://cdn.livemarketdata.com/KeldanImages/KYCkerfi_1.png",
    "https://cdn.livemarketdata.com/KeldanImages/KYCkerfi_2.png",
    "https://cdn.livemarketdata.com/KeldanImages/KYCkerfi_3.png",
    "https://cdn.livemarketdata.com/KeldanImages/KYCkerfi_4.png"
];

const BULLET_POINTS = [
    'Áreiðanleikakannanir á lögaðilum og einstaklingum.',
    'Áreiðanleikakannanir á erlendum aðilum.',
    'Gildandi skráning sótt úr Hlutafélagaskrá ásamt raunverulegum eigendum.',
    'Spurningalisti sendur út.',
    'Rafræn innskráning viðskiptavina (framvísun persónuskilríkja).',
    'Svör við spurningum móttekin.',
    'Sjálfvirk leit í PEP lista (stjórnmálaleg tengsl).',
    'Uppfletting í alþjóðlegum þvingunarlistum.',
    'Skráning áhættumats handvirkt eða sjálfvirkt.',
    'Skjöl hengd við hverja áreiðanleikakönnun.',
    'Sjálfvirk áminning þegar ákveðinn tími er liðinn frá síðustu könnun.',
    'Góð heildstæð yfirsýn á viðskiptavinum.'
];

const Areidanleikakannanir: React.FC = () => {
    const [ fullScreenImageUrl, setFullScreenImageUrl ] = useState<string | null>(null);
    const handleListItems = () => {
        return (
            <BulletList items={BULLET_POINTS} />
        )
    }
    return (
        <div className='main KCL_areidanleikakannanir-page'>
            {fullScreenImageUrl && (
                <FullScreenImage
                    imgUrl={fullScreenImageUrl}
                    imgAlt="Fullscreen Áreiðanleikakönnun screenshot"
                    onExit={() => setFullScreenImageUrl(null)}
                />
            )}
            <div className="main__inner-fluid grid-items">
                <HeadSection
                    title="Áreiðanleikakannanir"
                    summary={
                        <p>
                            Hvíla á þér skyldur um aðgerðir gegn peningaþvætti og fjármögnun hryðjuverka? <br/>
                            Keldan og <Link linkSize='20' url="https://www.hluthafaskra.is/Areidanleikakannanir" targetBlank>Hluthafaskrá.is</Link> halda úti einföldu og skilvirku kerfi til að framkvæma áreiðanleikakannanir sem gefur góða yfirsýn á viðskiptavinum, lögum samkvæmt.
                        </p>
                    }
                    actions={
                        <Button
                            size="lg"
                            anchorProps={{
                                href: '#kynning',
                            }}
                        >
                            Panta kynningu
                        </Button>
                    }
                    textAlign='center'
                />
                <div className="section__body">
                    <div className="shell">
                        <TextImageBlock
                            imgSide="right"
                            imgSrc="https://cdn.livemarketdata.com/KeldanImages/Areidanleikakonnun_KYC.png"
                        >
                            <h2>Þekktu þinn viðskiptavin</h2>
                            <div className='text-image-block__entry'>
                                <p className='paragraph'>KYC kerfið („Know your customer“) er ætlað til utanumhalds og framkvæmdar áreiðanleikakannana og stenst þau lög sem tilkynningarskyldir aðilar falla undir um aðgerðir gegn peningaþvætti og fjármögnun hryðjuverka.</p>
                                <p className='paragraph'>Kerfið gerir notendum kleift að framkvæma áreiðanleikakannanir á einfaldan hátt og öðlast góða yfirsýn á viðskiptavinum, lögaðilum og einstaklingum.</p>
                                <Link
                                    linkSize='16'
                                    url="https://www.hluthafaskra.is/Areidanleikakannanir"
                                    targetBlank
                                    icon='forward'
                                >
                                    Sjá nánar inni á vef Hluthafaskrár
                                </Link>
                            </div>
                        </TextImageBlock>
                        <TextImageBlock
                            imgSide="left"
                            imgSrc='https://cdn.livemarketdata.com/KeldanImages/Areidanleikakonnun_example_mynd.png'
                        >
                            <h2>Innifalið í kerfinu</h2>
                            <div className="text-image-block__entry">
                                {handleListItems()}
                            </div>
                        </TextImageBlock>
                        <div className="text-image-block-list">
                            <TextImageBlock
                                imgSide="right"
                            >
                                <h3>Vöktun á lögaðilum</h3>
                                <div className="text-image-block__entry">
                                    <p className='paragraph'>Í sumum tilfellum er ástæða til að fylgjast náið með ákveðnum aðilum vegna aukinnar áhættu sem felst í viðskiptasambandinu.
                                        Á Keldunni er hægt að skrá félög í <Link url={"/Min-Kelda/Voktun#Fyrirtaekjavaktin"} linkSize='18'>sjálfvirka vöktun</Link> og fá tilkynningu þegar nýjar niðurstöður liggja fyrir í ársreikningum, hlutafélagaskráningu eða lögbirtingum.
                                    </p>
                                </div>
                            </TextImageBlock>
                            <TextImageBlock>
                                <h3>PEP listi - stjórnmálaleg tengsl</h3>
                                <div className="text-image-block__entry">
                                    <p className='paragraph'>
                                        Keldan heldur úti íslenskum PEP lista sem er aðgengilegur tilkynningarskyldum aðilum. Á listanum eru einstaklingar sem eru eða hafa verið háttsettir í opinberri þjónustu ásamt nánustu fjölskyldu og samstarfsmönnum á síðustu 18 mánuðum. 
                                    </p>
                                    <p className='paragraph'>
                                        PEP listinn er einnig aðgengilegur sem vefþjónusta sé þess óskað og skal þá <Link linkSize='18' url='/Hafa-samband/Pep'>hafa samband við Kelduna.</Link>
                                    </p>
                                    <Button
                                        buttonType='secondary'
                                        size='lg'
                                        anchorProps={{href: "/Pep/Leit", style: {marginTop: '20px'}}}>
                                        Uppfletting í PEP lista
                                    </Button>
                                </div>
                            </TextImageBlock>
                        </div>
                    </div>
                </div>
                <div className="section section--gray section--contact" id="kynning">
                    <div className="shell">
                        <div className="section__inner">
                            <div className="section__head">
                                <h2>Fá kynningu á KYC kerfinu</h2>
                                <p className='summary-paragraph' style={{maxWidth: '636px'}}>
                                    Hafðu samband til að fá kynningu á KYC kerfi Hluthafaskrár.
                                </p>
                                <DueDiligenceForm />
                            </div>
                            <div className="section__body">
                                <div className="shell">
                                <PriceCard
                                    variant="overview"
                                    type="Verðskrá KYC kerfisins "
                                >
                                    <div className="price-card__details">
                                        <dl>
                                            <dt className='paragraph--bold'>Mánaðargjald</dt>
                                            <dd className='paragraph--bold'>{getSubscriptionPrice(true)}</dd>
                                        </dl>
                                        <p className='paragraph-small--italic' style={{marginBottom: '12px'}}>
                                            Áskrift að Keldunni er innifalin.
                                        </p>
                                        <div className='content-wrapper'>
                                            <dl>
                                                <dt>Framkvæmd áreiðanleikakönnun á lögaðilum + PEP uppfletting</dt>
                                                <dd>1.190 kr.</dd>
                                            </dl>
                                            <dl>
                                                <dt>Framkvæmd áreiðanleikakönnun á einstaklingum + PEP uppfletting</dt>
                                                <dd>590 kr.</dd>
                                            </dl>
                                            <dl>
                                                <dt>Uppfletting í alþjóðlegum válistum</dt>
                                                <dd>195 kr.</dd>
                                            </dl>
                                        </div>
                                        <p className='paragraph-small--italic'>Athugið, verðin eru án VSK.</p>
                                    </div>
                                </PriceCard>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="section__head">
                        <div className="shell">
                            <h2 id="Vaktarinn_Screenshots">Skjáskot úr KYC kerfinu</h2>
                        </div>
                    </div>
                    <div className="section__body">
                        <div className="shell">
                            <Slider
                                className="slider-screenshots"
                                perView={3}
                                centerSlides={false}
                                loop={true}
                                breakpointOptions={{
                                    320: {
                                        slidesPerView: 1
                                    },
                                    768: {
                                        slidesPerView: 3
                                    }
                                }}
                                onInit={() => {}}
                                onSlideChange={() => {}}
                            >
                                {AREIDANLEIKAKANNANIR_IMAGES_URLS.map((url) => (
                                    <SwiperSlide
                                        key={url}
                                        onClick={() => setFullScreenImageUrl(url)}
                                    >
                                        <img
                                            className="slide-image"
                                            src={url}
                                            alt="Áreiðanleikakannanir kerfi skjáskot"
                                            width="1406"
                                            height="811"
                                        />
                                    </SwiperSlide>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Areidanleikakannanir;