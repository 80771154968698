// Package imports:
import React, { useContext, useEffect, useState } from 'react';
// Component imports:
import AdRotator from '../Ad/AdRotator';
import SingleIndex from '../SingleIndex/SingleIndex';
import BasicChart from '../Charts/BasicChart/BasicChart';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
// Service imports:
import { DEFAULT_REFRESH_RATE, MARKET_REFRESH_RATES } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';
// Context imports:
import { AccessTokenContext } from '../../contexts/AccessTokenContext';


export type IndexTypes = "OMXN40" | "OMXC20CAP" | "OMXS30" | "OMXH25" | "OMXI15GI" | "OMXI15CAP" | "OMXI15" | "OMXIGI" | "OMXIPI" | "NOMXIBB" | "NOMXIBBTA" | "NOMXINOM" | "NOMXINOMTA" | "NOMXIREAL" | "NOMXIREALTA";

interface IProps {
    indexType: IndexTypes,
}

const SingleIndexPage: React.FC<IProps> = ({
    indexType
}) => {
    const accessToken = useContext(AccessTokenContext);
    const [ defaultProps, setDefaultProps ] = useState<IDefaultProps>({
        accessToken,
        refreshRateMs: DEFAULT_REFRESH_RATE
    });

    const [ title, setTitle ] = useState<string | undefined>('');

    useEffect(() => {
        setDefaultProps({
            ...defaultProps,
            accessToken
        });
    }, [accessToken, title]);

    return (
        <div className='main KCL_single-index-page KCL_market-page'>
            <div className='main__inner-fluid'>
                <div className="shell">
                    <HeadSection
                        hasAds='market'
                        title={indexType}
                    />
                    <div>
                        <div className='grid-items'>
                            <div className='grid-item'>
                                <SingleIndex
                                    index={indexType}
                                    setIndexName={(name) => setTitle(name)}
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['SINGLEINDEX']}
                                    id="SingleIndexPage_IndexTable"
                                />
                            </div>
                        </div>
                        <div className='middle-ad-section'>
                            <AdRotator location='Market1018x360' />
                        </div>
                        <div className='grid-items grid-items--flex'>
                            <div className="grid-item grid-item--3of4 grid-item--table-full">
                                <BasicChart
                                    symbol={indexType}
                                    title='Söguleg gögn'
                                    chartType='index'
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['SINGLEINDEXCHART']}
                                    id="SingleIndexPage_Chart"
                                /> 
                            </div>
                            <div className='grid-item grid-item--1of4 grid-item--table-full'>
                                <AdRotator location="Market310x400" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleIndexPage;