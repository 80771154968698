// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import BondsPageComp from '../../components/BondsPage/BondsPage';

const Bonds: React.FC = () => {
    return (
        <Entrypoint>
            <BondsPageComp />
        </Entrypoint>
    );
}

export default Bonds;