// Package imports:
import React from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Tabs from '../../ui-elements/Tabs/Tabs';
import Landsbankinn from './Landsbankinn/Landsbankinn';
import Krossar from './Krossar/Krossar';
import Sedlabankinn from './Sedlabankinn/Sedlabankinn';
import Tollgengi from './Tollgengi/Tollgengi';
import Kreditkort from './Kreditkort/Kreditkort';
// Service imports:
import { CUSTOM_REFRESH_RATES } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';

type Props = IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const Gjaldmidlar: React.FC<Props>= ({
    accessToken,
    refreshRateMs,
    ...props
}) => {
    return (
        <DisplayBox
            title="Gjaldmiðlar"
            className='KCL_gjaldmidlar'
            anchorProps={{ href: '/Markadir/Gjaldmidlar' }}
            displayBoxSize="sm"
            {...props}
        >
            <Tabs tabs={[{
                id: `${props.id}-LAIS`,
                label: 'Almennt gengi',
                component: <Landsbankinn
                    accessToken={accessToken}
                    refreshRateMs={CUSTOM_REFRESH_RATES['LANDSBANKINN']}
                />
            }, {
                id:`${props.id}-krossar`,
                label: 'Krossar',
                component: <Krossar
                    accessToken={accessToken}
                    refreshRateMs={CUSTOM_REFRESH_RATES['KROSSAR']}
                />
            }, {
                id:`${props.id}-sedlabankinn`,
                label: 'Seðlabankinn',
                component: <Sedlabankinn
                    accessToken={accessToken}
                    refreshRateMs={CUSTOM_REFRESH_RATES['SEDLABANKINN']}
                />
            }, {
                id:`${props.id}-tollgengi`,
                label: 'Tollgengi',
                component: <Tollgengi
                    accessToken={accessToken}
                    refreshRateMs={CUSTOM_REFRESH_RATES['TOLLGENGI']}
                />
            }, {
                id:`${props.id}-kreditkort`,
                label: 'Kreditkort',
                component: <Kreditkort
                    accessToken={accessToken}
                    refreshRateMs={CUSTOM_REFRESH_RATES['KREDITKORT']}
                />
            }]} />
        </DisplayBox>
    );
}

export default Gjaldmidlar;