import React, { useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import { GET_KELDAN_API_URL } from '../../services/config';
import { IKeldanApiResponse } from '../../types/KeldanTypes';
import { LegalAdvert, LegalSearchViewModel } from '../../types/LegalTypes';
import Table from '../../ui-elements/Table/Table';
import { MAX_RESULTS, ResultStatus, getItemHref } from './SearchPage';
import Button from '../../ui-elements/Button/Button';
import { useBuyingProcessUrl } from '../../services/buyhook';
import { ErrorMessages } from '../../services/errorMessages';
import { getTableDate } from '../../services/utils';
import Loading from '../../ui-elements/Loading/Loading';
import Link from '../../ui-elements/Link/Link';

const LegalSearch:React.FC<{
    term: string | null,
    onResultsChange: (hasResults: ResultStatus) => void;
    isAuthenticated?: boolean;
}> = ({term, onResultsChange, isAuthenticated}) => {
    const [isTableLoading, setIsTableLoading] = React.useState(false);
    const [legalList, setLegalList] = React.useState<LegalAdvert[]>([]);
    const { tryPurchaseItem, isItemLoading, setToastError, ModalsAndToasts } = useBuyingProcessUrl((legalAdvert: LegalAdvert, reference: string) => {
        const { advertNumber } = legalAdvert;
        const url = `${GET_KELDAN_API_URL()}/Legal/BuyReport/?adNumber=${advertNumber}&reference=${reference}`
        return url;
        },
        () => {},
        'Lögbirtingablaðinu'
    );

    const resetState = (message: ResultStatus) => {
        setLegalList([]);
        onResultsChange(message);
        setIsTableLoading(false);
    };
    
    const handleErrorResponse = (type: 'alert'| 'error', message: string) => {
        if (message === 'no card') {
            resetState('no card');
            setToastError({
                type: type,
                headText:'Greiðsluupplýsingar vantar',
                text: <p>Kreditkortaupplýsingar eru ekki fullnægjandi. Vinsamlegast yfirfarið <Link href='/Min-Kelda/Stillingar' linkSize='16'>kortaupplýsingar</Link>.</p>
            });
        }
        else resetState('no results');
    };

    const legalSearch = async () => {
        if (!term || term.length < 1) {
            resetState('no results');
            return;
        }

        try {
            setIsTableLoading(true);
            const requestQuery = new URLSearchParams({ term });
            const url = `${GET_KELDAN_API_URL()}/Legal/Search`;

            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
                body: requestQuery,
            });

            if (!response.ok) {
                handleErrorResponse('error', 'response not ok');
                return;
            }

            const body: IKeldanApiResponse<LegalSearchViewModel> = await response.json();
            
            setIsTableLoading(false);
            if (!body.success) {
                const { message } = body;
                const toastError = message === ErrorMessages.NoCard ? 'no card' : 'bad request';
                handleErrorResponse('alert', toastError)
                return;
            }

            if (body.result && body.result.result.length > 0) {
                setLegalList(body.result.result);
                onResultsChange('has results');
            } else {
                resetState('no results');
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            handleErrorResponse('error', 'network error');
        }
    };

    useEffect(() => {
        isAuthenticated ? legalSearch() : resetState('no results');
    }, [isAuthenticated]);

    if (term === null) return null;
    if (term.length < 1) return null;
    if (!isAuthenticated) return null;
    //no data
    if(legalList === null || legalList.length === 0) return null
    return (
        <div className='table-section legal-search'>
            <h4>Lögbirtingar</h4>
            {isTableLoading ? <Loading /> : <Table 
                data={legalList.slice(0,MAX_RESULTS)}
                columns={[{
                    title: 'dagsetning',
                    renderCell: ({ published }) => getTableDate(published, "DD/MM/YYYY", "."),
                    textAlign: 'left',
                    simpleSortable: ({ published }) => published,
                }, {
                    title: 'tegund',
                    renderCell: ({ type }) => type,
                    textAlign: 'left',
                    simpleSortable: ({ type }) => type
                }, {
                    title: 'efni',
                    renderCell: ({ subject }) => subject,
                    textAlign: 'left',
                    simpleSortable: ({ subject }) => subject
                }, {
                    title: 'númer',
                    renderCell: ({ advertNumber }) => advertNumber,
                    textAlign: 'left',
                    simpleSortable: ({ advertNumber }) => advertNumber
                }, {
                    title: 'sýnishorn',
                    renderCell: ({ highlight }) => 
                        (highlight !== null && highlight !== undefined) 
                            ? highlight.substring(0, Math.min(20, highlight.length))
                            : "Á ekki við",
                    textAlign: 'left',
                },{
                    title: '',
                    renderCell: ( datum ) => {
                        return ( 
                        <a className='anchorWrapForButton' href={getItemHref(
                            {
                                Tickertype: 'Lögbirtingar',
                                Symbol: datum.type,
                                Name: datum.type,
                            }
                        )}>
                            <span>
                                <Button
                                    onClick={() => tryPurchaseItem(datum, 'confirm-modal-with-reference')}
                                    showLoader={isItemLoading(datum)} buttonType='buy' size='sm'>Kaupa</Button>
                            </span>
                        </a>
                    )}
                }]}
                tableSize='lg'
            />}
            <ModalsAndToasts />
        </div>
    );
} 
export default LegalSearch;