// Package imports:
import React, { useEffect, useMemo, useState } from 'react';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';
import Loading from '../../../ui-elements/Loading/Loading';
import PropertyNavBar from '../PropertyNavBar/PropertyNavBar';
import GangverdReportBody from './GangverdReportBody';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { IEventReport, IKeldanApiResponse } from '../../../types/KeldanTypes';
import { IGangverdSearchResult } from '../../../types/PropertyTypes';
import { Fetched } from '../../../types/Types';

interface IProps {
    id?: string
}

const GangverdReport: React.FC<IProps> = ({
    id
}) => {
    const [report, setReport] = useState<Fetched<IEventReport<IGangverdSearchResult>>>(null);

    const fetchReport = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Property/GetGangverdReport/${id}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                }
                const responseBody: IKeldanApiResponse<IEventReport<IGangverdSearchResult>> = await response.json();
                const { result, message } = responseBody;
                if (result) {
                    setReport(result);
                } else if (message) {
                    setReport(new Error(message));
                } else {
                    setReport(new Error(ErrorMessages.ErrorInRequest));
                }
            } else {
                setReport(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setReport(new Error(ErrorMessages.OtherShort));
        }
    }

    useEffect(() => {
        fetchReport();
    }, []);

    const fastanr = useMemo(() => {
        if (report === null || report === undefined || report instanceof Error) return undefined;
        return report.overview.gangverd.info.fnr?.toString();
    }, [report]);

    const displayBody = () => {
        if (report instanceof Error) {
            return <Alert type='error' headText={report.message} />;
        }
        if (report === null) {
            return <Loading />;
        }
        return <GangverdReportBody report={report} />;
    }

    return (
        <div className='main KCL_property-page'>
            <div className='main__inner fluid'>
                <PropertyNavBar propertyNumber={fastanr} />
                <div className='shell'>
                    <HeadSection
                        title="Verðvísir Gangverðs"
                        showRecentReports
                    />
                    {displayBody()}
                </div>
            </div>
        </div>
    );
}

export default GangverdReport;