// Package imports:
import React from 'react';
// Component imports:
import Table from '../../../ui-elements/Table/Table';
import Button from '../../../ui-elements/Button/Button';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { useBuyingProcess } from '../../../services/buyhook';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { IPropertyDocument, IPropertyDocumentListResult, IPropertySearchResult } from '../../../types/PropertyTypes';
import { IKeldanApiResponse, OwnedReport } from '../../../types/KeldanTypes';

interface IProps {
    propertylist: IPropertySearchResult,
    setDocumentList: (doclist: IPropertyDocument[]) => void,
    setOwnedDocuments: (owned: OwnedReport[]) => void
}

interface IBuyFasteignaSkra {
    fastanr: string
}

const PropertyOverviewTable: React.FC<IProps> = ({
    propertylist,
    setDocumentList,
    setOwnedDocuments
}) => {
    const { tryPurchaseItem, tryToBuyItem, resetState, ModalsAndToasts } = useBuyingProcess(async (values: IBuyFasteignaSkra, modalReference) => {
        try {
            const { fastanr } = values;
            const url = `${GET_KELDAN_API_URL()}/Property/BuyDocumentList`;
            const query = new URLSearchParams({
                fastanr,
                reference: modalReference
            });

            const response = await fetch(`${url}?${query.toString()}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                    return;
                }
                const responseBody: IKeldanApiResponse<IPropertyDocumentListResult> & {owned: OwnedReport[]} = await response.json();
                const { message, result, owned } = responseBody;
                if (result) {
                    setDocumentList(result.response.documents.documents);
                    setOwnedDocuments(owned);
                } else if (message === ErrorMessages.NoCard) {
                    resetState('no card')
                } else if (message) {
                    resetState({
                        type: 'alert',
                        headText: message
                    });
                } else {
                    resetState('bad request');
                }
            } else {
                resetState('response not ok');
            }
        } catch (e) {
            resetState('network error');
        }
    }, 'fasteignaskrá');
    
    return (
        <div>
            <Table
                tableSize="lg"
                data={propertylist.response?.properties?.properties || null}
                columns={[{
                    title: 'Fastanúmer',
                    renderCell: ({property_number}) => property_number,
                    textAlign: 'left'
                }, {
                    title: 'Heiti',
                    renderCell: () => propertylist.response?.properties?.address?.street,
                    textAlign: 'left'
                }, {
                    title: 'Húsnúmer',
                    renderCell: () => propertylist.response?.properties?.address?.house_number,
                    textAlign: 'left'
                }, {
                    title: 'Merking',
                    renderCell: ({units}) => units[0].tag,
                    textAlign: 'left'
                }, {
                    title: 'Eigandi',
                    renderCell: ({owners}) => {
                        if (!owners) return '';
                        const filteredOwners = owners.filter(o => o.owner_type === 'F');
                        let s = '';
                        for (const element of filteredOwners) {
                            s += `${s === '' ? '' : ' / '}${element.name}`
                        }
                        return s;
                    },
                    textAlign: 'left'
                }, {
                    title: 'Sveitarfélag',
                    renderCell: () => propertylist.response?.properties?.address?.county,
                    textAlign: 'left'
                }, {
                    title: 'Byggð',
                    renderCell: () => propertylist.response?.properties?.address?.city,
                    textAlign: 'left'
                }, {
                    title: 'Skjalalisti',
                    renderCell: ({property_number}) => (
                        <Button
                            buttonType="buy"
                            size='sm'
                            showLoader={(
                                tryToBuyItem !== null
                                && tryToBuyItem.fastanr === property_number
                            )}
                            onClick={() => property_number && tryPurchaseItem({
                                fastanr: property_number
                            }, 'confirm-modal-with-reference')}
                        >
                            Kaupa
                        </Button>
                    )
                }]}
            />
            <ModalsAndToasts />
        </div>
    );
}

export default PropertyOverviewTable;