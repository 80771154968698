// Package imports:
import React, { useEffect, useState } from 'react';
import { faFileDownload } from '@fortawesome/pro-solid-svg-icons';
import Bugsnag from "@bugsnag/js";
// Component imports:
import Button from '../../ui-elements/Button/Button';
import Alert from '../../ui-elements/Alert/Alert';
// Service imports:
import { getEventUrl, reportTypeNameChanger } from '../../services/utils';
import useComponentVisible from '../../services/hooks';
import { GET_KELDAN_API_URL } from '../../services/config';
import { ErrorMessages } from '../../services/errorMessages';
// Type imports:
import { HistoryList } from '../../types/MyKeldaTypes';
import { Fetched } from '../../types/Types';

const HistoryButtonDropDown: React.FC = () => {
    //using custom hook for visability status and handling click outside component
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible();
    const [historyList, setHistoryList] = useState<Fetched<HistoryList>>(null);

    const fetchData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/MyKelda/HistoryList?redirectUrl=${window.location.pathname.substring(1)}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                }
                const body : HistoryList = await response.json();
                setHistoryList(body);
            } else {
                setHistoryList(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setHistoryList(new Error(ErrorMessages.NetworkError));
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const displayData = () => {
        if (historyList instanceof Error) {
            return <Alert type='error' headText={historyList.message} />;
        }
        if (!historyList || !historyList.count) {
            return <div className='box-item'>
                <p className='box-item__noItems'>Engar keyptar skýrslur fundust</p>
            </div>
        }
        return historyList?.events?.map(({
            type,
            typeName,
            id,
            searchTerm,
            eventDate,
            reference,
            fileId
        }) => (
            <a key={id} href={`${getEventUrl(searchTerm, typeName ?? type, id, fileId)}`} target='_blank' rel='noopener noreferrer'>
                <div className='box-item'>
                    <p className='box-item__title paragraph--bold'>
                        {reportTypeNameChanger(typeName ?? type)}
                    </p>
                    <p className='box-item__searchTerm paragraph-small'>
                        {searchTerm}<br/>{reference}
                    </p>
                    <p className='box-item__eventDate italic-text'>
                        {eventDate}
                    </p>
                </div>
            </a>
        ))
    }

    return (
        <div className='KCL_historyButtonDropDown' ref={ref}>
            <Button
                buttonType='secondary'
                onClick={() => setIsComponentVisible(!isComponentVisible)}
                icon={faFileDownload}
                hideTextInMobile
            >
                Nýlegar uppflettingar
            </Button>
            {isComponentVisible &&
                <div className='dropDownContainer'>
                    <div className='show-all_btn'>
                        <Button anchorProps={{href: "/Min-Kelda/Keyptar-Skyrslur"}} size='lg'>
                            Skoða allar uppflettingar
                        </Button>
                    </div>
                    <div className='box'>
                        <div className='all_data'>
                            {displayData()}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default HistoryButtonDropDown;