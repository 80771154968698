// Package imports:
import React from 'react';
// Component imports:
import Markhopalistar from '../../components/Markhopalistar/Markhopalistar';
import Entrypoint from '../Entrypoint';
// Type imports:
import { ListsViewModel } from '../../types/ListsTypes';

const MarkhopalistarPage: React.FC = ({
    ...props
}) => {
    return (
        <Entrypoint>
            <Markhopalistar model={props as ListsViewModel} />
        </Entrypoint>
    );
}

export default MarkhopalistarPage;