// Package imports:
import React, { useEffect, useState } from 'react';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../../ui-elements/Link/Link';
import Table from '../../../ui-elements/Table/Table';
import Alert from '../../../ui-elements/Alert/Alert';
import Loading from '../../../ui-elements/Loading/Loading';
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { insertDash } from '../../../services/utils';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { CarsReport } from '../../../types/VehicleTypes';
import { Fetched } from '../../../types/Types';
import { IKeldanApiResponse } from '../../../types/KeldanTypes';

interface IVehicleReport {
    ssn: string,
    eventDate: string,
    carsReport: CarsReport
}

const ViewVehiclesReport: React.FC<{ id: string }>= ({
    id
}) => {
    const [ vehicleReport, setVehicleReport] = useState<Fetched<IVehicleReport>>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${GET_KELDAN_API_URL()}/Individual/GetVehiclesReport/${id}`;
                const response = await fetch(url);
                if (response.ok) {
                    const responseBody: IKeldanApiResponse<IVehicleReport> = await response.json();
                    if (responseBody.result) {
                        setVehicleReport(responseBody.result);
                    } else if (responseBody.message) {
                        setVehicleReport(new Error(responseBody.message));
                    } else {
                        setVehicleReport(new Error(ErrorMessages.OtherShort));
                    }
                } else {
                    setVehicleReport(new Error(ErrorMessages.RequestFailed));
                }
            } catch (e) {
                setVehicleReport(new Error(ErrorMessages.NetworkError));
            }
        }
        fetchData();
    }, []);

    const displayTable = (type: 'owned' | 'no longer owned') => {
        if (vehicleReport === null) {
            return <Loading />
        }
        if (vehicleReport instanceof Error) {
            return (
                <Alert
                    type={
                        (vehicleReport.message === ErrorMessages.RequestFailed || vehicleReport.message === ErrorMessages.NetworkError)
                        ? 'error'
                        : 'alert'
                    }
                    headText={vehicleReport.message}
                />
            );
        }
        const isCurrentValue = (type === 'owned') ? '1' : '0'
        const filteredCars = vehicleReport.carsReport.vehicleList.filter(vechile => vechile.isCurrent === isCurrentValue);

        if (filteredCars.length === 0) {
            if (type === 'owned') {
                return (
                    <Alert
                        type='info'
                        headText='Engin ökutæki í eigu'
                    />
                )
            } else {
                return (
                    <Alert
                        type='info'
                        headText='Engin ökutæki ekki lengur í eigu'
                    />
                )
            }
        }

        return (
            <Table
                data={filteredCars}
                tableSize='lg'
                columns={[{
                    title: 'Tegund ökutækis',
                    renderCell: ({ type }) => type,
                    textAlign: 'left'
                }, {
                    title: 'Skráningarnúmer',
                    renderCell: ({ regno }) => regno,
                    textAlign: 'left'
                }, {
                    title: 'Fastanúmer',
                    renderCell: ({ permno }) => permno,
                    textAlign: 'left'
                }, {
                    title: 'Verksmiðjunúmer',
                    renderCell: ({ vin }) => vin,
                    textAlign: 'left'
                }, {
                    title: 'Eigandi/Umráðamaður',
                    renderCell: ({ role }) => role,
                    textAlign: 'left'
                }, {
                    title: 'Eigandi',
                    renderCell: ({ owner }) => owner,
                    textAlign: 'left'
                }]}
            />
        )
    }

    const displayBody = () => {
        if (vehicleReport === null) {
            return <Loading />
        }
        if (vehicleReport instanceof Error) {
            return <ErrorAlert error={vehicleReport} />
        }
        return <>
            <div className='info-col-wrapper'>
                <div className='info-col'>
                    <div className='info-item'>
                        <span className='title'>Kennitala</span>
                        <span className='value'>{insertDash(vehicleReport.ssn)}</span>
                    </div>
                </div>
            </div>
            <DisplayBox
                title='Ökutæki í eigu'
                className='KCL_ViewIndividualAssetsReport'
                asideComponent={
                    <div className='report-aside-component'>
                        <div className='link-wrapper'>
                            <Link
                                targetBlank
                                linkSize='15'
                                url={`/Pdf/Okutaeki-Eignastada?ssn=${vehicleReport.ssn}&eventId=${id}`}
                                icon='pdf'
                            >
                                Sækja skýrslu
                            </Link>
                        </div>
                        <div className='italic-text'>
                            Skýrsla keypt: {vehicleReport.eventDate}
                        </div>
                    </div>
                }
            >
                <div className='main'>
                    {displayTable('owned')}
                    <h4 className="middle-h4">Ökutæki ekki lengur í eigu</h4>
                    {displayTable('no longer owned')}
                </div>
            </DisplayBox>
        </>
    }

    return (
        <div className='main KCL_individual-profile assets-report'>
            <div className='shell'>
                <HeadSection
                    title="Ökutæki - skýrsla"
                    showRecentReports
                />
                {displayBody()}
            </div>
        </div>
    );
}

export default ViewVehiclesReport;