// Package imports:
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import Link from '../../../ui-elements/Link/Link';
import Loading from '../../../ui-elements/Loading/Loading';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Button from '../../../ui-elements/Button/Button';
import Fyrirvari from '../../Fyrirvari/Fyrirvari';
import ExampleReportButton from '../../ExampleReportButton/ExampleReportButton';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Fetched } from '../../../types/Types';
import { IKeldanApiResponse, OwnedReport } from '../../../types/KeldanTypes';
import { ICompanyViewModel } from '../../../types/CompanyTypes';

interface IOwnProps {
    large?: boolean,
}
const CompanyCompare: React.FC<ICompanyViewModel & IOwnProps> = ({
    id,
    isAuthenticated,
    large
}) => {
    const [data, setData] = useState<Fetched<OwnedReport[]>>(null);

    const [ disclaimerOpen, setDisclaimerOpen ] = useState(false);
    const openDisclaimer = () => setDisclaimerOpen(true);
    const closeDisclaimer = () => setDisclaimerOpen(false);

    const fetchOwnedData = async () => {
        try {
            const url = (large) 
                        ? `${GET_KELDAN_API_URL()}/Company/GetCompanyCompares/?id=${id}&limit=${10}`
                        : `${GET_KELDAN_API_URL()}/Company/GetCompanyCompares/${id}`;
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });

            if (!response.ok) {
                setData(new Error(ErrorMessages.RequestFailed));
                return;
            }
            if (response.redirected) {
                window.location.href = response.url;
                return;
            }
            const responseBody: IKeldanApiResponse<OwnedReport[]> = await response.json();
            if (responseBody.success) {
                if (responseBody.result) setData(responseBody.result);
            } else {
                const { message } = responseBody;
                if (message) {
                    if (message !== "LoggedOut") {
                        setData(new Error(message));
                    }
                } else {
                    setData(new Error(ErrorMessages.ErrorInRequest));
                }
            }
        } catch (e) {
            setData(new Error(ErrorMessages.NetworkError));
        }
    }

    useEffect(() => {
        if (isAuthenticated)
            fetchOwnedData();
    }, [isAuthenticated])

    const getContent = () => {
        if (!isAuthenticated) return null;
        if (data instanceof Error) return <Alert type='error' headText={data.message} />;
        if (data === null || data === undefined) return <Loading />;
        return <div>
            {data.map((item) => (
                <div key={item.balls} className={cx('company-compare-line', {'large': large})}>
                    <div>
                        <span className='company-compare-line-date'>
                            Samanburður gerður: {item.properDate.split(' ')[0].replace(/\//g, '.')}
                        </span>
                    </div>
                    <Button
                        size='sm'
                        buttonType='secondary'
                        anchorProps={{href: `/Company/ViewComparison/${id}?eventId=${item.balls}`}}
                    >
                        Skoða
                    </Button>
                </div>
            ))}
        </div>
    }

    return (
        <DisplayBox 
            className='KCL_company-compare'
            title="Samanburður fyrirtækja"
            description='Berðu saman lykiltölur allt að tíu fyrirtækja í einu'
            asideComponent={
                <Button anchorProps={{href: `/Fyrirtaeki/Samanburdur-Velja/${id}`}} size='sm'>
                    Nýr samanburður
                </Button>
            }
            footerLeft={!large && isAuthenticated
                ? <Link
                    linkSize='14'
                    url={`/Fyrirtaeki/Samanburdur/${id}`}
                    className='more-link'
                    icon='forward'
                >
                    Fleiri samanburðir
                </Link>
                : undefined
            }
            footerRight={
                <Fyrirvari
                    show={disclaimerOpen}
                    open={openDisclaimer}
                    close={closeDisclaimer}
                >
                    <p className='paragraph'>
                        Keldan vinnur gögn úr opinberum upplýsingum eftir bestu getu. Keldan ber ekki ábyrgð á röngum upplýsingum sem geta komið til vegna innsláttarvillna eða annarra þátta.
                        Ábendingar um villur eða rangar upplýsingar eru vel þegnar með tölvupósti á <Link url='mailto:help@keldan.is' linkSize='18'>help@keldan.is.</Link>
                    </p>
                </Fyrirvari>
            }
        >
            {getContent()}
            <div className='example-row'>
                <ExampleReportButton size='sm' reportType='company-compare' />
            </div>
        </DisplayBox>
    );
}

export default CompanyCompare;