// Package imports:
import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import Bugsnag from '@bugsnag/js';
// Component imports:
import Button from '../../../ui-elements/Button/Button';
import Table from '../../../ui-elements/Table/Table';
import Link from '../../../ui-elements/Link/Link';
import DropSelect from '../../../ui-elements/DropSelect/DropSelect';
import SmallSearch from '../../SmallSearch/SmallSearch';
import Loading from '../../../ui-elements/Loading/Loading';
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';
import Alert from '../../../ui-elements/Alert/Alert';
// Service imports:
import { getEventUrl, reportTypeNameChanger } from '../../../services/utils';
import { GET_KELDAN_API_URL } from '../../../services/config';
import { useDotNetDataMappedByString } from '../../../services/apiHooks';
import { usePageCounter } from '../../../services/hooks';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Fetched, IDefaultProps } from '../../../types/Types';
import { HistoryEvent, MyReportsSearchViewModel } from '../../../types/MyKeldaTypes';
import { OwnedReport } from '../../../types/KeldanTypes';

const MINIMUM_LETTER_SEARCH = 1;

const DEFAULT_DROP_DOWN_ITEMS = [{
    label: '10 á síðu',
    value: '10'
}, {
    label: '50 á síðu',
    value: '50'
}, {
    label: '100 á síðu',
    value: '100'
}];

type IProps = IDefaultProps & IOwnProps;

interface IOwnProps {
    model?: MyReportsSearchViewModel,
}

const nameChanger = (name?: string) => {
    switch (name) {
        case 'Fyrirtaeki':
            return 'Fyrirtæki';
        case 'Fyrirmyndarfyrirtaeki':
            return 'Fyrirmyndarfyrirtæki';
        case 'Hlutafelog':
            return 'Hlutafélög';
        case 'Hlutafelagathatttaka':
            return 'Hlutafélagaþátttaka';
        case 'Logbirtingar':
            return 'Lögbirtingar';
        case 'Samanburdur':
            return 'Samanburður';
        case 'Vinnuvelar':
            return 'Vinnuvélar';
        case 'Thjodskra':
            return 'Þjóðskrá';
        case 'Okutaeki':
            return 'Ökutæki';
        case 'PEP':
            return 'PEP uppflettingar';
        case "PEP kall stök kennitala":
            return 'PEP uppflettingar';
        case "PEP erlendir einstaklingar":
            return 'PEP erlendar uppflettingar';
        case "PEP-Erlent":
            return 'PEP uppflettingar erlendir einstaklingar';
        case "PEP-Erlent-Fyrirtaeki":
            return 'PEP uppflettingar erlend fyrirtæki';
        case 'Thinglyst-Skjol':
            return 'Þinglýst skjöl';
        default:
            return name ?? '';
    }
}

const idChanger = (id: string | undefined): string | undefined => {
    switch (id) {
        case 'PEP':
            return 'PEP kall stök kennitala';
        case 'PEP-Erlent':
            return 'PEP erlendir einstaklingar';
        case 'PEP-Erlent-Fyrirtaeki':
            return 'PEP erlend fyrirtæki';
        default:
            return id;
    }
}

const ReportsSubPage: React.FC<IProps> = ({
    model,
}) => {
    /* OVERHEAD */
    document.title = `${nameChanger(model?.id)} | Keyptar skýrslur`

    const getDataSlice = <T extends unknown>(data: T[]) => data.slice(currentPageIndex*itemsPerPage, (currentPageIndex+1)*itemsPerPage);

    /* REQUEST STATES */
    // All reports.
    const [ allReports, setAllReports ] = useState<Fetched<HistoryEvent[]>>(null);
    const [ allReportsCount, setAllReportsCount ] = useState<number | null>(null);
    // Most recently fetched data (to avoid clipping to <Loading> when user is still writing answer)
    const [ mostRecentlyFetchedSearchResult, setMostRecentlyFetchedSearchResult ] = useState<OwnedReport[] | null>(null);
    // Search string and result for string.
    const [ searchInput, setSearchInput, searchResultData ] = useDotNetDataMappedByString<MyReportsSearchViewModel>(
        '',
        (searchInput: string) => `/MyKelda/ReportsSearch?category=${idChanger(model?.id)}&term=${searchInput}`,
        undefined,
        (searchResult) => setMostRecentlyFetchedSearchResult(searchResult.result),
    );
    // Pagination
    const {
        currentPageIndex,
        itemsPerPage,
        setItemsPerPage,
        itemCount,
        setItemCount,
        totalPages,
        setTotalPages,
        resetPageCounter,
        PageCounter
    } = usePageCounter();

    /* Memo variables about states */
    // Is user using search word.
    const usingSearchWord = useMemo(() => {
        return (searchInput.length >= MINIMUM_LETTER_SEARCH);
    }, [ searchInput ]);

    // Item count of current data.
    useEffect(() => {
        if (usingSearchWord) {
            if (searchResultData.data === null) {
                if (mostRecentlyFetchedSearchResult === null) setItemCount(null);
                setItemCount(mostRecentlyFetchedSearchResult?.length ?? null);
            }
            else setItemCount(searchResultData.data.result.length);
        } else {
            setItemCount(allReportsCount);
        }
    }, [ usingSearchWord, allReportsCount, searchResultData ]);

    // Pagination memos.
    const isNextPageDisabled = useMemo(() => {
        if (totalPages === null) return true;
        if (!usingSearchWord && allReports !== null && !(allReports instanceof Error) && getDataSlice(allReports).length < itemsPerPage) return true;
        return ((currentPageIndex + 1) >= totalPages)
    }, [ currentPageIndex, totalPages, allReports]);

    // Fetch all reports paginatedly
    useEffect(() => {
        const fetchAllReports = async () => {
            const start = currentPageIndex * itemsPerPage;
            const limit = start + Math.max(100, itemsPerPage);
            
            try {
                const url = `${GET_KELDAN_API_URL()}/MyKelda/GetReports/${idChanger(model?.id) ?? ''}?start=${start}&limit=${limit}`;
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    }
                });
                if (response.ok) {
                    if (response.redirected) {
                        window.location.href = response.url;
                    } else {
                        const responseBody: MyReportsSearchViewModel = await response.json();
                        if (allReportsCount === null) setAllReportsCount(responseBody?.reports?.count ?? null);
                        setAllReports(current => {
                            const copy =
                                (Array.isArray(current))
                                ? [...current]
                                : [];
                            if (responseBody?.reports?.events) {
                                responseBody.reports.events.forEach((event, index) => {
                                    copy[start+index] = event;
                                })
                            }
                            return copy;
                        })
                    }
                } else {
                    throw new Error(ErrorMessages.RequestFailed)
                }
            } catch (e) {
                if (e instanceof Error) Bugsnag.notify(e);
            }
        }
        if (usingSearchWord) return;
        if (allReports instanceof Error) return;
        if (allReports !== null && totalPages !== null && (
            getDataSlice(allReports).length === itemsPerPage
            || ((currentPageIndex + 1) >= totalPages)
        )) return;
        fetchAllReports();
    }, [ itemsPerPage, usingSearchWord, currentPageIndex ]);

    // Set totalPages
    useEffect(() => {
        // User is searching, use searchresultdata
        if (usingSearchWord) {
            const count = searchResultData.data?.result.length ?? null;
            setTotalPages(count, itemsPerPage);
        }
        // User is not searching, use currentpagereports
        else {
            setTotalPages(allReportsCount, itemsPerPage);
        }
    }, [ itemsPerPage, usingSearchWord, allReportsCount, searchResultData ]);

    // Reset page counter
    useEffect(() => {
        resetPageCounter();
    }, [ itemsPerPage, usingSearchWord, searchInput ]);

    const padDateString = (date: string) => {
        date = date.replace(/\//g, '.')
        if (date[2] !== '.') date = date.padStart(date.length + 1, '0')
        if (date[5] !== '.') date = date.substring(0,3) + '0' + date.substring(3)
        return date;
    }

    const displayBody = () => {
        // User is searching:
        if (usingSearchWord) {
            const dataToDisplay = searchResultData.data?.result ?? mostRecentlyFetchedSearchResult ?? null;
            // Error case:
            if (searchResultData.data === null && searchResultData.error) {
                return <ErrorAlert error={searchResultData.error} />
            }
            // Loading case:
            else if (dataToDisplay === null) {
                return <Loading />
            }
            // No results case:
            else if (dataToDisplay.length === 0) {
                return (
                    <Alert type="info" headText={`Engar niðurstöður fundust fyrir ${searchInput}`} />
                );
            }
            // Show data case:
            return (
                <div>
                    <Table
                        tableSize='lg'
                        data={getDataSlice(dataToDisplay)}
                        columns={[{
                            title: 'Dags. pöntunar',
                            renderCell: ({date}) => padDateString(date),
                            textAlign: 'left',
                        }, {
                            title: 'Tegund',
                            renderCell: ({typeName, type}) => reportTypeNameChanger(typeName ?? type),
                            textAlign: 'left',
                        }, {
                            title: 'Tilvísun',
                            renderCell: ({reference}) => reference,
                            textAlign: 'left',
                        }, {
                            title: 'Leitarorð',
                            renderCell: ({value}) => value,
                            textAlign: 'left',
                        }, {
                            title: '',
                            renderCell: ({value, typeName, type, balls, fileId}) => <Button anchorProps={{href: `${getEventUrl(value, typeName ?? type, balls, fileId)}`}} size='sm' buttonType='secondary'>Skoða</Button>,
                            textAlign: 'right',
                        }
                        ]}
                    />
                </div>
            );
        }
        // User is not searching, show default:
        // Error case:
        if (allReports instanceof Error) {
            return (
                <ErrorAlert error={allReports} />
            );
        }
        // Loading case:
        else if (allReports === null) {
            return <Loading />
        }
        // No results case:
        else if (allReportsCount === 0) {
            return (
                <Alert type="info" headText={`Engar skýrslur fundust fyrir ${nameChanger(model?.id)}`} />
            );
        }
        const dataToDisplay = getDataSlice(allReports);
        if (dataToDisplay.length === 0) return <Loading />
        return (
            <Table
                tableSize='lg'
                data={dataToDisplay}
                columns={[{
                    title: 'Dags. pöntunar',
                    renderCell: ({eventDate}) => eventDate,
                    textAlign: 'left',
                }, {
                    title: 'Tegund',
                    renderCell: ({typeName, type}) => typeName !== null && typeName.length > 0 ? reportTypeNameChanger(typeName) : reportTypeNameChanger(type),
                    textAlign: 'left',
                }, {
                    title: 'Tilvísun',
                    renderCell: ({reference}) => reference,
                    textAlign: 'left',
                }, {
                    title: 'Leitarorð',
                    renderCell: ({searchTerm}) => searchTerm,
                    textAlign: 'left',
                }, {
                    title: 'Notandi',
                    renderCell: ({fullname}) => fullname,
                    textAlign: 'left',
                }, {
                    title: '',
                    renderCell: ({searchTerm, typeName, type, id, fileId}) => (
                        <Button
                            anchorProps={{
                                href: `${getEventUrl(searchTerm , typeName !== null && typeName.length > 0 ? typeName : type, id, fileId)}`,
                                target: '_blank'
                            }}
                            size='sm'
                            buttonType='secondary'
                        >
                            Skoða
                        </Button>
                    ),
                    textAlign: 'right',
                }
                ]}
            />
        );
    }

    const itemCountText = useMemo(() => {
        if (itemCount === null) return null;
        if (itemCount === 0) return 'Engar skýrslur fundust';
        if (itemCount % 10 === 1 && itemCount % 100 !== 11) return `${itemCount} skýrsla fannst`;
        return `${itemCount} skýrslur fundust`;
    }, [itemCount]);

    return (
        <div className="main KCL_reportsPage">
            <div className="section section--primary">
                <div className='shell'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <HeadSection
                                title='Keyptar skýrslur'
                                showRecentReports
                            />
                        </div>
                    </div>
                    {/* SEARCH BAR */}
                    <div className='row'>
                        <div className='col-lg-7 col-sm-12'>
                            <SmallSearch
                                search={searchInput}
                                setSearch={setSearchInput}
                                placeHolder="Leitaðu eftir tegund skýrslu, upprunalegum leitarorðum, tilvísun reiknings og fleira..."
                                id="Search_MyReportsSub"
                            />
                        </div>
                    </div>
                    <div className='section__body'>
                        <div className='content-space-between'>
                            <h2>{nameChanger(model?.id ?? '')}</h2>
                            <p className='paragraph-small--italic'>
                                {itemCountText}
                            </p>
                        </div>
                        <div className='content-space-between'>
                            <p>
                                <Link
                                    onClick={() => window.history.back()}
                                    linkSize='14'
                                    icon='back'
                                >
                                    Fara til baka
                                </Link>
                            </p>
                            <div className="page-size-selector">
                                <span className='paragraph-small--bold'>Sýna:</span>
                                <DropSelect
                                    options={
                                        (itemCount === null)
                                        // No item count option.
                                        ? DEFAULT_DROP_DOWN_ITEMS
                                        // Item count option.
                                        : [...DEFAULT_DROP_DOWN_ITEMS, {
                                            label: 'Allt á síðu',
                                            value: `${itemCount}`
                                        }]
                                    }
                                    defaultValue={itemsPerPage.toString()}
                                    size='lg'
                                    onChange={(option) => {
                                        if (typeof option === 'string') setItemsPerPage(parseInt(option))
                                    }}
                                    className='page-size-select'
                                />
                            </div>
                        </div>
                        <div className='content-body'>
                            {displayBody()}
                            <div className={cx('pagination-wrapper', {'next-page-disabled': isNextPageDisabled})}>
                                <PageCounter />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportsSubPage;