// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import ViewMembershipPage from '../../components/Individual/Hlutafelagathatttaka/ViewMembership';
// Type imports:
import { IdIndividualViewModel } from '../../types/IndividualTypes';

const ViewMembership: React.FC = ({...props}) => {
    return (
        <Entrypoint>
            <ViewMembershipPage model={props as IdIndividualViewModel} />
        </Entrypoint>
    );
}

export default ViewMembership;