// Package imports:
import React, { useEffect, useState } from 'react';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import Label from '../../../ui-elements/Label/Label';
import Link from '../../../ui-elements/Link/Link';
import Loading from '../../../ui-elements/Loading/Loading';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Button from '../../../ui-elements/Button/Button';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { getTableDate, insertDash } from '../../../services/utils';
import { useBuyingProcessUrl } from '../../../services/buyhook';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Fetched } from '../../../types/Types';
import { IKeldanApiResponse, OwnedReport } from '../../../types/KeldanTypes';
import { LegalAdvert, LegalSearchModel } from '../../../types/LegalTypes';
import { ICompanyViewModel } from '../../../types/CompanyTypes';

const CompanyLegal: React.FC<ICompanyViewModel> = ({
    id
}) => {
    const [data, setData] = useState<Fetched<LegalSearchModel>>();
    const [ownedData, setOwnedData] = useState<Fetched<OwnedReport[]>>(null);
    const { tryPurchaseItem, isItemLoading, ModalsAndToasts } = useBuyingProcessUrl(
        (legalAdvert: LegalAdvert, modalReference: string) => {
            const url = `${GET_KELDAN_API_URL()}/Fyrirtaeki/Logbirtingar-Kaupa/${id}?adNumber=${legalAdvert.advertNumber}&reference=${modalReference}`
            return url;
        },
        () => {
            window.setTimeout(() => {
                fetchOwnedData();
            }, 5*1000);
        },
        'Lögbirtingablaðinu'
    );

    // Fetching functions:
    const fetchData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Company/GetCompanyLegal/${id}`;
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (!response.ok) {
                setData(new Error(ErrorMessages.RequestFailed));
                return;
            }
            if (response.redirected) {
                window.location.href = response.url;
            }
            const responseBody: IKeldanApiResponse<LegalSearchModel> = await response.json();
            if (responseBody.success) {
                if (responseBody.result) setData(responseBody.result);
                else if (responseBody.message === "'Ekkert fannst") setData(null);
            } else {
                const { message } = responseBody;
                if (message) {
                    setData(new Error(message));
                } else {
                    setData(new Error(ErrorMessages.ErrorInRequest));
                }
            }
        } catch (e) {
            setData(new Error(ErrorMessages.OtherShort));
        }
    }

    const fetchOwnedData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Company/GetCompanyOwnedLegal/${id}`;
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                }
                const responseBody: IKeldanApiResponse<OwnedReport[]> = await response.json();
                if (responseBody.success) {
                    if (responseBody.result) setOwnedData(responseBody.result);
                } else {
                    const { message } = responseBody;
                    if (message) {
                        setOwnedData(new Error(message));
                    } else {
                        setOwnedData(new Error(ErrorMessages.ErrorInRequest));
                    }
                }
            } else {
                setOwnedData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setOwnedData(new Error(ErrorMessages.OtherShort));
        }
    }

    useEffect(() => {
        fetchData();
        fetchOwnedData();
    }, []);

    const checkIfOwned = (adNumber: string) => {
        if (ownedData !== null && !(ownedData instanceof Error)) {
        	for (const element of ownedData) {
                if (element.tokens.includes(adNumber)) return element.balls
            }
        }
        return 0;
    }

    const numberOfResults = () => {
        if (data instanceof Error || data === undefined || data === null) return undefined;
        if (data.count === 1) {
            return `Leit eftir ${insertDash(id ?? '')} skilaði 1 niðurstöðu`;
        }
        return `Leit eftir ${insertDash(id ?? '')} skilaði ${data.count} niðurstöðum`;
    }
    
    const displayBody = () => {
        if (data instanceof Error) return <Alert type='error' headText={data.message} />;
        if (data === undefined) return <Loading />;
        if (data === null) return <Alert type='info' headText={`Leit eftir ${insertDash(id ?? '')} skilaði engum niðurstöðum`} />;
        return <div>
            {data.ads?.map((item) => (
                <div key={item.advertNumber} className='company-legal-line'>
                    <div>
                        <span className='company-legal-line-date'>{`${getTableDate(item.published, "DD/MM/YYYY", ".")}`} </span>
                        <span className='company-legal-line-text'>{`${item.type}`} </span>
                    </div>
                    {(checkIfOwned(item.advertNumber.toString()) !== 0)
                        ? <Button
                            size='sm'
                            buttonType='secondary'
                            anchorProps={{
                                href: `/Fyrirtaeki/Logbirtingar-Skoda/${id}?eventId=${checkIfOwned(item.advertNumber.toString())}`,
                                target: '_blank'
                            }}
                        >
                            Skoða
                        </Button>
                        : <Button
                            size='sm'
                            buttonType='buy'
                            showLoader={isItemLoading(item)}
                            onClick={() => tryPurchaseItem(item, 'confirm-modal-with-reference')}
                        >
                            Kaupa
                        </Button>
                    }
                </div>
            ))}
            <Link
                linkSize='14'
                url={`/Fyrirtaeki/Logbirtingar/${id}`}
                className='more-link'
                icon='forward'
            >
                Fleiri lögbirtingar
            </Link>
        </div>
    }

    return (
        <DisplayBox 
            className='KCL_company-legal'
            title="Lögbirtingar"
            asideComponent={<Label labelType='origin' text='LÖGB.' fullText='Lögbirtingablaðið' />}
            description={numberOfResults()}
        >
            {displayBody()}
            <ModalsAndToasts />
        </DisplayBox>
    );
}

export default CompanyLegal;