// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import ViewReportComp from '../../components/Property/ViewReport/ViewReportWrapper';


const ViewReport: React.FC<{id?: string}> = ({
    id
}) => {
    return (
        <Entrypoint>
            <ViewReportComp id={id ?? ''} />
        </Entrypoint>
    );
}

export default ViewReport;