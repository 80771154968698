// Package imports:
import React, { memo } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

interface IOwnProps {
    id: string,
    name: string,
    children: any,
    className?: string,
    value: string,
    defaultValue: string,
    onChange(event: any): any,
};

type Props = IOwnProps & InputProps;

const Radio: React.FC<Props> = ({
    id,
    name,
    children,
    className,
    value,
    defaultValue,
    onChange,
    ...props
}) => {

    const handleOnChange = (event: any) => {
        onChange(event);
    };

    return (
        <div
            className={cx('radio', className, {'is-disabled': props.disabled})}
        >
            <input
                id={id}
                type="radio"
                name={name}
                className="radio__input"
                value={value}
                onChange={handleOnChange}
                checked={value === defaultValue}
                {...props}
            />

            <label htmlFor={id} className="radio__label">
                <span className="radio__icon">
                    <span>
                        <FontAwesomeIcon icon={faDotCircle}/>
                    </span>
                </span>

                <span>
                    {children}
                </span>
            </label>
        </div>
    );
};

export default memo(Radio);
