// Package imports:
import React from 'react';
import cx from 'classnames';
// Component imports:
import Icon from '../../Header/CustomIcon/CustomIcon';
// Service imports:
import useComponentVisible from '../../../services/hooks';
// Type imports:
import { IPropertyViewModel } from '../../../types/PropertyTypes';

const PropertyNavBar: React.FC<IPropertyViewModel> = ({
    propertyNumber
}) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible();
    return (
            <div className="KCL_sub-navbar">
                <ul className="mobileMenu">
                    <li>
                        <span className='list-category' id="mobile" onClick={() => setIsComponentVisible(!isComponentVisible)}>
                            Yfirlit <Icon type='dropdown-arrow' />
                        </span>
                        <ul ref={ref} className={cx("mobile dropdown", {"focused": isComponentVisible})}>
                            <li><a href={`/Fasteignir/Yfirlit?fastanr=${propertyNumber}`}>Yfirlit</a></li>
                            <li className='list'>
                                <span>Skýrslur</span>
                                <ul className='list-items'>
                                    <li><a href={`/Fasteignir/Fasteignaskra?fastanr=${propertyNumber}`}>Fasteignaskrá</a></li>
                                    <li><a href={`/Fasteignir/Gangverd?fastanr=${propertyNumber}`}>Verðvísir</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul className='itemList'>
                    <li><a className='list-category' href={`/Fasteignir/Yfirlit?fastanr=${propertyNumber}`}>Yfirlit</a></li>
                    <li>
                        <span className='list-category'>Skýrslur <span className='icon'><Icon type='dropdown-arrow' /></span></span>
                        <ul className="dropdown">
                            <li><a href={`/Fasteignir/Fasteignaskra?fastanr=${propertyNumber}`}>Fasteignaskrá</a></li>
                            <li><a href={`/Fasteignir/Gangverd?fastanr=${propertyNumber}`}>Verðvísir</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
    )
}

export default PropertyNavBar;

