// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import CustomValuationPage from '../../components/CustomValuationPage/CustomValuationPage';

const CustomValuation: React.FC = () => {
    return (
        <Entrypoint>
            <CustomValuationPage />
        </Entrypoint>
    );
}

export default CustomValuation;