// Package imports:
import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
// Type imports:
import { IApiLmdQuestorKeySeries } from '../../../types/HlutabrefTypes';
import { formatNumber, displayQuantityShorthand } from '../../../services/utils';

interface IChartDatum {
    name: string | undefined,
    y: number | undefined
};
interface IChartDataInfo {
    name: string,
    data: IChartDatum[]
};

interface IProps {
    data: IApiLmdQuestorKeySeries[],
    title?: string,
    detailed?: boolean,
    height?: number
};

const FinancialDataChart: React.FC<IProps> = ({
    data,
    title = '',
    height = 250
}) => {

    const divRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const chartDataInfo: IChartDataInfo = {
            name: title,
            data: []
        }
        const mult = data[0]?.NumbersIn ?? 1;
        for (let datum of data) {
            chartDataInfo.data.push({
                name: `${datum.PeriodName} '${datum.FiscalYear && datum.FiscalYear % 100}`,
                y: (datum.KeyValue ?? 0) * (datum.NumbersIn ?? 1) / mult ?? undefined
            });
        }


        if (divRef.current !== null) {
            Highcharts.chart(divRef.current, {
                chart: {
                    height: height,
                    marginTop: 20,
                    reflow: true,
                    style: {
                        fontFamily: "Roboto"
                    },
                    animation: false
                },
                exporting: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: '',
                    align: 'center',
                    x: 60,
                    margin: -14
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                tooltip: {
                    enabled: true,
                    formatter: function() {
                        return `
                            <div class="chart-tooltip">
                                <p class="tooltip-date">${this.point.name}</p>
                                <p class="tooltip-value">${formatNumber(this.y, null, 0)}</p>
                            </div>`;
                    },
                    borderWidth: 0,
                    shadow: false,
                    useHTML: true
                },
                xAxis: {
                    type: 'category',
                    title: {
                        text: ''
                    },
                    labels: {
                        autoRotation: undefined,
                        style: {
                            color: '#7B7C83'
                        }
                    },
                    tickWidth: 0
                },
                yAxis: {
                    title: {
                        text: `x${formatNumber(mult, null, 0)} ${data[0]?.Currency}`,
                        offset: 56,
                        margin: 9
                    },
                    labels: {
                        formatter: function() {
                            return displayQuantityShorthand(this.value as number);
                        }
                    },
                    gridLineDashStyle: 'Dash',
                    gridLineWidth: 1,
                    gridLineColor: '#D3D3D6'
                },
                plotOptions: {
                    column: {
                        enableMouseTracking: true,
                        animation: false,
                        zones: [{
                            value: 0,
                            color: '#DB0033'
                        }, {
                            color: '#48A85D'
                        }]
                    }
                },
                legend: {
                    enabled: true,
                    symbolHeight: 10,
                    symbolWidth: 10,
                    symbolRadius: 0,
                    itemStyle: {
                        fontSize: '13px'
                    }
                },
                responsive: {
                    rules: [{
                        condition: {
                           callback: function() {
                               return window.matchMedia('(max-width: 767px)').matches;
                           }
                        },
                        chartOptions: {
                            chart: {
                                spacingLeft: 0,
                                spacingRight: 5
                            },
                            legend: {
                                margin: 0,
                                itemStyle: {
                                    fontSize: '11px'
                                }
                            },
                            navigator: {
                                margin: 13
                            },
                            xAxis: {
                                labels: {
                                    style: {
                                        fontSize: '9px'
                                    }
                                }
                            },
                            yAxis: {
                                labels: {
                                    style: {
                                        fontSize: '9px'
                                    },
                                    x: -1
                                },
                                title: {
                                    offset: 27,
                                    x: 0,
                                    style: {
                                        fontSize: '10px'
                                    }
                                }
                            }
                        }
                    }]
                },
                series: [{
                    type: 'column',
                    id: 'data-series',
                    name: data[0]?.IsName ?? undefined,
                    data: chartDataInfo.data,
                    yAxis: 0,
                    tooltip: {
                        valueDecimals: 2
                    },
                    color: '#48A85D'
                }]
            }, () => {});
        }
    }, [data, divRef.current]);


    return (
        <div className="KCL_financial-data-chart">
            <div className="chart" ref={divRef}></div>
        </div>
    );
}

export default FinancialDataChart;