// Package imports:
import React from 'react';

interface IProps {
    label?: string,
    name?: string,
    formula?: JSX.Element
}

const FormulaBox: React.FC<IProps> = ({
    label,
    name,
    formula
}) => {
    return (
        <div className='KCL_formula-box'>
            <span className="formula-box__label">{label}</span>

            <div className="formula-box__content">
                {name && <strong>{name} =</strong>}
                <span className="formula-box__formula">{formula}</span>
            </div>
        </div>
    );
}

export default FormulaBox;