// Package imports:
import React, { useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
// Component imports:
import Input from '../../../ui-elements/Input/Formik/Input';
import Button from '../../../ui-elements/Button/Button';
import Alert from '../../../ui-elements/Alert/Alert';
import Recaptcha from '../../../ui-elements/Recaptcha/Recaptcha';
// Service imports:
import { isCompany, isPerson } from '../../../services/utils';
import useSendEmailHook from '../../../services/apiHooks';
// Type imports:
import { Fetched } from '../../../types/Types';



const PantaVerdmatContactForm: React.FC = () => {
    const [captchaToken, setCaptchaToken] = useState<string | null>('');
    const { loading, contactResponse, handleSubmit } = useSendEmailHook(
        'Email/VerdmatsContact',
        captchaToken
    );

    const subFormComponent = useMemo(() => {
        return ((props: { values: IFormValues }) => (
            <SubFormComponent
                contactResponse={contactResponse}
                values={props.values}
                loading={loading}
                setCaptchaToken={(e: string | null ) => setCaptchaToken(e)}
            />
        ))
    }, [ contactResponse, loading ]);

    return (
        <div className='KCL_contact-form'>
            <Formik
                initialValues={{
                    company_name: '',
                    company_ssn: '',
                    description: '',
                    contact_name: '',
                    contact_ssn: '',
                    contact_email: '',
                    contact_phone: ''
                }}
                validationSchema={Yup.object({
                    company_name: Yup.string()
                        .required('Nafn fyrirtækis verður að fylgja'),
                    company_ssn: Yup.string()
                        .required('Kennitala fyrirtækis verður að fylgja')
                        .matches(/^(\d{6}-\d{4})|^(\d+)$/, 'Kennitala má einungis innihalda tölustafi')
                        .min(10, 'Kennitala þarf að innihalda 10 tölustafi')
                        .max(11, 'Kennitala má ekki vera meira en 10 tölustafir')
                        .test('ogild-kennitala', 'Ógild fyrirtækja kennitala', (value) => isCompany(value)),
                    contact_name: Yup.string()
                        .required('Nafn tengiliðar verður að fylgja'),
                    contact_ssn: Yup.string()
                        .required('Kennitala tengiliðar verður að fylgja')
                        .matches(/^(\d{6}-\d{4})|^(\d+)$/, 'Kennitala má einungis innihalda tölustafi')
                        .min(10, 'Kennitala þarf að innihalda 10 tölustafi')
                        .max(11, 'Kennitala má ekki vera meira en 10 tölustafir')
                        .test('ogild-kennitala', 'Ógild einstaklings kennitala', (value) => isPerson(value)),
                    contact_email: Yup.string()
                        .email('Ógilt netfang')
                        .required('Netfang verður að fylgja'),
                    contact_phone: Yup.string()
                        .required('Símanúmer verður að fylgja')
                        .matches(/^[+]?\d+$/, 'Símanúmer má einungis innihalda tölustafi')
                        .min(7, 'Lágmarkslengd eru 7 tölustafir')
                        .max(11, 'Hámarkslengd eru 11 tölustafir'),
                    description: Yup.string()
                        .required("Lýsing verður að fylgja"),
                })}
                onSubmit={handleSubmit}
                component={subFormComponent}
            />
        </div>
    );
}

export default PantaVerdmatContactForm;

interface IFormValues {
    company_name: string,
    company_ssn: string,
    description: string,
    contact_name: string,
    contact_ssn: string,
    contact_email: string,
    contact_phone: string,
}


interface ISubFormProps {
    contactResponse: Fetched<string>
    values: IFormValues,
    loading: boolean,
    setCaptchaToken: (e: string | null) => void
}

const SubFormComponent: React.FC<ISubFormProps> = ({
    contactResponse,
    values,
    loading,
    setCaptchaToken
}) => {
    return (
        <Form>
            <div className="form__body">
                <div className="form__section">
                    <h3>Panta verðmat</h3>
                    <div className="form__row">
                        <div className='form__col'>
                            <Input
                                label="Fyrirtæki"
                                name="company_name"
                                id="company_name"
                                value={values.company_name}
                            />
                        </div>
                        <div className='form__col'>
                            <Input
                                label="Kennitala fyrirtækis"
                                name="company_ssn"
                                id="company_ssn"
                                value={values.company_ssn}
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className='form__col'>
                            <Input
                                inputType="textArea"
                                placeholder="Stutt lýsing á starfsemi fyrirtækis og helstu samkeppnisaðilum (eða sambærilegum fyrirtækjum ef einhver)"
                                name="description"
                                id="description"
                                value={values.description}
                            />
                        </div>
                    </div>

                    <h4>Tengiliður</h4>
                    <div className="form__row">
                        <div className='form__col'>
                            <Input
                                label="Nafn"
                                name="contact_name"
                                id="contact_name"
                                value={values.contact_name}
                            />
                        </div>
                        <div className='form__col'>
                            <Input
                                label="Kennitala"
                                name="contact_ssn"
                                id="contact_ssn"
                                value={values.contact_ssn}
                            />
                        </div>
                    </div>
                    <div className='form__row'>
                        <div className='form__col'>
                            <Input
                                label="Netfang"
                                name="contact_email"
                                id="contact_email"
                                value={values.contact_email}
                            />
                        </div>
                        <div className="form__col">
                            <Input
                                label="Sími"
                                name="contact_phone"
                                id="contact_phone"
                                value={values.contact_phone}
                            />
                        </div>
                        
                    </div>


                    {(contactResponse === null)
                        ? null
                        : (
                            <div className="form__row">
                                <div className="form__col">
                                    {(contactResponse instanceof Error)
                                    ? <Alert type='error' headText={contactResponse.message} />
                                    : <Alert type='info' headText={contactResponse} />}
                                </div>
                            </div>
                        )
                    }
                    <Recaptcha setToken={(e: string | null) => setCaptchaToken(e)} position={'center'} />
                </div>
            </div>
            <div className="form__actions">
                <Button showLoader={loading} buttonType="primary" size="lg">Senda pöntun</Button>
            </div>
        </Form>
    )
}
