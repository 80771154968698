// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import CompanySubPage from '../../components/Company/CompanySubPage/CompanySubPage';
import CompanyLegalLarge from '../../components/Company/CompanyLegal/CompanyLegalLarge';
// Type imports:
import { ICompanyViewModel } from '../../types/CompanyTypes';

const CompanyLegalPage: React.FC<ICompanyViewModel> = ({
    id,
    isAuthenticated,
    isSubscriber
}) => {
    return (
        <Entrypoint>
            <CompanySubPage 
                id={id}
                title='Lögbirtingar'
                isAuthenticated={isAuthenticated}
                isSubscriber={isSubscriber}
                component={
                    <CompanyLegalLarge id={id} isAuthenticated={isAuthenticated} />
                } 
                />
        </Entrypoint>
    );
}

export default CompanyLegalPage;