// Package imports:
import React, { useEffect } from 'react';
// Component imports:
import CalculatedColoredNumber from '../../ui-elements/CalculatedColoredNumber/CalculatedColoredNumber';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import LmdSingleLineTable from '../../ui-elements/Table/LmdSingleLineTable';
// Service imports:
import { formatNumber, getTableDate } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdFundPrice } from '../../types/FundsTypes';

interface IOwnProps {
    symbol: string | null,
    setFundName(name: string | undefined, type: string | undefined): void,
    setFundIssuerName(name: string | undefined): void,
    setFundPdfUrl(name: string | undefined): void,

}
type Props = IDefaultProps & IOwnProps & React.HTMLAttributes<HTMLDivElement>;

const SingleFund: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    symbol = 'frjalsi_1',
    setFundName,
    setFundIssuerName,
    setFundPdfUrl,
    ...props
}) => {
    const [fund] = useApiLmdData<IApiLmdFundPrice[]>(
        `/v1/market_data/v1/funds/${symbol}/prices/`,
        accessToken,
        refreshRateMs
    );

    useEffect(() => {
        const { data } = fund;
        if (data !== null) {
            //setting the title of the singleFundPage
            setFundName(data[0]?.Name?.toString(), data[0]?.FundType?.toString());
            setFundIssuerName(data[0]?.IssuerName ?? '');
            setFundPdfUrl(data[0]?.PdfUrl ?? '');
        }
    }, [fund]);

    const handleData = () => {
        const { data, error } = fund;
        if (data === null) {
            return {
                error,
                data: null
            }
        } else {
            return {
                error,
                data: data[0]
            }
        }
    }

    return (
        <DisplayBox {...props}>
            <LmdSingleLineTable 
                apiData={handleData()}
                columns={[{
                    title: 'Mynt',
                    renderCell: ( {Currency} ) => (Currency),
                    textAlign: 'left'
                }, {
                    title: 'Gengi',
                    renderCell: ( {LastPrice} ) => (formatNumber(LastPrice, '-', 2)),
                    textAlign: 'left'
                }, {
                    title: 'Dagsetning',
                    renderCell: ( {ValueDate } ) => (ValueDate && getTableDate(ValueDate.toString(), 'DD/MM/YYYY', '.')),
                    textAlign: 'left'
                }, {
                    title: '1 Ár',
                    renderCell: ( {Change12m} ) => (
                        <CalculatedColoredNumber
                            currentValue={Change12m}
                            formatting='percent'
                            hasArrow
                            hasColor
                        />
                    ),
                    textAlign: 'left'
                }, {
                    title: '2 Ár',
                    renderCell: ( {Change24m} ) => (
                        <CalculatedColoredNumber
                            currentValue={Change24m}
                            formatting='percent'
                            hasArrow
                            hasColor
                        />
                    ),
                    textAlign: 'left'
                }, {
                    title: '3 Ár',
                    renderCell: ( {Change36m} ) => (
                        <CalculatedColoredNumber
                            currentValue={Change36m}
                            formatting='percent'
                            hasArrow
                            hasColor
                        />
                    ),
                    textAlign: 'left'
                }, {
                    title: '4 Ár',
                    renderCell: ( {Change48m} ) => (
                        <CalculatedColoredNumber
                            currentValue={Change48m}
                            formatting='percent'
                            hasArrow
                            hasColor
                        />
                    ),
                    textAlign: 'left'
                }, {
                    title: '5 Ár',
                    renderCell: ( {Change60m} ) => (
                        <CalculatedColoredNumber
                            currentValue={Change60m}
                            formatting='percent'
                            hasArrow
                            hasColor
                        />
                    ),
                    textAlign: 'left'
                }]}
                tableSize='lg'
            />
        </DisplayBox>
    );
}

export default SingleFund;