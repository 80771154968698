// Package imports:
import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts/highstock';
// Service imports:
import { convertToPercentage } from '../../../services/utils';
// Type imports:
import { IDefaultProps } from '../../../types/Types';

export interface IChartDatum {
    x: number | undefined
    y: number | undefined
};

type columnType = 'column';

export interface IColumnChart {
    type: columnType,
    name: string, 
    color?: string,
    data: IChartDatum[]
}

interface IOwnProps {
    title?: string,
    data: Array<IColumnChart>,
    height?: number,
    showHeader?: boolean,
    grayBackground?: boolean
}


type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const ColumnChart: React.FC<Props> = ({
    title,
    data,
    height = 255,
    accessToken,
    refreshRateMs,
    grayBackground = false,
    ...props
}) => {

    const divRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (divRef.current !== null) {
            Highcharts.stockChart(divRef.current, {
                chart: {
                    height: height,
                    reflow: true,
                    style: {
                        fontFamily: "Roboto"
                    },
                    plotBackgroundColor: {
                        linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                        stops: [
                            [0.05, grayBackground ? '#F8F8FA' : '#FFF'],
                            [0.95, grayBackground ? '#FFF' : '#F8F8FA']
                        ]
                    },
                    backgroundColor: grayBackground ? '#F8F8FA' : '#FFFFFF'
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            x: 30,
                            y: 36
                        }
                    }
                },
                credits: {
                    enabled: false,
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                
                tooltip: {
                    enabled: true,
                    pointFormatter: function() {
                        return this.series.name + ': ' + convertToPercentage((this.y  ?? 1)/ 100, true, 2);
                    },
                    borderWidth: 0,
                    shadow: false,
                    useHTML: true,
                },
                xAxis: [{
                    type: 'datetime',
                    minTickInterval: 365*24*60*60*1000,
                    tickWidth: 0,
                    width: '100%'
                }],
                yAxis: [{
                    labels: {
                        formatter: function() {
                            return this.value.toString() + '%';
                        },
                    },
                    gridLineDashStyle: 'Dash',
                    gridLineWidth: 1,
                    gridLineColor: '#D3D3D6',
                    opposite: false,
                    height: '100%',
                }],
                legend: {
                    enabled: true,
                    align: 'center',
                    verticalAlign: 'bottom',
                    itemStyle: {
                        color: '#4F5059',
                        fontSize: '13px',
                        fontWeight: '400',
                        lineHeight: '15px',
                    },
                    symbolWidth: 10,
                    x: 5,
                    y: 20
                },
                rangeSelector: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            callback: function() {
                                return window.matchMedia('(max-width: 767px)').matches;
                            }
                        },
                        chartOptions: {
                            chart: {
                                spacingLeft: 0,
                                spacingRight: 5,
                            },
                            legend: {
                                y: 20,
                                itemStyle: {
                                    fontSize: '11px',
                                    lineHeight: '13px'
                                }
                            },
                            xAxis: {
                                labels: {
                                    style: {
                                        fontSize: '9px'
                                    }
                                }
                            },
                            yAxis: {
                                labels: {
                                    style: {
                                        fontSize: '9px'
                                    },
                                    x: -1
                                }
                            }
                        }
                    }]
                },
                series: data
            }, () => {});
        }
    }, [data, divRef.current]);
    return (
        <div className="chart" ref={divRef}></div>
    );
}

export default ColumnChart;