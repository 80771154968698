// Package imports:
import React from 'react';
// Component imports:
import FinancialDataChart from '../Charts/FinancialDataChart/FinancialDataChart';
import Alert from '../../ui-elements/Alert/Alert';
import Loading from '../../ui-elements/Loading/Loading';
// Service imports:
import { useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { ApiLmdData, IDefaultProps } from '../../types/Types';
import { IApiLmdQuestorKeySeries } from '../../types/HlutabrefTypes';

type Periods = 'quarters' | 'years';

interface IOwnProps {
    symbol: string,
    periods: Periods
}

type Props = IOwnProps & IDefaultProps;

const FinancialData: React.FC<Props> = ({
    symbol,
    periods,
    accessToken,
    refreshRateMs
}) => {
    const [data1] = useApiLmdData<IApiLmdQuestorKeySeries[]>(
        `/v1/market_data/v1/questor/key_series/?ticker=${symbol}&key_id=910100&periods=[${periods === 'quarters' ? 'Q1;Q2;Q3;Q4' : 'Y'}]`,
        accessToken,
        refreshRateMs
    );
    const [data2] = useApiLmdData<IApiLmdQuestorKeySeries[]>(
        `/v1/market_data/v1/questor/key_series/?ticker=${symbol}&key_id=100000&periods=[${periods === 'quarters' ? 'Q1;Q2;Q3;Q4' : 'Y'}]`,
        accessToken,
        refreshRateMs
    );

    const displayData = (data: ApiLmdData<IApiLmdQuestorKeySeries[]>) => {
        if (data.error instanceof Error) {
            return <Alert type="error" headText={data.error.message} />;
        }
        if (data.data === null) {
            return <Loading />;
        }
        return <FinancialDataChart data={data.data} />;
    }

    return (
        <div className="KCL_financial-data">
            {displayData(data1)}
            {displayData(data2)}
        </div>
    );
}

export default FinancialData;