// Package imports:
import React from 'react';
// Component imports:
import Tabs from '../../ui-elements/Tabs/Tabs';
import VehicleSearch from './VehicleSearch/VehicleSearch';
import ConstructionsSearch from './ConstructionsSearch/ConstructionsSearch';
import SsnSearch from './SsnSearch/SsnSearch';
import HistorySearch from './HistorySearch/HistorySearch';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';

interface IProps {
    isAuthenticated: boolean,
    bondsAllowed: boolean,
    individualAssetsAllowed: boolean
}

const Okutaeki: React.FC<IProps> = ({
    isAuthenticated,
    bondsAllowed,
    individualAssetsAllowed
}) => {
    return (
        <div className="main KCL_Okutaeki">
            <div className='section__body'>
                <div className='shell'>
                    <HeadSection
                        title='Ökutæki'
                        showRecentReports={isAuthenticated}
                    />
                    <Tabs  
                        tabsSize="lg"
                        saveTabToUrl
                        tabs={[{
                            id: "Okutaekjaskra",
                            label: "Ökutækjaskrá",
                            component: <VehicleSearch isAuthenticated={isAuthenticated} bondsAllowed={bondsAllowed} />
                        }, {
                            id: "Vinnuvelaskra",
                            label:"Vinnuvélaskrá",
                            component: <ConstructionsSearch isAuthenticated={isAuthenticated} />
                        }, {
                            id: "Eignastada",
                            label:"Eignastaða",
                            component: <SsnSearch
                                isAuthenticated={isAuthenticated}
                                individualAssetsAllowed={individualAssetsAllowed}
                            />
                        }, {
                            id: "Ferilskra",
                            label:"Ferilskrá",
                            component: <HistorySearch isAuthenticated={isAuthenticated} />
                        }]}
                    />
                </div>
            </div>
        </div>
    )
}

export default Okutaeki;