// Package imports:
import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
// Component imports:
import Alert from '../../ui-elements/Alert/Alert';
import Loading from '../../ui-elements/Loading/Loading';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import VehicleReportBody from './VehicleReportBody';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
// Service imports:
import { GET_KELDAN_API_URL } from '../../services/config';
import { ErrorMessages } from '../../services/errorMessages';
// Type imports:
import { VehicleSearchResult, VehicleViewModel } from '../../types/VehicleTypes';
import { IEventReport } from '../../types/KeldanTypes';
import { Fetched } from '../../types/Types';

interface IProps {
	model?: VehicleViewModel
}

const ViewVehicleProfile: React.FC<IProps> = ({
	model
}) => {
    const [data, setData] = useState<Fetched<IEventReport<VehicleSearchResult>>>(null);

    const fetchData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Vehicle/GetVehicleReport/${model?.id}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                }
                const body : IEventReport<VehicleSearchResult> = await response.json();
                setData(body);
            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setData(new Error(ErrorMessages.NetworkError));
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const displayBody = () => {
        if (data instanceof Error) {
            return <DisplayBox>
                <Alert type='error' headText={data.message} />
            </DisplayBox>
        }
        if (data === undefined || data === null) {
            return <DisplayBox>
                <Loading />
            </DisplayBox>
        }
        return <VehicleReportBody eventId={(model?.id)?.toString()} data={data} />
    }

    return (
        <div className="main KCL_vehicle-profile">
            <div className='shell'>
                <HeadSection
                    title='Ökutækjaskýrsla'
                    showRecentReports
                />
                {displayBody()}
            </div>
        </div>
    )
}
export default ViewVehicleProfile;