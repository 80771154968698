// Package imports:
import React from 'react';
// Component import.
import Search from '../../components/Legal/LegalSearch';
import Entrypoint from '../Entrypoint';
// Type imports:
import { IisAuthenticated } from '../../types/Types';

const LegalSearchPage: React.FC<IisAuthenticated> = ({
    isAuthenticated
}) => {
    return (
        <Entrypoint>
            <Search isAuthenticated={isAuthenticated ?? false}/>
        </Entrypoint>
    );
}

export default LegalSearchPage;