// Package imports:
import React from "react";
import Bugsnag from "@bugsnag/js";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
// Component imports:
import Button from "../../../ui-elements/Button/Button";
import Input from "../../../ui-elements/Input/Formik/Input";
import Link from "../../../ui-elements/Link/Link";
import ExampleReportButton from "../../ExampleReportButton/ExampleReportButton";
import LockIcon from "../../../ui-elements/LockIcon/LockIcon";
import Tooltip from "../../../ui-elements/Tooltip/Tooltip";
// Service imports:
import { GET_KELDAN_API_URL } from "../../../services/config";
import { isSSN, stripSSN } from "../../../services/utils";
import { useBuyingProcess } from "../../../services/buyhook";
import { ErrorMessages } from "../../../services/errorMessages";
// Type imports:
import { IKeldanApiContact } from "../../../types/KeldanTypes";

interface IProps {
    isAuthenticated: boolean
}

const ConstructionsSearch: React.FC<IProps> = ({
    isAuthenticated
}) => {
    const { tryPurchaseItem, tryToBuyItem, resetState, ModalsAndToasts } = useBuyingProcess(async (values: IFormValues, modalReference) => {
        try {
            const requestQuery = new URLSearchParams({
                Ssn: stripSSN(values.ssn) ?? '',
                Registration: values.skraningarnr,
                reference: modalReference
            });
            const url = `${GET_KELDAN_API_URL()}/Vehicle/ConstructionsSearch`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const responseBody: IKeldanApiContact = await response.json();
                    const { message } = responseBody;
                    
                    if (message === ErrorMessages.NoCard) {
                        resetState('no card');
                    }
                    else if (message) {
                        resetState({
                            type: 'alert',
                            headText: message
                        });
                    }
                    else {
                        resetState('bad request');
                    }
                }
            } else {
                resetState('response not ok');
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            resetState('network error');
        }
    }, 'vinnuvélaskrá');

    const displayForm = (status: 'enabled' | 'disabled') => {
        return (
            <Formik
                initialValues={{
                    ssn: '',
                    skraningarnr: '',
                    tilvisun: '',
                }}
                validationSchema={Yup.object({
                    ssn: Yup.string()
                            .matches(/^(\d{6}-\d{4})|^(\d+)$/, 'Kennitala má einungis innihalda tölustafi')
                            .min(10, 'Kennitala þarf að innihalda 10 tölustafi')
                            .max(11, 'Kennitala má ekki vera meira en 10 tölustafir')
                            .test('ssn-test', 'Ógild kennitala', (value) => isSSN(value, true)),
                    skraningarnr: Yup.string(),
                    tilvisun: Yup.string(),
                })}
                onSubmit={(values) => tryPurchaseItem(
                    values,
                    'confirm-modal-with-reference',
                    values.tilvisun
                )}
                component={(props: { values: IFormValues }) => (
                    <SubFormComponent
                        values={props.values}
                        loading={(tryToBuyItem !== null)}
                        disabled={status === 'disabled'}
                    />
                )}
            />
        )
    }

    return (
        <div className="Okutaekjaskra">
            <div className="section__body">
                <div className='KCL_vehicle-search-form'>
                    <h3>
                        {!isAuthenticated && <LockIcon size='lg' />}
                        <span>Uppfletting í Vinnuvélaskrá</span>
                    </h3>
                    <div className='search-form-description paragraph'>
                        Með því að slá inn skráningarnúmer eða kennitölu eiganda er hægt að kaupa upplýsingar um vinnuvélar. <Link linkSize="18" url="/Verdskra#verdskra">Sjá verðskrá.</Link>
                    </div>
                    {isAuthenticated
                        ? displayForm('enabled')
                        : <Tooltip
                            tooltip={
                                <span>
                                    Þú þarft að skrá þig inn og vera með skráð kreditkort til að nýta þessa þjónustu.
                                </span>
                            }
                            text={displayForm('disabled')}
                        />
                    }
                    <div className="example-reports-wrapper">
                        <ExampleReportButton size='lg' reportType='construction' />
                        <ExampleReportButton size='lg' reportType='constructions-owned' />
                    </div>
                </div>
            </div>
            <ModalsAndToasts />
        </div>
    );
}

export default ConstructionsSearch;

interface IFormValues {
    ssn: string,
    skraningarnr: string,
    tilvisun: string,
}

interface ISubFormProps {
    values: IFormValues,
    loading: boolean,
    disabled: boolean
}

const SubFormComponent: React.FC<ISubFormProps> = ({
    values,
    loading,
    disabled
}) => {
    return (
        <Form>
            <div className="form__body">
                <div className="form__section">
                    <div className="form__row">
                        <div className="form__col">
                            <Input
                                label="Kennitala"
                                name="ssn"
                                id="ssn"
                                value={values.ssn}
                                disabled={(values.skraningarnr !== '') || disabled}
                            />
                        </div>
                        <div className="form__col">
                            <Input
                                label="Skráningarnúmer"
                                name="skraningarnr"
                                id="skraningarnr"
                                value={values.skraningarnr}
                                disabled={(values.ssn !== '') || disabled}
                            />
                        </div>
                        <div className="form__col">
                            <Input
                                label="Tilvísun á reikning"
                                name="tilvisun"
                                id="tilvisun"
                                value={values.tilvisun}
                                disabled={disabled}
                            />
                        </div>
                        <div className="form__col actions">
                            <div className="form__actions">
                                <Button
                                    showLoader={loading}
                                    buttonType="buy"
                                    size="lg"
                                    disabled={disabled}
                                >
                                    Kaupa
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
}

