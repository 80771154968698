// Package imports:
import React, { createContext, useEffect, useState } from 'react';
// Type imports:
import { IUserInfo } from '../types/Types';

export const UserInfoContext = createContext<IUserInfo | undefined>(undefined)//{ isAuthenticated: true, name: 'i@i.ii' })//undefined);

interface IProps {
    userInfo?: IUserInfo
}

const UserInfoContextProvider: React.FC<IProps> = ({
    userInfo,
    children
}) => {
    const [ userInfoState, setUserInfo ] = useState<IUserInfo | undefined>(userInfo)
    useEffect(() => setUserInfo(userInfo), [userInfo]);

    return (
        <UserInfoContext.Provider value={userInfoState}>
            {children}
        </UserInfoContext.Provider>
    );
}

export default UserInfoContextProvider;
