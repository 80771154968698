// Package imports:
import React from 'react';
// Component imports:
import ReportsList from '../ReportsList/ReportsList';
import GangverdList from '../GangverdList/GangverdList';
import PropertyNavBar from '../PropertyNavBar/PropertyNavBar';
// Type imports:
import { IPropertyViewModel } from '../../../types/PropertyTypes';

const PropertyProfile: React.FC<IPropertyViewModel> = ({
    propertyNumber,
    bondsAllowed
}) => {
    return (
        <div className='main KCL_property-page'>
            <div className='main__inner-fluid'>
                <PropertyNavBar
                    propertyNumber={propertyNumber}
                />
                <div className='shell'>
                    <div className='property-page-body'>
                        <ReportsList
                            propertyNumber={propertyNumber}
                            bondsAllowed={bondsAllowed}
                        />
                        <GangverdList
                            propertyNumber={propertyNumber}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PropertyProfile;