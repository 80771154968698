// Package imports:
import React from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../ui-elements/Link/Link';
import VerticalTable from '../../ui-elements/Table/VerticalTable';
import Table from '../../ui-elements/Table/Table';
import Alert from '../../ui-elements/Alert/Alert';
// Type imports:
import { IEventReport } from '../../types/KeldanTypes';
import { VehicleSearchResult } from '../../types/VehicleTypes';

interface IProps {
    eventId?: string,
    data: IEventReport<VehicleSearchResult>,
    isExample?: boolean
}

const VehicleReportBody: React.FC<IProps> = ({
    eventId,
    data,
    isExample = false
}) => {
    const padDateString = (date?: string) => {
		if (!date) {
            return '-';
        }
        date = date.replaceAll('/', '.')
		if (date[2] !== '.') date = date.padStart(date.length + 1, '0')
		if (date[5] !== '.') date = date.substring(0,3) + '0' + date.substring(3)
		return date;
	}

    return (
        <>
            <DisplayBox
                title="Grunnupplýsingar"
                asideComponent={
                    <div className='report-aside-component'>
                        <div className='link-wrapper'>
                            <Link
                                targetBlank
                                url={`/Pdf/Okutaekjaskra/${eventId}`}
                                disabled={isExample}
                                icon='pdf'
                            >
                                Sækja skýrslu
                            </Link>
                        </div>
                        <div className='italic-text'>
                            Skýrsla keypt: {data.eventDate}
                        </div>
                    </div>
                }
                className="vehicle-report-box"
                grayBackground={isExample}
            >
                {/* Grunnupplýsingar */}
                <div className='table-section'>
                    <div className='vertical-table-wrapper'>
                        <VerticalTable
                            fallbackSymbol
                            data={data.overview?.car ? [data.overview.car] : []}
                            columns={[{
                                title: 'Eigandi',
                                renderCell: ({owners}) => (owners ? owners[0]?.fullname : null),
                            }, {
                                title: 'Kennitala',
                                renderCell: ({owners}) => (owners ? owners[0]?.persidno : null),
                            }, {
                                title: 'Lögheimili',
                                renderCell: ({owners}) => (owners ? owners[0]?.address : null),
                            }, {
                                title: 'Tegund',
                                renderCell: ({make, vehcom}) => (make + ' ' + vehcom),
                            }, {
                                title: 'Árgerð',
                                renderCell: ({modelyear}) => (modelyear),
                            }, {
                                title: 'Skráningarnúmer',
                                renderCell: ({regno}) => (regno),
                            }, {
                                title: 'Fastanúmer',
                                renderCell: ({permno}) => (permno),
                            }, {
                                title: 'Framleiðandi',
                                renderCell: ({manufacturer}) => (manufacturer),
                            }, {
                                title: 'Framleiðsluland',
                                renderCell: ({country}) => (country),
                            }
                            ]}
                            tableSize="lg"
                        />
                        <VerticalTable
                            fallbackSymbol
                            data={data.overview?.car ? [data.overview.car] : []}
                            columns={[{
                                title: 'Framleiðsluár',
                                renderCell: ({productyear}) => (productyear),
                            }, {
                                title: 'Nýskráningardagur',
                                renderCell: ({firstregdate}) => (firstregdate),
                            }, {
                                title: 'Næsta aðalskoðun',
                                renderCell: ({nextinspectiondate}) => (nextinspectiondate),
                            }, {
                                title: 'Litur',
                                renderCell: ({color}) => (color),
                            }, {
                                title: 'Staða',
                                renderCell: ({vehiclestatus}) => (vehiclestatus),
                            }, {
                                title: 'Veðbönd',
                                renderCell: ({hasEncumbrances}) => ((hasEncumbrances === '1') ? 'Já' : 'Nei'),
                            }, {
                                title: 'Númerastaða	',
                                renderCell: ({platestatus}) => (platestatus),
                            }, {
                                title: 'Tryggingar',
                                renderCell: ({insurancestatus}) => ((insurancestatus === '1') ? 'Já' : 'Nei'),
                            }, {
                                title: 'Tryggingafélag',
                                renderCell: ({insurancecompany}) => (insurancecompany),
                            }
                            ]}
                            tableSize="lg"
                        />
                    </div>
                </div>

                {/* Nánari upplýsingar */}
                <div className='table-section'>
                    <h4>Nánari upplýsingar</h4>
                    <div className='vertical-table-wrapper'>
                        <VerticalTable
                            fallbackSymbol
                            data={data.overview?.car ? [data.overview.car] : []}
                            columns={[{
                                title: 'Verksmiðjunúmer',
                                renderCell: ({vin}) => (vin),
                            }, {
                                title: 'Gerðarnúmer',
                                renderCell: ({typeno}) => (typeno),
                            }, {
                                title: 'Gerðarviðurkenningarnr.',
                                renderCell: ({typeapproval}) => (typeapproval),
                            }, {
                                title: 'Gerðarviðurkenningarviðb.',
                                renderCell: ({typeapprovalextension}) => (typeapprovalextension),
                            }, {
                                title: 'ESB Gerðarviðurkenningarnr.',
                                renderCell: ({eutypeapproval}) => (eutypeapproval),
                            }, {
                                title: 'Skattflokkur',
                                renderCell: ({taxgroup}) => (taxgroup),
                            }, {
                                title: 'Forskráningardagur',
                                renderCell: ({preregdate}) => (preregdate),
                            }, {
                                title: 'Afskráningardagur',
                                renderCell: ({deregdate}) => (deregdate),
                            }, {
                                title: 'Endurskráningardagur',
                                renderCell: ({reregdate}) => (reregdate),
                            }
                            ]}
                            tableSize="lg"
                        />
                        <VerticalTable
                            fallbackSymbol
                            data={data.overview?.car ? [data.overview.car] : []}
                            columns={[{
                                title: 'Kaupdagsetning',
                                renderCell: ({owners}) => {
                                    if(owners){
                                        const item = owners.find(x => x.current === '1');
                                        return item?.purchasedate;
                                    }
                                    else return null;
                                },
                            }, {
                                title: 'Skráning í umferð',
                                renderCell: ({firstregdate}) => (firstregdate),
                            }, {
                                title: 'Ökutækisflokkur',
                                renderCell: ({technical}) => (technical.vehgroup),
                            }, {
                                title: 'Breytt bifreið',
                                renderCell: ({rebuilt}) => (rebuilt === "1" ? 'Já' : 'Nei'),
                            }, {
                                title: 'Torfærubifreið',
                                renderCell: ({offroad}) => (offroad === "1" ? 'Já' : 'Nei'),
                            }, {
                                title: 'Skráningarflokkur',
                                renderCell: ({regtype}) => (regtype),
                            }, {
                                title: 'Tjónabifreið',
                                renderCell: ({hasdisasters}) => (hasdisasters === "1" ? 'Já' : 'Nei'),
                            }, {
                                title: 'Viðgerð tjónabifreið',
                                renderCell: ({fixed}) => (fixed === "1" ? 'Já' : 'Nei'),
                            }, {
                                title: 'Tegund tjónabifreiðar',
                                renderCell: ({disastertype}) => (disastertype),
                            }
                            ]}
                            tableSize="lg"
                        />
                    </div>
                </div>

                {/* Tæknilegar upplýsingar */}
                <div className='table-section'>
                    <h4>Tæknilegar upplýsingar</h4>
                    <div className='vertical-table-wrapper'>
                        <VerticalTable
                            fallbackSymbol
                            data={data.overview?.car?.technical ? [data.overview.car.technical] : []}
                            columns={[{
                                title: 'Eiginþyngd',
                                renderCell: ({mass}) => (mass.massinro + ' kg'),
                            }, {
                                title: 'Heildarþyngd',
                                renderCell: ({mass}) => (mass.massladen + ' kg'),
                            }, {
                                title: 'Heildarþyngd ása',
                                renderCell: ({mass}) => {
                                    let sum = 0;
                                    if(mass.massdaxle1) sum += parseInt(mass.massdaxle1);
                                    if(mass.massdaxle2) sum += parseInt(mass.massdaxle2);
                                    if(mass.massdaxle3) sum += parseInt(mass.massdaxle3);
                                    if(mass.massdaxle4) sum += parseInt(mass.massdaxle4);
                                    if(mass.massdaxle5) sum += parseInt(mass.massdaxle5);
                                    return sum.toString() + ' kg';
                                },
                            }, {
                                title: 'Burðargeta ökutækis',
                                renderCell: ({mass}) => (mass.masscapacity + ' kg'),
                            }, {
                                title: 'Þyngd óhemlaðs eftirvagns',
                                renderCell: ({mass}) => (mass.massoftrunbr + ' kg'),
                            }, {
                                title: 'Þyngd hemlaðs eftirvagns',
                                renderCell: ({mass}) => (mass.massoftrbr + ' kg'),
                            }, {
                                title: 'Lengd',
                                renderCell: ({size}) => (size.length + ' mm'),
                            }, {
                                title: 'Breidd',
                                renderCell: ({size}) => (size.width + ' mm'),
                            }, {
                                title: 'Hæð',
                                renderCell: ({size}) => (size.height + ' mm'),
                            }, {
                                title: 'Dyrafjöldi',
                                renderCell: ({doorsno}) => (doorsno),
                            } , {
                                title: 'Farþegar hjá ökumanni',
                                renderCell: ({passbydr}) => (passbydr),
                            }, {
                                title: 'Farþegafjöldi',
                                renderCell: ({pass}) => (pass),
                            }
                            ]}
                            tableSize="lg"
                        />
                        <VerticalTable
                            fallbackSymbol
                            data={data.overview?.car?.technical ? [data.overview.car.technical] : []}
                            columns={[{
                                title: 'Sætafjöldi',
                                renderCell: ({seatno}) => (seatno),
                            }, {
                                title: 'Gírafjöldi',
                                renderCell: ({noofgears}) => (noofgears),
                            }, {
                                title: 'Orkugjafi',
                                renderCell: ({engine}) => (engine),
                            }, {
                                title: 'Hámarks afköst',
                                renderCell: ({maxnetpow}) => (maxnetpow + ' kW'),
                            }, {
                                title: 'Vélargerð',
                                renderCell: ({workingpr}) => (workingpr),
                            }, {
                                title: 'Vélarnúmer',
                                renderCell: ({enginecode}) => (enginecode),
                            }, {
                                title: 'Slagrými',
                                renderCell: ({capacity}) => (capacity + ' cm3'),
                            }, {
                                title: 'CO2 Útblástur',
                                renderCell: ({co2}) => (co2 + ' g/km'),
                            }, {
                                title: 'Hjólbarðastærð',
                                renderCell: ({tyre}) => (tyre.tyreaxle1),
                            }, {
                                title: 'Felgustærð',
                                renderCell: ({axle}) => (axle.wheelaxle1),
                            }, {
                                title: 'Gerð yfirbyggingar',
                                renderCell: ({typeofbody}) => (typeofbody),
                            }
                            ]}
                            tableSize="lg"
                        />
                    </div>
                </div>

                {/* Skoðanir */}
                <div className="table-section">
                    <h4>Skoðanir</h4>
                    <Table
                        data={data.overview?.car?.inspections}
                        columns={[{
                            title: 'Skoðunarstaður',
                            renderCell: ({station}) => (station),
                            textAlign: 'left'
                        }, {
                            title: 'Tegund',
                            renderCell: ({type}) => (type),
                            textAlign: 'left'
                        }, {
                            title: 'skoðunardagur',
                            renderCell: ({date}) => (date),
                        }, {
                            title: 'staða mælis',
                            renderCell: ({odometer}) => (odometer),
                        }, {
                            title: 'Niðurstaða',
                            renderCell: ({result}) => (result),
                        }]}
                        tableSize="lg"
                    />
                </div>

                {/* Eigendur */}
                <div className="table-section">
                    <h4>Eigendasaga</h4>
                    <Table
                        data={data.overview?.car?.owners}
                        columns={[{
                            title: 'Eigandi',
                            renderCell: ({fullname}) => (fullname),
                            textAlign: 'left'
                        }, {
                            title: 'Kennitala',
                            renderCell: ({persidno}) => (persidno),
                            textAlign: 'left'
                        }, {
                            title: 'Póstnúmer',
                            renderCell: ({postalcode}) => (postalcode),
                        }, {
                            title: 'Kaupdagur',
                            renderCell: ({purchasedate}) => (purchasedate),
                        }, {
                            title: 'Móttökudagur',
                            renderCell: ({receptiondate}) => (receptiondate),
                        }]}
                        tableSize="lg"
                    />
                </div>

                {/* Umráðasaga */}
                <div className="table-section">
                    <h4>Umráðasaga</h4>
                    <Table
                        data={data.overview?.car?.operators}
                        columns={[{
                            title: 'Umráðamaður',
                            renderCell: ({fullname}) => (fullname),
                            textAlign: 'left'
                        }, {
                            title: 'Kennitala',
                            renderCell: ({persidno}) => (persidno),
                            textAlign: 'left'
                        }, {
                            title: 'byrjar',
                            renderCell: ({startdate}) => (startdate),
                        }, {
                            title: 'Lýkur',
                            renderCell: ({enddate}) => (enddate),
                        }]}
                        tableSize="lg"
                    />
                </div>
            </DisplayBox>
            {data.overview?.bondDetails?.bondDetails?.bondDetails?.bonds &&
                <>
                    <h2>Veðbandayfirlit</h2>
                    <DisplayBox className='vehicle-report-box'>
                        {data.overview.bondDetails.bondDetails.bondDetails.bonds.map((bond) => (
                            <div className='report-section' key={bond.id}>
                                <h4>{bond.number}. veðréttur</h4>
                                <div className='vertical-table-wrapper'>
                                    <VerticalTable
                                        data={[bond]}
                                        columns={[{
                                            title: 'Upphæð',
                                            renderCell: ({amount, currency}) => `${amount} ${currency}`
                                        }, {
                                            title: 'Myntgengi',
                                            renderCell: ({currency_rate}) => currency_rate
                                        }, {
                                            title: 'Númer',
                                            renderCell: ({id}) => id
                                        }, {
                                            title: 'Tegund',
                                            renderCell: ({type}) => type
                                        }, {
                                            title: 'Verðbinding',
                                            renderCell: ({index_status}) => index_status
                                        }]}
                                        tableSize='lg'
                                    />
                                    <VerticalTable
                                        data={[bond]}
                                        columns={[{
                                            title: 'Vísitölustig',
                                            renderCell: ({index_state}) => index_state
                                        }, {
                                            title: 'Veðhafi',
                                            renderCell: ({loaner}) => loaner
                                        }, {
                                            title: 'Útgáfudagur',
                                            renderCell: ({published}) => padDateString(published)
                                        }, {
                                            title: 'Móttökudagur',
                                            renderCell: ({received}) => padDateString(received)
                                        }]}
                                        tableSize='lg'
                                    />
                                </div>
                            </div>  
                        ))}
                    </DisplayBox>
                </>
            }
            <Alert
                type='info'
                headText='Athugið'
                text={
                    <>
                        <p>Birtar upplýsingar eru sóttar með tengingu við Ökutækjaskrá. 
                        Allar upplýsingar eru veittar samkvæmt starfsreglum Samgöngustofu og í samræmi við lög og reglur um persónuvernd og meðferð persónuupplýsinga.
                        </p>
                        <p>
                            Reynt er að tryggja að upplýsingar séu réttar en Keldan getur ekki ábyrgst áreiðanleika þeirra.
                        </p>
                    </>
                }
            />
        </>
    );
}

export default VehicleReportBody;