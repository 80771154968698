// Package imports:
import React, { useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
// Component imports:
import Input from '../../../ui-elements/Input/Formik/Input';
import Button from '../../../ui-elements/Button/Button';
import Alert from '../../../ui-elements/Alert/Alert';
import Recaptcha from '../../../ui-elements/Recaptcha/Recaptcha';
// Service imports:
import { isCompany, isPerson } from '../../../services/utils';
import useSendEmailHook from '../../../services/apiHooks';
// Type imports:
import { Fetched } from '../../../types/Types';

const ExcellentContactForm: React.FC = () => {
    const [captchaToken, setCaptchaToken] = useState<string | null>('');
    const { loading, contactResponse, handleSubmit } = useSendEmailHook('Email/ExcellentOrder', captchaToken);


    const subFormComponent = useMemo(() => {
        return ((props: { values: IFormValues }) => (
            <SubFormComponent
                contactResponse={contactResponse}
                loading={loading}
                values={props.values}
                setCaptchaToken={setCaptchaToken}
            />
        ))
    }, [ contactResponse, loading ]);

    return (
        <div className='KCL_contact-form'>
            <Formik
                initialValues={{
                    company: '',
                    companySSN: '',
                    name: '',
                    contactSSN: '',
                    email: '',
                }}
                validationSchema={Yup.object({
                    company: Yup.string()
                        .required('Nafn fyrirtækis verður að fylgja'),
                    companySSN: Yup.string()
                        .required('Kennitala fyrirtækis verður að fylgja')
                        .matches(/^(\d{6}-\d{4})|^(\d+)$/, 'Kennitala má einungis innihalda tölustafi')
                        .min(10, 'Kennitala þarf að innihalda 10 tölustafi')
                        .max(11, 'Kennitala má ekki vera meira en 10 tölustafir')
                        .test('ogild-kennitala', 'Ógild fyrirtækja kennitala', (value) => isCompany(value)),
                    name: Yup.string()
                        .required('Nafn tengiliðar verður að fylgja'),
                    contactSSN: Yup.string()
                        .required('Kennitala tengiliðar verður að fylgja')
                        .matches(/^(\d{6}-\d{4})|^(\d+)$/, 'Kennitala má einungis innihalda tölustafi')
                        .min(10, 'Kennitala þarf að innihalda 10 tölustafi')
                        .max(11, 'Kennitala má ekki vera meira en 10 tölustafir')
                        .test('ogild-kennitala', 'Ógild einstaklings kennitala', (value) => isPerson(value)),
                    email: Yup.string()
                        .email('Ógilt netfang')
                        .required('Netfang verður að fylgja'),
                })}
                onSubmit={handleSubmit}
                component={subFormComponent}
            />
        </div>
    );
}

export default ExcellentContactForm;

interface IFormValues {
    company: string,
    companySSN: string,
    name: string,
    contactSSN: string,
    email: string,
}


interface ISubFormProps {
    contactResponse: Fetched<string>
    loading: boolean,
    values: IFormValues,
    setCaptchaToken: (e: string | null) => void
}

const SubFormComponent: React.FC<ISubFormProps> = ({
    contactResponse,
    loading,
    values,
    setCaptchaToken
}) => {
        return (
            <Form>
                <div className="form__body">
                    <div className="form__section">
                        <h3>Panta vottun</h3>
                        <div className="form__row">
                            <div className="form__col">
                                <Input 
                                    label="Fyrirtæki"
                                    name="company"
                                    id="company"
                                    value={values.company}
                                />
                            </div>
                            <div className="form__col">
                                <Input
                                    label="Kennitala"
                                    name="companySSN"
                                    id="companySSN"
                                    value={values.companySSN}
                                />
                            </div>
                        </div>
                        
                        <h3>Tengiliður fyrirtækis</h3>
                        <div className="form__row" style={{marginBottom: "20px"}}>
                            <div className="form__col">
                                <Input 
                                    label="Nafn"
                                    name="name"
                                    id="name"
                                    value={values.name}
                                />
                            </div>
                            <div className="form__col">
                                <Input
                                    label="Kennitala"
                                    name="contactSSN"
                                    id="contactSSN"
                                    value={values.contactSSN}
                                />
                            </div>
                        </div>
                        <div className="form__row">
                            <div className="form__col">
                                <Input
                                    label="Netfang"
                                    name="email"
                                    id="email"
                                    value={values.email}
                                />
                            </div>
                            <div className='form__col'></div>
                        </div>


                        {(contactResponse === null)
                            ? null
                            : (
                                <div className="form__row">
                                    <div className="form__col">
                                        {(contactResponse instanceof Error)
                                        ? <Alert type='error' headText={contactResponse.message} />
                                        : <Alert type='info' headText={contactResponse} />}
                                    </div>
                                </div>
                            )
                        }
                        <Recaptcha setToken={(e: string | null) => setCaptchaToken(e)} position={'center'} />
                    </div>
                </div>
                <div className="form__actions">
                    <Button type='submit' showLoader={loading} buttonType="primary" size="lg">Senda pöntun</Button>
                </div>
            </Form>
        );
    }
