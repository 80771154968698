// Package imports:
import React, { useEffect, useState } from 'react';
// Component imports:
import Input from '../../ui-elements/Input/Input';
import Button from '../../ui-elements/Button/Button';
import Link from '../../ui-elements/Link/Link';
import Alert from '../../ui-elements/Alert/Alert';
// Service imports:
import { gtmPush } from '../../services/utils';
import { GET_KELDAN_API_URL } from '../../services/config';
import { ErrorMessages } from '../../services/errorMessages';

interface IOwnProps {
    username: string | null;
    password: string | null;
    redirectUrl: string | null;
}

const Login: React.FC<IOwnProps> = ({
    username: defaultUsername,
    password: defaultPassword,
    redirectUrl
}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isUnconfirmed, setIsUnconfirmed] = useState(false);
    const [loading, setLoading] = useState(false);

    // Update state to props on mount
    useEffect(() => {
        if (defaultUsername) setUsername(defaultUsername);
        if (defaultPassword) setPassword(defaultPassword);
    }, [])

    const handleSubmit = async () => {
        setLoading(true);
        setErrorMessage('');
        try {
            const requestQuery = new URLSearchParams({
                username,
                password,
                redirectUrl: redirectUrl ?? '/'
            });
            const url = `${GET_KELDAN_API_URL()}/Innskraning`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (response.ok) {
                if (response.redirected) {
                    gtmPush('buttonClick', 'innskraning', 'success');
                    window.location.href = response.url;
                    return;
                }
                const responseBody = await response.json();
                const { error, unconfirmed } = responseBody;
                setLoading(false);
                if (error) {
                    setErrorMessage(error);
                } else if (unconfirmed) {
                    setIsUnconfirmed(true);
                }
            } else {
                setLoading(false);
                setErrorMessage(ErrorMessages.RequestFailed);
            }
        } catch (e) {
            setLoading(false);
            setErrorMessage(ErrorMessages.NetworkError);
        }
    }

    const displayMessage = () => {
        if (errorMessage) {
            return (
                <Alert
                    type='error'
                    headText={errorMessage}
                />
            )
        }
        if (isUnconfirmed) {
            return (
                <Alert
                    type='alert'
                    headText='Athugið'
                    text={
                        <p>
                            Notandinn er óvirkur þar til nýskráning er staðfest.
                            Staðfestu nýskráningu með því að fylgja leiðbeiningum sem berast frá
                            {'\u0020'}
                            <a href="mailto:admin@keldan.is">admin@keldan.is</a>.
                            Skoðið ruslpóst ef tölvupósturinn hefur ekki borist.
                        </p>
                    }
                />
            )
        } else {
            return null
        }
    }

    return (
        <div className='main KCL_login-page'>
            <div className="section section--primary">
                 <div className="section__body">
                    <div className="shell">
                        <div className="login_container">
                            <h2>Innskráning á Kelduna</h2>
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    className="login-form"
                                    method='POST'
                                >
                                    <div className="form__section">
                                        <div className="form__body">
                                            <div className="form__row">
                                                <div className="form__col">
                                                    <Input
                                                        label="Netfang"
                                                        id="username"
                                                        value={username}
                                                        onChange={(e) => setUsername(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__col">
                                                    <Input
                                                        label="Lykilorð"
                                                        inputType='password'
                                                        id="password"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            displayMessage() !== null &&
                                            <div className='contactResponse'>
                                                {displayMessage()}
                                            </div>

                                        }
                                        <div className='form__actions'>
                                            <Button type='submit' showLoader={loading} buttonType="primary" size="lg">Innskrá</Button>
                                        </div>
                                    </div>
                                </form>

                                <div className="links">
                                    <Link
                                        url="/Nytt-Lykilord"
                                        linkSize='16'
                                        targetBlank
                                        icon='forward'
                                    >
                                        Nýtt lykilorð
                                    </Link>

                                    <Link
                                        url="/Verdskra"
                                        linkSize='16'
                                        icon='forward'
                                    >
                                        Nýskráning
                                    </Link>
                                </div>
                            </div>
                    </div>
                 </div>
            </div>
        </div>
    )
}

export default Login;