// Package imports:
import React from 'react';
// Component imports:
import ViewNewsArticle from '../../components/ViewNewsArticle/ViewNewsArticle';
import Entrypoint from '../Entrypoint';

const ViewArticle: React.FC<{id: string}> = ({
    id
}) => {
    return (
        <Entrypoint>
            <ViewNewsArticle id={id} />
        </Entrypoint>
    );
}

export default ViewArticle;