// Package imports:
import React, { useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
// Component imports:
import Table from '../../ui-elements/Table/Table';
import { MAX_RESULTS, ResultStatus, getItemHref } from './SearchPage';
import Button from '../../ui-elements/Button/Button';
import Loading from '../../ui-elements/Loading/Loading';
import Link from '../../ui-elements/Link/Link';
// Service imports:
import { GET_KELDAN_API_URL } from '../../services/config';
import { useBuyingProcessUrl } from '../../services/buyhook';
import { ErrorMessages } from '../../services/errorMessages';
// Type imports:
import { IKeldanApiResponse } from '../../types/KeldanTypes';
import { IPropertySearchResult, IPropertySearchResultLine } from '../../types/PropertyTypes';

const PropertySearch:React.FC<{
    term: string | null,
    onResultsChange: (hasResults: ResultStatus) => void;
    isAuthenticated?: boolean;
}> = ({term, onResultsChange, isAuthenticated}) => {
    const [isTableLoading, setIsTableLoading] = React.useState(false);
    const [propertyList, setPropertyList] = React.useState<IPropertySearchResultLine[]>([]);
    const { tryPurchaseItem, isItemLoading, setToastError, ModalsAndToasts } = useBuyingProcessUrl((property: IPropertySearchResultLine, reference: string) => {
        const { name_number } = property;
        const url = `${GET_KELDAN_API_URL()}/Property/SearchHeiti/?term=${name_number}&reference=${reference}`
        return url;
        },
        () => {},
        'fasteignaskrá'
    );

    const resetState = (message: ResultStatus) => {
        setPropertyList([]);
        onResultsChange(message);
        setIsTableLoading(false);
    };
    
    const handleErrorResponse = (type: 'alert'| 'error', message: string) => {
        if (message === 'no card') {
            resetState('no card');
            setToastError({
                type: type,
                headText:'Greiðsluupplýsingar vantar',
                text: <p>Kreditkortaupplýsingar eru ekki fullnægjandi. Vinsamlegast yfirfarið <Link href='/Min-Kelda/Stillingar' linkSize='16'>kortaupplýsingar</Link>.</p>
            });
        }
        else resetState('no results');
    };

    const propertySearch = async () => {
        if (!term || term.length < 1) {resetState('no results');return};

        try {
            setIsTableLoading(true);
            const requestQuery = new URLSearchParams({ term });
            const url = `${GET_KELDAN_API_URL()}/Property/PropertySearchAll`;

            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
                body: requestQuery,
            });

            if (!response.ok) {
                handleErrorResponse('error', 'response not ok');
                return;
            }

            const body: IKeldanApiResponse<IPropertySearchResult> = await response.json();
            
            setIsTableLoading(false);
            if (!body.success) {
                const { message } = body;
                const toastError = message === ErrorMessages.NoCard ? 'no card' : 'bad request';
                handleErrorResponse('alert', toastError)
                return;
            }

            if (body.result?.response?.addresses && body.result.response.addresses.length > 0) {
                setPropertyList(body.result.response.addresses);
                onResultsChange('has results');
            } else {
                resetState('no results');
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            handleErrorResponse('error', 'network error');
        }
    };
    useEffect(() => {
        isAuthenticated ? propertySearch() : resetState('no results');
    }, [isAuthenticated]);

    if (term === null) return null;
    if (term.length < 1) return null;
    if (!isAuthenticated) return null;
    //no data
    if (propertyList === null || propertyList?.length === 0) return null;
    return (
        <div className='table-section property-search'>
            <h4>Fasteignir</h4>
            {isTableLoading ? <Loading /> : <Table 
                data={propertyList.slice(0,MAX_RESULTS)}
                columns={[{
                    title: 'Heitisnúmer',
                    renderCell: ({ address: {name_number} }) => name_number,
                    textAlign: 'left',
                    simpleSortable: ({ address: {name_number} }) => name_number ?? null
                }, {
                    title: 'Heiti',
                    renderCell: ({ address: {street} }) => street,
                    textAlign: 'left',
                    simpleSortable: ({ address: {street} }) => street ?? null
                }, {
                    title: 'Húsnúmer',
                    renderCell: ({ address: {house_number} }) => house_number,
                    textAlign: 'left',
                    simpleSortable: ({ address: {house_number} }) => house_number ?? null
                }, {
                    title: 'Sveitarfélag',
                    renderCell: ({ address: {county} }) => county,
                    textAlign: 'left',
                    simpleSortable: ({ address: {county} }) => county ?? null
                }, {
                    title: 'Byggð',
                    renderCell: ({ address: {city} }) => city,
                    textAlign: 'left',
                    simpleSortable: ({ address: {city} }) => city ?? null
                },{
                    title: 'Yfirlitsmynd',
                    renderCell: ( datum ) => {
                        return ( 
                        <a className='anchorWrapForButton' href={getItemHref(
                            {
                                Tickertype: 'Fasteignir',
                                Symbol: datum.name_number,
                                Name: datum.address.display_address ?? '',
                            }
                        )}>
                            <span>
                                <Button
                                    onClick={() => tryPurchaseItem(datum, 'confirm-modal-with-reference')}
                                    showLoader={isItemLoading(datum)} buttonType='buy' size='sm'
                                >
                                    Kaupa
                                </Button>
                            </span>
                        </a>
                    )}
                }]}
                tableSize='lg'
            />}
            <ModalsAndToasts />
        </div>
    );
} 
export default PropertySearch;