// Package imports:
import React, { useEffect, useState } from 'react';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import Label from '../../../ui-elements/Label/Label';
import Loading from '../../../ui-elements/Loading/Loading';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Button from '../../../ui-elements/Button/Button';
import DropSelect from '../../../ui-elements/DropSelect/DropSelect';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { getTableDate, insertDash } from '../../../services/utils';
import { usePageCounter } from '../../../services/hooks';
import { useBuyingProcessUrl } from '../../../services/buyhook';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Fetched } from '../../../types/Types';
import { IKeldanApiResponse, OwnedReport } from '../../../types/KeldanTypes';
import { LegalAdvert, LegalSearchModel } from '../../../types/LegalTypes';
import { ICompanyViewModel } from '../../../types/CompanyTypes';

const CompanyLegalLarge: React.FC<ICompanyViewModel> = ({
    id
}) => {
    const [data, setData] = useState<Fetched<LegalSearchModel>>();
    const [ownedData, setOwnedData] = useState<Fetched<OwnedReport[]>>(null);

    const {
        currentPageIndex,
        itemsPerPage,
        setItemsPerPage,
        itemCount,
        setItemCount,
        PageCounter
    } = usePageCounter();

    const fetchData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Company/GetCompanyLegal?id=${id}&limit=${10000}`
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (!response.ok) {
                setData(new Error(ErrorMessages.RequestFailed));
                return;
            }
            if (response.redirected) {
                window.location.href = response.url;
            }
            const responseBody: IKeldanApiResponse<LegalSearchModel> = await response.json();
            if (responseBody.success) {
                if (responseBody.result) {
                    setData(responseBody.result);
                    setItemCount(responseBody.result.count);
                } else if (responseBody.message === "'Ekkert fannst") {
                    setData(null);
                }
            } else {
                const { message } = responseBody;
                if (message) {
                    setData(new Error(message));
                } else {
                    setData(new Error(ErrorMessages.ErrorInRequest));
                }
            }
        } catch (e) {
            setData(new Error(ErrorMessages.OtherShort));
        }
    }
    const fetchOwnedData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Company/GetCompanyOwnedLegal/${id}`;
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                }
                const responseBody: IKeldanApiResponse<OwnedReport[]> = await response.json();
                if (responseBody.success) {
                    if(responseBody.result) setOwnedData(responseBody.result);
                } else {
                    const { message } = responseBody;
                    if (message) {
                        setOwnedData(new Error(message));
                    } else {
                        setOwnedData(new Error(ErrorMessages.ErrorInRequest));
                    }
                }
            } else {
                setOwnedData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setOwnedData(new Error(ErrorMessages.OtherShort));
        }
    }
    useEffect(() => {
        fetchData();
        fetchOwnedData();
    }, []);

    const { tryPurchaseItem, isItemLoading, ModalsAndToasts } = useBuyingProcessUrl(
        (item: LegalAdvert, modalReference: string) => {
            const url = `${GET_KELDAN_API_URL()}/Fyrirtaeki/Logbirtingar-Kaupa/${id}?adNumber=${item.advertNumber}&reference=${modalReference}`;
            return url;
        },
        () => {
            window.setTimeout(() => {
                fetchOwnedData();
            }, 5*1000);
        },
        'Lögbirtingablaðinu'
    );

    const checkIfOwned = (adNumber: string) => {
        if (ownedData !== null && !(ownedData instanceof Error)) {
            for (const element of ownedData) {
                if (element.tokens.includes(adNumber)) return element.balls
            }
        }
        return 0;
    }

    const displayBody = () => {
        if (data instanceof Error) return <Alert type='error' headText={data.message} />
        if (data === undefined) return <Loading />
        if (data === null || itemCount === 0) return <Alert type='info' headText={`Leit eftir ${insertDash(id ?? '')} skilaði engum niðurstöðum`} />
        return <div>
            <div className='middle-section'>
                <span className='extra-info'>
                    Leit eftir {insertDash(id ?? '')} skilaði {data.count} {(data.count === 1) ? 'niðurstöðu': 'niðurstöðum'}.
                </span>
                <div className="page-size-selector">
                    <span className='extra-info'>Sýna:</span>
                    <DropSelect
                        options={[{
                            label: '10 á síðu',
                            value: '10'
                        }, {
                            label: '50 á síðu',
                            value: '50'
                        }, {
                            label: '100 á síðu',
                            value: '100'
                        },{
                            label: 'Allt á síðu',
                            value: '10000'
                        }]}
                        defaultValue={itemsPerPage.toString()}
                        size='sm'
                        onChange={(option) => { if (typeof option === 'string') setItemsPerPage(parseInt(option)); }}
                    />
                </div>
            </div>
            {data.ads?.slice(currentPageIndex * itemsPerPage, (currentPageIndex + 1) * itemsPerPage).map((item) => (
                <div key={item.advertNumber} className='company-legal-line'>
                    <div>
                        <span className='company-legal-line-date'>{`${getTableDate(item.published, "DD/MM/YYYY", ".")}`} </span>
                        <span className='company-legal-line-text'>{`${item.type}`} </span>
                    </div>
                    {(checkIfOwned(item.advertNumber.toString()) !== 0)
                        ? <Button
                            size='sm'
                            buttonType='secondary'
                            anchorProps={{
                                href: `/Fyrirtaeki/Logbirtingar-Skoda/${id}?eventId=${checkIfOwned(item.advertNumber.toString())}`,
                                target: '_blank'
                            }}
                        >
                            Skoða
                        </Button>
                        : <Button
                            size='sm'
                            buttonType='buy'
                            showLoader={isItemLoading(item)}
                            onClick={() => tryPurchaseItem(item, 'confirm-modal-with-reference')}
                        >
                            Kaupa
                        </Button>
                    }
                </div>
            ))}
            <PageCounter />
        </div>
    }
    
    return (
        <DisplayBox 
            className='KCL_company-legal KCL_company-legal_large'
            title="Lögbirtingar"
            asideComponent={<Label labelType='origin' text='LÖGB.' fullText='Lögbirtingablaðið' />}
        >
            {displayBody()}
            <ModalsAndToasts />
        </DisplayBox>
    );
}

export default CompanyLegalLarge;