// Package imports:
import React, { useEffect, useState } from 'react';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../../ui-elements/Link/Link';
import Table, { IColumn } from '../../../ui-elements/Table/Table';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';
import Loading from '../../../ui-elements/Loading/Loading';
import Tabs from '../../../ui-elements/Tabs/Tabs';
import CalculatedColoredNumber from '../../../ui-elements/CalculatedColoredNumber/CalculatedColoredNumber';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { formatNumber } from '../../../services/utils';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { ICompanyReportModel, IViewCompanyReport } from '../../../types/CompanyTypes';
import { Fetched } from '../../../types/Types';
import { IKeldanApiResponse } from '../../../types/KeldanTypes';

interface ICompanyReport {
    eventDate: string,
    companyReport: IViewCompanyReport
}

interface IRowDatum {
    title: string;
    level: number | null;
    main: string | null;
    secondary: string | null;
    change: number | null;
}

const ViewCompanyReport: React.FC<ICompanyReportModel>= ({
    id,
    eventId
}) => {
    const [ companyReportInfo, setCompanyReportInfo] = useState<Fetched<ICompanyReport>>(null);
    const [ currentTab, setCurrentTab ] = useState<'Rekstrarreikningur' | 'Efnahagsreikningur' | 'Sjodstreymi'>('Rekstrarreikningur');

    // Fetch companyReportData.
    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${GET_KELDAN_API_URL()}/Company/GetCompanyReport/${id}?eventId=${eventId}`;
                const response = await fetch(url);
                if (response.ok) {
                    const responseBody: IKeldanApiResponse<ICompanyReport> = await response.json();
                    if (responseBody.result) {
                        setCompanyReportInfo(responseBody.result);
                    } else if (responseBody.message) {
                        setCompanyReportInfo(new Error(responseBody.message));
                    } else {
                        setCompanyReportInfo(new Error(ErrorMessages.OtherShort));
                    }
                } else {
                    setCompanyReportInfo(new Error(ErrorMessages.RequestFailed));
                }
            } catch (e) {
                setCompanyReportInfo(new Error(ErrorMessages.NetworkError));
            }
        }
        fetchData();
    }, []);

    // Display Loading, Error or Table.
    const displayTable = () => {
        // Non-data check.
        if (companyReportInfo === null) return <Loading />;
        if (companyReportInfo instanceof Error) return <ErrorAlert error={companyReportInfo} />;

        // Destructure necessary data.
        const { keys, mainData, mainYear, secondaryData, secondaryYear } = companyReportInfo.companyReport;

        // Filter keys by current tab.
        let keysToDisplay;
        switch (currentTab) {
            case 'Rekstrarreikningur':
                keysToDisplay = keys.filter(key => key.keyPath !== null && key.keyPath.includes('100000'));
                break;
            case 'Efnahagsreikningur':
                keysToDisplay = keys.filter(key => key.keyPath !== null && (
                    key.keyPath.includes('200000') // Eignir
                    || key.keyPath.includes('300000') // Eigið fé
                    || key.keyPath.includes('400000') // Skuldir
                ))
                break;
            case 'Sjodstreymi':
                keysToDisplay = keys.filter(key => key.keyPath !== null && key.keyPath.includes('500000'));
                break;
        }

        // Should show secondary columns in table?
        const hasSecondary = (
            secondaryData !== null
            && secondaryYear !== null
        );

        // Join main and secondary data to formatted data for table.
        let calculatedRows: IRowDatum[] = [];
        keysToDisplay.forEach(key => {
            // Find datum.
            const mainReportValue = mainData.find(datum => datum.keyId === key.keyId);
            const secondaryReportValue = secondaryData?.find(datum => datum.keyId === key.keyId);
            // Find value.
            const mainValue = mainReportValue?.keyValueInverted ?? null;
            const secondaryValue = secondaryReportValue?.keyValueInverted ?? null
            // Set title based on language
            const title = mainReportValue?.isName ?? mainReportValue?.enName;

            // Calculate change:
            let change: number | null = null;
            // Only calculate change if:
            if (
                // None of the values are null or invalid.
                mainValue !== null
                && secondaryValue !== null
                && secondaryValue !== 0
                // The values have the same sign.
                && (
                    (secondaryValue >= 0 && mainValue >= 0)
                    || (mainValue <= 0 && secondaryValue <= 0)
                )
                // and The two years have same currency
                && secondaryYear !== null
                && mainYear.currency === secondaryYear.currency
            ) {
                // Scale numbers.
                const scaledMainValue = (mainYear.numbersIn ?? 1) * mainValue;
                const scaledSecondaryValue = (secondaryYear.numbersIn ?? 1) * secondaryValue;
                change = ((scaledMainValue - scaledSecondaryValue) / scaledSecondaryValue)
            }

            if (mainValue !== null || secondaryValue !== null) {
                calculatedRows.push({
                    title: title ?? '-',
                    level: key.level,
                    main: formatNumber(mainValue, '-', 0),
                    secondary: formatNumber(secondaryValue, '-', 0),
                    change
                })
            }
        })

        // Declare columns.
        const mainColumns: IColumn<IRowDatum>[] = [{
            title: '',
            renderCell: ({ title })  => title,
            textAlign: 'left'
        }, {
            title: mainYear.fiscalYear?.toString() ?? '-',
            renderCell: ({ main })  => main
        }];

        const secondaryColumns: IColumn<IRowDatum>[] = [{
            title: secondaryYear?.fiscalYear?.toString() ?? '-',
            renderCell: ({ secondary })  => secondary
        }, {
            title: 'Breyting',
            renderCell: ({ change })  => (change !== null)
                ? (
                    <CalculatedColoredNumber
                        currentValue={change}
                        previousValue={null}
                        formatting='percent'
                        hasColor
                    />
                ) : null
        }]

        const columns: IColumn<IRowDatum>[] = (hasSecondary)
            ? [...mainColumns, ...secondaryColumns]
            : mainColumns;

        const CURRENCY_ROW_TITLE = 'Gjaldmiðill';

        // Construct denomination string.
        const mainNumbers = (mainYear.numbersIn && mainYear.numbersIn > 1)
            ? formatNumber(mainYear.numbersIn, '', 0, ' ')
            : '';
        const secondaryNumbers = (secondaryYear?.numbersIn && secondaryYear.numbersIn > 1)
            ? formatNumber(secondaryYear.numbersIn, '', 0, ' ')
            : '';
        const mainDenomination = `Í ${mainNumbers}${mainYear.currency}`;
        const secondaryDenomination = `Í ${secondaryNumbers}${secondaryYear?.currency}`;

        // Add the first row which has the titles.
        const rowsToDisplay: IRowDatum[] = [{
            title: CURRENCY_ROW_TITLE,
            level: 1,
            main: mainDenomination,
            secondary: secondaryDenomination,
            change: null
        }, ...calculatedRows]

        return (
            <Table
                data={rowsToDisplay}
                columns={columns}
                tableSize='lg'
                expandableRowConfig={{
                    defaultExpandedIndexes: [0,1,2],
                    showNumberOfItemsInExpandable: false
                }}
                rowClassName={({level}, ) => {
                    // todo: add bold, italic instead of indent.
                    return undefined;
                }}
                renderUnderRowComponent={({title}) => {
                    if (title === CURRENCY_ROW_TITLE) {
                        return (
                            <tr className='tab-switcher-row'>
                                <td colSpan={columns.length}>
                                    <div className='tabs-wrapper'>
                                        {/* Using "fake" tabs since they're inside the table. */}
                                        <Tabs
                                            onTabChange={(i) => {
                                                if (i === 0) setCurrentTab('Rekstrarreikningur');
                                                if (i === 1) setCurrentTab('Efnahagsreikningur');
                                                if (i === 2) setCurrentTab('Sjodstreymi');
                                            }}
                                            tabs={[{
                                                id: 'Rekstrarreikningur',
                                                label: 'Rekstrarreikningur',
                                                component: <></>
                                            }, {
                                                id: 'Efnahagsreikningur',
                                                label: 'Efnahagsreikningur',
                                                component: <></>
                                            }, {
                                                id: 'Sjodstreymi',
                                                label: 'Sjóðstreymi',
                                                component: <></>
                                            }]}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                    return null;
                }}
            />
        );
    }

    return (
        <DisplayBox
            title='Innsleginn ársreikningur'
            className='KCL_ViewCompanyReport'
            asideComponent={(companyReportInfo === null || companyReportInfo instanceof Error)
                ? undefined
                : (
                    <div className='report-aside-component'>
                        <div className='link-wrapper'>
                            <div className='excel-link'>
                                <Link
                                    targetBlank
                                    linkSize='15'
                                    url={`/Excel/Innsleginn-Arsreikningur?ssn=${id}&eventId=${eventId}`}
                                    icon='excel'
                                >
                                    Sækja gögn í Excel
                                </Link>
                            </div>
                            <div className='pdf-link'>
                                <Link
                                    targetBlank
                                    linkSize='15'
                                    url={`/Pdf/Innslegnir-Arsreikningar?ssn=${id}&eventId=${eventId}`}
                                    icon='pdf'
                                >
                                    Sækja PDF
                                </Link>
                            </div>
                        </div>
                        <div className='italic-text'>
                            Skýrsla keypt: {companyReportInfo.eventDate}
                        </div>
                    </div>
                )
            }
        >
            {displayTable()}
        </DisplayBox>
    );
}

export default ViewCompanyReport;