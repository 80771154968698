// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import ThjodskraPage from '../../components/Individual/Thjodskra/Thjodskra';

interface IProps {
    isAuthenticated?: boolean
}

const Search: React.FC<IProps> = ({isAuthenticated}) => {
    return (
        <Entrypoint>
            <ThjodskraPage isAuthenticated={isAuthenticated ?? false}/>
        </Entrypoint>
    );
}

export default Search;