// Package imports:
import React, { useContext, useEffect, useState } from 'react';
// Component imports:
import AdRotator from '../Ad/AdRotator';
import SingleFund from '../SingleFund/SingleFund';
import BasicChart from '../Charts/BasicChart/BasicChart';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import Avatar, { AvatarNames } from '../../ui-elements/Avatar/Avatar';
import Link from '../../ui-elements/Link/Link';
// Service imports:
import { DEFAULT_REFRESH_RATE, MARKET_REFRESH_RATES } from '../../services/config';
import { changeIssuerName } from '../../services/utils';
// Type imports:
import { IDefaultProps } from '../../types/Types';
// Context imports:
import { AccessTokenContext } from '../../contexts/AccessTokenContext';

interface IProps {
    symbol: string,
}

const SingleFundPage: React.FC<IProps> = ({
    symbol,
}) => {
    const accessToken = useContext(AccessTokenContext);
    const [ defaultProps, setDefaultProps ] = useState<IDefaultProps>({
        accessToken,
        refreshRateMs: DEFAULT_REFRESH_RATE
    });
    const [ issuerName, setIssuerName ] = useState<string>('')
    const [ title, setTitle ] = useState<string>('');
    const [ pdfUrl, setPdfUrl ] = useState<string>('');

    useEffect(() => {
        setDefaultProps({
            ...defaultProps,
            accessToken
        });
    }, [accessToken, title, issuerName]);

    const handleTitle = (title: string | undefined, fundType: string | undefined) => {
        if (fundType === 'S') {
            if(title?.startsWith('S')){
                setTitle('Framtíðarauður VÍB - ' + title);
            }
            if(title?.startsWith('F')){
                setTitle('Frjálsi lífeyrissjóðurinn - ' + title);
            }
            if(title?.startsWith('L')){
                setTitle('Íslenski lífeyrissjóðurinn - ' + title);
            }
        } else {
            setTitle(title ?? '');
        }
    }

    const handleAvatar = (name: string) => {
        switch(name) { 
            case 'Íslandssjóðir': { 
                if(title.includes('Stýring')) return <Avatar size='lg' name='ISB' color dropShadow/>
                return <Avatar size='lg' name='IS' color dropShadow/>
            } 
            case 'Íslenski lífeyrissjóðurinn': { 
                return <Avatar size='lg' name='LAIS' color dropShadow/>
            }
            //empty string
            case '': { 
                return 
            } 
            default: { 
                return <Avatar size='lg' name={changeIssuerName(issuerName) as AvatarNames} color dropShadow/>
            } 
        }
    }

    return (
        <div className='main KCL_single-fund-page KCL_market-page'>
            <div className='main__inner-fluid'>
                <div className="shell">
                    <HeadSection
                        hasAds='market'
                        title={
                            <>
                                <h1>{handleAvatar(issuerName)} {title}</h1>
                                <Link
                                    url={pdfUrl}
                                    targetBlank
                                    linkSize='16'
                                    icon='forward'
                                >
                                    Vefur sjóðsins
                                </Link>
                            </>
                        }
                    />
                    <div>
                        <div className='grid-items'>
                            <div className='grid-item'>
                                <SingleFund
                                    setFundName={(name, type) => handleTitle(name, type)}
                                    setFundIssuerName={(name) => setIssuerName(name ?? '')}
                                    setFundPdfUrl={(url) => setPdfUrl(url ?? '')} 
                                    symbol={symbol}
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['SINGLEFUND']}
                                    id="SingleFundPage_FundTable"
                                />
                            </div>
                        </div>
                        <div className='middle-ad-section'>
                            <AdRotator location='Market1018x360' />
                        </div>
                        <div className="grid-items">
                            <div className="grid-item">
                                <BasicChart
                                    symbol={symbol}
                                    title='Söguleg gögn'
                                    chartType='fund'
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['SINGLEFUND']}
                                    id="SingleFundPage_Chart"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleFundPage;