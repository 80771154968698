// Package imports:
import React, { useMemo } from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Tabs from '../../ui-elements/Tabs/Tabs';
import Table from '../../ui-elements/Table/Table';
// Service imports:
import { formatNumber } from '../../services/utils';
import { useFetchedApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdAvailableReports, IApiLmdQuestorValues } from '../../types/HlutabrefTypes';

const REKSTRAR_KEYS = [{
    key: 910100,
    name: 'Rekstrartekjur'
}, {
    key: 910400,
    name: 'EBITDA'
}, {
    key: 910700,
    name: 'Hagnaður ársins'
}];
const EFNAHAGS_KEYS = [{
    key: 200000,
    name: 'Eignir'
}, {
    key: 300000,
    name: 'Eigið fé'
}, {
    key: 400000,
    name: 'Skuldir'
}, {
    key: 920900,
    name: 'Eigið fé og skuldir'
}];
const SJODSTREYMI_KEYS = [{
    key: 510200,
    name: 'Veltufé frá rekstri'
}, {
    key: 510000,
    name: 'Handbært fé frá rekstri'
}, {
    key: 520000,
    name: 'Fjárfestingarhreyfingar'
}, {
    key: 530000,
    name: 'Fjármögnunarhreyfingar'
}, {
    key: 950700,
    name: 'Breyting á handbæru fé'
}, {
    key: 950900,
    name: 'Handbært fé í lok tímabils'
}];

type ValueType = number | string | null;

interface IReportsDatum {
    name: string,
    yearOne: ValueType,
    yearTwo: ValueType,
    quarterOne: ValueType,
    quarterTwo: ValueType
}

interface IReportsData {
    headers: string[],
    multipliers: IReportsDatum,
    rekstrar: IReportsDatum[],
    efnahags: IReportsDatum[],
    sjodstreymi: IReportsDatum[]
}

interface IOwnProps {
    symbol: string
}
type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const CompanyReports: React.FC<Props> = ({
    symbol,
    accessToken,
    ...props
}) => {
    const availableReports = useFetchedApiLmdData<IApiLmdAvailableReports[]>(
        `/v1/market_data/v1/questor/available_reports/symbol/${symbol}/periods/[Q1;Q2;Q3;Q4;Y]`,
        accessToken
    );

    const {
        year1,
        year2,
        quarter1,
        quarter2
    } = useMemo(() => {
        const returnObject: {
            year1: IApiLmdAvailableReports | null
            year2: IApiLmdAvailableReports | null
            quarter1: IApiLmdAvailableReports | null
            quarter2: IApiLmdAvailableReports | null
        } = {
            year1: null,
            year2: null,
            quarter1: null,
            quarter2: null
        }
    
        if (availableReports === null || availableReports instanceof Error) return returnObject;
        const availableYears = availableReports.filter(d => d.PeriodName === 'Y');
        const availableQuarters = availableReports.filter(d => d.PeriodName?.startsWith('Q'));

        if (availableYears.length > 0) returnObject.year1 = availableYears[availableYears.length-1] ?? null;
        if (availableYears.length > 1) returnObject.year2 = availableYears[availableYears.length-2] ?? null;
        if (availableQuarters.length > 0) returnObject.quarter1 = availableQuarters[availableQuarters.length-1] ?? null;
        if (availableQuarters.length > 1) returnObject.quarter2 = availableQuarters[availableQuarters.length-2] ?? null;
        return returnObject;
    }, [availableReports]);

    const year1Data = useFetchedApiLmdData<IApiLmdQuestorValues[]>(
        (year1 === null || year1.FiscalYear === null)
            ? null
            : `/v1/market_data/v1/questor/values/period/Y/symbol/${symbol}?year=${year1.FiscalYear}`,
        accessToken
    )
    const year2Data = useFetchedApiLmdData<IApiLmdQuestorValues[]>(
        (year2 === null || year2.FiscalYear === null)
            ? null
            : `/v1/market_data/v1/questor/values/period/Y/symbol/${symbol}?year=${year2.FiscalYear}`,
        accessToken
    )
    const quarter1Data = useFetchedApiLmdData<IApiLmdQuestorValues[]>(
        (quarter1 === null || quarter1.FiscalYear === null || quarter1.PeriodName === null)
            ? null
            : `/v1/market_data/v1/questor/values/period/${quarter1.PeriodName}/symbol/${symbol}?year=${quarter1.FiscalYear}`,
        accessToken
    )
    const quarter2Data = useFetchedApiLmdData<IApiLmdQuestorValues[]>(
        (quarter2 === null || quarter2.FiscalYear === null || quarter2.PeriodName === null)
            ? null
            : `/v1/market_data/v1/questor/values/period/${quarter2.PeriodName}/symbol/${symbol}?year=${quarter2.FiscalYear}`,
        accessToken
    )

    const dataSetup = (data: IReportsData) => {
        const Year1 = year1Data instanceof Error ? null : year1Data;
        const Year2 = year2Data instanceof Error ? null : year2Data;
        const Quarter1 = quarter1Data instanceof Error ? null : quarter1Data;
        const Quarter2 = quarter2Data instanceof Error ? null : quarter2Data;
        const Year1NumbersIn = Year1?.find(d => d.NumbersIn !== null)?.NumbersIn;
        const Year1Currency = Year1?.find(d => d.Currency !== null)?.Currency;
        const Year2NumbersIn = Year2?.find(d => d.NumbersIn !== null)?.NumbersIn;
        const Year2Currency = Year2?.find(d => d.Currency !== null)?.Currency;
        const Quarter1NumbersIn = Quarter1?.find(d => d.NumbersIn !== null)?.NumbersIn;
        const Quarter1Currency = Quarter1?.find(d => d.Currency !== null)?.Currency;
        const Quarter2NumbersIn = Quarter2?.find(d => d.NumbersIn !== null)?.NumbersIn;
        const Quarter2Currency = Quarter2?.find(d => d.Currency !== null)?.Currency;
        const year1String = Year1?.find(d => d.FiscalYear !== null)?.FiscalYear?.toString() ?? '-';
        const year2String = Year2?.find(d => d.FiscalYear !== null)?.FiscalYear?.toString() ?? '-';
        const quarter1Period = Quarter1?.find(d => d.PeriodName !== null)?.PeriodName ?? '-';
        const quarter2Period = Quarter2?.find(d => d.PeriodName !== null)?.PeriodName ?? '-';
        const quarter1Year = Quarter1?.find(d => d.FiscalYear !== null)?.FiscalYear ?? 0;
        const quarter2Year = Quarter2?.find(d => d.FiscalYear !== null)?.FiscalYear ?? 0;
        const quarter1String = quarter1Period && quarter1Year ? `${quarter1Period} '${quarter1Year % 100}` : '-';
        const quarter2String = quarter2Period && quarter2Year ? `${quarter2Period} '${quarter2Year % 100}` : '-';
        data.multipliers = {
            ...data.multipliers,
            yearOne: formatNumber(Year1NumbersIn, '-', 0, ' ' + Year1Currency),
            yearTwo: formatNumber(Year2NumbersIn, '-', 0, ' ' + Year2Currency),
            quarterOne: formatNumber(Quarter1NumbersIn, '-', 0, ' ' + Quarter1Currency),
            quarterTwo: formatNumber(Quarter2NumbersIn, '-', 0, ' ' + Quarter2Currency)
        }
        data.headers = [year2String, year1String, quarter2String, quarter1String];
        return { Year1, Year2, Quarter1, Quarter2 };
    }
    
    const populateRekstrarData = (
        data: IReportsData,
        year1: IApiLmdQuestorValues[] | null,
        year2: IApiLmdQuestorValues[] | null,
        quarter1: IApiLmdQuestorValues[] | null,
        quarter2: IApiLmdQuestorValues[] | null
    ) => {
        for (const { key, name } of REKSTRAR_KEYS) {
            const y1 = year1?.find(d => d.KeyId === key);
            const y2 = year2?.find(d => d.KeyId === key);
            const q1 = quarter1?.find(d => d.KeyId === key);
            const q2 = quarter2?.find(d => d.KeyId === key);
            data.rekstrar.push({
                name: name,
                yearOne: y1?.KeyValue ?? null,
                yearTwo: y2?.KeyValue ?? null,
                quarterOne: q1?.KeyValue ?? null,
                quarterTwo: q2?.KeyValue ?? null
            });
        }
    }
    const populateEfnahagsData = (
        data: IReportsData,
        year1: IApiLmdQuestorValues[] | null,
        year2: IApiLmdQuestorValues[] | null,
        quarter1: IApiLmdQuestorValues[] | null,
        quarter2: IApiLmdQuestorValues[] | null
    ) => {
        for (let key of EFNAHAGS_KEYS) {
            const y1 = year1?.find(d => d.KeyId === key.key);
            const y2 = year2?.find(d => d.KeyId === key.key);
            const q1 = quarter1?.find(d => d.KeyId === key.key);
            const q2 = quarter2?.find(d => d.KeyId === key.key);
            data.efnahags.push({
                name: key.name,
                yearOne: y1?.KeyValue ?? null,
                yearTwo: y2?.KeyValue ?? null,
                quarterOne: q1?.KeyValue ?? null,
                quarterTwo: q2?.KeyValue ?? null
            });
        }
    }
    const populateSjodstreymiData = (
        data: IReportsData,
        year1: IApiLmdQuestorValues[] | null,
        year2: IApiLmdQuestorValues[] | null,
        quarter1: IApiLmdQuestorValues[] | null,
        quarter2: IApiLmdQuestorValues[] | null
    ) => {
        for (let key of SJODSTREYMI_KEYS) {
            const y1 = year1?.find(d => d.KeyId === key.key);
            const y2 = year2?.find(d => d.KeyId === key.key);
            const q1 = quarter1?.find(d => d.KeyId === key.key);
            const q2 = quarter2?.find(d => d.KeyId === key.key);
            data.sjodstreymi.push({
                name: key.name,
                yearOne: y1?.KeyValue ?? null,
                yearTwo: y2?.KeyValue ?? null,
                quarterOne: q1?.KeyValue ?? null,
                quarterTwo: q2?.KeyValue ?? null
            });
        }
    }
    const handleData = () => {
        const data: IReportsData = {
            headers: [],
            multipliers: {
                name: 'Gjaldmiðill',
                yearOne: null,
                yearTwo: null,
                quarterOne: null,
                quarterTwo: null
            },
            rekstrar: [],
            efnahags: [],
            sjodstreymi: []
        };
        const { Year1, Year2, Quarter1, Quarter2 } = dataSetup(data);
        populateRekstrarData(data, Year1, Year2, Quarter1, Quarter2);
        populateEfnahagsData(data, Year1, Year2, Quarter1, Quarter2);
        populateSjodstreymiData(data, Year1, Year2, Quarter1, Quarter2);
        return data;
    }

    const reportsData = handleData();

    return (
        <DisplayBox
            className='KCL_company-reports'
            title="Fjárhagsupplýsingar"
            {...props}
        >
            <Tabs
                tabs={[{
                    id: 'rekstrar',
                    label: 'Rekstrarreikningur',
                    component: <Table
                        data={[reportsData.multipliers, ...reportsData.rekstrar]}
                        columns={[{
                            title: '',
                            renderCell: ({name}) => name,
                            textAlign: 'left'
                        }, {
                            title: reportsData.headers[0],
                            renderCell: ({yearTwo}) => typeof yearTwo === 'number' ? formatNumber(yearTwo, '-', 0) : yearTwo
                        }, {
                            title: reportsData.headers[1],
                            renderCell: ({yearOne}) => typeof yearOne === 'number' ? formatNumber(yearOne, '-', 0) : yearOne
                        }, {
                            title: reportsData.headers[2],
                            renderCell: ({quarterTwo}) => typeof quarterTwo === 'number' ? formatNumber(quarterTwo, '-', 0) : quarterTwo
                        }, {
                            title: reportsData.headers[3],
                            renderCell: ({quarterOne}) => typeof quarterOne === 'number' ? formatNumber(quarterOne, '-', 0) : quarterOne
                        }]}
                        tableSize='lg'
                    />
                }, {
                    id: 'efnahags',
                    label: 'Efnahagsreikningur',
                    component: <Table
                        data={[reportsData.multipliers, ...reportsData.efnahags]}
                        columns={[{
                            title: '',
                            renderCell: ({name}) => name,
                            textAlign: 'left'
                        }, {
                            title: reportsData.headers[0],
                            renderCell: ({yearTwo}) => typeof yearTwo === 'number' ? formatNumber(yearTwo, '-', 0) : yearTwo
                        }, {
                            title: reportsData.headers[1],
                            renderCell: ({yearOne}) => typeof yearOne === 'number' ? formatNumber(yearOne, '-', 0) : yearOne
                        }, {
                            title: reportsData.headers[2],
                            renderCell: ({quarterTwo}) => typeof quarterTwo === 'number' ? formatNumber(quarterTwo, '-', 0) : quarterTwo
                        }, {
                            title: reportsData.headers[3],
                            renderCell: ({quarterOne}) => typeof quarterOne === 'number' ? formatNumber(quarterOne, '-', 0) : quarterOne
                        }]}
                        tableSize='lg'
                    />
                }, {
                    id: 'sjodstreymi',
                    label: 'Sjóðstreymi',
                    component: <Table
                        data={[reportsData.multipliers, ...reportsData.sjodstreymi]}
                        columns={[{
                            title: '',
                            renderCell: ({name}) => name,
                            textAlign: 'left'
                        }, {
                            title: reportsData.headers[0],
                            renderCell: ({yearTwo}) => typeof yearTwo === 'number' ? formatNumber(yearTwo, '-', 0) : yearTwo
                        }, {
                            title: reportsData.headers[1],
                            renderCell: ({yearOne}) => typeof yearOne === 'number' ? formatNumber(yearOne, '-', 0) : yearOne
                        }, {
                            title: reportsData.headers[2],
                            renderCell: ({quarterTwo}) => typeof quarterTwo === 'number' ? formatNumber(quarterTwo, '-', 0) : quarterTwo
                        }, {
                            title: reportsData.headers[3],
                            renderCell: ({quarterOne}) => typeof quarterOne === 'number' ? formatNumber(quarterOne, '-', 0) : quarterOne
                        }]}
                        tableSize='lg'
                    />
                }]}
            />
        </DisplayBox>
    );
}

export default CompanyReports;