// Package imports:
import React, { useEffect, useState } from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Table from '../../ui-elements/Table/Table';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import Loading from '../../ui-elements/Loading/Loading';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import Button from '../../ui-elements/Button/Button';
import ForeignCompanyPepModal from './ForeignCompanyPepModal';
// Service imports:
import { GET_KELDAN_API_URL } from '../../services/config';
import { getTableDate } from '../../services/utils';
import { ErrorMessages } from '../../services/errorMessages';
// Type imports:
import { Fetched } from '../../types/Types';
import { IKeldanApiResponse } from '../../types/KeldanTypes';
import { InitRequest } from '../../types/PepTypes';
import { ForeignCompanyPep, PepCompanyResult } from '../../types/ForeignCompanyPepTypes';

interface IfetchedData {
    eventDate: string,
    reply: string,
}

const ViewForeignCompanyReport: React.FC<{ id: string }>= ({
    id
}) => {
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState<PepCompanyResult | undefined>(undefined);
    const [ fetchedData, setfetchedData] = useState<Fetched<ForeignCompanyPep>>(null);
    function formatCategories(strings?: string[]): JSX.Element {
        if(strings === undefined) return <></>;
        const groups: { [key: string]: string[] } = {};

        strings.forEach(str => {
            const [prefix, suffix] = str.split(' - ');
            if (!groups[prefix]) {
                groups[prefix] = [];
            }
            if (suffix) {
                groups[prefix].push(suffix);
            }
        });

        const elements: JSX.Element[] = [];
        Object.entries(groups).forEach(([prefix, suffixes], index) => {
            if (index !== 0) {
                elements.push(<span>, </span>);
            }
            elements.push(<strong>{prefix}</strong>);
            if (suffixes.length > 0) {
                elements.push(<span>: {suffixes.join(', ')}</span>);
            }
        });

        return <span>{elements}</span>;
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${GET_KELDAN_API_URL()}/PEP/GetForeignCompanyReport/${id}`;
                const response = await fetch(url);
                if (response.ok) {
                    const responseBody: IKeldanApiResponse<IfetchedData> = await response.json();
                    if (responseBody.result) {
                        //parse the data and create the data object
                        const parsedData: ForeignCompanyPep = JSON.parse(responseBody.result.reply);
                        setfetchedData(parsedData);
                    } else if (responseBody.message) {
                        setfetchedData(new Error(responseBody.message));
                    } else {
                        setfetchedData(new Error(ErrorMessages.OtherShort));
                    }
                } else {
                    setfetchedData(new Error(ErrorMessages.RequestFailed));
                }
            } catch (e) {
                setfetchedData(new Error(ErrorMessages.NetworkError));
            }
        }
        fetchData();
    }, []);

    const displayResult = (data: ForeignCompanyPep) => {
        // null indicates loading
        if(data === undefined) return <></>
        if (data === null) return <Loading />
        
        // Check if connected
        return <>
            <div style={{marginTop: '10px'}}>
                <Table
                    tableSize="lg"
                    data={data.match ?? []}
                    showEmptyTableText
                    columns={[
                        {
                        title: "Nafn",
                        renderCell: ({ nameDetails }) =>
                            nameDetails?.[0]?.companyName ?? "",
                        textAlign: "left",
                        },
                        {
                        title: "Land",
                        renderCell: ({ primaryLocation }) => primaryLocation,
                        textAlign: "left",
                        },
                        {
                        title: "Lýsing",
                        renderCell: ({ generalInfo }) => generalInfo?.businessDescription,
                        textAlign: "left",
                        },
                        {
                        title: "Flokkur/ar",
                        renderCell: ({ categories }) => formatCategories(categories),
                        textAlign: "left",
                        },
                        {
                        title: "Ítarefni",
                        renderCell: (item) => (
                            <Button
                            onClick={() => {setModalData(item);setShowModal(true)}}
                            buttonType="secondary"
                            size="sm"
                            >
                            Skoða
                            </Button>
                        ),
                        },
                    ]}
                />
            </div>
        </>
    }
    function displayInitRequest(request: InitRequest | undefined) {
        const keysToIgnore = ['matchRate', 'watchListServices', 'flowKey', 'gender', 'personalNumber'];
        if (request === undefined) return <></>;
        return Object.entries(request)
            .filter(([key]) => !keysToIgnore.includes(key))
            .map(([key, value]) => (
                <div className='info-col' key={key}>
                    <div className='info-item' >
                        <span className='title'>{key}</span>
                        <span className='value' style={{textTransform: 'capitalize'}}>{value}</span>
                    </div>
                </div>
            ));
    }

    const displayReport = () => {
        if (fetchedData === null) return <Loading />;
        if (fetchedData instanceof Error) return <ErrorAlert error={fetchedData} />;
        return <>
            <div className='info-col-wrapper'>
                {displayInitRequest(fetchedData.request)}
            </div>
            <DisplayBox>
                <div className='italic-text'>
                    Skýrsla sótt: {fetchedData.date && fetchedData.date.length > 0 && fetchedData.date[0].date ? getTableDate(fetchedData.date[0].date, 'DD/MM/YYYY HH:MM:SS', '.') : '-'}
                </div>
                <div className='main'>{displayResult(fetchedData)}</div>
            </DisplayBox>
        </>
    }

    return (
        <div className='main KCL_viewPep KCL_individual-profile assets-report'>
            <div className='shell'>
                <HeadSection 
                    title="Alþjóðlegir válistar"
                />
                {displayReport()}
            </div>
            <ForeignCompanyPepModal show={showModal} close={() => setShowModal(false)} data={modalData} />
        </div>
    );
}

export default ViewForeignCompanyReport;