// Package imports:
import React, { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';

interface ILanguage {
    lang: string,
    value: string
}

interface ILanguagePickerProps {
    availableLanguages: ILanguage[],
    defaultValue: string,
    onChange?(lang?: string | null): void,
    setLanguage?(lang: string): void
}

const LanguagePicker: React.FC<ILanguagePickerProps> = ({
    availableLanguages,
    defaultValue,
    onChange = () => {},
    setLanguage = () => {}
}) => {
    const dropdown = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(defaultValue)
    const [selectedOption, setSelectedOption] = useState<ILanguage | null | undefined>();

    const toggleOpen = () => setIsOpen(!isOpen)

    const hasValue = useMemo(() => {
        return selectedValue && selectedValue.toString().length >= 1
    }, [selectedValue])

    const handleOutsideClick = (event: any) => {
        if (dropdown.current) {
            if (!dropdown.current.contains(event.target)) {
               setIsOpen(false);
            }
        }
    }

    useEffect(() => {
        window.addEventListener('click', handleOutsideClick);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsOpen(false);
        const option = availableLanguages.find(opt => selectedValue && opt.value?.toString() === selectedValue.toString());
        
        onChange(selectedValue)
        setLanguage(option?.lang ?? '')
        setSelectedOption(option)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue]);

    return (
        <div
            className={cx(
                'KCL_language-picker',
                {
                    'is-open': isOpen,
                    'has-value': hasValue
                }
            )}
            ref={dropdown}
        >
            <button
                onClick={toggleOpen}
                className="drop-select__trigger"
                type="button"
            >
                <span>
                    {selectedOption ? selectedOption.lang : ''}
                </span>
                <i>
                    <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} />
                </i>
            </button>

            <div className="drop-select__overlay">
                <div className="drop-select__inner">
                    {availableLanguages.length > 0 && availableLanguages.map((option) => (
                        option.value !== selectedValue && <button
                            key={option.lang}
                            type="button"
                            className={cx('drop-select__item', {
                                'is-selected': selectedValue && option.value === selectedValue
                            })} 
                            onClick={() => setSelectedValue(option.value)}
                        >
                            {option.lang}
                            <span></span>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default LanguagePicker;