// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import GjaldeyrismarkadurPage from '../../components/GjaldeyrismarkadurPage/GjaldeyrismarkadurPage';

const Currencies: React.FC = () => {
    return (
    <Entrypoint>
        <GjaldeyrismarkadurPage />
    </Entrypoint>
    );
}

export default Currencies;