// Package imports:
import React from 'react';
// Component imports:
import Label from '../../ui-elements/Label/Label';
import Modal from '../../ui-elements/Modal/Modal';

interface IProps {
    show: boolean,
    open(): void,
    close(): void
};

const Fyrirvari: React.FC<IProps> = ({
    show,
    open,
    close,
    children
}) =>  (
    <>
        <Label
            text='Fyrirvari'
            labelType='disclaimer'
            onClick={open}
        />
        <Modal
            show={show}
            onHide={close}
            hasCloseButton
            header='Fyrirvari'
            body={children}
        />
    </>
);

export default Fyrirvari;