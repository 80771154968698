// Package imports:
import React from 'react';
// Component imports:
import GemmaqPage from '../../components/Gemmaq/Gemmaq';
// Context imports:
import AccessTokenContextProvider from '../../contexts/AccessTokenContext';

const GenderScale: React.FC = () => {
    return (
        <AccessTokenContextProvider>
            <GemmaqPage />
        </AccessTokenContextProvider>
    );
}

export default GenderScale;