// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import HeaderComp from '../../components/Header/Header';
// Type imports:
import { IUserInfo } from '../../types/Types';
// Context imports:
import UserInfoContextProvider from '../../contexts/UserInfoContext';

interface IProps {
    userInfo?: IUserInfo
}

const Header: React.FC<IProps> = ({
    userInfo
}) => {
    return (
        <Entrypoint dontShowFallBackComponent>
            <UserInfoContextProvider userInfo={userInfo}>
                <HeaderComp />
            </UserInfoContextProvider>
        </Entrypoint>
    );
}

export default Header;