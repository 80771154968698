// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import WebserviceComp from '../../components/Webservice/Webservice';

const Webservice: React.FC = () => {
    return (
        <Entrypoint>
            <WebserviceComp />
        </Entrypoint>
    );
}

export default Webservice;