// Package imports:
import React from 'react';
// Component imports:
import ScrollSpy from '../../../ui-elements/ScrollSpy/ScrollSpy';
import ScrollSpySection from '../../../ui-elements/ScrollSpy/ScrollSpySection';
import Table from '../../../ui-elements/Table/Table';
import Link from '../../../ui-elements/Link/Link';
import BulletList from '../../../ui-elements/Lists/BulletList';

interface IIndexation {
    name: string,
    options: string,
    source: string
};

const Notkunar: React.FC = () => {
    const data: IIndexation[] = [
        {
            name: "Fasteignaskýrsla",
            options: "Heimilisfang, landsnúmer eða fastanúmer eignar",
            source: "Húsnæðis- og mannvirkjastofnun"
        },
        {
            name: "Eignastaða - fasteignir",
            options: "Kennitala eða nafn",
            source: "Húsnæðis- og mannvirkjastofnun"
        },
        {
            name: "Eignasaga - fasteignir",
            options: "Kennitala eða nafn",
            source: "Húsnæðis- og mannvirkjastofnun"
        },
        {
            name: "Fasteignaskýrsla með veðböndum",
            options: "Heimilisfang, landsnúmer eða fastanúmer eignar",
            source: "Húsnæðis- og mannvirkjastofnun"
        },
        {
            name: "Skýrsla um hlutafélag",
            options: "Kennitala hlutafélags eða nafn hlutafélags",
            source: "Ríkisskattstjóri"
        },
        {
            name: "Skýrsla um aðild einstaklings að hlutafélagi",
            options: "Kennitala einstaklings eða nafn einstaklings",
            source: "Ríkisskattstjóri"
        },
        {
            name: "Auglýsing úr Lögbirtingablaði",
            options: "Auglýsing á tilteknu tímabili eða eftir tilteknum texta",
            source: "Sýslumaðurinn í Vík"
        },
        {
            name: "Grunnupplýsingar úr þjóðskrá (nafn, kennitala, heimilisfang)",
            options: "Nafn",
            source: "Ferli ehf. - Þjóðskrá"
        },
        {
            name: "Ökutækjaskýrsla",
            options: "Fastanúmer eða skráningarnúmer",
            source: "Samgöngustofa"
        },
        {
            name: "Ökutæki - kennitöluleit",
            options: "Kennitala eða nafn",
            source: "Samgöngustofa"
        },
        {
            name: "Ökutækjaskýrsla með veðböndum",
            options: "Fastanúmer eða skráningarnúmer",
            source: "Samgöngustofa - Fasteignaskrá Íslands v/veðbanda"
        },
        {
            name: "Ársreikningur fyrirtækis",
            options: "Kennitala hlutafélags eða nafn hlutafélags",
            source: "Ríkisskattstjóri"
        },
        {
            name: "Stofnskjöl, samþykktir og önnur skönnuð skjöl",
            options: "Kennitala hlutafélags eða nafn hlutafélags",
            source: "Ríkisskattstjóri"
        },
    ];
    return (
        <div className='notkunar'>
            <article className="article-overview">
                <div className="article__body">
                    <ScrollSpy
                        containerClass="article__content"
                        navClass="article__nav"
                        parentClass='notkunar'
                        initialStep='almennt'
                    >
                        <ScrollSpySection className="article__section" id="almennt" label="Almennt">
                            <h2>Almennt</h2>
                            <p className='paragraph'>
                                Keldan ehf. hefur milligöngu um miðlun upplýsinga úr opinberum skrám en upplýsingarnar eru sóttar til ábyrgðaraðila skránna. Ábyrgðaraðilar sem samið hefur verið við vegna þessarar þjónustu eru:
                            </p>
                            <BulletList
                                items={[
                                    'HMS vegna fasteignaskrár',
                                    'HMS vegna veðbandayfirlita fasteigna og ökutækja',
                                    'RSK vegna fyrirtækjaskrár',
                                    'RSK vegna hlutafélagaskrár',
                                    'RSK vegna ársreikninga fyrirtækja',
                                    'Ferli ehf. vegna þjóðskrár',
                                    'Samgöngustofa vegna ökutækjaskrár',
                                    'Sýslumaðurinn í Vík vegna Lögbirtingablaðsins',
                                    'Vinnueftirlit ríkisins vegna vinnuvélaskrár'
                                ]}
                            />
                            <p className='paragraph'>
                                Miðlun upplýsinga í kerfinu fer þannig fram að áskrifandi eða notandi sem áskrifandi hefur skráð á sínum vegum óskar eftir upplýsingum úr hinum opinberu
                                skrám þarf að skrá sig inn í kerfið. Upplýsingar um áskrifanda eða notanda sem skráður hefur verið á vegum áskrifanda eru skráðar í gagnagrunn kerfisins,
                                þ.e. nafn, kennitala, heimilisfang, netfang o.þ.h.
                            </p>
                            <p className='paragraph'>
                                Sérhver fyrirspurn áskrifanda eða notanda á hans vegum er skráð í gagnagrunn hjá Keldunni ásamt kennitölu þess sem gerir fyrirspurnina. Allar fyrirspurnir
                                eru því rekjanlegar til þess einstaklings eða fyrirtækis sem fyrirspurnina gerði.
                            </p>
                            <p className='paragraph'>
                                Vegna samskipta milli Keldunnar og áskrifanda hefur áskrifandi gefið upp netfang sitt. Tilkynningar frá Keldunni vegna skilmála þessara verða sendar
                                með tölvupósti á uppgefið netfang áskrifanda. Þá áskilur Keldan sér rétt á að senda áskrifendum og notendum tölvupóst í markaðslegum tilgangi.
                            </p>
                            <p className='paragraph'>
                                Áskrifandi ber fulla ábyrgð á að notkun hans og notenda, sem hann hefur skráð, á upplýsingakerfi og upplýsingaþjónustu Keldunnar sé í samræmi við lög
                                og skilmála þessa. Áskrifandi ber einnig ábyrgð á því hvaða aðilar eru gerðir að notendum, meðferð lykilorða og gerð ráðstafana til að koma í veg fyrir
                                að óviðkomandi aðilar notfæri sér áskrift hans. Keldan ber ekki ábyrgð á því ef lykilorð glatast eða er misnotað og getur afgreitt beiðni um
                                upplýsingar í góðri trú til notenda sem nota uppgefið lykilorð á meðan áskrifandi hefur ekki tilkynnt Keldunni um annað.
                            </p>
                            <p className='paragraph'>
                                Miðlun upplýsinga úr opinberum skrám er ekki starfsleyfisskyld en Persónuvernd hefur verið tilkynnt um starfsemi Keldunnar.
                            </p>
                        </ScrollSpySection>
                        <ScrollSpySection className="article__section" id="tilfallandi-kaup" label="Tilfallandi kaup">
                            <h2>Tilfallandi kaup</h2>
                            <p className='paragraph'>
                                Ef um er að ræða tilfallandi kaup á upplýsingum án þess að um skráningu í áskrift sé að ræða er viðkomandi kaupandi upplýsinga áskrifandi í skilningi notkunarskilmála þessara, með eftirfarandi undantekningum:
                            </p>
                            <ol className="list-alphabets">
                                <li>
                                    <p className='paragraph'>
                                        Einungis er um einn notanda að ræða.
                                    </p>
                                </li>
                                <li>
                                    <p className='paragraph'>
                                        Samningur aðila um notkun gildir í klukkustund og nær eingöngu til þeirra skýrslna sem pantaðar eru á þeim tíma. Samningi aðila lýkur án sérstakrar uppsagnar.
                                    </p>
                                </li>
                                <li>
                                    <p className='paragraph'>
                                        Ekki er greitt áskriftargjald en greitt er gjald fyrir upplýsingar sem sóttar eru í upplýsingakerfi Keldunnar skv. gildandi verðskrá hverju sinni.
                                    </p>
                                </li>
                                <li>
                                    <p className='paragraph'>
                                        Skýrslur sem tilfallandi notandi kaupir eru ekki geymdar í skýrslukerfi Keldunnar og eru honum því ekki aðgengilegar síðar.
                                    </p>
                                </li>
                                <li>
                                    <p className='paragraph'>
                                        Tilfallandi kaupandi fær ekki aðgang að reikningsyfirliti um notkun á upplýsingakerfi Keldunnar.
                                    </p>
                                </li>
                                <li>
                                    <p className='paragraph'>
                                        Tilfallandi kaupanda er ekki tilkynnt um breytingar á notendaskilmálum eða verðskrá.
                                    </p>
                                </li>
                            </ol>
                        </ScrollSpySection>
                        <ScrollSpySection className="article__section" id="skyrslur" label="Skýrslur">
                            <h2>Skýrslur</h2>
                            <Table
                                columns={[
                                    {
                                        renderCell: ({ name }) => name,
                                        textAlign: 'left',
                                        title: 'Skýrsla'
                                    },
                                    {
                                        renderCell: ({ options }) => options,
                                        textAlign: 'left',
                                        title: 'Leitarmöguleikar'
                                    },
                                    {
                                        renderCell: ({ source }) => source,
                                        textAlign: 'left',
                                        title: 'Ábyrgðaraðili'
                                    }
                                ]}
                                data={data}
                                tableSize='lg'
                            />
                        </ScrollSpySection>
                        <ScrollSpySection className="article__section" id="verdskra" label="Verðskrá">
                            <h2>Verðskrá</h2>
                            <p className='paragraph'>
                                Áskrifandi Keldunnar greiðir mánaðarlegt áskriftargjald samkvæmt verðskrá Keldunnar. Þá greiðir áskrifandi fyrir hverja skýrslu sem hann eða notendur
                                á hans vegum sækja í kerfið samkvæmt verðskrá. Hægt er að kaupa einstakar skýrslur án þess að greiða áskriftargjald en þá eru skýrslur sem viðkomandi
                                kaupir ekki vistaðar eins og frá greinir í 4. grein. Gjald fyrir tilfallandi kaup á skýrslum án áskriftar má sjá í verðskrá.
                            </p>
                            <p className='paragraph'>
                                Keldan áskilur sér rétt til að breyta verðskrá. Tilkynna skal áskrifendum um breytingu á verðskrá með tölvupósti á uppgefið netfang.
                            </p>
                            <p className='paragraph'>
                                Áskrifandi getur sagt áskrift sinni upp með því að senda tölvupóst á netfangið <span className='inline-link'><Link url="mailto:info@keldan.is" targetBlank linkSize='18'>info@keldan.is</Link></span>.
                            </p>
                        </ScrollSpySection>
                        <ScrollSpySection className="article__section" id="skyrslur-og-vidskiptayfirlit" label="Vistun á skýrslum og viðskiptayfirlit">
                            <h2>Vistun á skýrslum og viðskiptayfirlit</h2>
                            <p className='paragraph'>
                                Til hægðarauka fyrir áskrifanda og notendur á hans vegum eru skýrslur sem keyptar hafa verið vistaðar á lokuðu vefsvæði (<em>Keldan mín</em>) sem áskrifandi
                                og notendur á hans vegum hafa aðgang að. Skýrslur sem eru vistaðar eru merktar þannig að ekki fari á milli mála að um gamlar og e.t.v. úreltar
                                upplýsingar sé að ræða. Þær geta því komið áskrifanda og notendum á hans vegum að gagni til upprifjunar en koma ekki í stað uppflettinga í opinberum
                                skrám. <em>Keldan mín</em> hefur einnig að geyma nákvæmt viðskiptayfirlit þar sem áskrifandi getur séð upplýsingar um öll viðskipti sín við Kelduna.
                            </p>
                            <p className='paragraph'>
                                Sé áskrift sagt upp er aðgangi notanda að kerfinu lokað í lok þess mánaðar sem uppsögn berst. Þá er jafnframt lokað aðgangi áskrifanda að skýrslum
                                sem keyptar hafa verið og vistaðar eru í kerfinu.
                            </p>
                            <p className='paragraph'>
                                Óheimilt er að nota upplýsingamiðlun Keldunnar til að safna í gagnagrunn þeim upplýsingum sem aðgangur er veittur að.
                            </p>
                        </ScrollSpySection>
                        <ScrollSpySection className="article__section" id="voktun" label="Vöktun">
                            <h2>Vöktun</h2>
                            <p className='paragraph'>
                                Keldan býður viðskiptavinum sínum upp á þá þjónustu að geta sett "vöktun" á tilteknar upplýsingar sem birtast í ársreikningaskrá, hlutafélagaskrá
                                og Lögbirtingablaði. Þjónusta þessi er til hagræðis fyrir viðskiptavini Keldunnar, en fyrirtækið ábyrgist ekki að "vöktunin" sé algild eða hnökralaus.
                            </p>
                        </ScrollSpySection>
                        <ScrollSpySection className="article__section" id="rettur-skradra-adila" label="Réttur skráðra aðila">
                            <h2>Réttur skráðra aðila</h2>
                            <p className='paragraph'>
                                Samkvæmt lögum um persónuvernd nr. 77/2000 hvíla skyldur á ábyrgðaraðila hinna opinberu skráa sem Keldan miðlar upplýsingum úr um upplýsingagjöf
                                til þeirra sem þar eru skráðir. Samkvæmt lögunum á hinn skráði m.a. rétt á að fá frá ábyrgðaraðila vitneskju um hver hefur fengið aðgang að
                                upplýsingum um hann. Til að tryggja að ábyrgðaraðili hverrar skráar fyrir sig geti uppfyllt lagaskyldu sína að þessu leyti skráir Keldan sérhverja
                                fyrirspurn sem gerð er gegnum kerfi hennar. Óski ábyrgðaraðili eftir upplýsingum um það hverjir hafa sótt upplýsingar um tiltekinn skráðan aðila
                                mun Keldan afhenda lista yfir allar fyrirspurnir um viðkomandi aðila þar sem fram koma kennitölur þeirra sem gert hafa fyrirspurnir um viðkomandi
                                í kerfinu.
                            </p>
                        </ScrollSpySection>
                        <ScrollSpySection className="article__section" id="upplysingar-ur-thinglysingabokum" label="Miðlun upplýsinga úr þinglýsingabókum">
                            <h2>Miðlun upplýsinga úr þinglýsingabókum</h2>
                            <p className='paragraph'>
                                Þann 17. mars 2010 veitti dómsmála- og mannréttindaráðuneytið Keldunni ehf. heimild til aðgangs að tölvufærðum þinglýsingabókum með beinlínutengingu
                                samkvæmt 1. mgr. 12. gr. reglugerðar nr. 405/2008 um þinglýsingar sbr. 53. gr. þinglýsingalaga nr. 39/1978.
                            </p>
                            <p className='paragraph--bold'>
                                Í 11. grein reglugerðar nr. 405/2008 um þinglýsingar segir:
                            </p>
                            <div className='vert__line'>
                                <blockquote>
                                    <p className='paragraph--italic'>
                                        Þeim sem er nauðsynlegt vegna starfsemi sinnar má veita heimild til að skoða tölvufærðar þinglýsingabækur og rafræn endurrit þinglýstra
                                        skjala með beinlínutengingu.
                                    </p>
                                    <p className='paragraph--italic'>
                                        Sá er heimild hefur til að skoða þinglýsingabók með beinlínutengingu má prenta út úr henni veðbandayfirlit og rafræn endurrit þinglýstra
                                        skjala. Er honum óheimilt að veita óviðkomandi aðila upplýsingar úr þinglýsingabók sem hann fær með beinlínutengingu eða afhenda slíkum
                                        aðila veðbandayfirlit.
                                    </p>
                                </blockquote>
                            </div>
                            <p>
                                Áskrifandi Keldunnar eða notandi sem áskrifandi skráir á sínum vegum sem óskar eftir aðgangi að þinglýsingarbókum (veðböndum) þarf að staðfesta sérstaklega að honum sé nauðsynlegt vegna starfsemi sinnar að skoða tölvufærðar þinglýsingarbækur og þarf hann að gefa skýringu á því hvers vegna aðgangurinn er nauðsynlegur. Skýring áskrifanda eða notanda á hans vegum er vistuð með umsókn um aðgang og áskilur Keldan sér rétt til að sannreyna þær skýringar sem gefnar eru og veita öðrum upplýsingar um þær eftir því sem lög og reglur mæla fyrir um.
                            </p>
                        </ScrollSpySection>
                        <ScrollSpySection className="article__section" id="breytingar-a-skilmalum" label="Breytingar á skilmálum">
                            <h2>Breytingar á skilmálum</h2>
                            <p className='paragraph'>
                                Keldan áskilur sér rétt til breytinga á skilmálum þessum. Ef skilmálum er breytt skal tilkynna áskrifanda með tölvupósti á uppgefið netfang um
                                breytingarnar áður en þær taka gildi.
                            </p>
                        </ScrollSpySection>
                        <ScrollSpySection className="article__section" id="almennur-fyrirvari" label="Almennur fyrirvari">
                            <h2>Almennur fyrirvari</h2>
                            <p className='paragraph'>
                                Upplýsingar þær sem Keldan miðlar eru sóttar í opinberar skrár eins og lýst er í 1. grein. Aðgangur að upplýsingum og þjónusta Keldunnar sem á
                                honum byggist, s.s. vistun eldri skýrslna, byggir á reglum og framkvæmd ábyrgðaraðila hverju sinni. Keldan áskilur sér rétt til að breyta þjónustu
                                sinni í samræmi við breyttar reglur og stjórnsýsluframkvæmd.
                            </p>
                            <p className='paragraph'>
                                Keldan ábyrgist ekki að upplýsingar þær sem miðlað er séu réttar. Sérstaklega þarf að gæta að því að nokkur tími getur liðið frá því gögnum er
                                skilað til ábyrgðaraðila opinberra skráa þar til þau eru færð til rafrænnar skráningar. Öll notkun á upplýsingum sem Keldan miðlar er á ábyrgð áskrifanda.
                            </p>
                            <p className='paragraph'>
                                Keldan áskilur sér rétt til að rjúfa aðgang að upplýsingum um stundarsakir fyrirvaralaust og án tilkynningar, ef þörf krefur vegna viðhalds og
                                endurbóta á kerfi Keldunnar, öryggis upplýsinga, o.þ.h.
                            </p>
                        </ScrollSpySection>
                        <ScrollSpySection className="article__section" id="serstakir-skilmalar" label="Sérstakir skilmálar vegna einstakra opinberra skráa">
                            <h2>Sérstakir skilmálar vegna einstakra opinberra skráa</h2>
                            <ol id='subAnchor11' type="a" className='list-alphabets'>
                                <li>
                                    <div>
                                        <p className='paragraph--bold'>Vegna fasteignahluta fasteignaskrár:</p>
                                        <ol className="disclist" type="i">
                                            <li>
                                                <p className='paragraph'>
                                                    Allar upplýsingar úr fasteignaskrá eru trúnaðarmál og mega ekki afhendast öðrum, beint eða óbeint.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Aðgangi að vefsetri Keldunnar fylgir réttur til að gera fyrirspurnir í fasteignaskrá. Einungis áskrifandi og notendur á hans vegum
                                                    sem skráðir hafa verið í upplýsingakerfið hafa heimild til að gera fyrirspurnir á grundvelli samnings þessa.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Áskrifandi eða notandi á hans vegum, sem hefur heimild til að sækja upplýsingar fyrir milligöngu Keldunnar er bundinn þagnarskyldu
                                                    um innihald þeirra upplýsinga.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Áskrifanda og notendum á hans vegum er skylt að geyma allar upplýsingar úr fasteignaskrá á öruggum stað.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Söfnun upplýsinga úr fasteignaskrá með rafrænum hætti er með öllu óheimil. Sama gildir um samtengingar við aðrar skrár.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Brjóti áskrifandi eða notandi á hans vegum gegn ákvæðum þessara skilmála er heimilt að loka á aðgang áskrifanda eða notanda að
                                                    fasteignaskrá hjá Keldunni án tafar. Jafnframt er áskilinn réttur til að krefja áskrifanda um bætur vegna tjóns sem hljótast kann af
                                                    þeim brotum.
                                                </p>
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <p className='paragraph--bold'>Vegna þinglýsingahluta fasteignaskrár:</p>
                                        <ol className="disclist" type="i">
                                            <li>
                                                <p className='paragraph'>
                                                    Einungis áskrifandi og notendur sem skráðir hafa verið á hans vegum hafa heimild til að gera fyrirspurnir og mega þeir ekki afhenda
                                                    öðrum lykilorð sitt eða kynna með öðrum hætti aðgangsheimildir sínar.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Áskrifandi og notendur á hans vegum, sem hafa heimild til að sækja upplýsingar í þinglýsingahluta fasteignaskrár eru bundnir þagnarskyldu
                                                    um innihald þeirra upplýsinga gagnvart aðilum er ekki þurfa starfs síns vegna að hafa vitneskju um innihaldið.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Óheimilt er að veita utanaðkomandi aðila upplýsingar úr þinglýsingabók eða afhenda slíkum aðila útprentuð veðbandayfirlit úr þinglýsingakerfinu.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Söfnun upplýsinga úr fasteignaskrá með rafrænum hætti er með öllu óheimil. Sama gildir um samtengingar við aðrar skrár.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Vakin er athygli á því að veðbandayfirlit kemur ekki í stað vottorðs þinglýsingastjóra.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Sé brotið gegn framangreindum skilyrðum, reglugerð um þinglýsingar eða samningsaðili uppfyllir ekki lengur skilyrði til að hafa aðgang
                                                    að kerfinu skal fella aðgangsheimildina niður.
                                                </p>
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <p className='paragraph--bold'>Vegna kennitöluleitar í fasteignaskrá til að kanna eignastöðu og eignasögu einstaklings:</p>
                                        <ol className="disclist" type="i">
                                            <li>
                                                <p className='paragraph'>
                                                    Sækja þarf um heimild sérstaklega og gilda sérstakir skilmálar.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Einungis lögmenn í innheimtustarfsemi, skiptastjórar í gjaldþrota- eða dánarbúum, umsjónarmenn skv. lögum um tímabundna greiðsluaðlögun
                                                    og opinberir aðilar sem til þess hafa lagaheimild geta fengið aðgang.
                                                </p>
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <p className='paragraph--bold'>Vegna kennitöluleitar í fasteignaskrá til að kanna eignastöðu og eignasögu lögaðila:</p>
                                        <ol className="disclist" type="i">
                                            <li>
                                                <p className='paragraph'>
                                                    Einungis sérstaklega skráðir starfsmenn hjá hverjum notanda hafa heimild til að gera fyrirspurnir á grundvelli samnings þessa og mega
                                                    þeir ekki afhenda öðrum lykilorð sín eða kynna með öðrum hætti aðgangsheimildir sínar.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Starfsmaður hjá notanda, sem hefur heimild til að sækja upplýsingar í fasteignaskrá, er bundinn þagnarskyldu um innihald þeirra
                                                    upplýsinga gagnvart aðilum er ekki þurfa starfs síns vegna að hafa vitneskju um innihaldið.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Hvorki HMS né Keldan bera ábyrgð á hugsanlegu tjóni sem kann að hljótast af því að upplýsingar úr fasteignaskrá reynist rangar.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Keldan áskilur sér rétt til að vera óbundin af einstökum ákvæðum samnings þessa, ef lögum er breytt á þann veg, að efndir eru ómögulegar
                                                    af hálfu Keldunnar. Sama gildir ef aðrar opinberar reglur standa í vegi fyrir efndum, svo og vis major af einhverri tegund. HMS gerir slíkt hið sama.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Notandi skal eyða upplýsingum úr fasteignaskrá þegar ekki eru málefnalegar ástæður til að varðveita þær.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Sé brotið gegn framangreindum skilyrðum, reglugerð um þinglýsingar eða samningsaðili uppfyllir ekki lengur skilyrði til að hafa aðgang
                                                    að kerfinu skal fella aðgangsheimildina niður.
                                                </p>
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <p className='paragraph--bold'>Vegna ökutækjaskrár:</p>
                                        <ol className="disclist" type="i">
                                            <li>
                                                <p className='paragraph'>
                                                    Keldan skráir nafn og kennitölu þess sem gerir fyrirspurn í ökutækjaskrá.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Ef viðtakandi upplýsinga er einstaklingur skal hann sanna á sér deili með framvísun persónuskilríkja.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Viðtakanda upplýsinga er aðeins heimilt að vista upplýsingarnar í eigin kerfum en er óheimilt að safna þeim í sérstakan gagnagrunn
                                                    um ökutæki.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Viðtakanda upplýsinga er aðeins heimilt að nota upplýsingarnar í eigin þágu en er óheimilt að miðla þeim til þriðja aðila eða birta
                                                    þær opinberlega nema að því leyti sem það getur talist eðlilegur þáttur í starfsemi viðtakanda. Persónuupplýsingar má þó aldrei birta
                                                    opinberlega.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Viðtakandi skal kynna sér starfsreglur um upplýsingamiðlun úr ökutækjaskrá.
                                                </p>
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <p className='paragraph--bold'>Vegna kennitöluleitar í ökutækjaskrá:</p>
                                        <ol className="disclist" type="i">
                                            <li>
                                                <div>
                                                    <p className='paragraph'>
                                                        Auk almennra skilmála gilda eftirfarandi skilmálar um aðgang lögmanna og skiptastjóra að kennitöluuppflettingum:
                                                    </p>
                                                    <ol type="i">
                                                        <li>
                                                            <p className='paragraph'>
                                                                Lögmenn og skiptastjórar skulu undirrita sérstaka yfirlýsingu um að upplýsingarnar verði aðeins notaðar í þeim tilvikum sem
                                                                slíkt er heimilt skv. grein 4.3 í starfsreglum Samgöngustofu um upplýsingamiðlun úr ökutækjaskrá.
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className='paragraph'>
                                                                Aðeins er heimilt að veita lögmanni og skiptastjóra sjálfum aðgang að uppflettingum eftir kennitölu. Allar uppflettingar sem
                                                                eru framkvæmdar með þeirra aðgangi eru á þeirra ábyrgð og þeir skulu gæta þess að enginn óviðkomandi geti nýtt aðganginn.
                                                            </p>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <p className='paragraph'>
                                                        Auk almennra skilmála gilda neðangreindir skilmálar um aðgang aðila að ökutækjum í sinni eigu eða umráðum:
                                                    </p>
                                                    <ol type="i">
                                                        <li>
                                                            <p className='paragraph'>
                                                                Beiðni um aðgang að ökutækjum í eigu tiltekins aðila skal staðfest af eiganda sjálfum eða einhverjum sem hefur heimild til
                                                                að skuldbinda lögaðila.
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className='paragraph'>
                                                                Aðili sem fær aðgang að eigin kennitölu ber ábyrgð á öllum uppflettingum sem eru framkvæmdar í hans nafni og skal gæta þess
                                                                að enginn óviðkomandi aðili geti nýtt aðganginn.
                                                            </p>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <p className='paragraph--bold'>Vegna vinnuvélaskrár:</p>
                                        <ol className="disclist" type="i">
                                            <li>
                                                <p className='paragraph'>
                                                    Keldan skráir nafn og kennitölu þess sem gerir fyrirspurn í vinnuvélaskrá.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Ef viðtakandi upplýsinga er einstaklingur skal hann sanna á sér deili með framvísun persónuskilríkja.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Viðtakanda upplýsinga er heimilt að vista upplýsingarnar í eigin kerfum en er óheimilt að safna þeim í sérstakan gagnagrunn yfir vinnuvélar.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Viðtakanda upplýsinga er aðeins heimilt að nota upplýsingarnar í eigin þágu en er óheimilt að miðla þeim til þriðja aðila eða birta
                                                    þær opinberlega nema að því leyti sem það getur talist eðlilegur þáttur í starfsemi viðtakanda. Persónuupplýsingar má þó aldrei
                                                    birta opinberlega.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='paragraph'>
                                                    Viðtakandi skal kynna sér starfsreglur um upplýsingamiðlun úr vinnuvélaskrá.
                                                </p>
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <p className='paragraph--bold'>Vegna kennitöluleitar í vinnuvélaskrá:</p>
                                        <ol className="disclist" type="i">
                                            <li>
                                                <div>
                                                    <p className='paragraph'>
                                                        Auk almennra skilmála gilda eftirfarandi skilmálar um aðgang lögmanna og skiptastjóra að kennitöluuppflettingum:
                                                    </p>
                                                    <ol type="i">
                                                        <li>
                                                            <p className='paragraph'>
                                                                Lögmenn og skiptastjórar skulu undirrita sérstaka yfirlýsingu um að upplýsingarnar verði aðeins notaðar í þeim tilvikum sem
                                                                slíkt er heimilt skv. grein 4.3 í starfsreglum Vinnueftirlitsins um upplýsingamiðlun úr vinnuvélaskrá.
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className='paragraph'>
                                                                Aðeins er heimilt að veita lögmanni og skiptastjóra sjálfum aðgang að uppflettingum eftir kennitölu. Allar uppflettingar
                                                                sem eru framkvæmdar með þeirra aðgangi eru á þeirra ábyrgð og þeir skulu gæta þess að enginn óviðkomandi geti nýtt aðganginn.
                                                            </p>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <p className='paragraph'>
                                                        Auk almennra skilmála gilda neðangreindir skilmálar um aðgang aðila að vinnuvélum í sinni eigu eða umráðum:
                                                    </p>
                                                    <ol type="i">
                                                        <li>
                                                            <p className='paragraph'>
                                                                Beiðni um aðgang að vinnuvélum í eigu tiltekins aðila skal staðfest af eiganda sjálfum eða einhverjum sem hefur heimild til
                                                                að skuldbinda lögaðila.
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className='paragraph'>
                                                                Aðili sem fær aðgang að eigin kennitölu ber ábyrgð á öllum uppflettingum sem eru framkvæmdar í hans nafni og skal gæta þess
                                                                að enginn óviðkomandi aðili geti nýtt aðganginn.
                                                            </p>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                            </ol>
                        </ScrollSpySection>
                    </ScrollSpy>
                </div>
            </article>
        </div >
    )
}

export default Notkunar;