// Package imports:
import React, { memo, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import cx from 'classnames';
// Component imports:
import Link from '../../ui-elements/Link/Link';
import Label from '../../ui-elements/Label/Label';
// Service imports:
import { gtmPush } from '../../services/utils';

interface IProps {
    title: string | null,
    description: string,
    date: JSX.Element | Date | string | null,
    label: string | null | undefined,
    source: string | null | undefined,
    link: string | null,
    isNew: boolean | null,
    seen: boolean ,
}

const DagatalItem: React.FC<IProps>= ({title, description, date, source, link, isNew, seen}) => {
    const [ isOpen, setIsOpen ] = useState(false)
    const [ isSeen, setIsSeen] = useState(false);
    const [ isHighlighted, setIsHighlighted] = useState(false)

    const expandDagatalItem = () => {
        setIsOpen(!isOpen);
        setIsSeen(true)
        setIsHighlighted(false)
    }

    useEffect(() => {
        if(isNew) {
            setIsHighlighted(true)

             const timer = setTimeout(() => {
                setIsHighlighted(false)
            }, 60000)

            return () => clearTimeout(timer);
        }

    }, [isNew])

    useEffect(() => {
        setIsSeen(seen)
    }, [seen])
    return (
        <div
            className={cx('dagatal-item', 
                { 
                    'is-open': isOpen,
                    'is-seen': isSeen,
                    'is-new': isNew,
                    'is-highlighted': isHighlighted
                }
            )}
        >
            <div
                className="dagatal-item__head"
                onClick={() => {expandDagatalItem(); gtmPush('DagatalItemClick', title ?? 'Title is null', (!isOpen) ? 'Opened' : 'Closed');}}
            >
                <button 
                    className="dagatal-item__trigger"
                >
                    {title}

                    {!isSeen && isNew && (
                        <span className="dagatal-item__notify"></span>
                    )}
                </button>

                <div className="dagatal-item__meta">
                    <span className="dagatal-item__time">{date}</span>
                </div>
            </div>

            <AnimateHeight
                className="dagatal-item__content"
                duration={300}
                height={isOpen ? 'auto' : 0}
            >
                <div className="dagatal-item__body">
                    <div className="dagatal-item__entry" dangerouslySetInnerHTML={{__html: description}} />

                    <div className="dagatal-item__actions">
                        <Link
                            targetBlank
                            url={link ?? '#'}
                            linkSize='12'
                            icon='forward'
                        >
                            Skoða atburð
                        </Link>
                        <Label labelType='origin' text={source ?? ''} size='sm' />
                    </div>
                </div>
            </AnimateHeight>
        </div>
    )
}

export default memo(DagatalItem);