// Package imports:
import React, { useRef, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
// Component imports:
import Button from '../../../ui-elements/Button/Button';
import Input from '../../../ui-elements/Input/Formik/Input';
import Loading from '../../../ui-elements/Loading/Loading';
import Link from '../../../ui-elements/Link/Link';
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Table from '../../../ui-elements/Table/Table';
import Tooltip from '../../../ui-elements/Tooltip/Tooltip';
import SearchResults from '../../../ui-elements/SearchResults/SearchResults';
// Service imports:
import { isSSN, stripSSN } from '../../../services/utils';
import { useBuyingProcess } from '../../../services/buyhook';
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { IndividualSearch, IndividualSearchResult } from '../../../types/IndividualTypes';
import { IKeldanApiResponse } from '../../../types/KeldanTypes';

interface IPurchaseThjodskraInformation {
    ssn: string,
    whereClick: 'form' | 'list'
}

interface IProps {
    isAuthenticated: boolean
}

const Thjodskra: React.FC<IProps> = ({ isAuthenticated }) => {
    const [ searchResults, setSearchResults ] = useState<IndividualSearchResult[]>([]);
    const [ searchButtonLoading, setSearchButtonLoading ] = useState(false);
    const [ searchTermResultName, setSearchTermResultName ] = useState('');

    const searchResultRef = useRef<HTMLDivElement | null>(null)

    const { tryToBuyItem, tryPurchaseItem, setToastError, resetState, ModalsAndToasts } = useBuyingProcess(async (purchaseThjodskraInfo: IPurchaseThjodskraInformation, modalReference: string) => {
        const { ssn: rawSsn } = purchaseThjodskraInfo;
        const ssn = stripSSN(rawSsn);
        try {
            const requestQuery = new URLSearchParams({
                id: ssn ?? '', 
                reference: modalReference
            })
            const url = `${GET_KELDAN_API_URL()}/Individual/BuyProfile?${requestQuery.toString()}`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                    return;
                }
                const responseBody: IKeldanApiResponse<IndividualSearch> = await response.json();
                const { message } = responseBody;
                if (message === ErrorMessages.NoCard) {
                    resetState('no card');
                } else if (message) resetState({
                    type: 'alert',
                    headText: message
                });
                else resetState('bad request');
            } else {
                resetState('response not ok');
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            resetState('network error');
        }
    }, 'þjóðskrá')

    const handleSearch = async (ssn: string) => {
        setSearchButtonLoading(true);
        try {
            const requestQuery = new URLSearchParams({
                term: ssn
            })
            const url = `${GET_KELDAN_API_URL()}/Individual/Search`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (!response.ok) {
                setToastError('response not ok');
                setSearchButtonLoading(false);
                return;
            }
            if (response.redirected) {
                window.location.href = response.url;
                return;
            }
            const responseBody: IKeldanApiResponse<IndividualSearch> = await response.json();
            if (responseBody.success) {
                if (responseBody.result) {
                    setSearchResults(responseBody.result.list.list.list);
                    setSearchTermResultName(ssn);
                    searchResultRef.current?.scrollIntoView();
                }
            } else {
                const { message } = responseBody;
                if (message === ErrorMessages.NoCard) {
                    setToastError('no card');
                } else if (message) {
                    setToastError({
                        type: 'alert',
                        headText: message
                    });
                } else {
                    setToastError('bad request');
                }
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setToastError('network error');
        }
        setSearchButtonLoading(false);
    }
    
    const displayForm = (type: 'disabled' | 'enabled') => (
        <Formik
            initialValues={{
                ssn: '',
                tilvisun: ''
            }}
            validationSchema={Yup.object({
                ssn: Yup.string().required('Leit má ekki vera tóm')
            })}
            onSubmit={(values) => {
                const trimmed = stripSSN(values.ssn.trim());
                //very unlikely that this will ever happen since we disable submit if value is undefined or null
                if(trimmed === undefined || trimmed === null) {setToastError('bad request');return}
                if (isSSN(trimmed)) {
                    tryPurchaseItem(
                        { ssn: trimmed, whereClick: 'form' },
                        'confirm-modal-with-reference',
                        values.tilvisun
                    )
                } else {
                    handleSearch(trimmed)
                }
            }}
            component={(props: { values: ISubFormValues }) => (
                <SubFormComponent
                    values={props.values}
                    disabled={(type === 'disabled')}
                    loading={(tryToBuyItem !== null && tryToBuyItem.whereClick === 'form') || searchButtonLoading}
                />
            )}
        />
    )

    const displayResults = () => {
        if (searchResults === undefined) return <Loading />
        if (searchResults.length === 0) {
            return <p>Engar niðurstöður fundust fyrir leitarorð: {searchTermResultName}</p>
        }
        return <Table
            tableSize='lg'
            data={searchResults}
            columns={[{
                title: 'Nafn',
                renderCell: ({ name }) => name,
                textAlign: 'left',
                simpleSortable: ({ name }) => name,
            }, {
                title: 'Heimili',
                renderCell: ({ address }) => address,
                textAlign: 'left',
                simpleSortable: ({ address }) => address
            }, {
                title: '',
                renderCell: ({ ssn }) => (
                    <Button
                        onClick={() => tryPurchaseItem({
                            ssn,
                            whereClick: 'list'
                        }, 'confirm-modal-with-reference')}
                        showLoader={(
                            tryToBuyItem !== null &&
                            tryToBuyItem.ssn === ssn &&
                            tryToBuyItem.whereClick === 'list'
                        )}
                        size='sm'
                        buttonType='buy'
                    >
                        Kaupa
                    </Button>
                ),
            }]}
        />
    }

    return (
        <div className='main KCL_thjodskra-search'>
            <div className='main__inner-fluid'>
                <div className="shell">
                    <HeadSection
                        title='Þjóðskrá'
                        summary={
                            <p>Hér getur þú flett upp nöfnum og kennitölum í Þjóðskrá Íslands. </p>
                        }
                        description={
                            <p className='paragraph--bold'>
                                Athugið að ef leitað er eftir gildri kennitölu er um kaup að ræða. <Link linkSize='18' url='/Verdskra#verdskra'>Sjá verðskrá.</Link>
                            </p>
                        }
                        showRecentReports={isAuthenticated}
                    />
                    <div>
                        {(isAuthenticated)
                            ? displayForm('enabled')
                            : <Tooltip
                                tooltip={
                                    <span>
                                        Þú þarft að skrá þig inn og vera með skráð kreditkort til að nýta þessa þjónustu.
                                    </span>
                                }
                                text={displayForm('disabled')}
                            />
                        }
                    </div>
                </div>
                <SearchResults
                    title={`Leitarniðurstöður fyrir ${searchTermResultName}`}
                    show={searchTermResultName !== null && searchTermResultName.length > 0}
                    closeResults={() => setSearchTermResultName('')}
                    ref={searchResultRef}
                    component={
                        <DisplayBox>
                            {displayResults()}
                        </DisplayBox>
                    }
                />
            </div>
            <ModalsAndToasts />
        </div>
    );
}

export default Thjodskra;
interface ISubFormValues {
    ssn: string,
    tilvisun: string
}

interface ISubFormProps {
    values: ISubFormValues
    loading: boolean
    disabled: boolean
}


const SubFormComponent: React.FC<ISubFormProps> = ({
    values,
    disabled,
    loading
}) => (
    <Form>
        <div className="form__body">
            <div className="form__section">
                <div className="form__row">
                    <div className="form__col">
                        <Input
                            label='Leit í þjóðskrá'
                            disabled={disabled}
                            name="ssn"
                            id="ssn"
                            value={values.ssn}
                        />
                    </div>
                    <div className='form__col'>
                        <Input
                            label='Tilvísun á reikning'
                            disabled={disabled}
                            name='tilvisun'
                            id='tilvisun'
                            value={values.tilvisun}
                        />
                    </div>
                    <div className="form__col actions">
                        <div className="form__actions">
                            {isSSN(values.ssn)
                                ? <Button
                                    buttonType='buy'
                                    showLoader={loading}
                                    disabled={disabled}
                                    size="lg"
                                >
                                    Kaupa
                                </Button>
                                : <Button
                                    showLoader={loading}
                                    disabled={disabled}
                                    size="lg"
                                >
                                    Leita
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Form>
)