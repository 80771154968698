// Package imports:
import React, { useMemo } from 'react';
// Component imports:
import Button from '../../ui-elements/Button/Button';

interface IProps {
    errorStatus?: number,
}

const ErrorPage: React.FC<IProps>= ({
    errorStatus
}) => {
    const { title, message } = useMemo(() => {
        switch (errorStatus) {
            case 402:
                return {
                    title: 'Greiðsluupplýsingar vantar',
                    message: <><p>Kreditkortaupplýsingar eru ekki fullnægjandi.</p> <Button anchorProps={{href: '/Min-Kelda/Stillingar'}}>Stillingar</Button></>
                };
            case 403:
                return {
                    title: '403: Aðgangur bannaður',
                    message: <p>Þú hefur ekki tilskilin réttindi fyrir þessa síðu.</p>
                };
            case 404:
                return {
                    title: '404: Síða finnst ekki',
                    message: <p>Mögulega er sambandsleysi við vefþjón eða síðan sem þú ert að leita að er ekki lengur til.</p>
                };
            case 410:
                return {
                    title: '410: Síðu eytt',
                    message: <p>Síðan sem þú ert að leita að er ekki lengur til.</p>
                };
            case 500:
                return {
                    title: '500: Innri netþjónsvilla',
                    message: <p>Svo virðist sem netþjónninn hafi lent í aðstæðum sem hann veit ekki hvernig á að höndla.</p>
                };
            default:
                return {
                    title: 'Úps... þetta er vandræðalegt',
                    message: <p>Mögulega er sambandsleysi við vefþjón eða síðan sem þú ert að leita að er ekki lengur til.</p>
                };
        }
    }, [errorStatus])

    return (
        <div className='KCL_errorPage'>
            <div className="errorPage-leftBox">
                <h1>{title}</h1>
                {errorStatus === 402 || errorStatus === 403 || errorStatus === 410
                    ? <div className="errorPage-message">
                        <p className='summary-paragraph'>{message}</p>
                    </div>
                    : <>
                        <div className="errorPage-message">
                            <p className='summary-paragraph'>{message}</p>
                            <p className='summary-paragraph'>Keldufólkið er líklega á fullu að vinna að lausn. Á meðan getur þú litið til dæmis á Kaupsamninga:</p>
                        </div>
                        <Button anchorProps={{href: "/Fasteignir/Leit#Kaupsamningar"}}>
                            Skoða kaupsamninga
                        </Button>
                    </>
                }
            </div>
            <div className="errorPage-rightBox">
                <h1 className="status-code errorPage-errorStatusCode1">{errorStatus !== undefined && errorStatus !== 402 ? errorStatus : '???'}</h1>
                <h1 className="status-code errorPage-errorStatusCode2">{errorStatus !== undefined && errorStatus !== 402 ? errorStatus : '???'}</h1>
                <h1 className="status-code errorPage-errorStatusCode3">{errorStatus !== undefined && errorStatus !== 402 ? errorStatus : '???'}</h1> 
            </div>
            
        </div>
    );
}

export default ErrorPage;