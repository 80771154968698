// Package imports:
import React, { useContext, useEffect, useState } from 'react';
// Component imporst:
import SjodirComp from '../../components/Sjodir/Sjodir';
// Service imports:
import { DEFAULT_REFRESH_RATE } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';
// Context imports:
import AccessTokenContextProvider, { AccessTokenContext } from '../../contexts/AccessTokenContext';

const SjodirWrapper = () => {
    const accessToken = useContext(AccessTokenContext);
    const [ defaultProps, setDefaultProps ] = useState<IDefaultProps>({
        accessToken,
        refreshRateMs: DEFAULT_REFRESH_RATE
    });

    useEffect(() => {
        setDefaultProps({
            ...defaultProps,
            accessToken
        });
    }, [accessToken]);

    return (
        <SjodirComp {...defaultProps} />
    )
}

const Sjodir: React.FC = () => {
    return (
        <AccessTokenContextProvider>
            <SjodirWrapper />
        </AccessTokenContextProvider>
    );
}

export default Sjodir;