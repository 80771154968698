// Package imports:
import React from "react";
import Bugsnag from "@bugsnag/js";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import download from 'downloadjs';
// Component imports:
import Button from "../../../ui-elements/Button/Button";
import Input from "../../../ui-elements/Input/Formik/Input";
import Link from "../../../ui-elements/Link/Link";
import ExampleReportButton from "../../ExampleReportButton/ExampleReportButton";
import LockIcon from "../../../ui-elements/LockIcon/LockIcon";
import Tooltip from "../../../ui-elements/Tooltip/Tooltip";
// Service imports:
import { GET_KELDAN_API_URL } from "../../../services/config";
import { useBuyingProcess } from "../../../services/buyhook";
import { ErrorMessages } from "../../../services/errorMessages";
// Type imports:
import { IKeldanApiContact } from "../../../types/KeldanTypes";

interface IProps {
    isAuthenticated: boolean
}

const HistorySearch: React.FC<IProps> = ({
    isAuthenticated
}) => {
    const { tryPurchaseItem, tryToBuyItem, resetState, ModalsAndToasts } = useBuyingProcess(async (values: IFormValues, modalReference) => {
        try {
            const requestQuery = new URLSearchParams({
                Permanent: values.fastanr,
                Registration: values.skraningarnr,
                Vin: values.verksmidjunr,
                reference: modalReference
            });
            const url = `${GET_KELDAN_API_URL()}/Vehicle/HistorySearch`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (!response.ok) {
                resetState('response not ok');
                return;
            }
            if (response.redirected) {
                window.location.href = response.url;
                return;
            }
            const contentTypeHeader = response.headers.get('Content-Type');
            if (contentTypeHeader?.includes('pdf')) {
                // Trying blob.
                const blob = await response.blob();
                const fileName = values.fastanr || values.skraningarnr || values.verksmidjunr ||values.tilvisun || 'okutaekjaferilskra';
                download(blob, `${fileName}.pdf`, 'application/pdf');
            } else if (contentTypeHeader?.includes('json')) {
                const responseBody: IKeldanApiContact = await response.json();
                const { message } = responseBody;
                if (message === ErrorMessages.NoCard) {
                    resetState('no card');
                } else if (message) {
                    resetState({
                        type: 'alert',
                        headText: message
                    })
                } else {
                    resetState('bad request');
                }
            } else {
                resetState('network error');
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            resetState('network error');
        }
    }, 'ökutækjaskrá');

    const displayForm = (status: 'enabled' | 'disabled') => {
        return (
            <Formik
                initialValues={{
                    fastanr: '',
                    skraningarnr: '',
                    verksmidjunr: '',
                    tilvisun: '',
                }}
                validationSchema={Yup.object({
                    fastanr: Yup.string(),
                    skraningarnr: Yup.string(),
                    verksmidjunr: Yup.string(),
                    tilvisun: Yup.string(),
                })}
                onSubmit={(values) => tryPurchaseItem(
                    values,
                    'confirm-modal-with-reference',
                    values.tilvisun
                )}
                component={(props: { values: IFormValues }) => (
                    <SubFormComponent
                        values={props.values}
                        loading={(tryToBuyItem !== null)}
                        disabled={status === 'disabled'}
                    />
                )}
            />
        );
    }

    return (
        <div className="Okutaekjaskra">
            <div className="section__body">
                <div className='KCL_vehicle-search-form'>
                    <h3>
                        {!isAuthenticated && <LockIcon size='lg' />}
                        <span>Ferilskrá ökutækis</span>
                    </h3>
                    <div className='search-form-description'>
                        <p className='paragraph'>Með því að slá inn fasta-, skráningar- eða verksmiðjunúmer er hægt að kaupa upplýsingar um ferilskrá ökutækis.</p>
                        <p className='paragraph--bold'>Athugið að gögnin eru sótt sem PDF skrá. <Link linkSize="18" url="/Verdskra#verdskra">Sjá verðskrá.</Link></p>
                    </div>
                    {isAuthenticated
                        ? displayForm('enabled')
                        : <Tooltip
                            tooltip={
                                <span>
                                    Þú þarft að skrá þig inn og vera með skráð kreditkort til að nýta þessa þjónustu.
                                </span>
                            }
                            text={displayForm('disabled')}
                        />
                    }
                    <ExampleReportButton size='lg' reportType='vehicle-history' />
                </div>
            </div>
            <ModalsAndToasts />
        </div>
    );
}

export default HistorySearch;

interface IFormValues {
    fastanr: string,
    skraningarnr: string,
    verksmidjunr: string,
    tilvisun: string,
}

interface ISubFormProps {
    values: IFormValues,
    loading: boolean,
    disabled: boolean
}

const SubFormComponent: React.FC<ISubFormProps> = ({
    values,
    loading,
    disabled
}) => {
    return (
        <Form>
            <div className="form__body">
                <div className="form__section">
                    <div className="form__row">
                        <div className="form__col">
                            <Input
                                label="Fastanúmer"
                                name="fastanr"
                                id="fastanr"
                                value={values.fastanr}
                                disabled={(values.skraningarnr !== '' || values.verksmidjunr !== '') || disabled}
                            />
                        </div>
                        <div className="form__col">
                            <Input
                                label="Skráningarnúmer"
                                name="skraningarnr"
                                id="skraningarnr"
                                value={values.skraningarnr}
                                disabled={(values.fastanr !== '' || values.verksmidjunr !== '') || disabled}
                            />
                        </div>
                        <div className="form__col">
                            <Input
                                label="Verksmiðjunúmer"
                                name="verksmidjunr"
                                id="verksmidjunr"
                                value={values.verksmidjunr}
                                disabled={(values.fastanr !== '' || values.skraningarnr !== '') || disabled}
                            />
                        </div>
                        <div className="form__col">
                            <Input
                                label="Tilvísun á reikning"
                                name="tilvisun"
                                id="tilvisun"
                                value={values.tilvisun}
                            />
                        </div>
                        <div className="form__col actions">
                            <div className="form__actions">
                                <Button
                                    showLoader={loading}
                                    buttonType="buy"
                                    size="lg"
                                    disabled={disabled}
                                >
                                    Kaupa
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
}

