// Package imports:
import React from 'react';
// Component imports:
import SingleIndexPage, { IndexTypes } from '../../components/SingleIndexPage/SingleIndexPage';
import Entrypoint from '../Entrypoint';

interface IOwnProps {
    symbol?: string | null
}

const SingleIndex: React.FC<IOwnProps> = ({
    symbol
}) => {
    return (
        <Entrypoint>
            <SingleIndexPage indexType={(symbol ?? 'OMXI15') as IndexTypes} />
        </Entrypoint>
    );
}

export default SingleIndex;