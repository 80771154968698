// Package imports:
import React from 'react';
// Component imports:
import GEMMAQContactForm from '../ContactForms/GEMMAQContactForm/GEMMAQContactForm';

const GEMMAQLicense: React.FC = () => {
    return (
        <div className='main KCL_GEMMAQ-publishing'>
            <div className='section section--contact'>
                <div className="shell">
                    <div className='section__inner'>
                        <div className='section__head'>
                            <h1>GEMMAQ kynjakvarði</h1>
                            <p className='summary-paragraph'>
                                GEMMAQ kynjakvarðinn veitir upplýsingar um kynjahlutföll í leiðtogastörfum fyrirtækja á íslenskum markaði, skráð og óskráð, eins og staðan er hverju sinni.
                            </p>
                            <p className='paragraph'>
                                Einkunnir eru gefnar á skalanum 0-10, þar sem 10 er hæsta einkunnin, miðað við hlutfall kvenna í framkvæmdastjórn og stjórn félagsins. Kvarðinn tekur til greina bæði kyn forstjóra og stjórnarformanns - sem reiknast jafnframt inn í GEMMAQ einkunn félags þegar kona gegnir stöðunni.
                            </p>
                        </div>

                        <div className="section__body">
                            <div className='section__content form form--contact'>
                                <GEMMAQContactForm publishing />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GEMMAQLicense;