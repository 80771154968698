// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import SignUpPage from '../../components/SignUp/SignUpPages/SignUpPage';
// Type imports:
import { KeldanSubOption } from '../../types/SignUpTypes';

const SignUp: React.FC<{plan?: KeldanSubOption}> = ({
    plan = 'free'
}) => {
    return (
        <Entrypoint>
            <SignUpPage subscriptionChoice={plan} />
        </Entrypoint>
    );
}

export default SignUp;