// Package imports:
import React, { useMemo } from 'react';
import { SeriesOptionsType } from 'highcharts/highstock';
import { format } from 'date-fns';
import ISLocale from 'date-fns/locale/is';
import cx from 'classnames';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Label from '../../../ui-elements/Label/Label';
import Alert from '../../../ui-elements/Alert/Alert';
import ChartLoading from '../../../ui-elements/ChartLoading/ChartLoading';
import DefaultChart from '../DefaultChart/DefaultChart';
// Service imports:
import { getAPItoday, formatNumber } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IApiLmdIndexSnapshot } from '../../../types/HlutabrefTypes';
import { IDefaultProps } from '../../../types/Types';
import { IApiLmdCurrency } from '../../../types/CurrencyTypes';
import { IApiHistoryTimeseriesShortDATA } from '../../../types/MarketTypes';

type ChartType = 'index' | 'currency' | 'fund';

interface IChartDatum {
    x: number | undefined
    y: number | undefined
};

interface IChartDataInfo {
    name: string,
    data: IChartDatum[]
};


interface IOwnProps {
    chartType?: ChartType,
    symbol?: string,
    title?: string,
    height?: number,
};

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const BasicChart: React.FC<Props> = ({
    chartType,
    symbol,
    title,
    height = 500,
    accessToken,
    refreshRateMs,
    className,
    ...props
}) => {
    const baseCurr = symbol?.substring(0,3);
    const crossCurr = symbol?.substring(3,6);
    const today = new Date();
    const from = new Date(today.getFullYear() - 5, today.getMonth() + 1, today.getDate());
    const getUrl = () => {
        switch (chartType) {
            case 'index':
                return `/v1/market_data/v1/indexes/symbol/${symbol}/snapshot?from_date=${getAPItoday(from)}&to_date=${getAPItoday(today)}`;
            case 'currency':
                return `/v1/market_data/v1/currency_cross/base_currency/${baseCurr?.toUpperCase()}/cross_currency/${crossCurr?.toUpperCase()}/history/?from_date=${getAPItoday(from)}&to_date=${getAPItoday(today)}`;
            default:
                return `/v1/market_data/v1/funds/${symbol}/history_timeseries/?from_date=${getAPItoday(from)}&to_date=${getAPItoday(today)}`;
        }
    }
    const [apiData] = useApiLmdData<IApiLmdIndexSnapshot[] | IApiLmdCurrency[] | IApiHistoryTimeseriesShortDATA>(
        getUrl(),
        accessToken,
        refreshRateMs      
    );
    
    const { series, yAxisTitle }: { series: SeriesOptionsType[], yAxisTitle: string } = useMemo(() => {
        const { data, error } = apiData;
        if (data === null || error) return { series: [], yAxisTitle: '' };

        const chartDataInfo: IChartDataInfo = {
            name: '',
            data: []
        }

        let yAxisTitle = '';

        switch (chartType) {
            case 'index':
                chartDataInfo.name =  symbol ?? '';
                yAxisTitle = 'Gildi';
                // If chart type is index, data is IApiLmdIndexSnapshot[]
                // @ts-ignore
                for (let datum of data.filter(x => x.current_value !== null && x.trade_date !== null)) {
                    chartDataInfo.data.push({
                        x: Date.parse(datum.trade_date.replace(/ /g, "T")),
                        y: datum.current_value
                    });
                }
                break;
            case 'currency':
                // If chart type is currency, data is IApiLmdCurrency[]
                //@ts-ignore
                chartDataInfo.name = (data[0]?.Basecurrency ?? '') + (data[0]?.Crosscurrency ?? '');
                yAxisTitle = 'Kross';
                //@ts-ignore
                for (let datum of data.filter(x => x.Datetimerate !== null && x.Averagerate !== null)) {
                    chartDataInfo.data.push({
                        x: Date.parse(datum.Datetimerate.replace(/ /g, "T")),
                        y: datum.Averagerate
                    });
                }
                break;
            case 'fund':
                // If chart type is fund, data is IApiHistoryTimeseriesShortDATA
                //@ts-ignore
                chartDataInfo.name = data.name;
                yAxisTitle = 'Verð';
                //@ts-ignore
                for (let datum of data.data) {
                    chartDataInfo.data.push({
                        x: datum[0],
                        y: datum[1]
                    });
                }
                break;
            default:
                break;
        }

        return {
            series: [{
                type: 'line',
                id: 'AAPL',
                name: chartDataInfo.name,
                data: chartDataInfo.data,
                tooltip: {
                    valueDecimals: 2
                },
                yAxis: 0
            }],
            yAxisTitle: yAxisTitle
        }
    }, [apiData]);

    if (chartType === 'index') {
        if (apiData.data === null ) {
            return <DisplayBox 
                        className='KCL_chart chart--basic'
                        title={title}
                        {...props}
                    >   
                        <div className='no-data-info'>
                            <ChartLoading />
                        </div>
                    </DisplayBox>
        }
        //@ts-ignore
        if (apiData.data.length === 0) {
            return <DisplayBox 
                        className='KCL_chart chart--basic'
                        title={title}
                        {...props}
                    >   
                        <div className='no-data-info'>
                            <Alert type='info' headText={'Engin söguleg gögn fengust fyrir þessa vísitölu ' + symbol} />
                        </div>
                    </DisplayBox>
        }
    } else if (chartType === 'fund') {
        if (apiData.data === null) {
            return <DisplayBox
                        className='KCL_chart chart--basic'
                        title={title}
                        {...props}
                    >   
                        <div className='no-data-info'>
                            <ChartLoading />
                        </div>
                    </DisplayBox>
        }
        //@ts-ignore
        if (apiData.data.length === 0) {
            return <DisplayBox
                        className='KCL_chart chart--basic'
                        title={title}
                        {...props}
                    >   
                        <div className='no-data-info'>
                            <Alert type='info' headText={'Engin söguleg gögn fengust fyrir þennan sjóð ' + symbol} />
                        </div>
                    </DisplayBox>
        }
    }
    return (
        <DisplayBox 
            className={cx('KCL_chart chart--basic', className)}
            title={title}
            {...props}
        >
            <DefaultChart
                height={height}
                tooltipFormatter={
                    function() {
                        //filtera einungis það sem er visible af því ef það er notað points[0] crashar allt þegar eitthvað series er disable-að
                        return (`<div class="chart-tooltip">
                                    <p class="tooltip-date">${format(this.x, 'EEEE, dd. MMM yyyy', {locale: ISLocale})}</p>
                                    ${this.points?.filter(x => x.point.visible === true).map(x => (
                                        `<p><span style="color: ${x.color}">${x.series.getName()}</span>: ${formatNumber(this.y, null, 3)}</p>`
                                    ))}
                                </div>`).replace(/>,</g,'><')
                    }
                }
                yAxes={[{
                    title: {
                        text: yAxisTitle,
                        offset: 52,
                        x: 5
                    },
                    labels: {
                        x: -11,
                        y: 2
                    }
                }]}
                series={series}
                seriesOptions={{ turboThreshold: 4000 }}
            />
            {( chartType === 'currency') 
                ? <Label
                    text="Seðlabanki Íslands"
                    labelType="origin"
                    url='https://sedlabanki.is'
                />
                : null
            }
        </DisplayBox>
    );
}

export default BasicChart;