// Package imports:
import React, { useState } from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../ui-elements/Link/Link';
import Loading from '../../ui-elements/Loading/Loading';
import Alert from '../../ui-elements/Alert/Alert';
// Service imports:
import { getTableDate } from '../../services/utils';
import { useRefreshRateFetching } from '../../services/hooks';
import { GET_NEWS_LMD_URL } from '../../services/config';
import { ErrorMessages } from '../../services/errorMessages';
// Type imports:
import { Fetched, IDefaultProps } from '../../types/Types';
import { INewsLmdNewsItem, INewsLmdResponse } from '../../types/NewsTypes';

interface IOwnProps {
    symbol?: string,
    limit?: number
}

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const SharesNews: React.FC<Props> = ({
    symbol,
    limit = 25,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [data, setData] = useState<Fetched<INewsLmdNewsItem[]>>(null);

    const fetchData = async () => {
        try {
            const url = `${GET_NEWS_LMD_URL()}/search/query?f=symbol.ticker&limit=${limit}&q=("${symbol}_OMX_IS" "${symbol}")&start=0`;
            const response = await fetch(url);
            if (response.ok) {
                const body: INewsLmdResponse = await response.json();
                setData(body.feedItems);
            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            
            setData(new Error(ErrorMessages.NetworkError));
        }
    };

    useRefreshRateFetching(
        fetchData,
        refreshRateMs,
        accessToken
    );

    const displayNews = () => {
        if (data === null) return <Loading />
        if (data instanceof Error) return <Alert type="error" headText={data.message} />
        if (data.length === 0) return <p>Engar Fréttir fundust</p>
        return data.map((d) => (
            <div className="news-row" key={d.id}>
                <span>
                    <Link
                        className="news-title"
                        url={d.link ?? ''}
                        targetBlank
                        linkSize='15'
                        splitLines
                    >
                        {d.title}
                    </Link>
                </span>
                <div className="name-and-time">
                    <span className='source'>{d.fullSource}</span>
                    <span className="time">{getTableDate(d.publish_date, 'HH:MM', '.')}</span>
                </div>
            </div>
        ));
    }

    return (
        <DisplayBox
            className='KCL_news-by-markets'
            title="Fréttir úr Kauphöllinni"
            {...props}
        >
            {displayNews()}
        </DisplayBox>
    );
}

export default SharesNews;