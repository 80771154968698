// Package imports:
import React from 'react';
// Component imports:
import GangverdList from '../GangverdList/GangverdList';
import PropertyNavBar from '../PropertyNavBar/PropertyNavBar';

const Gangverd: React.FC<{id?: string}> = ({
    id
}) => {
    return (
        <div className='main KCL_property-page'>
            <div className='main__inner-fluid'>
                <PropertyNavBar
                    propertyNumber={id}
                />
                <div className='shell'>
                    <div className='property-page-body'>
                        <GangverdList
                            propertyNumber={id}
                            full
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Gangverd;