// Package imports:
import React, { useContext, useEffect, useState } from 'react';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Alert from '../../../ui-elements/Alert/Alert';
import CompanyNavBar from '../CompanyNavBar/CompanyNavBar';
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';
import CompanyTitle from '../CompanyTitle/CompanyTitle';
import Link from '../../../ui-elements/Link/Link';
import Tabs from '../../../ui-elements/Tabs/Tabs';
import EbitdaValuation from './EbidtaValuation';
import FcfValuation from './FcfValuation';
// Type imports:
import { ICompanyViewModel } from '../../../types/CompanyTypes';
import { IDefaultProps } from '../../../types/Types';
// Context imports:
import { AccessTokenContext } from '../../../contexts/AccessTokenContext';

const Valuation: React.FC<ICompanyViewModel> = ({
    id,
    isAuthenticated,
    isSubscriber
}) => {
    const accessToken = useContext(AccessTokenContext);
    const [ defaultProps, setDefaultProps ] = useState<IDefaultProps>({
        accessToken
    });

    useEffect(() => {
        setDefaultProps({
            ...defaultProps,
            accessToken
        });
    }, [accessToken]);

    const [activeTab, setActiveTab] = useState(0);

    return (
        <div className='main KCL_company-page valuation-page'>
            <div className='main__inner-fluid'>
                <div className='head-wrapper'>
                    <CompanyNavBar
                        isAuthenticated={isAuthenticated}
                        isSubscriber={isSubscriber}
                        id={id}
                    />
                    <div className='shell'>
                        <HeadSection
                            title='Verðmat Keldunnar'
                            summary={
                                <p>Verðmat Keldunnar byggir á núvirtu áætluðu sjóðstreymi til eilífðar í íslenskum krónum.</p>
                            }
                            actions={
                                <Link
                                    url='/Markadir/Fjarmalahugtok'
                                    linkSize='16'
                                    icon='forward'
                                >
                                    Nánar um verðmat í Fjármálahugtökum
                                </Link>
                            }
                            rightSide={
                                <Alert
                                    type='info'
                                    headText='Fyrirvari'
                                    text={<p>
                                        Verðmatið er ekki fjárfestingarráðgjöf og skal ekki notað til ákvörðunartöku auk þess sem upplýsingar í verðmatinu kunna að vera rangar.
                                        Þetta verðmat er gert til fróðleiks, samanburðar og skemmtunar en ekki til þess að sýna endanlegt sölu- eða kaupverð félags.<br />
                                        Keldan ehf. ber enga ábyrgð á röngum upplýsingum eða fjárhagstjóni sem kann að verða vegna verðmatsins.
                                    </p>}
                                />
                            }
                        />
                    </div>
                </div>
                <div className='shell main-content'>
                    <CompanyTitle id={id} accessToken={accessToken} />
                    <div className='body'>
                        <div>
                            <DisplayBox
                                displayBoxSize='lg'
                            >
                                <Tabs
                                    tabsSize='lg'
                                    tabs={[{
                                        id: 'FrjalstSjodstreymi',
                                        label: 'Verðmat byggt á frjálsu sjóðstreymi',
                                        component: <FcfValuation id={id} accessToken={accessToken} changeTab={(t) => setActiveTab(t)} />
                                    }, {
                                        id: 'EBITDA',
                                        label: 'Verðmat byggt á EBITDA',
                                        component: <EbitdaValuation id={id} accessToken={accessToken} />
                                    }]}
                                    saveTabToUrl
                                    redirectToTab={activeTab}
                                />
                            </DisplayBox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Valuation;