// Package imports:
import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

type DivProps = React.ClassAttributes<HTMLDivElement>;

interface IOwnProps {
    title?: string,
    show?: boolean,
    closeResults?(): void,
    component?: JSX.Element
}

type Props = DivProps & IOwnProps;

const SearchResults = forwardRef<HTMLDivElement, Props>(({
    title,
    show = false,
    closeResults,
    component,
    ...props
}, ref) => {
    if (!show) return <></>
    return (
        <div className='KCL_search-results section--gray' ref={ref} {...props}>
            <div className='shell'>
                <div className='result_header'>
                    <h2>{title}</h2>
                    {closeResults && <FontAwesomeIcon icon={faXmark} onClick={closeResults} className='icon-fa' />}
                </div>
                {component}
            </div>
        </div>
    );
})

export default SearchResults;