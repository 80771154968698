// Package imports:
import React from 'react';
import { format } from 'date-fns';
import isIsLocale from 'date-fns/locale/is';
// Service imports:
import { isDateToday } from '../../services/utils';

interface IProps {
    date: Date
};

const CalendarDate: React.FC<IProps>= ({date}) => {
    return (
        <div className="KCL_calendar-date">
            {isDateToday(date)
                ? (
                    <div className="today_date">
                        Í dag
                    </div>
                )
                : (
                    <span>{format(date, 'dd. MMM', { locale: isIsLocale })}</span>
                )
            }
        </div>
    );
}

export default CalendarDate;