// Package imports:
import React, { useMemo } from 'react';
import { SeriesOptionsType } from 'highcharts/highstock';
import { format } from 'date-fns';
import ISLocale from 'date-fns/locale/is';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import DefaultChart from '../DefaultChart/DefaultChart';
// Service imports:
import { getAPItoday, formatNumber, convertToPercentage } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../../types/Types';
import { IApiHistoryTimeseriesShort, IApiHistoryTimeseriesShortDATA } from '../../../types/MarketTypes';

interface IChartDatum {
    x: number | undefined
    y: number | undefined
};

interface IChartDataInfo {
    name: string,
    data: IChartDatum[]
};


interface IOwnProps {
    symbol?: string,
    title?: string,
    height?: number
};

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const BondsChart: React.FC<Props> = ({
    symbol,
    height = 500,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const today = new Date();
    const from = new Date(today.getFullYear() - 5, today.getMonth() + 1, today.getDate());
    const [apiData] = useApiLmdData<IApiHistoryTimeseriesShortDATA>(
        `/v1/market_data/v1/tradables/${symbol}/history_timeseries_short/?from_date=${getAPItoday(from)}&to_date=${getAPItoday(today)}}`,
        accessToken,
        refreshRateMs
    );

    const handleData = () => {
        if (apiData.data === null || apiData.error) return null;
        let dataToReturn: IApiHistoryTimeseriesShort[] = [];
        for(let datum of apiData.data.data){
            dataToReturn.push({
                trading_date: datum[0],
                open: datum[1],
                open_yield: datum[2],
                high: datum[3],
                high_yield: datum[4],
                low: datum[5],
                low_yield: datum[6],
                close: datum[7],
                close_yield: datum[8],
                volume: datum[9],
                open_corrected: datum[10],
                high_corrected: datum[11],
                low_corrected: datum[12],
                close_corrected: datum[13],
                volume_corrected: datum[14],
            })
        }
        return dataToReturn;
    };

    const series: SeriesOptionsType[] = useMemo(() => {
        const data = handleData();
        if (data === null) return [];

        const chartDataYieldInfo: IChartDataInfo = {
            name: symbol ?? '',
            data: []
        }

        const chartDataPriceInfo: IChartDataInfo = {
            name: symbol ?? '',
            data: []
        }

        const chartDataVolumeInfo: IChartDataInfo = {
            name: symbol ?? '',
            data: []
        }

        for (let datum of data) {
            if (datum.trading_date === null) continue;
            chartDataYieldInfo.data.push({
                x: datum.trading_date,
                y: datum.close_yield ?? undefined
            });
            chartDataPriceInfo.data.push({
                x: datum.trading_date,
                y: datum.close ?? undefined
            });
            chartDataVolumeInfo.data.push({
                x: datum.trading_date,
                y: datum.volume ?? undefined
            });
        }

        return [{
            type: 'line',
            id: 'yield',
            name: 'Krafa',
            data: chartDataYieldInfo.data,
            tooltip: {
                valueDecimals: 2
            },
            yAxis: 0,
            color: '#FF7697'
        }, {
            type: 'line',
            id: 'price',
            name: 'Verð',
            data: chartDataPriceInfo.data,
            tooltip: {
                valueDecimals: 2
            },
            yAxis: 1
        }, {
            type: 'column',
            id: 'volume',
            name: 'Magn',
            data: chartDataVolumeInfo.data,
            yAxis: 2,
            color: '#48A85D',
            tooltip: {
                valueDecimals: 2
            }
        }]
    }, [apiData]);
    return (
        <DisplayBox
            className='KCL_chart chart--bonds'
            title="Söguleg gögn"
            {...props}
        >
            <DefaultChart
                height={height}
                tooltipFormatter={
                    function() {
                        return `<div class="chart-tooltip">
                            ${this.points?.reduce(function (s, point) {
                                if (point.series.name === 'Verð') {
                                    return `${s}<p class="tooltip-value"><span style="color: ${point.color}">${point.series.name}</span>: ${formatNumber(point.y, null, 3)}</p>`;
                                } else if (point.series.name === 'Krafa') {
                                    return `${s}<p class="tooltip-value"><span style="color: ${point.color}">${point.series.name}</span>: ${convertToPercentage((point.y) ?? null, true, 3)}</p>`;
                                } else {
                                    return `${s}<p class="tooltip-volume"><span style="color: ${point.color}">${point.series.name}</span>: ${formatNumber(point.y, null, 0)}</p>`
                                }
                                
                            }, `<p class="tooltip-date">${format(this.x, 'EEEE, dd. MMM yyyy', {locale: ISLocale})}</p>`)}
                        </div>`;
                    }
                }
                yAxes={[{
                    title: {
                        text: 'Krafa',
                        offset: 52,
                        x: 5
                    },
                    labels: {
                        formatter: function() {
                            return convertToPercentage(this.value as number, true, 2) ?? this.value.toString();
                        },
                        x: -11,
                        y: 2
                    },
                    minTickInterval: 0.001,
                    height: '70%'
                }, {
                    title: {
                        text: 'Verð',
                        offset: 52,
                        x: 5
                    },
                    opposite: true,
                    height: '70%',
                    labels: {
                        x: 11,
                        y: 2
                    }
                }, {
                    title: {
                        text: 'Magn',
                        offset: 52,
                        x: 5
                    },
                    top: '76%',
                    height: '24%',
                    offset: 0,
                    labels: {
                        x: -11,
                        y: 2
                    }
                }]}
                series={series}
            />
        </DisplayBox>
    );
}

export default BondsChart;