// Package imports:
import React from "react";
// Component imports:
import DisplayBox from "../../ui-elements/DisplayBox/DisplayBox";
import LmdTable from "../../ui-elements/Table/LmdTable";
import CalculatedColoredNumber from "../../ui-elements/CalculatedColoredNumber/CalculatedColoredNumber";
// Service imports:
import { formatNumber } from "../../services/utils";
import { useApiLmdData } from "../../services/apiHooks";
import { CUSTOM_REFRESH_RATES } from "../../services/config";
// Type imports:
import { IDefaultProps } from "../../types/Types";
import { IApiLmdIndexStatisticListShort } from "../../types/MarketTypes";
import {
    firstTableSymbols,
    nameChanger,
    secondTableSymbols,
} from "../MultipleIndices/MultipleIndices";

type Props = IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const Visitolur: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [indexStatistics] = useApiLmdData<IApiLmdIndexStatisticListShort[]>(
        `v1/market_data/v1/keldan/indexes/index_statistics_list`,
        accessToken,
        CUSTOM_REFRESH_RATES["VISITOLUR"]
      );

    const handleData = () => {
        const { data, error } = indexStatistics;
        if (data === null) return indexStatistics;
        return {
            data: {
                "Norrænar hlutabréfavísitölur": data.filter((x) =>
                    firstTableSymbols.includes(x.Symbol)
                ),
                "Íslenskar hlutabréfavísitölur": data.filter((x) =>
                    secondTableSymbols.includes(x.Symbol)
                ),
            },
            error
        };
    };
    return (
        <DisplayBox
            className="KCL_visitolur"
            title="Helstu vísitölur"
            anchorProps={{ href: "/Markadir/Visitolur" }}
            displayBoxSize="sm"
            {...props}
        >
            <LmdTable
                id={props.id}
                apiData={handleData()}
                columns={[{
                    title: "Vísitala",
                    renderCell: ({ Symbol: symbol }) => (
                        <div className="avatar-and-text">{nameChanger(symbol, "13")}</div>
                    ),
                    textAlign: "left"
                }, {
                    title: "Gildi",
                    renderCell: ({ LastValue }) => formatNumber(LastValue, "-", 2)
                }, {
                    title: "Dagsbr.",
                    renderCell: ({ YesterdaysValueChangePer }) => (
                        <CalculatedColoredNumber
                            currentValue={YesterdaysValueChangePer}
                            formatting="percent"
                            hasColor
                        />
                    )
                }, {
                    title: "1 Mán.",
                    renderCell: ({ Weeks4ValueChangePer }) => (
                        <CalculatedColoredNumber
                            currentValue={Weeks4ValueChangePer}
                            formatting="percent"
                            hasColor
                        />
                    )
                }, {
                    title: "YTD",
                    renderCell: ({ YearValueChangePer }) => (
                        <CalculatedColoredNumber
                            currentValue={YearValueChangePer}
                            formatting="percent"
                            hasColor
                        />
                    )
                }, {
                    title: "1 Ár",
                    renderCell: ({ Months12ValueChangePer }) => (
                        <CalculatedColoredNumber
                            currentValue={Months12ValueChangePer}
                            formatting="percent"
                            hasColor
                        />
                    )
                }]}
                expandableRowConfig={{
                    defaultExpandedIndexes: [0, 1],
                    showNumberOfItemsInExpandable: true,
                }}
            />
        </DisplayBox>
    );
};

export default Visitolur;
