// Package imports:
import React, { useContext, useMemo, useRef, useState } from 'react';
import { format } from "date-fns";
// Component imports:
// import Button from '../../ui-elements/Button/Button';
import LmdTable from '../../ui-elements/Table/LmdTable';
import SmallSearch from '../SmallSearch/SmallSearch';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Tooltip from '../../ui-elements/Tooltip/Tooltip';
import Alert from '../../ui-elements/Alert/Alert';
import Loading from '../../ui-elements/Loading/Loading';
import Button from '../../ui-elements/Button/Button';
import SearchResults from '../../ui-elements/SearchResults/SearchResults';
// Service imports:
import { useApiLmdData, useApiLmdDataMappedByString } from '../../services/apiHooks';
import { formatNumber, icelandifyString } from '../../services/utils';
import { useBuyingProcessUrl } from '../../services/buyhook';
import { GET_KELDAN_API_URL } from '../../services/config';
// Type imports:
import { ILeigusamningur, IPropertySearchViewModel } from '../../types/PropertyTypes';
// Context imports:
import { AccessTokenContext } from '../../contexts/AccessTokenContext';

const Leigusamningar: React.FC<IPropertySearchViewModel> = ({
    isAuthenticated,
    documentsAllowed
}) => {
    const accessToken = useContext(AccessTokenContext);

    const [ latestLeigusamningar ] = useApiLmdData<ILeigusamningur[]>(`/static_data/v1/register/residental_rent/latest?limit=50`, accessToken);
    const [ mostRecentlyFetchedLeigusamningar, setMostRecentlyFetchedLeigusamningar ] = useState<ILeigusamningur[]>([]);
    const [ searchInput, setSearchInput, leigusamningarForSearchInput ] = useApiLmdDataMappedByString<ILeigusamningur[]>(
        '',
        (searchInput: string) => `/v1/static_data/v1/register/residental_rent/address/${searchInput}*/?limit=50`,
        accessToken,
        undefined,
        undefined,
        (leigusamningar) => setMostRecentlyFetchedLeigusamningar(leigusamningar)
    );

    const searchResultRef = useRef<HTMLDivElement>(null);

    const Leigusamningar = useMemo(() => {
        if (searchInput.length < 3) return latestLeigusamningar;
        if (leigusamningarForSearchInput.data === null && leigusamningarForSearchInput.error === null) {
            return ({
                data: mostRecentlyFetchedLeigusamningar,
                error: null
            });
        }
        return leigusamningarForSearchInput;
    }, [searchInput, latestLeigusamningar, leigusamningarForSearchInput, mostRecentlyFetchedLeigusamningar]);

    const { tryPurchaseItem, isItemLoading, ModalsAndToasts } = useBuyingProcessUrl(
        (doc: ILeigusamningur, modalReference: string) => {
            const url = `${GET_KELDAN_API_URL()}/Pdf/Thinglyst-Skjal-Kaupa?docnr=${doc.Skjalanumer}&onr=${doc.Emnr}&fastanr=${doc.Fastnum}&reference=${modalReference}`;
            return url;
        },
        () => {},
        'fasteignaskrá'
    );

    const displayResults = () => {
        if (Leigusamningar.data === null) {
            return <Loading />;
        }
        if (Leigusamningar.data.length > 0) {
            return <LmdTable
                tableSize='lg'
                apiData={Leigusamningar}
                columns={[{
                    title: 'Fastanúmer',
                    renderCell: ({ Fastnum }) => Fastnum,
                    textAlign: 'left',
                    simpleSortable: ({ Fastnum }) => Fastnum
                }, {
                    title: "Heimilisfang",
                    renderCell: ({ Heimilisfang }) => Heimilisfang,
                    textAlign: 'left',
                    simpleSortable: ({ Heimilisfang }) => Heimilisfang
                }, {
                    title: "Tegund",
                    renderCell: ({ Tegund }) => icelandifyString(Tegund),
                    textAlign: 'left',
                    simpleSortable: ({ Tegund }) => icelandifyString(Tegund)
                }, {
                    title: "Dags . Þingl.",
                    renderCell: ({ Thinglystdags }) => format(new Date(Thinglystdags.replace(/ /g, "T")), "dd.MM.yyyy"),
                    textAlign: 'right',
                    simpleSortable: ({ Thinglystdags }) => Thinglystdags
                }, {
                    title: "Heildarverð",
                    renderCell: ({ Heildarverd }) => formatNumber(Heildarverd, '-', 0, ' kr.'),
                    textAlign: 'right',
                    simpleSortable: ({ Heildarverd }) => Heildarverd
                }, {
                    title: "Stærð",
                    renderCell: ({ Staerd }) => {
                        return <>{Staerd}{" m"}<sup>2</sup></>
                    },
                    textAlign: 'right',
                    simpleSortable: ({ Staerd }) => Staerd
                }, {
                    title: "Fermetraverð",
                    renderCell: ({ Heildarverd, Staerd }) => formatNumber(Heildarverd/Staerd, '-', 0, ' kr.'),
                    textAlign: 'right',
                    simpleSortable: ({ Heildarverd, Staerd }) => (Heildarverd / Staerd)
                }, {
                    title: (
                        <Tooltip
                            text='Nothæfur samn.'
                            tooltip={
                                <span>
                                    Samningur verður ónothæfur í vísitöluútreikning og<br/>
                                    samanburð t.d. vegna þess að samningurinn er<br/>
                                    - Milli skyldra<br/>
                                    - Fjöldi fasteigna í samningi fleiri en 1<br/>
                                    - Kvöð um notkun<br/>
                                    - Fjármálastofnun selur<br/>
                                    - Fjármálastofnun kaupir<br/>
                                    - Greitt með lausafé<br/>
                                    - Greitt með fasteign<br/>
                                    - Selt að hluta<br/>
                                    - Ófullnægjandi uppl. um staðgreiðsluverð<br/>
                                    - Ástandi ábótavant<br/>
                                </span>
                            }
                        />
                    ),
                    renderCell: ({ OnothaefurSamningur }) => OnothaefurSamningur ? "Nei" : "Já",
                    textAlign: 'center',
                    simpleSortable: ({ OnothaefurSamningur }) => (OnothaefurSamningur ? "Nei" : "Já")
                }, {
                    title: "Samningur",
                    renderCell: (doc) => (
                        <Button
                            size='sm'
                            buttonType='buy'
                            showLoader={isItemLoading(doc)}
                            onClick={() => tryPurchaseItem(doc, 'confirm-modal-with-reference')}
                            disabled={!isAuthenticated || !documentsAllowed}
                        >
                            Kaupa
                        </Button>
                    ),
                    textAlign: 'left'
                }]}
            />
        }
        return <Alert type='info' headText="Engar niðurstöður fundust" />;
    }

    return (
        <div className='KCL_property-search-form KCL_leigusamningar'>
            <div className='shell'>
                <h3>Leigusamningar</h3>
                <div className='property-form-description paragraph'>
                    Hér má finna leigusamninga frá HMS. HMS skráir upplýsingarnar upp úr þinglýstum leigusamningum.<br/>
                    Leigusamningar geta verið fyrir íbúðarhúsnæði, sumarhús eða atvinnuhúsnæði.
                </div>
                <div className='row'>
                    <div className="col-lg-7 col-sm-12">
                        <div className="search__box">
                            <SmallSearch
                                search={searchInput}
                                setSearch={setSearchInput}
                                placeHolder="Leitaðu eftir heimilisfangi..."
                                id="Search_Leigusamningar"
                                onSubmit={() => { searchResultRef.current?.scrollIntoView() }}
                            />
                            <p className="extra-info">Byggt á upplýsingum frá HMS frá 2006-{new Date().getFullYear()}</p>
                        </div>
                    </div>
                </div>
            </div>
            <SearchResults
                show
                ref={searchResultRef}
                component={
                    <div className="shell">
                        <DisplayBox>
                            {displayResults()}
                        </DisplayBox>
                        <ModalsAndToasts />
                    </div>
                }
            />
        </div>
    )
}

export default Leigusamningar;