// Package imports:
import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import Loading from '../../../ui-elements/Loading/Loading';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { IdIndividualViewModel, IndividualMembership } from '../../../types/IndividualTypes';
import { IEventReport, IKeldanApiResponse } from '../../../types/KeldanTypes';
import ViewMembershipBody from './ViewMembershipBody';

interface IProps {
    model: IdIndividualViewModel;
}
const ViewMembership: React.FC<IProps> = ({ model }) => {
    const [ contactResponse, setContactResponse ] = useState<JSX.Element>(<></>)
    const [ data, setData ] = useState<IEventReport<IndividualMembership>>()
    const [ loading, setLoading] = useState(false)

    const getMembershipInfo = async () => {
        setLoading(true)
        try {
            const url = `${GET_KELDAN_API_URL()}/Individual/GetMembershipInfo/${model.id}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            });
            setLoading(false)
            if (!response.ok) return;
            if (response.redirected) {
                window.location.href = response.url;
            }
            const responseBody: IKeldanApiResponse<IEventReport<IndividualMembership>> = await response.json();
            if (responseBody.success) {
                if (responseBody.result) setData(responseBody.result);
            } else {
                const { message } = responseBody;
                if (message) {
                    setContactResponse(<Alert type='alert' headText={message} />);
                } else {
                    setContactResponse(<Alert type='alert' headText={ErrorMessages.ErrorInRequest} />)
                }
            }
        } catch (e) {
            setLoading(false)
            if (e instanceof Error) Bugsnag.notify(e);
        }
    }
    useEffect(() => {
        getMembershipInfo()
    }, [])

    const displayBody = () => {
        if (loading) return <Loading />
        if (data) return <ViewMembershipBody report={data} />
        return null
    }

    return (
        <div className='main KCL_individual-profile viewMembership'>
            <div className='main__inner-fluid'>
                <div className="shell">
                    {displayBody()}
                    {contactResponse}
                </div>
            </div>
        </div>
    );
}

export default ViewMembership;
