// Package imports:
import React, { useContext, useMemo } from 'react';
// Service imports:
import { useFetchedApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { ICompanyViewModel } from '../../../types/CompanyTypes';
// Context imports:
import { AccessTokenContext } from '../../../contexts/AccessTokenContext';

interface ICompanyAwards {
    Year: number;
    Isexcellent: boolean;
}

const IMAGE_BASE_URL = "https://cdn.livemarketdata.com/Images/Excellent/"

const CompanyAwards: React.FC<ICompanyViewModel> = ({
    id
}) => {
    const accessToken = useContext(AccessTokenContext);
    const data = useFetchedApiLmdData<ICompanyAwards[]>(
        `/v1/market_data/v1/keldan/company/excellent_company/ssn/${id}`,
        accessToken
    );

    const years = useMemo(() => {
        if (data === null || data instanceof Error) {
          return null;
        }
        return findYearRange(data);
    }, [data]);

    function findYearRange(data: ICompanyAwards[]): string | null {
        let startYear: number | null = null;
        let endYear: number | null = null;
    
        for (let year = new Date().getFullYear(); year >= 2017; year--) {
            const yearEntry = data.find((entry) => entry.Year === year);
            if (yearEntry && yearEntry.Isexcellent) {
                if (endYear === null) {
                    endYear = year; // Set endYear to the highest year with true value
                }
                startYear = year; // Set startYear to the current year with true value
            } else if (startYear !== null) {
                // If a true value was found earlier, and the current year is false, break
                break;
            }
        }
    
        if (startYear === endYear) {
            // If there's only one year, return it as a string
            return startYear !== null ? startYear.toString() : null;
        }
    
        return startYear !== null && endYear !== null
            ? `${startYear}-${endYear}`
            : null;
    }

    return <div className="KCL_company-awards">
        {data === null || years === null
            ? null
            : <a href='/Lists/Excellent'>
                <img
                    src={`${IMAGE_BASE_URL}fyrirmyndar${years}.svg`}
                    alt={`Fyrirmyndarfyrirtæki í rekstri ${years}`}
                    width="800"
                    height="469"
                />
            </a>
        }
    </div>;
}

export default CompanyAwards;