// Package imports:
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import Icon from '../CustomIcon/CustomIcon';

interface IOwnProps {
    onClick?: () => void;
    isOpen?: boolean;
}

const MobileMyKeldaMenu: React.FC<IOwnProps> = ({
    onClick,
    isOpen = false
}) => {
    return (
        <div
            className='my-kelda-menu'
            onClick={onClick}
        >
            {isOpen
                ? <Icon type='close' />
                : <FontAwesomeIcon icon={faUser} />
            }
        </div>
  );
}

export default MobileMyKeldaMenu;
