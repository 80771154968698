// Package imports:
import React, { useEffect, useMemo, useRef, useState } from "react";
// Component imports:
import AdRotator from "../../Ad/AdRotator";
import Alert from "../../../ui-elements/Alert/Alert";
import Button from "../../../ui-elements/Button/Button";
import DisplayBox from "../../../ui-elements/DisplayBox/DisplayBox";
import Loading from "../../../ui-elements/Loading/Loading";
import SmallSearch from "../../SmallSearch/SmallSearch";
import Table from "../../../ui-elements/Table/Table";
import { pickRandomCompanies } from "../CompanySearchesPage/CompanySearchesPage";
// Service imports:
import { ErrorMessages } from "../../../services/errorMessages";
import { GET_KELDAN_API_URL } from "../../../services/config";
import { insertDash } from "../../../services/utils";
import { useBuyingProcess } from "../../../services/buyhook";
// Type imports:
import { Fetched, IisAuthenticated } from "../../../types/Types";
import {
  ICompanySearchResults,
  ICompanySearchResultsItem,
} from "../../../types/SearchTypes";
import { IKeldanApiResponse, OwnedReport } from "../../../types/KeldanTypes";
// Context imports:
import { companies } from "./DefaultCompaniesForSearch";

const CompanyGildandiSkraning: React.FC<IisAuthenticated> = ({
    isAuthenticated,
    isSubscriber,
}) => {
    const urlParams = new URLSearchParams(window.location.search);
    const term = urlParams.get("search");
    // Autocomplete request variables:
    const [searchInput, setSearchInput] = useState(term);
    const [randomCompanies] = useState<ICompanySearchResultsItem[]>(
        pickRandomCompanies(companies, 10)
    );
    const [loading, setLoading] = useState(false);
    const searchResultRef = useRef<HTMLDivElement>(null);
    const [searchResults, setSearchResults] = useState<Fetched<ICompanySearchResultsItem[]>>(null);

    useEffect(() => {
        // Clear the previous timer if it exists
        let timerId: NodeJS.Timeout | null = null;

        const companySearch = async () => {
            if (searchInput === null || searchInput.length < 3) {
                setSearchResults(null);
                return;
            }

            try {
                setLoading(true);
                setSearchResults(null);

                const url = `${GET_KELDAN_API_URL()}/Company/SearchRskConnector?searchTerm=${encodeURIComponent(
                    searchInput.trim()
                )}`;
                const response = await fetch(url);

                if (response.ok) {
                    const body: ICompanySearchResults = await response.json();
                    const companies: ICompanySearchResultsItem[] = body.results ?? [];
                    setSearchResults(companies.length === 0 ? [] : companies);
                } else {
                    setSearchResults(new Error(ErrorMessages.RequestFailed));
                }
            } catch (error) {
                setSearchResults(new Error(ErrorMessages.NetworkError));
            } finally {
                setLoading(false);
            }
        };

        // Set a new timer to call the API after 1/4 second
        timerId = setTimeout(companySearch, 250);

        // Clear the timer when the component unmounts or when searchInput changes
        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [searchInput]);

    const { tryPurchaseItem, isItemLoading, resetState, ModalsAndToasts } = useBuyingProcess(async (ssn: string | null, reference: string, withOwners) => {
        try {
            const baseurl = `${GET_KELDAN_API_URL()}/Fyrirtaeki/Hlutafelagaskra-Kaupa/${ssn}`;
            const query = new URLSearchParams({
                'addresses': 'false',
                'owners': 'false',
                'json': 'true',
                'reference': reference
                
            });
            if (withOwners) query.set('owners', 'true');

            const url = `${baseurl}?${query.toString()}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            });

            if (!response.ok) {
                resetState('response not ok');
                return;
            }
            if (response.redirected) {
                window.location.href = response.url;
                return;
            }
            const responseBody: IKeldanApiResponse<OwnedReport[]> = await response.json();
            if (!responseBody.success) {
                const { message } = responseBody;
                if (message === ErrorMessages.NoCard) {
                    resetState('no card');
                } else if (message) {
                    resetState({ headText: message, type: 'alert' });
                } else {
                    resetState('bad request');
                }
            }
        } catch (e) {
            resetState('network error');
        }
    }, 'hlutafélagaskrá');

    const getPurchaseButton = (ssn: string | null) => {
        if (isSubscriber) {
            return (
                <Button
                    showLoader={isItemLoading(ssn)}
                    size="sm"
                    buttonType="primary"
                    anchorProps={{href: `/Fyrirtaeki/Hlutafelagaskra-Kaupa/${ssn}?owners=true`}}
                >
                    Sækja
                </Button>
            );
        }
        if (isAuthenticated) {
            return (
                <Button
                    showLoader={isItemLoading(ssn)}
                    size="sm"
                    buttonType="buy"
                    onClick={() =>{
                        tryPurchaseItem(ssn, "confirm-modal-with-reference-and-owners")}
                    }
                >
                    Kaupa
                </Button>
            );
        }
        return (
            <Button
                size="sm"
                buttonType="buy"
                anchorProps={{
                    href: `/Innskraning?ReturnUrl=${window.location.pathname}`,
                }}
            >
                Kaupa
            </Button>
        );
    };
    interface Column {
        title: string;
        renderCell: (company: ICompanySearchResultsItem) => string | JSX.Element;
        textAlign: "left" | "right";
    }

    const columns: Column[] = [{
        title: "Nafn",
        renderCell: ({ name }: ICompanySearchResultsItem) => name ?? "",
        textAlign: "left",
    }, {
        title: "Kennitala",
        renderCell: ({ ssn }: ICompanySearchResultsItem) => insertDash(ssn),
        textAlign: "left",
    }, {
        title: "",
        renderCell: ({ ssn }: ICompanySearchResultsItem) => (
            getPurchaseButton(ssn)
        ),
        textAlign: "right",
    }];

    const displayResult = useMemo(() => {
        if (loading || (!searchResults && searchInput?.trim())) {
            return <Loading />;
        }

        let data: ICompanySearchResultsItem[] | undefined;

        if (searchInput === null || searchInput === "" || searchInput.length < 3) {
            data = randomCompanies;
        } else if (searchResults instanceof Error) {
            return <Alert type={"alert"} headText={searchResults.message} />;
        } else if (searchResults === null || searchResults.length === 0) {
            return <h4>Engar leitarniðurstöður fundust fyrir "{searchInput}"</h4>;
        } else {
            data = searchResults;
        }
        const sortedData = [...data].sort((a, b) => {
            const nameA = a.name || "";
            const nameB = b.name || "";
            return nameA.localeCompare(nameB);
        });
        return <Table tableSize="lg" data={sortedData} columns={columns} />;
    }, [searchResults, loading, isItemLoading]); // Only recompute the memoized value when searchResults or loading changes.

    return (
        <div className="KCL_company-search-tab">
            <div className="shell">
                {searchInput === null ||
                    searchInput === "" ||
                    searchInput.length < 3
                        ? (
                            <>
                                <h3>Gildandi skráning</h3>
                                <p className="paragraph">
                                    Flettu upp gildandi skráningu fyrirtækja úr Hlutafélagaskrá eftir
                                    nafni eða kennitölu.
                                </p>
                            </>
                        ) : (
                            <h3>Leitarniðurstöður fyrir "{searchInput}"</h3>
                        )
                }
            </div>
            <div className="content_wrapper">
                <div className="shell">
                    <div className="result_wrapper">
                        <DisplayBox>
                            <SmallSearch
                                search={searchInput ?? ""}
                                setSearch={(e) => {
                                    setSearchInput(e);
                                    window.history.pushState(
                                        null,
                                        "",
                                        "/Fyrirtaeki/Leit?search=" + e + "#GildandiSkraning"
                                    );
                                }}
                                inputSize="lg"
                                placeHolder="Leitaðu eftir fyrirtækjum..."
                                id="Search_page"
                                onSubmit={() => {
                                    searchResultRef.current?.scrollIntoView();
                                }}
                            />
                            <div className="table-section" ref={searchResultRef}>
                                {displayResult}
                            </div>
                        </DisplayBox>
                    </div>
                    <AdRotator location="Market310x400" />
                </div>
            </div>
            <ModalsAndToasts />
        </div>
    );
};

export default CompanyGildandiSkraning;
