// Package imports:
import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartArea } from '@fortawesome/free-solid-svg-icons'
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../ui-elements/Link/Link';
import CalculatedColoredNumber from '../../ui-elements/CalculatedColoredNumber/CalculatedColoredNumber';
import HagtolurChart from './HagtolurChart/HagtolurChart';
import Button from '../../ui-elements/Button/Button';
import Modal from '../../ui-elements/Modal/Modal';
import LmdTable from '../../ui-elements/Table/LmdTable';
// Service imports:
import { formatNumber, getAPItoday, getTableDate } from '../../services/utils';
import { useApiLmdData, useApiLmdDataMappedByString } from '../../services/apiHooks';
import { CUSTOM_REFRESH_RATES } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdIndexation } from '../../types/RatesTypes';

type Props = IDefaultProps & React.HTMLAttributes<HTMLDivElement>;
interface IIndexation {
    title: string,
    symbol: string,
    index_value: number | null,
    diffyear: number | null,
    diff12months: number | null,
    valuedate: string | null
}

const BASE_INDEX_VALUES: IIndexation[] = [{
    title: 'Atvinnulausir',
    symbol: 'Atvinnulausir',
    index_value: null, diffyear: null, diff12months: null, valuedate: null
}, {
    title: 'Atvinnuleysi',
    symbol: 'Atvinnuleysi',
    index_value: null, diffyear: null, diff12months: null, valuedate: null
}, {
    title: 'Byggingarvísitala',
    symbol: 'BYG',
    index_value: null, diffyear: null, diff12months: null, valuedate: null
}, {
    title: 'Greiðslujöfnuður',
    symbol: 'GRJ',
    index_value: null, diffyear: null, diff12months: null, valuedate: null
}, {
    title: 'Innflutningur CIF',
    symbol: 'InnflutningurCIF',
    index_value: null, diffyear: null, diff12months: null, valuedate: null
}, {
    title: 'Innflutningur FOB',
    symbol: 'InnflutningurFOB',
    index_value: null, diffyear: null, diff12months: null, valuedate: null
}, {
    title: 'Launavísitala',
    symbol: 'LAU',
    index_value: null, diffyear: null, diff12months: null, valuedate: null
}, {
    title: 'Lánskjaravísitala',
    symbol: 'LKJ',
    index_value: null, diffyear: null, diff12months: null, valuedate: null
}, {
    title: 'Vísitala neysluverðs til verðt.',
    symbol: 'NEY',
    index_value: null, diffyear: null, diff12months: null, valuedate: null
}, {
    title: 'Útflutningur FOB',
    symbol: 'UtflutningurFOB',
    index_value: null, diffyear: null, diff12months: null, valuedate: null
}, {
    title: 'Vísitala framleiðsluverðs',
    symbol: 'VisitalaFramleidsluverds',
    index_value: null, diffyear: null, diff12months: null, valuedate: null
}, {
    title: 'Vöruskiptajöfnuður',
    symbol: 'Voruskiptajofnudur',
    index_value: null, diffyear: null, diff12months: null, valuedate: null
}];

const Hagtolur: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const randomChartIndex = Math.floor(Math.random() * BASE_INDEX_VALUES.length);

    const [ indexes ] = useApiLmdData<IApiLmdIndexation[]>(
        `/v1/market_data/v1/keldan/indexation/${getAPItoday()}`,
        accessToken,
        CUSTOM_REFRESH_RATES['HAGTOLUR']
    );
    const [ selectedSymbol, setSelectedSymbol, chart ] = useApiLmdDataMappedByString<IApiLmdIndexation[]>(
        BASE_INDEX_VALUES[randomChartIndex].symbol,
        (symbol: string) => `/v1/market_data/v1/keldan/indexation/${symbol}/history/?from_date=${2005}-01-01&to_date=${getAPItoday()}`,
        accessToken,
        CUSTOM_REFRESH_RATES['HAGTOLUR']
    );

    // Set state.
    const [ bigChartOpen, setBigChartOpen ] = useState(false);
    const openBigChart = () => setBigChartOpen(true);
    const closeBigChart = () => setBigChartOpen(false);

    const chartHeader = useMemo(() => {
        const index = BASE_INDEX_VALUES.find(bi => bi.symbol === selectedSymbol);
        return index?.title ?? '-';
    }, [ selectedSymbol ]);
    
    const handleIndexes = () => {
        const { data, error } = indexes;
        if (data === null) return {
            data: null,
            error
        };
        const baseIndexValuesCopy = [...BASE_INDEX_VALUES];
        for (let entry of baseIndexValuesCopy) {
            const indexationData = data.find(datum => datum.symbol === entry.symbol);
            if (indexationData !== undefined) {
                entry.index_value = indexationData.index_value;
                entry.diffyear = indexationData.diffyear;
                entry.diff12months = indexationData.diff12months;
                entry.valuedate = indexationData.valuedate;
            }
        }
        return {
            data: baseIndexValuesCopy,
            error
        };
    }

    const filterDataForSmallHeaderChart = () => {
        const chartData = chart.data ?? [];
        return chartData.filter(datum => datum.valuedate && parseInt(datum.valuedate.substring(0, 4)) >= 2015)
    }

    return (
        <DisplayBox
            title="Helstu hagtölur"
            className='KCL_hagtolur'
            displayBoxSize="sm"
            {...props}
        >
            {/* Small Header Chart */}
            <div className="chart-box">
                <div className="chart-box__head">
                    <p className='paragraph-small'>{chartHeader}</p>

                    <Button buttonType="secondary" size="sm" onClick={openBigChart}>Skoða</Button>
                </div>

                <div className="chart-box__body">
                    <HagtolurChart
                        data={filterDataForSmallHeaderChart()}
                    />
                </div>
            </div>
            {/* Table of indexes */}
            <LmdTable
                apiData={handleIndexes()}
                columns={[{
                    title: 'Flokkur',
                    renderCell: ({title, symbol}) => (
                        <span className="icon-and-title">
                            <button title="Sjá graf">
                                <FontAwesomeIcon
                                    icon={faChartArea}
                                    className="icon"
                                    onClick={() => setSelectedSymbol(symbol)}
                                />
                            </button>
                            <Link
                                wrapTextForMobile
                                onClick={() => {
                                    setSelectedSymbol(symbol)
                                    openBigChart();
                                }}
                                linkSize='13'
                            >
                                {title}
                            </Link>
                        </span>
                    ),
                    textAlign: "left",
                    sortable: (a, b) => a.title?.localeCompare(b.title ?? '', 'is') ?? -1
                }, {
                    title: 'Gildi',
                    renderCell: ({index_value}) => formatNumber(index_value),
                    simpleSortable: ({index_value}) => index_value
                }, {
                    title: '+/- YTD',
                    renderCell: ({diffyear}) => (
                        <CalculatedColoredNumber
                            currentValue={diffyear}
                            formatting='decimal'
                            hasColor
                        />
                    ),
                    simpleSortable: ({diffyear}) => diffyear
                }, {
                    title: '+/- 12M',
                    renderCell: ({diff12months}) => (
                        <CalculatedColoredNumber
                            currentValue={diff12months}
                            formatting='decimal'
                            hasColor
                        />
                    ),
                    simpleSortable: ({diff12months}) => diff12months
                }, {
                    title: 'Dags.',
                    renderCell: ({valuedate}) => getTableDate(valuedate, 'DD/MM/YY', '.'),
                    simpleSortable: ({valuedate}) => (valuedate === null) ? null : new Date(valuedate).getTime()
                }]}
            />
            {/* Big Chart Modal */}
            <Modal
                show={bigChartOpen}
                onHide={closeBigChart}
                hasCloseButton
                header={chartHeader}
                body={
                    <HagtolurChart
                        data={chart.data ?? []}
                        title={chartHeader}
                        detailed={true}
                        height={480}
                    />
                }
            />
        </DisplayBox>
    );
}

export default Hagtolur;