// Package imports:
import React, { useContext, useMemo, useRef, useState } from 'react';
import { format } from "date-fns";
// Component imports:
import LmdTable from '../../ui-elements/Table/LmdTable';
import SmallSearch from '../SmallSearch/SmallSearch';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Button from '../../ui-elements/Button/Button';
import Tooltip from '../../ui-elements/Tooltip/Tooltip';
import Alert from '../../ui-elements/Alert/Alert';
import Loading from '../../ui-elements/Loading/Loading';
import SearchResults from '../../ui-elements/SearchResults/SearchResults';
// Service imports:
import { useApiLmdData, useApiLmdDataMappedByString } from '../../services/apiHooks';
import { formatNumber, icelandifyString } from '../../services/utils';
import { useBuyingProcessUrl } from '../../services/buyhook';
import { GET_KELDAN_API_URL } from '../../services/config';
// Type imports:
import { IKaupsamningur, IPropertySearchViewModel } from '../../types/PropertyTypes';
// Context imports:
import { AccessTokenContext } from '../../contexts/AccessTokenContext';


const Kaupsamningar: React.FC<IPropertySearchViewModel> = ({
    isAuthenticated,
    documentsAllowed
}) => {
    const accessToken = useContext(AccessTokenContext);

    const [ latestKaupsamningar ] = useApiLmdData<IKaupsamningur[]>(`/static_data/v1/register/realestate_sales/latest?limit=50`, accessToken);
    const [ mostRecentlyFetchedKaupsamningar, setMostRecentlyFetchedKaupsamningar ] = useState<IKaupsamningur[]>([]);
    const [ searchInput, setSearchInput, kaupsamningarForSearchInput ] = useApiLmdDataMappedByString<IKaupsamningur[]>(
        '',
        (searchInput: string) => `/v1/static_data/v1/register/realestate_sales/address/${searchInput}*/?limit=50&offset=0`,
        accessToken,
        undefined,
        undefined,
        (kaupsamningar) => setMostRecentlyFetchedKaupsamningar(kaupsamningar)
    );

    const searchResultRef = useRef<HTMLDivElement>(null);

    const kaupsamningar = useMemo(() => {
        if (searchInput.length < 3) return latestKaupsamningar;
        if (kaupsamningarForSearchInput.data === null && kaupsamningarForSearchInput.error === null) {
            return {
                data: mostRecentlyFetchedKaupsamningar,
                error: null
            }
        }
        return kaupsamningarForSearchInput
    }, [searchInput, latestKaupsamningar, kaupsamningarForSearchInput, mostRecentlyFetchedKaupsamningar]);

    const { tryPurchaseItem, isItemLoading, ModalsAndToasts } = useBuyingProcessUrl(
        (doc: IKaupsamningur, modalReference: string) => {
            const url = `${GET_KELDAN_API_URL()}/Pdf/Thinglyst-Skjal-Kaupa?docnr=${doc.Skjalanumer}&onr=${doc.Emnr}&fastanr=${doc.Fastnum}&reference=${modalReference}`;
            return url;
        },
        () => {},
        'fasteignaskrá'
    );

    const displayResults = () => {
        if (kaupsamningar.data === null) {
            return <Loading />;
        }
        if (kaupsamningar.data.length === 0) {
            return <Alert type='info' headText="Engar niðurstöður fundust" />;
        }
        return <LmdTable
            tableSize='lg'
            apiData={kaupsamningar}
            columns={[{
                title: 'Fastanúmer',
                renderCell: ({ Fastnum }) => Fastnum,
                textAlign: 'left',
                simpleSortable: ({ Fastnum }) => Fastnum
            }, {
                title: "Heimilisfang",
                renderCell: ({ Heimilisfang }) => Heimilisfang,
                textAlign: 'left',
                simpleSortable: ({ Heimilisfang }) => Heimilisfang
            }, {
                title: 'Postnr',
                renderCell: ({ Postnr }) => Postnr,
                textAlign: 'left',
                simpleSortable: ({ Postnr }) => Postnr
            }, {
                title: "Tegund",
                renderCell: ({ Tegund }) =>icelandifyString(Tegund),
                textAlign: 'left',
                simpleSortable: ({ Tegund }) => icelandifyString(Tegund)
            }, {
                title: "Dags . Þingl.",
                renderCell: ({ Thinglystdags }) => format(new Date(Thinglystdags.replace(/ /g, "T")), "dd.MM.yyyy"),
                textAlign: 'right',
                simpleSortable: ({ Thinglystdags }) => Thinglystdags
            }, {
                title: "Fasteignamat",
                renderCell: ({ Fasteignamat }) => formatNumber(Fasteignamat, '-', 0, ' kr.'),
                textAlign: 'right',
                simpleSortable: ({ Fasteignamat }) => Fasteignamat
            }, {
                title: "Kaupverð",
                renderCell: ({ Kaupverd }) => formatNumber(Kaupverd, '-', 0, ' kr.'),
                textAlign: 'right',
                simpleSortable: ({ Kaupverd }) => Kaupverd
            }, {
                title: "Stærð",
                renderCell: ({ Einflm, LodFlmein }) => {
                    return <>{Einflm}{" "}{LodFlmein}</>
                },
                textAlign: 'right',
                simpleSortable: ({ Einflm }) => Einflm
            }, {
                title: "Fermetraverð",
                renderCell: ({ Kaupverd, Einflm }) => formatNumber(Kaupverd/Einflm, '-', 0, ' kr.'),
                textAlign: 'right',
                simpleSortable: ({ Kaupverd, Einflm }) => (Kaupverd / Einflm)
            }, {
                title: (
                    <Tooltip
                        text='Nothæfur samn.'
                        tooltip={
                            <span>
                                Samningur verður ónothæfur í vísitöluútreikning og<br/>
                                samanburð t.d. vegna þess að samningurinn er<br/>
                                - Milli skyldra<br/>
                                - Fjöldi fasteigna í samningi fleiri en 1<br/>
                                - Kvöð um notkun<br/>
                                - Fjármálastofnun selur<br/>
                                - Fjármálastofnun kaupir<br/>
                                - Greitt með lausafé<br/>
                                - Greitt með fasteign<br/>
                                - Selt að hluta<br/>
                                - Ófullnægjandi uppl. um staðgreiðsluverð<br/>
                                - Ástandi ábótavant<br/>
                            </span>
                        }
                    />
                ),
                renderCell: ({ OnothaefurSamningur }) => OnothaefurSamningur ? "Nei" : "Já",
                textAlign: 'left',
                simpleSortable: ({ OnothaefurSamningur }) => (OnothaefurSamningur ? "Nei" : "Já")
            }, {
                title: "Samningur",
                renderCell: (doc) => (
                    <Button
                        size='sm'
                        buttonType='buy'
                        showLoader={isItemLoading(doc)}
                        onClick={() => tryPurchaseItem(doc, 'confirm-modal-with-reference')}
                        disabled={!isAuthenticated || !documentsAllowed}
                    >
                        Kaupa
                    </Button>
                ),
                textAlign: 'left'
            }, {
                title: "Verðmat",
                renderCell: ({ Fastnum }) => (
                    <Button
                        size='sm'
                        buttonType='secondary'
                        anchorProps={{
                            target: '_blank',
                            href: `/Fasteignir/Leit?fastanr=${Fastnum}#Gangverd`
                        }}
                    >
                        Verðvísir
                    </Button>
                ),
                textAlign: 'left',
            }]}
        />
    }

    
    return (
        <div className='KCL_property-search-form KCL_kaupsamningar'>
            <div className='shell'>
                <h3>Kaupsamningar</h3>
                <div className='property-form-description paragraph'>
                    Hér má finna kaupskrá fasteigna frá HMS. HMS skráir upplýsingarnar upp úr þinglýstum kaupsamningum.<br/>
                    Kaupsamningar geta verið um íbúðarhúsnæði, sumarhús eða atvinnuhúsnæði.
                </div>
                <div className='row'>
                    <div className="col-lg-7 col-sm-12">
                        <div className="search__box">
                            <SmallSearch
                                search={searchInput}
                                setSearch={setSearchInput}
                                placeHolder="Leitaðu eftir heimilisfangi..."
                                id="Search_Kaupsamningar"
                                onSubmit={() => { searchResultRef.current?.scrollIntoView() }}
                            />
                            <p className="extra-info">Byggt á upplýsingum frá HMS frá 2006-{new Date().getFullYear()}</p>
                        </div>
                    </div>
                </div>
            </div>
            <SearchResults
                show
                ref={searchResultRef}
                component={
                    <div className="shell">
                        <DisplayBox>
                            {displayResults()}
                        </DisplayBox>
                        <ModalsAndToasts />
                    </div>
                }
            />
        </div>
    )
}

export default Kaupsamningar;