// Package imports:
import React from 'react';
import cx from 'classnames';
// Component imports:
import CompanyWatchButton from '../CompanyWatchButton/CompanyWatchButton';
import Icon from '../../Header/CustomIcon/CustomIcon';
// Service imports:
import useComponentVisible from '../../../services/hooks';
// Type imports:
import { ICompanyViewModel } from '../../../types/CompanyTypes';


const CompanyNavBar: React.FC<ICompanyViewModel> = ({
    id,
    isAuthenticated,
    isSubscriber
}) => {

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible();

    return (
            <div className="KCL_sub-navbar">
                <ul className="mobileMenu">
                    <li>
                        <span className='list-category' id="mobile" onClick={() => setIsComponentVisible(!isComponentVisible)}>
                            Yfirlit fyrirtækis <Icon type='dropdown-arrow' />
                        </span>
                        <ul ref={ref} className={cx("mobile dropdown", {"focused" : isComponentVisible})}>
                            <li><a href={`/Fyrirtaeki/Yfirlit/${id}`}>Yfirlit fyrirtækis</a></li>
                            <li className='list'>
                                <span>Skýrslur</span>
                                <ul className='list-items'>
                                    <li><a href={`/Fyrirtaeki/Arsreikningar/${id}`}>Ársreikningaskrá</a></li>
                                    <li><a href={`/Fyrirtaeki/Hlutafelagaskra/${id}`}>Hlutafélagaskrá</a></li>
                                    <li><a href={`/Fyrirtaeki/Fyrirtaekjaskra/${id}`}>Fyrirtækjaskrá</a></li>
                                    <li><a href={`/Fyrirtaeki/Innslegnir-Arsreikningar/${id}`}>Innslegnir ársreikningar</a></li>
                                    <li><a href={`/Fyrirtaeki/Samanburdur/${id}`}>Samanburður</a></li>
                                </ul>
                            </li>
                            <li className='list'>
                                <span>Eignir</span>
                                <ul className='list-items'>
                                    <li><a href={`/Fyrirtaeki/Fasteignir/${id}`}>Fasteignir</a></li>
                                    <li><a href={`/Fyrirtaeki/Okutaeki/${id}`}>Ökutæki</a></li>
                                    <li><a href={`/Fyrirtaeki/Vinnuvelar/${id}`}>Vinnuvélar</a></li>
                                </ul>
                            </li>
                            <li><a href={`/Fyrirtaeki/Logbirtingar/${id}`}>Lögbirtingar</a></li>
                            <li><a href={`/Fyrirtaeki/Verdmat/${id}`}>Verðmat</a></li>
                        </ul>
                    </li>
                </ul>
                <ul className='itemList'>
                    <li><a className='list-category' href={`/Fyrirtaeki/Yfirlit/${id}`}>Yfirlit fyrirtækis</a></li>
                    <li>
                        <span className='list-category'>Skýrslur <span className='icon'><Icon type='dropdown-arrow' /></span></span>
                        <ul className="dropdown">
                            <li><a href={`/Fyrirtaeki/Arsreikningar/${id}`}>Ársreikningaskrá</a></li>
                            <li><a href={`/Fyrirtaeki/Hlutafelagaskra/${id}`}>Hlutafélagaskrá</a></li>
                            <li><a href={`/Fyrirtaeki/Fyrirtaekjaskra/${id}`}>Fyrirtækjaskrá</a></li>
                            <li><a href={`/Fyrirtaeki/Innslegnir-Arsreikningar/${id}`}>Innslegnir ársreikningar</a></li>
                            <li><a href={`/Fyrirtaeki/Samanburdur/${id}`}>Samanburður</a></li>
                        </ul>
                    </li>
                    <li>
                        <span className='list-category' >Eignir <span className='icon'><Icon type='dropdown-arrow' /></span></span>
                        <ul className="dropdown">
                            <li><a href={`/Fyrirtaeki/Fasteignir/${id}`}>Fasteignir</a></li>
                            <li><a href={`/Fyrirtaeki/Okutaeki/${id}`}>Ökutæki</a></li>
                            <li><a href={`/Fyrirtaeki/Vinnuvelar/${id}`}>Vinnuvélar</a></li>
                        </ul>
                    </li>
                    <li><a className='list-category' href={`/Fyrirtaeki/Logbirtingar/${id}`}>Lögbirtingar</a></li>
                    <li><a className='list-category' href={`/Fyrirtaeki/Verdmat/${id}`}>Verðmat</a></li>
                </ul>
                <CompanyWatchButton isAuthenticated={isAuthenticated} isSubscriber={isSubscriber} id={id ?? ''} />
            </div>
    )
}

export default CompanyNavBar;

