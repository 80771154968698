// Package imports:
import React from 'react';
// Component imporst:
import ReportsPage from '../../components/ReportsPage/ReportsPage';
import Entrypoint from '../Entrypoint';

const Reports: React.FC = ({
    ...props
}) => {
    return (
        <Entrypoint>
            <ReportsPage />
        </Entrypoint>
    );
}

export default Reports;