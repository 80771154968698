// Package imports:
import React from 'react';
// Component imports:
import Link from '../../../ui-elements/Link/Link';
import CalculatedColoredNumber from '../../../ui-elements/CalculatedColoredNumber/CalculatedColoredNumber';
import LmdTable from '../../../ui-elements/Table/LmdTable';
// Service imports:
import { formatNumber, displayQuantityShorthand, calculateDelta } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IApiLmdSnapshot } from '../../../types/SkuldabrefTypes';
import { IDefaultProps } from '../../../types/Types';


type Props = IDefaultProps ;

const Vixlar: React.FC<Props>= ({
    refreshRateMs,
    accessToken
}) => {
    const [ vixlar, prevVixlar ] = useApiLmdData<IApiLmdSnapshot[]>(
        `v1/market_data/v1/keldan/exchange/IS/market/*/symbol/*/type/bonds_bills/delayed_bonds`,
        accessToken,
        refreshRateMs
    );

    const handleData = () => {
        const { data, error } = vixlar;
        if (data === null) return vixlar;

        const filterData = (groupname: string | null) => {
            return data.filter(x => x.index_name === groupname);
        }

        return {
            data: {
                "Verðtryggt": filterData('CPI_IS'),
                "Óverðtryggt": filterData(null)
            },
            error
        }
    }


    return (
        <div className="KCL_vixlar">
            <LmdTable
                id='KCL_skuldabref-vixlar'
                apiData={handleData()}
                columns={[{
                    title: 'Flokkur',
                    renderCell: ({symbol}) => <Link url={`/Markadir/Skuldabref/${symbol}`} linkSize='13'>{symbol}</Link>,
                    textAlign: 'left',
                    simpleSortable: ({symbol}) => symbol
                }, {
                    title: 'Síðasta krafa',
                    renderCell: ({last_yield, symbol}) => {
                        return (
                            <td style={{ padding: 0 }}>
                                <CalculatedColoredNumber
                                    currentValue={last_yield} 
                                    previousValue={prevVixlar.data?.find(pd => pd.symbol === symbol)?.last_yield ?? null}
                                    formatting='percent'
                                    hasBackground
                                />
                            </td>
                        )},
                        overrideTd: true,
                    simpleSortable: ({last_yield}) => last_yield
                }, {
                    title: 'Delta',
                    renderCell: ({
                        last_yield,
                        previous_last_yield,
                        symbol
                    }) => {
                        const prevDatum = prevVixlar.data?.find(pd => pd.symbol === symbol) ?? null
                        return (
                            <td style={{ padding: 0}}>
                                <CalculatedColoredNumber
                                    currentValue={(last_yield !== null && previous_last_yield !== null) ? last_yield - previous_last_yield : null}
                                    previousValue={
                                        (prevDatum !== null && prevDatum.last_yield !== null && prevDatum.previous_last_yield !== null ) 
                                            ? (prevDatum.last_yield - prevDatum.previous_last_yield) 
                                            : null
                                    }
                                    formatter={(val) => (val === null ? '-' : calculateDelta(val).toString())}
                                    hasBackground
                                    hasColor
                                />
                            </td>
                        )
                    },
                    overrideTd: true,
                    simpleSortable: ({last_yield, previous_last_yield}) =>
                        (last_yield === null || previous_last_yield === null)
                            ? null
                            : (last_yield - previous_last_yield)
                }, {
                    title: 'Kaupkrafa',
                    renderCell: ({bid_yield, symbol}) => {
                        return (
                            <td style={{ padding: 0 }}>
                                <CalculatedColoredNumber
                                    currentValue={bid_yield}
                                    previousValue={prevVixlar.data?.find(pd => pd.symbol === symbol)?.bid_yield ?? null}
                                    formatting='percent'
                                    hasBackground
                                />
                            </td>
                        )
                    },
                    overrideTd: true,
                    simpleSortable: ({bid_yield}) => bid_yield
                }, {
                    title: 'Sölukrafa',
                    renderCell: ({ask_yield, symbol}) => {
                        return (
                            <td style={{ padding: 0 }}>
                                <CalculatedColoredNumber
                                    currentValue={ask_yield}
                                    previousValue={prevVixlar.data?.find(pd => pd.symbol === symbol)?.ask_yield ?? null}
                                    formatting='percent'
                                    hasBackground
                                />
                            </td>
                        )},
                    overrideTd: true,
                    simpleSortable: ({ask_yield}) => ask_yield
                }, {
                    title: 'Verð',
                    renderCell: ({last_price}) => formatNumber(last_price),
                    simpleSortable: ({last_price}) => last_price
                }, {
                    title: 'Velta',
                    renderCell: ({intraday_accumulated_turnover}) => displayQuantityShorthand(intraday_accumulated_turnover),
                    simpleSortable: ({intraday_accumulated_turnover}) => intraday_accumulated_turnover
                }]}
                expandableRowConfig={{
                    defaultExpandedIndexes: [0, 1],
                    showNumberOfItemsInExpandable: false
                }}
            />
        </div>
    );
}

export default Vixlar;