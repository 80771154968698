// Package imports:
import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
// Component imports:
import Button from '../../../ui-elements/Button/Button';
import Loading from '../../../ui-elements/Loading/Loading';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { useBuyingProcess } from '../../../services/buyhook';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Fetched } from '../../../types/Types';
import { ICompanyViewModel, CompanyAssetsReport } from '../../../types/CompanyTypes';
import { IKeldanApiResponse } from '../../../types/KeldanTypes';

interface IOwnProps {
    large?: boolean,
    assetType: 'properties' | 'vehicles' | 'constructions'
}
const CompanyAssetsTab: React.FC<ICompanyViewModel & IOwnProps> = ({
    id,
    isAuthenticated,
    large,
    assetType
}) => {
    // Current report.
    const [report, setReport] = useState<Fetched<CompanyAssetsReport>>(null);
    const getViewReportUrl = () => {
        if (report === null || report instanceof Error) return undefined;
        switch (assetType) {
            case 'properties':
                return `/Fyrirtaeki/Fasteignir-Skoda/${id}?eventId=${report.eventId}`;
            case 'vehicles':
                return `/Fyrirtaeki/Okutaeki-Skoda/${id}?eventId=${report.eventId}`;
            case 'constructions':
                return `/Fyrirtaeki/Vinnuvelar-Skoda/${id}?eventId=${report.eventId}`;
        }
    }
    // Use different variable based on Asset Type (properties, vehicles or constructions).
    const {
        fetchDataUrl,
        buyReportUrl,
        viewReportUrl,
        assetName,
        dataSourceText
    } = useMemo(() => {
        switch (assetType) {
            case 'properties':
                return {
                    fetchDataUrl: `${GET_KELDAN_API_URL()}/Company/GetCompanyPropertiesReportInfo/${id}`,
                    buyReportUrl: `${GET_KELDAN_API_URL()}/Company/BuyPropertiesReport/${id}`,
                    viewReportUrl: getViewReportUrl(),
                    assetName: 'Fasteignir',
                    dataSourceText: 'fasteignaskrá'
                }
            case 'vehicles':
                return {
                    fetchDataUrl: `${GET_KELDAN_API_URL()}/Company/GetCompanyVehiclesReportInfo/${id}`,
                    buyReportUrl: `${GET_KELDAN_API_URL()}/Company/BuyVehiclesReport/${id}`,
                    viewReportUrl: getViewReportUrl(),
                    assetName: 'Ökutæki',
                    dataSourceText: 'ökutækjaskrá'
                }
            case 'constructions':
                return {
                    fetchDataUrl: `${GET_KELDAN_API_URL()}/Company/GetCompanyConstructionsReportInfo/${id}`,
                    buyReportUrl: `${GET_KELDAN_API_URL()}/Company/BuyConstructionsReport/${id}`,
                    viewReportUrl: getViewReportUrl(),
                    assetName: 'Vinnuvélar',
                    dataSourceText: 'vinnuvélaskrá'
                }
        }
    }, [ id, assetType, report ]);

    // Get current report.
    const fetchData = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            const response = await fetch(fetchDataUrl, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const body = await response.json();
                    if (body.message !== "Ekkert fannst") {
                        setReport(body);
                    } else {
                        setReport(new Error('Ekkert fannst'));
                    }
                }
            } else {
                setReport(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setReport(new Error(ErrorMessages.OtherShort));
        }
    }
    useEffect(() => {
        if (isAuthenticated)
            fetchData();
    }, [isAuthenticated]);

    // Buying process hook
    const { tryPurchaseItem, isItemLoading, resetState, ModalsAndToasts } = useBuyingProcess(async (option: boolean, modalReference: string, modalHistory) => {
        try {
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            const response = await fetch(buyReportUrl + `?reference=${modalReference}` + (assetType !== 'constructions' ? `&history=${modalHistory}` : ''), {
                method: 'GET',
                headers
            });

            if (!response.ok) {
                resetState('response not ok');
                return;
            }
            if (response.redirected) {
                window.location.href = response.url;
                return;
            }
            const responseBody: IKeldanApiResponse = await response.json();
            if (responseBody.message) {
                const { message } = responseBody;
                if (message === ErrorMessages.NoCard) {
                    resetState('no card')
                } else {
                    resetState({
                        type: 'alert',
                        headText: message
                    })
                }
                if (message === ErrorMessages.NoProperties || message === ErrorMessages.NoVehicles || message === ErrorMessages.NoConstructions) {
                    fetchData();
                }
            }
        } catch (e) {
            resetState('network error');
        }
    }, dataSourceText);

    const getReportInfo = () => {
        if (!isAuthenticated) return null;
        if (report === null) return <Loading />;
        if (report instanceof Error) {
            if (report.message === 'Ekkert fannst') return null;
            return <ErrorAlert error={report} />
        }
        return (
            <div className="owned-report-values">
                <div className="owned-report-head-row">
                    <span>Skýrsla keypt: {report.reportBought}</span>
                    {report.owned || (report.sold && report.sold !== -1)
                        ? <Button
                            buttonType='secondary'
                            anchorProps={{
                                href: viewReportUrl
                            }}
                            size='sm'
                        >
                            Skoða
                        </Button>
                        : null
                    }
                </div>
                <div className="owned-row">{assetName} í eigu: {report.owned}</div>
                {report.sold !== -1 ? <div className="sold-row">{assetName} {assetName === 'Ökutæki' ? 'seld' : 'seldar'}: {report.sold}</div> : null}
            </div>
        )
    }
    return (
        <div className={cx('KCL_company-assets-tab', {'large': large})}>
            <div className={cx("buy-current-row", {'large': large})}>
                <span>Gildandi skráning</span>
                {(isAuthenticated)
                    ? (
                        <Button
                            showLoader={isItemLoading(true)}
                            size='sm'
                            buttonType='buy'
                            onClick={() => {
                                tryPurchaseItem(
                                    true,
                                    assetType === 'constructions'
                                        ? 'confirm-modal-with-reference'
                                        : 'confirm-modal-with-reference-and-history'
                                )
                            }}
                        >
                            Kaupa
                        </Button>
                    ) : (
                        <Button
                            size='sm'
                            buttonType='buy'
                            anchorProps={{href: `/Innskraning?ReturnUrl=${window.location.pathname}`}}
                        >
                            Kaupa
                        </Button>
                    )
                }
            </div>
            {getReportInfo()}
            <ModalsAndToasts />
        </div>
    );
}

export default CompanyAssetsTab;