// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import CompanySubPage from '../../components/Company/CompanySubPage/CompanySubPage';
import CompanyReportsList from '../../components/Company/CompanyReportsList/CompanyReportsList';
// Type imports:
import { ICompanyViewModel } from '../../types/CompanyTypes';

const CompanyReportsPage: React.FC<ICompanyViewModel> = ({
    id,
    isAuthenticated,
    isSubscriber
}) => {
    return (
        <Entrypoint>
            <CompanySubPage 
                id={id} 
                title='Innslegnir ársreikningar'
                isAuthenticated={isAuthenticated}
                isSubscriber={isSubscriber}
                component={
                    <CompanyReportsList isSubscriber={isSubscriber} isAuthenticated={isAuthenticated} id={id} />
                } 
            />
        </Entrypoint>
    );
}

export default CompanyReportsPage;