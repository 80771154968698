// Package imports:
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { Toast, ToastContainer } from 'react-bootstrap';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import Link from '../../../ui-elements/Link/Link';
import Loading from '../../../ui-elements/Loading/Loading';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Button from '../../../ui-elements/Button/Button';
import Table from '../../../ui-elements/Table/Table';
import CompareSearch from '../CompanySearch/SearchBar/CompareSearch';
import Tooltip from '../../../ui-elements/Tooltip/Tooltip';
// Service imports:
import { GET_KELDAN_API_URL, TOAST_DELAY } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Fetched } from '../../../types/Types';
import { IKeldanApiResponse } from '../../../types/KeldanTypes';
import { ComparableAvailability, ICompanyViewModel } from '../../../types/CompanyTypes';

interface ICompareViewModel {
    availability: ComparableAvailability[],
}

const CompanyCompareChoose: React.FC<ICompanyViewModel> = ({
    id,
    isAuthenticated,
    isSubscriber,
}) => {
    const [data, setData] = useState<Fetched<ICompareViewModel>>(null);

    const [ chosenCompanies, setChosenCompanies ] = useState<ComparableAvailability[]>();
    const [ availableCompanies, setAvailableCompanies ] = useState<ComparableAvailability[]>();
    const [showToast, setShowToast] = useState(false);
    const [contactResponse, setContactResponse] = useState<JSX.Element>()
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Company/GetCompareData/?id=${id}`
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                const responseBody: IKeldanApiResponse<ICompareViewModel> = await response.json();
                if (responseBody.success) {
                    if (responseBody.result) {
                        setData(responseBody.result);
                        const instance = responseBody.result.availability.filter(x => x.company_id === id)
                        setChosenCompanies(instance);
                        setAvailableCompanies(responseBody.result.availability.filter(x => x.company_id !== id));
                    }
                } else {
                    const { message } = responseBody;
                    if (message) {
                        setData(new Error(message));
                    } else {
                        setData(new Error(ErrorMessages.ErrorInRequest));
                    }
                }
            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setData(new Error(ErrorMessages.NetworkError));
        }
    }

    const fetchSingleCompareableData = async (comp_id: string) => {
        // if comp id in list already we show toast and return
        if (chosenCompanies?.find(x => x.company_id === comp_id)) {
            setContactResponse(<Alert type='info' headText='Fyrirtæki nú þegar til samanburðar' />)
            setShowToast(true)
            return;
        }
        //if more than 10 instances in array already
        if (chosenCompanies && chosenCompanies.length >= 10) {
            //SHOW ERRROR
            setContactResponse(<Alert type='info' headText='Einungis er hægt að velja 10 fyrirtæki.' />)
            setShowToast(true)
            return;
        }
        try {
            const url = `${GET_KELDAN_API_URL()}/Company/SingleComparable/?id=${comp_id}`
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });

            if (!response.ok) {
                setContactResponse( <Alert type='alert' headText={ErrorMessages.RequestFailed} />);
                setShowToast(true)
                return;
            }
            const responseBody: IKeldanApiResponse<ComparableAvailability> = await response.json();
            if (responseBody.success) {
                if (responseBody.result && chosenCompanies) {
                    setChosenCompanies([...chosenCompanies, responseBody.result])
                    removeFromArray(availableCompanies, 'available', comp_id)
                }
            } else {
                const { message } = responseBody;
                if (message) {
                    setContactResponse( <Alert type='alert' headText={message} />);
                    setShowToast(true)
                } else {
                    setContactResponse( <Alert type='alert' headText={ErrorMessages.ErrorInRequest} />);
                    setShowToast(true)
                }
            }
        } catch (e) {
            setContactResponse( <Alert type='alert' headText={ErrorMessages.NetworkError} />);
            setShowToast(true)
        }
    }

    const getChosenCompaniesSSN = () => {
        const companyIds = chosenCompanies?.filter(x => x.company_id !== id).map(x => x.company_id);
        return companyIds?.join(',');
    }

    const buyComparisonReport = async () => {
        if (chosenCompanies?.length === 1) {
            setContactResponse( <Alert type='info' headText='Vinsamlegast veljið fyrirtæki til samanburðar' />);
            setShowToast(true)
            return;
        }
        setLoading(true);
        getChosenCompaniesSSN();
        try {
            const url = `${GET_KELDAN_API_URL()}/Company/Compare/?id=${id}&comparable_companies=${getChosenCompaniesSSN()}`
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'POST',
                headers
            });
            if (response.ok) {
                setLoading(false)
                if (response.redirected) {
                    window.location.href = response.url
                } else {
                    const { message }: IKeldanApiResponse = await response.json()
                    if (message === ErrorMessages.NoCard) {
                        setContactResponse(
                            <Alert
                                type='alert'
                                withCloseIcon
                                headText='Greiðsluupplýsingar vantar'
                                text={<p>Kreditkortaupplýsingar eru ekki fullnægjandi. Vinsamlegast yfirfarið <Link url='/Min-Kelda/Stillingar' linkSize='16'>kortaupplýsingar</Link>.</p>}
                            />
                        );
                        setShowToast(true)
                    } else {
                        setContactResponse( <Alert type='alert' headText={ErrorMessages.ErrorInRequest} />);
                        setShowToast(true)
                    }
                }
            } else {
                setLoading(false)
                setContactResponse( <Alert type='alert' headText={ErrorMessages.RequestFailed} />);
                setShowToast(true)
            }
        } catch (e) {
            setLoading(false)
            setContactResponse( <Alert type='alert' headText={ErrorMessages.NetworkError} />);
            setShowToast(true)
        }
    }

    const addToChosenCompanies = (comp_id: string) => {
        //cant be more than 10 companies for compare
        if(chosenCompanies && chosenCompanies.length < 10){
            //if there are less than 10 companies chosen
            if(availableCompanies){
                //add to chosen companies array
                const instance = availableCompanies.filter(x => x.company_id === comp_id);
                if(instance[0]){
                    setChosenCompanies([...chosenCompanies, instance[0]])
                }

                //delete company from available companies array
                setAvailableCompanies(availableCompanies.filter(x => x.company_id !== comp_id))
            }
        } else {
            //SHOW ERRROR
            setContactResponse(<Alert type='info' headText='Einungis er hægt að velja 10 fyrirtæki.' />)
            setShowToast(true)
        }
    }

    const removeFromArray = (dataToChange: ComparableAvailability[] | undefined, arrayType: 'chosen' | 'available', comp_id: string) => {
        if(dataToChange){
            if(arrayType === 'chosen'){
                //remove from chosen company list
                setChosenCompanies(dataToChange.filter(x => x.company_id !== comp_id));
                //if the company id was in available company list, we add it back
                if(data && !(data instanceof Error )){
                    const instance = data.availability.filter(x => x.company_id === comp_id);
                    // if instance found we add it to the availableCompanies list
                    if(instance[0] && availableCompanies) setAvailableCompanies([instance[0], ...availableCompanies])
                }
            }
            if(arrayType === 'available') setAvailableCompanies(dataToChange.filter(x => x.company_id !== comp_id)); 
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    if (data instanceof Error) {
        return <Alert type='error' headText={data.message} />
    }

    return (
        <div className='KCL_company-compare-choose'>
            <DisplayBox 
                title="Fyrirtæki í samanburð"
                asideComponent={
                    <div className='aside-component'>
                        <CompareSearch
                            disabled={chosenCompanies?.length === 10}
                            termToAdd={(e: string) => fetchSingleCompareableData(e)}
                            size='sm'
                            placeholder='Bæta fyrirtæki í listann'
                        />
                        <div className='aside-infoWrapper'>
                            <p className='italic-text'>{chosenCompanies?.length ?? 0}/10 fyrirtæki</p>
                            {isAuthenticated
                                ? <Button
                                    onClick={() => buyComparisonReport()}
                                    showLoader={loading}
                                    buttonType={!isSubscriber ? 'buy' : 'primary'}
                                    size='sm'
                                >
                                    {!isSubscriber ? 'Kaupa' : 'Skoða'}
                                </Button>
                                : <Tooltip
                                    text={
                                        <Button
                                            onClick={() => buyComparisonReport()}
                                            showLoader={loading}
                                            buttonType='buy'
                                            size='sm'
                                            disabled
                                        >
                                            Kaupa
                                        </Button>
                                    }
                                    tooltip={
                                        <span>
                                            Þú þarft að skrá þig inn og vera með skráð kreditkort til að nýta þessa þjónustu.
                                        </span>
                                    }
                                />
                            }
                        </div>
                    </div>
                }
            >
            {chosenCompanies
                ? <Table
                    columns={[{
                        title: 'Nafn',
                        renderCell: ({ name, company_id }) => <Link linkSize='15' url={`/Fyrirtaeki/Yfirlit/${company_id}`}>{name}</Link>,
                        textAlign: 'left',
                        simpleSortable: ({name}) => name

                    }, {
                        title: 'Ár',
                        renderCell: ({ fiscal_year }) => fiscal_year,
                        textAlign: 'left',
                        simpleSortable: ({fiscal_year}) => fiscal_year
                    }, {
                        title: 'Tekjur',
                        renderCell: ({ income_isk }) => 
                            income_isk
                                ? <FontAwesomeIcon icon={faCheck} className="icon fa-check"/>
                                : <FontAwesomeIcon icon={faX} className="icon fa-X"/>,
                        textAlign: 'center',
                    }, {
                        title: 'Gjöld',
                        renderCell: ({ expenses_isk }) => 
                            expenses_isk
                                ? <FontAwesomeIcon icon={faCheck} className="icon fa-check"/>
                                : <FontAwesomeIcon icon={faX} className="icon fa-X"/>,
                        textAlign: 'center',
                    }, {
                        title: 'EBITDA',
                        renderCell: ({ ebitda_isk }) => 
                            ebitda_isk
                                ? <FontAwesomeIcon icon={faCheck} className="icon fa-check"/>
                                : <FontAwesomeIcon icon={faX} className="icon fa-X"/>,
                        textAlign: 'center',
                    }, {
                        title: 'EBIT',
                        renderCell: ({ ebit_isk }) => 
                            ebit_isk
                                ? <FontAwesomeIcon icon={faCheck} className="icon fa-check"/>
                                : <FontAwesomeIcon icon={faX} className="icon fa-X"/>,
                        textAlign: 'center',
                    }, {
                        title: 'Afkoma',
                        renderCell: ({ profit_isk }) => 
                            profit_isk
                                ? <FontAwesomeIcon icon={faCheck} className="icon fa-check"/>
                                : <FontAwesomeIcon icon={faX} className="icon fa-X"/>,
                        textAlign: 'center',
                    }, {
                        title: 'Eignir',
                        renderCell: ({ assets_isk }) => 
                            assets_isk
                                ? <FontAwesomeIcon icon={faCheck} className="icon fa-check"/>
                                : <FontAwesomeIcon icon={faX} className="icon fa-X"/>,
                        textAlign: 'center',
                    }, {
                        title: 'Skuldir',
                        renderCell: ({ liabilities_isk }) => 
                            liabilities_isk
                                ? <FontAwesomeIcon icon={faCheck} className="icon fa-check"/>
                                : <FontAwesomeIcon icon={faX} className="icon fa-X"/>,
                        textAlign: 'center',
                    }, {
                        title: 'Eigið fé',
                        renderCell: ({ equity_isk }) => 
                            equity_isk
                                ? <FontAwesomeIcon icon={faCheck} className="icon fa-check"/>
                                : <FontAwesomeIcon icon={faX} className="icon fa-X"/>,
                        textAlign: 'center',
                    }, {
                        title: 'Sjóðstreymi',
                        renderCell: ({ cashfromoa_isk }) => 
                            cashfromoa_isk
                                ? <FontAwesomeIcon icon={faCheck} className="icon fa-check"/>
                                : <FontAwesomeIcon icon={faX} className="icon fa-X"/>,
                        textAlign: 'center',
                    }, {
                        title: '',
                        renderCell: ({ company_id }) => 
                            (company_id === id) 
                            ? <></>
                            : <Button
                                size='sm'
                                buttonType='secondary'
                                onClick={() => removeFromArray(chosenCompanies, 'chosen', company_id)}>Eyða</Button>,
                        textAlign: 'right',
                    },]}
                    data={chosenCompanies}
                    tableSize="lg"
                />
                : <Loading />
            }
            </DisplayBox>

            <DisplayBox
                title='Svipuð fyrirtæki'>
                    {availableCompanies
                        ? <Table
                            columns={[{
                                title: 'Nafn',
                                renderCell: ({ name, company_id }) => <Link linkSize='15' url={`/Fyrirtaeki/Yfirlit/${company_id}`}>{name}</Link>,
                                textAlign: 'left',
                                simpleSortable: ({name}) => name

                            }, {
                                title: 'Ár',
                                renderCell: ({ fiscal_year }) => fiscal_year,
                                textAlign: 'left',
                                simpleSortable: ({fiscal_year}) => fiscal_year
                            }, {
                                title: 'Tekjur',
                                renderCell: ({ income_isk }) => 
                                    income_isk
                                        ? <FontAwesomeIcon icon={faCheck} className="icon fa-check"/>
                                        : <FontAwesomeIcon icon={faX} className="icon fa-X"/>,
                                textAlign: 'center',
                            }, {
                                title: 'Gjöld',
                                renderCell: ({ expenses_isk }) => 
                                    expenses_isk
                                        ? <FontAwesomeIcon icon={faCheck} className="icon fa-check"/>
                                        : <FontAwesomeIcon icon={faX} className="icon fa-X"/>,
                                textAlign: 'center',
                            }, {
                                title: 'EBITDA',
                                renderCell: ({ ebitda_isk }) => 
                                    ebitda_isk
                                        ? <FontAwesomeIcon icon={faCheck} className="icon fa-check"/>
                                        : <FontAwesomeIcon icon={faX} className="icon fa-X"/>,
                                textAlign: 'center',
                            }, {
                                title: 'EBIT',
                                renderCell: ({ ebit_isk }) => 
                                    ebit_isk
                                        ? <FontAwesomeIcon icon={faCheck} className="icon fa-check"/>
                                        : <FontAwesomeIcon icon={faX} className="icon fa-X"/>,
                                textAlign: 'center',
                            }, {
                                title: 'Afkoma',
                                renderCell: ({ profit_isk }) => 
                                    profit_isk
                                        ? <FontAwesomeIcon icon={faCheck} className="icon fa-check"/>
                                        : <FontAwesomeIcon icon={faX} className="icon fa-X"/>,
                                textAlign: 'center',
                            }, {
                                title: 'Eignir',
                                renderCell: ({ assets_isk }) => 
                                    assets_isk
                                        ? <FontAwesomeIcon icon={faCheck} className="icon fa-check"/>
                                        : <FontAwesomeIcon icon={faX} className="icon fa-X"/>,
                                textAlign: 'center',
                            }, {
                                title: 'Skuldir',
                                renderCell: ({ liabilities_isk }) => 
                                    liabilities_isk
                                        ? <FontAwesomeIcon icon={faCheck} className="icon fa-check"/>
                                        : <FontAwesomeIcon icon={faX} className="icon fa-X"/>,
                                textAlign: 'center',
                            }, {
                                title: 'Eigið fé',
                                renderCell: ({ equity_isk }) => 
                                    equity_isk
                                        ? <FontAwesomeIcon icon={faCheck} className="icon fa-check"/>
                                        : <FontAwesomeIcon icon={faX} className="icon fa-X"/>,
                                textAlign: 'center',
                            }, {
                                title: 'Sjóðstreymi',
                                renderCell: ({ cashfromoa_isk }) => 
                                    cashfromoa_isk
                                        ? <FontAwesomeIcon icon={faCheck} className="icon fa-check"/>
                                        : <FontAwesomeIcon icon={faX} className="icon fa-X"/>,
                                textAlign: 'center',
                            }, {
                                title: '',
                                renderCell: ({ company_id }) => 
                                    <Button
                                        disabled={chosenCompanies?.length === 10}
                                        size='sm'
                                        buttonType='secondary'
                                        onClick={() => addToChosenCompanies(company_id)}
                                    >
                                        Bæta við
                                    </Button>,
                                textAlign: 'right',
                            },]}
                            data={availableCompanies}
                            tableSize="lg"
                    />
                        : <Loading />
                    }

            </DisplayBox>

            <ToastContainer className='toastContainer' >
                <Toast onClose={() => setShowToast(false)} autohide delay={TOAST_DELAY} show={showToast}>
                    {contactResponse}
                </Toast>
            </ToastContainer>
        </div>
    );
}

export default CompanyCompareChoose;