// Package imports:
import React, { useEffect, useState } from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Table from '../../ui-elements/Table/Table';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import Loading from '../../ui-elements/Loading/Loading';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import Link from '../../ui-elements/Link/Link';
import Label from '../../ui-elements/Label/Label';
// Service imports:
import { GET_KELDAN_API_URL } from '../../services/config';
import { insertDash } from '../../services/utils';
import { ErrorMessages } from '../../services/errorMessages';
import { sortEntries } from './DomesticSearch';
// Type imports:
import { Fetched } from '../../types/Types';
import { IKeldanApiResponse } from '../../types/KeldanTypes';
import { IPepType } from '../../types/PepTypes';
import { IPepData } from './PepPage';

interface IfetchedData {
    eventDate: string,
    reply: string,
}
interface IParsedReportData {
    result: IPepData,
    eventDate: string
}

const ViewPepReport: React.FC<{ id: string }>= ({
    id
}) => {
    const [ fetchedData, setfetchedData] = useState<Fetched<IParsedReportData>>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${GET_KELDAN_API_URL()}/PEP/GetReport/${id}`;
                const response = await fetch(url);
                if (response.ok) {
                    const responseBody: IKeldanApiResponse<IfetchedData> = await response.json();
                    if (responseBody.result) {
                        //parse the data and create the data object
                        const parsedData: IPepType = JSON.parse(responseBody.result.reply);
                        const newData: IParsedReportData  = {
                            eventDate: responseBody.result.eventDate,
                            result: {
                                name: parsedData.results[0].name ?? '',
                                ssn: parsedData.results[0].ssn ?? '',
                                connected: parsedData.results[0].connected ?? '',
                                entries: sortEntries(parsedData.results ?? null),
                            }
                        }
                        setfetchedData(newData);
                    } else if (responseBody.message) {
                        setfetchedData(new Error(responseBody.message));
                    } else {
                        setfetchedData(new Error(ErrorMessages.OtherShort));
                    }
                } else {
                    setfetchedData(new Error(ErrorMessages.RequestFailed));
                }
            } catch (e) {
                setfetchedData(new Error(ErrorMessages.NetworkError));
            }
        }
        fetchData();
    }, []);
    const displayResult = (data: IParsedReportData | null) => {
        // null indicates loading
        if (data === null) return <Loading />
    
        // Check if there are results
        if (data.result !== undefined){
            // Check if connected
            return <>
                <div style={{marginTop: '10px'}}>
                <Table
                    tableSize='lg'
                    data={data.result.connected ? data.result.entries : []}
                    columns={[
                        {
                        title: 'Aðili',
                        renderCell: ({ name }) => name,
                        textAlign: 'left',
                        },
                        {
                        title: 'Tengsl',
                        renderCell: ({ connection }) => connection,
                        textAlign: 'left',
                        },
                        {
                        title: 'Staða',
                        renderCell: ({ status }) => status,
                        textAlign: 'left',
                        },
                        {
                        title: 'Heimild',
                        renderCell: ({ source }) => source,
                        },
                    ]}
                />
                {
                    !data.result.connected && <p className='noResultText italic-text'>Engin stjórnmálaleg tengsl</p>
                }
                </div>
            </>
        }
    }

    const displayReport = () => {
        if (fetchedData === null) return <Loading />
        if (fetchedData instanceof Error) return <ErrorAlert error={fetchedData} />
        return <>
            <div className='info-col-wrapper'>
                <div className='info-col'>
                    <div className='info-item'>
                        <span className='title'>Kennitala</span>
                        <span className='value'>{insertDash(fetchedData.result.ssn ?? '')}</span>
                    </div>
                </div>
                <div className='info-col'>
                    <div className='info-item'>
                        <span className='title'>Nafn</span>
                        <span className='value'>{fetchedData.result.name}</span>
                    </div>
                </div>
            </div>
            <DisplayBox
                titleComponent={
                    <>
                        Stjórnmálaleg tengsl:
                        <Label
                            labelType={fetchedData.result.connected ? 'yes': 'no'}
                            size='lg'
                            text={fetchedData.result.connected ? 'Já' : 'Nei'}
                        />
                    </>
                }
                asideComponent={
                    <div className='report-aside-component'>
                        <div className='link-wrapper'>
                            <Link
                                targetBlank
                                linkSize='15'
                                url={`/Pdf/Pep/${id}`}
                                icon='pdf'
                            >
                                Sækja skýrslu
                            </Link>
                        </div>
                        <div className='italic-text'>
                            Skýrsla sótt: {fetchedData.eventDate}
                        </div>
                    </div>
                }
            >
                <div className='main'>{displayResult(fetchedData)}</div>
            </DisplayBox>
        </>
    }

    return (
        <div className='main KCL_viewPep KCL_individual-profile assets-report'>
            <div className='shell'>
                <HeadSection 
                    title="PEP listi - stjórnmálaleg tengsl"
                    showRecentReports
                />
                {displayReport()}
            </div>
        </div>
    );
}

export default ViewPepReport;