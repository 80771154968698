// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import SharesPageComp from '../../components/SharesPage/SharesPage';

const Shares: React.FC = () => {
    return (
        <Entrypoint>
            <SharesPageComp />
        </Entrypoint>
    );
}

export default Shares;