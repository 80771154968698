// Package imports:
import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import LmdTable from '../../../ui-elements/Table/LmdTable';
import Link from '../../../ui-elements/Link/Link';
import CalculatedColoredNumber from '../../../ui-elements/CalculatedColoredNumber/CalculatedColoredNumber';
import Avatar, { AvatarNames } from '../../../ui-elements/Avatar/Avatar';
import MiniGraph from '../../../ui-elements/MiniGraph/MiniGraph';
// import Tooltip from '../../../ui-elements/Tooltip/Tooltip';
// Service imports:
import { displayQuantityShorthand, getLogoSymbol } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IApiLmdSnapshot, IApiLmdIndex, IOmxAndSnapshot } from '../../../types/HlutabrefTypes';
import { ApiLmdData, IDefaultProps } from '../../../types/Types';

type Props = IDefaultProps;

const OllHlutabref: React.FC<Props>= ({
    refreshRateMs,
    accessToken,
}) => {
    const [ allEquities, prevAllEquities] = useApiLmdData<IApiLmdSnapshot[]>(
        '/v1/market_data/v1/keldan/exchange/*/market/*/symbol/*/delayed_equities',
        accessToken,
        refreshRateMs
    );
    const [ omx, prevOmx ] = useApiLmdData<IApiLmdIndex[]>(
        'v1/market_data/v1/keldan/index/OMXI15/snapshot',
        accessToken,
        refreshRateMs
    );

    const combinedData: IOmxAndSnapshot[] | null = (omx.data === null || allEquities.data === null)
    ? null
    : [
        ...omx.data.map(omx => ({
            symbol: omx.Symbol,
            last_price: omx.CurrentValue,
            intraday_per_change: omx.DiffDayPer,
            bid: null,
            ask: null,
            intraday_accumulated_turnover: null
        })),
        ...allEquities.data
    ];

    const apiData: ApiLmdData<IOmxAndSnapshot[]> = {
        data: combinedData,
        error: allEquities.error ?? omx.error ?? null
    }


    const combinedPrevData: IOmxAndSnapshot[] = [
        ...(prevOmx.data?.map(omx => ({
            symbol: omx.Symbol,
            last_price: omx.CurrentValue,
            intraday_per_change: omx.DiffDayPer,
            bid: null,
            ask: null,
            intraday_accumulated_turnover: null
        })) ?? []),
        ...(prevAllEquities?.data ?? [])
    ];

    return (
        <LmdTable 
            apiData={apiData}
            columns={[{
                title: 'Auðkenni',
                renderCell: ({symbol, notifications}) => (
                    <div className="avatar-and-text">
                        <a href={`/Markadir/${symbol === 'OMXI15' ? 'Visitolur' : 'Hlutabref'}/${symbol}`} aria-label={`${symbol === 'OMXI15' ? 'Vísitölur -' : 'Hlutabréf -'} ${symbol}`}>
                            {(symbol === null)
                                ? null
                                : (
                                    <Avatar
                                        size='sm'
                                        backgroundGrey
                                        name={(symbol === 'OMXI15') ? 'OMX' : getLogoSymbol(symbol.toUpperCase()) as AvatarNames}
                                    />
                                )
                            }
                        </a>
                        <Link url={`/Markadir/${symbol === 'OMXI15' ? 'Visitolur' : 'Hlutabref'}/${symbol}`} linkSize='13'>
                            {symbol}
                        </Link>
                        {/* {notifications !== undefined && notifications !== null &&
                            <Tooltip
                                text={<FontAwesomeIcon icon={faExclamationCircle} />}
                                tooltip={<>{notifications}</>}
                                noUnderline
                            />
                        } */}
                    </div>
                ),
                textAlign: 'left',
                simpleSortable: ({symbol}) => symbol
            }, {
                title: 'Verð',
                renderCell: ({last_price, symbol}) => {
                    return (
                        <td style={{ padding: 0 }}>
                            <CalculatedColoredNumber 
                                currentValue={last_price}
                                previousValue={combinedPrevData.find(pd => pd.symbol === symbol)?.last_price ?? null}
                                formatting='decimal'
                                hasBackground
                            />
                        </td>
                    )
                },
                overrideTd: true,
                simpleSortable: ({last_price}) => last_price
            }, {
                title: 'Breyting',
                renderCell: ({intraday_per_change, symbol}) => {
                    return (
                        <td style={{ padding: 0 }}>
                            <CalculatedColoredNumber
                                currentValue={intraday_per_change}
                                previousValue={combinedPrevData.find(pd => pd.symbol === symbol)?.intraday_per_change ?? null}
                                formatting='percent'
                                hasBackground
                                hasColor
                            />
                        </td>
                    )
                },
                overrideTd: true,
                simpleSortable: ({intraday_per_change}) => intraday_per_change
            }, {
                title: 'Sl. 3 Mán',
                renderCell: ({symbol}) => (symbol) && (
                    <MiniGraph
                        symbol={symbol}
                        folder={(symbol === 'OMXI15') ? 'Indices' : 'Tradables'}
                    />
                )
            }, {
                title: 'Kaup',
                renderCell: ({bid, symbol}) => {
                    return (
                        <td style={{ padding: 0 }}>
                            <CalculatedColoredNumber
                                currentValue={bid}
                                previousValue={combinedPrevData.find(pd => pd.symbol === symbol)?.bid ?? null}
                                formatting='decimal'
                                hasBackground
                            />
                        </td>
                    )
                },
                overrideTd: true,
                simpleSortable: ({bid}) => bid
            }, {
                title: 'Sala',
                renderCell: ({ask, symbol}) => {
                    return (
                        <td style={{ padding: 0}}>
                            <CalculatedColoredNumber
                                currentValue={ask}
                                previousValue={combinedPrevData.find(pd => pd.symbol === symbol)?.ask ?? null}
                                formatting='decimal'
                                hasBackground
                            />
                        </td>
                    )
                },
                overrideTd: true,
                simpleSortable: ({ask}) => ask
            }, {
                title: 'Velta',
                renderCell: ({intraday_accumulated_turnover}) => displayQuantityShorthand(intraday_accumulated_turnover),
                simpleSortable: ({intraday_accumulated_turnover}) => intraday_accumulated_turnover
            }]}
        />
    );
}

export default OllHlutabref;