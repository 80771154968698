// Package imports:
import React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/sharp-solid-svg-icons';

type ListItem = string | JSX.Element;

interface IProps {
    items: ListItem[],
    size?: 'sm' | 'lg'
}

const CheckList: React.FC<IProps> = ({
    items,
    size = 'lg'
}) => {
    return (
        <ul className={cx('KCL_check-list', size)}>
            {items.map((item, index) => (
                <li
                    className={cx('check-list-item', `paragraph${size === 'sm' ? '-small' : ''}`)}
                    key={index}
                >
                    <FontAwesomeIcon icon={faCheck} className='icon-fa' />
                    <span className='list-item--content'>{item}</span>
                </li>
            ))}
        </ul>
    );
}

export default CheckList;