// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import CompanySubPage from '../../components/Company/CompanySubPage/CompanySubPage';
import ViewCompanyCompare from '../../components/Company/CompanyCompare/ViewCompanyCompare';
// Type imports:
import { ICompanyReportViewModel } from '../../types/CompanyTypes';

const ViewCompanyComparePage: React.FC<ICompanyReportViewModel> = ({
    id,
    eventId,
    isAuthenticated,
    isSubscriber
}) => {
    return (
        <Entrypoint>
            <CompanySubPage
                noAd
                id={id}
                title='Samanburður'
                isAuthenticated={isAuthenticated}
                isSubscriber={isSubscriber}
                component={
                    <ViewCompanyCompare id={id} eventId={eventId} />
                }
                showRecentReports
            />
        </Entrypoint>
    );
}

export default ViewCompanyComparePage;