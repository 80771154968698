// Package imports:
import ContentLoader from "react-content-loader"

const CircleLoading = (props: any) => (
    <ContentLoader 
        speed={2}
        width="300"
        height='300'
        viewBox="0 0 300 300"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <ellipse rx="149.5" ry="149.5" transform="translate(150 150)" fill="#ecebeb" strokeWidth="0"/>
    </ContentLoader>
)

export default CircleLoading;