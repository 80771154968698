// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import CompanySubPage from '../../components/Company/CompanySubPage/CompanySubPage';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Label from '../../ui-elements/Label/Label';
import CompanyAssetsTab from '../../components/Company/CompanyAssets/CompanyAssetsTab';
// Type imports:
import { ICompanyViewModel } from '../../types/CompanyTypes';

const CompanyConstructionPage: React.FC<ICompanyViewModel> = ({
    id,
    isAuthenticated,
    isSubscriber
}) => {
    return (
        <Entrypoint>
            <CompanySubPage 
                id={id} 
                title='Vinnuvélar'
                isAuthenticated={isAuthenticated}
                isSubscriber={isSubscriber}
                component={
                    <DisplayBox
                        title='Vinnuvélar'
                        asideComponent={<Label text='VINNUV.SKR.' fullText="Vinnuvélaskrá" labelType='origin' />}
                    >
                        <CompanyAssetsTab
                            large
                            assetType='constructions'
                            id={id}
                            isAuthenticated={isAuthenticated}
                        />
                    </DisplayBox>
                } 
            />
        </Entrypoint>
    );
}

export default CompanyConstructionPage;