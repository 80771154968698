// Package imports:
import React from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Loading from '../../ui-elements/Loading/Loading';
import Alert from '../../ui-elements/Alert/Alert';
import GEMMAQChart from './GEMMAQChart';
// Service imports:
import { useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdGenderLensSnapshot } from '../../types/HlutabrefTypes';

interface IOwnProps {
    symbol: string
}

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const GEMMAQData: React.FC<Props> = ({
    symbol,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [data] = useApiLmdData<IApiLmdGenderLensSnapshot[]>(
        `/v1/market_data/v1/company_reports/exchange/*/symbol/${symbol}/gender_lens_snapshot`,
        accessToken,
        refreshRateMs
    );

    const displayBody = () => {
        if (data.error instanceof Error) {
            return <Alert type="error" headText={data.error.message} />;
        }
        if (data.data === null) {
            return <Loading />;
        }
        return <GEMMAQChart data={data.data} />;
    }

    return (
        <DisplayBox
            anchorProps={{ href: '/Markadir/Gemmaq' }}
            className='KCL_GEMMAQ-data'
            title="GEMMAQ kynjakvarði"
            {...props}
        >
            {displayBody()}
        </DisplayBox>
    );
}

export default GEMMAQData;