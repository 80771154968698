// Package imports:
import React from 'react';
// Component imports:
import Flag, { FlagNames} from '../../../ui-elements/Flag/Flag';
import Link from '../../../ui-elements/Link/Link';
import LmdTable from '../../../ui-elements/Table/LmdTable';
// Service imports:
import { formatNumber } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../../types/Types';
import { IApiLmdCurrencyRate } from '../../../types/CurrencyTypes';

const CURRENCIES_TO_SHOW = ['ISK','EUR','USD','GBP','JPY','CHF','DKK','SEK','NOK','CAD','PLN']

type IProps = IDefaultProps;

const Krossar: React.FC<IProps>= ({
    accessToken,
    refreshRateMs
}) => {
    const [ krossar ] = useApiLmdData<IApiLmdCurrencyRate[]>(
        `/v1/market_data/v1/keldan/base_currency/ISK/cross_currency/[${CURRENCIES_TO_SHOW.join(';')}]/sources/CBI/rate_types/sedlabankagengi/currency_rate`,
        accessToken,
        refreshRateMs
    );

    const handleData = () => {
        const { data, error } = krossar;
        if (data === null) return krossar;

        const sortedData = [...data].sort((a, b) => {
            if (a.Crosscurrency === null && b.Crosscurrency === null) return 0;
            if (a.Crosscurrency === null) return -1;
            if (b.Crosscurrency === null) return 1;
            return CURRENCIES_TO_SHOW.indexOf(a.Crosscurrency) - CURRENCIES_TO_SHOW.indexOf(b.Crosscurrency);
        });
        sortedData[0].Averagerate = 1;
        return {
            data: sortedData,
            error
        };
    };

    const sortedApiData = handleData();
    const dataRows = sortedApiData.data ?? [];

    return (
        <LmdTable
            apiData={sortedApiData}
            columns={[{
                title: `\u00A0`,
                renderCell: (rowDatum) => (
                    <div className="avatar-and-text">
                        <a href={`/Markadir/Gjaldmidlar/CBI${rowDatum.Crosscurrency}`} aria-label={`Gjaldmiðlar - ${rowDatum.Crosscurrency}`}>
                            <Flag name={rowDatum.Crosscurrency?.toUpperCase() as FlagNames} />
                        </a>
                        <Link
                            url={`/Markadir/Gjaldmidlar/CBI${rowDatum.Crosscurrency}`}
                            linkSize='13'
                        >
                            {rowDatum.Crosscurrency}
                        </Link>
                    </div>
                ),
                textAlign:'left'
            },
            ...dataRows.map(colDatum => ({
                title: colDatum.Crosscurrency ?? '',
                renderCell: (rowDatum: IApiLmdCurrencyRate) => {
                    if (rowDatum.Averagerate !== null && colDatum.Averagerate !== null) {
                        return formatNumber(rowDatum.Averagerate / colDatum.Averagerate)
                    } else {
                        return '-';
                    }
                },
                simpleSortable: (rowDatum: IApiLmdCurrencyRate) => (
                    rowDatum.Averagerate === null
                    || colDatum.Averagerate === null
                    || colDatum.Averagerate === 0)
                ? null
                : (rowDatum.Averagerate / colDatum.Averagerate)
            }))]}
        />
    );
}

export default Krossar;