// Package imports:
import React from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Loading from '../../ui-elements/Loading/Loading';
import Alert from '../../ui-elements/Alert/Alert';
// Service imports:
import { formatNumber, getTableDate } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdKeyFigures } from '../../types/HlutabrefTypes';

interface IOwnProps {
    symbol: string
}

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const KeyFigures: React.FC<Props> = ({
    symbol,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [data] = useApiLmdData<IApiLmdKeyFigures[]>(
        `/v1/market_data/v1/questor/key_figures_series/${symbol}/latest`,
        accessToken,
        refreshRateMs
    );

    const displayBody = () => {
        if (data.error instanceof Error) {
            return <Alert type="error" headText={data.error.message} />
        }
        if (data.data === null || data.data.length === 0) {
            return <Loading />
        }
        return <>
            <div className="box-wrapper">
                <div className="grey-box large-box">
                    <span className="grey-box__content">
                        <span className="title">Markaðsvirði</span>
                        <span className="value">{formatNumber(data.data[0].MarketCapital, '-', 0)} ISK</span>
                    </span>
                </div>
                <div className="grey-box small-box">
                    <span className="grey-box__content">
                        <span className="title">V / H hlutfall</span>
                        <span className="value">{data.data[0].EarningsTtm && data.data[0].MarketCapitalFx !== null ? formatNumber(data.data[0].MarketCapitalFx / data.data[0].EarningsTtm) : 'N/A'}</span>
                    </span>
                </div>
                <div className="grey-box small-box">
                    <span className="grey-box__content">
                        <span className="title">V / I hlutfall</span>
                        <span className="value">{data.data[0].EquityLatest && data.data[0].MarketCapitalFx !== null ? formatNumber(data.data[0].MarketCapitalFx / data.data[0].EquityLatest) : 'N/A'}</span>
                    </span>
                </div>
            </div>
        </>
    }

    return (
        <DisplayBox
            className='KCL_key-figures'
            title="Hlutföll og lykiltölur"
            asideComponent={<span className="extra-info">
                Dags. {data.error instanceof Error || data.data === null || data.data.length === 0 ? null : getTableDate(data.data[0].TradingDate, 'DD/MM/YYYY', '.')}
            </span>}
            {...props}
        >
            {displayBody()}
        </DisplayBox>
    );
}

export default KeyFigures;