// Package imports:
import React from 'react';
import cx from 'classnames';
// Component imports:
import DelayOrClosedLabel from '../DelayOrClosedLabel/DelayOrClosedLabel';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';

export type TimePeriod = 'intraday' | 'weeks4' | 'months3' | 'year';

export type HeatMapColor = 'pos' | 'neg' | 'neutral';

export interface IHeatMapData {
    symbol: string | null,
    color: HeatMapColor,
    value: number | null,
    changeValue: number | null
}

interface IOwnProps {
    timePeriod: TimePeriod;
    setTimePeriod: (timePeriod: TimePeriod) => void;
    displayBody: () => JSX.Element;
}

type Props = IOwnProps & React.HTMLAttributes<HTMLDivElement>;

const HeatMap: React.FC<Props> = ({
    timePeriod,
    setTimePeriod,
    displayBody,
    ...props
}) => {
    return (
        <DisplayBox
            className='KCL_heat-map'
            title="Hitakort"
            statusComponent={<DelayOrClosedLabel />}
            asideComponent={
                <div className="time-period-buttons">
                    <button
                        className={cx({'is-active': timePeriod === 'intraday'})}
                        onClick={() => setTimePeriod('intraday')}
                    >
                        Í dag
                    </button>
                    <button 
                        className={cx({'is-active': timePeriod === 'weeks4'})}
                        onClick={() => setTimePeriod('weeks4')}
                    >
                        1 mán.
                    </button>
                    <button
                        className={cx({'is-active': timePeriod === 'months3'})}
                        onClick={() => setTimePeriod('months3')}
                    >
                        3 mán.
                    </button>
                    <button
                        className={cx({'is-active': timePeriod === 'year'})}
                        onClick={() => setTimePeriod('year')}
                    >
                        Þetta ár
                    </button>
                </div>
            }
            {...props}
        >
            {displayBody()}
        </DisplayBox>
    );
}

export default HeatMap;