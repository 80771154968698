// Package imports:
import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
// Component imports:
import CompanyAwards from '../CompanyAwards/CompanyAwards';
import Avatar from '../../../ui-elements/Avatar/Avatar';
import Link from '../../../ui-elements/Link/Link';
import Loading from '../../../ui-elements/Loading/Loading';
import Alert from '../../../ui-elements/Alert/Alert';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { getTableDate, insertDash, displayAddress } from '../../../services/utils';
import { useFetchedApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IKeldanApiResponse } from '../../../types/KeldanTypes';
import { IAPI1819, ICompanyViewModel, KeldanCompanyInfoStripped } from '../../../types/CompanyTypes';

type Props = ICompanyViewModel;

const CompanyTitle: React.FC<Props> = ({
    id,
    accessToken,
}) => {
    const keldanCompanyDetailsData = useFetchedApiLmdData<KeldanCompanyInfoStripped>(
        `/v1/market_data/v1/keldan/company/${id}/basic_info`,
        accessToken,
        (body: KeldanCompanyInfoStripped[]) => body[0]
    )
    const [data1819, setData1819] = useState<IAPI1819>();

    //fetchPhoneNumber and logo.
    useEffect(() => {
        const fetch1819API = async () => {
            try {
                const url = `${GET_KELDAN_API_URL()}/Company/GetAPI1819/${id}`;
                const response = await fetch(url, {
                    method: 'GET',
                });
                if (response.ok) {
                    const responseBody: IKeldanApiResponse<IAPI1819> = await response.json();
                    if(responseBody) setData1819(responseBody.result);
                }
            } catch (e) {
                if (e instanceof Error) Bugsnag.notify(e);
            }
        }
        fetch1819API();
    }, []);

    const insertDashInPhoneNum = (s : string | undefined) => {
        if(s === undefined) return '-'
        if(s.length === 7)
        return s.substring(0,3) + '-' + s.substring(3)
        else return s
    }

    if (keldanCompanyDetailsData instanceof Error)
        return <Alert type='error' headText={keldanCompanyDetailsData.message} />
    //Loading, waiting for data -> loading
    if (keldanCompanyDetailsData === undefined)
        return <Loading />

    const getRegisteredDates = () => {
        if (keldanCompanyDetailsData === null) return '-'
        if (keldanCompanyDetailsData.DateDeregistered !== null)
            // \u00A0 is a non-breaking space, this forces the formatting to be consistent.
            return `${getTableDate(keldanCompanyDetailsData?.DateRegistered ?? '', 'DD/MM/YYYY', '.')}\u00A0/ ${getTableDate(keldanCompanyDetailsData?.DateDeregistered ?? '', 'DD/MM/YYYY', '.')}`
        return `${getTableDate(keldanCompanyDetailsData?.DateRegistered, 'DD/MM/YYYY', '.')}`
    }
        
    return (
        <div className='KCL_company-title'>
            <div className="info-col">
                {
                    data1819?.logo &&
                    <Avatar dataLink={data1819?.logo} size='lg'/>
                }
                <div className="info-item">
                    <span className="title">Fyrirtæki</span>
                    <span className="value companyName">{keldanCompanyDetailsData?.CompanyName}</span>
                </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Kennitala</span>
                    <span className="value">{insertDash(keldanCompanyDetailsData?.CompanyId?.toString() ?? '')}</span>
                </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Póstfang</span>
                    <span className="value">
                        {displayAddress(keldanCompanyDetailsData?.CompanyAddress, keldanCompanyDetailsData?.CompanyPostalCode, keldanCompanyDetailsData?.CompanyCity)}
                    </span> 
                </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Símanúmer</span>
                    <span className="value">
                        <Link
                            linkSize='15'
                            url={data1819?.link ?? 'https://1819.is/'}
                            target="_blank"
                        >
                            {(Array.isArray(data1819?.phones) && data1819?.phones.length)
                                ? insertDashInPhoneNum(data1819?.phones[0])
                                : '1819.is'
                            }
                        </Link>
                    </span>
                </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Ísat</span>
                    <span className="value">{keldanCompanyDetailsData?.IsatId ?? '-'}</span>
                </div>
            </div>
            <div className="info-col">
                <div className="info-item">
                    <span className="title">Ísat flokkur</span>
                    <span className="value isatCategory">{keldanCompanyDetailsData?.IsatDescriptionIs ?? '-'}</span>
                </div>
            </div>
            {!keldanCompanyDetailsData?.DateDeregistered &&
                <div className="info-col">
                    <div className="info-item">
                        <span className="title">Forráðamaður</span>
                        <span className="value isatCategory">{keldanCompanyDetailsData?.ChairmanName ?? keldanCompanyDetailsData?.BoardmemberName ?? '-'}</span>
                    </div>
                </div>
            }
            <div className="info-col">
                <div className="info-item">
                     <span className="title">
                        {(keldanCompanyDetailsData?.DateDeregistered !== null)
                            ? 'Skráð / Afskráð'
                            : 'Skráð' 
                        }
                    </span>
                    <span className="value">
                        {getRegisteredDates()} 
                    </span> 
                </div>
            </div>
            {/* fyrirmyndarfyrirtæki */}
            <CompanyAwards id={id} />
        </div>
    );
}

export default CompanyTitle;