// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import CompanySubPage from '../../components/Company/CompanySubPage/CompanySubPage';
import CompanyCompare from '../../components/Company/CompanyCompare/CompanyCompare';
// Type imports:
import { ICompanyViewModel } from '../../types/CompanyTypes';

const CompanyComparePage: React.FC<ICompanyViewModel> = ({
    id,
    isAuthenticated,
    isSubscriber
}) => {
    return (
        <Entrypoint>
            <CompanySubPage 
                id={id}
                title='Samanburður'
                isAuthenticated={isAuthenticated}
                isSubscriber={isSubscriber}
                component={
                    <CompanyCompare large id={id} isAuthenticated={isAuthenticated} />
                } 
            />
        </Entrypoint>
    );
}

export default CompanyComparePage;