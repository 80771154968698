// Package imports:
import React, { memo } from "react";
// Component imports:
import Label from "../../ui-elements/Label/Label";
// Service imports:
import { getTimeFromDate } from "../../services/utils";

interface IProps {
  title: string | null;
  url?: string | null;
  time: string | null;
  allDay: boolean;
  source: string | null;
}

const CalendarItem: React.FC<IProps> = ({
  title,
  url,
  time,
  allDay,
  source,
}) => {
  return (
    <li className="KCL_calendar-item">
      <a
        className="link"
        href={url ?? "#"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="link_background">
          <span>{title}</span>
        </div>
      </a>
      <div className="time-and-source">
        {!allDay && time ? (
          <em style={{ marginLeft: "4px" }}>
            Kl. {getTimeFromDate(time, "HH:MM")}
          </em>
        ) : (
          <em></em>
        )}
        {source && <Label text={source} labelType={"origin"} />}
      </div>
    </li>
  );
};

export default memo(CalendarItem);
