// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import NewPasswordComp from '../../components/NewPassword/NewPassword';

const NewPassword: React.FC = () => {
    return (
        <Entrypoint>
            <NewPasswordComp />
        </Entrypoint>
    );
}

export default NewPassword;