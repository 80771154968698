// Package imports:
import React from 'react';
import cx from 'classnames';
// Component imports:
import Button from '../../ui-elements/Button/Button';
import Logo from '../Header/Logo/Logo';
// Service imports:
import { getSubscriptionPrice } from '../../services/utils';

interface IProps {
    size?: 'sm' | 'lg'
    raised?: boolean
}

const SubscriptionBanner: React.FC<IProps> = ({
    size = 'lg',
    raised
}) => {
    return <div className={cx('KCL_subscription-banner', size, {
        'raised': raised
    })}>
        <div className='head-and-description'>
            <div className='head'><Logo /><span>|  áskrift</span></div>
            <span className='description'>
                Með áskrift að Keldunni færðu frjálsan aðgang að gögnum úr ársreikningum yfir 30.000 íslenskra fyrirtækja.
            </span>
        </div>
        <div className='price-and-button'>
            <div className='price-wrapper'>
                <span className='price-head'>Frá aðeins</span>
                <span className='price'><span className='price-value'>{getSubscriptionPrice()}</span> kr. á mánuði + vsk</span>
            </div>
            <Button
                buttonType='primary'
                size='lg'
                anchorProps={{href: '/Um-Askrift'}}
            >
                Koma í áskrift
            </Button>
        </div>
    </div>
}

export default SubscriptionBanner;