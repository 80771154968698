// Package imports:
import React from 'react';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type OpeningHoursType = 'kaupholl' | 'markadur';

interface IProps {
    type: OpeningHoursType,
}

const OpeningHours: React.FC<IProps> = ({
    type
}) => {
    const getText = () => {
        switch (type) {
            case 'kaupholl':
                return <>Kauphöll er opin <strong>milli kl. 09:30 og 15:30</strong> á bankadögum.</>;
            case 'markadur':
                return <>Markaðurinn er opinn <strong>milli kl. 09:15 og 16:00</strong> á bankadögum.</>;
            default:
                return <></>;
        }
    }

    return (
        <div className="KCL_opening-hours">
            <FontAwesomeIcon icon={faClock} className="icon-fa" />
            <div>
                {getText()} Bankadagar eru virkir dagar þegar bankar eru opnir á Íslandi.
            </div>
        </div>
    )
}

export default OpeningHours;