// Package imports:
import React from 'react';
// Component imports:
import AssetsReportBody from './AssetsReportBody';
// Type imports:
import { IPropertyReport } from '../../../types/PropertyTypes';
// Static data imports:
import EXAMPLE_DATA from '../../../assets/staticData/ExampleReports/Property/AssetsReport.json';

const AssetsReportExample: React.FC = () => {
    return <AssetsReportBody propertyReport={EXAMPLE_DATA as IPropertyReport} isExample />
}

export default AssetsReportExample;