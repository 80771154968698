// Package imports:
import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
// Component imports:
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Alert from '../../../ui-elements/Alert/Alert';
import Table from '../../../ui-elements/Table/Table';
import Button from '../../../ui-elements/Button/Button';
import Loading from '../../../ui-elements/Loading/Loading';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { insertDash } from '../../../services/utils';
import { useBuyingProcess } from '../../../services/buyhook';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { IndividualViewModel } from '../../../types/IndividualTypes';
import { IKeldanApiResponse, OwnedReport } from '../../../types/KeldanTypes';
import { Fetched, IAuthenticationWithId } from '../../../types/Types';

const IndividualProfile: React.FC<IAuthenticationWithId> = ({
    isSubscriber,
    id
}) => {
    const [ ssn, setSsn ] = useState<string>('');
    const [ userData, setUserData ] = useState<Fetched<IndividualViewModel>>(null);
    const [ eventData, setEventData ] = useState<Fetched<OwnedReport[]>>(null);

    const { tryPurchaseItem, isItemLoading, resetState, ModalsAndToasts } = useBuyingProcess( async () => {
        try {
            const requestQuery = new URLSearchParams({
                id: id.toString(),
                ssn: ssn
            });
            const url = `${GET_KELDAN_API_URL()}/Individual/BuyMembership`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const responseBody: IKeldanApiResponse = await response.json();
                    const { message } = responseBody;
                    if (message === ErrorMessages.NoCard) {
                        resetState('no card')
                    }
                    if (message) {
                        resetState({
                            type: 'alert',
                            headText: message
                        })
                    } else {
                        resetState('bad request')
                    }
                }
            } else {
                resetState('response not ok')
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            resetState('network error')
        }
    }, 'hlutafélagaskrá');

    const getEventInfo = async (ssn: string) => {
        try {

            const url = `${GET_KELDAN_API_URL()}/Individual/GetEventInfo/${id}?ssn=${ssn}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            });
            if (response.ok) {
                const responseBody: IKeldanApiResponse<OwnedReport[]> = await response.json();
                if(responseBody.success){
                    if (response.redirected) {
                        window.location.href = response.url;
                    }
                    if(responseBody.result){
                        setEventData(responseBody.result)
                    }
                } else {
                    const { message } = responseBody;
                    setEventData(new Error(message ?? ErrorMessages.OtherShort))
                }
            } else {
                setEventData(new Error(ErrorMessages.RequestFailed))
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setEventData(new Error(ErrorMessages.NetworkError))
        }
    }

    const getIndividualInfo = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Individual/GetIndividualInfo/${id}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            });
            if (response.ok) {
                const responseBody: IKeldanApiResponse<IndividualViewModel> = await response.json();
                if(responseBody.success){
                    if(responseBody.result){
                        setUserData(responseBody.result)
                        document.title = responseBody.result.individual.overview.details.name;
                        setSsn(responseBody.result.ssn)
                        getEventInfo(responseBody.result.ssn)
                    }
                } else {
                    const { message } = responseBody;
                    setUserData(new Error(message ?? ErrorMessages.OtherShort))
                }
            } else {
                setUserData(new Error(ErrorMessages.RequestFailed))
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setUserData(new Error(ErrorMessages.NetworkError))
        }
    }

    useEffect(() => {getIndividualInfo()}, []);

    const displayAddress = (data: IndividualViewModel) => {
        const { individual: { overview: { details: { address, zip, city } } } } = data;
        return `${address} - ${zip} ${city}`;
    }

    const displayName = (data: IndividualViewModel) => data.individual.overview.details.name;

    const displayHeader = () => {
        if (userData === null) {
            return <Loading />
        }
        if (userData instanceof Error) {
            return <ErrorAlert error={userData} />
        }
        return <>
            <HeadSection
                title={displayName(userData)}
                showRecentReports
            />
            <div>
                <div className='info-col-wrapper'>
                    <div className='info-col'>
                        <div className='info-item'>
                            <span className='title'>
                                KENNITALA
                            </span>
                            <span className='value'>
                                {insertDash(ssn)}
                            </span>
                        </div>
                    </div>
                    <div className='info-col'>
                        <div className='info-item'>
                            <span className='title'>
                                NAFN
                            </span>
                            <span className='value'>
                                {displayName(userData)}
                            </span>
                        </div>
                    </div>
                    <div className='info-col'>
                        <div className='info-item'>
                            <span className='title'>
                                PÓSTFANG
                            </span>
                            <span className='value'>
                                {displayAddress(userData)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    const displayReports = () => {
        if (eventData === null) {
            return <Loading />
        }
        if (eventData instanceof Error) {
            return <ErrorAlert error={eventData} />
        }
        return <Table
            dontShowHeaders
            data={eventData}
            columns={[{
                title: '',
                renderCell: ({properDate}) => `Skráning ${isSubscriber ? 'sótt' : 'keypt'}: ` + properDate,
                textAlign: 'left'
            }, {
                title: '',
                renderCell: ({balls}) => (
                    <Button
                        anchorProps={{href: `/Individual/ViewMembership/${balls.toString()}`}}
                        size='sm'
                        buttonType='secondary'
                    >
                        Skoða
                    </Button>
                )
            }]}
            tableSize='lg'
        />
    }

    return (
        <div className='main KCL_individual-profile'>
            <div className='main__inner-fluid'>
                <div className="shell">
                    {displayHeader()}
                    <DisplayBox
                        title='Aðild'
                        description='Hér getur þú flett upp hlutafélagaþátttöku'
                    >
                        <div>
                            <div className='gildandiSkraningContainer'>
                                <span className='gildandiSkraning_text'>
                                    Gildandi skráning
                                </span>
                                {isSubscriber
                                    ? <Button
                                        showLoader={isItemLoading(true)}
                                        onClick={() => tryPurchaseItem(true)}
                                        buttonType='primary'
                                        size='sm'
                                    >
                                        Sækja
                                    </Button>
                                    : <Button
                                        showLoader={isItemLoading(true)}
                                        onClick={() => tryPurchaseItem(true, 'confirm-modal-with-reference')}
                                        buttonType='buy'
                                        size='sm'
                                    >
                                        Kaupa
                                    </Button>
                                }
                            </div>
                            {displayReports()}
                        </div>
                    </DisplayBox>
                    <Alert
                        type='info'
                        headText='Athugið'
                        text={
                            <>
                                <p>Birtar upplýsingar eru sóttar með tengingu við Þjóðskrá. Allar upplýsingar eru veittar samkvæmt starfsreglum Þjóðskrár og í samræmi við lög og reglur um persónuvernd og meðferð persónuupplýsinga.</p>
                                <p>Reynt er að tryggja að upplýsingar séu réttar en Keldan getur ekki ábyrgst áreiðanleika þeirra.</p>
                            </>
                        }
                    />
                </div>
            </div>
            <ModalsAndToasts />
        </div>
    );
}

export default IndividualProfile;
