// Package imports:
import React from 'react';
// Component import.
import ListarSerpontunPage from '../../components/ListarSerpontun/ListarSerpontun';
import Entrypoint from '../Entrypoint';

const Serpontun: React.FC = () => {
    return (
        <Entrypoint>
            <ListarSerpontunPage />
        </Entrypoint>
    );
}

export default Serpontun;