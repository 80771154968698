// Package imports:
import React, { useContext, useEffect, useState } from 'react';
// Component imports:
import AdRotator from '../Ad/AdRotator';
import MultipleIndices from '../MultipleIndices/MultipleIndices';
import IndicesChart from '../Charts/IndicesChart/IndicesChart';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
// Service imports:
import { DEFAULT_REFRESH_RATE, MARKET_REFRESH_RATES } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';
// Context imports:
import { AccessTokenContext } from '../../contexts/AccessTokenContext';

const IndicesPage: React.FC = () => {

    const accessToken = useContext(AccessTokenContext);
    const [ defaultProps, setDefaultProps ] = useState<IDefaultProps>({
        accessToken,
        refreshRateMs: DEFAULT_REFRESH_RATE
    });

    useEffect(() => {
        setDefaultProps({
            ...defaultProps,
            accessToken
        });
    }, [accessToken]);


    return (
        <div className='main KCL_indices-page KCL_market-page'>
            <div className='main__inner-fluid'>
                <div className="shell">
                    <HeadSection
                        hasAds='market'
                        title='Vísitölur'
                    />
                    <div>
                        <div className='middle-section grid-items'>
                            <div className='grid-item first-item-middle-section'>
                                <MultipleIndices
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['MULTIPLEINDICES']}
                                    id="IndicesPage_IndicesTable"
                                />
                            </div>
                        </div>
                        <div className='middle-ad-section'>
                            <AdRotator location='Market1018x360' />
                        </div>
                        <div className='grid-items grid-items--flex'>
                            <div className="grid-item grid-item--3of4 grid-item--table-full">
                                <IndicesChart
                                    title='Samanburður'
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['INDICESCHART']}
                                    id="IndicesPage_Chart"
                                />
                            </div>
                            <div className='grid-item grid-item--1of4 grid-item--table-full'>
                                <AdRotator location="Market310x400" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IndicesPage;