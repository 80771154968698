// Package imports:
import React from 'react';
// Component import.
import AlmenntAllt from '../../components/Markhopalistar/ListarAlmenntAllt';
import Entrypoint from '../Entrypoint';

const AlmenntAlltPage: React.FC = ({
    ...props
}) => {
    return (
        <Entrypoint>
            <AlmenntAllt model={props as any} />
        </Entrypoint>
    );
}

export default AlmenntAlltPage;