// Package imports:
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import FilterItem from '../../ui-elements/Filter/FilterItem/FilterItem';
import LegalSearch from './LegalSearch';
import CompanySearch from './CompanySearch';
import DefaultSearch from './DefaultSearch';
import SmallSearch from '../SmallSearch/SmallSearch';
import ThjodskraSearch from './ThjodskraSearch';
import AdRotator from '../Ad/AdRotator';
import Button from '../../ui-elements/Button/Button';
import Loading from '../../ui-elements/Loading/Loading';
import PropertySearch from './PropertySearch';
// Service imports:
import { useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IApiLmdAutocompleteItem } from '../../types/SearchTypes';
// Context imports:
import { AccessTokenContext } from '../../contexts/AccessTokenContext';


export const getItemHref = (item: IApiLmdAutocompleteItem) => {
    switch (item.Tickertype) {
        case 'share':
            return `/Markadir/Hlutabref/${item.Symbol}`;
        case 'bond':
            return `/Markadir/Skuldabref/${item.Symbol}`;
        case 'currency':
            return `/Markadir/Gjaldmidlar/${item.Symbol}`;
        case 'fund':
            return `/Markadir/Sjodir/${item.Symbol}`;
        case 'index':
            return `/Markadir/Visitolur/${item.Symbol}`;
        case 'bondIndex':
            return `/Markadir/Visitolur/${item.Symbol}`;
        case 'company':
            return `/Fyrirtaeki/Yfirlit/${item.Symbol}`;
        default:
            break;
    }
}
interface IProps {
    term?: string,
    isAuthenticated?: boolean,
}

export type ResultStatus = 'disabled' | 'has results' | 'no results' | 'loading' | 'no card';

type SearchFilterType = 'Fyrirtæki' | 'Hlutabréf' | 'Skuldabréf' | 'Gjaldmiðlar' | 'Sjóðir' | 'Vísitölur' | 'Skuldabréfa Vísitölur' | 'Lögbirtingar' | 'Þjóðskrá' | 'Fasteignir';

type NEWSettingsBooleanMap = {
    [key in SearchFilterType]: ResultStatus
}

interface IApiLmdAutocompleteSpecial {
    [key: string]: any;
}

export const MAX_RESULTS = 10;

const SearchPage: React.FC<IProps> = ({isAuthenticated}) => {
    const INITIAL_SETTINGS: NEWSettingsBooleanMap = {
        'Fyrirtæki': 'loading',
        'Hlutabréf': 'loading',
        'Skuldabréf': 'loading',
        'Gjaldmiðlar': 'loading',
        'Sjóðir': 'loading',
        'Vísitölur': 'loading',
        'Skuldabréfa Vísitölur': 'loading',
        //loaded on demand
        'Lögbirtingar': 'no results',
        'Þjóðskrá': 'no results',
        'Fasteignir': 'no results'
    }
    const urlParams = new URLSearchParams(window.location.search);
    const term = urlParams.get('search');
    const accessToken = useContext(AccessTokenContext);
    // Autocomplete request variables:
    const [autocompleteData] = useApiLmdData<IApiLmdAutocompleteSpecial>(`/v1/static_data/v1/autocomplete/keldan/`, accessToken);
    const [searchInput, setSearchInput] = useState(term);
    const [termToSearchFor, setTermToSearchFor] = useState(term);

    // Settings: filter by type.
    const [ settings, setSettings ] = useState(INITIAL_SETTINGS);
    const [displayMoreButton, setDisplayMoreButton] = useState(true)

    const handleResultsChange = useCallback((componentName: SearchFilterType, hasResults: ResultStatus) => {
        setSettings(prevResults => ({ ...prevResults, [componentName]: hasResults }))
      }, []);

    useEffect(() => {
        //reset settings and display more button when search term changes
        setDisplayMoreButton(true)
        setSettings(INITIAL_SETTINGS)
    },[termToSearchFor])

    const loadMoreResults = () => {
        // load thjodskra and logbirtingar components
        setDisplayMoreButton(false)
        if(isAuthenticated) {
            setSettings({...settings, 'Þjóðskrá': 'loading', 'Lögbirtingar': 'loading', 'Fasteignir': 'loading'})
        }
    }

    const dataKeys: { [key: string]: string } = {
        'Fyrirtæki': 'companies',
        'Hlutabréf': 'shares',
        'Skuldabréf': 'bonds',
        'Gjaldmiðlar': 'currencies',
        'Sjóðir': 'funds',
        'Vísitölur': 'indexes',
        'Skuldabréfa Vísitölur': 'bond_indexes',
        'Lögbirtingar': 'legal',
        'Þjóðskrá': 'nationalRegistry',
        'Fasteignir': 'properties'
    };

    const data: { [key in SearchFilterType]: JSX.Element | null } = useMemo(() => {
        const data: { [key in SearchFilterType]: JSX.Element | null } = {
            'Fyrirtæki': null,
            'Hlutabréf': null,
            'Skuldabréf': null,
            'Gjaldmiðlar': null,
            'Sjóðir': null,
            'Vísitölur': null,
            'Skuldabréfa Vísitölur': null,
            'Lögbirtingar': null,
            'Þjóðskrá': null,
            'Fasteignir': null
        };
        const searchTypes: SearchFilterType[] = [
            'Fyrirtæki',
            'Hlutabréf',
            'Skuldabréf',
            'Gjaldmiðlar',
            'Sjóðir',
            'Vísitölur',
            'Skuldabréfa Vísitölur',
            'Lögbirtingar',
            'Þjóðskrá',
            'Fasteignir'
        ];

        for (const type of searchTypes) {
            if (!termToSearchFor || (displayMoreButton && (type === 'Lögbirtingar' || type === 'Þjóðskrá' || type === 'Fasteignir'))) {
                data[type] = null;
            } else if (type === 'Fyrirtæki') {
                data[type] = <CompanySearch onResultsChange={hasResults => handleResultsChange(type, hasResults)} term={termToSearchFor} />;
            } else if (type === 'Lögbirtingar') {
                data[type] = <LegalSearch isAuthenticated={isAuthenticated} onResultsChange={hasResults => handleResultsChange(type, hasResults)} term={termToSearchFor} />;
            } else if (type === 'Þjóðskrá') {
                data[type] = <ThjodskraSearch isAuthenticated={isAuthenticated} onResultsChange={hasResults => handleResultsChange(type, hasResults)} term={termToSearchFor} />;
            } else if (type === 'Fasteignir') {
                data[type] = <PropertySearch isAuthenticated={isAuthenticated} onResultsChange={hasResults => handleResultsChange(type, hasResults)} term={termToSearchFor} />;
            } else {
                data[type as SearchFilterType] = <DefaultSearch title={type} data={autocompleteData.data?.[dataKeys[type]] ?? autocompleteData.error} onResultsChange={hasResults => handleResultsChange(type as SearchFilterType, hasResults)} term={termToSearchFor} />;
            }
        }
        return data;
    }, [settings, isAuthenticated, autocompleteData, displayMoreButton]);


    const displaySetting = (title: SearchFilterType, value: ResultStatus, index: number) => {
        if(data[title] === null) return null
        if (value === 'disabled') {return (
            <li key={index}>
                <FilterItem
                    size='lg'
                    showCheck={true}
                    text={title}
                    selected={false}
                    toggleSelected={() => {
                        setSettings({
                            ...settings,
                            [title]: 'has results'
                        })
                    }}
                />
            </li>
        )} else if (value === 'has results') {
            return (
                <li key={index}>
                    <FilterItem
                        size='lg'
                        showCheck={true}
                        text={title}
                        selected={true}
                        toggleSelected={() => {
                            setSettings({
                                ...settings,
                                [title]: 'disabled'
                            })
                        }}
                    />
                </li>
        )} else {
            return null
        }
    }

    const isEmpty = useMemo(() => {
        //if all items in settings are 'no results' => it is empty.
        if (Object.values(settings).every(value => value === 'no results')) return true;

        return false
    }, [ settings, data]);

    const isLoading = useMemo(() => {
        //if all items in settings are 'loading' => it is loading.
        if (Object.values(settings).every(value => value === 'loading')) return true;

        return false
    }, [ settings, data]);
    const displayResults = () => {
        if (isLoading) return <Loading />
        if (isEmpty) return <div>
            <h4>Engar leitarniðurstöður fundust fyrir "{termToSearchFor}"</h4>
            <SmallSearch
                search={searchInput ?? ''}
                setSearch={setSearchInput}
                inputSize='lg'
                placeHolder='Leitaðu eftir fyrirtækjum og verðbréfum...'
                onSubmit={(e) => {setTermToSearchFor(e); window.history.pushState(null, '', '/leit?search=' + e);}}
                id='Search_page'
            />
        </div>
        return <>
            <strong className='paragraph-small--bold'>Sía eftir tegund efnis:</strong>
            <div className='filter_search_wrapper'>
                <div className='KCL_filter'>
                    <ul className='filter_container'>
                        {Object.entries(settings).map(([title, value], index) => displaySetting(title as SearchFilterType, value, index))}
                    </ul>
                </div>
                <SmallSearch
                    search={searchInput ?? ''}
                    setSearch={setSearchInput}
                    inputSize='lg'
                    placeHolder='Leitaðu eftir fyrirtækjum og verðbréfum...'
                    onSubmit={(e) => {setTermToSearchFor(e); window.history.pushState(null, '', '/leit?search=' + e);}}
                    id='Search_page'
                />
            </div>
            <div>
                {Object.entries(data).map(([title, results]) => (
                    (settings[title as SearchFilterType] === 'has results' || settings[title as SearchFilterType] === 'loading')
                        ? <div key={title}>{results}</div> 
                        : null
                ))}
            </div>
            <div style={{marginTop: '20px'}} className='jc-center'>
                {isAuthenticated && displayMoreButton
                    ? <Button buttonType='secondary' onClick={() => loadMoreResults()}>Fleiri niðurstöður</Button>
                    : settings["Lögbirtingar"] === 'loading' && settings['Þjóðskrá'] === 'loading' && settings["Fasteignir"] === 'loading' && <Loading />
                }
            </div>
        </>
    }
    return (
        <div className='main KCL_searchPage'>
            <div className="main__inner-fluid">
                <div className="shell">
                    <div className="section">
                        <h2>Leitarniðurstöður fyrir "{termToSearchFor}"</h2>
                        <div className='content_wrapper'>
                            <div className='result_wrapper'>
                                <DisplayBox>
                                    {displayResults()}
                                </DisplayBox>
                            </div>
                            <AdRotator location='Market310x400' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchPage;