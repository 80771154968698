// Package imports:
import React from 'react';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Table from '../../../ui-elements/Table/Table';
import Link from '../../../ui-elements/Link/Link';
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';
import Alert from '../../../ui-elements/Alert/Alert';
// Service imports:
import { insertDash, capitalize } from '../../../services/utils';
// Type imports:
import { IndividualMembership } from '../../../types/IndividualTypes';
import { IEventReport } from '../../../types/KeldanTypes';

interface IProps {
    report: IEventReport<IndividualMembership>,
    isExample?: boolean
}

const ViewMembershipBody: React.FC<IProps> = ({
    report,
    isExample = false
}) => {

    const displayName = () => {
        return report?.overview?.member?.name ?? report?.overview?.name ?? null;
    }

    const displayAddress = () => {
        return (report.overview.member?.address ?? report.overview.address) + ' - ' 
            + (report.overview.member?.zip ?? report?.overview.zip) + ' ' 
            + (report.overview.member?.city ?? report.overview.city)
    }

    return (
        <>
            <HeadSection
                title={displayName()}
                showRecentReports={!isExample}
            />
            <div>
                <div className='info-col-wrapper'>
                    <div className='info-col'>
                        <div className='info-item'>
                            <span className='title'>
                                KENNITALA
                            </span>
                            <span className='value'>
                                {insertDash(report.overview?.member?.ssn ?? report.overview.ssn ?? '')}
                            </span>
                        </div>
                    </div>
                    {(report.overview?.includeAddress)
                        ? <div className='info-col'>
                            <div className='info-item'>
                                <span className='title'>
                                    PÓSTFANG
                                </span>
                                <span className='value'>
                                    {displayAddress()}
                                </span>
                            </div>
                        </div>
                        : null
                    }
                </div>
            </div>
            <div>
                <DisplayBox
                    title={`Aðild keypt ${report?.eventDate}`}
                    grayBackground={isExample}
                >
                    <div>
                        {(report.overview.member)
                            ? <Table
                                tableSize='lg'
                                data={report.overview.member.list}
                                columns={[{
                                    title: 'Félag',
                                    renderCell: ({name, companySsn}) => (
                                        <Link
                                            linkSize='15'
                                            url={`/Fyrirtaeki/Yfirlit/${companySsn}`}
                                            targetBlank
                                            disabled={isExample}
                                        >
                                            {name}
                                        </Link>
                                    ),
                                    textAlign: 'left',
                                    simpleSortable: ({name}) => name
                                }, {
                                    title: 'Kennitala',
                                    renderCell: ({companySsn}) => insertDash(companySsn)
                                }, {
                                    title: 'Afskráð',
                                    renderCell: ({unlisted, state_unlisted}) => (unlisted) ? `Afskráð ${state_unlisted}` : '',
                                }, {
                                    title: 'Hlutverk',
                                    renderCell: ({description_type, description_pos}) => (!description_pos) ? description_type : capitalize(description_pos),
                                }]}
                            />
                            : <Table
                                tableSize='lg'
                                data={report.overview.list?.list.list ?? []}
                                columns={[{
                                    title: 'Félag',
                                    renderCell: ({name, ssn}) => <Link linkSize='15' url={`/Fyrirtaeki/Yfirlit/${ssn}`} targetBlank>{name}</Link>,
                                    textAlign: 'left',
                                    simpleSortable: ({name}) => name
                                }, {
                                    title: 'Kennitala',
                                    renderCell: ({ssn}) => insertDash(ssn)
                                }, {
                                    title: 'Afskráð',
                                    renderCell: ({unlisted, state_unlisted}) => (unlisted === "J") ? `Afskráð ${state_unlisted}` : '',
                                }, {
                                    title: 'Hlutverk',
                                    renderCell: ({description_type, description_pos}) => (description_pos === null) ? description_type : description_pos,
                                }]}
                            />
                        }
                    </div>
                </DisplayBox>
            </div>
            <Alert
                type='info'
                headText='Athugið' 
                text={
                    <>
                        <p>Birtar upplýsingar eru sóttar með tengingu við Þjóðskrá. Allar upplýsingar eru veittar samkvæmt starfsreglum Þjóðskrár og í samræmi við lög og reglur um persónuvernd og meðferð persónuupplýsinga.</p>
                        <p>Reynt er að tryggja að upplýsingar séu réttar en Keldan getur ekki ábyrgst áreiðanleika þeirra.</p>
                    </>
                }
            />
        </>
    );
}

export default ViewMembershipBody;