// Package imports:
import React from 'react';
// Component import.
import ExcellentListComponent from '../../components/ExcellentCompanies/ExcellentList/ExcellentList';
import Entrypoint from '../Entrypoint';

interface IOwnProps {
    year?: string | null
}

const ExcellentList: React.FC<IOwnProps> = ({
    year
}) => {
    return (
        <Entrypoint>
            <ExcellentListComponent year={year ?? '2021'} />
        </Entrypoint>
    );
}

export default ExcellentList;