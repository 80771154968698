// Package imports:
import React from 'react';
// Component imports:
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import Button from '../../ui-elements/Button/Button';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import BulletList from '../../ui-elements/Lists/BulletList';

const Webservice: React.FC = () => {
    return (
        <div className="main KCL_webservice">
            <div className='section--white'>
                <div className='shell'>
                    <HeadSection
                        title="Vefþjónustu aðgangur Keldunnar"
                        summary={
                            <p>
                                Auktu skilvirkni í miðlun gagna og upplýsinga með vefþjónustu frá Keldunni.
                                <br />
                                Viðskiptavinir Keldunnar geta fengið aðgang að vefþjónustu þar sem gögnum er streymt beint frá grunni Keldunnar í eigin kerfi með API tengingu.
                            </p>
                        }
                        actions={
                            <Button anchorProps={{ href: '/Hafa-Samband/API'}}>Hafðu samband</Button>
                        }
                        textAlign='center'
                    />
                </div>
            </div>
            <div className='section--gray body-content'>
                <div className='shell'>
                    <h2 className='webservice-title'>Vefþjónustur í boði</h2>
                    <div className='row'>
                        <div className='col-lg-4 col-sm-12'>
                            <DisplayBox
                                className="card"
                                title="Ársreikningagrunnur"
                                displayBoxSize="lg"
                            >
                                <p className='paragraph-small'>
                                    Vefþjónusta með tengingu við ársreikningagrunn Keldunnar þar sem hægt er að birta gögn úr innslegnum ársreikningum.
                                    <br/>
                                    Verð per uppflettingu er 790 kr. +vsk.
                                </p>
                                <Button
                                    size='sm'
                                    buttonType='secondary'
                                    anchorProps={{ href: '/Hafa-Samband/API'}}
                                >
                                    Hafðu samband
                                </Button>
                            </DisplayBox>
                        </div>
                        <div className='col-lg-4 col-sm-12'>
                            <DisplayBox
                                className="card middle-card"
                                title="Opinberar skrár"
                                displayBoxSize="lg"
                            >
                                <p className='paragraph-small'>
                                    Vefþjónusta með tengingu við allar helstu opinberar skrár á Íslandi: 
                                </p>
                                <BulletList
                                    items={[
                                        'Fyrirtækjaskrá',
                                        'Fasteignaskrá',
                                        'Ökutækjaskrá',
                                        'Þjóðskrá',
                                    ]}
                                    size='sm'
                                />
                                <Button
                                    size='sm'
                                    buttonType='secondary'
                                    anchorProps={{ href: '/Hafa-Samband/API'}}
                                >
                                    Hafðu samband
                                </Button>
                            </DisplayBox>
                        </div>
                        <div className='col-lg-4 col-sm-12'>
                            <DisplayBox
                                className="card"
                                title="Sérsniðin vefþjónusta"
                                displayBoxSize="lg"
                            >
                                <p className='paragraph-small'>
                                    Þarft þú sérsniðna vefþjónustu? 
                                    <br />
                                    Keldan getur hjálpað þér að setja saman umfangsmeiri vefþjónustu eftir þínum hentugleika og er þá gjald umsamið. 
                                </p>
                                <Button
                                    size='sm'
                                    buttonType='secondary'
                                    anchorProps={{ href: '/Hafa-Samband/API'}}
                                >
                                    Hafðu samband
                                </Button>
                            </DisplayBox>
                        </div>
                    </div>
                    <div className='row'>
                        <p className='bottom-disclaimer paragraph--italic'>
                            Notkunarskilmálar fyrir vefþjónustu Keldunnar: 
                            <br />
                            Vefþjónusta er aðgengileg og til notkunar fyrir innri kerfi félaga en ekki til endurbirtingar á opnum síðum, nema um það sé sérstaklega samið.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Webservice;