// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import AboutComp from '../../components/About/About';

const About: React.FC = () => {
    return (
        <Entrypoint>
            <AboutComp />
        </Entrypoint>
    );
}

export default About;