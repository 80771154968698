// Package imports:
import React from 'react';
// Component imports:
import Link from '../../../ui-elements/Link/Link';
import VerticalTable from '../../../ui-elements/Table/VerticalTable';
import Table from '../../../ui-elements/Table/Table';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
// Type imports:
import { IEventReport } from '../../../types/KeldanTypes';
import { ConstructionsSearchResult, ConstructionsSearchResultOld } from '../../../types/VehicleTypes';

interface IProps {
    eventId?: string,
    data?: IEventReport<ConstructionsSearchResult>,
    dataOld?: IEventReport<ConstructionsSearchResultOld>,
    isExample?: boolean
}

const ConstructionReportBody: React.FC<IProps> = ({
    eventId,
    data,
    dataOld,
    isExample = false
}) => {
    if (data !== undefined) {
        return (
            <DisplayBox
                grayBackground={isExample}
                title='Grunnupplýsingar'
                asideComponent={
                    <div className='report-aside-component'>
                        <div className='link-wrapper'>
                        <Link
                            targetBlank
                            linkSize='15'
                            url={`/Pdf/Construction/${eventId}`}
                            disabled={isExample}
                            icon='pdf'
                        >
                            Sækja skýrslu
                        </Link>
                        </div>
                        <div className='italic-text'>
                            Skýrsla keypt: {data?.eventDate  ?? ''}
                        </div>
                    </div>
                }
            >
                {/* Grunnupplýsingar */}
                <div className='vertical-table-wrapper'>
                    <VerticalTable
                        data={[data.overview.data]}
                        columns={[{
                            title: 'Skráð',
                            renderCell: ({regDate}) => `${regDate.dateTime.date.day}.${regDate.dateTime.date.month}.${regDate.dateTime.date.year}`
                        }, {
                            title: 'Gerð',
                            renderCell: ({make}) => make,
                        }, {
                            title: 'Undirflokkur',
                            renderCell: ({subcategory}) => subcategory,
                        }, {
                            title: 'Eigandi',
                            renderCell: ({owner}) => owner,
                        }, {
                            title: 'Umráðamaður',
                            renderCell: ({caretaker}) => caretaker === '' ? 'Á ekki við' : caretaker,
                        }, {
                            title: 'Innflytjandi',
                            renderCell: ({importer}) => importer,
                        }, {
                            title: 'Framleiðslunúmer',
                            renderCell: ({vin}) => vin,
                        }
                    ]}
                        tableSize="lg"
                    />
                    <VerticalTable
                        data={[data.overview.data]}
                        columns={[{
                            title: 'Tegund',
                            renderCell: ({type}) => type,
                        }, {
                            title: 'Yfirflokkur',
                            renderCell: ({category}) => category,
                        }, {
                            title: 'Árgerð',
                            renderCell: ({model}) => model,
                        }, {
                            title: 'Kennitala eiganda',
                            renderCell: ({ownerSsn}) => ownerSsn,
                        }, {
                            title: 'Kennitala umráðamanns',
                            renderCell: ({caretakerSsn}) => caretakerSsn === '' ? 'Á ekki við' : caretakerSsn,
                        }, {
                            title: 'Staða',
                            renderCell: ({status}) => status,
                        }, {
                            title: 'Framleiðsluland	',
                            renderCell: ({country}) => country,
                        }]}
                        tableSize="lg"
                    />
                </div>
                {
                    data.overview.data.ownedHistory !== null &&
                        <div>
                            <h4>Eigendasaga</h4>
                            <Table 
                                data={data.overview.data.ownedHistory}
                                columns={[{
                                    title: 'Eigandi',
                                    renderCell: ({name}) => name,
                                    textAlign: 'left',
                                }, {
                                    title: 'Kennitala',
                                    renderCell: ({ssn}) => ssn,
                                    textAlign: 'left',
                                }, {
                                    title: 'Skráningarlykill',
                                    renderCell: ({code}) => code,
                                }, {
                                    title: 'Dags. eigendaskipta',
                                    renderCell: ({dateTraded}) => `${dateTraded?.dateTime.date.day}.${dateTraded?.dateTime.date.month}.${dateTraded?.dateTime.date.year}`,
                                }, {
                                    title: 'Dags. skráningar',
                                    renderCell: ({dateRegistered}) => `${dateRegistered?.dateTime.date.day}.${dateRegistered?.dateTime.date.month}.${dateRegistered?.dateTime.date.year}`,
                                }]}
                                tableSize='lg'
                            />
                        </div>

                }
            </DisplayBox>
        )
    }
    if (dataOld !== undefined) {
        return (
            <DisplayBox
                title='Grunnupplýsingar'
                asideComponent={
                    <div className='report-aside-component'>
                        <div className='link-wrapper'>
                            <Link
                                targetBlank
                                linkSize='15'
                                url={`/Pdf/Construction/${eventId}`}
                                icon='pdf'
                            >
                                Sækja skýrslu
                            </Link>
                        </div>
                        <div className='italic-text'>
                            Skýrsla keypt: {dataOld.eventDate ?? ''}
                        </div>
                    </div>
                }
            >
                {/* Grunnupplýsingar */}
                <div className='vertical-table-wrapper'>
                    <VerticalTable
                        data={[dataOld.overview.data]}
                        columns={[{
                            title: 'Skráð',
                            renderCell: ({regDate}) => `${regDate.day}.${regDate.month}.${regDate.year}`
                        }, {
                            title: 'Gerð',
                            renderCell: ({make}) => make,
                        }, {
                            title: 'Undirflokkur',
                            renderCell: ({subcategory}) => subcategory,
                        }, {
                            title: 'Eigandi',
                            renderCell: ({owner}) => owner,
                        }, {
                            title: 'Umráðamaður',
                            renderCell: ({caretaker}) => caretaker === '' ? 'Á ekki við' : caretaker,
                        }, {
                            title: 'Innflytjandi',
                            renderCell: ({importer}) => importer,
                        }, {
                            title: 'Framleiðslunúmer',
                            renderCell: ({vin}) => vin,
                        }
                    ]}
                        tableSize="lg"
                    />
                    <VerticalTable
                        data={[dataOld.overview.data]}
                        columns={[{
                            title: 'Tegund',
                            renderCell: ({type}) => type,
                        }, {
                            title: 'Yfirflokkur',
                            renderCell: ({category}) => category,
                        }, {
                            title: 'Árgerð',
                            renderCell: ({model}) => model,
                        }, {
                            title: 'Kennitala eiganda',
                            renderCell: ({ownerSsn}) => ownerSsn,
                        }, {
                            title: 'Kennitala umráðamanns',
                            renderCell: ({caretakerSsn}) => caretakerSsn === '' ? 'Á ekki við' : caretakerSsn,
                        }, {
                            title: 'Staða',
                            renderCell: ({status}) => status,
                        }, {
                            title: 'Framleiðsluland	',
                            renderCell: ({country}) => country,
                        }]}
                        tableSize="lg"
                    />
                </div>
                {
                    dataOld.overview.data.ownedHistory.anyType !== null &&
                        <div>
                            <h4>Eigendasaga</h4>
                            <Table 
                                data={dataOld.overview.data.ownedHistory.anyType}
                                columns={[{
                                    title: 'Eigandi',
                                    renderCell: ({name}) => name,
                                    textAlign: 'left',
                                }, {
                                    title: 'Kennitala',
                                    renderCell: ({ssn}) => ssn,
                                    textAlign: 'left',
                                }, {
                                    title: 'Skráningarlykill',
                                    renderCell: ({code}) => code,
                                }, {
                                    title: 'Dags. eigendaskipta',
                                    renderCell: ({dateTraded}) => `${dateTraded.day}.${dateTraded.month}.${dateTraded.year}`,
                                }, {
                                    title: 'Dags. skráningar',
                                    renderCell: ({dateRegistered}) => `${dateRegistered.day}.${dateRegistered.month}.${dateRegistered.year}`,
                                }]}
                                tableSize='lg'
                            />
                        </div>
                }
            </DisplayBox>
        )
    }
    return null;
}

export default ConstructionReportBody;