// Package imports:
import React from 'react';
// Component imports:
import ScrollSpy from '../../../ui-elements/ScrollSpy/ScrollSpy';
import Link from '../../../ui-elements/Link/Link';
import ScrollSpySection from '../../../ui-elements/ScrollSpy/ScrollSpySection';

const PersonuverndPEP: React.FC = () => {
    return (
        <div className='personuvernd'>
            <article className='article-overview'>
                <div className='article__head'>
                    <p className='paragraph'>
                        Persónuverndaryfirlýsing þessi útskýrir hvernig Keldan ehf., kt. 491009-0200 (framvegis kölluð <span className='paragraph--bold'>„Keldan“</span>) meðhöndlar og að öðru leyti vinnur persónuupplýsingar í tengslum við vöruna PEP listi - stjórnmálaleg tengsl (framvegis kölluð <span className='paragraph--bold'>„PEP listi“</span>). Persónuverndaryfirlýsingin gildir um allar persónuupplýsingar sem Keldan vinnur í tengslum við PEP listann.
                    </p>
                    <p className='paragraph'>
                        Fyrirspurnum,  ábendingum  og  athugasemdum  sem  varða  persónuupplýsingar  eða  persónuverndarstefnu þessa má beina á netfangið <Link url="mailto:pep@keldan.is" linkSize='18'>pep@keldan.is</Link>.
                    </p>
                </div>
                <div className='article__body'>
                    <ScrollSpy
                        containerClass='article__content'
                        navClass='article__nav'
                        parentClass='personuvernd'
                        initialStep='hlutverk-keldunnar'
                    >
                        <ScrollSpySection className='article__section' id='hlutverk-keldunnar' label='Hlutverk Keldunnar'>
                            <h2>Hlutverk Keldunnar</h2>
                            <p className='paragraph'>Í tengslum við vinnslu persónuupplýsinga á PEP listanum telst Keldan ábyrgðaraðili í skilningi reglugerðar Evrópuþingsins og ráðsins (ESB) 2016/679 frá 27. apríl 2016 um vernd einstaklinga í tengslum við vinnslu persónuupplýsinga og um frjálsa miðlun slíkra upplýsinga (framvegis kallað <span className='paragraph--bold'>„GDPR”</span>), sbr. einnig lög um Persónuvernd og vinnslu persónuupplýsinga nr. 90/2018 (framvegis kallað <span className='paragraph--bold'>„persónuverndarlög“</span> eða <span className='paragraph--bold'>“pvl.”</span>).</p>
                        </ScrollSpySection>
                        <ScrollSpySection className='article__section' id='vinnsla-og-sofnun' label='Vinnsla og söfnun persónuupplýsinga'>
                            <h2>Vinnsla og söfnun persónuupplýsinga</h2>
                            <p className='paragraph'>Í tengslum við PEP listann safnar Keldan upplýsingum um aðila sem hafa stjórnmálaleg tengsl í skilningi laga um vernd gegn peningaþvætti nr. 140/2018 (framvegis kallað <span className='paragraph--bold'>"peningaþvættislög"</span> eða <span className='paragraph--bold'>“pþvl.”</span>). Er hér um að ræða einstaklinga sem hafa verið háttsettir í opinberri þjónustu, ásamt nánustu fjölskyldu þeirra og nánum samstarfsmönnum. Persónuupplýsingar sem Keldan vinnur í þessum tilgangi eru: nafn, kennitala, staða, nafn og kennitala þess aðila sem einstaklingur er tengdur (ef einstaklingur er eða hefur ekki sjálfur verið háttsettur í opinberri þjónustu) og upplýsingar um tímabil sem einstaklingur gegndi starfi (dagsetningar).</p>
                            <p className='paragraph'>Upplýsingum um einstaklinga á listanum er safnað af opinberum vefsíðum, Þjóðskrá Íslands, úr fjölmiðlum og Hlutafélagaskrá.</p>
                        </ScrollSpySection>
                        <ScrollSpySection className='article__section' id='tilgangur-vinnslu' label='Tilgangur vinnslu persónupplýsinga og heimild Keldunnar'>
                            <h2>Tilgangur vinnslu persónupplýsinga og heimild Keldunnar</h2>
                            <h3>Almennar persónuupplýsingar</h3>
                            <p className='paragraph'>Vinnslan er nauðsynleg í þágu lögmætra viðskiptahagsmuna Keldunnar, sbr. 6. Tölulið 9. gr. pþvl. Hagsmunir, grundvallarréttindi og frelsi hins skráða sem krefjast verndar persónuupplýsinga eru ekki taldir vega þyngra en þeir lögmætu hagsmunir sem liggja til grundvallar vinnslunni.</p>
                            <h3>Viðkvæmar persónuupplýsingar</h3>
                            <p className='paragraph'>Ljóst er að hluti framangreindra upplýsinga kunna að teljast viðkvæmar persónuupplýsingar en samkvæmt a-lið 3. tölul. 3. gr. persónuverndarlaga eru stjórnmálaskoðanir einstaklings viðkvæmar persónuupplýsingar. Hins vegar, með vísan til 5. tölul. 11. gr. pvl., er heimilt að vinna með viðkvæmar persónuupplýsingar sem hinn skráði hefur augljóslega sjálfur gert opinberar. Í því sambandi safnar Keldan og vinnur eingöngu upplýsingar um stjórnmálaskoðanir sem hafa birst í því samhengi að viðkomandi hafi opinberlega tekið þátt í stjórnmálum.</p>
                        </ScrollSpySection>
                        <ScrollSpySection className='article__section' id='afhending-personuupplysinga' label='Afhending persónuupplýsinga til þriðju aðila'>
                            <h2>Afhending persónuupplýsinga til þriðju aðila</h2>
                            <p className='paragraph'>Keldan miðlar persónuupplýsingum til sameiginlegra ábyrgðaraðila sinna, það er tilkynningarskyldra aðila sem eru áskrifendur að listanum. Þá kann Keldan að miðla upplýsingum til þriðju aðila sem eru ráðnir af fyrirtækinu til að þjónusta Kelduna í tengslum við fyrirfram ákveðna vinnu, svo sem þjónustuveitendur í tengslum við upplýsingatækni eða hýsingu. Í þeim tilfellum gerir Keldan vinnslusamning við viðkomandi aðila. Slíkur samningur kveður meðal annars á um skyldu vinnsluaðilans til að fylgja fyrirmælum Keldunnar um meðferð persónuupplýsinga og er honum óheimilt að nota þær í öðrum tilgangi. Jafnframt ber vinnsluaðila skylda til að tryggja öryggi upplýsinganna með viðeigandi hætti. Í öðrum tilvikum getur einnig verið nauðsynlegt fyrir Kelduna að miðla persónuupplýsingum til þriðju aðila, til dæmis þegar skylda stendur til þess samkvæmt lögum.</p>
                            <p className='paragraph'>Samkvæmt persónuverndarlögum er Keldunni aðeins heimilt að flytja persónuupplýsingar út fyrir EES ef miðlunin er nauðsynleg, til dæmis til að efna samning og í afmörkuðum tilvikum vegna lögmætra hagsmuna fyrirtækisins. Þá er Keldunni aðeins heimilt að miðla slíkum upplýsingum til landa sem tryggja fullnægjandi vernd (að mati framkvæmdastjórnar ESB) eða ef Keldan hefur gert viðeigandi verndarráðstafanir.</p>
                        </ScrollSpySection>
                        <ScrollSpySection className='article__section' id='vardveislutimi-personuupplysinga' label='Varðveislutími persónuupplýsinga'>
                            <h2>Varðveislutími persónuupplýsinga</h2>
                            <p className='paragraph'>Persónuupplýsingar eru varðveittar svo lengi sem lögmætar eða málefnalegar ástæður eru fyrir vinnslunni. Er upplýsingum því eytt eigi síðar en 18 mánuðum frá því að staða skráðra einstaklinga hefur breyst á þann veg að þeir teljast ekki lengur falla undir áhættuhóp vegna stjórnmálalegra tengsla í skilningi peningaþvættislaga. Til að tryggja framangreint framkvæmir Keldan reglulega endurskoðun á skráningu upplýsinganna og uppfærir þær reglulega með tilliti til nýrra upplýsinga.</p>
                        </ScrollSpySection>
                        <ScrollSpySection className='article__section' id='rettindi-einstaklinga' label='Réttindi einstaklinga'>
                            <h2>Réttindi einstaklinga</h2>
                            <p className='paragraph'>Í persónuverndarlögum er kveðið á um ýmis réttindi einstaklinga, til að mynda aðgangsrétt, flutningsrétt, rétt til leiðréttingar og eyðingar, og rétt til að andmæla eða takmarka vinnslu persónuupplýsinga. Einstaklingum sem ætla að neyta réttinda sinna er nauðsynlegt að sanna á sér deili. Réttindin geta verið takmörkunum háð, sem leiða meðal annars af lögum, hagsmunum annarra sem upplýsingarnar varða eða mikilvægum fjárhags- eða viðskiptahagsmunum. Almennt eru beiðnir sem varða framangreind réttindi einstaklingum að kostnaðarlausu. Keldan áskilur sér rétt til þess að innheimta gjald ef farið er fram á afhendingu fleiri en eins afrits af persónuupplýsingum. Keldan áskilur sér einnig rétt til að neita að verða við beiðni sem er augljóslega tilefnislaus eða óhófleg.</p>
                            <p className='paragraph'>Áður en einstaklingur er skráður á PEP listann er honum send skrifleg tilkynning um að það standi til að skrá viðkomandi á listann og honum gefinn 14 daga frestur til að koma á framfæri athugasemdum og/eða leiðréttingum vegna fyrirhugaðrar skráningar.</p>
                        </ScrollSpySection>
                        <ScrollSpySection className='article__section' id='oryggi-og-eftirlit' label='Öryggi og eftirlit'>
                            <h2>Öryggi og eftirlit</h2>
                            <p className='paragraph'>Keldan leggur mikla áherslu á öryggi við vinnslu og meðferð persónuupplýsinga. Keldan tryggir viðeigandi öryggi, þar með talið með vernd gegn óleyfilegri eða ólögmætri vinnslu og gegn glötun, eyðileggingu eða tjóni fyrir slysni, með viðeigandi tæknilegum og skipulagslegum ráðstöfunum.</p>
                            <p className='paragraph'>Komi upp öryggisbrestur við meðferð persónuupplýsinga þar sem staðfest er eða grunur leikur á um að persónuupplýsingar hafi komist í hendur óviðkomandi aðila er Persónuvernd og eftir atvikum, einstaklingum tilkynnt um öryggisbrest nema hann hafi ekki í för með sér mikla áhættu fyrir einstaklinga.</p>
                        </ScrollSpySection>
                        <ScrollSpySection className='article__section' id='kvortun-til-personuverndar' label='Kvörtun til Persónuverndar'>
                            <h2>Kvörtun til Persónuverndar</h2>
                            <p className='paragraph'>Ef upp kemur ágreiningur við meðferð persónuupplýsinga á einstaklingur ávallt rétt á því að beina kvörtun til Persónuverndar með tölvupósti á netfangið <Link linkSize='18' url='mailto:postur@personuvernd.is'>postur@personuvernd.is</Link>.</p>
                        </ScrollSpySection>
                        <ScrollSpySection className='article__section' id='breytingar-a-personuverndarstefnunni' label='Breytingar á persónuverndarstefnunni'>
                            <h2>Breytingar á persónuverndarstefnunni</h2>
                            <p className='paragraph'>Keldan er ávallt að leita leiða til að auka öryggi persónuupplýsinga. Persónuverndarstefnan kann því að taka breytingum í takt við aukið öryggi. Breytingar á stefnunni taka gildi við birtingu uppfærðrar stefnu á vefsíðu Keldunnar: <Link linkSize='18' url='https://keldan.is/'>www.keldan.is</Link>.</p>
                        </ScrollSpySection>
                    </ScrollSpy>
                </div>
            </article>
        </div>
    );
}

export default PersonuverndPEP;