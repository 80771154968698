// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import NewPricingPageComp from '../../components/PricingPage/NewPricingPage';

const NewPricingPage: React.FC = () => {
    return (
        <Entrypoint>
            <NewPricingPageComp />
        </Entrypoint>
    );
}

export default NewPricingPage;