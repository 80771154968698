// Package imports:
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as regularStar } from '@fortawesome/pro-regular-svg-icons';
import { faStar as solidStar}  from '@fortawesome/pro-solid-svg-icons';
import ToastContainer from 'react-bootstrap/esm/ToastContainer';
import Toast from 'react-bootstrap/esm/Toast';
import Bugsnag from '@bugsnag/js';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import LockIcon from '../../../ui-elements/LockIcon/LockIcon';
// Service imports:
import { GET_KELDAN_API_URL, TOAST_DELAY } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { IKeldanApiResponse } from '../../../types/KeldanTypes';
import { ICompanyViewModel } from '../../../types/CompanyTypes';

const CompanyWatchButton: React.FC<ICompanyViewModel> = ({
    id,
    isSubscriber
}) => {
    const [watching, setWatching] = useState<boolean>(false);
    const [watchIds, setWatchIds] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [showToast, setShowToast] = useState(false);
    const [contactResponse, setContactResponse] = useState<JSX.Element>();

    const handleClick = async () => {
        setLoading(true);
        let url = (watching)
                    ? `${GET_KELDAN_API_URL()}/Company/DeleteWatches?ids=${watchIds}`
                    : `${GET_KELDAN_API_URL()}/Company/AddWatch?id=${id}`;
        try {
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            const response = await fetch(url, {
                method: 'POST',
                headers
            });
            if (response.ok) {
                setLoading(false);
                if(response.redirected){
                    window.location.href = response.url;
                }
                const responseBody: IKeldanApiResponse<string> = await response.json();
                if (responseBody.success) {
                    if(responseBody.result) setWatchIds(responseBody.result)
                    setWatching(!watching)
                } else {
                    const { message } = responseBody;
                    setShowToast(true);
                    setContactResponse(<Alert closeAlert={() => setShowToast(false)} withCloseIcon type='alert' headText={message ?? ''} />);
                }
            } else {
                setLoading(false);
                setShowToast(true);
                setContactResponse(<Alert closeAlert={() => setShowToast(false)} withCloseIcon type='alert' headText={ErrorMessages.RequestFailed} />);
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setLoading(false);
            setShowToast(true);
            setContactResponse(<Alert closeAlert={() => setShowToast(false)} withCloseIcon type='error' headText={ErrorMessages.Other} />);
        }
    }

    const fetchWatchIds = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Company/GetWatchIds/${id}`;
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                const responseBody: IKeldanApiResponse<string> = await response.json();
                if (responseBody.success) {
                    if(responseBody.result)
                    setWatchIds(responseBody.result)
                    setWatching((responseBody.result !== null && responseBody.result !== '') ? true : false );
                } else {
                    const { message } = responseBody;
                    setShowToast(true);
                    setContactResponse(<Alert closeAlert={() => setShowToast(false)} withCloseIcon type='alert' headText={message ?? ''} />);
                }
            } else {
                setShowToast(true);
                setContactResponse(<Alert closeAlert={() => setShowToast(false)} withCloseIcon type='alert' headText={ErrorMessages.RequestFailed} />);
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setShowToast(true);
            setContactResponse(<Alert closeAlert={() => setShowToast(false)} withCloseIcon type='error' headText={ErrorMessages.Other} />);
        }
    }
   
    useEffect(() => {
        if (isSubscriber)
            fetchWatchIds();
    }, [isSubscriber])

    if (isSubscriber) {
        return (
            <>
                <ToastContainer className='toastContainer'>
                    <Toast onClose={() => setShowToast(false)} autohide delay={TOAST_DELAY} show={showToast}>
                        {contactResponse}
                    </Toast>
                </ToastContainer>
                <button 
                    onClick={() => handleClick()}
                    className={cx(
                        'KCL_company-watch-button',
                        'KCL_button',
                        'with-icon',
                        'sm',
                        { 'watching': watching }
                    )}
                >
                    <FontAwesomeIcon
                        icon={(watching) ? solidStar : regularStar} 
                        className={cx("icon-fa", {"rotate": loading})}
                    />
                    <span>
                        {(watching) 
                            ? 'Fyrirtæki í vöktun'
                            : 'Vakta fyrirtæki'
                        }
                    </span>
                </button>
            </>
        )
    } else {
        //not logged in or not in subscription
        return (
            <button className={cx('KCL_company-watch-button KCL_button sm disabled')}>
                <span>
                    <LockIcon />
                    <span>Vakta fyrirtæki</span>
                </span>
            </button>
        )
    }
}

export default CompanyWatchButton;