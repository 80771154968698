// Package imports:
import React, { useEffect, useMemo, useState } from "react";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Bugsnag from "@bugsnag/js";
import { Toast, ToastContainer } from "react-bootstrap";
// Component imports:
import Alert from "../../../ui-elements/Alert/Alert";
import Table from "../../../ui-elements/Table/Table";
import Button from "../../../ui-elements/Button/Button";
import FrettaVaktinForm from "./FrettaVaktinForm";
import Tooltip from "../../../ui-elements/Tooltip/Tooltip";
// Service imports:
import { GET_KELDAN_API_URL, TOAST_DELAY } from '../../../services/config';
import { ErrorMessages } from "../../../services/errorMessages";
import SEO from "../../../services/SEO";
// Type imports:
import { IKeldanApiResponse } from "../../../types/KeldanTypes";
import { IisAuthenticated } from "../../../types/Types";

interface IWatchItem {
    search_term: string,
    system: string,
}

interface IStockwatcherResponse {
    error?: string,
    search_term?: string,
    system?: string,
}

const FrettaVaktin: React.FC<IisAuthenticated> = ({
    isSubscriber = false
}) => {
    const [contactResponse, setContactResponse] = useState<JSX.Element>(<></>);
    const [showToast, setShowToast] = useState(false);
    const [ watches, setWatches ] = useState<IWatchItem[]>([]);

    const fetchWatches = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/News/GetAllSearchTermsInWatch`;
            const response = await fetch(url);
            if (response.ok) {
                if (response.redirected) window.location.href = response.url;
                const body : IKeldanApiResponse<IWatchItem[]> = await response.json();
                setWatches(body.result ?? []);
            } else {
                setShowToast(true)
                setContactResponse(<Alert type={'error'} headText={ErrorMessages.UnableToFetchData} />);
            }
        } catch (e) {
            if (e instanceof Error) {
                Bugsnag.notify(e);
            }
            setShowToast(true)
            setContactResponse(<Alert type={'error'} headText={ErrorMessages.NetworkError} />);
        }
    };
    useEffect(() => {
        if (isSubscriber) fetchWatches()
    }, [isSubscriber])

    const displayData = useMemo(() => {
        if (!watches) return [];
        let data: IWatchItem[] = [];
        watches.forEach((watch) => {
            data.push({
                search_term: watch.search_term,
                system: watch.system
            });
        });
        return data;
    }, [watches]);
 
    const handleDelete = async (term: string) => {
        try {
            setContactResponse(<></>);
            const url = `${GET_KELDAN_API_URL()}/News/DeleteWatch?term=${term}`;
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
            });
            if (response.ok) {
                if (response.redirected) window.location.href = response.url;
                const responseBody: IKeldanApiResponse<IStockwatcherResponse> = await response.json();
                //request return 200
                if (responseBody.success) {
                    // result includes error object. So if that is undefined no error occured
                    if(responseBody.result?.error){
                        const { error } = responseBody.result;
                        setShowToast(true)
                        // already exist error translating
                        setContactResponse(<Alert type={'alert'} headText={error} />);
                    } else {
                        setShowToast(true)
                        //success message
                        setContactResponse(<Alert type={'success'} headText={`${term} hefur verið eytt úr vöktun`} />);
                        fetchWatches()
                    }
                } else {
                    setShowToast(true)
                    setContactResponse(<Alert type={'error'} headText={ErrorMessages.Other} />)
                }
            } else {
                setShowToast(true)
                setContactResponse(<Alert type={'error'} headText={ErrorMessages.RequestFailed} />);
            }
        } catch (e) {
            setShowToast(true)
            setContactResponse(<Alert type={'error'} headText={ErrorMessages.NetworkError} />);
        }
    }

    const displayForm = (type: 'enabled' | 'disabled') => {
        return <FrettaVaktinForm
            fetchWatches={() => fetchWatches()}
            disabled={type === 'disabled'}
        />
    }

    return (
        <div className="KCL_watch-tab">
            <SEO
                title="Fréttavaktin"
                description="Með fréttavakt Keldunnar geta áskrifendur vaktað allt að 10 orð eða orðasambönd. Þegar fréttir eru birtar sem innihalda orð í vöktun er tilkynning þess efnis send með tölvupósti."
                keyword="Fréttir, fréttaumfjöllun, vakta fréttir, fréttavöktun, fréttavakt, fjölmiðlavakt, fjölmiðlavaktin, leitarorð, orðasamband, orð til að vakta, í fjölmiðlum, skrá í vöktun"
            />
            <div className="upper-section">
                <div className="left-side">
                    <h3>Fréttavaktin</h3>
                    <p className="description paragraph">
                        Með fréttavakt Keldunnar geta áskrifendur vaktað allt að 10 orð eða orðasambönd. Þegar fréttir eru birtar sem innihalda orð í vöktun er tilkynning þess efnis send með tölvupósti.
                    </p>
                    {isSubscriber
                        ? displayForm('enabled')
                        : <Tooltip
                            tooltip={<span>Aðeins í boði fyrir áskrifendur</span>}
                            text={displayForm('disabled')}
                        />
                    }
                </div>
                <Alert
                    type="info"
                    headText="Athugið"
                    text={<div>
                        <p>
                        Komma (,) á milli leitarorða skilar fréttum sem innihalda bæði orð eða orðasambönd.
                        Gæsalappir (“...”) utanum orðasambönd skila fréttum sem innihalda orð í þeirri röð.
                        Mínus merki (-) fyrir framan orð skila fréttum sem innihalda ekki orðið.
                        <br/><strong>Fréttavaktin nær til fyrirsagna og textabúta, eða eins og þær birtast á Keldunni.</strong>
                        </p>
                        </div>
                    }
                />
            </div>
            {isSubscriber && <>
                <div className="middle-section marginTop75px">
                    <h3 className="table-title">Orð í vöktun</h3>
                </div>
                <Table
                    data={displayData}
                    columns={[{
                        title: 'í vöktun',
                        renderCell: ({search_term}) => search_term,
                        textAlign: 'left'
                    }, {
                        title: '',
                        renderCell: ({search_term}) => (
                            <Button icon={faTrashCan} size="sm" buttonType="secondary" onClick={() => {
                                handleDelete(search_term);
                            }}>
                                Eyða
                            </Button> 
                        ),
                    }]}
                    tableSize='lg'
                />
                <ToastContainer className='toastContainer' >
                    <Toast
                        show={showToast}
                        autohide
                        delay={TOAST_DELAY}
                        onClose={() => setShowToast(false)}
                    >
                        {contactResponse}
                    </Toast>
                </ToastContainer>
            </>}
        </div>
    )
}

export default FrettaVaktin;
