// Package imports:
import React, { useContext } from 'react';
// Component imports:
import CompanyAssets from '../CompanyAssets/CompanyAssets';
import CompanyStatements from '../CompanyStatements/CompanyStatements';
import CompanyPapers from '../CompanyPapers/CompanyPapers';
import CompanyFinancialInfo from '../CompanyFinancialInfo/CompanyFinancialInfo';
import CompanyLegal from '../CompanyLegal/CompanyLegal';
import CompanyCompare from '../CompanyCompare/CompanyCompare';
import CompanySimilar from '../CompanySimilar/CompanySimilar';
import CompanyMembers from '../CompanyMembers/CompanyMembers';
import CompanyTitle from '../CompanyTitle/CompanyTitle';
import CompanyNavBar from '../CompanyNavBar/CompanyNavBar';
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';
import CompanyReportsChartWrapper from '../CompanyReportsChartWrapper/CompanyReportsChartWrapper';
import CompanyOwners from '../CompanyOwners/CompanyOwners';
import CompanyValuation from '../CompanyValuation/CompanyValuation';
import AdRotator from '../../Ad/AdRotator';
// Type imports:
import { ICompanyViewModel } from '../../../types/CompanyTypes';
// Context imports:
import { AccessTokenContext } from '../../../contexts/AccessTokenContext';

const Profile: React.FC<ICompanyViewModel> = ({
    id,
    isAuthenticated,
    isSubscriber
}) => {
    const accessToken = useContext(AccessTokenContext);
    
    return (
        <div className='main KCL_company-profile'>
            <div className='main__inner-fluid'>
                <CompanyNavBar isAuthenticated={isAuthenticated} isSubscriber={isSubscriber} id={id} />
                <div className="shell">
                    <HeadSection title='Fyrirtækjaleit Keldunnar' />
                    <CompanyTitle id={id} accessToken={accessToken} />
                    <div className='grid-items'>
                        <div className='grid-item grid-item--2of3'>
                            <CompanyFinancialInfo
                                id={id}
                                isAuthenticated={isAuthenticated}
                                isSubscriber={isSubscriber}
                                accessToken={accessToken}
                            />
                        </div>
                        <div className='grid-item grid-item--1of3'>
                            <CompanyStatements
                                id={id}
                                isAuthenticated={isAuthenticated}
                                limit={2}
                            />
                            {!isSubscriber  && <AdRotator location='Market310x400' />}
                            <CompanyMembers id={id} isAuthenticated={isAuthenticated} isSubscriber={isSubscriber} />
                            <AdRotator location='Company400x80' />
                            <CompanyValuation id={id} isAuthenticated={isAuthenticated} isSubscriber={isSubscriber} accessToken={accessToken} />
                            <CompanyAssets id={id} isAuthenticated={isAuthenticated} />
                            <CompanyPapers
                                id={id}
                                isAuthenticated={isAuthenticated}
                                limit={3}
                            />
                            <CompanyReportsChartWrapper
                                id={id}
                                isSubscriber={isSubscriber}
                                accessToken={accessToken}
                            />
                        </div>
                        <div className='grid-item grid-item--2of3'>
                            <div className='grid-items'>
                                <div className='grid-item grid-item--1of2'>
                                    <CompanyCompare id={id} isAuthenticated={isAuthenticated} />
                                    <CompanyOwners id={id} isAuthenticated={isAuthenticated} />
                                </div>
                                <div className='grid-item grid-item--1of2'>
                                    <CompanySimilar id={id} />
                                    {isAuthenticated && <CompanyLegal id={id} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;