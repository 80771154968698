interface ICountryList {
  label: string;
  value: string;
}

export const CountryList: ICountryList[] = [
  { value: "AF", label: "Afganistan" },
  { value: "AL", label: "Albanía" },
  { value: "DZ", label: "Alsír" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angóla" },
  { value: "AI", label: "Angvilla" },
  { value: "AG", label: "Antígva og Barbúda" },
  { value: "AR", label: "Argentína" },
  { value: "AM", label: "Armenía" },
  { value: "AW", label: "Arúba" },
  { value: "AU", label: "Ástralía" },
  { value: "AZ", label: "Aserbaídsjan" },
  { value: "AT", label: "Austurríki" },
  { value: "TL", label: "Austur-Tímor" },
  { value: "AX", label: "Álandseyjar" },
  { value: "BS", label: "Bahamaeyjar" },
  { value: "BH", label: "Barein" },
  { value: "BD", label: "Bangladess" },
  { value: "BB", label: "Barbados" },
  { value: "BE", label: "Belgía" },
  { value: "BZ", label: "Belís" },
  { value: "BJ", label: "Benín" },
  { value: "BM", label: "Bermúdaeyjar" },
  { value: "BT", label: "Bútan" },
  { value: "BO", label: "Bólivía" },
  { value: "BA", label: "Bosnía og Hersegóvína" },
  { value: "BW", label: "Botsvana" },
  { value: "BR", label: "Brasilía" },
  { value: "VG", label: "Bresku Jómfrúaeyjar" },
  { value: "IO", label: "Bresku Indlandshafseyjar" },
  { value: "BN", label: "Brúnei" },
  { value: "BG", label: "Búlgaría" },
  { value: "BF", label: "Búrkína Fasó" },
  { value: "BI", label: "Búrúndí" },
  { value: "BV", label: "Bouveteyja" },
  { value: "KH", label: "Kambódía" },
  { value: "CM", label: "Kamerún" },
  { value: "CA", label: "Kanada" },
  { value: "CV", label: "Grænhöfðaeyjar" },
  { value: "KY", label: "Caymaneyjar" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "Kína" },
  { value: "CX", label: "Jólaeyja" },
  { value: "CC", label: "Kókoseyjar" },
  { value: "CO", label: "Kólumbía" },
  { value: "KM", label: "Kómoreyjar" },
  { value: "CG", label: "Vestur-Kongó" },
  { value: "CD", label: "Austur-Kongó" },
  { value: "CK", label: "Cookseyjar" },
  { value: "CR", label: "Kostaríka" },
  { value: "HR", label: "Króatía" },
  { value: "CU", label: "Kúba" },
  { value: "CY", label: "Kýpur" },
  { value: "CZ", label: "Tékkland" },
  { value: "DK", label: "Danmörk" },
  { value: "DJ", label: "Djíbútí" },
  { value: "DM", label: "Dóminíka" },
  { value: "DO", label: "Dóminíska lýðveldið" },
  { value: "EC", label: "Ekvador" },
  { value: "EG", label: "Egyptaland" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Miðbaugs-Gínea" },
  { value: "ER", label: "Erítrea" },
  { value: "EE", label: "Eistland" },
  { value: "ET", label: "Eþíópía" },
  { value: "FK", label: "Falklandseyjar" },
  { value: "FO", label: "Færeyjar" },
  { value: "FJ", label: "Fídjieyjar" },
  { value: "FI", label: "Finnland" },
  { value: "FR", label: "Frakkland" },
  { value: "GF", label: "Franska Gvæjana" },
  { value: "PF", label: "Franska Pólýnesía" },
  { value: "TF", label: "Frönsku suðlægu landsvæðin" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambía" },
  { value: "GE", label: "Georgía" },
  { value: "DE", label: "Þýskaland" },
  { value: "GH", label: "Gana" },
  { value: "GI", label: "Gíbraltar" },
  { value: "GR", label: "Grikkland" },
  { value: "GL", label: "Grænland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Gvadelúpeyjar" },
  { value: "GU", label: "Gvam" },
  { value: "GT", label: "Gvatemala" },
  { value: "GN", label: "Gínea" },
  { value: "GW", label: "Gínea-Bissá" },
  { value: "GY", label: "Gvæjana" },
  { value: "HT", label: "Haítí" },
  { value: "HM", label: "Heard og McDonaldseyjar" },
  { value: "HN", label: "Hondúras" },
  { value: "HK", label: "Hong Kong" },
  { value: "HU", label: "Ungverjaland" },
  { value: "IS", label: "Ísland" },
  { value: "IN", label: "Indland" },
  { value: "ID", label: "Indónesía" },
  { value: "IR", label: "Íran" },
  { value: "IQ", label: "Írak" },
  { value: "IE", label: "Írland" },
  { value: "IL", label: "Ísrael" },
  { value: "IT", label: "Ítalía" },
  { value: "CI", label: "Fílabeinsströndin" },
  { value: "JM", label: "Jamaíka" },
  { value: "JP", label: "Japan" },
  { value: "JE", label: "Jersey" },
  { value: "JO", label: "Jórdanía" },
  { value: "KZ", label: "Kasakstan" },
  { value: "KE", label: "Kenía" },
  { value: "KI", label: "Kíribatí" },
  { value: "KP", label: "Norður-Kórea" },
  { value: "KR", label: "Suður-Kórea" },
  { value: "KW", label: "Kúveit" },
  { value: "KG", label: "Kirgisistan" },
  { value: "LA", label: "Laos" },
  { value: "LV", label: "Lettland" },
  { value: "LB", label: "Líbanon" },
  { value: "LS", label: "Lesótó" },
  { value: "LR", label: "Líbería" },
  { value: "LY", label: "Líbýa" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Litháen" },
  { value: "LU", label: "Lúxemborg" },
  { value: "MO", label: "Makaó" },
  { value: "MG", label: "Madagaskar" },
  { value: "MW", label: "Malaví" },
  { value: "MY", label: "Malasía" },
  { value: "MV", label: "Maldíveyjar" },
  { value: "ML", label: "Malí" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshalleyjar" },
  { value: "MQ", label: "Martiník" },
  { value: "MR", label: "Máritanía" },
  { value: "MU", label: "Máritíus" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexíkó" },
  { value: "FM", label: "Míkrónesía" },
  { value: "MD", label: "Moldóva" },
  { value: "MC", label: "Mónakó" },
  { value: "MN", label: "Mongólía" },
  { value: "MS", label: "Montserrat" },
  { value: "MA", label: "Marokkó" },
  { value: "MZ", label: "Mósambík" },
  { value: "MM", label: "Mjanmar" },
  { value: "NA", label: "Namibía" },
  { value: "NR", label: "Nárú" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Holland" },
  { value: "AN", label: "Hollensku Antillur" },
  { value: "NC", label: "Nýja Kaledónía" },
  { value: "NZ", label: "Nýja Sjáland" },
  { value: "NI", label: "Níkaragva" },
  { value: "NE", label: "Níger" },
  { value: "NG", label: "Nígería" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Norfolkeyja" },
  { value: "MP", label: "Norður-Maríanaeyjar" },
  { value: "NO", label: "Noregur" },
  { value: "OM", label: "Óman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palá" },
  { value: "PS", label: "Palestína" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papúa Nýja-Gínea" },
  { value: "PY", label: "Paragvæ" },
  { value: "PE", label: "Perú" },
  { value: "PH", label: "Filippseyjar" },
  { value: "PN", label: "Pitcairn" },
  { value: "PL", label: "Pólland" },
  { value: "PT", label: "Portúgal" },
  { value: "PR", label: "Púertó Ríkó" },
  { value: "QA", label: "Katar" },
  { value: "RE", label: "Réunion" },
  { value: "RO", label: "Rúmenía" },
  { value: "RU", label: "Rússland" },
  { value: "RW", label: "Rúanda" },
  { value: "SH", label: "St. Helena" },
  { value: "KN", label: "St. Kitts og Nevis" },
  { value: "LC", label: "St. Lúsía" },
  { value: "PM", label: "St. Pierre og Miquelon" },
  { value: "VC", label: "St. Vincent og Grenadíneyjar" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marínó" },
  { value: "ST", label: "Saó Tóme og Prinsípe" },
  { value: "SA", label: "Sádi-Arabía" },
  { value: "SN", label: "Senegal" },
  { value: "SC", label: "Seychelleseyjar" },
  { value: "SL", label: "Síerra Leóne" },
  { value: "SG", label: "Singapúr" },
  { value: "SK", label: "Slóvakía" },
  { value: "SI", label: "Slóvenía" },
  { value: "SB", label: "Salómonseyjar" },
  { value: "SO", label: "Sómalía" },
  { value: "ZA", label: "Suður-Afríka" },
  { value: "GS", label: "Suður-Georgía og Suður-Sandvíkseyjar" },
  { value: "ES", label: "Spánn" },
  { value: "LK", label: "Srí Lanka" },
  { value: "SD", label: "Súdan" },
  { value: "SR", label: "Súrínam" },
  { value: "SJ", label: "Svalbarði og Jan Mayen" },
  { value: "SZ", label: "Svasíland" },
  { value: "SE", label: "Svíþjóð" },
  { value: "CH", label: "Sviss" },
  { value: "SY", label: "Sýrland" },
  { value: "TW", label: "Taívan" },
  { value: "TJ", label: "Tadsjikistan" },
  { value: "TZ", label: "Tansanía" },
  { value: "TH", label: "Taíland" },
  { value: "TG", label: "Tógó" },
  { value: "TK", label: "Tókelá" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trínidad og Tóbagó" },
  { value: "TN", label: "Túnis" },
  { value: "TR", label: "Tyrkland" },
  { value: "TM", label: "Túrkmenistan" },
  { value: "TC", label: "Turks- og Caicoseyjar" },
  { value: "TV", label: "Túvalú" },
  { value: "UG", label: "Úganda" },
  { value: "UA", label: "Úkraína" },
  { value: "AE", label: "Sameinuðu arabísku furstadæmin" },
  { value: "GB", label: "Bretland" },
  { value: "US", label: "Bandaríkin" },
  { value: "UY", label: "Úrúgvæ" },
  { value: "UZ", label: "Úsbekistan" },
  { value: "VU", label: "Vanúatú" },
  { value: "VA", label: "Vatíkanið" },
  { value: "VE", label: "Venesúela" },
  { value: "VN", label: "Víetnam" },
  { value: "WF", label: "Wallis- og Fútúnaeyjar" },
  { value: "EH", label: "Vestur-Sahara" },
  { value: "YE", label: "Jemen" },
  { value: "ZM", label: "Sambía" },
  { value: "ZW", label: "Simbabve" },
];
