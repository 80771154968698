// Package imports:
import React from 'react';

type SliderProps = React.InputHTMLAttributes<HTMLInputElement>;

interface IOwnProps {
    value: number,
    usePercentage?: boolean
}

export type SliderInputProps = SliderProps & IOwnProps;

const SliderInput: React.FC<SliderInputProps> = ({
    min,
    max,
    onChange,
    onInput,
    value,
    usePercentage
}) => {
    const maxValue = () => {
        if (max === undefined) return 1;
        if (usePercentage) return Number(max)/100;
        return Number(max);
    }
    return (
        <div className='KCL_slider-input'>
            <input
                type='range'
                min={min}
                max={max}
                value={value}
                step='0.01'
                onChange={onChange}
                style={{
                    background: `linear-gradient(to right, #1D3097 0%, #1D3097 ${value ? value/maxValue() : 0}%, #D4DEFF ${value ? value/maxValue() : 0}%, #D4DEFF 100%)`
                }}
                onInput={onInput}
            />
            <div className="end-values">
                <span className="low-value">{usePercentage ? `${min}%` : min}</span>
                <span className="high-value">{usePercentage ? `${max}%` : max}</span>
            </div>
        </div>
    );
}

export default SliderInput;