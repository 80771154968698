// Package imports:
import React, { useContext, useState } from 'react';
//@ts-ignore
import Columns from 'react-columns';
// Component imports:
import AdRotator from '../Ad/AdRotator';
import SharesTable from '../SharesTable/SharesTable';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import ShareVolumeByPriceChart from '../Charts/ShareVolumeByPriceChart/ShareVolumeByPriceChart';
import VaktarinnNews from '../VaktarinnNews/VaktarinnNews';
import Tabs from '../../ui-elements/Tabs/Tabs';
import CompanyReportsFiles from '../CompanyReportsFiles/CompanyReportsFiles';
import CompanyReportsAnalysis from '../CompanyReportsAnalysis/CompanyReportsAnalysis';
import LatestTrades from '../LatestTrades/LatestTrades';
import TradablesChanges from '../TradablesChanges/TradablesChanges';
import GEMMAQData from '../GEMMAQData/GEMMAQData';
import TradablesInformationShares from '../TradablesInformation/TradablesInformationShares';
import KeyFigures from '../KeyFigures/KeyFigures';
import CompanyReports from '../CompanyReports/CompanyReports';
import ShareIntradayChart from '../IntradayCharts/ShareIntradayChart/ShareIntradayChart';
import Shareholders from '../Shareholders/Shareholders';
import SharesNews from '../SharesNews/SharesNews';
import FinancialData from '../FinancialData/FinancialData';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import Avatar, { AvatarNames } from '../../ui-elements/Avatar/Avatar';
// Service imports:
import { getLogoSymbol } from '../../services/utils';
import { useFetchedApiLmdData } from '../../services/apiHooks';
import { MARKET_REFRESH_RATES } from '../../services/config';
// Context imports:
import { AccessTokenContext } from '../../contexts/AccessTokenContext';

const queries = [
    {
        columns: 2,
        query: 'min-width: 1024px'
    },
];

interface IProps {
    symbol: string
}

const SingleSharePage: React.FC<IProps> = ({
    symbol
}) => {
    const accessToken = useContext(AccessTokenContext);
    const [title, setTitle] = useState(symbol);
    const exchange: string = useFetchedApiLmdData(
        `/v1/static_data/v1/exchanges/*/shares/${symbol}`,
        accessToken,
        (body) => body[0]?.ExchangeSymbol ? body[0].ExchangeSymbol : 'ETF'
    );

    return (
        <div className='main KCL_single-share-page KCL_market-page'>
            <div className="main__inner-fluid">
                <div className="shell">
                    <HeadSection 
                        hasAds='market'
                        title={
                            <h1>
                                <Avatar
                                    size='lg'
                                    name={getLogoSymbol(symbol) as AvatarNames}
                                    color
                                    dropShadow
                                />
                                {title}
                            </h1>
                        }
                    />
                    <div>
                        <div className="grid-items">
                            <div className="grid-item">
                                <SharesTable
                                    symbol={symbol}
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['SHARESTABLE']}
                                    id="SingleSharePage_SharesTable"
                                />
                            </div>
                        </div>
                        <div className='grid-items grid-items--flex'>
                            <div className="grid-item grid-item--2of3 grid-item--table-full">
                                <DisplayBox
                                    className="KCL_chart chart-display-box"
                                    id="SingleSharePage_Charts"
                                >
                                    <Tabs
                                        tabs={[{
                                            id: 'history',
                                            label: 'Söguleg gögn',
                                            component: <ShareVolumeByPriceChart
                                                symbol={symbol}
                                                accessToken={accessToken}
                                                refreshRateMs={MARKET_REFRESH_RATES['SINGLESHARECHART']}
                                            />
                                        }, {
                                            id: 'intraday',
                                            label: 'Innan dags',
                                            component: <ShareIntradayChart
                                                symbol={symbol}
                                                accessToken={accessToken}
                                                refreshRateMs={MARKET_REFRESH_RATES['INTRADAYCHART']}
                                            />
                                        }]}
                                        redirectToTab={symbol === 'NOVA' && Date.now() < Date.parse('2022-06-22T00:00:00') ? 1 : undefined}
                                    />
                                </DisplayBox>
                            </div>
                            <div className='grid-item grid-item--1of3 grid-item--table-full'>
                                {(exchange === 'XICE' || exchange === 'FNIS')
                                    ? <CompanyReportsFiles
                                        symbol={symbol}
                                        accessToken={accessToken}
                                        refreshRateMs={MARKET_REFRESH_RATES['COMPANYREPORTSFILES']}
                                        id="SingleSharePage_CompanyReportsFiles"
                                    />
                                    : <AdRotator location="Market310x400" />
                                }
                            </div>
                        </div>
                        <AdRotator location="Market1018x360" />
                        <div className="grid-items grid-items--flex">
                            <div className="grid-item grid-item--1of3 grid-item--table-full">
                                <LatestTrades
                                    symbol={symbol}
                                    limit={5}
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['LATESTTRADES']}
                                    id="SingleSharePage_LatestTrades"
                                />
                            </div>
                            <div className="grid-item grid-item--1of3 grid-item--table-full">
                                <TradablesChanges
                                    symbol={symbol}
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['TRADABLESCHANGES']}
                                    id="SingleSharePage_Changes"
                                />
                            </div>
                            <div className="grid-item grid-item--1of3 grid-item--table-full">
                                {(exchange === 'XICE' || exchange === 'FNIS') &&
                                    <GEMMAQData
                                        symbol={symbol}
                                        accessToken={accessToken}
                                        refreshRateMs={MARKET_REFRESH_RATES['GEMMAQDATA']}
                                        id="SingleSharePage_GEMMAQ"
                                    />
                                }
                            </div>
                        </div>
                        <div className="main__cols grid-items">
                            <Columns className="custom-cols" queries={queries}>
                                <div className="grid-item">
                                    {(exchange === 'XICE') &&
                                        <DisplayBox
                                            title="Þróun lykiltalna"
                                            id="SingleSharePage_FinancialDataCharts"
                                        >
                                            <Tabs
                                                tabs={[{
                                                    id: 'quarterly',
                                                    label: 'Fjórðungsleg',
                                                    component: <FinancialData
                                                        symbol={symbol}
                                                        periods='quarters'
                                                        accessToken={accessToken}
                                                        refreshRateMs={MARKET_REFRESH_RATES['FINANCIALDATA']}
                                                    />
                                                }, {
                                                    id: 'annual',
                                                    label: 'Árleg',
                                                    component: <FinancialData
                                                        symbol={symbol}
                                                        periods='years'
                                                        accessToken={accessToken}
                                                        refreshRateMs={MARKET_REFRESH_RATES['FINANCIALDATA']}
                                                    />
                                                }]}
                                            />
                                        </DisplayBox>
                                    }
                                    {(exchange === 'FNIS') &&
                                        <DisplayBox
                                            title="Árleg þróun lykiltalna"
                                            id="SingleSharePage_FinancialDataCharts"
                                        >
                                            <FinancialData
                                                symbol={symbol}
                                                periods='years'
                                                accessToken={accessToken}
                                                refreshRateMs={MARKET_REFRESH_RATES['FINANCIALDATA']}
                                            />
                                        </DisplayBox>
                                    }
                                </div>
                                <div className="grid-item">
                                    {exchange === 'XICE' &&
                                        <KeyFigures
                                            symbol={symbol}
                                            accessToken={accessToken}
                                            refreshRateMs={MARKET_REFRESH_RATES['KEYFIGURES']}
                                            id="SingleSharePage_KeyFigures"
                                        />
                                    }
                                    {(exchange === 'XICE' || exchange === 'FNIS') &&
                                        <AdRotator location="Market310x400" />
                                    }
                                </div>
                                <div className="grid-item">
                                    {(exchange === 'XICE' || exchange === 'FNIS') &&
                                        <CompanyReports
                                            symbol={symbol}
                                            accessToken={accessToken}
                                            id="SingleSharePage_FinancialData"
                                        />
                                    }
                                </div>
                                <div className="grid-item">
                                    {(exchange === 'XICE' && symbol.toLowerCase() !== 'marel' && symbol.toLowerCase() !== 'arion') &&
                                        <Shareholders
                                            symbol={symbol}
                                            accessToken={accessToken}
                                            refreshRateMs={MARKET_REFRESH_RATES['SHAREHOLDERS']}
                                            id="SingleSharePage_Shareholders"
                                        />
                                    }
                                </div>
                                <div className="grid-item">
                                    {exchange === 'XICE' &&
                                        <CompanyReportsAnalysis
                                            symbol={symbol}
                                            limit={6}
                                            accessToken={accessToken}
                                            refreshRateMs={MARKET_REFRESH_RATES['COMPANYREPORTSANALYSIS']}
                                            id="SingleSharePage_CompanyReportsAnalysis"
                                        />
                                    }
                                </div>
                            </Columns>
                        </div>
                        <div className="grid-items grid-items--flex">
                            <div className="grid-item grid-item--1of2 grid-item--table-full">
                                {exchange !== 'ETF' &&
                                    <SharesNews
                                        symbol={symbol}
                                        limit={10}
                                        accessToken={accessToken}
                                        refreshRateMs={MARKET_REFRESH_RATES['SHARESNEWS']}
                                        id="SingleSharePage_News"
                                    />
                                }
                            </div>
                            <div className="grid-item grid-item--1of2 grid-item--table-full">
                                {typeof exchange === 'string' && exchange?.startsWith('X') &&
                                    <VaktarinnNews
                                        symbol={symbol}
                                        accessToken={accessToken}
                                        refreshRateMs={MARKET_REFRESH_RATES['VAKTARINNNEWS']}
                                        id="SingleSharePage_VaktarinnNews"
                                    />
                                }
                            </div>
                        </div>
                        <div className="grid-items ">
                            <div className="grid-item " id="SingleSharePage_TradablesInformation">
                                <TradablesInformationShares
                                    setShareName={(name) => setTitle(name as string)}
                                    symbol={symbol}
                                    accessToken={accessToken}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleSharePage;