// Package imports:
import React from 'react';
// Component imports:
import Button from '../../ui-elements/Button/Button';

interface TextBlockProps {
    title: string;
    content: string;
}
const TEXT_BLOCKS: TextBlockProps[] = [
    {
        title: 'Innslegnar lykiltölur',
        content: 'Gögn úr ársreikningum yfir 30.000 íslenskra fyrirtækja. Helstu lyklar úr rekstrar-, efnahagsreikningi og sjóðstreymi.'
    }, {
        title: 'Gögn úr opinberum skrám',
        content: 'Opinber gögn á lægra verði. Hlutafélaga-, Fasteigna-, Ökutækja-, og Þjóðskrá, hlutafélagaþátttaka, lögbirtingar og svo framvegis.'
    }, {
        title: 'Vöktun á fyrirtækjum',
        content: 'Ótakmarkaður fjöldi fyrirtækja í vöktun á nýjum niðurstöðum í fyrirtækjaskrá, ársreikningum og lögbirtingum.'
    }, {
        title: 'Verðmat fyrirtækja',
        content: 'Virði íslenskra fyrirtækja byggt á núvirtu áætluðu sjóðstreymi eða EBITDA.'
    }, {
        title: 'Fréttavakt',
        content: 'Allt að 10 orð í vöktun og tilkynning send þegar þau koma fyrir í fréttum.'
    }, {
        title: 'Samanburður fyrirtækja',
        content: 'Öflugt tól til samanburðar á lykiltölum fyrirtækja. Samanburður allt að 10 fyrirtækja í einu.'
    }
]

const AboutSubscription: React.FC = () => {
    return (
        <div className='main KCL_about-subscription-page'>
            <div className='section--blue'>
                <div className='mini_shell '>
                    <div className='header_content'>

                    <div className='img-container'>
                        <img
                            src='https://cdn.livemarketdata.com/KeldanImages/Um-askrift-title.svg'
                            alt='um-askrift-title'
                            className='um-askrift-title desktop'
                            width="668"
                            height="168"
                        />
                        <img
                            src='https://cdn.livemarketdata.com/KeldanImages/Um-askrift-title-mobile.svg'
                            alt='um-askrift-title-mobile'
                            className='um-askrift-title mobile'
                            width="668"
                            height="168"
                        />
                    </div>
                    <div className='img-container'>
                        <img
                            src='https://cdn.livemarketdata.com/KeldanImages/Um-askrift-image.svg'
                            alt='um-askrift-mynd'
                            className='um-aksrift-image'
                            width="693"
                            height="402" 
                        />
                    </div>
                    </div>
                </div>
            </div>
            <div className='mini_shell'>
                <div className='get-subscription'>
                    <span>
                        Skráðu þig eða þitt fyrirtæki í áskrift og opnaðu nýjar gagnagáttir.
                    </span>
                    <Button
                        buttonType='primary'
                        anchorProps={{href: 'https://keldan.is/askrift'}}
                    >
                        Koma í áskrift
                    </Button>
                </div>
                <div className='text_block-container'>
                    {TEXT_BLOCKS.map(({title, content}) => (
                        <div className='text_block' key={title}>
                            <h6 className='text_block-title'>{title}</h6>
                            <p className='paragraph-small'>{content}</p>
                        </div>))
                    }
                </div>
            </div>
        </div>
    );
}

export default AboutSubscription;