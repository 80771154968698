// Package imports:
import React from 'react';
// Components imports:
import Button from '../../ui-elements/Button/Button';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../ui-elements/Link/Link';
import BulletList from '../../ui-elements/Lists/BulletList';
// Service imports:
import { formatNumber } from '../../services/utils';
// Type imports:
import { ListsViewModel } from '../../types/ListsTypes';

export interface IOwnProps {
    model: ListsViewModel,
}

const Markhopalistar: React.FC<IOwnProps> = ({
    model
}) => {
    const handleListItems = (id: number) => {
        let d = model.categories.filter((x: any) => x.id === id);
        if (d) {
            return (
                <BulletList
                    items={d[0].lists.map((item) =>
                        <Link
                            key={item.id}
                            splitLines
                            linkSize='18'
                            url={`/Listar/Almennt/${item.id}`}
                        >
                            <span>{item.name}</span>
                        </Link>
                    )}
                />
            )
        }
    }

    const handle300BiggestMap = () => {
        return (
            <ul>
                {model.firstData?.slice(0, 10).map((item, index) => 
                    <li key={item['Kennitala']}>
                        <div className='list_item-wrapper'>
                            <div>
                                <span className='marker'>{index + 1}.</span>
                                <Link linkSize='16' url={`/Fyrirtaeki/Yfirlit/${item['Kennitala']}`}>
                                    {item['Nafn']}
                                </Link>
                            </div>
                            <div>
                                <span className='value'>{formatNumber(parseInt(item['Tekjur (M.Kr.)']),'-', 0)}</span><span className='currency'>m.kr.</span>
                            </div>
                        </div>
                    </li>
                )}
            </ul>
        )
    }

    const handleDisplayBoxData = (id: number) => {
        let d = model.top3Lists.filter(x => x.list.id === id);
        return (
            <ul>
                {d[0]?.data?.slice(0, 10).map((item, index) => 
                    <li key={item['Kennitala']}>
                        <div className='list_item-wrapper'>
                            <div>
                                <span className='marker'>{index + 1}.</span>
                                <Link linkSize='16' url={`/Fyrirtaeki/Yfirlit/${item['Kennitala']}`}>
                                    {item['Nafn']}
                                </Link>
                            </div>
                            <div>
                                <span className='value'>{formatNumber(parseInt(item['Tekjur (M.Kr.)']),'-', 0)}</span><span className='currency'>m.kr.</span>
                            </div>
                        </div>
                    </li>
                )}
            </ul>
        )
    }

    return (
        <div className="main KCL_markhopalistar">
            <div className='section__body'>
                <div className='shell'>
                    {/* HEAD SECTION */}
                    <div className='row'>
                        <div className='col-sm-12'>
                            <HeadSection
                                title='Markhópalistar'
                                summary={
                                    <p>Keldan tekur saman lista yfir stærstu fyrirtækin sem skilgreind eru eftir stærðum, atvinnugreinum og sveitarfélögum.</p>
                                }
                                actions={
                                    <Button anchorProps={{ href: '/Listar/Serpontun'}}>Sérpanta lista</Button>
                                }
                                textAlign='center'
                            />
                        </div>
                    </div>
                </div>
                <div className='section--gray'>
                    <div className='shell'>
                        <h2 className='popularListTitle'>Vinsælir listar á Keldunni</h2>
                        <div className='row'>
                            <div className='col-lg-4 col-sm-12'>
                                <DisplayBox
                                    className="card"
                                    title="300 Stærstu fyrirtækin"
                                    displayBoxSize="lg"
                                    asideComponent={
                                        <Button
                                            buttonType='secondary'
                                            size='sm'
                                            anchorProps={{href: '/Listar/Almennt/2'}}
                                        >
                                                Skoða
                                        </Button>
                                    }
                                    >
                                        {handle300BiggestMap()}
                                </DisplayBox>
                            </div>
                            <div className='col-lg-4 col-sm-12'>
                                <DisplayBox
                                    className="card"
                                    title="Stærstu fyrirtækin í smásölu"
                                    displayBoxSize="lg"
                                    asideComponent={
                                        <Button
                                            buttonType='secondary'
                                            size='sm'
                                            anchorProps={{href: '/Listar/Almennt/3'}}
                                        >
                                            Skoða
                                        </Button>
                                    }
                                >
                                    {handleDisplayBoxData(3)}
                                </DisplayBox>
                            </div>
                            <div className='col-lg-4 col-sm-12'>
                                <DisplayBox
                                    className="card"
                                    title="Stærstu fyrirtækin í ferðaþjónustu"
                                    displayBoxSize="lg"
                                    asideComponent={
                                        <Button
                                            buttonType='secondary'
                                            size='sm'
                                            anchorProps={{href: '/Listar/Almennt/5'}}
                                        >
                                            Skoða
                                        </Button>
                                    }
                                >
                                    {handleDisplayBoxData(5)}
                                </DisplayBox>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section'> 
                    <div className="shell">
                        <h3>Stærstu fyrirtækin eftir atvinnugrein</h3>
                        <article className="article-overview">
                            <div className="article__body">
                                <div className="article__section">
                                    {handleListItems(3)}
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="shell">
                        <h3>Stærstu fyrirtækin eftir sveitafélögum</h3>
                        <article className="article-overview">
                            <div className="article__body">
                                <div className="article__section">
                                    {handleListItems(2)}
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="shell">
                        <h3>Stærstu fyrirtækin eftir tekjum</h3>
                        <article className="article-overview">
                            <div className="article__body">
                                <div className="article__section">
                                    {handleListItems(1)}
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Markhopalistar;
