// Package imports:
import React, { useState, useMemo, useContext, useEffect, useRef } from 'react'
import cx from 'classnames'
// Components imports:
import Icon from '../CustomIcon/CustomIcon';
import SearchResponse from './SearchResponse';
// import SearchDropdown from './SearchDropdown';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { getSearchTermsFromSearch, isElementSubChild } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { IApiLmdAutocomplete, IApiLmdAutocompleteItem, ICompanySearchResults, ISearchItem } from '../../../types/SearchTypes';
// Context imports:
import { AccessTokenContext } from '../../../contexts/AccessTokenContext';

const MINIMUM_SEARCH_LENGTH = 3;

interface IOwnProps {
    focusSearchInputField?: () => void
}

const Search: React.FC<IOwnProps> = ({
    focusSearchInputField
}) => {
    // Context variables:
    const accessToken = useContext(AccessTokenContext);
    // State variables:
    const [isSearchDropdownOpen/*, setIsSearchDropdownOpen*/] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [search, setSearch] = useState('');
    // Autocomplete request variables:
    const [autocompleteData] = useApiLmdData<IApiLmdAutocomplete>(`/v1/static_data/v1/autocomplete/keldan/`, accessToken);
    // Company search: maps searchTerm to list of companies.
    const [companySearchTermToSearchItemsMap, setCompanySearchTermToSearchItemsMap] = useState<{
        [searchTerm in string]?: IApiLmdAutocompleteItem[] | Error
    }>({});
    // Refs:
    const searchFieldInputRef = useRef<HTMLInputElement | null>(null);
    const searchResponseChildRef = useRef<HTMLDivElement | null>(null);
    const timeoutFunctionIdRef = useRef<number | null>(null);
    // Memo variables:
    const isSearchResponseOpen = useMemo(() => {
        return isFocused && search.length >= MINIMUM_SEARCH_LENGTH
    }, [isFocused, search]);

    const searchData: ISearchItem[] = useMemo(() => {
        // Check search term is long enough to use.
        if (search.length < MINIMUM_SEARCH_LENGTH) return [];

        // Split search string into multiple search terms.
        const searchTerms = getSearchTermsFromSearch(search);

        // This object contains all categories and all results.
        // It will be filtered down to show only the ones that contain a search term.
        const allSearchItems: ISearchItem[] = [{
            title: 'Hlutabréf',
            items: autocompleteData.data?.shares ?? autocompleteData.error
        }, {
            title: 'Skuldabréf',
            items: autocompleteData.data?.bonds ?? autocompleteData.error
        }, {
            title: 'Gjaldmiðlar',
            items: autocompleteData.data?.currencies ?? autocompleteData.error
        }, {
            title: 'Sjóðir',
            items: autocompleteData.data?.funds ?? autocompleteData.error
        }, {
            title: 'Vísitölur',
            items: autocompleteData.data?.indexes ?? autocompleteData.error
        }, {
            title: 'Skuldabréfavísitölur',
            items: autocompleteData.data?.bond_indexes ?? autocompleteData.error
        }];

        const searchItemsFilteredBySearchTerms: ISearchItem[] = allSearchItems.map(({title, items}) => {
            // Cant filter items if there aren't any items.
            if (items === null || items instanceof Error) return { title, items };

            // Filter items by search term.
            const filteredItems = items.filter(item => {
                // Return true if:
                // - symbol contains all search terms.
                // OR name contains all search terms.
                let symbolContainsAllSearchTerms = true;
                let nameContainsAllSearchTerms = true;
                for (let searchTerm of searchTerms) {
                    if (item.Symbol === null || !item.Symbol.toLowerCase().includes(searchTerm.toLowerCase()))
                        symbolContainsAllSearchTerms = false;
                    if (item.Name === null || !item.Name.toLowerCase().includes(searchTerm.toLowerCase()))
                        nameContainsAllSearchTerms = false;
                }
                return (symbolContainsAllSearchTerms || nameContainsAllSearchTerms);
            });

            return {
                title,
                items: filteredItems
            }
        });

        // Remove all entries that are either null or length = 0.
        const searchItemsToDisplay = searchItemsFilteredBySearchTerms.filter(searchItem => {
            // Display errors
            if (searchItem.items instanceof Error) return true;
            // Don't display null or 0 items.
            if (searchItem.items === null || searchItem.items.length  === 0) return false;
            return true;
        })

        // Get data that has to be specifially requested (company data).
        // If data not present, request it specifically.
        const companyData = companySearchTermToSearchItemsMap[search];
        if (companyData !== undefined) {
            searchItemsToDisplay.push({
                title: 'Fyrirtæki',
                items: companyData
            });
        }
        // Combine all data and return.
        return searchItemsToDisplay;
    }, [ search, autocompleteData, companySearchTermToSearchItemsMap ]);

    // Helper delay function.
    const withDelay = (func: () => void) => {
        const timeoutFunctionId = timeoutFunctionIdRef.current;
        if (timeoutFunctionId) window.clearTimeout(timeoutFunctionId);

        timeoutFunctionIdRef.current = window.setTimeout(func, 400);
    }

    // Keep parent element up-to-date if input is focused or not.
    useEffect(() => {
        if (isFocused && focusSearchInputField) focusSearchInputField();
    }, [ isFocused ])

    // Correctly set focus, since the user can still click inside header without removing focus.
    useEffect(() => {
        const handleClickOutsideOfSearch = (e: MouseEvent) => {
            // Only unfocus input if:
            // - click was outside of KCL_search-response.
            // - AND click was outside of input field-control.
            const { target } = e;
            const searchInputElement = searchFieldInputRef.current;
            const searchResponseElement = searchResponseChildRef.current;
            if (target instanceof Element && searchInputElement && searchResponseElement) {
                // If user clicks on element, which is not in header, then turn off focus.
                if (!isElementSubChild(target, searchInputElement) && !isElementSubChild(target, searchResponseElement)) {
                    setIsFocused(false);
                }
            } else {
                setIsFocused(false)
            }
        }
        document.addEventListener('click', handleClickOutsideOfSearch);
        return () => document.removeEventListener('click', handleClickOutsideOfSearch);
    }, [])

    // Companies, unlike other autocomplete items, must be explicitly searched for.
    // This effect takes care of that.
    useEffect(() => {
        const companySearch = async (term: string) => {
            if (search.length < MINIMUM_SEARCH_LENGTH) return;
            try {
                const url = `${GET_KELDAN_API_URL()}/Company/SearchRskConnector?searchTerm=${encodeURIComponent(term.trim())}`;
                const response = await fetch(url);
                if (response.ok) {
                    const body: ICompanySearchResults = await response.json();
                    const companies: IApiLmdAutocompleteItem[] = body.results?.map(({ssn, name}) => ({
                        Symbol: ssn,
                        Name: name,
                        Tickertype: 'company'
                    })) ?? [];
                    setCompanySearchTermToSearchItemsMap({
                        ...companySearchTermToSearchItemsMap,
                        [term]: companies
                    });
                } else {
                    setCompanySearchTermToSearchItemsMap({
                        ...companySearchTermToSearchItemsMap,
                        [term]: new Error(ErrorMessages.RequestFailed)
                    });
                }
            } catch (e) {
                setCompanySearchTermToSearchItemsMap({
                    ...companySearchTermToSearchItemsMap,
                    [term]: new Error(ErrorMessages.NetworkError)
                });
            }
        }
        // Check search term is long enough to use.
        withDelay(() => companySearch(search));
    }, [search]);

    return (
        <div
            className={cx('KCL_search', {
                'is-search-response-open': isSearchResponseOpen,
                'is-dropdown-open': isSearchDropdownOpen
            })}
            id="Search_Header"
        >
            {/* Send user to Search Response Page */}
            <form action="/Leit?" autoComplete='off'>
                <div className="field-control">
                    <button
                        type='submit'
                        className="search__submit"
                        onClick={e => {
                            if (search.length === 0) {
                                e.preventDefault();
                                searchFieldInputRef.current?.focus();
                            }
                        }}
                        title="Leita"
                    >
                        <span><Icon type="search" /></span>
                    </button>
                    <input
                        type="text"
                        name="search"
                        ref={searchFieldInputRef}
                        value={search}
                        placeholder="Leitaðu eftir fyrirtækjum og verðbréfum..."
                        className="search-field"
                        onFocus={() => setIsFocused(true)}
                        onChange={e => setSearch(e.target.value)}
                    />
                    {/* Bleika slaufan */}
                    {new Date().getMonth() === 9 && <img alt='pink-ribbon' className="pink-ribbon-image" src="https://cdn.livemarketdata.com/Images/Pink_Ribbon.svg" />}
                </div>
                {/* <SearchDropdown
                    isSearchDropdownOpen={isSearchDropdownOpen}
                    setIsSearchDropdownOpen={setIsSearchDropdownOpen}
                    handleClick={handleClick}
                /> */}
                <SearchResponse
                    searchFieldInputRef={searchFieldInputRef}
                    searchData={searchData}
                    isOpen={isSearchResponseOpen}
                    search={search}
                    ref={searchResponseChildRef}
                />
            </form>
        </div>
    )
}

export default Search;
