// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import ViewVehiclesReportComp from '../../components/Individual/IndividualAssets/ViewVehiclesReport';

const ViewVehiclesReport: React.FC<{ id: string }> = ({
    id
}) => {
    return (
        <Entrypoint>
            <ViewVehiclesReportComp id={id} />
        </Entrypoint>
    );
}

export default ViewVehiclesReport;