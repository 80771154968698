// Package imports:
import React from 'react';
// Component imports:
import Table from '../../../ui-elements/Table/Table';
import Button from '../../../ui-elements/Button/Button';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { useBuyingProcessUrl } from '../../../services/buyhook';
// Type imports:
import { IPropertyDocument } from '../../../types/PropertyTypes';
import { OwnedReport } from '../../../types/KeldanTypes';

interface IProps {
    doclist: IPropertyDocument[],
    owned?: OwnedReport[],
    fastanr: string
}

const DocumentListTable: React.FC<IProps> = ({
    doclist,
    owned,
    fastanr
}) => {
    const { tryPurchaseItem, isItemLoading, ModalsAndToasts } = useBuyingProcessUrl(
        (doc: IPropertyDocument, modalReference: string) => {
            const url = `${GET_KELDAN_API_URL()}/Pdf/Thinglyst-Skjal-Kaupa?docnr=${doc.document_number.trimEnd()}&onr=${doc.office_number}&fastanr=${fastanr}&reference=${modalReference}`;
            return url;
        },
        () => {},
        'fasteignaskrá'
    );

    const getOwned = (doc: IPropertyDocument) => {
        const ownedDoc = owned?.find((ownedDoc) => ownedDoc.tokens.includes(doc.document_number.trimEnd()) && ownedDoc.tokens.includes(doc.office_number));
        return ownedDoc;
    }
    
    return (
        <div>
            <Table
                tableSize="lg"
                data={doclist || null}
                columns={[{
                    title: 'Númer skjals',
                    renderCell: ({document_number}) => document_number,
                    textAlign: 'left'
                }, {
                    title: 'Embættisnúmer',
                    renderCell: ({office_number}) => office_number,
                    textAlign: 'left'
                }, {
                    title: 'Útgáfudagur',
                    renderCell: ({published}) => published,
                    textAlign: 'right'
                }, {
                    title: 'Móttökudagur',
                    renderCell: ({received}) => received,
                    textAlign: 'right'
                }, {
                    title: 'Tegund skjals',
                    renderCell: ({type_name}) => type_name,
                    textAlign: 'left'
                }, {
                    title: 'Skjal',
                    renderCell: (doc) => {
                        const owned = getOwned(doc);
                        return owned
                            ? <Button
                                    buttonType='secondary'
                                    size='sm'
                                    anchorProps={{href: `/Pdf/Thinglyst-Skjal-Skoda/${owned.balls}`}}
                                >
                                    Skoða
                                </Button>
                            : <Button
                                buttonType="buy"
                                size='sm'
                                showLoader={isItemLoading(doc)}
                                onClick={() => tryPurchaseItem(doc, 'confirm-modal-with-reference')}
                            >
                                Kaupa
                            </Button>
                    }
                }]}
            />
            <ModalsAndToasts />
        </div>
    );
}

export default DocumentListTable;