// Package imports:
import React from 'react';
// Component imports:
import VehicleReportBody from '../VehicleReportBody';
// Type imports:
import { IEventReport } from '../../../types/KeldanTypes';
import { VehicleSearchResult } from '../../../types/VehicleTypes';
// Static data imports:
import EXAMPLE_DATA from '../../../assets/staticData/ExampleReports/Vehicle/VehicleReport.json';

const VehicleProfileExample: React.FC = () => {
    return (
        <VehicleReportBody data={EXAMPLE_DATA as IEventReport<VehicleSearchResult>} isExample />
    );
}

export default VehicleProfileExample;