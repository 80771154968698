// Package imports:
import React, { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { NumberFormatValues } from 'react-number-format';
// Component imports:
import CompanyValuationTable from './CompanyValuationTable';
import Alert from '../../../ui-elements/Alert/Alert';
import Table from '../../../ui-elements/Table/Table';
import Loading from '../../../ui-elements/Loading/Loading';
import Tooltip from '../../../ui-elements/Tooltip/Tooltip';
import BulletList from '../../../ui-elements/Lists/BulletList';
import SliderVariableUnit from '../../../ui-elements/SliderInput/SliderVariableUnit';
// Service imports:
import { formatNumber, showValuation } from '../../../services/utils';
import { useFetchedApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { ICompanyValuation, ICompanyViewModel } from '../../../types/CompanyTypes';

const MIN_WACC = 0.1;
const MAX_WACC = 20;
const MIN_GROWTH = 0.1;
const MAX_GROWTH = 10;

interface IOwnProps {
    changeTab?(tab: number): void
}

const FcfValuation: React.FC<ICompanyViewModel & IOwnProps> = ({
    id,
    accessToken,
    changeTab = () => {}
}) => {
    const [wacc, setWacc] = useState<number | null>(null);
    const [growth, setGrowth] = useState<number | null>(null);
    const [usingUserWacc, setUsingUserWacc] = useState<boolean | null>(null);
    const [usingUserGrowth, setUsingUserGrowth] = useState<boolean | null>(null);
    const usingUserInput = useMemo(() => usingUserWacc || usingUserGrowth, [usingUserWacc, usingUserGrowth]);

    const [invalidWacc, setInvalidWacc] = useState(false);
    const [invalidGrowth, setInvalidGrowth] = useState(false);

    const [params, setParams] = useState<string | null>(null);

    const [formulaTablesOpen, setFormulaTablesOpen] = useState(false);

    const [initialWacc, setInitialWacc] = useState<number | null>(null);
    const [initialGrowth, setInitialGrowth] = useState<number | null>(null);
    
    const [waccChanged, setWaccChanged] = useState(false);
    const [growthChanged, setGrowthChanged] = useState(false);

    useEffect(() => {
        if (wacc !== null && growth !== null) {
            setInvalidGrowth(false);
            setInvalidWacc(wacc <= growth);
        }

        if (usingUserWacc !== null) {
            const waccChangeTimer = setTimeout(() => {
                setWaccChanged(true);

                const waccTimer = setTimeout(() => {
                    setWaccChanged(false);
                }, 2000);

                return () => clearTimeout(waccTimer);
            }, 200);
            return () => clearTimeout(waccChangeTimer);
        }
    }, [wacc]);
    
    useEffect(() => {
        if (wacc !== null && growth !== null) {
            setInvalidWacc(false);
            setInvalidGrowth(growth >= wacc);
        }

        if (usingUserGrowth !== null) {
            const growthChangeTimer = setTimeout(() => {
                setGrowthChanged(true);

                const growthTimer = setTimeout(() => {
                    setGrowthChanged(false);
                }, 2000);

                return () => clearTimeout(growthTimer);
            }, 200);
            return () => clearTimeout(growthChangeTimer);
        }
    }, [growth]);

    const timeoutFunctionIdRef = useRef<number | null>(null);
    // Helper delay function.
    const withDelay = (func: () => void) => {
        const timeoutFunctionId = timeoutFunctionIdRef.current;
        if (timeoutFunctionId) window.clearTimeout(timeoutFunctionId);

        timeoutFunctionIdRef.current = window.setTimeout(func, 400);
    }

    useEffect(() => {
        if (!usingUserInput) {
            if (params || !data || data instanceof Error) {
                setParams('');
            }
        }
    }, [usingUserInput]);
    
    useEffect(() => {
        if (wacc !== null && growth !== null && wacc <= growth) return;
        let p = '';
        if (!usingUserInput) {
            withDelay(() => setParams(p));
            return;
        }
        if (usingUserWacc && wacc) {
            if (usingUserGrowth && growth) {
                p = `?wacc=${wacc/100}&growth=${growth/100}`;
            } else {
                p = `?wacc=${wacc/100}`;
            }
        } else if (usingUserGrowth && growth) {
            p = `?growth=${growth/100}`;
        }
        withDelay(() => setParams(p));
    }, [wacc, growth, usingUserWacc, usingUserGrowth]);
    
    const data = useFetchedApiLmdData<ICompanyValuation>(
        `/v1/market_data/v1/company_valuation/${id}/valuation/v2${params}`,
        accessToken
    );

    const shouldShowValuation = useMemo(() => {
        if (data instanceof Error) return 'error';
        if (data === null) return 'loading';
        if (usingUserInput) return 'show';
        return showValuation(data);
    }, [data])

    useEffect(() => {
        if (data === null || data instanceof Error) return;
        if (data.wacc !== null) {
            setWacc(data.wacc*100);
            if (initialWacc === null) setInitialWacc(data.wacc*100);
        }
        if (data.gdp_growth_rate !== null) {
            setGrowth(data.gdp_growth_rate*100);
            if (initialGrowth === null) setInitialGrowth(data.gdp_growth_rate*100);
        }
    }, [data]);

    useEffect(() => {
        if (!usingUserWacc) setWacc(initialWacc);
        if (!usingUserGrowth) setGrowth(initialGrowth);
    }, [usingUserWacc, usingUserGrowth])

    useEffect(() => {
        if (shouldShowValuation !== 'show' && shouldShowValuation !== 'loading') {
            changeTab(1);
        }
    }, [shouldShowValuation]);

    if (data === null) return <Loading />;
    if (data instanceof Error) {
        return <Alert
            type='alert'
            headText='Ekki tókst að verðmeta fyrirtæki'
            text={<>
                <p>Keldan styðst við ýmsar forsendur við gerð verðmatsins.<br />Eftirfarandi ástæður gætu valdið því að ekki tekst að verðmeta fyrirtæki:</p>
                <BulletList
                    items={[
                        'Ársreikningar eru of gamlir eða of fáir.',
                        'Gögn vantar við útreikning ávöxtunarkröfu.',
                        'Gögn vantar við útreikning frjáls sjóðstreymis.',
                        'Atvinnugrein fyrirtækis bendir til þess að verðmatsaðferð eigi ekki við.',
                        'Niðurstöður verðmats eru utan þess sem eðlilegt gæti talist.',
                        'Ársreikningar eru í öðrum gjaldmiðli en ISK.'
                    ]}
                />
            </>}
        />
    }
    if (shouldShowValuation === 'negative-fcf') {
        return <div className='grid-items'>
            <div className='grid-item'>
                <Alert
                    type='info'
                    headText='Fyrirtæki er með neikvætt frjálst sjóðstreymi'
                    text='Ekki er unnt að birta verðmat fyrir fyrirtæki með neikvætt frjálst sjóðstreymi en sjá má útreikninga hér fyrir neðan.'
                />
            </div>
            <div className='grid-item valuation-calculations'>
                <Table
                    data={[{
                        name: 'EBIT',
                        value: data.ebit
                    }, {
                        name: 'Skattar',
                        value: data.taxes
                    }, {
                        name: 'Afskriftir',
                        value: data.depreciation !== null ? -1*data.depreciation : null
                    }, {
                        name: 'Fjárfesting',
                        value: data.investment
                    }, {
                        name: 'Breyting veltufjármuna',
                        value: data.change_in_nwc
                    }]}
                    columns={[{
                        title: 'Skýring',
                        renderCell: ({name}) => name,
                        textAlign: 'left'
                    }, {
                        title: 'Upphæð',
                        renderCell: ({value}) => formatNumber(value, '-', 0, ' ISK'),
                        textAlign: 'right'
                    }]}
                    tableSize='lg'
                />
                <div className='final-value-row'>
                    <span className='text'>Samtals frjálst sjóðstreymi</span>
                    <span className='value'>{formatNumber(data.free_cashflow, '-', 0, ' ISK')}</span>
                </div>
            </div>
        </div>
    }
    if (shouldShowValuation !== 'show') {
        return <Alert
            type='alert'
            headText='Ekki tókst að verðmeta fyrirtæki'
            text={<>
                <p>Keldan styðst við ýmsar forsendur við gerð verðmatsins.<br />Eftirfarandi ástæður gætu valdið því að ekki tekst að verðmeta fyrirtæki:</p>
                <BulletList
                    items={[
                        'Ársreikningar eru of gamlir eða of fáir.',
                        'Gögn vantar við útreikning ávöxtunarkröfu.',
                        'Gögn vantar við útreikning frjáls sjóðstreymis.',
                        'Atvinnugrein fyrirtækis bendir til þess að verðmatsaðferð eigi ekki við.',
                        'Niðurstöður verðmats eru utan þess sem eðlilegt gæti talist.',
                        'Ársreikningar eru í öðrum gjaldmiðli en ISK.'
                    ]}
                />
            </>}
        />
    }
    
    return (
        <div className='grid-items'>
            <div className='grid-item summary-box'>
                <div className='description'>
                    <h2>Niðurstöður verðmats fyrir <span className='bold-name'>{data.company_name}</span></h2>
                    <p className='paragraph-small'>Hér koma niðurstöður verðmatsins sem byggðar eru á frjálsu sjóðstreymi.</p>
                    <p className='paragraph-small--bold'>Frjálst sjóðstreymi er reiknað út frá nýjasta ársreikningi og geta þeir útreikningar því verið rangir eða gefið villandi mynd af fyrirtækinu, skoðið því með það í huga.</p>
                </div>
                <div className='grey-box'>
                    <div className={cx('value', { 'highlighted': waccChanged || growthChanged })}>
                        <span className='value-number'>{formatNumber(data.equity_valuation, '-', 0)}</span>
                        <span className='value-unit'>ISK</span>
                    </div>
                    <div className='title'>Samtals virði eigin fjár</div>
                </div>
            </div>
            <div className='grid-item model-box'>
                <h3>Verðmatsmódel</h3>
                <p className='extra-info'>Í þessu einfaldaða verðmatsmódeli er hægt að breyta gildum fyrir WACC og vöxt fyrirtækisins með rennistikunum hér að neðan. Aðrar breytur módelsins eru fastar.</p>
                <div className='variables'>
                    <div className='variables--section'>
                        <h6>
                            Veginn fjármagnskostnaður (WACC)
                            <Tooltip
                                text={<FontAwesomeIcon icon={faInfoCircle} className='icon-fa' />}
                                tooltip={<p>
                                    Fyrirtæki fjármagna fjárfestingar með eigin fé eða lánum en kostnaður þessara fjármögnunarleiða
                                    er mismunandi. WACC (Weighted Average Cost of Capital) tekur saman eiginfjárkröfu og skuldakröfu
                                    félags í réttum hlutföllum. WACC er svo notað til þess að núvirða framtíðar greiðsluflæði félags.
                                </p>}
                                noUnderline
                            />
                        </h6>
                        <p className='variables-description paragraph-small'>
                            Meðalkostnaður fjármagnsins sem fyrirtækið notar til að standa undir rekstri.
                        </p>
                        <SliderVariableUnit
                            sliderProps={{
                                min: '0',
                                max: '20',
                                value: wacc ?? 0,
                                onChange: (e) => {
                                    setWacc(parseFloat(e.currentTarget.value));
                                },
                                onInput: () => setUsingUserWacc(true)
                            }}
                            inputProps={{
                                value: wacc,
                                onValueChange: (values: NumberFormatValues) => {
                                    if (values.floatValue) {
                                        setWacc(values.floatValue);
                                    }
                                },
                                isAllowed: (values) => values.floatValue !== undefined && values.floatValue >= MIN_WACC && values.floatValue <= MAX_WACC
                            }}
                            onInput={() => setUsingUserWacc(true)}
                            reset={() => setUsingUserWacc(false)}
                            usePercentage
                        />
                        <div className={cx('invalid-params-message', {'show': invalidWacc})}>
                            WACC getur ekki verið lægri en vöxtur
                        </div>
                    </div>
                    <div className='variables--section'>
                        <h6>
                            Vöxtur
                            <Tooltip
                                text={<FontAwesomeIcon icon={faInfoCircle} className='icon-fa' />}
                                tooltip={<p>
                                    Vöxtur, eilífðarvöxtur eða lokavöxtur er sá vöxtur sem áætlaður er á hagnað eða peningaflæði
                                    fyrirtækis út í eilífðina. Við áætlun vaxtar þarf að finna jafnvægi milli skammtíma- og
                                    langtímavaxtar því verðmatsmódelið er afar næmt á breytingar á vextinum.
                                </p>}
                                noUnderline
                            />
                        </h6>
                        <p className='variables-description paragraph-small'>
                            Meðalhagvöxtur á Íslandi síðan 1995.
                        </p>
                        <SliderVariableUnit
                            sliderProps={{
                                min: '0',
                                max: '10',
                                value: growth ?? 0,
                                onChange: (e) => {
                                    setGrowth(parseFloat(e.currentTarget.value));
                                },
                                onInput: () => setUsingUserGrowth(true)
                            }}
                            inputProps={{
                                value: growth,
                                onValueChange: (values: NumberFormatValues) => {
                                    if (values.floatValue) {
                                        setGrowth(values.floatValue);
                                    }
                                },
                                isAllowed: (values) => values.floatValue !== undefined && values.floatValue >= MIN_GROWTH && values.floatValue <= MAX_GROWTH
                            }}
                            onInput={() => setUsingUserGrowth(true)}
                            reset={() => setUsingUserGrowth(false)}
                            usePercentage
                        />
                        <div className={cx('invalid-params-message', {'show': invalidGrowth})}>
                            Vöxtur getur ekki verið hærri en WACC.
                        </div>
                    </div>
                </div>
                <div className='calculations'>
                    <div className='calculations--line'>
                        <CompanyValuationTable
                            data={data}
                            type='return-on-equity'
                            tableOpen={formulaTablesOpen}
                            setTableOpen={setFormulaTablesOpen}
                        />
                        <CompanyValuationTable
                            data={data}
                            type='return-on-debt'
                            tableOpen={formulaTablesOpen}
                            setTableOpen={setFormulaTablesOpen}
                        />
                        <CompanyValuationTable
                            data={data}
                            type='wacc'
                            tableOpen={formulaTablesOpen}
                            setTableOpen={setFormulaTablesOpen}
                            waccHighlight={waccChanged}
                            growthHighlight={growthChanged}
                        />
                    </div>
                    <div className='calculations--line'>
                        <CompanyValuationTable
                            data={data}
                            type='fcf'
                            tableOpen={formulaTablesOpen}
                            setTableOpen={setFormulaTablesOpen}
                        />
                        <CompanyValuationTable
                            data={data}
                            type='valuation'
                            tableOpen={formulaTablesOpen}
                            setTableOpen={setFormulaTablesOpen}
                            waccHighlight={waccChanged}
                            growthHighlight={growthChanged}
                        />
                    </div>
                </div>
            </div>
            <div className='grid-item summary-box final-result-box'>
                <div className='description'>
                    <h3>Virði eigin fjár hjá <span className='bold-name'>{data.company_name}</span></h3>
                    <p className='paragraph-small'>
                        Lokaútreikningur á virði eigin fjár er reiknaður á eftirfarandi hátt eins og lýst er hér við niðurstöðu.
                    </p>
                    {window.matchMedia('(min-width: 1024px)').matches &&
                        <div className={cx('details', { 'show': formulaTablesOpen })}>
                            <p className='paragraph-small'>
                                Verðmatið byggir á núvirtu áætluðu sjóðstreymi til eilífðar og er á nafngrunni, í íslenskum krónum. 
                                Stuðst er við reiknuð gildi frá Dr. Aswath Damodaran við útreikning ávöxtunarkröfu. 
                                Athugið að engu sérstöku álagi hefur verið bætt við ávöxtunarkröfu vegna seljanleika, rekstraróvissu eða annarra þátta. 
                                Ekki er tekið tillit til erlendra gjaldmiðla við útreikning ávöxtunarkrafna og WACC.
                            </p>
                            <p className='paragraph-small'>
                                Virði fyrirtækisins má lýsa með eftirfarandi jöfnu:
                            </p>
                            <img src="https://cdn.livemarketdata.com/KeldanImages/CompanyValuation.png" alt="Virði fyrirtækis" width="640" height="115" />
                        </div>
                    }
                </div>
                <div className='results'>
                    <CompanyValuationTable
                        data={data}
                        type='equity-valuation-large'
                        tableOpen={formulaTablesOpen}
                        setTableOpen={setFormulaTablesOpen}
                        waccHighlight={waccChanged}
                        growthHighlight={growthChanged}
                    />
                </div>
                {window.matchMedia('(max-width: 1023px)').matches &&
                    <div className={cx('description details', { 'show': formulaTablesOpen })}>
                        <p>Verðmatið byggir á núvirtu áætluðu sjóðstreymi til eilífðar og er á nafngrunni, í íslenskum krónum. Stuðst er við reiknuð gildi frá Dr. Aswath Damodaran við útreikning ávöxtunarkröfu. Athugið að engu sérstöku álagi hefur verið bætt við ávöxtunarkröfu vegna seljanleika, rekstraróvissu eða annarra þátta. Ekki er tekið tillit til erlendra gjaldmiðla við útreikning ávöxtunarkrafna og WACC.</p>
                        <p>Virði fyrirtækisins má lýsa með eftirfarandi jöfnu:</p>
                        {window.matchMedia('(max-width: 767px)').matches
                            ? <img src="https://cdn.livemarketdata.com/KeldanImages/CompanyValuationMobile.png" alt="Virði fyrirtækis" width="345" height="217" />
                            : <img src="https://cdn.livemarketdata.com/KeldanImages/CompanyValuation.png" alt="Virði fyrirtækis" width="640" height="115" />
                        }
                    </div>
                }
            </div>
        </div>
    );
}

export default FcfValuation;