// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import CompanyProfileComp from '../../components/Company/Profile/Profile';
// Type imports:
import { ICompanyViewModel } from '../../types/CompanyTypes';


const CompanyProfile: React.FC<ICompanyViewModel> = ({
    id,
    isAuthenticated,
    isSubscriber
}) => {
    return (
        <Entrypoint>
            <CompanyProfileComp
                id={id}
                isAuthenticated={isAuthenticated}
                isSubscriber={isSubscriber}
            />
        </Entrypoint>
    );
}

export default CompanyProfile;