// Package imports:
import React from "react";
import Bugsnag from "@bugsnag/js";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
// Component imports:
import Button from "../../../ui-elements/Button/Button";
import Input from "../../../ui-elements/Input/Formik/Input";
import Link from "../../../ui-elements/Link/Link";
import ExampleReportButton from "../../ExampleReportButton/ExampleReportButton";
import LockIcon from "../../../ui-elements/LockIcon/LockIcon";
import Tooltip from "../../../ui-elements/Tooltip/Tooltip";
import Checkbox from "../../../ui-elements/Input/Formik/Checkbox";
// Service imports:
import { GET_KELDAN_API_URL } from "../../../services/config";
import { isCompany, isSSN, stripSSN } from "../../../services/utils";
import { useBuyingProcess } from "../../../services/buyhook";
import { ErrorMessages } from "../../../services/errorMessages";
// Type imports:
import { IKeldanApiContact } from "../../../types/KeldanTypes";

interface IProps {
    isAuthenticated: boolean,
    individualAssetsAllowed: boolean
}

const SsnSearch: React.FC<IProps> = ({
    isAuthenticated,
    individualAssetsAllowed
}) => {
    const { tryPurchaseItem, tryToBuyItem, resetState, ModalsAndToasts } = useBuyingProcess(async (values: IFormValues, modalReference, modalHistory) => {
        try {
            const requestQuery = new URLSearchParams({
                Ssn: stripSSN(values.ssn) ?? '',
                reference: modalReference,
                History: modalHistory.toString()
            });
            const url = `${GET_KELDAN_API_URL()}/Vehicle/SsnSearch`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const responseBody: IKeldanApiContact = await response.json();
                    const { message } = responseBody;
                    
                    if (message === ErrorMessages.NoCard) {
                        resetState('no card');
                    } else if (message) {
                        resetState({
                            type: 'alert',
                            headText: message
                        });
                    } else {
                        resetState('bad request');
                    }
                }
            } else {
                resetState('response not ok');
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            resetState('network error');
        }
    }, 'ökutækjaskrá');

    const displayForm = (status: 'enabled' | 'disabled') => {
        return (
            <Formik
                initialValues={{
                    ssn: '',
                    tilvisun: '',
                    history: false
                }}
                validationSchema={Yup.object({
                    ssn: Yup.string()
                            .matches(/^(\d{6}-\d{4})|^(\d+)$/, 'Kennitala má einungis innihalda tölustafi')
                            .min(10, 'Kennitala þarf að innihalda 10 tölustafi')
                            .max(11, 'Kennitala má ekki vera meira en 10 tölustafir')
                            .test('ssn-test', 'Ógild kennitala', (value) => isSSN(value, true))
                            .test('individual-allowed-test', 'Einungis er hægt að leita að lögaðila', (value) => individualAssetsAllowed || isCompany(value)),
                    tilvisun: Yup.string(),
                })}
                onSubmit={(values) => tryPurchaseItem(
                    values,
                    'confirm-modal-with-reference-and-history',
                    values.tilvisun,
                    values.history
                )}
                component={(props: { values: IFormValues }) => (
                    <SubFormComponent
                        values={props.values}
                        loading={(tryToBuyItem !== null)}
                        disabled={status === 'disabled'}
                    />
                )}
            />
        );
    }

    return (
        <div className="Okutaekjaskra">
            <div className="section__body">
                <div className='KCL_vehicle-search-form'>
                    <h3>
                        {!isAuthenticated && <LockIcon size="lg" />}
                        <span>Eignastaða</span>
                    </h3>
                    <div className='search-form-description paragraph'>
                        Með því að slá inn kennitölu er hægt að kaupa upplýsingar um þau ökutæki sem eru til umráða viðkomandi fyrirtækis. <Link linkSize="18" url="/Verdskra#verdskra">Sjá verðskrá.</Link>
                    </div>
                    {isAuthenticated
                        ? displayForm('enabled')
                        : <Tooltip
                            tooltip={
                                <span>
                                    Þú þarft að skrá þig inn og vera með skráð kreditkort til að nýta þessa þjónustu.
                                </span>
                            }
                            text={displayForm('disabled')}
                        />
                    }
                    <ExampleReportButton size='lg' reportType='vehicles-owned' />
                </div>
            </div>
            <div>
                <p className='paragraph-small--italic' style={{maxWidth: '946px'}}>
                    Aðgang að eignastöðu og eignasögu eftir kennitölu einstaklinga hafa aðeins lögmenn, skiptastjórar, og eftir atvikum fyrirsvarsmenn fjármálafyrirtækja, lífeyrissjóða og opinberir aðilar sem hafa tilskilin leyfi til slíkra uppflettinga.
                    Undirrita þarf sérstaka yfirlýsingu um ábyrgð á notkun á þessum upplýsingum.
                </p>
                <Link linkSize='16' url='/Hafa-Samband' icon='forward'>Hafa samband</Link>
            </div>
            <ModalsAndToasts />
        </div>
    );
}

export default SsnSearch;

interface IFormValues {
    ssn: string,
    tilvisun: string,
    history: boolean
}

interface ISubFormProps {
    values: IFormValues,
    loading: boolean,
    disabled: boolean
}

const SubFormComponent: React.FC<ISubFormProps> = ({
    values,
    loading,
    disabled
}) => {
    return (
        <Form>
            <div className="form__body">
                <div className="form__section">
                    <div className="form__row">
                        <div className="form__col">
                            <Input
                                label="Kennitala"
                                name="ssn"
                                id="ssn"
                                value={values.ssn}
                                disabled={disabled}
                            />
                        </div>
                        <div className="form__col">
                            <Input
                                label="Tilvísun á reikning"
                                name="tilvisun"
                                id="tilvisun"
                                value={values.tilvisun}
                                disabled={disabled}
                            />
                        </div>
                        <div className="form__col actions">
                            <div className="form__actions">
                                <Button
                                    showLoader={loading}
                                    buttonType="buy"
                                    size="lg"
                                    disabled={disabled}
                                >
                                    Kaupa
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="form__row checkbox-row">
                        <div className="form__col">
                            <Checkbox
                                label="með eignasögu"
                                name="history"
                                id="history"
                                value={values.history.toString()}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
}

