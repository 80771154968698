// Package imports:
import React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpLong, faDownLong } from '@fortawesome/pro-solid-svg-icons';

export type Sign = 'pos' | 'neg' | 'neutral';
export type BackgroundSign = 'pos' | 'neg' ;

interface IProps {
    value?: string | null,
    sign?: Sign,
    hasArrow?: boolean,
    background?: BackgroundSign,
    constantBackground?: BackgroundSign
}

const ColoredNumber: React.FC<IProps>= ({
    value,
    sign = 'neutral',
    hasArrow,
    background,
    constantBackground
}) => {
    return (
        <span
            className={cx(
                'KCL_colored-number',
                sign,
                (background !== undefined) ? `background-${background}` : null,
                (constantBackground) ? `constant-background-${constantBackground}` : null
            )}
        >
            {(hasArrow && sign !== 'neutral') && (
                <FontAwesomeIcon
                    className={cx('arrow', sign)}
                    icon={(sign === 'pos') ? faUpLong : faDownLong}
                />
            )}
            {value}
        </span>
    );
}

export default ColoredNumber;