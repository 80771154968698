// Package imports:
import React from 'react';
// Component imports:
import SearchPage from '../../components/SearchPage/SearchPage';
import Entrypoint from '../Entrypoint';
import { IisAuthenticated } from '../../types/Types';

const Search: React.FC<IisAuthenticated> = ({isAuthenticated}) => {
    return (
        <Entrypoint>
            <SearchPage isAuthenticated={isAuthenticated} />
        </Entrypoint>
    );
}

export default Search;