// Package imports:
import React, { useEffect, useState } from 'react';
// Component imports:
import Loading from '../../../ui-elements/Loading/Loading';
import Button from '../../../ui-elements/Button/Button';
import Link from '../../../ui-elements/Link/Link';
import SmallSearch from '../../SmallSearch/SmallSearch';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';
import useScrollSpy from '../../../ui-elements/ScrollSpy/UseScrollSpy';
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';
import BulletList from '../../../ui-elements/Lists/BulletList';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Fetched } from '../../../types/Types';
import { IKeldanApiExcellentByAlphabet } from '../../../types/FyrirmyndarTypes';

interface IProps {
    year: string | null
}

const CompanyList: React.FC<IProps> = ({
    year
}) => {
    const [data, setData] = useState<Fetched<IKeldanApiExcellentByAlphabet>>(null);
    const [searchFilter, setSearchFilter] = useState('');

    const fetchData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Lists/ExcellentCompaniesAlphabetDictionary?year=${year}`;
            const response = await fetch(url);
            if (response.ok) {
                const body = await response.json();
                setData(body);
            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            
            setData(new Error(ErrorMessages.NetworkError));
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const { scrollToSection } = useScrollSpy('section-1', 'KCL_company-list');

    const goToSection = ((event: any, htmlElement: any) => {
        event.preventDefault();

        scrollToSection(htmlElement)
    });

    const getTotalNumberCompanies = () => {
        if (data === null || data instanceof Error) return '...';
        return Object.values(data).reduce((totalNum, companies) => (totalNum + companies.length), 0);
    }

    const displayNavLinks = () => {
        if (data instanceof Error || data === null) return;

        return (
            <div className="navigation__link">
                {data && Object.keys(data).map((key, index) => (
                    data[key].length > 0
                    ? <span key={key} onClick={ev => goToSection(ev, `section-${index + 1}`)}>
                        <a href="/" onClick={e => e.preventDefault()}>{key}</a>
                    </span>
                    : <span key={key}>{key}</span>
                ))}
            </div>
        )
    }

    const displayData = () => {
        if (data instanceof Error) return <ErrorAlert error={data} />;
        if (data === null) return <Loading />;

        const dataToDisplay = (searchFilter === '')
            // No filter
            ? data
            // If filter
            : (Object
            .entries(data)
            // Filter companies per key.
            .map(([key, companyList]) => {
                const filteredCompanies = companyList.filter(company => {
                    const name = company.name.toLowerCase();
                    return name.includes(searchFilter.toLowerCase());
                });

                return {
                    key,
                    filteredCompanies
                };
            })
            // Combine filtered companies on to key.
            .reduce((currentMap: IKeldanApiExcellentByAlphabet, { key, filteredCompanies }) => {
                currentMap[key] = filteredCompanies;
                return currentMap
            }, {}));

        return (
            Object.keys(dataToDisplay).map((key, index) => {
                const companies = dataToDisplay[key];
                const section = index + 1;
                return (companies.length > 0)
                    ? (
                        <div key={key} className="alphabet" id={`section-${section}`}>
                            <h2>{key}</h2>
                            <article className="article-overview">
                                <div className="article__body">
                                    <div className="article__section">
                                        <BulletList
                                            items={companies.map((company) =>
                                                <Link
                                                    targetBlank
                                                    url={`/Fyrirtaeki/Yfirlit/${company.companyid}`}
                                                    linkSize='18'
                                                    key={company.companyid}
                                                >
                                                    {company.name}
                                                </Link>
                                            )}
                                        />
                                    </div>
                                </div>
                            </article>
                        </div>
                    ) : null
            })
        )
    }

    return (
        <div className='main KCL_excellent-list'>
            <div className="main__inner-fluid">
                <div className="section--start">
                    <div className="mini_shell">
                        <HeadSection
                            title={`Fyrirmyndarfyrirtæki í rekstri ${year}`}
                            summary={
                                <p>Samtals {getTotalNumberCompanies()} fyrirtæki fengu viðurkenninguna árið {year}.</p>
                            }
                            actions={
                                <Button
                                    size='lg'
                                    buttonType='primary'
                                    anchorProps={{href: '/Listar/Fyrirmyndarfyrirtaeki#vottun'}}
                                >
                                    Panta vottun
                                </Button>
                            }
                            rightSide={
                                <img
                                    src={`https://cdn.livemarketdata.com/Images/Excellent/fyrirmyndar${year}.svg`}
                                    alt="Fyrirmyndarfyrirtæki"
                                    className="current-year"
                                    width="350"
                                    height="205"
                                />
                            }
                            textAlign='left-to-center'
                        />
                    </div>

                    <div className="shell">
                        <div className="section__search">
                            <div className='naviation__search'>
                                {displayNavLinks()}
                                <div className="row">
                                    <div className='col-lg-6 offset-lg-3 col-sm-8 offset-sm-2'>
                                        <div className="search__box">
                                            <SmallSearch
                                                search={searchFilter}
                                                setSearch={setSearchFilter}
                                                placeHolder='Leitaðu eftir fyrirtæki'
                                                id="Search_ExcellentList"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section section--primary section--companies">
                    <div className="section__body">
                        <div className="shell">
                            {displayData()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyList;