// Package imports:
import React, { useContext, useEffect, useState } from 'react';
//@ts-ignore
import Columns from 'react-columns';
// Component imports:
import Link from '../../ui-elements/Link/Link';
import HeatMap from '../HeatMap/SharesHeatMap';
import MarketOverview from '../MarketOverview/MarketOverview';
import CompanyReportsAnalysis from '../CompanyReportsAnalysis/CompanyReportsAnalysis';
import AdRotator from '../Ad/AdRotator';
import SharesTable from '../SharesTable/SharesTable';
import NewsByMarkets from '../NewsByMarkets/NewsByMarkets';
import BasicChart from '../Charts/BasicChart/BasicChart';
import OpeningHours from '../OpeningHours/OpeningHours';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
// Service imports:
import { DEFAULT_REFRESH_RATE, MARKET_REFRESH_RATES } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';
// Context imports:
import { AccessTokenContext } from '../../contexts/AccessTokenContext';

const queries = [
    {
        columns: 2,
        query: 'min-width: 1024px'
    },
];

const SharesPage: React.FC = () => {
    const accessToken = useContext(AccessTokenContext);
    const [ defaultProps, setDefaultProps ] = useState<IDefaultProps>({
        accessToken,
        refreshRateMs: DEFAULT_REFRESH_RATE
    });

    useEffect(() => {
        setDefaultProps({
            ...defaultProps,
            accessToken
        });
    }, [accessToken]);

    return (
        <div className='main KCL_shares-page KCL_market-page'>
            <div className="main__inner-fluid">
                <div className="shell">
                    <HeadSection 
                        hasAds='market'
                        title='Hlutabréf'
                        summary={
                            <p>Upplýsingar um gengi hlutabréfa frá Kauphöll Íslands eru birtar með 15 mínútna seinkun. Rauntímaupplýsingar eru í boði í gegnum Keldan markaðir, KODIAK Pro og KODIAK Excel.</p>
                        }
                        actions={<>
                            <Link
                                url="/Keldan-Markadir"
                                linkSize='16'
                                icon='forward'
                            >
                                Nánar um Keldan markaðir
                            </Link>
                            <Link
                                url="https://www.kodi.is/product/kodiak-pro-2/"
                                linkSize='16'
                                targetBlank
                                icon='forward'
                            >
                                Nánar um KODIAK Pro
                            </Link>
                            <Link
                                url="https://www.kodi.is/product/kodiak-excel/"
                                linkSize='16'
                                targetBlank
                                icon='forward'
                            >
                                Nánar um KODIAK Excel
                            </Link>
                        </>}
                        rightSide={<OpeningHours type="kaupholl" />}
                    />
                    <div>
                        <div className="main__cols grid-items">
                            <Columns className="custom-cols" queries={queries}>
                                <div className="grid-item">
                                    <MarketOverview
                                        overviewType='shares'
                                        accessToken={accessToken}
                                        refreshRateMs={MARKET_REFRESH_RATES['MARKETOVERVIEW']}
                                        id="SharesPage_MarketOverview"
                                    />
                                </div>
                                <div className="grid-item">
                                    <HeatMap
                                        accessToken={accessToken}
                                        refreshRateMs={MARKET_REFRESH_RATES['HEATMAP']}
                                        id="SharesPage_HeatMap"
                                    />
                                </div>
                                <div className="grid-item">
                                    <BasicChart
                                        chartType="index"
                                        symbol="OMXI15"
                                        title="Hlutabréfavísitala"
                                        accessToken={accessToken}
                                        refreshRateMs={MARKET_REFRESH_RATES['SHARESINDEXCHART']}
                                        id="SharesPage_IndexChart"
                                        className='chart--half-width'
                                    />
                                </div>
                                <div className="grid-item">
                                    <CompanyReportsAnalysis
                                        accessToken={accessToken}
                                        refreshRateMs={MARKET_REFRESH_RATES['COMPANYREPORTSANALYSIS']}
                                        id="SharesPage_CompanyReportsAnalysis"
                                    />
                                </div>
                            </Columns>
                        </div>
                        <div className="grid-items">
                            <div className='grid-item'>
                                <AdRotator location="Market1018x360" />
                            </div>
                        </div>
                        <div className="grid-items">
                            <div className='grid-item' id="hlutabref">
                                <SharesTable
                                    exchange='XICE'
                                    showDatepicker
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['SHARESTABLE']}
                                    id="SharesPage_SharesTable"
                                />
                            </div>
                        </div>
                        <div className='grid-items grid-items--flex'>
                            <div className="grid-item grid-item--2of3 grid-item--table-full">
                                <NewsByMarkets
                                    limit={10}
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['NEWSBYMARKETS']}
                                    id="SharesPage_News"
                                />
                            </div>
                            <div className='grid-item grid-item--1of3 grid-item--table-full'>
                                <AdRotator location="Market310x400" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SharesPage;