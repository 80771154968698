// Package imports:
import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
// Component imports:
import ErrorPage from '../components/ErrorPage/ErrorPage';
// Context imports:
import AccessTokenContextProvider from '../contexts/AccessTokenContext';

const releaseStage = (process.env.NODE_ENV !== 'production')
// Local dev: npm run start or npm run dev
? 'development'
// Non local deployment. Depends on whether .env.staging or .env.prod is used.
: (process.env.REACT_APP_API_LMD_SOURCE === 'production')
? 'production'
: 'staging';

let ErrorBoundary: BugsnagErrorBoundary | typeof React.Fragment = React.Fragment;

Bugsnag.start({
    apiKey: '857900aa68e6fd1715f22814324e8f3f',
    plugins: [new BugsnagPluginReact()],
    releaseStage
});

ErrorBoundary =  Bugsnag?.getPlugin('react')?.createErrorBoundary(React) ?? React.Fragment;

interface IOwnProps {
    // For header and footer and such, entrypoints that are not pages.
    dontShowFallBackComponent?: boolean
}

/**
 * The Entrypoint component is a wrapper for "page" components that we render
 * into the C# dotnet keldan. It has the bugsnag error boundary and the accessTokenContext
 * Additionally, commonly used contexts or boundaries should be used to wrap all entrypoints
 */
const Entrypoint: React.FC<IOwnProps> = ({children, dontShowFallBackComponent}) => {
    return (
        <ErrorBoundary
            FallbackComponent={dontShowFallBackComponent ? undefined : (() => <ErrorPage/>)}
        >
            <AccessTokenContextProvider>
                {children}
            </AccessTokenContextProvider>
        </ErrorBoundary>
    );
}

export default Entrypoint;