// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import CompanySearchPage from '../../components/Company/CompanySearchesPage/CompanySearchesPage';
import { IisAuthenticated } from '../../types/Types';

const ViewVehicleReport: React.FC<IisAuthenticated> = ({
    isAuthenticated,
    isSubscriber
}) => {
    return (
        <Entrypoint>
            <CompanySearchPage isAuthenticated={isAuthenticated} isSubscriber={isSubscriber} />
        </Entrypoint>
    );
}

export default ViewVehicleReport;