// Package imports:
import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartMixedUpCircleCurrency, faSearch, faTrophyStar } from '@fortawesome/pro-solid-svg-icons';
import ScrollLock from 'react-scrolllock';
import {use100vh} from 'react-div-100vh';
import cx from 'classnames';
// Component imports:
import NavDropdown from './NavDropdown';
import LoggedIn from '../NavAccess/LoggedIn';
import LoggedOut from '../NavAccess/LoggedOut';
import MobileMyKeldaMenu from '../MobileMenuButtons/MobileMyKeldaMenu';
import MobileHamburgerMenu from '../MobileMenuButtons/MobileHamburgerMenu';
// Service imports:
import { mapSectionToNav } from '../../../services/utils';
// Context imports:
import { UserInfoContext } from "../../../contexts/UserInfoContext";

interface IOwnProps {
    setSearchFocus: (b: boolean) => void,
    isMobileNavMenuOpen: boolean,
    setIsMobileNavMenuOpen: (b: boolean) => void,
    isMobileMyKeldaMenuOpen: boolean,
    setIsMobileMyKeldaMenuOpen: (b: boolean) => void
}

const Nav: React.FC<IOwnProps> = ({
    setSearchFocus,
    isMobileNavMenuOpen,
    setIsMobileNavMenuOpen,
    isMobileMyKeldaMenuOpen,
    setIsMobileMyKeldaMenuOpen
}) => {
    const toggleMobileNavMenu = () => setIsMobileNavMenuOpen(!isMobileNavMenuOpen);
    const toggleMobileMyKeldaMenu = () => setIsMobileMyKeldaMenuOpen(!isMobileMyKeldaMenuOpen);

    const height = (use100vh() ?? 0) - 115;
    const userInfo = useContext(UserInfoContext);

    const activeSection = mapSectionToNav[window.location.pathname.split("/")[1].toLowerCase()];

    return (
        <div
            className={cx("header-upper-right", {
                "is-mobile-nav-menu-open": isMobileNavMenuOpen,
                "is-mobile-mykelda-menu-open": isMobileMyKeldaMenuOpen
            })}
        >
            <button className="search-button" onClick={() => setSearchFocus(true)}>
                <FontAwesomeIcon icon={faSearch} />
            </button>
            <ScrollLock isActive={isMobileNavMenuOpen}>
                <div
                    style={isMobileNavMenuOpen ? {height: height?.toString() + 'px'} : {}}
                    className='KCL_nav-container KCL_main-nav'
                >
                    {/* Always visible */}
                    <ul className="nav__main">
                        <NavDropdown
                            label='Markaðurinn'
                            navItems={[[{
                                link: '/Markadir/Hlutabref',
                                label: 'Hlutabréf'
                            }, {
                                link: '/Markadir/Skuldabref',
                                label: 'Skuldabréf'
                            }, {
                                link: '/Markadir/Gjaldeyrismarkadur',
                                label: 'Gjaldeyrismarkaður'
                            }, {
                                link: '/Markadir/Gjaldmidlar',
                                label: 'Gjaldmiðlar'
                            }, {
                                link: '/Markadir/Visitolur',
                                label: 'Vísitölur'
                            }, {
                                link: '/Markadir/Sjodir',
                                label: 'Sjóðir'
                            }, {
                                link: '/Markadir/Sereignarsjodir',
                                label: 'Séreignarsjóðir'
                            }], [{
                                link: '/Frettir',
                                label: 'Fréttavakt Keldunnar'
                            }, {
                                link: '/Dagatal',
                                label: 'Dagatal viðskiptalífsins'
                            }, {
                                link: '/Markadir/Fjarmalahugtok',
                                label: 'Fjármálahugtök'
                            }, {
                                link: '/Markadir/Gemmaq',
                                label: 'GEMMAQ Kynjakvarði'
                            }, {
                                link: '/Verdskra#Markadir',
                                label: 'Verðskrá'
                            }]]}
                            buttonItems={[{
                                label: 'Keldan markaðir',
                                link: '/Keldan-Markadir',
                                icon: faChartMixedUpCircleCurrency
                            }]}
                            highlighted={activeSection === 'market'}
                        />
                        <NavDropdown
                            isMobileNavMenuOpen={isMobileNavMenuOpen}
                            label="Gagnaleit"
                            navItems={{
                                'Fyrirtæki': [
                                    {
                                        link: '/Fyrirtaeki/Leit#Fyrirtaekjaleit',
                                        label: 'Fyrirtækjaleit'
                                    }, {
                                        link: '/Fyrirtaeki/Leit#Hlutafelagathatttaka',
                                        label: 'Hlutafélagaþátttaka'
                                    }, {
                                        link: '/Fyrirtaeki/Leit#GildandiSkraning',
                                        label: 'Gildandi skráning',
                                    }, {
                                        link: '/Fyrirtaeki/Leit#Samanburdur',
                                        label: 'Samanburður',
                                    }, {
                                        link: '/Fyrirtaeki/Leit#Verdmat',
                                        label: 'Verðmat',
                                    }
                                ],
                                'Fasteignir': [
                                    {
                                        link: '/Fasteignir/Leit#Fasteignaskra',
                                        label: 'Fasteignaskrá'
                                    }, {
                                        link: '/Fasteignir/Leit#Thinglyst-Skjol',
                                        label: 'Þinglýst skjöl'
                                    }, {
                                        link: '/Fasteignir/Leit#Eignastada',
                                        label: 'Eignastaða'
                                    }, {
                                        link: '/Fasteignir/Leit#Kaupsamningar',
                                        label: 'Kaupsamningar'
                                    }, {
                                        link: '/Fasteignir/Leit#Leigusamningar',
                                        label: 'Leigusamningar'
                                    }
                                ],
                                'Ökutæki': [
                                    {
                                        link: '/Okutaeki#Okutaekjaskra',
                                        label: 'Ökutækjaskrá'
                                    }, {
                                        link: '/Okutaeki#Vinnuvelaskra',
                                        label: 'Vinnuvélaskrá'
                                    }, {
                                        link: '/Okutaeki#Eignastada',
                                        label: 'Eignastaða'
                                    }, {
                                        link: '/Okutaeki#Ferilskra',
                                        label: 'Ferilskrá'
                                    }
                                ],
                                'Annað': [
                                    {
                                        link: '/PEP/Leit#Island',
                                        label: 'Stjórnmálaleg tengsl'
                                    }, {
                                        link: '/Einstaklingar/Thjodskra',
                                        label: 'Þjóðskrá'
                                    }, {
                                        link: '/Logbirtingar/Leit',
                                        label: 'Lögbirtingar'
                                    }, {
                                        link: '/Min-Kelda/Voktun#Fyrirtaekjavaktin',
                                        label: 'Vöktun'
                                    }, {
                                        link: '/Verdskra#Gagnaleit',
                                        label: 'Verðskrá'
                                    }
                                ],
                            }}
                            highlighted={activeSection === 'search'}
                        />
                        <NavDropdown
                            label='Keldan'
                            navItems={[[{
                                link: '/Um-Okkur',
                                label: 'Um Kelduna'
                            }, {
                                link: '/Verdskra',
                                label: 'Áskriftarleiðir'
                            }, {
                                link: '/Listar',
                                label: 'Markhópalistar'
                            }, {
                                link: '/Vefthjonusta',
                                label: 'Vefþjónusta (API)'
                            }, {
                                link: '/Areidanleikakannanir',
                                label: 'Áreiðanleikakannanir'
                            }, {
                                link: '/Auglysingar',
                                label: 'Auglýsingar'
                            }], [{
                                link: '/Keldan-Markadir',
                                label: 'Keldan markaðir'
                            }, {
                                link: '/Tilkynningar',
                                label: 'Keldan tilkynningar',
                                isNew: true
                            }, {
                                link: '/Vaktarinn',
                                label: 'Vaktarinn'
                            }, {
                                link: '/Sersnidid-Verdmat',
                                label: 'Sérsniðið verðmat',
                                isNew: true
                            }]]}
                            buttonItems={[{
                                label: 'Fyrirmyndarfyrirtæki í rekstri',
                                link: '/Listar/Fyrirmyndarfyrirtaeki',
                                icon: faTrophyStar
                            }]}
                            highlighted={activeSection === 'keldan'}
                        />
                    </ul>
                </div>
            </ScrollLock>
            {userInfo && userInfo.isAuthenticated
                ? <>
                    <LoggedIn
                        isMobileNavMenuOpen={isMobileMyKeldaMenuOpen}
                        userInfo={userInfo}
                        highlighted={activeSection === 'mykelda'}
                    />
                    <MobileMyKeldaMenu
                        onClick={() => {
                            toggleMobileMyKeldaMenu();
                            setSearchFocus(false);
                            setIsMobileNavMenuOpen(false);
                        }}
                        isOpen={isMobileMyKeldaMenuOpen}
                    />
                </>
                : <LoggedOut />
            }
            <MobileHamburgerMenu
                onClick={() => {
                    toggleMobileNavMenu();
                    setSearchFocus(false);
                    setIsMobileMyKeldaMenuOpen(false);
                }}
                isOpen={isMobileNavMenuOpen}
            />
        </div>
  );
}

export default Nav;
