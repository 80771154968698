// Package imports:
import React from 'react';
// Component imports:
import Tabs from '../../ui-elements/Tabs/Tabs';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import Notkunar from './Notkunar/Notkunar';
import Samningur from './Samningur/Samningur';
import PersonuverndPEP from './PersonuverndPEP/PersonuverndPEP';
import Personuverndarstefna from './Personuverndarstefna/Personuverndarstefna';

const UserDisclaimer: React.FC = () => {
    return (
        <div className="main KCL_disclaimer">
            <div className='mini_shell'>
                <HeadSection
                    title="Skilmálar Keldunnar"
                    summary={
                        <p>Áskrifandi og notendur sem áskrifandi skráir á sínum vegum í upplýsingakerfi Keldunnar samþykkja eftirfarandi skilmála: </p>
                    }
                />
                <div className='section__body'>
                    <Tabs
                        saveTabToUrl
                        tabs={[{
                            id: "notkunarskilmalar",
                            label: "Notkunarskilmálar",
                            component: <Notkunar />
                        }, {
                            id: "samningur",
                            label: "Samningur vegna áskriftar og kaupa",
                            component: <Samningur />
                        }, {
                            id: "personuvernd",
                            label: "Persónuverndarstefna",
                            component: <Personuverndarstefna />
                        }, {
                            id: "peplisti",
                            label: "Persónuverndaryfirlýsing vegna PEP lista",
                            component: <PersonuverndPEP />
                        }]}
                        tabsSize="lg"
                    />
                </div>
            </div>
        </div>
    )
}

export default UserDisclaimer;