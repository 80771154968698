export const ErrorMessages = {
    NoCard: 'Kort vantar',
    EmptyReport: 'Þessi skýrsla er tóm.',
    NoProperties: 'Engar fasteignir skráðar á þessa kennitölu fundust',
    NoVehicles: 'Engin ökutæki skráð á þessa kennitölu fundust',
    NoConstructions: 'Engar vinnuvélar skráðar á þessa kennitölu fundust',
    UnableToFetchData: 'Ekki tókst að sækja gögn. Vinsamlegast reyndu aftur eða hafðu samband við info@keldan.is.',
    NoResult: 'Þessi skýrsla skilar engum niðurstöðum, ekki verður rukkað fyrir þessi kaup.',
    Contact: 'Villa kom upp, vinsamlegast hafið samband, info@keldan.is',
    FileError: 'Ekki tókst að ná í skjalið. Vinsamlegast reyndu aftur eða hafðu samband við info@keldan.is',
    RequestFailed: 'Fyrirspurn mistókst',
    ErrorInRequest: 'Villa í fyrirspurn',
    NetworkError: 'Network Error',
    Other: 'Eitthvað hefur farið úrskeiðis, vinsamlegast reynið aftur.',
    OtherShort: 'Eitthvað fór úrskeiðis',
    NoDataFound: 'Engin gögn í boði',
    NoAccess: 'Þú hefur ekki tilskylin réttindi fyrir þessa aðgerð.',
}