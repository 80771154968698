// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import AboutNewswireComp from '../../components/AboutNewswire/AboutNewswire';

const AboutNewswire: React.FC = () => {
    return (
        <Entrypoint>
            <AboutNewswireComp />
        </Entrypoint>
    );
}

export default AboutNewswire;