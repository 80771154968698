// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import UserDisclaimerComp from '../../components/UserDisclaimer/UserDisclaimer';

const UserDisclaimer: React.FC = () => {
    return (
        <Entrypoint>
            <UserDisclaimerComp />
        </Entrypoint>
    );
}

export default UserDisclaimer;