// Package imports:
import React from 'react';
import cx from 'classnames';

type DivProps = React.HTMLAttributes<HTMLDivElement>;
type ImageSide = 'left' | 'right';
interface IOwnProps {
    imgSrc?: string,
    imgSide?: ImageSide
}

type Props = DivProps & IOwnProps;

const TextImageBlock: React.FC<Props> = ({
    className = '',
    imgSrc = '',
    imgSide = 'left',
    children,
    ...props
}) => {
    return (
        <div
            className={cx('KCL_text-image-block', {
                'no-image': imgSrc.length === 0
            }, `image-${imgSide}`, className)}
            {...props}
        >
            {imgSrc && (
                <div className="text-image-block__media-holder">
                    <div className="text-image-block__media image-fit">
                        <img src={imgSrc} alt="" />
                    </div>
                </div>
            )}

            <div className="text-image-block__body">
                <div className="text-image-block__content">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default TextImageBlock
