// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import VaktarinnPage from '../../components/VaktarinnPage/VaktarinnPage';

const Vaktarinn: React.FC = () => {
    return (
        <Entrypoint>
            <VaktarinnPage />
        </Entrypoint>
    );
}

export default Vaktarinn;