// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import PropertyReports from '../../components/Property/Reports/Reports';
// Type imports:
import { IPropertyViewModel } from '../../types/PropertyTypes';

const Reports: React.FC<IPropertyViewModel> = ({
    propertyNumber,
    bondsAllowed
}) => {
    return (
        <Entrypoint>
            <PropertyReports propertyNumber={propertyNumber} bondsAllowed={bondsAllowed} />
        </Entrypoint>
    );
}

export default Reports;