// Package imports:
import React from 'react';

interface IProps {
    width?: string
}

const Censored: React.FC<IProps> = ({width}) => {
    return (
        <span style={{width: width ? width : '100%', display: 'inline-block'}} className='KCL_censored'></span>
    );
}

export default Censored;