// Package imports:
import React from 'react';
// Service imports:
import { getRelease } from '../../services/config';

interface IProps {
    folder?: 'Crypto' | 'Currencies/ISB' | 'Currencies/NBX' | 'Currencies/CBI' | 'Funds' | 'Tradables' | 'Indices'
    symbol: string
}

const MiniGraph: React.FC<IProps> = ({
    folder = 'Tradables',
    symbol
}) => (
    <img
        src={`https://cdn.livemarketdata.com/KeldanGraphs/${getRelease()}/${folder}/${symbol}/graph.svg`}
        alt={`${symbol} Mini-Graph`}
        width={60}
        height={20}
        onError={(event) => {
            event.currentTarget.src = "https://cdn.livemarketdata.com/KeldanGraphs/default-graph.svg";
        }}
    />
)

export default MiniGraph;