// Package imports:
import React, { Fragment } from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import CalendarDate from '../../ui-elements/CalendarDate/CalendarDate';
import Link from '../../ui-elements/Link/Link';
import DagatalItem from './DagatalItem';
import Loading from '../../ui-elements/Loading/Loading';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
// Service imports:
import { getAPItoday } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IDefaultProps, IKeldanCalendarEvent } from '../../types/Types';

type Props = IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const Dagatal: React.FC<Props>= ({
    refreshRateMs,
    accessToken,
    ...props
}) => {
    // Set state.
    const [ calendarEvents ] = useApiLmdData<IKeldanCalendarEvent[]>(
        `/v1/market_data/v1/keldan/calendar/events?start_date=${getAPItoday()}&start=0&limit=15`,
        accessToken,
        refreshRateMs
    );

    const displayData = () => {
        let { data, error } = calendarEvents;
        if (data === null) {
            if (error === null) return <Loading />;
            else return <ErrorAlert error={error} />
        }
        return (
            <>
                {data.map((feedItem) => (
                    <Fragment key={feedItem.id}>
                        <DagatalItem
                            key={feedItem.id}
                            title={feedItem.title}
                            description={feedItem.notes || 'Ekki fundust frekari upplýsingar um atburð' }
                            date={(feedItem.startdate_utc !== null) 
                                ? <CalendarDate date={new Date(feedItem.startdate_utc.replace(/ /g, "T"))} />
                                : null
                            }
                            label={feedItem.calendar_title ?? ''}
                            source={feedItem.calendar_title ?? ''}
                            link={feedItem.url}
                            isNew={false}
                            seen={false}
                        />
                    </Fragment>
                ))}
            </>
        )
    }

    return (
        <DisplayBox
            className="KCL_dagatal"
            title="Dagatal viðskiptalífsins"
            anchorProps={{ href: '/Dagatal' }}
            displayBoxSize="sm"
            footerLeft={
                <Link
                    url="/Dagatal"
                    linkSize='14'
                    icon='forward'
                >
                    Fara í dagatal viðskiptalífsins
                </Link>
            }
            {...props}
        >
            {displayData()}
        </DisplayBox>
    );
}

export default Dagatal;