// Package imports:
import React, { useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const Recaptcha: React.FC<{
    setToken: (e: string | null) => void,
    position: "left" | "center" | "right"
}> = ({
    setToken,
    position,
}) => {
  
    const captchaRef = useRef(null)

    const getDirection = () => {
        switch (position) {
            case "left":
                return 'flex-start'
            case "right":
                return 'flex-end'
            default:
                return 'center'
        }
    }
    return <div className='KCL_recaptcha' style={{'alignItems': getDirection()}}>
        <ReCAPTCHA
            ref={captchaRef}
            sitekey={'6LcMzD0oAAAAAC3AYOdrt46Ax9WyivSS04OOFsrX'}
            onChange={setToken}
        />
    </div>
}

export default Recaptcha;