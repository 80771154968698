// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import AdvertiseComp from '../../components/Advertise/Advertise';

const Advertise: React.FC = () => {
    return (
        <Entrypoint>
            <AdvertiseComp />
        </Entrypoint>
    );
}

export default Advertise;