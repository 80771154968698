// Package imports:
import React, { Fragment } from 'react';
import cx from 'classnames';
// Component imports:
import useScrollSpy from './UseScrollSpy';

interface IProps {
    parentClass: string
    initialStep?: string,
    children: any,
    containerClass: string,
    navClass: string
};

const ScrollSpy: React.FC<IProps> = ({
    parentClass,
    initialStep = 'section-1',
    children,
    containerClass = '',
    navClass = ''
}) => {
    const { currentSection, scrollToSection } = useScrollSpy(initialStep, parentClass);

    const goToSection = ((event: any, htmlElement: any) => {
        event.preventDefault();

        scrollToSection(htmlElement)
    })
    return (
        <Fragment>
            <div className={cx('scroll-spy-nav', navClass)}>
                <ul>
                    {children && children.map((child: any) => (
                        <li
                            key={child.props.label}
                            className={cx({ 'is-active': currentSection === child.props.id })}
                        >
                            <a
                                href={`#${child.props.id}`}
                                onClick={ev => goToSection(ev, child.props.id)}
                            >
                                {child.props.label}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>

            <div className={containerClass}>
                {children}
            </div>
        </Fragment>
    );
};

export default ScrollSpy;
