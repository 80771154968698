// Package imports:
import React, { useMemo } from 'react';
import Highcharts, { SeriesOptionsType } from 'highcharts/highstock';
import ISLocale from 'date-fns/locale/is';
import { format } from 'date-fns';
import cx from 'classnames';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Label from '../../../ui-elements/Label/Label';
import DefaultChart from '../DefaultChart/DefaultChart';
// Service imports:
import { formatNumber } from '../../../services/utils';
// Type imports:
import { IDefaultProps } from '../../../types/Types';

interface IOwnProps {
    symbol?: string,
    title?: string,
    height?: number,
    data: [] | IVisibleCurrency[]
}

interface IVisibleCurrency {
    symbol: string,
    name: string,
    color: string,
    data: number[][]
}

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const GjaldmidlarChart: React.FC<Props> = ({
    title,
    height = 500,
    accessToken,
    refreshRateMs,
    data,
    className,
    ...props
}) => {
    const series: SeriesOptionsType[] = useMemo(() => {
        let allSeries: Highcharts.SeriesLineOptions[] = data.map((currency) => {
             return ({
                id: currency.symbol,
                type: 'line',
                name: currency.name,
                color: currency.color,
                data: currency.data ?? []
            })
        })

        return allSeries;
    }, [data]);

    return (
        <DisplayBox
            className={cx('KCL_chart', className)}
            title={title}
            footerRight={
                <Label
                    text="Seðlabanki Íslands"
                    labelType="origin"
                    url='https://sedlabanki.is'
                />
            }
            {...props}
        >
            <div className='row'>
                <DefaultChart
                    height={height}
                    tooltipFormatter={
                        function() {
                            //filtera einungis það sem er visible af því ef það er notað points[0] crashar allt þegar eitthvað series er disable-að
                            return (
                                `<div class="chart-tooltip">
                                    <p class="tooltip-date">${format(this.x, 'EEEE, dd. MMM yyyy', {locale: ISLocale})}</p>
                                    ${this.points?.filter(x => x.point.visible === true).map(x => (
                                        `<p><span style="color: ${x.color}">${x.series.getName()}</span>: ${formatNumber(x.y, '-', 3)}</p>`
                                    ))}
                                </div>`
                            ).replace(/>,</g,'><')
                        }
                    }
                    yAxes={[{
                        title: {
                            text: 'Kross',
                            margin: 15
                        },
                        height: '100%',
                    }]}
                    series={series}
                />
            </div>
        </DisplayBox>
    );
}

export default GjaldmidlarChart;