// Package imports:
import React, { useMemo, useRef, useState } from "react";
// Component imports:
import Button from "../../ui-elements/Button/Button";
import Link from "../../ui-elements/Link/Link";
import Loading from "../../ui-elements/Loading/Loading";
import DisplayBox from "../../ui-elements/DisplayBox/DisplayBox";
import Table from "../../ui-elements/Table/Table";
import SearchResults from "../../ui-elements/SearchResults/SearchResults";
// Type imports:
import { Subscription } from "../../types/KeldanTypes";
//Constants imports:
import ForeignPepSearchCompany from "./ForeignSearchCompanyForm";
import { PepCompanyResult } from "../../types/ForeignCompanyPepTypes";
import ForeignCompanyPepModal from "./ForeignCompanyPepModal";
import { formatCategories } from "./PepPage";

interface IProps {
  isAuthenticated: boolean;
  subscriptions: Subscription[] | null;
}

export interface IForeignSearchParams {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  dob?: string;
}
const ForeignSearchCompany: React.FC<IProps> = ({
  isAuthenticated,
  subscriptions,
}) => {
  //modals
  const [companyShowModal, setCompanyShowModal] = useState(false);
  const [companyModalData, setCompanyModalData] =
    useState<PepCompanyResult | undefined>(undefined);
  //type to know which search results to display
  const [companyData, setCompanyData] =
    useState<PepCompanyResult[] | null | undefined>(undefined);

  const searchResultsRef = useRef<HTMLDivElement>(null);
  const cleanResultsAndForm = () => {
    setCompanyData(undefined);
  };

  const displayResultSection = () => {
    if (companyData === undefined) return <></>;
    return (
      <SearchResults
        title={
          "Niðurstöður"
        }
        show
        closeResults={() => cleanResultsAndForm()}
        ref={searchResultsRef}
        component={
          (companyData === null) ? (
            <DisplayBox>
              <Loading />
            </DisplayBox>
          ) : (
            <DisplayBox>{tableComponent}</DisplayBox>
          )
        }
      />
    );
  };

  const tableComponent = useMemo(() => {
      return (
        <Table
          tableSize="lg"
          data={companyData ?? []}
          showEmptyTableText
          columns={[
            {
              title: "Nafn",
              renderCell: ({ nameDetails }) =>
                nameDetails?.[0]?.companyName ?? "",
              textAlign: "left",
            },
            {
              title: "Land",
              renderCell: ({ primaryLocation }) => primaryLocation,
              textAlign: "left",
            },
            {
              title: "Lýsing",
              renderCell: ({ generalInfo }) => generalInfo?.businessDescription,
              textAlign: "left",
            },
            {
              title: "Flokkur/ar",
              renderCell: ({ categories }) => formatCategories(categories),
              textAlign: "left",
            },
            {
              title: "Ítarefni",
              renderCell: (item) => (
                <Button
                  onClick={() => {
                    setCompanyModalData(item);
                    setCompanyShowModal(true);
                  }}
                  buttonType="secondary"
                  size="sm"
                >
                  Skoða
                </Button>
              ),
            },
          ]}
        />
      );
    
  }, [companyData]);

  const handleSearchResults = (
    data: PepCompanyResult[] | null | undefined,
  ) => {
    setCompanyData(data);
  };

  const isDisabled = useMemo(() => {
    return !(
      subscriptions !== null &&
      isAuthenticated &&
      subscriptions.some((sub) => sub.ProductId === 29)
    );
  }, [isAuthenticated, subscriptions]);
  return (
    <>
      <div className="KCL_pep-search shell">
        <div className="main__inner-fluid">
          <div className="descriptionWrapper">
            <p className="summary-paragraph">
              Hér geta tilkynningarskyldir aðilar kannað stöðu lögaðila gagnvart alþjóðlegum þvingunarlistum og öðrum válistum.
            </p>
            <p className="paragraph--bold">
              Athugið að hver uppfletting er samkvæmt{" "}
              <Link linkSize="18" url="/Areidanleikakannanir#kynning">
                verðskrá Keldunnar
              </Link>
            </p>
          </div>
          <ForeignPepSearchCompany
            isDisabled={isDisabled}
            changeSearchResults={handleSearchResults}
            isAuthenticated={isAuthenticated}
          />
        </div>
        <div style={{ marginBottom: "100px" }}>
          {isDisabled && (
            <div style={{ marginTop: "30px" }}>
              <p className="paragraph">
                Ertu tilkynningarskyldur aðili en ekki með aðgang?{" "}
                <Link url="/Hafa-Samband/PEP" linkSize="18">
                  Hafðu samband.
                </Link>
              </p>
            </div>
          )}
          {isAuthenticated && (
            <div style={{ marginTop: "50px" }}>
              <Link
                url="/Min-Kelda/Keyptar-Skyrslur/PEP-Erlent-Fyrirtaeki"
                linkSize="16"
                icon="forward"
              >
                Sjá allar uppflettingar
              </Link>
            </div>
          )}
        </div>
      </div>

      {displayResultSection()}

      <ForeignCompanyPepModal
        show={companyShowModal}
        close={() => setCompanyShowModal(false)}
        data={companyModalData}
      />
    </>
  );
};

export default ForeignSearchCompany;
