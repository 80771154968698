// Package imports:
import React from 'react';
// Component imports:
import YieldCurve from '../../Charts/YieldCurve/YieldCurve';
// Type imports
import { IDefaultProps } from '../../../types/Types';

type Props = IDefaultProps;

const Vaxtaferlar: React.FC<Props> = ({
    accessToken,
    refreshRateMs
}) => {
    const defaultProps = {
        accessToken,
        refreshRateMs
    };
    return (
        <div className='KCL_vaxtaferlar'>
            <YieldCurve indexed={true} {...defaultProps} />
            <YieldCurve indexed={false} {...defaultProps} />
        </div>
    );
}

export default Vaxtaferlar;