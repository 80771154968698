// Package imports:
import { Fragment } from 'react';
import cx from 'classnames';

export type AlignOptions = 'left' | 'center' | 'right';
type TableSize = 'sm' | 'lg';

interface IProps<T> {
    data: Array<Array<T>>
    columns: Array<Array<{
        title: string | JSX.Element,
        renderCell(dataItem: T): any,
        textAlign?: AlignOptions,
        overrideTd?: boolean
    }>>,
    dontShowHeaders?: boolean,
    tableSize?: TableSize,
    pricingTableHeaderStyle?: boolean,
}

const TandemTable = <T extends any>({
    data,
    columns,
    dontShowHeaders,
    tableSize = 'sm',
    pricingTableHeaderStyle,
}: IProps<T>) => {
    // Helper funcy's (AKA Hunkys).
    const getAlignStyles = (align?: AlignOptions) => ({ textAlign: align ?? 'right' });
    // Display functions
    const displayHead = (i: number) => (dontShowHeaders
        ? null
        : <thead>
            <tr>
                {columns[i].map(({ title, textAlign }, index) => {
                    return (
                        <th
                            key={index}
                            style={getAlignStyles(textAlign)}
                        >
                            <span className='title-span'>
                                <span className='text-span'>
                                    {title}
                                </span>
                            </span>
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
    const displayBody = (i: number) => (
        <tbody>
            {data[i].map((datum, index) => (
                <tr key={index}>
                    {columns[i].map(({ renderCell, textAlign, overrideTd }, index) => (
                        overrideTd
                            ? <Fragment key={index}>
                                {renderCell(datum)}
                            </Fragment>
                            : <td
                                key={index}
                                style={getAlignStyles(textAlign)}
                            >
                                {renderCell(datum)}
                            </td>
                    ))}
                </tr>
            ))}
        </tbody>
    );

    return (
        <div className={cx('KCL_table', 'KCL_table--tandem', tableSize, {'pricingTableHeaderStyle': pricingTableHeaderStyle})}>
            <div className='table__body'>
                <table>
                    {data.map((_, index) => (
                        <Fragment key={index}>
                            {displayHead(index)}
                            {displayBody(index)}
                        </Fragment>
                    ))}
                </table>
            </div>
        </div>
    );
}

export default TandemTable;
