// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import HafaSamband from '../../components/HafaSamband/HafaSamband';
// Type imports:
import { ContactTopic } from '../../types/Types';

const Contact: React.FC<{topic?: ContactTopic}> = ({
    topic = 'question'
}) => {
    return (
        <Entrypoint>
            <HafaSamband topic={topic} />
        </Entrypoint>
    );
}

export default Contact;