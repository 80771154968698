// Package imports:
import { Fragment } from 'react';
import cx from 'classnames';
// Component imports:
import Loading from '../Loading/Loading';
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert';
// Type imports:
import { ApiLmdData } from '../../types/Types';

export type AlignOptions = 'left' | 'center' | 'right';
type TableSize = 'sm' | 'lg';

interface IProps<T> {
    headerTitle?: string,
    apiData: ApiLmdData<T>,
    columns: Array<{
        title: string | JSX.Element
        renderCell(dataItem: T): any,
        textAlign?: AlignOptions,
        overrideTd?: boolean
    }>,
    dontShowHeaders?: boolean,
    tableSize?: TableSize,
    statusComponent?: JSX.Element
}

const SingleLineTable = <T extends any>({
    apiData,
    columns,
    dontShowHeaders,
    tableSize = 'sm',
}: IProps<T>) => {

    // Helper funcy's (AKA Hunkys).
    const getAlignStyles = (align?: AlignOptions) => ({ textAlign: align ?? 'right' });
    
    // Display functions
    const displayHead = () => {
        return dontShowHeaders
            ? null
            : <thead>
                <tr>
                    {columns.map(({ title, textAlign }, index) => {
                        return (
                            <th
                                key={index}
                                style={getAlignStyles(textAlign)}
                            >
                                {title}
                            </th>
                        );
                    })}
                </tr>
            </thead>
    }

    const displayBody = () => {
        const { data } = apiData;
        if (data === null) return null;
        return displayRowBody(data);
    }

    const displayRowBody = (data: T) => (
        <tbody>
            <tr>
                {columns.map(({ renderCell, textAlign, overrideTd }, colIndex) => (
                    overrideTd
                        ? <Fragment key={colIndex}>
                            {renderCell(data)}
                        </Fragment>
                        : <td
                            key={colIndex}
                            style={getAlignStyles(textAlign)}
                            className={cx(`align-${textAlign}`)}
                        >
                            {renderCell(data)}
                        </td>
                ))}
            </tr>
        </tbody>
    );

    const displayMessages = () => {
        const { data, error } = apiData;
        if (data === null) {
            // Case 1. Both are loading.
            if (error === null) {
                return <Loading />;
            }
            // Case 2. Only Error
            else {
                return <ErrorAlert error={error} />;
            }
        }
    }


    return (
        <div className={cx('KCL_table KCL_single-line-table', tableSize)}>
            <div className='table__body'>
                <table>
                    {displayHead()}
                    {displayBody()}
                </table>
                {displayMessages()}
            </div>
        </div>
    );
}

export default SingleLineTable;
