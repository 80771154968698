// Package imports:
import React, { useState } from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import LmdTable, { AlignOptions } from '../../ui-elements/Table/LmdTable';
import CalculatedColoredNumber from '../../ui-elements/CalculatedColoredNumber/CalculatedColoredNumber';
import PriceRange from '../../ui-elements/PriceRange/PriceRange';
import Filter from '../../ui-elements/Filter/Filter';
// Service imports:
import { displayQuantityShorthand, formatNumber, getTableDate } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
// Types imports:
import { IDefaultProps, SettingsTypeFxMarket } from '../../types/Types';
import { IApiIrSnapshot } from '../../types/MarketTypes';
import Flag, { FlagNames } from '../../ui-elements/Flag/Flag';

type Props = IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

type SettingsBooleanMap = {
    [key in SettingsTypeFxMarket]: boolean
}

type FxMarketKey = keyof IApiIrSnapshot;

const getColumnInfoWithPercentage = (fxMarketKey: FxMarketKey): IColumnInfo => ({
    renderCell: (datum) => {
        const value = datum[fxMarketKey];
        if (typeof value === 'number') {
            return <CalculatedColoredNumber
                currentValue={value}
                formatting='percent'
                hasArrow
                hasColor
            />
        }
    },
    textAlign: 'left'
})

interface IColumnInfo {
    renderCell: (d: IApiIrSnapshot) => any,
    textAlign?: AlignOptions,
    overrideTd?: boolean
}

const SETTING_TO_VALUE_MAP: {
    [T in SettingsTypeFxMarket]: IColumnInfo
} = {
    'Verð': {
        renderCell: ({last_price}) => formatNumber(last_price),
        textAlign: 'left'
    },
    'Kaup': {
        renderCell: ({bid_price}) => formatNumber(bid_price),
        textAlign: 'left'
    },
    'Sala': {
        renderCell: ({ask_price}) => formatNumber(ask_price),
        textAlign: 'left'
    },
    'Velta mynt': {
        renderCell: ({intraday_accumulated_volume}) => displayQuantityShorthand(intraday_accumulated_volume),
        textAlign: 'left'
    },
    'Velta ISK': {
        renderCell: ({intraday_accumulated_turnover}) => displayQuantityShorthand(intraday_accumulated_turnover),
        textAlign: 'left'
    },
    'Fj. viðsk.': {
        renderCell: ({intraday_no_of_trades}) => intraday_no_of_trades,
        textAlign: 'left'
    },
    'Dagsbr.': getColumnInfoWithPercentage('intraday_price_change_per'),
    '1 mán.': getColumnInfoWithPercentage('weeks4_price_change_per'),
    'Innan árs': getColumnInfoWithPercentage('year_price_change_per'),
    '1 ár': getColumnInfoWithPercentage('months12_price_change_per'),
    'Síðustu viðsk.': {
        renderCell: ({last_trade_dateutc}) => getTableDate(last_trade_dateutc?.toString() ?? null, "HH:MM:SS", "."),
        textAlign: 'left'
    },
    'Árs bil': {
        renderCell: ({months12_low_price, months12_high_price, last_price}) => <PriceRange low={months12_low_price} high={months12_high_price} value={last_price} size='sm' />,
        textAlign: 'right'
    }
}

const getInitialSettingsState = (): SettingsBooleanMap => ({
    'Verð': true,
    'Kaup': true,
    'Sala': true,
    'Velta mynt': false,
    'Velta ISK': true,
    'Fj. viðsk.': true,
    'Dagsbr.': false,
    '1 mán.': false,
    'Innan árs': false,
    '1 ár': false,
    'Síðustu viðsk.': true,
    'Árs bil': false
});

const FxMarketTable: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    ...props
}) => {

    const [EURISKData] = useApiLmdData<IApiIrSnapshot[]>(
        '/v1/market_data/v1/ir/equities/symbol/EURISK:KELD/snapshot/',
        accessToken,
        refreshRateMs
    );
    const [USDISKData] = useApiLmdData<IApiIrSnapshot[]>(
        '/v1/market_data/v1/ir/equities/symbol/USDISK:KELD/snapshot/',
        accessToken,
        refreshRateMs
    );

    const [settings, setSettings] = useState(getInitialSettingsState());

    function changeSettings(header: SettingsTypeFxMarket) {
        setSettings({
            ...settings,
            [header]: !settings[header]
        })
    }

    const settingsValues = Object.entries(settings).map(([settingKey, settingValue]) => ({
        text: settingKey,
        selected: settingValue,
        toggleSelected: () => changeSettings(settingKey as SettingsTypeFxMarket)
    }))


    const columnsBasedOnSettings = Object.entries(settings).filter(([key, value]) => value).map(([key]) => ({
        title: key,
        ...(SETTING_TO_VALUE_MAP[key as SettingsTypeFxMarket])
    }));
    
    const handleData = () => {
        if (EURISKData.error) return EURISKData;
        if (USDISKData.error) return USDISKData;
        const dataToReturn = [];
        if(EURISKData.data !== null) {
            dataToReturn.push(EURISKData.data[0])
        }
        if(USDISKData.data !== null) {
            dataToReturn.push(USDISKData.data[0])
        }
        return {
            error: null,
            data: dataToReturn
        };
    }

    return (
        <DisplayBox 
            title="Gjaldeyrismarkaður"
            anchorProps={{href: '/Markadir/Gjaldeyrismarkadur'}}
            asideComponent={
                <div className="fxmarket-filter">
                    <Filter itemStyle={{size: 'lg', showCheck: true }} itemValues={settingsValues} columnStyle='grid' /> 
                </div>
            }
            displayBoxSize="sm"
            className='KCL_fx-market-table'
            {...props}
        >
            <LmdTable 
                apiData={handleData()} 
                columns={[{
                    title: 'Auðkenni',
                    renderCell: ({symbol}) => (
                        <div className="avatar-and-text">
                            <Flag name={symbol?.slice(0, 3) as FlagNames} />
                            <span>{symbol?.replace(':KELD', '')}</span>
                        </div>
                    ),
                    textAlign: 'left',
                }, ...columnsBasedOnSettings]}
            />
        </DisplayBox>
    )
}

export default FxMarketTable;