// Package imports:
import { useState } from 'react'
import cx from 'classnames';
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import ISLocale from 'date-fns/locale/is';
import USLocale from 'date-fns/locale/en-US';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons'
import { useField, useFormikContext } from 'formik';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';

interface IProps { 
    [x: string]: any,
    name: string,
    value: string | null,
    label?: string,
    size?: string,
    lang?: string,
    maxDate?: Date,
    minDate?: Date,
    disabled?: boolean,
    id?: string,
    max_width?: boolean,
    dateFormat?: string
}

export const DatePickerField = ({ 
    label = 'Dagsetning ',
    size = 'lg',
    lang = 'en',
    maxDate,
    minDate,
    dateFormat = 'dd.MM.yyyy',
    max_width,
    disabled = false,
    id = 'date-picker',
    ...props
}: IProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const locale = (lang === 'is') ? ISLocale : USLocale;
    // Label starts inline, but once date or isopen changes it goes on top.
    const isLabelOnTop = ((props.value !== '' && props.value !== null) || isOpen);
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props.name);
    return <>
        <div>
            <div
                className={cx('KCL_datepicker', size, {
                    'is-open': isOpen,
                    'has-error': meta.error && meta.touched,
                    'disabled': disabled,
                })}
            >
                <label
                    htmlFor={id}
                    className={cx("datepicker__label", {
                        'is-on-top': isLabelOnTop,
                        'disabled': disabled,
                        'has-error': meta.error && meta.touched
                    })}
                >
                    {label}
                </label>
                {disabled 
                    ? <input
                        disabled
                        className={cx('datepicker__input','disabled', {'max_width': max_width})}
                        id={id}
                    />
                    : <DatePicker
                        {...field}
                        {...props}
                        value={props.value === null ? undefined : props.value}
                        id={id}
                        //change sizE? ??
                        className={cx('datepicker__input', {'has-error': meta.error && meta.touched, 'max_width': max_width})}
                        selected={(field.value && new Date(field.value)) || null}
                        onChange={(val) => {
                            setFieldValue(field.name, val);
                        }}
                        dateFormat={dateFormat}
                        locale={locale}
                        selectsRange={false}
                        onCalendarOpen={() => setIsOpen(true)}
                        onCalendarClose={() => setIsOpen(false)}
                        minDate={minDate}
                        maxDate={maxDate}
                        renderCustomHeader={({
                            monthDate,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <div className="react-datepicker__header-inner">
                                <button 
                                    aria-label={lang === 'is' ? 'Fyrri mánuður' : 'Previous Month'}
                                    className="react-datepicker__navigation react-datepicker__navigation--previous"
                                    onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        decreaseMonth()
                                    }} 
                                    disabled={prevMonthButtonDisabled}
                                >
                                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.281 9.719a1.01 1.01 0 0 0 1.094.219C5.75 9.78 6 9.405 6 9V1c0-.375-.25-.75-.625-.906a1.01 1.01 0 0 0-1.094.218l-4 4A.97.97 0 0 0 0 5a.99.99 0 0 0 .281.719l4 4Z" fill="currentColor"/>
                                    </svg>
                                </button>
    
                                <div className="react-datepicker__current-month">
                                    {format(monthDate, 'MMMM y', {locale: locale})}
                                </div>
                    
                                <button 
                                    onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        increaseMonth()
                                    }} 
                                    disabled={nextMonthButtonDisabled}
                                    aria-label={lang === 'is' ? 'Næsti mánuður' : 'Next Month'}
                                    className="react-datepicker__navigation react-datepicker__navigation--next"
                                >
                                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.688.313A1.01 1.01 0 0 0 .593.092.984.984 0 0 0 0 1v8c0 .406.219.781.594.938a1.01 1.01 0 0 0 1.094-.22l4-4C5.875 5.532 6 5.282 6 5c0-.25-.125-.5-.313-.688l-4-4Z" fill="currentColor"/>
                                    </svg>
                                </button>
                            </div>
                        )}
                    />
                }
                {(meta.error && meta.touched)
                    ? <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="datepicker__icon icon-fa error-icon"
                    />
                    : <FontAwesomeIcon
                        icon={faCalendarDay}
                        className="datepicker__icon"
                    />
                }
            </div>
            {meta.error && meta.touched && <div className="datePicker-error-message ">{meta.error}</div>}
        </div>
    </>;
} 