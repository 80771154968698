// Package imports:
import React from 'react';
import cx from 'classnames';

interface IProps {
    data: number[]
}

const SmallColumnChart: React.FC<IProps> = ({
    data
}) => {
    const maximum = Math.max(...data);
    const minimum = Math.min(...data);
    const range = maximum >= 0 ? maximum - Math.min(0, minimum) : Math.abs(minimum);
    return (
        <div className='KCL_small-column-chart'>
            {data.map((value, index) => {
                let top: string | number;
                if (value >= 0) top = `${((maximum-value)/range)*100}%`;
                else if (maximum >= 0) top = `${(maximum/range)*100}%`;
                else top = 0;
                let bottom: string | number;
                if (value >= 0) {
                    if (minimum >= 0) bottom = 0;
                    else bottom = `${(Math.abs(minimum)/range)*100}%`;
                }
                else bottom = `${(Math.abs(minimum-value)/range)*100}%`;
                return <div
                    key={index}
                    className={cx('column', { 'negative': value < 0, 'positive': value > 0 })}
                    style={{
                        height: `${(Math.abs(value)/range)*100}%`,
                        top: top,
                        bottom: bottom
                    }}
                />
            })}
        </div>
    );
}

export default SmallColumnChart;