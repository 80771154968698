// Package imports:
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Component imports:
import AdRotator from "../../Ad/AdRotator";
import Alert from "../../../ui-elements/Alert/Alert";
import Button from "../../../ui-elements/Button/Button";
import DisplayBox from "../../../ui-elements/DisplayBox/DisplayBox";
import Loading from "../../../ui-elements/Loading/Loading";
import SmallSearch from "../../SmallSearch/SmallSearch";
import Table, { IColumn } from "../../../ui-elements/Table/Table";
import Tooltip from "../../../ui-elements/Tooltip/Tooltip";
import BulletList from "../../../ui-elements/Lists/BulletList";
import LockIcon from "../../../ui-elements/LockIcon/LockIcon";
import Censored from "../../../ui-elements/Censored/Censored";
import Fyrirvari from "../../Fyrirvari/Fyrirvari";
import Link from "../../../ui-elements/Link/Link";
// Service imports:
import { ErrorMessages } from "../../../services/errorMessages";
import { GET_API_LMD_URL, GET_KELDAN_API_URL } from "../../../services/config";
import {
    insertDash,
    quantityShorthandSplit,
    showValuation,
} from "../../../services/utils";
import { companies } from "./DefaultCompaniesForSearch";
import { pickRandomCompanies } from "../CompanySearchesPage/CompanySearchesPage";
// Type imports:
import { Fetched, IisAuthenticated } from "../../../types/Types";
import {
    ICompanySearchResults,
    ICompanySearchResultsItem,
} from "../../../types/SearchTypes";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { ICompanyValuation } from "../../../types/CompanyTypes";
// Context imports:
import { AccessTokenContext } from "../../../contexts/AccessTokenContext";

const CompanyVerdmat: React.FC<IisAuthenticated> = ({ isAuthenticated, isSubscriber }) => {
    const [disclaimerOpen, setDisclaimerOpen] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const term = urlParams.get("search");
    // Autocomplete request variables:
    const [searchInput, setSearchInput] = useState(term);
    const [randomCompanies] = useState<ICompanySearchResultsItem[]>(
        pickRandomCompanies(companies, 10)
    );
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] =
        useState<Fetched<ICompanySearchResultsItem[]>>(null);
    useEffect(() => {
        // Clear the previous timer if it exists
        let timerId: NodeJS.Timeout | null = null;

        const companySearch = async () => {
            if (searchInput === null || searchInput.length < 3) {
                setSearchResults(null);
                return;
            }

            try {
                setLoading(true);
                setSearchResults(null);

                const url = `${GET_KELDAN_API_URL()}/Company/SearchRskConnector?searchTerm=${encodeURIComponent(
                    searchInput.trim()
                )}`;
                const response = await fetch(url);

                if (response.ok) {
                    const body: ICompanySearchResults = await response.json();
                    const companies: ICompanySearchResultsItem[] = body.results ?? [];
                    setSearchResults(companies.length === 0 ? [] : companies);
                } else {
                    setSearchResults(new Error(ErrorMessages.RequestFailed));
                }
            } catch (error) {
                setSearchResults(new Error(ErrorMessages.NetworkError));
            } finally {
                setLoading(false);
            }
        };

        // Set a new timer to call the API after 1/4 second
        timerId = setTimeout(companySearch, 250);

        // Clear the timer when the component unmounts or when searchInput changes
        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [searchInput]);

    const searchResultRef = useRef<HTMLDivElement>(null);

    const columns: IColumn<ICompanySearchResultsItem>[] = [{
        title: "Nafn",
        renderCell: ({ name }: ICompanySearchResultsItem) => name ?? "",
        textAlign: "left",
    }, {
        title: "Kennitala",
        renderCell: ({ ssn }: ICompanySearchResultsItem) => insertDash(ssn),
        textAlign: "left",
    }, {
        title: "Virði eigin fjár",
        renderCell: ({ ssn }: ICompanySearchResultsItem) =>
            isAuthenticated && isSubscriber ? (
                <ValuationColumn ssn={ssn} />
            ) : (
                <>
                    <td className="align-right">
                        <Censored width="120px" />
                    </td>
                    <td className="align-right">
                        <Button size="sm" buttonType="secondary" disabled>
                            Skoða
                        </Button>
                    </td>
                </>
            ),
        textAlign: "right",
        overrideTd: true
    }, {
        title: "",
        renderCell: () => <></>,
        textAlign: "right",
        overrideTd: true
    }];

    const displayResult = useMemo(() => {
        if (loading || (!searchResults && searchInput?.trim())) {
            return <Loading />;
        }

        let data: ICompanySearchResultsItem[] | undefined;

        if (searchInput === null || searchInput === "" || searchInput.length < 3) {
            data = randomCompanies;
        } else if (searchResults instanceof Error) {
            return <Alert type={"alert"} headText={searchResults.message} />;
        } else if (searchResults === null || searchResults.length === 0) {
            return <h4>Engar leitarniðurstöður fundust fyrir "{searchInput}"</h4>;
        } else {
            data = searchResults;
        }
        const sortedData = [...data].sort((a, b) => {
            const nameA = a.name || "";
            const nameB = b.name || "";
            return nameA.localeCompare(nameB);
        });
        return <Table tableSize="lg" data={sortedData} columns={columns} />;
    }, [searchResults, loading]); // Only recompute the memoized value when searchResults or loading changes.

    const hasAccess = useMemo(() => isAuthenticated && isSubscriber, [isAuthenticated, isSubscriber]);

    const displaySearch = () => {
        return <SmallSearch
            disabled={!hasAccess}
            search={searchInput ?? ""}
            setSearch={(e) => {
                setSearchInput(e);
                window.history.pushState(
                    null,
                    "",
                    "/Fyrirtaeki/Leit?search=" + e + "#Verdmat"
                );
            }}
            inputSize="lg"
            placeHolder="Leitaðu eftir fyrirtækjum..."
            id="Search_page"
            onSubmit={() => {
                searchResultRef.current?.scrollIntoView();
            }}
        />
    }
    return (
        <div className="KCL_company-verdmat-tab">
            <div className="shell">
                {searchInput === null ||
                    searchInput === "" ||
                    searchInput.length < 3 ? (
                        <>
                            <h3 className="heading">
                                {!(isAuthenticated && isSubscriber) && <LockIcon size="lg" noMargin />}
                                Verðmat Keldunnar
                                <Fyrirvari
                                    show={disclaimerOpen}
                                    open={() => setDisclaimerOpen(true)}
                                    close={() => setDisclaimerOpen(false)}
                                >
                                    <p className="paragraph">
                                        Verðmatið er ekki fjárfestingarráðgjöf og skal ekki notað til
                                        ákvörðunartöku auk þess sem upplýsingar í verðmatinu kunna að vera
                                        rangar. Þetta verðmat er gert til fróðleiks, samanburðar og
                                        skemmtunar en ekki til þess að sýna endanlegt sölu- eða kaupverð
                                        félags.
                                        <br />
                                        Keldan ehf. ber enga ábyrgð á röngum upplýsingum eða fjárhagstjóni
                                        sem kann að verða vegna verðmatsins.
                                    </p>
                                </Fyrirvari>
                            </h3>
                            <p className="paragraph">
                                Verðmat Keldunnar byggir á núvirtu áætluðu sjóðstreymi til eilífðar í
                                íslenskum krónum.
                            </p>
                            <Link
                                url="/Sersnidid-Verdmat"
                                icon={"forward"}
                                linkSize="16"
                            >
                                Sérsniðið verðmat
                            </Link>
                            <Link
                                url="/Markadir/Fjarmalahugtok#verdmat-fyrirtaekja"
                                icon={"forward"}
                                linkSize="16"
                            >
                                Nánar um verðmat í Fjármálahugtökum
                            </Link>
                        </>
                    ) : (
                        <h3>Leitarniðurstöður fyrir "{searchInput}"</h3>
                    )
                }
            </div>
            <div className="content_wrapper">
                <div className="shell">
                    <div className="result_wrapper">
                        <DisplayBox>
                            {hasAccess
                                ? displaySearch()
                                : <Tooltip
                                    text={<>{displaySearch()}</>}
                                    tooltip={<>Þú þarft að skrá þig inn og vera í áskrift til að nýta þessa þjónustu.</>}
                                />
                            }
                            <div className="table-section" ref={searchResultRef}>
                                {displayResult}
                            </div>
                        </DisplayBox>
                    </div>
                    <AdRotator location="Market310x400" />
                </div>
            </div>
        </div>
    );
};

export default CompanyVerdmat;

const ValuationColumn: React.FC<{ ssn: string | null }> = ({ ssn }) => {
    const accessToken = useContext(AccessTokenContext);
    const [data, setData] = useState<ICompanyValuation | null | Error>(null);
    const [activeTab, setActiveTab] = useState(0);

    const skodaButton: JSX.Element = (
        <Button buttonType="secondary" size="sm" anchorProps={{href: `/Fyrirtaeki/Verdmat/${ssn}#FrjalstSjodstreymi`}}>
            Skoða
        </Button>
    );

    const displayError = (error: string) => {
        return (
            <>
                <td></td>
                <td className="align-right" style={{ paddingRight: '29px', verticalAlign: 'bottom' }}>
                    <Tooltip
                        width={21}
                        noUnderline
                        text={<FontAwesomeIcon style={{width: '20px', height: '20px'}} icon={faInfoCircle} />}
                        tooltip={
                            <>
                                <span className="paragraph-xsmall">
                                    Keldan styðst við ýmsar forsendur við gerð verðmatsins.
                                    <br />
                                    Eftirfarandi ástæður gætu valdið því að ekki tekst að verðmeta
                                    fyrirtæki:
                                </span>
                                <BulletList
                                    size="xsm"
                                    blackMarker
                                    items={[
                                        "Ársreikningar eru of gamlir eða of fáir.",
                                        "Gögn vantar við útreikning ávöxtunarkröfu.",
                                        "Gögn vantar við útreikning frjáls sjóðstreymis.",
                                        "Atvinnugrein fyrirtækis bendir til þess að verðmatsaðferð eigi ekki við.",
                                        "Niðurstöður verðmats eru utan þess sem eðlilegt gæti talist.",
                                        "Ársreikningar eru í öðrum gjaldmiðli en ISK.",
                                    ]}
                                />
                            </>
                        }
                    />
                </td>
            </>
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            const url = `${GET_API_LMD_URL()}/v1/market_data/v1/company_valuation/${ssn}/valuation/v2`;
            fetch(url, {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((res) => {
                if (res.ok) return res.json();
                else if (res.status === 404)
                    throw new Error(ErrorMessages.NoDataFound);
                else throw new Error(ErrorMessages.RequestFailed);
            })
            .then((resBody) => {
                setData(resBody);
            })
            .catch((err) => {
                if (err instanceof Error) setData(err);
                else setData(new Error(ErrorMessages.RequestFailed));
            });
        };
        setData(null);
        if (accessToken) fetchData();
    }, [accessToken, ssn]);

    const { displayNumberFCF, displayUnitFCF } = useMemo(() => {
        if (data instanceof Error || data === undefined || data == null) {
            return { displayNumberFCF: null, displayUnitFCF: null };
        }
        const { number, unit } = quantityShorthandSplit(data.equity_valuation, 0);
        return { displayNumberFCF: number, displayUnitFCF: unit };
    }, [data]);

    const { displayNumberEBITDA, displayUnitEBITDA, displayCurrency } = useMemo(() => {
        if (data instanceof Error || data === undefined || data == null) {
            return {
                displayNumberEBITDA: null,
                displayUnitEBITDA: null,
                displayCurrency: null,
            };
        }
        const { number, unit } = quantityShorthandSplit(
            data.ebitda_equity_valuation,
            0
        );
        return {
            displayNumberEBITDA: number,
            displayUnitEBITDA: unit,
            displayCurrency: data.currency,
        };
    }, [data]);

    const shouldShowValuation = useMemo(() => {
        if (data instanceof Error) return "error";
        if (data === null) return "loading";
        return showValuation(data);
    }, [data]);

    useEffect(() => {
        if (shouldShowValuation !== "show" && shouldShowValuation !== "loading") {
            setActiveTab(1);
        }
    }, [shouldShowValuation]);

    const getFCFTab = () => {
        if (shouldShowValuation === "negative-fcf") {
            return displayError("Fyrirtæki er með neikvætt frjálst sjóðstreymi");
        }
        if (shouldShowValuation !== "show") {
            return displayError("Ekki tókst að verðmeta fyrirtæki");
        }
        return displayResult(`${displayNumberFCF}${displayUnitFCF} ${displayCurrency}`);
    };

    const getEBITDATab = () => {
        if (
            data instanceof Error ||
            data === null ||
            data.ebitda === null ||
            data.ebitda_multiple === null ||
            data.ebitda_equity_valuation === null ||
            data.ebitda_equity_valuation <= 0
        ) {
            return displayError("Ekki tókst að verðmeta fyrirtæki");
        }
        if (data.ebitda <= 0) {
            return displayError("Fyrirtæki er með neikvætt EBITDA");
        }
        return displayResult(
            `${displayNumberEBITDA}${displayUnitEBITDA} ${displayCurrency}`
        );
    };

    const displayResult = (result: string) => {
        return (
            <>
                <td className="align-right">
                    {result}
                </td>
                <td className="align-right">
                    {skodaButton}
                </td>
            </>
        );
    };

    const displayValuation = useMemo(() => {
        if (data === null) {
            return <>
                <td className="align-right">
                    <Loading width="120" count={1} />
                </td>
                <td className="align-right">
                    <Loading width="70" count={1} />
                </td>
            </>;
        }
        return activeTab === 0 ? getFCFTab() : getEBITDATab();
    }, [activeTab, data]);

    return displayValuation;
};
