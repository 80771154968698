// Package imports:
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import Label from '../../../ui-elements/Label/Label';
import Link from '../../../ui-elements/Link/Link';
import Loading from '../../../ui-elements/Loading/Loading';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Button from '../../../ui-elements/Button/Button';
import ExampleReportButton from '../../ExampleReportButton/ExampleReportButton';
import Censored from '../../../ui-elements/Censored/Censored';
import { CompanyDetailedOverviewWrapper } from '../ViewMembersReport/ViewMembersReport';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { useBuyingProcess } from '../../../services/buyhook';
import { ErrorMessages } from '../../../services/errorMessages';
import { insertDash } from '../../../services/utils';
// Type imports:
import { Fetched } from '../../../types/Types';
import { IKeldanApiResponse, OwnedReport } from '../../../types/KeldanTypes';
import { BoardMember, ICompanyViewModel } from '../../../types/CompanyTypes';

const limit = 100;

const CompanyMembers: React.FC<ICompanyViewModel & {large?: boolean}> = ({
    id,
    isAuthenticated,
    isSubscriber,
    large
}) => {
    const [ data, setData ] = useState<Fetched<OwnedReport[]>>([]);
    const [ prokuruhafar, setProkuruhafar] = useState<Fetched<BoardMember[] | null> | undefined >(undefined);
    const [ eventId, setEventId ] = useState<number | null>(null);
    useEffect(() => {
        //this is to prevent some random error with some companies that retrigger multiple re-renders. Couldnt find out why.
        let counter = 1;
        const fetchProkuruhafa = async () => {
            try {
                counter++;
                setProkuruhafar(null);

                const url = `${GET_KELDAN_API_URL()}/Company/GetMemberReport/${id}?eventId=${eventId}`;
                const response = await fetch(url);
                if (!response.ok) {
                    setProkuruhafar(new Error(ErrorMessages.RequestFailed));
                    return;
                }
                if (response.redirected) {
                    window.location.href = response.url;
                    return;
                }
                const responseBody: IKeldanApiResponse<CompanyDetailedOverviewWrapper> = await response.json();
                if (responseBody.success && responseBody.result) {
                    if(responseBody.result.overview.procuration !== null) setProkuruhafar(responseBody.result.overview.procuration);
                    else setProkuruhafar(undefined);
                } else if (responseBody.message) {
                    setProkuruhafar(new Error(responseBody.message));
                } else {
                    setProkuruhafar(new Error(ErrorMessages.OtherShort));
                }
            } catch (e) {
                setProkuruhafar(new Error(ErrorMessages.NetworkError));
            }
        }
        if(eventId !== null && eventId > 0 && counter === 1) fetchProkuruhafa();
    }, [eventId]);

    useEffect(() => {
        const fetchData = async () => {
            // trigger loading
            setData(null)
    
            try {
                const url = (large) 
                    ? `${GET_KELDAN_API_URL()}/Company/GetCompanyMembersReports/?id=${id}&limit=${limit}`
                    : `${GET_KELDAN_API_URL()}/Company/GetCompanyMembersReports/${id}`;
                const headers = {
                    'Content-Type': 'application/json; charset=utf-8'
                };
                const response = await fetch(url, {
                    method: 'GET',
                    headers
                });
                if (!response.ok) {
                    setData(new Error(ErrorMessages.RequestFailed));
                    return;
                }
                if (response.redirected) {
                    window.location.href = response.url;
                }
                const responseBody: IKeldanApiResponse<OwnedReport[]> = await response.json();
                if (responseBody.success) {
                    if (responseBody.result) setData(responseBody.result);
                    if(responseBody.result && responseBody.result.length > 0) {
                        //find the max balls value -> newest report
                        const maxBalls = Math.max(...responseBody.result.map(x => x.balls));
                        setEventId(maxBalls);
                    }
                } else {
                    const { message } = responseBody;
                    if (message) {
                        setData(new Error(message));
                    } else {
                        setData(new Error(ErrorMessages.ErrorInRequest));
                    }
                }
            } catch (e) {
                setData(new Error(ErrorMessages.OtherShort));
            }
        }
        if (isAuthenticated) fetchData();
    }, [isAuthenticated]);

    const { tryPurchaseItem, isItemLoading, resetState, ModalsAndToasts } = useBuyingProcess(async (_, reference: string, withOwners) => {
        try {
            const baseurl = `${GET_KELDAN_API_URL()}/Fyrirtaeki/Hlutafelagaskra-Kaupa/${id}`;
            const query = new URLSearchParams({
                'addresses': 'false',
                'owners': 'false',
                'json': 'true',
                'reference': reference
            });

            if (withOwners) query.set('owners', 'true');

            const url = `${baseurl}?${query.toString()}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            });

            if (!response.ok) {
                resetState('response not ok');
                return;
            }
            if (response.redirected) {
                window.location.href = response.url;
                return;
            }
            const responseBody: IKeldanApiResponse<OwnedReport[]> = await response.json();
            if (responseBody.success) {
                if (responseBody.result) setData(responseBody.result);
            } else {
                const { message } = responseBody;
                if (message === ErrorMessages.NoCard) {
                    resetState('no card');
                } else if (message) {
                    resetState({ headText: message, type: 'alert' });
                } else {
                    resetState('bad request');
                }
            }
        } catch (e) {
            resetState('network error');
        }
    }, 'hlutafélagaskrá');

    const formatDate = (date: string) => {
        const newDate = date.split(' ')[0].split('/');
        if (newDate.length === 3) {
            return `${newDate[0].padStart(2, '0')}.${newDate[1].padStart(2, '0')}.${newDate[2].substring(2)}`;
        }
        return '-';
    }

    const getPurchaseButton = () => {
        if (isSubscriber) {
            return (
                <Button
                    size='sm'
                    buttonType='primary'
                    anchorProps={{href: `/Fyrirtaeki/Hlutafelagaskra-Kaupa/${id}?owners=true`}}
                >
                    Sækja
                </Button>
            );
        }
        if (isAuthenticated) {
            return (
                <Button
                    showLoader={isItemLoading(id)}
                    size='sm'
                    buttonType='buy'
                    onClick={() => {tryPurchaseItem(id, 'confirm-modal-with-reference-and-owners')}}
                >
                    Kaupa
                </Button>
            );
        }
        return (
            <Button
                size='sm'
                buttonType='buy'
                anchorProps={{href: `/Innskraning?ReturnUrl=${window.location.pathname}`}}
            >
                Kaupa
            </Button>
        );
    }
    const displayOwnedReports = () => {
        if (data instanceof Error) {
            return <Alert type='error' headText={data.message} />;
        }
        if (data === null) {
            return <Loading />;
        }
        if (!isAuthenticated) {
            return null;
        }
        
        return data.sort((a, b) => b.balls - a.balls).slice(0, (large) ? limit : 1).map((item) => (
            <div key={item.balls} className={cx('company-members-line', {'large': large})}>
            <span className='company-members-line-text'>
                Skráning {isSubscriber ? 'sótt:' : 'keypt:'} {formatDate(item.date)}
            </span>
            <Button
                size='sm'
                buttonType='secondary'
                anchorProps={{href: `/Fyrirtaeki/Hlutafelagaskra-Skoda/${id}?eventId=${item.balls}`}}
            >
                Skoða
            </Button>
            </div>
        ))
    }
    const displayProkuruUmbod = () => {
        const displayItem = () => {
            if(prokuruhafar === undefined) {
                return <></>
            } else if(prokuruhafar instanceof Error) {
                return <Alert type='error' headText={prokuruhafar.message} />;
            } else if(prokuruhafar === null) {
                return <div style={{marginTop: '5px'}}><Loading count={2}/></div>;
            } else {
                return prokuruhafar.map((item) => (
                    <span className='paragraph-xsmall' key={item.ssn}>
                        {
                            `${item.name}, ${insertDash(item.ssn)}`
                        }
                    </span>
                ))
            }
        }

        if(!isAuthenticated) return <div className={cx('company-prokuruumbod censored', {'large': large})}>
            <strong><span className='company-members-line-text' style={{marginBottom: '5px'}}>Prókúruumboð</span></strong>
                <Censored width='40%'/>
                <Censored width='50%'/>
        </div>
        
        if(prokuruhafar === undefined) return null;
        return <div className={cx('company-prokuruumbod', {'large': large})}>
            <strong><span className='company-members-line-text' style={{marginBottom: '5px'}}>Prókúruumboð</span></strong>
            {
                displayItem()
            }
        </div>
    }

    return (
        <DisplayBox 
            className='KCL_company-members'
            title="Hlutafélagaskrá"
            description='Nýjustu upplýsingar um prókúruhafa, framkvæmdastjóra, stjórn og fleira.'
            asideComponent={<Label labelType='origin' text='RSK' />}
            footerLeft={!large
                ? <Link
                    linkSize='14'
                    url={`/Fyrirtaeki/Hlutafelagaskra/${id}`}
                    className='more-link'
                    icon='forward'
                >
                    Eldri skráningar
                </Link>
                : undefined
            }
            footerRight={!large
                ? <ExampleReportButton size='sm' reportType='company-members' />
                : undefined
            }
            >   
            <div>
                <div className={cx('company-members-line', {'large': large})}>
                    <div>
                        <strong><span className='company-members-line-text'>Gildandi skráning (KYC)</span></strong>
                        {
                            isSubscriber && <p className='paragraph-xsmall'>með raunverulegum eigendum</p>
                        }
                    </div>
                    {getPurchaseButton()}
                </div>
                {displayOwnedReports()}
                {!large && displayProkuruUmbod()}
            </div>
            <ModalsAndToasts />
        </DisplayBox>
    );
}

export default CompanyMembers;