// Package imports:
import ContentLoader from "react-content-loader"

const Loading:React.FC<{count?: number, width?: string}> = ({count = 4, width = "100%"},props: any) => 
    <ContentLoader 
        speed={2}
        width={width}
        height={(count * 30) - 15}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        {
            Array.from({ length: count }).map((_, index) => (
                <rect
                    key={index}
                    x="0"
                    y={index * 30}
                    rx="8"
                    ry="8"
                    width={index === count - 1 && count > 1 ? "50%" : "100%"}
                    height="15"
                />
            ))
        }
    </ContentLoader>

export default Loading;