// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import ViewConstructionProfilePage from '../../components/Okutaeki/ConstructionsSearch/ViewConstructionProfile';
// Type imports:
import { ConstructionViewModel } from '../../types/VehicleTypes';

const ViewConstructionProfile: React.FC = ({...props}) => {
    return (
        <Entrypoint>
            <ViewConstructionProfilePage model={props as ConstructionViewModel}/>
        </Entrypoint>
    );
}

export default ViewConstructionProfile;