// Package imports:
import { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
// Service imports:
import { getNumberOfPages } from './utils';

export const usePageCounter = () => {
    const [ totalPages, setTotalPagesState] = useState<number | null>(null);
    const [ currentPageIndex, setCurrentPageIndex ] = useState(0);
    const [ itemsPerPage, setItemsPerPage ] = useState(10);
    const [ itemCount, setItemCount ] = useState<number | null>(0);

    const goToNextPage = () => {
        if (totalPages === null) return;
        const nextPage = Math.min((currentPageIndex + 1), totalPages - 1);
        setCurrentPageIndex(nextPage);
    }

    const goToPreviousPage = () => {
        const previousPage = Math.max((currentPageIndex - 1), 0);
        setCurrentPageIndex(previousPage);
    }

    const setTotalPages = (totalCountOfItems: number | null, itemsPerPage: number) => {
        if (totalCountOfItems === null) {
            setTotalPagesState(null);
        } else {
            const newTotalPages = getNumberOfPages(totalCountOfItems, itemsPerPage);
            setTotalPagesState(newTotalPages);
        }
    }

    const resetPageCounter = () => setCurrentPageIndex(0);

    useEffect(() => {
        resetPageCounter();
    }, [itemsPerPage]);

    useEffect(() => {
        setTotalPages(itemCount, itemsPerPage);
    }, [itemCount, itemsPerPage]);

    const isNextPageDisabled = useMemo(() => {
        if (totalPages === null) return true;
        return ((currentPageIndex + 1) >= totalPages)
    }, [ currentPageIndex, totalPages]);

    const isPrevPageDisabled = useMemo(() => {
        return (currentPageIndex === 0)
    }, [ currentPageIndex ]);
    
    const PageCounter: React.FC = () => {
        return (
            <div className="KCL_pagination">
                <FontAwesomeIcon  
                    className={cx('pagination_angle', 'prev-page', {'disabled': isPrevPageDisabled})} 
                    icon={faAngleLeft}
                    onClick={isPrevPageDisabled ? undefined : goToPreviousPage}
                />
                <span className="pagination_text">síða {currentPageIndex + 1} af {totalPages ?? '...'}</span>
                <FontAwesomeIcon  
                    className={cx('pagination_angle', 'next-page', {'disabled': isNextPageDisabled})} 
                    icon={faAngleRight}
                    onClick={isNextPageDisabled ? undefined : goToNextPage}
                />
            </div>
        )
    }

    return {
        currentPageIndex,
        itemsPerPage,
        setItemsPerPage,
        itemCount,
        setItemCount,
        totalPages,
        setTotalPages,
        resetPageCounter,
        goToNextPage,
        goToPreviousPage,
        PageCounter
    }
}

type FetchFunc = (accessToken?: string, refreshRateMs?: number) => void;
export const useRefreshRateFetching = (fetchFunc: FetchFunc, refreshRateMs: number | undefined, accessToken: string | undefined, initialFunc?: FetchFunc, noRefreshRateFunc?: FetchFunc, allowFetchWithNoToken?: boolean) => {
    useEffect(() => {
        if (allowFetchWithNoToken || accessToken) {
            // If refresh rate, start fetching on an interval
            if (refreshRateMs) {
                const intervalId = window.setInterval(() => {
                    fetchFunc(accessToken, refreshRateMs);
                }, refreshRateMs);
                // Initial fetchData function.
                if (initialFunc === undefined) fetchFunc(accessToken, refreshRateMs);
                else initialFunc(accessToken, refreshRateMs);
                // Clean up: clear interval to avoid memory leaks
                return () => window.clearInterval(intervalId);
            }
            // No refresh rate. Call 1 time.
            else {
                if (noRefreshRateFunc === undefined) fetchFunc(accessToken, refreshRateMs);
                else noRefreshRateFunc(accessToken, refreshRateMs);
            }
        } else {
            console.warn('No token in useRefreshRateFetching')
        }
    }, [ accessToken, refreshRateMs ]);
}


export const useDebug = (name: string, value: any) => {
    useEffect(() => {
        console.log(`debug ${name} change: `)
        console.log(value)
    }, [ value ])
}

export default function useComponentVisible() {
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event: MouseEvent) => {
    //@ts-ignore
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, !isComponentVisible);

    return () => {
      document.removeEventListener(
        "click",
        handleClickOutside,
        !isComponentVisible
      );
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

export const useStateRef = <T extends unknown>(stateVariable: T) => {
    const ref = useRef(stateVariable);
    useEffect(() => { ref.current = stateVariable }, [ stateVariable ]);
    return ref;
}