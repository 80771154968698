// Package imports:
import React from 'react';
// Component imports:
import ViewCompanyCompareBody from './ViewCompanyCompareBody';
// Type imports:
import { IViewCompanyCompare } from '../../../types/CompanyTypes';
// Static data imports:
import EXAMPLE_DATA from '../../../assets/staticData/ExampleReports/Company/CompanyCompare.json';

const CompanyCompareExample: React.FC = () => {
    return <ViewCompanyCompareBody id={'5101050510'} data={EXAMPLE_DATA as IViewCompanyCompare} isExample />
}

export default CompanyCompareExample;