// Package imports:
import React, { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
// Component imports:
import NewsItem from '../NewsItem';
import Link from '../../../ui-elements/Link/Link';
import AdRotator from '../../Ad/AdRotator';
import Loading from '../../../ui-elements/Loading/Loading';
import SmallSearch from '../../SmallSearch/SmallSearch';
import Filter from '../../../ui-elements/Filter/Filter';
import Alert from '../../../ui-elements/Alert/Alert';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';
// Service imports:
import { usePageCounter, useStateRef } from '../../../services/hooks';
import { getNumberOfPages, sortIcelandic, updateDoubleMapValue } from '../../../services/utils';
import { GET_NEWS_LMD_URL } from '../../../services/config';
import { getSourceListFromSourceString, getSourceStringFromSourceList } from '../../../services/newsUtils';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Fetched, IDefaultProps } from '../../../types/Types';
import { INewsLmdNewsItem, INewsLmdResponse, INewsFeedCategoryDetails, INewsFeedSourceFilter, NewsItemFeedIdMapToBoolean, SearchInputToSourceFilterToResultsDoubleMap, SearchResultsInfo, SourceFilterToResultsMap, INewsFeedSourcesResponse } from '../../../types/NewsTypes';

const ITEMS_TO_DISPLAY_PER_PAGE = 25;
const INITIAL_REFRESH_RATE_NEWS_ITEMS_TO_FETCH = 20;
const NEWS_ITEMS_FETCH_ON_INDEX_CHANGE = 50;

const MINIMUM_LETTER_SEARCH = 3;

type UsingSourceFilter = 'all' | 'some' | 'none'

interface IOwnProps {
    // setHasNew(hasNewNewsItems: boolean): void,
    newsFeedCategoryDetails: INewsFeedCategoryDetails
}

type Props = IDefaultProps & IOwnProps;

/**
 * HINT: Use ctrl+shift+p: > "Fold Level 2", if you're starting out here.
 *
 * At first the initial news batch is fetched.
 * The newsitems that are displayed for the user are stored in the defaultNewsItems state variable.
 * Then, every [refreshRateMs] milliseconds, More news are fetched.
 * - Since we don't know how many new newsItems have been added, we start by fetching [INTIAL_REFRESH_RATE_NEWS_ITEMS_TO_FETCH].
 * - Then we double this number until the new news batch includes the first newsitem of the already-fetched news.
 * The new news should only be added to the defaultNewsItems if the user is on the first page.
 * - If the user is scrolling through old news, we don't want the list to shift around due to the new arrivals.
 * - If the user is on an old page, the news news items are added to the state variable newNewsWaitingQueue.
 * When the user is going through old news, we requested by searching from the timestamp of the oldest fetched news (last in the defaultNewsItems array)
 * - We fetch [NEWS_ITEMS_FETCH_ON_INDEX_CHANGE] of news and append it to the end of defaultNewsItems.
 *
 *
 * Additionally we have maps mapping the newsId to boolean, for if any given news item is "new" or "read".
 * - Default is: not new, and not read.
 *
 * Since the state can be confusing, all state variables have been commented with their purpose.
 */
const News: React.FC<Props>= ({
    // setHasNew,
    newsFeedCategoryDetails,
    refreshRateMs
}) => {
    /* News Storage States: */
    // The list of "default" news items that the user can see.
    // "Default" meaning non-filtered, non-searched news. Simply the most recent news.
    const [ defaultNewsItems, setDefaultNewsItems ] = useState<INewsLmdNewsItem[] | null>(null);
    // When the user is:
    // - not filtering by source AND
    // - not searching by word AND
    // - on page 1.
    // Then new news should automatically be added to defaultNewsItems.
    // HOWEVER,
    // When the user does NOT meet those conditions, we must store the new news items in the newNewsWaitingQueue.
    // This list gets added to the front of defaultNewsItems when the user meets ALL coniditions again.
    const [ defaultNewsItemsWaitingQueue, setDefaultNewsItemsWaitingQueue ] = useState<INewsLmdNewsItem[]>([]);
    // Search word results map:
    // When user filters by source only, and not by word:
    // - then the first key (the word) is "" (the empty string).
    // When the user filters by word only, and not by source:
    // -> then the second key (sourceFilterString) is "" (the empty string).
    // When the user filters by both, then use both keys: searchResultsDoubleMap?.[searchInput]?.[sourceFilterString];
    const [searchResultsDoubleMap, setSearchResultsDoubleMap] = useState<SearchInputToSourceFilterToResultsDoubleMap>({});

    /* News Filtering States: */
    // Sources used to filter by.
    const [sourceFilters, setSourceFilters] = useState<Fetched<INewsFeedSourceFilter[]>>(null);
    // Search word
    const [searchInput, setSearchInput] = useState('');

    /* News to Boolean maps: */
    // IsSeen news = news that has been clicked on/opened
    const [ newsItemFeedIdToIsSeenMap, setNewsItemFeedIdToIsSeenMap ] = useState<NewsItemFeedIdMapToBoolean>({});
    // IsNew refers to the yellow "bolla" next to the news item.
    const [ newsItemFeedIdToIsNewMap, setNewsItemFeedIdToIsNewMap ] = useState<NewsItemFeedIdMapToBoolean>({});
    // IsHighlighted refers to the yellow highlighted hue that very new news has.
    const [ newsItemFeedIdToIsHighlightedMap, setNewsItemFeedIdToIsHighlightedMap ] = useState<NewsItemFeedIdMapToBoolean>({});

    /* Display helper states: */
    const [ lastDisplayableData, setLastDisplayableData ] = useState<INewsLmdNewsItem[] | null>(null);

    const getSliceOfDataGivenPageIndex = (index: number, dataArray: Fetched<INewsLmdNewsItem[]> = defaultNewsItems) => {
        if (dataArray === null || dataArray instanceof Error) return {
            dataSlice: [],
            isSliceFullLength: false,
        }
        const dataSlice = dataArray.slice(index*ITEMS_TO_DISPLAY_PER_PAGE, (index+1)*ITEMS_TO_DISPLAY_PER_PAGE);
        return {
            dataSlice,
            isSliceFullLength: (dataSlice.length === ITEMS_TO_DISPLAY_PER_PAGE)
        }
    }

    /* Overhead News States: */
    // Page index variables.
    const {
        currentPageIndex,
        totalPages: totalPagesForDefault, // Renamed. When searching, the total pages are fetched from the Double Map.
        setTotalPages: setTotalPagesForDefault, // Renamed. When searching, the total pages are fetched from the Double Map.
        resetPageCounter,
        goToNextPage,
        goToPreviousPage
    } = usePageCounter();
    // Any error if it would occur
    const [newsError, setNewsError] = useState<Error | null>(null);

    /* MEMO */
    const usingSourceFilter: UsingSourceFilter = useMemo(() => {
        if (sourceFilters === null || sourceFilters instanceof Error) return 'all';
        if (sourceFilters.every(sourceFilter => !sourceFilter.isOn)) return 'none';
        if (sourceFilters.every(sourceFilter => sourceFilter.isOn)) return 'all';
        return 'some';
    }, [ sourceFilters ]);
    
    const usingSearchWord = useMemo(() => {
        return searchInput.length > MINIMUM_LETTER_SEARCH
    }, [ searchInput ]);

    const usingDefaultNews = useMemo(() => {
        return (!usingSearchWord && usingSourceFilter === 'all');
    }, [ usingSearchWord, usingSourceFilter ]);

    const { dataToDisplay, isCompleteSlice } = useMemo(() => {
        const loadingState = {
            dataToDisplay: null,
            isCompleteSlice: false
        };
    
        const getResults = (dataSlice: INewsLmdNewsItem[], isSliceFullLength: boolean, numberOfPages: number | null, isOnLastPage: boolean) => {
            return {
                dataToDisplay: dataSlice,
                isCompleteSlice: isSliceFullLength || isOnLastPage || (numberOfPages === 0)
            };
        };
    
        const filterBySourceAndSearch = (searchResults: SearchResultsInfo | undefined) => {
            if (searchResults === undefined) return loadingState;
    
            const { dataSlice, isSliceFullLength } = getSliceOfDataGivenPageIndex(currentPageIndex, searchResults.results);
            const numberOfPages = getNumberOfPages(searchResults.totalCount, ITEMS_TO_DISPLAY_PER_PAGE);
            const isOnLastPage = ((currentPageIndex + 1) === numberOfPages);
    
            return getResults(dataSlice, isSliceFullLength, numberOfPages, isOnLastPage);
        };
    
        if (usingSourceFilter === 'none') return loadingState;
    
        if (!usingSearchWord) {
            if (usingSourceFilter === 'all') {
                if (defaultNewsItems === null) return loadingState;
    
                const { dataSlice, isSliceFullLength } = getSliceOfDataGivenPageIndex(currentPageIndex);
                const numberOfPages = totalPagesForDefault;
                const isOnLastPage = ((currentPageIndex + 1) === numberOfPages);
    
                return getResults(dataSlice, isSliceFullLength, numberOfPages, isOnLastPage);
            } else {
                const sourceFilterString = getSourceStringFromSourceList(sourceFilters, 'on');
                return filterBySourceAndSearch(searchResultsDoubleMap?.['']?.[sourceFilterString ?? '']);
            }
        } else {
            if (usingSourceFilter === 'all') {
                return filterBySourceAndSearch(searchResultsDoubleMap?.[searchInput]?.['']);
            } else {
                const sourceFilterString = getSourceStringFromSourceList(sourceFilters, 'on');
                return filterBySourceAndSearch(searchResultsDoubleMap?.[searchInput]?.[sourceFilterString ?? '']);
            }
        }
    }, [ sourceFilters, searchInput, searchResultsDoubleMap, defaultNewsItems, currentPageIndex, totalPagesForDefault, usingSearchWord ]);

    const totalPages = useMemo(() => {
        if (usingSourceFilter === 'none') return null;

        if (usingDefaultNews) return totalPagesForDefault ?? null;

        const sourceFilterString = (usingSourceFilter === 'all')
            ? ''
            : getSourceStringFromSourceList(sourceFilters, 'on');
        if (sourceFilterString === null) return null;
        const searchResults = searchResultsDoubleMap?.[searchInput]?.[sourceFilterString];
        if (searchResults === undefined) return null;
        return getNumberOfPages(searchResults.totalCount, ITEMS_TO_DISPLAY_PER_PAGE);
    }, [ usingDefaultNews, searchResultsDoubleMap, searchInput, sourceFilters, totalPagesForDefault, usingSourceFilter ]);

    const localStorageKey = useMemo(() => {
        return `KELDAN_NEWS_SETTINGS_${newsFeedCategoryDetails.category}_OFF`;
    }, [newsFeedCategoryDetails]);

    /* REFS */
    // Interval functions can't read state correctly. Needs ref's instead.
    const defaultNewsItemsRef = useStateRef(defaultNewsItems)
    const defaultNewsItemsWaitingQueueRef = useStateRef(defaultNewsItemsWaitingQueue);
    const currentPageIndexRef = useStateRef(currentPageIndex);
    const newsItemFeedIdToIsNewMapRef = useStateRef(newsItemFeedIdToIsNewMap);
    const newsItemFeedIdToIsHighlightedMapRef = useStateRef(newsItemFeedIdToIsHighlightedMap);
    const usingDefaultNewsRef = useStateRef(usingDefaultNews);
    const searchResultsDoubleMapRef = useStateRef(searchResultsDoubleMap);
    const sourceFiltersRef = useStateRef(sourceFilters);
    const usingSearchWordRef = useStateRef(usingSearchWord);

    // Ref for timeout id.
    const timeoutFunctionIdRef = useRef<number | null>(null);

    /* EFFECTS AND FUNCTIONS */
    // Update lastDisplayableData to last viable data.
    useEffect(() => { if (dataToDisplay !== null) setLastDisplayableData(dataToDisplay) }, [dataToDisplay]);

    // Reset page counter on search input change
    useEffect(() => {
        if (currentPageIndex !== 0) resetPageCounter();
    }, [ searchInput, sourceFilters ]);

    // Update defaultNewsItems and newNewsWaitingQueue when the user returns to the default state.
    useEffect(() => {
        if (defaultNewsItemsWaitingQueue.length > 0 && usingDefaultNews && currentPageIndex === 0 && Array.isArray(defaultNewsItems)) {
            setDefaultNewsItems([...defaultNewsItemsWaitingQueue, ...defaultNewsItems]);
            setDefaultNewsItemsWaitingQueue([]);
        }
    }, [ usingDefaultNews, currentPageIndex ])

    // useEffect(() => setHasNew((newNewsWaitingQueue.length !== 0)), [newNewsWaitingQueue]);

    // Update boolean maps when opening news item.
    const newsItemOnOpen = (newsItem: INewsLmdNewsItem) => {
        const { id } = newsItem;
        if (id === null) return;
        // Set to "not new" if new
        setNewsItemFeedIdToIsNewMap({
            ...newsItemFeedIdToIsNewMap,
            [id]: false
        })
        setNewsItemFeedIdToIsHighlightedMap({
            ...newsItemFeedIdToIsHighlightedMap,
            [id]: false
        })
        // Set to seen if not seen
        setNewsItemFeedIdToIsSeenMap({
            ...newsItemFeedIdToIsSeenMap,
            [id]: true
        })
    }

    // Fetch helper function
    const fetchNews = async (
        numberOfNewsItemsToFetch: number,
        onSuccess: (newsItems: INewsLmdNewsItem[], totalCount: number) => void,
        onFail: (err: Error) => void,
        startPublishDate?: number,
        searchQuery?: string,
        sourceFilterString?: string
    ) => {
        // Fetch items
        let baseUrl = GET_NEWS_LMD_URL();
        const urlSearchParams = new URLSearchParams({
            start: '0',
            limit: numberOfNewsItemsToFetch.toString(),
        });
        if(searchQuery) urlSearchParams.set('SortByField', 'score');
        // Search query has special URL ending.
        if (searchQuery || sourceFilterString) {
            baseUrl += '/search/query';
            urlSearchParams.set('f', 'title;body');
            urlSearchParams.set('q', searchQuery?.toLowerCase() ?? '*');
            if (sourceFilterString) {
                urlSearchParams.set('filter', `feedMeta.feedSymbol=${sourceFilterString}`);
            } else {
                urlSearchParams.set('filter', `topics=${newsFeedCategoryDetails.category}`);
            }
        }
        // If not search query, get the default url
        else {
            baseUrl += '/search/keldan';
            urlSearchParams.set('category', newsFeedCategoryDetails.category)
            if (startPublishDate) {
                urlSearchParams.set('startPublishDate', startPublishDate.toString())
            }
        }

        const url = `${baseUrl}?${urlSearchParams.toString()}`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.ok) return res.json();
            else throw new Error(ErrorMessages.RequestFailed);
        })
        .then((resBody: INewsLmdResponse) => {
            if (resBody === null || resBody.feedItems === null) throw new Error('Server did not return any news.')
            let removeFutureNews = resBody.feedItems.filter(x => x.publish_timestamp === null || x.publish_timestamp <= Date.now());
            onSuccess(removeFutureNews, resBody.totalCount);
        })
        .catch(err => {
            if (err instanceof Error) {
                onFail(err);
            } else {
                onFail(new Error(ErrorMessages.NetworkError));
            }
        });
    }

    // Sources fetch.
    useEffect(() => {
        const sourcesFetch = async () => {
            try {
                // Fetch items
                const url = `${GET_NEWS_LMD_URL()}/api/feeds?topics=${newsFeedCategoryDetails.category}`;
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const body: INewsFeedSourcesResponse = await response.json();
                    // Filter body by category.
                    const sourcesForCategory = body.feeds;
                    // Sort by name
                    sourcesForCategory.sort((source1, source2) => sortIcelandic(source1.feed.feedDisplayName, source2.feed.feedDisplayName))
                    // Add boolean value to the filter.
                    const sourceFiltersForCategory: INewsFeedSourceFilter[] = sourcesForCategory.map(newsFeedSource => ({
                        newsFeedSource,
                        isOn: true
                    }));
                    // Set default settings from local storage.
                    try {
                        const dontUseSettingsString = localStorage.getItem(localStorageKey);
                        if (dontUseSettingsString === null) {
                            setSourceFilters(sourceFiltersForCategory);
                            return;
                        }
                        const dontUseTitles = dontUseSettingsString.split(';');
                        const sourceFiltersForCategoryWithSavedSettings: INewsFeedSourceFilter[] = sourceFiltersForCategory.map(({ newsFeedSource }) => ({
                            newsFeedSource,
                            isOn: !dontUseTitles.includes(newsFeedSource.feed.feedSymbol)
                        }));
                        setSourceFilters(sourceFiltersForCategoryWithSavedSettings);
                    } catch (e) {
                        // Localstorage error
                        setSourceFilters(sourceFiltersForCategory);
                    }
                } else {
                    setSourceFilters(new Error(ErrorMessages.RequestFailed));
                }
            } catch (e) {
                if (e instanceof Error) {
                    setSourceFilters(e);
                } else {
                    setSourceFilters(new Error(ErrorMessages.NetworkError));
                }
            }
        }
        sourcesFetch();
    }, []);

    // Initial data fetch:
    useEffect(() => {
        fetchNews(
            NEWS_ITEMS_FETCH_ON_INDEX_CHANGE,
            (newsItems, totalCount) => {
                if (totalPagesForDefault === null) setTotalPagesForDefault(totalCount, ITEMS_TO_DISPLAY_PER_PAGE);
                setDefaultNewsItems(newsItems);
            },
            (err) => {
                setNewsError(err);
            }
        )
    }, []);

    // DefaultNewsItems: Index based data fetch.
    useEffect(() => {
        if (defaultNewsItems === null || !usingDefaultNews) return;
        // If it is not complete, fetch more.
        if (!isCompleteSlice) {
            const lastVisibleNewsitem = defaultNewsItems.slice(-1).pop();
            fetchNews(
                NEWS_ITEMS_FETCH_ON_INDEX_CHANGE,
                (newNewsItems) => {
                    // Append new news items onto visible news.
                    const joinedListOfOldAndNewNews = defaultNewsItems.concat(newNewsItems);
                    // There could be some overlap between the end of visiblenews and the start of newnews items.
                    // So we remove duplicates.
                    const joinedNewsWithoutDuplicates = [];
                    // Map newsitem.id to boolean. Faster timecomplexity than "visibleNewsWithoutDuplicates.includes()";
                    const hasNewsItemBeenAdded: {
                        [feedId in string]?: boolean
                    } = {};
                    for (const newsItem of joinedListOfOldAndNewNews) {
                        if (newsItem.id === null) continue;
                        if (hasNewsItemBeenAdded[newsItem.id]) continue;
                        joinedNewsWithoutDuplicates.push(newsItem);
                        hasNewsItemBeenAdded[newsItem.id] = true;
                    }
                    setDefaultNewsItems(joinedNewsWithoutDuplicates);
                },
                (err) => {
                    setNewsError(err);
                },
                lastVisibleNewsitem?.publish_timestamp ?? undefined
            )
        }
    }, [ isCompleteSlice, usingDefaultNews, defaultNewsItems ])

    // Refresh rate based fetch
    const newNewsRefreshFunction = async () => {
        const defaultNewsItems = defaultNewsItemsRef.current;
        const defaultNewsItemsWaitingQueue = defaultNewsItemsWaitingQueueRef.current;
        const currentPageIndex = currentPageIndexRef.current;
        const newsItemFeedIdToIsNewMap = newsItemFeedIdToIsNewMapRef.current;
        const newsItemFeedIdToIsHighlightedMap = newsItemFeedIdToIsHighlightedMapRef.current;
        const usingDefaultNews = usingDefaultNewsRef.current;
        const usingSearchWord = usingSearchWordRef.current;
        const searchResultsDoubleMap = searchResultsDoubleMapRef.current;
        const sourceFilters = sourceFiltersRef.current;

        const combineOldAndNewNews = (newNewsItems: INewsLmdNewsItem[], oldNews: INewsLmdNewsItem[], sources: string[] | null) => {
            const newNewsItemsForSources = (sources === null)
                ? newNewsItems
                : newNewsItems.filter(newsItem => sources.includes(newsItem.source ?? ''));

            const firstOldNews = oldNews[0];
            let indexWhereOldNewsBegins = newNewsItemsForSources.findIndex(newsItem => (
                (
                    newsItem.id !== null
                    && newsItem.id === firstOldNews.id
                )
                || (
                    newsItem.publish_timestamp !== null
                    && firstOldNews.publish_timestamp !== null
                    && newsItem.publish_timestamp < firstOldNews.publish_timestamp
                )
            ));

            const addedNews = indexWhereOldNewsBegins !== -1 ? newNewsItemsForSources.slice(0, indexWhereOldNewsBegins) : [];
            const combinedNews = [...addedNews, ...oldNews];

            // There could be some overlap between the end of new news and old news.
            // So we remove duplicates.
            const combinedNewsWithoutDuplicates = [];
            // Map newsitem.id to boolean. Faster timecomplexity than ".includes()";
            const hasNewsItemBeenAdded: {
                [feedId in string]?: boolean
            } = {};
            for (const newsItem of combinedNews) {
                if (newsItem.id === null) continue;
                if (hasNewsItemBeenAdded[newsItem.id]) continue;
                combinedNewsWithoutDuplicates.push(newsItem);
                hasNewsItemBeenAdded[newsItem.id] = true;
            }

            return {
                combinedNews: combinedNewsWithoutDuplicates,
                addedNews
            };
        }

        // Always update default news and non-searchword,yes-sourcefilter news.
        if (defaultNewsItems !== null) {
            const recursionStoppingNewsItem = (defaultNewsItems[0] ?? null) as INewsLmdNewsItem | null;
            if (recursionStoppingNewsItem === null || recursionStoppingNewsItem.id === null) return;
            const fetchNewNews = (numberToFetch: number, recursionTime = 0) => {
                fetchNews(
                    numberToFetch,
                    (newNewsItems) => {
                        // Check if it has retreived all new news.
                        // Do this by finding index of the first old news item. (old as in already fetched).
                        let indexWhereOldNewsBegins = newNewsItems.findIndex(newsItem => (
                            (
                                newsItem.id !== null
                                && newsItem.id === recursionStoppingNewsItem.id
                            )
                            || (
                                newsItem.publish_timestamp !== null
                                && recursionStoppingNewsItem.publish_timestamp !== null
                                && newsItem.publish_timestamp < recursionStoppingNewsItem.publish_timestamp
                            )
                        ));
                        // If not try again with higher limit.
                        if (indexWhereOldNewsBegins === -1) {
                            // RECURSION ALERT:
                            // If for some reason something goes wrong, this will break us out of recursion after 3 loops:
                            if (recursionTime < 3) fetchNewNews(numberToFetch*2, recursionTime+1);
                            return;
                        }

                        let newsThatGetNotification: INewsLmdNewsItem[] = [];

                        // Handle default news:
                        const defaultNewsToAddTo = (usingDefaultNews && currentPageIndex !== 0)
                            ? defaultNewsItemsWaitingQueue
                            : defaultNewsItems
                        const { combinedNews, addedNews } = combineOldAndNewNews(newNewsItems, defaultNewsToAddTo, null);
                        if (usingDefaultNews) {
                            newsThatGetNotification = addedNews;
                            if (currentPageIndex === 0) setDefaultNewsItems(combinedNews)
                            else setDefaultNewsItemsWaitingQueue(combinedNews);
                        } else {
                            setDefaultNewsItems(combinedNews);
                        }

                        // Handle each source filter case:
                        const sourceFilterToResultsMap = searchResultsDoubleMap?.[''];
                        const newSourceFilterToResultsMap: SourceFilterToResultsMap = { ...sourceFilterToResultsMap };
                        const currentSourceFilterString = getSourceStringFromSourceList(sourceFilters, 'on');
                        if (sourceFilterToResultsMap !== undefined) {
                            for (const sourceFilterString in sourceFilterToResultsMap) {
                                const searchResultInfo = sourceFilterToResultsMap?.[sourceFilterString];
                                if (searchResultInfo === undefined) continue;
                                const { results, totalCount, waitingQueue } = searchResultInfo;
                                const newsToAddTo = (!usingSearchWord && currentSourceFilterString === sourceFilterString && currentPageIndex !== 0)
                                    ? waitingQueue
                                    : results
                                if (newsToAddTo instanceof Error || newsToAddTo === undefined) continue;
                                const { combinedNews, addedNews } = combineOldAndNewNews(newNewsItems, newsToAddTo, getSourceListFromSourceString(sourceFilterString));
                                
                                if (!usingSearchWord && currentSourceFilterString === sourceFilterString) {
                                    newsThatGetNotification = addedNews;
                                    if (currentPageIndex === 0) {
                                        newSourceFilterToResultsMap[sourceFilterString] = {
                                            results: combinedNews,
                                            waitingQueue: [],
                                            totalCount: totalCount + addedNews.length
                                        }
                                    } else {
                                        newSourceFilterToResultsMap[sourceFilterString] = {
                                            results,
                                            waitingQueue: combinedNews,
                                            totalCount: totalCount + addedNews.length
                                        }
                                    }
                                } else {
                                    newSourceFilterToResultsMap[sourceFilterString] = {
                                        results: combinedNews,
                                        waitingQueue: [],
                                        totalCount: totalCount + addedNews.length
                                    }
                                }
                            }
                        }
                        // Initialize new states here, that will be updated
                        const newSearchResultsDoubleMap = {
                            ...searchResultsDoubleMap,
                            '': newSourceFilterToResultsMap
                        };
                        
                        // Update isNew map.
                        const newNewsItemsToTrueMap: NewsItemFeedIdMapToBoolean = {};
                        const newNewsItemsToFalseMap: NewsItemFeedIdMapToBoolean = {};
                        newsThatGetNotification.forEach(newsItem => {
                            const { id } = newsItem;
                            if (id) {
                                newNewsItemsToTrueMap[id] = true;
                                newNewsItemsToFalseMap[id] = false;
                            }
                        });
                        
                        const newNewsItemFeedIdToIsNewMap = {
                            ...newsItemFeedIdToIsNewMap,
                            ...newNewsItemsToTrueMap
                        };
                        const newNewsItemFeedIdToIsHighlightedMap = {
                            ...newsItemFeedIdToIsHighlightedMap,
                            ...newNewsItemsToTrueMap
                        };

                        // Finish by setting all new states and time outs
                        setSearchResultsDoubleMap(newSearchResultsDoubleMap);
                        // Set timeouts for resetting isNew notifier + highlight.
                        setNewsItemFeedIdToIsHighlightedMap(newNewsItemFeedIdToIsHighlightedMap);
                        setNewsItemFeedIdToIsNewMap(newNewsItemFeedIdToIsNewMap);
                        window.setTimeout(() => {
                            const newsItemFeedIdToIsHighlightedMap = newsItemFeedIdToIsHighlightedMapRef.current;
                            setNewsItemFeedIdToIsHighlightedMap({
                                ...newsItemFeedIdToIsHighlightedMap,
                                ...newNewsItemsToFalseMap
                            });
                        }, 15*1000 /*15 sek.*/);
                        window.setTimeout(() => {
                            const newsItemFeedIdToIsNewMap = newsItemFeedIdToIsNewMapRef.current;
                            setNewsItemFeedIdToIsNewMap({
                                ...newsItemFeedIdToIsNewMap,
                                ...newNewsItemsToFalseMap
                            });
                        }, 15*60*1000 /* 15 min. */);
                    },
                    (err) => {
                        setNewsError(err);
                    }
                )
            }
            fetchNewNews(INITIAL_REFRESH_RATE_NEWS_ITEMS_TO_FETCH);
        }
    }
    useEffect(() => {
        if (refreshRateMs) {
            // If refresh rate, set fetchData on an interval
            const intervalId = window.setInterval(() => {
                newNewsRefreshFunction();
            }, refreshRateMs);
            // Clean up: clear interval to avoid memory leaks
            return () => window.clearInterval(intervalId);
        }
    }, [ refreshRateMs, usingSearchWord, usingSourceFilter ]);

    // Reset waiting queues on page index change.
    useEffect(() => {
        if (currentPageIndex !== 0) return;
        // Reset default queue.
        if (defaultNewsItems !== null) {
            setDefaultNewsItems([...defaultNewsItemsWaitingQueue, ...defaultNewsItems]);
            setDefaultNewsItemsWaitingQueue([]);
        }
        // Reset search/source based queue.
        let newSearchResultsDoubleMap = {
            ...searchResultsDoubleMap
        };
        for (const searchInputKey in searchResultsDoubleMap) {
            const sourceFilterToResultsMap = searchResultsDoubleMap[searchInputKey];
            if (sourceFilterToResultsMap === undefined) continue;
            for (const sourceFilterKey in sourceFilterToResultsMap) {
                const searchResults = sourceFilterToResultsMap[sourceFilterKey];
                if (searchResults === undefined) continue;
                const { results, waitingQueue } = searchResults;
                if (results instanceof Error || waitingQueue === undefined) continue;
                const newSearchResult: SearchResultsInfo = {
                    ...searchResults,
                    results: [...results, ...waitingQueue],
                    waitingQueue: []
                }
                newSearchResultsDoubleMap = updateDoubleMapValue(newSearchResultsDoubleMap, searchInputKey, sourceFilterKey, newSearchResult);
            }
        }
        setSearchResultsDoubleMap(newSearchResultsDoubleMap);
    }, [currentPageIndex]);

    // Search based fetch.
    useEffect(() => {
        if (usingDefaultNews || isCompleteSlice || usingSourceFilter === 'none') return;

        // Helper delay function.
        const withDelay = (func: () => void) => {
            const timeoutFunctionId = timeoutFunctionIdRef.current;
            if (timeoutFunctionId) window.clearTimeout(timeoutFunctionId);

            timeoutFunctionIdRef.current = window.setTimeout(func, 400);
        }

        // no search word. only source filter.
        if (!usingSearchWord) {
            const sourceFilterString = getSourceStringFromSourceList(sourceFilters, 'on');
            if (sourceFilterString === null) return;
            const searchResults = searchResultsDoubleMap?.['']?.[sourceFilterString];
                
            let numberOfNewsItemsToFetch = (searchResults === undefined || searchResults.results instanceof Error)
                ? NEWS_ITEMS_FETCH_ON_INDEX_CHANGE
                : (searchResults.results.length * 2);
            if (searchResults?.totalCount) numberOfNewsItemsToFetch = Math.min(searchResults.totalCount, numberOfNewsItemsToFetch); 
            fetchNews(
                numberOfNewsItemsToFetch,
                (results, totalCount) => {
                    const newSearchResultsDoubleMap = updateDoubleMapValue<SearchResultsInfo>(
                        searchResultsDoubleMap,
                        '',
                        sourceFilterString,
                        {
                            results,
                            totalCount,
                            waitingQueue: []
                        }
                    );
                    setSearchResultsDoubleMap(newSearchResultsDoubleMap);
                },
                (err) => {
                    const newSearchResultsDoubleMap = updateDoubleMapValue<SearchResultsInfo>(
                        searchResultsDoubleMap,
                        '',
                        sourceFilterString,
                        {
                            results: err,
                            totalCount: 0,
                            waitingQueue: []
                        }
                    );
                    setSearchResultsDoubleMap(newSearchResultsDoubleMap);
                },
                undefined,
                undefined,
                sourceFilterString
            )
        }
        // Search word.
        else {
            // No Source filter.
            if (usingSourceFilter === 'all') {
                const searchResults = searchResultsDoubleMap?.[searchInput]?.[''];
                
                let numberOfNewsItemsToFetch = (searchResults === undefined || searchResults.results instanceof Error)
                    ? NEWS_ITEMS_FETCH_ON_INDEX_CHANGE
                    : (searchResults.results.length * 2);
                if (searchResults?.totalCount) numberOfNewsItemsToFetch = Math.min(searchResults.totalCount, numberOfNewsItemsToFetch); 
                withDelay(() => fetchNews(
                    numberOfNewsItemsToFetch,
                    (results, totalCount) => {
                        const newSearchResultsDoubleMap = updateDoubleMapValue<SearchResultsInfo>(
                            searchResultsDoubleMap,
                            searchInput,
                            '',
                            {
                                results,
                                totalCount,
                                waitingQueue: []
                            }
                        );
                        setSearchResultsDoubleMap(newSearchResultsDoubleMap);
                    },
                    (err) => {
                        const newSearchResultsDoubleMap = updateDoubleMapValue<SearchResultsInfo>(
                            searchResultsDoubleMap,
                            searchInput,
                            '',
                            {
                                results: err,
                                totalCount: 0,
                                waitingQueue: []
                            }
                        );
                        setSearchResultsDoubleMap(newSearchResultsDoubleMap);
                    },
                    undefined,
                    searchInput
                ));
            }
            // Filtering by source filter.
            else {
                const sourceFilterString = getSourceStringFromSourceList(sourceFilters, 'on');
                if (sourceFilterString === null) return;
                const searchResults = searchResultsDoubleMap?.[searchInput]?.[sourceFilterString];
                
                let numberOfNewsItemsToFetch = (searchResults === undefined || searchResults.results instanceof Error)
                    ? NEWS_ITEMS_FETCH_ON_INDEX_CHANGE
                    : (searchResults.results.length * 2);
                if (searchResults?.totalCount) numberOfNewsItemsToFetch = Math.min(searchResults.totalCount, numberOfNewsItemsToFetch); 
                withDelay(() => fetchNews(
                    numberOfNewsItemsToFetch,
                    (results, totalCount) => {
                        const newSearchResultsDoubleMap = updateDoubleMapValue<SearchResultsInfo>(
                            searchResultsDoubleMap,
                            searchInput,
                            sourceFilterString,
                            {
                                results,
                                totalCount,
                                waitingQueue: []
                            }
                        );
                        setSearchResultsDoubleMap(newSearchResultsDoubleMap);
                    },
                    (err) => {
                        const newSearchResultsDoubleMap = updateDoubleMapValue<SearchResultsInfo>(
                            searchResultsDoubleMap,
                            searchInput,
                            sourceFilterString,
                            {
                                results: err,
                                totalCount: 0,
                                waitingQueue: []
                            }
                        );
                        setSearchResultsDoubleMap(newSearchResultsDoubleMap);
                    },
                    undefined,
                    searchInput,
                    sourceFilterString
                ));
            }
        }
    }, [ usingDefaultNews, searchInput, sourceFilters, searchResultsDoubleMap, isCompleteSlice, usingSourceFilter ]);

    const displayNewsItems = () => {
        if (usingSourceFilter === 'none') {
            return <Alert
                headText='Hætt að sækja. Veldu fleiri veitur.'
                type='alert'
            />
        }
        const dataOrLastData = dataToDisplay ?? lastDisplayableData ?? null;
        if (dataOrLastData === null) {
            if (newsError === null) return <Loading />;
            else return <ErrorAlert error={newsError} />
        }
        // We split it up so that the NewsItem component can have the correct key.
        // This means if news increases, open news stays open. It doesn't reset the news item component.
        const topNews = dataOrLastData.slice(0, 6);
        const middleNews = dataOrLastData.slice(6, 15);
        const bottomNews = dataOrLastData.slice(15);
        return (
            <div className='news-items'>
                {topNews.map((feedItem) => (
                    <NewsItem
                        key={feedItem.id}
                        item={feedItem}
                        isNew={feedItem.id !== null && (newsItemFeedIdToIsNewMap[feedItem.id] ?? false)}
                        isHighlighted={feedItem.id !== null && (newsItemFeedIdToIsHighlightedMap[feedItem.id] ?? false)}
                        isSeen={feedItem.id !== null && (newsItemFeedIdToIsSeenMap[feedItem.id] ?? false)}
                        onOpen={() => newsItemOnOpen(feedItem)}
                    />
                ))}
                <AdRotator location="FrontPage400x80" />
                {middleNews.map((feedItem) => (
                    <NewsItem
                        key={feedItem.id}
                        item={feedItem}
                        isNew={feedItem.id !== null && (newsItemFeedIdToIsNewMap[feedItem.id] ?? false)}
                        isHighlighted={feedItem.id !== null && (newsItemFeedIdToIsHighlightedMap[feedItem.id] ?? false)}
                        isSeen={feedItem.id !== null && (newsItemFeedIdToIsSeenMap[feedItem.id] ?? false)}
                        onOpen={() => newsItemOnOpen(feedItem)}
                    />
                ))}
                <AdRotator location="FrontPage300x250" />
                {bottomNews.map((feedItem) => (
                    <NewsItem
                        key={feedItem.id}
                        item={feedItem}
                        isNew={feedItem.id !== null && (newsItemFeedIdToIsNewMap[feedItem.id] ?? false)}
                        isHighlighted={feedItem.id !== null && (newsItemFeedIdToIsHighlightedMap[feedItem.id] ?? false)}
                        isSeen={feedItem.id !== null && (newsItemFeedIdToIsSeenMap[feedItem.id] ?? false)}
                        onOpen={() => newsItemOnOpen(feedItem)}
                    />
                ))}
            </div>
        );
    }

    const isNextPageDisabled = useMemo(() => {
        return (totalPages !== null && (currentPageIndex + 1) >= totalPages)
    }, [ currentPageIndex, totalPages]);

    const isPrevPageDisabled = useMemo(() => {
        return (currentPageIndex === 0)
    }, [ currentPageIndex ]);

    return (
        <div className="KCL_news">
            <div className="search__box">
                <SmallSearch
                    search={searchInput}
                    setSearch={setSearchInput}
                    inputSize='sm'
                    id={`Search_News_FrontPage_${newsFeedCategoryDetails.category}`}
                />
                <div className='filter-wrapper'>
                    {
                        (sourceFilters === null || sourceFilters instanceof Error)
                        ? null
                        : <Filter
                            itemStyle={{
                                size: 'lg',
                                showCheck: true
                            }}
                            notAllSelectedBubble
                            itemValues={sourceFilters.map(({newsFeedSource, isOn }, i) => ({
                                selected: isOn,
                                text: newsFeedSource.feed.feedDisplayName,
                                key: newsFeedSource.feed.feedKey,
                                toggleSelected: () => {
                                    // Copy to prevent corruption of state.
                                    const sourcesCopy = [...sourceFilters];
                                    sourcesCopy.splice(i, 1, {
                                        newsFeedSource,
                                        isOn: !isOn
                                    });
                                    // Save current settings to local storage.
                                    const localStorageSettingsString = getSourceStringFromSourceList(sourcesCopy, 'off');
                                    try {
                                        if (localStorageSettingsString !== null) {
                                            localStorage.setItem(localStorageKey, localStorageSettingsString);
                                        } else {
                                            localStorage.removeItem(localStorageKey);
                                        }
                                    } catch (e) {
                                        // Local Storage Error.
                                    }
                                    setSourceFilters(sourcesCopy);
                                }
                            }))}
                            columnStyle='flow'
                            toggleAll={() => {
                                const sourcesCopy: INewsFeedSourceFilter[] = sourceFilters.map(sourceFilter => ({
                                    newsFeedSource: sourceFilter.newsFeedSource,
                                    isOn: usingSourceFilter !== 'all'
                                }));
                                // Save current settings to local storage.
                                const localStorageSettingsString = getSourceStringFromSourceList(sourcesCopy, 'off');
                                try {
                                    if (localStorageSettingsString !== null) {
                                        localStorage.setItem(localStorageKey, localStorageSettingsString);
                                    } else {
                                        localStorage.removeItem(localStorageKey);
                                    }
                                } catch (e) {
                                    // Local Storage Error.
                                }
                                setSourceFilters(sourcesCopy);
                            }}
                        />
                    }
                </div>
            </div>
            {displayNewsItems()}
            <div className="display-box__footer">
                <Link
                    url="/Frettir"
                    linkSize='14'
                    icon='forward'
                >
                    Fara í Fréttavakt
                </Link>

                <div className="news-list__pagination">
                    <div className="news-list__indicator">
                        {(totalPages === 0) ? 0 : (currentPageIndex + 1)} af {totalPages ?? '...'}
                    </div>
                    <div className="pagination">
                        <span
                            className={cx("news-list__arrows", {"disabled": isPrevPageDisabled })}
                            onClick={isPrevPageDisabled ? undefined : goToPreviousPage}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none" style={{marginBottom: '2px'}}>
                                <path
                                    d="M1.44246 9.21327L0.706407 8.3709C0.405295 8.01991 0.438752 7.49343 0.77332 7.17754L7.5316 0.684268C7.79926 0.438577 8.20074 0.438577 8.4684 0.684268L15.2267 7.17754C15.5612 7.49343 15.5947 8.01991 15.2936 8.3709L14.5575 9.21327C14.2899 9.56425 13.7546 9.59935 13.4535 9.28346L8 4.01865L2.54653 9.28346C2.24542 9.59935 1.71011 9.56425 1.44246 9.21327Z"
                                    fill={isPrevPageDisabled ? "#D3D3D6" : "#4569EE"}
                                />
                            </svg>
                        </span>
                        <span
                            className={cx("news-list__arrows", {"disabled": isNextPageDisabled })}
                            onClick={isNextPageDisabled ? undefined : goToNextPage}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none">
                                <path
                                    d="M14.5575 0.786734L15.2936 1.6291C15.5947 1.98009 15.5612 2.50657 15.2267 2.82246L8.4684 9.31573C8.20074 9.56142 7.79926 9.56142 7.5316 9.31573L0.773321 2.82246C0.438752 2.50657 0.405295 1.98009 0.706407 1.6291L1.44246 0.786734C1.71011 0.435746 2.24542 0.400648 2.54653 0.716536L8 5.98135L13.4535 0.716536C13.7546 0.400648 14.2899 0.435746 14.5575 0.786734Z"
                                    fill={isNextPageDisabled ? "#D3D3D6" : "#4569EE"}
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default News;