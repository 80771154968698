// Package imports:
import React from 'react';
// Component imports:
import AlertComp from '../../ui-elements/Alert/Alert';

interface IProps {
    type?: 'info' | 'alert' | 'error',
    headText?: string,
    text?: string,
    moreInfoLink?: string,
    defaultClose?: boolean
}

const Alert: React.FC<IProps> = ({
    type,
    headText,
    text,
    moreInfoLink,
    defaultClose
}) => {
    return (
        <AlertComp type={type ?? 'info'} headText={headText ?? 'Example head text'} text={text} moreInfoLink={moreInfoLink} withCloseIcon={defaultClose} />
    );
}

export default Alert;