// Package imports:
import React, { useEffect, useState } from 'react';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import Loading from '../../../ui-elements/Loading/Loading';
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';
import DocumentListReportBody from './DocumentListReportBody';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { IEventReport, IKeldanApiResponse, OwnedReport } from '../../../types/KeldanTypes';
import { IPropertyDocumentListResult, IPropertyViewModel } from '../../../types/PropertyTypes';
import { Fetched } from '../../../types/Types';

const ViewDocumentList: React.FC<IPropertyViewModel> = ({
    propertyNumber,
    eventId
}) => {
    const [report, setReport] = useState<Fetched<IEventReport<IPropertyDocumentListResult>>>(null);
    const [owned, setOwned] = useState<Fetched<OwnedReport[]>>(null)

    const fetchReport = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Property/GetDocumentList/${eventId}?fastanr=${propertyNumber}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                }
                const responseBody: IKeldanApiResponse<{report: IEventReport<IPropertyDocumentListResult>, owned: OwnedReport[]}> = await response.json();
                const { result, message } = responseBody;
                if (result) {
                    setReport(result.report);
                    setOwned(result.owned);
                } else if (message) {
                    setReport(new Error(message));
                } else {
                    setReport(new Error(ErrorMessages.ErrorInRequest));
                }
            } else {
                setReport(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setReport(new Error(ErrorMessages.OtherShort));
        }
    }

    useEffect(() => {
        fetchReport();
    }, []);

    const displayReport = () => {
        if (report instanceof Error) {
            return <Alert type='error' headText={report.message} />;
        }
        if (owned instanceof Error) {
            return <Alert type='error' headText={owned.message} />;
        }
        if (report === undefined || report === null) {
            return <Loading />;
        }
        return <DocumentListReportBody
            report={report}
            owned={owned ?? []}
            propertyNumber={propertyNumber ?? ''}
        />;
    }
    return (
        <div className='main KCL_property-page'>
            <div className='main__inner fluid'>
                <div className='shell'>
                    <HeadSection
                        title='Þinglýst skjöl'
                        showRecentReports
                    />
                    {displayReport()}
                </div>
            </div>
        </div>
    );
}

export default ViewDocumentList;