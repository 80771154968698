// Package imports:
import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
// Component imports:
import Logo from "./Logo/Logo";
import Nav from "./Nav/Nav";
import Search from "./Search/Search";
// Service imports:
import { isElementSubChild } from "../../services/utils";

const Header: React.FC = () => {
    const headerRef = useRef<HTMLElement | null>(null);
    const [isMobileNavMenuOpen, setIsMobileNavMenuOpen] = useState(false);
    const [isMobileMyKeldaMenuOpen, setIsMobileMyKeldaMenuOpen] = useState(false);
    // Used to shift navbar around, to give space to the input field.
    const [isSmallSearchInputFieldFocused, setIsSmallSearchInputFieldFocused] = useState(false);
    // We don't want the search to "unfocus" when we click out of the search alone.
    // Because there are many buttons in the nav bar that will shift around when we unfocus the state.
    // So therefore, to focus: click the input.
    // But to unfocus, you have to click an element that is not inside the header.
    // For this we will have a helper function, and a document event listener.
    const focusSearchInputField = () => setIsSmallSearchInputFieldFocused(true);
    useEffect(() => {
        const outOfHeaderClickListener = (e: MouseEvent) => {
            const { target } = e;
            const headerElement = headerRef.current;
            if (target instanceof Element && headerElement) {
                // If user clicks on element, which is not in header, then turn off focus.
                if (!isElementSubChild(target, headerElement)) {
                    setIsSmallSearchInputFieldFocused(false);
                }
            } else {
                setIsSmallSearchInputFieldFocused(false);
            }
        };
        document.addEventListener("click", outOfHeaderClickListener);
        return () => document.removeEventListener("click", outOfHeaderClickListener);
    });
    
    return (
        <header
            ref={headerRef}
            className={cx("KCL_header", {
                "is-search-input-focused": isSmallSearchInputFieldFocused || isMobileNavMenuOpen || isMobileMyKeldaMenuOpen,
            })}
        >
            {/* Add pink-ribbon class for pink ribbon theme, pride class for pride theme */}
            <div className={cx("header-content", { "pink-ribbon": new Date().getMonth() === 9})}>
                <Logo />
                <div className="search-wrapper">
                    <Search focusSearchInputField={focusSearchInputField} />
                </div>
                <Nav
                    setSearchFocus={setIsSmallSearchInputFieldFocused}
                    isMobileNavMenuOpen={isMobileNavMenuOpen}
                    setIsMobileNavMenuOpen={setIsMobileNavMenuOpen}
                    isMobileMyKeldaMenuOpen={isMobileMyKeldaMenuOpen}
                    setIsMobileMyKeldaMenuOpen={setIsMobileMyKeldaMenuOpen}
                />
            </div>
        </header>
    );
};

export default Header;
