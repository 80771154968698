// Package imports:
import React, { useState, useRef, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown } from '@fortawesome/pro-regular-svg-icons'


type SelectSize = 'sm' | 'lg' | 'xs';

export interface IOptionProps {
    id?: string,
    label: string | JSX.Element,
    value: string
}

interface IOwnProps {
    label?: string,
    options?: IOptionProps[],
    size?: SelectSize,
    className?: string | React.HTMLAttributes<HTMLElement>,
    defaultValue?: string | string[] | null,
    onChange?(s?: string | string[] | null): void
    disabled?: boolean,
}

export type DropSelectProps = IOwnProps;

const DropSelect: React.FC<DropSelectProps> = ({
    label,
    size = 'sm',
    options = [],
    className = '',
    defaultValue = '',
    onChange = () => {},
    disabled,
    children
}) => {
    const dropdown = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(defaultValue)
    const [selectedOption, setSelectedOption] = useState<IOptionProps | null | undefined>(null);

    const toggleOpen = () => setIsOpen(!isOpen)

    const hasValue = useMemo(() => {
        return selectedValue && selectedValue.toString().length >= 1
    }, [selectedValue])

    const handleOutsideClick = (event: any) => {
        if (dropdown.current) {
            if (!dropdown.current.contains(event.target)) {
               setIsOpen(false);
            }
        }
    }

    useEffect(() => {
        window.addEventListener('click', handleOutsideClick);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsOpen(false);
        const option = options.find(opt => selectedValue && opt.value.toString() === selectedValue.toString());
        
        setSelectedOption(option)
        
        onChange(selectedValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue]);

    return (
        <div 
            className={cx(
                'KCL_drop-select', 
                className.toString(),
                size,
                {
                    'is-open': isOpen,
                    'has-value': hasValue,
                    'disabled' : disabled,
                }
            )} 
            ref={dropdown}
        >
            {label && (
                <span className="drop-select__label">
                    <span>{label}</span>
                </span>
            )}

            <button
                onClick={toggleOpen}
                className="drop-select__trigger btn"
                type="button"
            >
                <span>
                    {selectedOption ? selectedOption.label : ''}
                </span>

                <i>
                    <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown}/>
                </i>
            </button>

            <div className="drop-select__overlay">
                <div className="drop-select__inner">
                    {children ? (
                        <div>{children}</div>
                    ): options.length > 0 && options.map((option, i) => (
                        <button
                            key={option.id}
                            type="button"
                            className={cx('drop-select__item', {'is-selected': selectedValue && option.value.toString() === selectedValue.toString()})} 
                            onClick={() => setSelectedValue(option.value)}
                        >
                            {option.label}
                        </button>
                    ))
                    }
                </div>
            </div>
        </div>
    )
}

export default DropSelect
