// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import HomePageComp from '../../components/HomePage/HomePage';

const HomePage: React.FC = () => {
    return (
        <Entrypoint>
            <HomePageComp />
        </Entrypoint>
    );
}

export default HomePage;