// Package imports:
import React, { useEffect } from 'react';

interface IOwnProps {
    imgUrl: string;
    imgAlt: string;
    onExit: () => void;
}

type Props = IOwnProps;

const FullScreenImage: React.FC<Props> = ({
    imgUrl,
    imgAlt,
    onExit
}) => {
    // On mount, add "Esc" button listener.
    useEffect(() => {
        const onEscapeListener = (e: KeyboardEvent) => {
            if (e.key === 'Escape') onExit();
        }
        window.addEventListener('keydown', onEscapeListener);
        return () => window.removeEventListener('keydown', onEscapeListener);
    }, [])

    return (
        <div
            className='KCL_full-screen-image'
            onClick={onExit}
        >
            <div
                className='img-container'
                style={{ backgroundImage: `url(${imgUrl})` }}
            />
        </div>
    );
}

export default FullScreenImage;