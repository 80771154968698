// Package imports:
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf} from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import LmdTable from '../../ui-elements/Table/LmdTable';
import Fyrirvari from '../Fyrirvari/Fyrirvari';
// Service imports:
import { getTableDate } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdAvailableReports } from '../../types/HlutabrefTypes';

interface IOwnProps {
    symbol: string,
    limit?: number
}

const getPeriodName = (pn: string | null) => {
    switch (pn) {
        case '3M':
            return '3 mán.';
        case '6M':
            return '6 mán.';
        case '9M':
            return '9 mán.';
        case 'Y':
            return 'Ár';
        default:
            return null;
    }
}

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const CompanyReportsFiles: React.FC<Props> = ({
    symbol,
    limit = 10,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [apiData] = useApiLmdData<IApiLmdAvailableReports[]>(
        `/v1/market_data/v1/questor/available_reports/symbol/${symbol}/periods/[3M;6M;9M;Y]/`,
        accessToken,
        refreshRateMs
    );
    const [disclaimerOpen, setDisclaimerOpen] = useState(false);
    const openDisclaimer = () => setDisclaimerOpen(true);
    const closeDisclaimer = () => setDisclaimerOpen(false);

    const handleData = () => {
        const { data, error } = apiData;
        if (data === null) {
            return apiData;
        } else {
            const newData = data.slice(-limit);
            newData.reverse();
            return {
                error,
                data: newData
            }
        }
    }

    return (
        <DisplayBox
            className='KCL_company-reports-files'
            title="Árs- og árshlutareikningar"
            footerRight={
                <Fyrirvari
                    show={disclaimerOpen}
                    open={openDisclaimer}
                    close={closeDisclaimer}
                >
                    <p className='paragraph'>Keldan ábyrgist ekki áreiðanleika eða réttmæti upplýsinga sem settar eru fram.</p>
                    <p className='paragraph'>Árs- og árshlutareikningum er safnað saman af vefsíðum fyrirtækjanna eða úr kauphallartilkynningum, ekki er víst að um réttar upplýsingar sé að ræða.</p>
                </Fyrirvari>
            }
            {...props}
        >
            <LmdTable
                apiData={handleData()}
                columns={[{
                    title: 'Ár',
                    renderCell: ({FiscalYear}) => FiscalYear,
                    textAlign: 'left'
                }, {
                    title: 'Tímabil',
                    renderCell: ({PeriodName}) => getPeriodName(PeriodName),
                    textAlign: 'left'
                }, {
                    title: 'Frá',
                    renderCell: ({PeriodStart}) => getTableDate(PeriodStart, 'DD/MM/YYYY', '.'),
                    textAlign: 'left'
                }, {
                    title: 'Til',
                    renderCell: ({PeriodEnd}) => getTableDate(PeriodEnd, 'DD/MM/YYYY', '.'),
                    textAlign: 'left'
                }, {
                    title: '',
                    renderCell: ({Ticker, PeriodName, FiscalYear}) => (
                        <a href={`https://questor-reports.s3-eu-west-1.amazonaws.com/${Ticker}-${PeriodName}-${FiscalYear}.pdf`} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFilePdf} className="icon-fa" />
                        </a>
                    ),
                    textAlign: 'center'
                }]}
                tableSize='lg'
            />
        </DisplayBox>
    );
}

export default CompanyReportsFiles;