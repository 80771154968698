// Package imports:
import React from 'react';
// Component imports:
import CalculatedColoredNumber from '../../ui-elements/CalculatedColoredNumber/CalculatedColoredNumber';
import Loading from '../../ui-elements/Loading/Loading';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import PriceRange from '../../ui-elements/PriceRange/PriceRange';
import TandemTable from '../../ui-elements/Table/TandemTable';
import Flag, { FlagNames } from '../../ui-elements/Flag/Flag';
import Link from '../../ui-elements/Link/Link';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
// Service imports:
import { formatNumber } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdIndexStatisticListShort } from '../../types/MarketTypes';

export const firstTableSymbols = [ 'OMXN40', 'OMXC25', 'OMXS30', 'OMXH25' ];
export const secondTableSymbols = [ 'OMXI15GI', 'OMXI15CAP', 'OMXI15', 'OMXIGI', 'OMXIPI' ];
const thirdTableSymbols = [ 'NOMXIBB', 'NOMXIBBTA', 'NOMXINOM', 'NOMXINOMTA' ];
const fourthTableSymbols = [ 'NOMXIREAL', 'NOMXIREALTA' ];

interface SymbolMap {
    [key: string]: {
        flag: FlagNames;
        displayName: string;
    };
}

const symbolMap: SymbolMap = {
    'OMXN40': { flag: 'OMX', displayName: 'OMXN40 - Nordic' },
    'OMXC25': { flag: 'DKK', displayName: 'OMXC25 - Danmörk' },
    'OMXS30': { flag: 'SEK', displayName: 'OMXS30 - Svíþjóð' },
    'OMXH25': { flag: 'FIN', displayName: 'OMXH25 - Finnland' },
    'OMXI15GI': { flag: 'ISK', displayName: 'OMXI15GI' },
    'OMXI15CAP': { flag: 'ISK', displayName: 'OMXI15CAP' },
    'OMXI15': { flag: 'ISK', displayName: 'OMXI15' },
    'OMXIGI': { flag: 'ISK', displayName: 'OMXIGI' },
    'OMXIPI': { flag: 'ISK', displayName: 'OMXIPI' },
    'NOMXIBB': { flag: 'ISK', displayName: 'NOMXIBB' },
    'NOMXIBBTA': { flag: 'ISK', displayName: 'NOMXIBBTA' },
    'NOMXINOM': { flag: 'ISK', displayName: 'NOMXINOM' },
    'NOMXINOMTA': { flag: 'ISK', displayName: 'NOMXINOMTA' },
    'NOMXIREAL': { flag: 'ISK', displayName: 'NOMXIREAL' },
    'NOMXIREALTA': { flag: 'ISK', displayName: 'NOMXIREALTA' },
};

export type SymbolType = typeof firstTableSymbols[number] | typeof secondTableSymbols[number] | typeof thirdTableSymbols[number] | typeof fourthTableSymbols[number];

export const nameChanger = (symbol: SymbolType, linkSize: '13' | '15' = '15') => {
    const symbolData = symbolMap[symbol];
        return <>
            <span className="flag">
                <a href={`/Markadir/Visitolur/${symbol}`} aria-label={`Vísitölur - ${symbol}`}>
                    <Flag name={symbolData?.flag}/>
                </a>
            </span>
            <span className="symbol">
                <Link url={`/Markadir/Visitolur/${symbol}`} linkSize={linkSize}>{symbolData.displayName}</Link>
            </span>
        </>;
};
interface IOwnProps {
    basecurrency?: string
}
type Props = IDefaultProps & IOwnProps & React.HTMLAttributes<HTMLDivElement>;

const MultipleIndices: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    ...props
}) => {

    const [indexStatistics] = useApiLmdData<IApiLmdIndexStatisticListShort[]>(
      `v1/market_data/v1/keldan/indexes/index_statistics_list`,
      accessToken,
      refreshRateMs
    );

    const { data, error } = indexStatistics;

    const displayBody = () => {
        if (error !== null) {
            return <ErrorAlert error={error} />;
        }
        if (data === null) {
            return <Loading />;
        }
        return <TandemTable 
            data={[
                data.filter(x => firstTableSymbols.includes(x.Symbol)),
                data.filter(x => secondTableSymbols.includes(x.Symbol)),
                data.filter(x => thirdTableSymbols.includes(x.Symbol)),
                data.filter(x => fourthTableSymbols.includes(x.Symbol)),
            ]}
            columns={[
                //TABLE ONE 
                // Norrænar hlutabréfavísitölur
                [{
                    title: <span className='table-section__title'>Norrænar hlutabréfavísitölur</span>,
                    renderCell: ( { Symbol: symbol } ) => (
                        <div className="avatar-and-text">
                            {nameChanger(symbol)}
                        </div>
                    ),
                    textAlign: 'left'
                }, {
                    title: 'Gildi',
                    renderCell: ( {LastValue} ) => (formatNumber(LastValue, '-', 2)),
                }, {
                    title: 'Dagsbreyting',
                    renderCell: ( {YesterdaysValueChangePer} ) => (
                        <CalculatedColoredNumber
                            currentValue={YesterdaysValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    ),
                    
                }, {
                    title: '1 Mán.',
                    renderCell: ( {Weeks4ValueChangePer} ) => (
                        <CalculatedColoredNumber
                            currentValue={Weeks4ValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    ),
                }, {
                    title: 'Innan árs',
                    renderCell: ( {YearValueChangePer}) => (
                        <CalculatedColoredNumber
                            currentValue={YearValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    ),
                }, {
                    title: '1 Ár',
                    renderCell: ( { Months12ValueChangePer } ) => (
                        <CalculatedColoredNumber
                            currentValue={Months12ValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    ),
                }, {
                    title: 'Árs bil',
                    renderCell: ( { Months12LowValue, Months12HighValue, LastValue } ) => (
                        <PriceRange low={Months12LowValue} high={Months12HighValue} value={LastValue} />),
                }],
                //TABLE TWO
                //Íslenskar hlutabréfavísitölur
                [{
                    title: <span className='table-section__title'>Íslenskar hlutabréfavísitölur</span>,
                    renderCell: ( {Symbol: symbol} ) => (
                        <div className="avatar-and-text">
                            {nameChanger(symbol)}
                        </div>
                    ),
                    textAlign: 'left'
                }, {
                    title: '',
                    renderCell: ( {LastValue} ) => (formatNumber(LastValue, '-', 2)),
                }, {
                    title: '',
                    renderCell: ( {YesterdaysValueChangePer} ) => (
                        <CalculatedColoredNumber
                            currentValue={YesterdaysValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    ),
                    
                }, {
                    title: '',
                    renderCell: ( {Weeks4ValueChangePer} ) => (
                        <CalculatedColoredNumber
                            currentValue={Weeks4ValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    ),
                }, {
                    title: '',
                    renderCell: ( {YearValueChangePer  }) => (
                        <CalculatedColoredNumber
                            currentValue={YearValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    ),
                }, {
                    title: '',
                    renderCell: ( { Months12ValueChangePer } ) => (
                        <CalculatedColoredNumber
                            currentValue={Months12ValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    ),
                }, {
                    title: '',
                    renderCell: ( { Months12LowValue, Months12HighValue, LastValue } ) => (
                        <PriceRange low={Months12LowValue} high={Months12HighValue} value={LastValue} />),
                }],
                //TABLE THREE
                //Íslenskar óverðtryggðar skuldabréfavísitölur
                [{
                    title: <span className='table-section__title'>Íslenskar óverðtryggðar skuldabréfavísitölur</span>,
                    renderCell: ( {Symbol: symbol} ) => (
                        <div className="avatar-and-text">
                            {nameChanger(symbol)}
                        </div>
                    ),
                    textAlign: 'left'
                }, {
                    title: '',
                    renderCell: ( {LastValue} ) => (formatNumber(LastValue, '-', 2)),
                }, {
                    title: '',
                    renderCell: ( {YesterdaysValueChangePer} ) => (
                        <CalculatedColoredNumber
                            currentValue={YesterdaysValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    )
                }, {
                    title: '',
                    renderCell: ( {Weeks4ValueChangePer} ) => (
                        <CalculatedColoredNumber
                            currentValue={Weeks4ValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    )
                }, {
                    title: '',
                    renderCell: ( {YearValueChangePer}) => (
                        <CalculatedColoredNumber
                            currentValue={YearValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    ),
                }, {
                    title: '',
                    renderCell: ( { Months12ValueChangePer } ) => (
                        <CalculatedColoredNumber
                            currentValue={Months12ValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    )
                }, {
                    title: '',
                    renderCell: ( { Months12LowValue, Months12HighValue, LastValue } ) => (
                        <PriceRange low={Months12LowValue} high={Months12HighValue} value={LastValue} />),
                }],
                //TABLE FOUR
                //Íslenskar verðtryggðar skuldabréfavísitölur
                [{
                    title: <span className='table-section__title'>Íslenskar verðtryggðar skuldabréfavísitölur</span>,
                    renderCell: ( {Symbol: symbol} ) => (
                        <div className="avatar-and-text">
                            {nameChanger(symbol)}
                        </div>
                    ),
                    textAlign: 'left'
                }, {
                    title: '',
                    renderCell: ( {LastValue} ) => (formatNumber(LastValue, '-', 2)),
                }, {
                    title: '',
                    renderCell: ( {YesterdaysValueChangePer} ) => (
                        <CalculatedColoredNumber
                            currentValue={YesterdaysValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    )
                }, {
                    title: '',
                    renderCell: ( {Weeks4ValueChangePer} ) => (
                        <CalculatedColoredNumber
                            currentValue={Weeks4ValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    )
                }, {
                    title: '',
                    renderCell: ( {YearValueChangePer} ) => (
                        <CalculatedColoredNumber
                            currentValue={YearValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    ),
                }, {
                    title: '',
                    renderCell: ( { Months12ValueChangePer } ) => (
                        <CalculatedColoredNumber
                            currentValue={Months12ValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    )
                }, {
                    title: '',
                    renderCell: ( { Months12LowValue, Months12HighValue, LastValue } ) => (
                        <PriceRange low={Months12LowValue} high={Months12HighValue} value={LastValue} />),
                }],
            ]}
            tableSize='lg'
        />
    }

    return (
        <DisplayBox 
            className='KCL_multipleIndices'
            title="Helstu vísitölur"
            {...props}
        >
            {displayBody()}
        </DisplayBox>
    );
}

export default MultipleIndices;