// Package imports:
import React, { useMemo } from 'react';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';
import Link from '../../../ui-elements/Link/Link';
import Loading from '../../../ui-elements/Loading/Loading';
import Table from '../../../ui-elements/Table/Table';
import VerticalTable from '../../../ui-elements/Table/VerticalTable';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import PropertyNavBar from '../PropertyNavBar/PropertyNavBar';
import PropertyReportBody from './PropertyReportBody';
// Service imports:
import { convertToPercentage, formatNumber, getTableDate } from '../../../services/utils';
// Type imports:
import { IEventReport } from '../../../types/KeldanTypes';
import { IPropertySearchResult } from '../../../types/PropertyTypes';

interface IProps {
    id: string,
    report: IEventReport<IPropertySearchResult>,
    documentsAllowed: boolean
}

const ViewReport: React.FC<IProps> = ({
    id,
    report,
    documentsAllowed
}) => {
    const propertyNumber = useMemo(() => {
        if (report instanceof Error) return undefined;
        if (!report?.overview.property?.land) {
            return report?.overview.property?.property?.property ? report.overview.property.property.property[0].property_number : undefined;
        } else {
            return undefined;
        }
    }, [report]);

    const nameNumber = useMemo(() => {
        if (report instanceof Error) return undefined;
        if (!report?.overview.property?.land) {
            return report?.overview?.property?.property?.property ? report.overview.property.property.property[0].name_number : undefined;
        } else {
            return undefined;
        }
    }, [report]);

    const padDateString = (date?: string) => {
		if (!date) {
            return '-';
        }
        date = date.replaceAll('/', '.')
		if (date[2] !== '.') date = date.padStart(date.length + 1, '0')
		if (date[5] !== '.') date = date.substring(0,3) + '0' + date.substring(3)
		return date;
	}

    const displayAddress = (address: string | undefined, city: string | undefined) => {
        if (address === undefined || city === undefined) return '';
        return `${address}, ${city}`;
    }

    const info = useMemo(() => {
        if (report instanceof Error) return null;
        return report?.overview.property?.property;
    }, [report]);

    const displayReport = () => {
        if (report instanceof Error) return <Alert type='error' headText={report.message} />;
        if (report === undefined || report === null) return <Loading />;
        if (!report.overview.property?.land) {
            return <>
                <PropertyNavBar propertyNumber={propertyNumber} />
                <div className='shell'>
                    <HeadSection
                        title={displayAddress(info?.address?.display_address, info?.address?.city)}
                        showRecentReports
                    />
                    <div className='info-col-wrapper'>
                        <div className='info-col'>
                            <div className='info-item'>
                                <span className='title'>
                                    Fastanúmer
                                </span>
                                <span className='value'>
                                    {propertyNumber}
                                </span>
                            </div>
                        </div>
                        <div className='info-col'>
                            <div className='info-item'>
                                <span className='title'>
                                    Merking
                                </span>
                                <span className='value'>
                                    {info?.property ? info?.property[0].units[0].tag : '-'}
                                </span>
                            </div>
                        </div>
                        <div className='info-col'>
                            <div className='info-item'>
                                <span className='title'>
                                    Heitisnúmer
                                </span>
                                <span className='value'>
                                    {nameNumber}
                                </span>
                            </div>
                        </div>
                        <div className='info-col'>
                            <div className='info-item'>
                                <span className='title'>
                                    Skýring
                                </span>
                                <span className='value'>
                                    {info?.property ? info?.property[0].units[0].description : '-'}
                                </span>
                            </div>
                        </div>
                    </div>
                    <PropertyReportBody eventId={id} report={report} propertyNumber={propertyNumber} documentsAllowed={documentsAllowed} />
                </div>
            </>
        }
        return <div className='shell'>
            <HeadSection
                title={
                    <div>
                        <div className='info-col-wrapper'>
                            <div className='info-col'>
                                <div className='info-item'>
                                    <span className='title'>
                                        Landnúmer
                                    </span>
                                    <span className='value'>
                                        {report.overview.property?.land?.land?.land_number}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                showRecentReports
            />
            <div className='property-page-body'>
                <DisplayBox
                    title='Grunnupplýsingar'
                    asideComponent={
                        <div className='report-aside-component'>
                            <div className='link-wrapper'>
                                <Link
                                    targetBlank
                                    url={`/Pdf/Fasteignaskra-Land/${id}`}
                                    icon='pdf'
                                >
                                    Sækja skýrslu
                                </Link>
                            </div>
                            <div className='italic-text'>
                                Skýrsla keypt: {report.eventDate}
                            </div>
                        </div>
                    }
                    className='property-report-box'
                >
                    <div className='report-section'>
                        <div className='vertical-table-wrapper'>
                            <VerticalTable
                                data={[report.overview.property?.land?.land]}
                                columns={[{
                                    title: 'Landnúmer',
                                    renderCell: (land) => land?.land_number
                                }, {
                                    title: 'Flatarmál',
                                    renderCell: (land) => `${land?.area} ${land?.area_unit}`
                                }]}
                                //     title: 'Heitisnúmer',
                                //     renderCell: (land) => land?.name_number
                                // }, {
                                //     title: 'Byggð',
                                //     renderCell: (land) => land?.city
                                // }, {
                                //     title: 'Lóðarnúmer',
                                //     renderCell: (land) => land?.plot_number
                                // }]}
                                tableSize='lg'
                            />
                            <VerticalTable
                                data={[report.overview.property?.land?.land]}
                                columns={[{
                                //     title: 'Flatarmál',
                                //     renderCell: (land) => `${land?.area} ${land?.area_unit}`
                                // }, {
                                    title: 'Lóðarmat',
                                    renderCell: (land) => `${formatNumber(land?.land_estimation, '-', 0)} kr.`
                                }, {
                                    title: 'Notkun',
                                    renderCell: (land) => land?.usage
                                }]}
                                tableSize='lg'
                            />
                        </div>
                    </div>
                    {report.overview.property?.land?.land?.owners &&
                        <div className='report-section'>
                            <h4>Eigendur</h4>
                            <Table
                                data={report.overview.property.land.land.owners}
                                columns={[{
                                    title: 'Nafn',
                                    renderCell: ({name}) => name,
                                    textAlign: 'left'
                                }, {
                                    title: 'Kennitala',
                                    renderCell: ({ssn}) => ssn,
                                    textAlign: 'left'
                                // }, {
                                //     title: 'Hlutfall',
                                //     renderCell: ({owned_part}) => convertToPercentage(owned_part === undefined || owned_part === null ? null : owned_part/100, true, 1),
                                //     textAlign: 'right'
                                }, {
                                    title: 'Kaup',
                                    renderCell: ({buy_date}) => getTableDate(buy_date ?? null, 'DD/MM/YYYY', '.'),
                                    textAlign: 'right'
                                }, {
                                    title: 'Afhending',
                                    renderCell: ({receive_date}) => getTableDate(receive_date ?? null, 'DD/MM/YYYY', '.'),
                                    textAlign: 'right'
                                }]}
                                tableSize='lg'
                            />
                        </div>
                    }
                </DisplayBox>

                {report.overview.bondDetails && 
                        <>
                            <h2 className='bonds-heading'>Veðbandayfirlit</h2>
                            <DisplayBox
                                className='property-report-box'
                            >
                                {report.overview.bondDetails.bondDetails?.bondDetails?.owners?.map((owner, index) => (
                                    <div className='report-section' key={`bondowner_${index}`}>
                                        <h4>{owner.name}</h4>
                                        <div className='vertical-table-wrapper'>
                                            <VerticalTable
                                                data={[owner]}
                                                columns={[{
                                                    title: 'Kennitala',
                                                    renderCell: ({ssn}) => ssn
                                                }, {
                                                    title: 'Málsaðili',
                                                    renderCell: ({name}) => name
                                                }, {
                                                    title: 'Eignarhlutfall',
                                                    renderCell: ({owned_part}) => convertToPercentage(owned_part === undefined ? null : owned_part/100, true, 1)
                                                }, {
                                                    title: 'Tegund skjals',
                                                    renderCell: ({document_type}) => document_type
                                                }, {
                                                    title: 'Númer',
                                                    renderCell: ({document_number}) => document_number
                                                }]}
                                                tableSize='lg'
                                            />
                                            <VerticalTable
                                                data={[owner]}
                                                columns={[{
                                                    title: 'Staða skjals',
                                                    renderCell: ({document_status}) => document_status
                                                }, {
                                                    title: 'Útgáfudagur',
                                                    renderCell: ({document_published}) => padDateString(document_published)
                                                }, {
                                                    title: 'Móttökudagur',
                                                    renderCell: ({document_received}) => padDateString(document_received)
                                                }, {
                                                    title: 'Embættisnúmer',
                                                    renderCell: ({office_number}) => office_number
                                                }]}
                                                tableSize='lg'
                                            />
                                        </div>
                                    </div>
                                ))}
                                {report.overview.bondDetails.bondDetails?.bondDetails?.bonds?.map((bond, index) => (
                                    <div className='report-section' key={`bond_${index}`}>
                                        <h4>{bond.number}. veðréttur</h4>
                                        <div className='vertical-table-wrapper'>
                                            <VerticalTable
                                                data={[bond]}
                                                columns={[{
                                                    title: 'Upphæð',
                                                    renderCell: ({amount, currency}) => `${amount} ${currency}`
                                                }, {
                                                    title: 'Myntgengi',
                                                    renderCell: ({currency_rate}) => currency_rate
                                                }, {
                                                    title: 'Númer',
                                                    renderCell: ({id}) => id
                                                }, {
                                                    title: 'Tegund',
                                                    renderCell: ({type}) => type
                                                }, {
                                                    title: 'Verðbinding',
                                                    renderCell: ({index_status}) => index_status
                                                }]}
                                                tableSize='lg'
                                            />
                                            <VerticalTable
                                                data={[bond]}
                                                columns={[{
                                                    title: 'Vísitölustig',
                                                    renderCell: ({index_state}) => index_state
                                                }, {
                                                    title: 'Veðhafi',
                                                    renderCell: ({loaner}) => loaner
                                                }, {
                                                    title: 'Útgáfudagur',
                                                    renderCell: ({published}) => padDateString(published)
                                                }, {
                                                    title: 'Móttökudagur',
                                                    renderCell: ({received}) => padDateString(received)
                                                }]}
                                                tableSize='lg'
                                            />
                                        </div>
                                    </div>  
                                ))}
                                {report.overview.bondDetails.bondDetails?.bondDetails?.primary_allowances &&
                                    <div className='report-section'>
                                        <h4>Frumheimildir</h4>
                                        <Table
                                            data={report.overview.bondDetails.bondDetails.bondDetails.primary_allowances}
                                            columns={[{
                                                title: 'Númer',
                                                renderCell: ({document_number}) => document_number,
                                                textAlign: 'left'
                                            }, {
                                                title: 'Staða',
                                                renderCell: ({document_status}) => document_status,
                                                textAlign: 'left'
                                            }, {
                                                title: 'Tegund',
                                                renderCell: ({document_type}) => document_type,
                                                textAlign: 'left'
                                            }, {
                                                title: 'Útgáfudagur',
                                                renderCell: ({document_published}) => padDateString(document_published),
                                                textAlign: 'right'
                                            }, {
                                                title: 'Móttökudagur',
                                                renderCell: ({document_received}) => padDateString(document_received),
                                                textAlign: 'right'
                                            }]}
                                            tableSize='lg'
                                        />
                                    </div>
                                }
                                {report.overview.bondDetails.bondDetails?.bondDetails?.contracts &&
                                    <div className='report-section'>
                                        <h4>Efnisatriði</h4>
                                        <Table
                                            data={report.overview.bondDetails.bondDetails.bondDetails.contracts}
                                            columns={[{
                                                title: 'Númer',
                                                renderCell: ({document_number}) => document_number,
                                                textAlign: 'left'
                                            }, {
                                                title: 'Staða',
                                                renderCell: ({document_status}) => document_status,
                                                textAlign: 'left'
                                            }, {
                                                title: 'Embættisnúmer',
                                                renderCell: ({office_number}) => office_number,
                                                textAlign: 'left'
                                            }, {
                                                title: 'Tegund',
                                                renderCell: ({document_type}) => document_type,
                                                textAlign: 'left'
                                            }, {
                                                title: 'Lýsing',
                                                renderCell: ({description}) => description,
                                                textAlign: 'left'
                                            }]}
                                            tableSize='lg'
                                        />
                                    </div>
                                }
                            </DisplayBox>
                        </>
                    }
            </div>
            <Alert
                type='info'
                headText='Athugið' 
                text={
                    <>
                        <p>Birtar upplýsingar eru sóttar með tengingu við Fasteignaskrá Íslands. Allar upplýsingar eru veittar samkvæmt starfsreglum Fasteignaskrár og í samræmi við lög og reglur um persónuvernd og meðferð persónuupplýsinga.</p>
                        <p>Reynt er að tryggja að upplýsingar séu réttar en Keldan getur ekki ábyrgst áreiðanleika þeirra.</p>
                    </>
                }
            />
        </div>
    }

    return (
        <div className='main KCL_property-page'>
            <div className='main__inner fluid'>
                {displayReport()}
            </div>
        </div>
    );
}

export default ViewReport;