// Package imports:
import React from 'react'
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import Tooltip from '../Tooltip/Tooltip';
// Service imports:
import { formatNumber } from '../../services/utils';
// Type imports:
import { IKeldanApiPricingViewModel } from '../../types/HomeTypes';

interface IProps {
    groups: IKeldanApiPricingViewModel | null,
    className?: string,
}

const SubscriptionPricingTable: React.FC<IProps> = ({
    groups,
    className
}) => {
    const formatFreePrice = (price: number | null) => {
        if (price === null) {
            return <Tooltip
                text={<FontAwesomeIcon icon={faXmark} />}
                tooltip={<>Ekki í boði</>}
                noUnderline
            />
        }
        return formatNumber(price, null, 0, ' kr.')
    }
    const formatSubPrice = (price: number) => {
        if (price < 0) return "Umsamið"
        if (price !== 0) {
            return formatNumber(price, null, 0, ' kr.')
        }
        return "Innifalið"
    }
    return (
        <div className={cx('KCL_subscription-pricing-table', className)}>
            <table>
                <colgroup>
                    <col />
                    <col />
                    <col className="highlight" />
                </colgroup>

                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>stök skýrsla</th>
                        <th>Áskrift</th>
                    </tr>
                </thead>

                <tfoot>
                    <tr>
                        <td colSpan={2}>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                </tfoot>

                {groups && Object.keys(groups.products).map((key) => (
                    <tbody key={'table-' + key}>
                        <tr key={key}>
                            <td colSpan={2}><strong>{key}</strong></td>
                            <td>&nbsp;</td>
                        </tr>

                        {groups.products[key].map((entryItem, ind) => (
                            <tr key={ind}>
                                <td>{entryItem.name}</td>
                                <td>
                                    <div className={cx('flex items-center justify-center')}>
                                        <span>
                                            {formatFreePrice(entryItem.freePrice)}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div className={cx('flex items-center justify-center')}>
                                        <span>
                                            {formatSubPrice(entryItem.subPrice)}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                ))}
            </table>
        </div>
    )
}

export default SubscriptionPricingTable
