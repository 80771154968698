// Package imports:
import React from 'react';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import DocumentListTable from './DocumentListTable';
import Alert from '../../../ui-elements/Alert/Alert';
// Type imports:
import { IEventReport, OwnedReport } from '../../../types/KeldanTypes';
import { IPropertyDocumentListResult } from '../../../types/PropertyTypes';

interface IProps {
    isExample?: boolean,
    report: IEventReport<IPropertyDocumentListResult>,
    owned?: OwnedReport[],
    propertyNumber: string
}

const DocumentListReportBody: React.FC<IProps> = ({
    isExample,
    report,
    owned,
    propertyNumber
}) => {
    return (
        <div>
            <DisplayBox
                className='property-report-box'
                grayBackground={isExample}
                asideComponent={
                    <div className='report-aside-component'>
                        <div className='italic-text'>
                            Skýrsla keypt: {report.eventDate}
                        </div>
                    </div>
                }
            >
                {report.overview?.response.documents?.documents && report.overview.response.documents.documents.length > 0
                    ? <DocumentListTable doclist={report.overview.response.documents.documents} owned={owned} fastanr={propertyNumber} />
                    : <Alert type='info' headText="Engar niðurstöður fundust" />
                }
            </DisplayBox>
        </div>
    );
}

export default DocumentListReportBody;