// Package imports:
import React from 'react';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Table from '../../../ui-elements/Table/Table';
import Link from '../../../ui-elements/Link/Link';
import Alert from '../../../ui-elements/Alert/Alert';
// Type imports:
import { IVehicleReport } from '../../../types/VehicleTypes';

interface IProps {
    id?: string,
    eventId?: string,
    vehicleReport: IVehicleReport,
    isExample?: boolean
}

const VehiclesOwnedReportBody: React.FC<IProps> = ({
    id,
    eventId,
    vehicleReport,
    isExample
}) => {
    const displayTable = (type: 'owned' | 'no longer owned') => {
        const isCurrentValue = (type === 'owned') ? '1' : '0'
        const filteredCars = vehicleReport.carsReport.vehicleList.filter(vechile => vechile.isCurrent === isCurrentValue);

        if (filteredCars.length === 0) {
            if (type === 'owned') {
                return (
                    <Alert
                        type='info'
                        headText='Engin ökutæki í eigu'
                    />
                )
            } else {
                return (
                    <Alert
                        type='info'
                        headText='Engin ökutæki ekki lengur í eigu'
                    />
                )
            }
        }

        return (
            <Table
                data={filteredCars}
                tableSize='lg'
                columns={[{
                    title: 'Tegund ökutækis',
                    renderCell: ({ type }) => type,
                    textAlign: 'left'
                }, {
                    title: 'Skráningarnúmer',
                    renderCell: ({ regno }) => regno,
                    textAlign: 'left'
                }, {
                    title: 'Fastanúmer',
                    renderCell: ({ permno }) => permno,
                    textAlign: 'left'
                }, {
                    title: 'Verksmiðjunúmer',
                    renderCell: ({ vin }) => vin,
                    textAlign: 'left'
                }, {
                    title: 'Eigandi/Umráðamaður',
                    renderCell: ({ role }) => role,
                    textAlign: 'left'
                }, {
                    title: 'Eigandi',
                    renderCell: ({ owner }) => owner,
                    textAlign: 'left'
                }]}
            />
        )
    }

    return (
        <DisplayBox
            title='Ökutæki í eigu'
            className='KCL_ViewCompanyAssetsReport'
            asideComponent={(vehicleReport === null || vehicleReport instanceof Error)
                ? undefined
                : (
                    <div className='report-aside-component'>
                        <div className='link-wrapper'>
                            <Link
                                targetBlank
                                linkSize='15'
                                url={`/Pdf/Okutaeki-Eignastada?ssn=${id}&eventId=${eventId}`}
                                disabled={isExample}
                                icon='pdf'
                            >
                                Sækja skýrslu
                            </Link>
                        </div>
                        <div className='italic-text'>
                            Skýrsla keypt: {vehicleReport.eventDate}
                        </div>
                    </div>
                )
            }
            grayBackground={isExample}
        >
            <div className='main'>
                {displayTable('owned')}
                <h4 className="middle-h4">Ökutæki ekki lengur í eigu</h4>
                {displayTable('no longer owned')}
            </div>
        </DisplayBox>
    );
}

export default VehiclesOwnedReportBody;