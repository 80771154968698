// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import FinanceRefComp from '../../components/FinanceRefPage/FinanceRefPage';

const FinanceRef: React.FC = () => {
    return (
    <Entrypoint>
        <FinanceRefComp />
    </Entrypoint>
    );
}

export default FinanceRef;