// Package imports:
import React from 'react';
// Component imports:
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import ListarSerpontunContactForm from '../ContactForms/ListarSerpontunContactForm/ListarSerpontunContactForm';
import Link from '../../ui-elements/Link/Link';

const ListarSerpontun: React.FC = () => {
    return (
        <div className="KCL_listar-serpontun">
            <div className="main__inner">
                <div className="mini_shell">
                    <HeadSection
                        title="Sérpöntun á markhópalista"
                        summary={
                            <p>Afhending á sérpöntuðum markhópalista getur tekið 1-2 virka daga og er listinn afhentur sem Excel skjal. Verð miðast við 100 stærstu fyrirtækjalista og getur tekið breytingum eftir umfangi.</p>
                        }
                        actions={
                            <Link
                                url='/Verdskra#verdskra'
                                linkSize='16'
                                targetBlank
                                icon='forward'
                            >
                                Skoða verðskrá
                            </Link>
                        }
                    />
                    <div className="section__body">
                        <div className="section__body-inner">
                            <div className="section__content form form--contact">
                                <ListarSerpontunContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListarSerpontun;
