// Package imports:
import React from 'react';
// Component imports:
import CalculatedColoredNumber from '../../ui-elements/CalculatedColoredNumber/CalculatedColoredNumber';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import PriceRange from '../../ui-elements/PriceRange/PriceRange';
import LmdSingleLineTable from '../../ui-elements/Table/LmdSingleLineTable';
// Service imports:
import { formatNumber } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdIndexStatisticListShort } from '../../types/MarketTypes';
import { IndexTypes } from '../SingleIndexPage/SingleIndexPage';

interface IOwnProps {
    index?: string,
    setIndexName(name: string | undefined): void,
}
type Props = IDefaultProps & IOwnProps & React.HTMLAttributes<HTMLDivElement>;

const SingleIndex: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    index = 'OMXN40',
    setIndexName,
    ...props
}) => {
    const [indices] = useApiLmdData<IApiLmdIndexStatisticListShort[]>(
        `/v1/market_data/v1/keldan/indexes/index_statistics_list`,
        accessToken,
        refreshRateMs
    );

    const nameChanger = (symbol: IndexTypes) => {
        if(symbol === 'OMXN40'){
            return ('OMXN40 - Nordic')
        }
        if(symbol === 'OMXC20CAP'){
            return ('OMXC20CAP - Danmörk')
        }
        if(symbol ===  'OMXS30'){
            return ('OMXS30 - Svíþjóð')
        }
        if(symbol ===  'OMXH25'){
            return ('OMXH25 - Finnland')
        }
        return symbol;
    }

    const handleData = () => {
        if (indices.data === null) return { data: null, error: indices.error };
        const indexData = indices.data.find((x) => x.Symbol === index);
        const error = indices.error ?? indexData === undefined ? new Error('No data') : null;
        return {
            data: indexData ?? null,
            error
        };
    }
    return (
        <DisplayBox
            title={nameChanger(index as IndexTypes)}
            {...props}
        >
            <LmdSingleLineTable
                apiData={handleData()}
                tableSize='lg'
                columns={[{
                    title: 'Gildi',
                    renderCell: ( {LastValue} ) => (formatNumber(LastValue, '-', 2)),
                    textAlign: 'left'
                }, {
                    title: 'Dagsbreyting',
                    renderCell: ( {YesterdaysValueChangePer} ) => (
                        <CalculatedColoredNumber
                            currentValue={YesterdaysValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    ),
                    textAlign: 'left'
                }, {
                    title: '1 Mán.',
                    renderCell: ( {Weeks4ValueChangePer} ) => (
                        <CalculatedColoredNumber
                            currentValue={Weeks4ValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    ),
                    textAlign: 'left'
                }, {
                    title: 'Innan árs',
                    renderCell: ( {YearValueChangePer  }) => (
                        <CalculatedColoredNumber
                            currentValue={YearValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    ),
                    textAlign: 'left'
                }, {
                    title: '1 Ár',
                    renderCell: ( { Months12ValueChangePer } ) => (
                        <CalculatedColoredNumber
                            currentValue={Months12ValueChangePer}
                            formatting='percent'
                            hasColor
                        />
                    ),
                    textAlign: 'left'
                }, {
                    title: 'Árs bil',
                    renderCell: ( { Months12LowValue, Months12HighValue, LastValue } ) => (
                        <PriceRange low={Months12LowValue} high={Months12HighValue} value={LastValue} />
                    ),
                    textAlign: 'left'
                }]}
            />
        </DisplayBox>
    );
}

export default SingleIndex;