// Package imports:
import React, { useState, useEffect } from "react";
// Component imports:
import Alert from "../../../ui-elements/Alert/Alert";
import Loading from "../../../ui-elements/Loading/Loading";
import ViewReport from "./ViewReport";
import ViewReportOld from "./ViewReportOld";
// Service imports:
import { GET_KELDAN_API_URL } from "../../../services/config";
import { ErrorMessages } from "../../../services/errorMessages";
// Type imports:
import { IEventReport, IKeldanApiResponse } from '../../../types/KeldanTypes';
import { IPropertySearchResult, IPropertySearchResultOld } from '../../../types/PropertyTypes';
import { Fetched } from '../../../types/Types';

interface IReportResponse {
    report?: IEventReport<IPropertySearchResult>,
    reportOld?: IEventReport<IPropertySearchResultOld>,
    documentsAllowed: boolean
}

const ViewReportWrapper: React.FC<{id: string}> = ({
    id
}) => {
    const [report, setReport] = useState<Fetched<IEventReport<IPropertySearchResult>>>(null);
    const [reportOld, setReportOld] = useState<IEventReport<IPropertySearchResultOld> | null>(null);
    const [documentsAllowed, setDocumentsAllowed] = useState(false);

    const fetchReport = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Property/GetReport/${id}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            });
            if (!response.ok) {
                setReport(new Error(ErrorMessages.RequestFailed));
                return;
            }
            if (response.redirected) {
                window.location.href = response.url;
                return;
            }
            const responseBody: IKeldanApiResponse<IReportResponse> = await response.json();
            const { result, message } = responseBody;
            if (result) {
                if (result.report) {
                    setReport(result.report);
                } else if (result.reportOld) {
                    setReportOld(result.reportOld);
                }
                setDocumentsAllowed(result.documentsAllowed);
            } else if (message) {
                setReport(new Error(message));
            } else {
                setReport(new Error(ErrorMessages.ErrorInRequest));
            }
        } catch (e) {
            setReport(new Error(ErrorMessages.OtherShort));
        }
    }

    useEffect(() => {
        fetchReport();
    }, []);

    if (report instanceof Error) {
        return <Alert type='error' headText={report.message} />
    }
    if ((report === undefined || report === null) && reportOld === null) {
        return <Loading />
    }
    if (report) {
        return <ViewReport id={id} report={report} documentsAllowed={documentsAllowed} />
    }
    if (reportOld !== null) {
        return <ViewReportOld id={id} report={reportOld} documentsAllowed={documentsAllowed} />
    }
    return null;
}

export default ViewReportWrapper;