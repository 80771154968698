// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import IndicesPage from '../../components/IndicesPage/IndicesPage';

const Indices: React.FC = () => {
    return (
        <Entrypoint>
            <IndicesPage />
        </Entrypoint>
    );
}

export default Indices;