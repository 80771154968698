// Package imports:
import React, { useEffect, useState } from 'react';
// Component imports:
import Link from '../../../ui-elements/Link/Link';
import Modal from '../../../ui-elements/Modal/Modal';
import Alert from '../../../ui-elements/Alert/Alert';
import Table from '../../../ui-elements/Table/Table';
// Service imports:
import { formatNumber } from '../../../services/utils';
// Type imports:
import { ICompanyValuation, ICompanyViewModel } from '../../../types/CompanyTypes';
import CompanyTitleMini from '../CompanyTitle/CompanyTitleMini';

interface ITableLine {
    name: string,
    value: number | null
}

type Props = ICompanyViewModel & { data: ICompanyValuation };

const ValuationCalculations: React.FC<Props> = ({
    id,
    accessToken,
    data
}) => {
    const [ calculationsOpen, setCalculationsOpen ] = useState(false);
    const openCalculations = () => setCalculationsOpen(true);
    const closeCalculations = () => setCalculationsOpen(false);
    const [ tableData, setTableData ] = useState<ITableLine[]>([]);

    useEffect(() => {
        setTableData([{
            name: 'EBIT',
            value: data.ebit
        }, {
            name: 'Skattar',
            value: data.taxes
        }, {
            name: 'Afskriftir',
            value: data.depreciation !== null && data.depreciation !== 0
                ? -1*data.depreciation
                : data.depreciation
        }, {
            name: 'Fjárfesting',
            value: data.investment
        }, {
            name: 'Breyting veltufjármuna',
            value: data.change_in_nwc
        }]);
    }, [data]);

    return (
        <>
            <Link
                className='more-link'
                url=''
                linkSize='14'
                onClick={(e) => {
                    e.preventDefault();
                    openCalculations();
                }}
                icon='more'
            >
                Sjá útreikning
            </Link>
            <Modal
                show={calculationsOpen}
                hasCloseButton
                onHide={closeCalculations}
                header={<h3>Frjálst sjóðstreymi</h3>}
                body={<div>
                    <CompanyTitleMini id={id} accessToken={accessToken} />
                    <Table
                        data={tableData}
                        columns={[{
                            title: 'Skýring',
                            renderCell: ({name}) => name,
                            textAlign: 'left'
                        }, {
                            title: 'Upphæð',
                            renderCell: ({value}) => formatNumber(value, '-', 0, ' kr.'),
                            textAlign: 'right'
                        }]}
                        tableSize='lg'
                    />
                    <div className='final-value-row paragraph--bold'>
                        <span className='text'>Samtals frjálst sjóðstreymi</span>
                        <span className='value'>{formatNumber(data.free_cashflow, '-', 0, ' kr.')}</span>
                    </div>
                    <Alert
                        type='info'
                        headText='Fyrirvari'
                        text={<p>
                            Verðmatið er ekki fjárfestingarráðgjöf og skal ekki notað til ákvörðunartöku auk þess sem upplýsingar í verðmatinu kunna að vera rangar.<br />
                            Keldan ehf. ber enga ábyrgð á röngum upplýsingum eða fjárhagstjóni sem kann að verða vegna verðmatsins.
                        </p>}
                    />
                </div>}
                className='valuation-calculations'
            />
        </>
    );
}

export default ValuationCalculations;