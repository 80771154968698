// Package imports:
import React, { useState } from 'react';
import cx from 'classnames';
import InnerHTML from 'dangerously-set-html-content';
import { v4 as uuidv4 } from 'uuid';

type DivProps = React.HTMLAttributes<HTMLDivElement>;

interface IAdMetadata {
    source?: string,
    script?: string,
    link?: string,
    expired?: boolean,
    expires?: string,
    show?: string,
    cat?: string
}

export interface IAdProps {
    path: string,
    metadata: IAdMetadata,
    height: number,
    width: number,
    id?: string,
}

type Props = DivProps & IAdProps;

const Ad: React.FC<Props> = ({
    path,
    metadata,
    height,
    width,
    id,
    ...props
}) => {
    const name = path.split('/')[path.split('/').length - 1].split('.')[0];
    const [ isIframeLoaded, setIsIframeLoaded ] = useState(false);
    const getAdBody = () => {
        const adType = path.split('.')[path.split('.').length - 1];
        switch (adType) {
            case 'mp4':
                return (
                    <a href={metadata.link} target="_blank" rel="noreferrer">
                        <video autoPlay loop muted playsInline width={width} height={height}>
                            <source src={path} type="video/mp4" />
                        </video>
                    </a>
                );
            case 'iframe':
                return (
                    <iframe
                        name={uuidv4()}
                        style={{
                            visibility: (isIframeLoaded) ? 'visible' : 'hidden'
                        }}
                        onLoad={() => setIsIframeLoaded(true)}
                        src={metadata.source}
                        height={height+4}
                        width={width}
                        title="adtitle"
                    />
                );
            case 'script':
                return <InnerHTML html={metadata.script ?? ''} />;
            default:
                return (
                    <a href={metadata.link} target="_blank" rel="noreferrer">
                        <img
                            src={path}
                            alt=""
                            width={width}
                            height={height}
                        />
                    </a>
                );
        }
    }
    
    return (
        <div
            id={id}
            data-name={`Ad_${name}`}
            className={cx("KCL_ad", {'iframe-ad': path.endsWith('iframe') || path.endsWith('script')}, `ad${width}x${height}`)}
            {...props}
        >
            {getAdBody()}
        </div>
    )
}

export default Ad
