// Package imports:
import React from 'react';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Alert from '../../../ui-elements/Alert/Alert';
import PropertyOverviewTable from './PropertyOverviewTable';
// Type imports:
import { IEventReport } from '../../../types/KeldanTypes';
import { IPropertySearchResult } from '../../../types/PropertyTypes';

interface IProps {
    isExample?: boolean,
    report: IEventReport<IPropertySearchResult>,
    bondsAllowed?: boolean,
    documentsAllowed?: boolean
}

const OverviewReportBody: React.FC<IProps> = ({
    isExample,
    report,
    bondsAllowed,
    documentsAllowed
}) => {
    return (
        <>
            <div className='property-page-body'>
                <DisplayBox
                    className='property-report-box'
                    grayBackground={isExample}
                    asideComponent={
                        <div className='report-aside-component'>
                            <div className='italic-text'>
                                Skýrsla keypt: {report.eventDate}
                            </div>
                        </div>
                    }
                >
                    <PropertyOverviewTable
                        propertylist={report.overview}
                        bondsAllowed={bondsAllowed}
                        documentsAllowed={documentsAllowed}
                    />
                </DisplayBox>
            </div>
            <Alert
                type='info'
                headText='Athugið' 
                text={<>
                    <p>Birtar upplýsingar eru sóttar með tengingu við Fasteignaskrá Íslands. Allar upplýsingar eru veittar samkvæmt starfsreglum Fasteignaskrár og í samræmi við lög og reglur um persónuvernd og meðferð persónuupplýsinga.</p>
                    <p>Reynt er að tryggja að upplýsingar séu réttar en Keldan getur ekki ábyrgst áreiðanleika þeirra.</p>
                </>}
            />
        </>
    );
}

export default OverviewReportBody;