// Package imports:
import React, { useMemo } from 'react';
// Component imports:
import ColoredNumber, { BackgroundSign, Sign } from '../ColoredNumber/ColoredNumber';
// Service imports:
import { convertToPercentage, formatNumber } from '../../services/utils';

interface IProps {
    currentValue: number | null,
    previousValue?: number | null,
    formatting?: 'percent' | 'decimal' | 'integer' | 'yield',
    formatter?(val: number | null): string | null,
    hasArrow?: boolean,
    hasBackground?: boolean,
    hasColor?: boolean
}

const CalculatedColoredNumber: React.FC<IProps>= ({
    currentValue,
    previousValue,
    formatting,
    formatter,
    hasArrow,
    hasBackground,
    hasColor
}) => {
    const sign: Sign = useMemo(() => {
        if (!hasColor || !currentValue) return 'neutral';
        if (currentValue > 0) return 'pos';
        return 'neg';
    }, [currentValue, hasColor]);

    const backgroundSign: BackgroundSign | undefined = useMemo(() => {
        if (!hasBackground || previousValue === undefined
            || previousValue === null || currentValue === null) {
            return undefined;
        }
        if (currentValue > previousValue) {
            return 'pos';
        }
        if (currentValue < previousValue) {
            return 'neg';
        }
        return undefined;
    }, [currentValue, previousValue, hasBackground]);

    const formattedValue = useMemo(() => {
        if (formatter !== undefined) return formatter(currentValue);
        if (formatting === 'percent') return convertToPercentage(currentValue, true, 2);
        if (formatting === 'decimal') return formatNumber(currentValue);
        if (formatting === 'integer') return formatNumber(currentValue, '-', 0);
        if (formatting === 'yield') return formatNumber(currentValue ? currentValue*10000 : 0, '-', 0, 'pkt.');
        return currentValue?.toString();
    }, [currentValue, formatting, formatter]);
    
    return (
        <ColoredNumber
            value={formattedValue}
            sign={sign}
            hasArrow={hasArrow}
            background={backgroundSign}
        />
    );
}

export default CalculatedColoredNumber;