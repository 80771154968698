// Package imports:
import React from 'react';
// Component imports:
import GemmaqLicenseComponent from '../../components/GEMMAQLicense/GEMMAQLicense';

const GEMMAQLicense: React.FC = () => {
    return (
        <GemmaqLicenseComponent />
    );
}

export default GEMMAQLicense;