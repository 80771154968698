// Package imports:
import React from 'react';
// Component imports:
import Tabs from '../../ui-elements/Tabs/Tabs';
import PropertySearch from './PropertySearch/PropertySearch';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import AssetsSearch from './AssetsSearch/AssetsSearch';
import GangverdSearch from './GangverdSearch/GangverdSearch';
import DocumentSearch from './DocumentSearch/DocumentSearch';
import Kaupsamningar from '../Kaupsamningar/Kaupsamningar';
import Leigusamningar from '../Leigusamningar/Leigusamningar';
// Type imports:
import { IPropertySearchViewModel } from '../../types/PropertyTypes';

const PropertySearches: React.FC<IPropertySearchViewModel> = ({
	isAuthenticated,
	bondsAllowed,
	individualAssetsAllowed,
	documentsAllowed
}) => {
	return (
		<div className="main KCL_Fasteignir">
			<div className='section__body'>
				<div className='shell'>
					<HeadSection
						title='Fasteignir'
						showRecentReports={isAuthenticated}
					/>
				</div>
				<Tabs  
					tabsSize="lg"
					saveTabToUrl
					tabs={[{
						id: "Fasteignaskra",
						label: "Fasteignaskrá",
						component: <PropertySearch isAuthenticated={isAuthenticated} bondsAllowed={bondsAllowed} />
					}, {
						id: "Thinglyst-Skjol",
						label: "Þinglýst skjöl",
						component: <DocumentSearch isAuthenticated={isAuthenticated} documentsAllowed={documentsAllowed} />
					}, {
						id: "Eignastada",
						label: "Eignastaða",
						component: <AssetsSearch isAuthenticated={isAuthenticated} individualAssetsAllowed={individualAssetsAllowed} />
					}, {
						id: "Gangverd",
						label: "Verðvísir Gangverðs",
						component: <GangverdSearch isAuthenticated={isAuthenticated} />
					}, {
						id: "Kaupsamningar",
						label: "Kaupsamningar",
						component: <Kaupsamningar isAuthenticated={isAuthenticated} documentsAllowed={documentsAllowed} />
					}, {
						id: "Leigusamningar",
						label: "Leigusamningar",
						component: <Leigusamningar isAuthenticated={isAuthenticated} documentsAllowed={documentsAllowed} />
					}]}
					shellHead
				/>
			</div>
		</div>
	)
}

export default PropertySearches;