// Package imports:
import React, { useContext } from 'react';
import cx from 'classnames';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Alert from '../../../ui-elements/Alert/Alert';
import Loading from '../../../ui-elements/Loading/Loading';
import BlurredCompanyInfoBox from '../BlurredCompanyInfoBox/BlurredCompanyInfoBox';
// Service imports:
import { convertToPercentage } from '../../../services/utils';
// Type imports:
import { ICompanyOwners, ICompanyViewModel } from '../../../types/CompanyTypes';
import { useFetchedApiLmdData } from '../../../services/apiHooks';
// Context imports:
import { AccessTokenContext } from '../../../contexts/AccessTokenContext';

const CompanyOwners: React.FC<ICompanyViewModel> = ({
    id,
    isAuthenticated
}) => {
    return (
        <DisplayBox
            className='KCL_company-owners'
            title='Raunverulegir eigendur'
        >
            {isAuthenticated
                ? <CompanyOwnersDisplay id={id} />
                : <BlurredCompanyInfoBox boxType='owners' />
            }
        </DisplayBox>
    );
}

const CompanyOwnersDisplay: React.FC<ICompanyViewModel> = ({
    id
}) => {
    const accessToken = useContext(AccessTokenContext);
    const data = useFetchedApiLmdData<ICompanyOwners[]>(`/v1/market_data/v1/rsk/company_owners/${id}`, accessToken);

    if (data instanceof Error) return <Alert type='alert' headText={data.message} />
    if (data === null) return <Loading />
    if (data.length === 0) return <Alert type='info' headText='Engin gögn fundust' />
    const sortedData = [...data].sort((a, b) => b.Eignarhlutur - a.Eignarhlutur)
    return <div className={cx('owners-boxes-wrapper', { 'odd-owners': data.length % 2 === 1 })}>
        {sortedData.map((owner) => !owner.AdiliNafn && !owner.AdiliFaedingarmanudur && !owner.AdiliRikisfang && !owner.Eignarhlutur
            ? null
            : <div className='company-owner-box' key={owner.AdiliNafn + owner.AdiliFaedingarmanudur}>
                <div>
                    <div className='owner-name'>{owner.AdiliNafn}</div>
                    <div className='owner-info'>Fæðingarár/mánuður: {owner.AdiliFaedingarmanudur?.replace('-', '/')}</div>
                    <div className='owner-info'>Búseta: {owner.AdiliBusetuland} Ríkisfang: {owner.AdiliRikisfang}</div>
                    <div className='owner-percentage'>Eignarhlutur: {convertToPercentage(owner.Eignarhlutur / 100, true, 2)}</div>
                </div>
            </div>
        )}
    </div>
}

export default CompanyOwners;