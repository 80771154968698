// Package imports:
import React, { useState, useEffect, useRef } from 'react';
import { Swiper } from 'swiper/react';
import cx from 'classnames';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons'

type DivProps = React.HTMLAttributes<HTMLDivElement>;

interface IOwnProps {
    activeSlideIndex?: number,
    onSlideChange(n: number): void,
    loop?: boolean,
    pagination?: boolean,
    perView?: number |'auto',
    centerSlides?: boolean,
    hideActions?: boolean,
    breakpointOptions?: object,
    onInit(): void,
    initialSlide?: number,
    spaceBetween?: number,
}

type Props = DivProps & IOwnProps;

SwiperCore.use([Navigation, Pagination]);

const Slider: React.FC<Props> = ({
    activeSlideIndex = 0,
     onSlideChange,
     loop = false, 
     pagination = false,
     perView = 1,
     centerSlides = false,
     hideActions = false,
     breakpointOptions = {},
     onInit = () => {},
     initialSlide = 0,
     className,
     spaceBetween = 0,
     children
}) => {
    const [firstSwiper, setFirstSwiper] = useState<SwiperCore>();
    const prevArrow = useRef(null)
    const nextArrow = useRef(null)

    useEffect(() => {
        if (loop) {
            firstSwiper && firstSwiper.slideToLoop(activeSlideIndex)
        } else {
            firstSwiper && firstSwiper.slideTo(activeSlideIndex)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSlideIndex]);

    const handleChange = (swiper: SwiperCore) => {
        if (onSlideChange) {
            onSlideChange(swiper.realIndex)
        }
    }
    const navOptions = {
        nextEl: nextArrow.current,
        prevEl: prevArrow.current
    }

    const paginationOptions = {
        el: '.slider__pagination',
        clickable: true
    }

    return (
        <div className={cx('KCL_slider', className)}>
            <Swiper
                initialSlide={initialSlide}
                slidesPerView={perView}
                loop={loop}
                navigation={navOptions}
                onSwiper={setFirstSwiper}
                onInit={onInit}
                onSlideChange={(swiper) => handleChange(swiper)}
                pagination={ pagination ? paginationOptions : pagination}
                centeredSlides={centerSlides}
                spaceBetween={spaceBetween}
                breakpoints={{
                    // when window width is >= 640px
                    320: {
                        slidesPerView: 1,
                        centeredSlides: false
                    },
                    // when window width is >= 768px
                    1024: {
                        slidesPerView: perView,
                        centeredSlides: centerSlides
                    },
                    ...breakpointOptions
                }}
            >
                {children}
            </Swiper>

            {!hideActions &&
                <div className="slider__actions">
                    <button
                        ref={prevArrow} 
                        className={cx('slider__button slider__prev')}
                        title="Fyrri"
                    >
                    <i className="icon-fa">
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </i>
                    </button>

                    <div className="slider__pagination" />

                    <button 
                        ref={nextArrow} 
                        className={cx('slider__button slider__next')}
                        title="Næsta"
                    >
                    <i className="icon-fa">
                        <FontAwesomeIcon icon={faAngleRight} />
                    </i>
                    </button>
                </div>    
            }
        </div>
    );
}

export default Slider;