// Package imports:
import { useEffect, useState } from 'react';

const UseScrollSpy = (initialSection: string, parentClass: string) => {
    const offsetElement = '.KCL_header';
    const [currentSection, setCurrentSection] = useState(initialSection);

    const getHeaderHeight = () => {
        const headerElement = document.querySelector(offsetElement)
        return headerElement?.clientHeight ?? 0;
    };

    const scrollToSection = ((id: string) => {
        if (currentSection !== id) {
            const scrollToElement = document.getElementById(id);
            if (scrollToElement === null) return;
            const elementScrollDistance = scrollToElement.offsetTop - getHeaderHeight() - 30;

            window.scrollTo({
                top: elementScrollDistance,
                behavior: 'smooth'
            });
        }

        setCurrentSection(id)
    });

    const handleScrolling = () => {
        const scrollElements = document.querySelectorAll(`.${parentClass} .scrollspy-section`);

        scrollElements.forEach(el => {
            const elementTop = el.getBoundingClientRect().top;
            const elementBottom = el.getBoundingClientRect().bottom;

            if (elementTop <= getHeaderHeight() && elementBottom >= getHeaderHeight()) {
                const id = el.id;
                setCurrentSection(id)
            }
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScrolling);
        return () => {
            window.removeEventListener('scroll', handleScrolling);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        currentSection,
        scrollToSection
    }
}

export default UseScrollSpy