import React, { useEffect, useState } from 'react';
import { GET_KELDAN_API_URL } from '../../services/config';
import Table from '../../ui-elements/Table/Table';
import { MAX_RESULTS, ResultStatus } from './SearchPage';
import Button from '../../ui-elements/Button/Button';
import { ErrorMessages } from '../../services/errorMessages';
import Loading from '../../ui-elements/Loading/Loading';
import { useBuyingProcess } from '../../services/buyhook';
import { stripSSN } from '../../services/utils';
import { IKeldanApiResponse } from '../../types/KeldanTypes';
import { IndividualSearch, IndividualSearchResult } from '../../types/IndividualTypes';
import Bugsnag from '@bugsnag/js';
import Link from '../../ui-elements/Link/Link';

interface IPurchaseThjodskraInformation {
    ssn: string,
    whereClick: 'form' | 'list'
}

const ThjodskraSearch:React.FC<{
    term: string | null,
    onResultsChange: (hasResults: ResultStatus) => void;
    isAuthenticated?: boolean;
}> = ({term, onResultsChange, isAuthenticated}) => {

    const [ searchResults, setSearchResults ] = useState<IndividualSearchResult[]>();
    const [isItemLoading, setIsItemLoading] = useState(false);
    const { tryToBuyItem, tryPurchaseItem, setToastError, resetState, ModalsAndToasts } = useBuyingProcess(async (purchaseThjodskraInfo: IPurchaseThjodskraInformation, modalReference: string) => {
        const { ssn: rawSsn } = purchaseThjodskraInfo;
        const ssn = stripSSN(rawSsn);
        try {
            const requestQuery = new URLSearchParams({
                id: ssn ?? '', 
                reference: modalReference
            })
            const url = `${GET_KELDAN_API_URL()}/Individual/BuyProfile?${requestQuery.toString()}`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                    return;
                }
                const responseBody: IKeldanApiResponse<IndividualSearch> = await response.json();
                const { message } = responseBody;
                if (message === ErrorMessages.NoCard) {
                    resetState('no card');
                } else if (message) resetState({
                    type: 'alert',
                    headText: message
                });
                else resetState('bad request');
            } else {
                resetState('response not ok');
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            resetState('network error');
        }
    }, 'þjóðskrá')
    
    const resetComponent = (message: ResultStatus) => {
        setSearchResults([]);
        onResultsChange(message);
        setIsItemLoading(false);
    };
    
    const handleErrorResponse = (type: 'alert'| 'error', message: string) => {
        if(message === 'no card') {
            resetState('no card');
            setToastError({
                type: type,
                headText:'Greiðsluupplýsingar vantar',
                text: <p>Kreditkortaupplýsingar eru ekki fullnægjandi. Vinsamlegast yfirfarið <Link href='/Min-Kelda/Stillingar' linkSize='16'>kortaupplýsingar</Link>.</p>
            });
        }
        else resetComponent('no results');
    };
    const thjodskraSearch = async () => {
        if (!term || term.length < 1) {resetComponent('no results');return};
        try {
            setIsItemLoading(true);
            const requestQuery = new URLSearchParams({ term });
            const url = `${GET_KELDAN_API_URL()}/Individual/Search`;

            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
                body: requestQuery,
            });

            if (!response.ok) {
                handleErrorResponse('error', 'response not ok');
                return;
            }

            const responseBody: IKeldanApiResponse<IndividualSearch> = await response.json();
            setIsItemLoading(false);
            if (!responseBody.success) {
                const { message } = responseBody;
                const toastError = message === ErrorMessages.NoCard ? 'no card' : 'bad request';
                handleErrorResponse('alert', toastError)
                return;
            }

            if (responseBody.result && responseBody.result.list.list.list.length > 0) {
                setSearchResults(responseBody.result.list.list.list);
                onResultsChange('has results');
            } else {
                resetComponent('no results');
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            handleErrorResponse('error', 'network error');
        }
    };
    useEffect(() => {
        isAuthenticated  ? thjodskraSearch() : resetComponent('no results');
    }, [isAuthenticated]);

    if (term === null) return null;
    if (term.length < 1) return null;
    if(!isAuthenticated) return null;
    if(searchResults === null  || searchResults === undefined || searchResults.length === 0) return <>{null}<ModalsAndToasts/></>

    return (
        <div className='table-section thjodskra-search'>
            <h4>Þjóðskrá</h4>
            {
            isItemLoading ? <Loading /> : 
            <Table
                tableSize='lg'
                data={searchResults.slice(0,MAX_RESULTS) ?? []}
                columns={[{
                    title: 'Nafn',
                    renderCell: ({ name }) => name,
                    textAlign: 'left',
                    simpleSortable: ({ name }) => name,
                }, {
                    title: 'Heimili',
                    renderCell: ({ address }) => address,
                    textAlign: 'left',
                    simpleSortable: ({ address }) => address
                }, {
                    title: '',
                    renderCell: ({ ssn }) => (
                        <Button
                            onClick={() => tryPurchaseItem({
                                ssn,
                                whereClick: 'list'
                            }, 'confirm-modal-with-reference')}
                            showLoader={(
                                tryToBuyItem !== null &&
                                tryToBuyItem.ssn === ssn &&
                                tryToBuyItem.whereClick === 'list'
                            )}
                            size='sm'
                            buttonType='buy'
                        >
                            Kaupa
                        </Button>
                    ),
                }]}
            />}
            <ModalsAndToasts />
        </div>
    );
} 
export default ThjodskraSearch;