// Package imports:
import { Form, Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import * as Yup from 'yup';
// Component imports:
import Input from '../../ui-elements/Input/Formik/Input';
import Alert from '../../ui-elements/Alert/Alert';
// Service imports:
import { GET_KELDAN_API_URL } from '../../services/config';
import { isCompany, stripSSN } from '../../services/utils';
import { ErrorMessages } from '../../services/errorMessages';
// Type imports:
import { IKeldanApiResponse } from '../../types/KeldanTypes';

interface IIsExcellentResponse {isExcellent: boolean, name: string}

const ExcellentSearch: React.FC<{year: number, showDisclaimer?: boolean}> = ({
    year,
    showDisclaimer = false
}) => {
    const [ contactResponse, setContactResponse ] = useState<JSX.Element>()
    const [ loading, setLoading] = useState(false);
    
    const handleSubmit = async (values: IFormValues) => {
        setLoading(true)
        const strippedSSN = stripSSN(values.ssn);
        try {
            const url = `${GET_KELDAN_API_URL()}/Company/IsExcellent/${strippedSSN}?year=${year}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            });
            if(response.ok){
                setLoading(false);
                const responseBody: IKeldanApiResponse<IIsExcellentResponse> = await response.json();
                if(responseBody.result){
                    if(responseBody.result.isExcellent) {
                        setContactResponse(<span>{`${responseBody.result.name} er á lista yfir fyrirmyndarfyrirtæki ${year}`}</span>)
                    } else {
                        setContactResponse(
                            <span>{`${(responseBody.result.name === null)
                                ?   values.ssn
                                :   responseBody.result.name} er ekki á lista yfir fyrirmyndarfyrirtæki ${year}`}
                            </span>)
                    }
                }
            } else {
                setLoading(false)
                setContactResponse(<Alert type="error" headText={ErrorMessages.RequestFailed} />);
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setLoading(false)
            setContactResponse(<Alert type="error" headText={ErrorMessages.NetworkError} />);
        }
    }
    
    const subFormComponent = useMemo(() => {
        return ((props: { values: IFormValues }) => (
            <SubFormComponent
                values={props.values}
                contactResponse={contactResponse}
                showDisclaimer={showDisclaimer}
            />
        ))
    }, [loading, contactResponse]);
    return (
        <Formik
            initialValues={{
                ssn: '',
            }}
            validationSchema={Yup.object({
                ssn: Yup.string()
                    .min(10, 'Kennitala þarf að innihalda 10 tölustafi')
                    .max(11, 'Kennitala má ekki vera meira en 10 tölustafir')
                    .matches(/^(\d{6}-\d{4})|^(\d+)$/, 'Kennitala má einungis innihalda tölustafi')
                    .test('ogild-kennitala', 'Ógild fyrirtækja kennitala', (value) => isCompany(value)),
            })}
            onSubmit={handleSubmit}
            component={subFormComponent}
        />
    );
}
export default ExcellentSearch;

interface IFormValues {
    ssn: string | undefined,
}
interface ISubFormProps {
    values: IFormValues,
    contactResponse?: JSX.Element,
    showDisclaimer?: boolean
}
const SubFormComponent: React.FC<ISubFormProps> = ({
    values,
    contactResponse,
    showDisclaimer = false
}) => {
    return (
        <Form>
            <div className="KCL_excellent_search">
                <h5>Er þitt fyrirtæki til fyrirmyndar í ár?</h5>
                <div className="form__row">
                    <div className="form__col">
                        <Input
                            label='Leita að kennitölu fyrirtækis'
                            name="ssn"
                            id="ssn"
                            value={values.ssn}
                            withSearchGlassIcon
                        />
                    </div>
                </div>
                <div className="response paragraph--bold">
                    {contactResponse ? <>{contactResponse}<br /></> : null}
                    {/* Search disclaimer! */}
                    {showDisclaimer && <span className='disclaimer'>Athugið að listinn er enn í vinnslu og því ekki tæmandi.</span>}
                </div>
            </div>
        </Form>
    );
}