// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import PEPSearchPage from '../../components/PEP/PepPage';
import { Subscription } from '../../types/KeldanTypes';

interface IProps {
    isAuthenticated: boolean,
    subscriptions: Subscription[]
}

const Search: React.FC<IProps> = ({isAuthenticated, subscriptions}) => {
    return (
        <Entrypoint>
            <PEPSearchPage isAuthenticated={isAuthenticated} subscriptions={subscriptions}/>
        </Entrypoint>
    );
}

export default Search;