// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import PropertyGangverd from '../../components/Property/Gangverd/Gangverd';

const Gangverd: React.FC<{id?: string}> = ({
    id
}) => {
    return (
        <Entrypoint>
            <PropertyGangverd id={id} />
        </Entrypoint>
    );
}

export default Gangverd;