// Package imports:
import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
// Component imports:
import ChartLoading from '../../../ui-elements/ChartLoading/ChartLoading';
// Service imports:
import { displayQuantityShorthand, formatNumber } from '../../../services/utils';

HC_more(Highcharts);

type Language = 'is' | 'en';

type bubbleType = 'bubble';

export interface IBubbleChart {
    type: bubbleType,
    name: string, 
    color?: string,
    data: {
        x: number,
        y: number,
        z: number,
    }[]
}

interface IProps {
    data: Array<IBubbleChart>,
    lang?: Language,
    height?: number,
    showHeader?: boolean,
    grayBackground?: boolean
}


const BubbleChart: React.FC<IProps> = ({
    data,
    height = 300,
    grayBackground = false
}) => {
    // Div references.
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (data.length > 0 && divRef.current !== null) {
            
            Highcharts.chart(divRef.current, {
                chart: {
                    type: 'bubble',
                    plotBorderWidth: 0,
                    zoomType: 'xy',
                    height: height,
                    reflow: true,
                    animation: true,
                    style: {
                        fontFamily: "Roboto"
                    },
                    plotBackgroundColor: {
                        linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                        stops: [
                            [0.05, grayBackground ? '#F8F8FA' : '#FFF'],
                            [0.95, grayBackground ? '#FFF' : '#F8F8FA']
                        ]
                    },
                    backgroundColor: grayBackground ? '#F8F8FA' : '#FFFFFF'
                },
                title: {
                    text: ''
                },
            
                xAxis: {
                    title: {
                        text: 'AFKOMA'
                    },
                    labels: {
                        formatter: function() {
                            return displayQuantityShorthand(this.value as number) ?? this.value.toString();
                        },
                    }
                },
                credits: {
                    enabled: false
                },
                yAxis: {
                    gridLineDashStyle: 'Dash',
                    gridLineWidth: 1,
                    gridLineColor: '#D3D3D6',
                    opposite: false,
                    height: '100%',
                    startOnTick: false,
                    endOnTick: false,
                    title: {
                        text: 'TEKJUR'
                    },
                    labels: {
                        formatter: function() {
                            return displayQuantityShorthand(this.value as number) ?? this.value.toString();
                        },
                    },
                },

                legend: {
                    enabled: true,
                    align: 'center',
                    // verticalAlign: 'bottom',
                    itemStyle: {
                        color: '#4F5059',
                        fontSize: '13px',
                        fontWeight: '400',
                        lineHeight: '15px',
                    },
                    symbolWidth: 10,
                    x: 5,
                },
            
                tooltip: {
                    useHTML: true,
                    followPointer: true
                },
                plotOptions: {
                    bubble: {
                        opacity: 1,
                        tooltip: {
                            pointFormatter: function() {
                                return `Tekjur: ${formatNumber(this.y, '-', 0)} kr. </br> 
                                        Afkoma:  ${formatNumber(this.x, '-', 0)} kr.` 
                            },
                        }
                    },
                    series: {
                        marker: {
                            fillOpacity: 1,
                        },
                        dataLabels: {
                            enabled: true,
                            format: '{point.z}%',
                            verticalAlign: 'top',
                            style:{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '11px',
                                lineHeight: '13px',
                                color: '#232530',
                                textOutline: '0',
                            }
                            
                        }
                    }
                },
                series: data
            }, () => {});
        } else if (divRef.current !== null) {
            Highcharts.chart(divRef.current, {
                chart: {
                    type: 'bubble',
                    height: height,
                    style: {
                        fontFamily: "Roboto"
                    },
                    animation: false
                },
                title: {
                    x: 0,
                    floating: true,
                    y: 0.48*height,
                    style: {
                        fontSize: '16px',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto'
                    }
                },
                series: [{
                    type: 'pie',
                    innerSize: '40%',
                    data: [{
                        name: '',
                        y: 1,
                    }],
                    dataLabels: {
                        enabled: false
                    },
                }],
                exporting: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    enabled: false
                },
                legend: {
                    enabled: false
                }
            }, () => {});
        }
    }, [data]);
    if(data.length === 0) return <ChartLoading />
    return (
        <div className='bubble-chart'>
            <div ref={divRef}></div>
        </div>
    );
}

export default BubbleChart;