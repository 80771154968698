// Package imports:
import React from "react";
import * as Yup from 'yup';
import { Form, Formik } from "formik";
import Bugsnag from "@bugsnag/js";
// Component imports:
import Input from '../../../ui-elements/Input/Formik/Input';
import Button from '../../../ui-elements/Button/Button';
import Alert from "../../../ui-elements/Alert/Alert";
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from "../../../services/errorMessages";
// Type imports:
import { IMyKeldaSettingsResponse } from "../../../types/MyKeldaTypes";


interface IFormProps {
    values: IFormValues
}
interface IFormValues {
    oldPassword: string,
    newPassword: string,
    confirmNewPassword: string,
}

interface IOwnProps {
    contactResponse: (a: JSX.Element) => void,
}

const LykilordForm: React.FC<IOwnProps> = ({
    contactResponse,
}) => {
    const handleSubmit = async (values: IFormValues) => {
        try {
            contactResponse(<></>);
            const requestQuery = new URLSearchParams({
                oldPassword: values.oldPassword,
                newPassword: values.newPassword
            });
            const url = `${GET_KELDAN_API_URL()}/MyKelda/UpdateUserPassword`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const responseBody: IMyKeldaSettingsResponse = await response.json();
                    if (responseBody.saved) {
                        //reset form on success
                        values.oldPassword = '';
                        values.newPassword = '';
                        values.confirmNewPassword = '';
                        contactResponse(
                            <Alert
                                withCloseIcon
                                closeAlert={() => contactResponse(<></>)}
                                type="success"
                                headText={responseBody.info ?? 'Breytingar hafa verið vistaðar'} 
                            />);
                    }
                    else {
                        contactResponse(
                            <Alert 
                                withCloseIcon
                                closeAlert={() => contactResponse(<></>)}
                                type="error"
                                headText={responseBody.info ?? 'Breytingar hafa ekki verið vistaðar'} 
                            />);
                    }
                }
            } else {
                contactResponse(
                    <Alert 
                        withCloseIcon
                        closeAlert={() => contactResponse(<></>)}
                        type="error"
                        headText={ErrorMessages.RequestFailed} 
                    />);
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            contactResponse(
                <Alert
                    withCloseIcon
                    closeAlert={() => contactResponse(<></>)}
                    type="error"
                    headText={ErrorMessages.NetworkError} 
                />);
        }
    }

    const form: React.FC<IFormProps> = ({
        values
    }) => {
        return <Form>
            <div className="form__body">
                <div className="form__row">
                    <div className="form__col">
                        <Input 
                            name="oldPassword"
                            id="oldPassword"
                            className="field-input" 
                            label="Núverandi lykilorð" 
                            type="password"
                            autoComplete="new-password"
                            value={values.oldPassword}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__col">
                        <Input 
                            name="newPassword"
                            id="newPassword"
                            className="field-input" 
                            label="Nýtt lykilorð" 
                            type="password"
                            autoComplete="new-password"
                            value={values.newPassword}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__col">
                        <Input 
                            name="confirmNewPassword"
                            id="confirmNewPassword"
                            className="field-input" 
                            label="Nýtt lykilorð aftur" 
                            type="password"
                            autoComplete="new-password"
                            value={values.confirmNewPassword}
                        />
                    </div>
                </div>
                <div className="form__actions">
                    <Button type="submit" buttonType="primary">Vista breytingar</Button>
                </div>
            </div>
        </Form>
    }

    return (
        <div className='KCL_Lykilord-form'>
            <Formik
                initialValues={{
                    oldPassword: '',
                    newPassword: '',
                    confirmNewPassword: '',
                }}
                validationSchema={Yup.object({
                    oldPassword: Yup.string()
                        .required('Lykilorð verður að fylgja'),
                    newPassword: Yup.string()
                        .required('Lykilorð verður að fylgja')
                        .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).{12,}$/, 'Lykilorð of veikt, þarf að innihalda a.m.k. 12 stafi, einn tölustaf, einn hástaf og einn lágstaf'),
                    confirmNewPassword: Yup.string()
                        .oneOf([Yup.ref('newPassword')], 'Lykilorð verða að vera eins'),
                })}
                onSubmit={handleSubmit}
                component={form}
            />
        </div>
    )
}

export default LykilordForm;