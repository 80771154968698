// Package imports:
import React from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../ui-elements/Link/Link';
// Service imports:
import { getAPItoday, getTableDate } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IApiLmdNews } from '../../types/HlutabrefTypes';
import { IDefaultProps } from '../../types/Types';

interface IOwnProps {
    market?: string,
    limit?: number
}

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const NewsByMarkets: React.FC<Props> = ({
    market = 'ISEQ SHR',
    limit = 25,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const today = new Date();
    const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    const [data] = useApiLmdData<IApiLmdNews[]>(
        `/v1/market_data/v1/exchanges/*/markets/${market}/tradables/*/news?from_date=${getAPItoday(lastMonth)}&to_date=${getAPItoday(tomorrow)}&language=IS`,
        accessToken,
        refreshRateMs
    );

    const handleData = () => {
        if (data.data === undefined || data.data === null || data.error instanceof Error) {
            return [];
        } else {
            return data.data.slice(0, limit);
        }
    };

    return (
        <DisplayBox
            className='KCL_news-by-markets'
            title="Fréttir úr Kauphöllinni"
            {...props}
        >
            {handleData().map((d, i) => (
                <div className="news-row" key={i}>
                    <span>
                        <Link
                            className="news-title"
                            url={d.url ?? ''}
                            targetBlank
                            linkSize='15'
                            splitLines
                        >
                            {d.title}
                        </Link>
                    </span>
                    <span className="name-and-time">
                        <span className="source">{d.instrument_name}</span>
                        <span className="time">{getTableDate(d.date, 'HH:MM', '.')}</span>
                    </span>
                </div>
            ))}
        </DisplayBox>
    );
}

export default NewsByMarkets;