// Package imports:
import React, { useMemo, useEffect, useState } from 'react';
import cx from 'classnames';
import { SeriesLineOptions, SeriesOptionsType } from 'highcharts/highstock';
import ISLocale from 'date-fns/locale/is';
import { format } from 'date-fns';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import LmdTable from '../../../ui-elements/Table/LmdTable';
import Avatar, { AvatarNames } from '../../../ui-elements/Avatar/Avatar';
import DefaultChart from '../DefaultChart/DefaultChart';
// Service imports:
import { useApiLmdData, useApiLmdDataMappedByString } from '../../../services/apiHooks';
import { getAPItoday, changeIssuerName, formatNumber } from '../../../services/utils';
// Type imports:
import { IDefaultProps } from '../../../types/Types';
import { IApiLmdFundPricePublic } from '../../../types/FundsTypes';
import { IApiHistoryTimeseriesShortDATA } from '../../../types/MarketTypes';

interface IOwnProps {
    symbol?: string,
    title?: string,
    height?: number
}

interface IVisibleFund {
    symbol: string,
    name: string,
    color: string,
    data: number[][]
}

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const FundsChart: React.FC<Props> = ({
    title,
    height = 500,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    // Data for the table: list of all funds. We use their symbols to fetch chart data with the hook
    const [ tableData ] = useApiLmdData<IApiLmdFundPricePublic[]>(
        `/v1/market_data/v1/funds/*/prices/public?value_date=${getAPItoday()}`,
        accessToken,
        refreshRateMs
    );
    // List of funds that are visible in the chart.
    const [visibleChartFunds, setVisibleChartFunds] = useState<IVisibleFund[]>([{
        symbol: 'isl_equus',
        name: 'IS EQUUS Hlutabréf',
        color: '#4569EE',
        data: []
    }]);
    const handleTableData = useMemo(() => {
        const { data, error } = tableData;
        if (data === null) return tableData;

        const filterData = (groupname: string) => {
            return data.filter(x => {
                return x.group_key === groupname && x.fund_type !== 'S'
            });
        }
        const sortFundPrice = (a: IApiLmdFundPricePublic, b: IApiLmdFundPricePublic) => {
            if (a.change12m === null && b.change12m === null) return 0;
            else if (a.change12m === null) return 1;
            else if (b.change12m === null) return -1;
            else return b.change12m - a.change12m;
        }
        return {
            data: {
                "Mesta ávöxtun síðustu 12 mánuði á rekstrarfélag": filterData('top_12m').sort(sortFundPrice),
                "Skammtímasjóðir": filterData('short_term').sort(sortFundPrice),
                "Íslensk skuldabréf - stutt": filterData('is_bonds_short').sort(sortFundPrice),
                "Íslensk skuldabréf - meðallöng": filterData('is_bonds_medium').sort(sortFundPrice),
                "Íslensk skuldabréf - löng": filterData('is_bonds_long').sort(sortFundPrice),
                "Íslensk hlutabréf": filterData('is_equity').sort(sortFundPrice),
                "Erlend hlutabréf": filterData('foreign_equity').sort(sortFundPrice),
                "Erlend hlutabréf - ISK": filterData('foreign_equity_isk').sort(sortFundPrice),
                "Erlend skuldabréf": filterData('foreign_bonds').sort(sortFundPrice),
                "Blandaðir sjóðir": filterData('mixed').sort(sortFundPrice)
            },
            error
        }
    },[tableData])
    
    useEffect(() => {
        if (handleTableData.data !== null) {
            //@ts-ignore
            const highestFundList: IApiLmdFundPricePublic[] = handleTableData.data["Mesta ávöxtun síðustu 12 mánuði á rekstrarfélag"];
            const highestSymbol = highestFundList.length > 0 ? (highestFundList[0].symbol ?? '') : '';
            const highestName = highestFundList.length > 0 ? (highestFundList[0].name ?? '') : '';
            setVisibleChartFunds([{
                symbol: highestSymbol,
                name: highestName,
                color: '#4569EE',
                data: []
            }])
            if (highestSymbol !== '') {
                setSymbolToFetchFor(highestSymbol)
            }
        }
    },[handleTableData]) 
    
    // Hook used for fetching chart data for clicked symbol.
    const [symbolToFetchFor, setSymbolToFetchFor, newDataForFetchedSymbol] = useApiLmdDataMappedByString<IApiHistoryTimeseriesShortDATA>(
        visibleChartFunds[0]?.symbol ?? '',
        (letter: string) => `/v1/market_data/v1/funds/${letter}/history_timeseries?from_date=1972-02-26&to_date=${getAPItoday()}`,
        accessToken,
        refreshRateMs
    );

    useEffect(() => {
        const existingVisibleChartFundIndex = visibleChartFunds.findIndex(visibleChartFund => visibleChartFund.symbol === symbolToFetchFor);
        if (existingVisibleChartFundIndex !== -1 && newDataForFetchedSymbol.data !== null) {
            const existingVisibleChartFund = visibleChartFunds[existingVisibleChartFundIndex];
            const newVisibleChartFunds = [...visibleChartFunds];
            newVisibleChartFunds.splice(existingVisibleChartFundIndex, 1, {
                ...existingVisibleChartFund,
                name: newDataForFetchedSymbol.data.name,
                data: newDataForFetchedSymbol.data.data
            })
            setVisibleChartFunds(newVisibleChartFunds);
        }
    }, [symbolToFetchFor, newDataForFetchedSymbol]);

    const series: SeriesOptionsType[] = useMemo(() => {
        let allSeries: SeriesLineOptions[] = visibleChartFunds.map((fund) => {
             return ({
                id: fund.symbol,
                type: 'line',
                name: fund.name,
                color: fund.color,
                data: fund.data ?? []
            })
        })

        return allSeries;
    }, [visibleChartFunds, tableData]);

    const handleTableFundClick = (symbol: string | null) => {
        if (symbol === null) return;
        if (newDataForFetchedSymbol.data === null && newDataForFetchedSymbol.error === null) return;
        const existingChartFund = visibleChartFunds.find(chartFund => chartFund.symbol === symbol);
        if (existingChartFund === undefined) {
            if (symbol === symbolToFetchFor) {
                setVisibleChartFunds(visibleChartFunds.concat([{
                    symbol,
                    name: newDataForFetchedSymbol.data?.name ?? '',
                    data: newDataForFetchedSymbol.data?.data ?? [],
                    color: `#${((Math.floor(Math.random() * 16777215)).toString(16)).padStart(6, '0')}`
                }]))
            } else {
                setVisibleChartFunds(visibleChartFunds.concat([{
                    symbol,
                    name: 'Hleður...',
                    color: `#${((Math.floor(Math.random() * 16777215)).toString(16)).padStart(6, '0')}`,
                    data: []
                }]));
                setSymbolToFetchFor(symbol);
            }
        } else {
            setVisibleChartFunds(visibleChartFunds.filter(chartFund => chartFund.symbol !== symbol));
        }
    }

    return (
        <DisplayBox
            className='KCL_chart'
            title={title}
            {...props}
        >
            <div className='row'>
                <div className='col-lg-9 col-sm-12'>
                    <DefaultChart
                        height={height}
                        tooltipFormatter={
                            function() {
                                //filtera einungis það sem er visible af því ef það er notað points[0] crashar allt þegar eitthvað series er disable-að
                                return (
                                    `<div class="chart-tooltip">
                                        <p class="tooltip-date">${format(this.x, 'EEEE, dd. MMM yyyy', {locale: ISLocale})}</p>
                                        ${this.points?.filter(x => x.point.visible === true).map(x => (
                                            `<p><span style="color: ${x.color}">${x.series.getName()}</span>: ${formatNumber(x.y, '-', 1)}</p>`
                                        ))}
                                    </div>`
                                ).replace(/>,</g,'><')
                            }
                        }
                        yAxes={[{
                            title: {
                                text: 'Verð (kr.)',
                                margin: 15
                            },
                            height: '100%'
                        }]}
                        series={series}
                        seriesOptions={{
                            compare: series.length > 1 ? 'percent' : undefined
                        }}
                    />
                </div>
                <div className='col-lg-3 col-sm-12'>
                    <LmdTable
                        apiData={handleTableData}
                        columns={[{
                            title: '',
                            // sortable:  ,
                            renderCell: ({ symbol, issuer_name, name }) => (
                                <div
                                    className={cx('avatar-and-text', {
                                        'selected': (visibleChartFunds.findIndex(chartFund => chartFund.symbol === symbol) !== -1)
                                    })}
                                    onClick={() => handleTableFundClick(symbol)}
                                >
                                    <Avatar
                                        name={changeIssuerName(issuer_name) as AvatarNames}
                                        size='sm'
                                        color
                                        backgroundGrey
                                    />
                                    <span>
                                        {name}
                                    </span>
                                </div>
                                // <div
                                //     onClick={() => handleTableFundClick(Symbol)}
                                // >
                                //     <Avatar
                                //         name={changeIssuerName(IssuerName) as AvatarNames}
                                //         size='sm'
                                //         color
                                //         backgroundGrey
                                //     />
                                //     <span>{Name}</span>
                                // </div>
                            ),
                            textAlign: 'left'
                        }]}
                        expandableRowConfig={{
                            defaultExpandedIndexes: [0],
                            showNumberOfItemsInExpandable: true
                        }}
                    />
                </div>
            </div>
        </DisplayBox>
    );
}

export default FundsChart;