// Package imports:
import React from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Tabs from '../../ui-elements/Tabs/Tabs';
import OMX from './OMX/OMX';
import ETF from './ETF/ETF';
import FirstNorth from './FirstNorth/FirstNorth';
import ValinHlutabref from './ValinHlutabref/ValinHlutabref';
import OllHlutabref from './OllHlutabref/OllHlutabref';
import DelayOrClosedLabel from '../DelayOrClosedLabel/DelayOrClosedLabel';
// Service imports:
import { CUSTOM_REFRESH_RATES } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';

type Props = IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const Hlutabref: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const defaultProps = {
        accessToken,
        refreshRateMs: CUSTOM_REFRESH_RATES['HLUTABREF']
    };
    return (
        <DisplayBox
            className='KCL_Hlutabref'
            anchorProps={{ href: '/Markadir/Hlutabref' }}
            title="Hlutabréf"
            statusComponent={<DelayOrClosedLabel />}
            displayBoxSize="sm"
            {...props}
        >
            <Tabs
                tabs={[{
                    id: `${props.id}-omx`,
                    label: "XICE",
                    component: <OMX {...defaultProps} />
                }, {
                    id: `${props.id}-firstNorth`,
                    label: "First North",
                    component: <FirstNorth {...defaultProps}/>
                }, {
                    id: `${props.id}-ollHlutabref`,
                    label: "Öll hlutabréf",
                    component: <OllHlutabref {...defaultProps}/>
                }, {
                    id: `${props.id}-ETF`,
                    label: "ETF",
                    component: <ETF {...defaultProps}/>
                }, {
                    id: `${props.id}-valinHlutabref`,
                    label: "Valin hlutabréf",
                    component: <ValinHlutabref {...defaultProps}/>
                }]}
            />
        </DisplayBox>
    );
}

export default Hlutabref;