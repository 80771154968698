// Package imports:
import React, { useContext, useEffect, useMemo, useState } from 'react';
// Component imports:
import FundsChart from "../Charts/FundsChart/FundsChart";
import AdRotator from '../Ad/AdRotator';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import DatePicker from '../../ui-elements/DatePicker/DatePicker';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import Loading from '../../ui-elements/Loading/Loading';
import CalculatedColoredNumber from '../../ui-elements/CalculatedColoredNumber/CalculatedColoredNumber';
import Table from '../../ui-elements/Table/Table';
import Avatar, { AvatarNames } from '../../ui-elements/Avatar/Avatar';
import Link from '../../ui-elements/Link/Link';
import Tabs from '../../ui-elements/Tabs/Tabs';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
// Service imports:
import { changeIssuerName, formatNumber, getAPItoday, getTableDate, sortIcelandic } from '../../services/utils';
import { useApiLmdDataMappedByString } from '../../services/apiHooks';
import { AccessTokenContext } from '../../contexts/AccessTokenContext';
import { MARKET_REFRESH_RATES } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdFundPricePublic } from '../../types/FundsTypes';

type IProps = IDefaultProps;

const FundsPage: React.FC<IProps> = () => {
    const refreshRateMs = MARKET_REFRESH_RATES['FUNDS'];
    const accessToken = useContext(AccessTokenContext);
    const [defaultProps, setDefaultProps] = useState<IDefaultProps>({
        accessToken,
        refreshRateMs
    });
    const [currentDate, setCurrentDate, fundsForDate] = useApiLmdDataMappedByString<IApiLmdFundPricePublic[]>(
        getAPItoday(),
        (date: string) => `/v1/market_data/v1/funds/*/prices/public?value_date=${date}`,
        accessToken,
        refreshRateMs
    );

    const filterData = (groupname: string) => {
        const { data } = fundsForDate;
        if (data === null) return [];
        return data.filter(x => x.group_key === groupname && x.fund_type !== 'S');
    }

    const sortFundPrice = (a: IApiLmdFundPricePublic, b: IApiLmdFundPricePublic) => {
        if (a.change12m === null && b.change12m === null) return 0;
        else if (a.change12m === null) return 1;
        else if (b.change12m === null) return -1;
        else return b.change12m - a.change12m;
    }

    useEffect(() => {
        setDefaultProps({
            ...defaultProps,
            accessToken
        });
    }, [accessToken]);

    const { data, error } = fundsForDate;

    const dataToDisplay = useMemo(() => {
        if (data === null) return null;
        // Take out sereignarsjodir and sort by default to alphabetical.
        const sortedFundsWithoutSpecialFunds = data
            .filter(fundInfo => fundInfo.group_name !== 'Séreignasjóðir')
            .sort((a, b) => sortIcelandic(a.name, b.name));
        return sortedFundsWithoutSpecialFunds;
    }, [data]);

    // Get list of options for dropdown filters:
    const {
        allCurrencies,
        allIssuers,
        allGroups
    } = useMemo(() => {
        const optionsObject = {
            allCurrencies: [] as string[],
            allIssuers: [] as string[],
            allGroups: [] as string[]
        };
        // Null check.
        if (dataToDisplay === null) return optionsObject;
        // Currencies
        optionsObject.allCurrencies = dataToDisplay.reduce((curr: string[], item) => {
            if (item.currency === null || curr.includes(item.currency)) return curr;
            return curr.concat([item.currency])
        }, []);
        // Issuers
        optionsObject.allIssuers = dataToDisplay.reduce((curr: string[], item) => {
            if (item.issuer_name === null || curr.includes(item.issuer_name)) return curr;
            return curr.concat([item.issuer_name])
        }, []);
        // Groups
        optionsObject.allGroups = dataToDisplay.reduce((curr: string[], item) => {
            if (item.group_name === null || curr.includes(item.group_name)) return curr;
            return curr.concat([item.group_name])
        }, []);
        optionsObject.allCurrencies.sort(sortIcelandic);
        optionsObject.allIssuers.sort(sortIcelandic);
        optionsObject.allGroups.sort(sortIcelandic);

        return optionsObject;
    }, [dataToDisplay]);

    const displayAllFunds = () => {
        if (dataToDisplay === null) {
            return (error === null) ? <Loading /> : <ErrorAlert error={error} />;
        }
        return (
            <Table
                data={dataToDisplay}
                tableSize='lg'
                className='all-funds-table'
                columns={[{
                    title: 'Nafn sjóðs',
                    renderCell: ({ issuer_name, symbol, name }) => (
                        <div className="avatar-and-text">
                            <a
                                href={`/Markadir/Sjodir/${symbol}`}
                                rel="noopener noreferrer"
                                aria-label={name ?? ''}
                            >
                                <Avatar
                                    name={changeIssuerName(issuer_name) as AvatarNames}
                                    size='sm'
                                    color
                                    backgroundGrey
                                />
                            </a>
                            <Link url={`/Markadir/Sjodir/${symbol}`} linkSize='15'>
                                {name}
                            </Link>
                        </div>
                    ),
                    textAlign: 'left',
                    sortable: (a, b) => sortIcelandic(a.name, b.name),
                    filter: {
                        id: 'sjodur',
                        type: 'string',
                        value: ({name}) => name
                    }
                }, {
                    title: 'Útgefandi',
                    textAlign: 'left',
                    renderCell: ({ issuer_name }) => issuer_name,
                    simpleSortable: ({ issuer_name }) => issuer_name,
                    filter: {
                        id: 'utgefandi',
                        type: allIssuers,
                        value: ({ issuer_name }) => issuer_name
                    }
                }, {
                    title: 'Tegund',
                    textAlign: 'left',
                    renderCell: ({ group_name }) => group_name,
                    sortable: (a, b) => sortIcelandic(a.group_name, b.group_name),
                    filter: {
                        id: 'tegund',
                        type: allGroups,
                        value: ({ group_name }) => group_name
                    }
                }, {
                    title: 'Mynt',
                    renderCell: ({ currency }) => (
                        currency
                    ),
                    textAlign: 'left',
                    simpleSortable: ({ currency }) => currency,
                    filter: {
                        id: 'mynt',
                        type: allCurrencies,
                        value: ({currency}) => currency
                    }
                }, {
                    title: 'Gengi',
                    renderCell: ({ last_price }) => (formatNumber(last_price, '-', 2)),
                    simpleSortable: ({last_price }) => last_price
                }, {
                    title: '1 Ár',
                    renderCell: ({ change12m }) => (
                        <CalculatedColoredNumber
                            currentValue={change12m}
                            formatting='percent'
                            hasArrow
                            hasColor
                        />
                    ),
                    simpleSortable: ({ change12m }) => change12m
                }, {
                    title: '2 Ár',
                    renderCell: ({ change24m }) => (
                        <CalculatedColoredNumber
                            currentValue={change24m}
                            formatting='percent'
                            hasArrow
                            hasColor
                        />
                    ),
                    simpleSortable: ({ change24m }) => change24m
                }, {
                    title: '3 Ár',
                    renderCell: ({ change36m }) => (
                        <CalculatedColoredNumber
                            currentValue={change36m}
                            formatting='percent'
                            hasArrow
                            hasColor
                        />
                    ),
                    simpleSortable: ({ change36m }) => change36m
                }, {
                    title: '4 Ár',
                    renderCell: ({ change48m }) => (
                        <CalculatedColoredNumber
                            currentValue={change48m}
                            formatting='percent'
                            hasArrow
                            hasColor
                        />
                    ),
                    simpleSortable: ({ change48m }) => change48m
                }, {
                    title: '5 Ár',
                    renderCell: ({ change60m }) => (
                        <CalculatedColoredNumber
                            currentValue={change60m}
                            formatting='percent'
                            hasArrow
                            hasColor
                        />
                    ),
                    simpleSortable: ({ change60m }) => change60m
                }, {
                    title: 'Dagsetning',
                    renderCell: ({ value_date }) => (value_date && getTableDate(value_date.toString(), 'DD/MM/YYYY', '.')),
                    simpleSortable: ({ value_date }) => (value_date === null) ? 0 : new Date(value_date).getTime(),
                    filter: {
                        id: 'dagsetning',
                        type: 'string',
                        value: ({value_date}) => (value_date && getTableDate(value_date.toString(), 'DD/MM/YYYY', '.'))
                    }
                }]}
            />
        )
    }

    const displayCategories = () => {
        if (data === null) {
            return (error === null) ? <Loading /> : <ErrorAlert error={error} />;
        }
        return (
            <Table
                data={{
                    "Mesta ávöxtun síðustu 12 mánuði á rekstrarfélag": filterData('top_12m').sort(sortFundPrice),
                    "Skammtímasjóðir": filterData('short_term').sort(sortFundPrice),
                    "Íslensk skuldabréf - stutt": filterData('is_bonds_short').sort(sortFundPrice),
                    "Íslensk skuldabréf - meðallöng": filterData('is_bonds_medium').sort(sortFundPrice),
                    "Íslensk skuldabréf - löng": filterData('is_bonds_long').sort(sortFundPrice),
                    "Íslensk hlutabréf": filterData('is_equity').sort(sortFundPrice),
                    "Erlend hlutabréf": filterData('foreign_equity').sort(sortFundPrice),
                    "Erlend hlutabréf - ISK": filterData('foreign_equity_isk').sort(sortFundPrice),
                    "Erlend skuldabréf": filterData('foreign_bonds').sort(sortFundPrice),
                    "Blandaðir sjóðir": filterData('mixed').sort(sortFundPrice)
                }}
                tableSize='lg'
                columns={[{
                    title: 'Nafn sjóðs',
                    renderCell: ({ issuer_name, symbol, name }) => (
                        <div className="avatar-and-text">
                            <a
                                href={`/Markadir/Sjodir/${symbol}`}
                                rel="noopener noreferrer"
                                aria-label={name ?? ''}
                            >
                                <Avatar
                                    name={changeIssuerName(issuer_name) as AvatarNames}
                                    size='sm'
                                    color
                                    backgroundGrey
                                />
                            </a>
                            <Link url={`/Markadir/Sjodir/${symbol}`} linkSize='15'>
                                {name}
                            </Link>
                        </div>
                    ),
                    textAlign: 'left',
                    sortable: (a, b) => sortIcelandic(a.name, b.name)
                }, {
                    title: 'Mynt',
                    renderCell: ({ currency }) => currency,
                    textAlign: 'left',
                    simpleSortable: ({ currency }) => currency
                }, {
                    title: 'Gengi',
                    renderCell: ({ last_price }) => (formatNumber(last_price, '-', 2)),
                    simpleSortable: ({last_price }) => last_price
                }, {
                    title: 'Dagsetning',
                    renderCell: ({ value_date }) => (value_date && getTableDate(value_date.toString(), 'DD/MM/YYYY', '.')),
                    simpleSortable: ({ value_date }) => (value_date === null) ? 0 : new Date(value_date).getTime()
                }, {
                    title: '1 Ár',
                    renderCell: ({ change12m }) => (
                        <CalculatedColoredNumber
                            currentValue={change12m}
                            formatting='percent'
                            hasArrow
                            hasColor
                        />
                    ),
                    simpleSortable: ({ change12m }) => change12m
                }, {
                    title: '2 Ár',
                    renderCell: ({ change24m }) => (
                        <CalculatedColoredNumber
                            currentValue={change24m}
                            formatting='percent'
                            hasArrow
                            hasColor
                        />
                    ),
                    simpleSortable: ({ change24m }) => change24m
                }, {
                    title: '3 Ár',
                    renderCell: ({ change36m }) => (
                        <CalculatedColoredNumber
                            currentValue={change36m}
                            formatting='percent'
                            hasArrow
                            hasColor
                        />
                    ),
                    simpleSortable: ({ change36m }) => change36m
                }, {
                    title: '4 Ár',
                    renderCell: ({ change48m }) => (
                        <CalculatedColoredNumber
                            currentValue={change48m}
                            formatting='percent'
                            hasArrow
                            hasColor
                        />
                    ),
                    simpleSortable: ({ change48m }) => change48m
                }, {
                    title: '5 Ár',
                    renderCell: ({ change60m }) => (
                        <CalculatedColoredNumber
                            currentValue={change60m}
                            formatting='percent'
                            hasArrow
                            hasColor
                        />
                    ),
                    simpleSortable: ({ change60m }) => change60m
                }]}
                expandableRowConfig={{
                    defaultExpandedIndexes: [0],
                    showNumberOfItemsInExpandable: true
                }}
            />
        )
    }

    return (
        <div className="main KCL_funds-page KCL_market-page">
            <div className="main__inner-fluid">
                <div className="shell">
                    <HeadSection
                        hasAds='market'
                        title='Sjóðir'
                    />
                    <div>
                        <div className='middle-section grid-items'>
                            <div className='grid-item first-item-middle-section'>
                                <DisplayBox
                                    title="Sjóðir"
                                    asideComponent={
                                        <div className="funds-datepicker">
                                            <DatePicker maxDate={new Date()} size="sm" selectedDate={new Date(currentDate)} setSelectedDate={(newDate) => setCurrentDate(getAPItoday(newDate))}> </DatePicker>
                                        </div>
                                    }
                                    id="FundsPage_FundsTable"
                                >
                                    <Tabs
                                        tabs={[{
                                            id: 'AllirSjodir',
                                            label: 'Allir sjóðir',
                                            component: displayAllFunds()
                                        }, {
                                            id: 'FlokkadirSjodir',
                                            label: 'Flokkaðir sjóðir',
                                            component: displayCategories()
                                        }]}
                                    />
                                </DisplayBox>
                            </div>
                        </div>
                        <div className='middle-ad-section'>
                            <AdRotator location='Market1018x360' />
                        </div>
                        <div className='grid-items grid-items--flex'>
                            <div className="grid-item grid-item--3of4 grid-item--table-full">
                            <FundsChart
                                title='Samanburður sjóða'
                                accessToken={accessToken}
                                refreshRateMs={MARKET_REFRESH_RATES['FUNDSCHART']}
                                id="FundsPage_Chart"
                            />
                            </div>
                            <div className='grid-item grid-item--1of4 grid-item--table-full'>
                                <AdRotator location="Market310x400" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FundsPage;