// URLS SHOULD NEVER END IN /

export const GET_API_LMD_URL = () => {
    switch (process.env.REACT_APP_API_LMD_SOURCE) {
        case 'production':
            return 'https://api.livemarketdata.com';
        case 'staging':
            return 'https://api-staging.livemarketdata.com';
        default:
            return 'https://api.livemarketdata.com';
    }
}

export const GET_KELDAN_API_URL = () => {
    switch (process.env.REACT_APP_KELDAN_API_SOURCE) {
        case 'production':
            return '';
        case 'staging':
            return 'https://staging.keldan.is';
        case 'development':
            return 'http://localhost:5000';
        default:
            return '';
    }
}

export const GET_NEWS_LMD_URL = () => {
    switch (process.env.REACT_APP_NEWS_LMD_SOURCE) {
        case 'production':
            return 'https://news-search.livemarketdata.com';
        case 'staging':
            return 'https://news-search-staging.livemarketdata.com';
        default:
            return 'https://news-search.livemarketdata.com';
    }
}

export const GET_NEWSWIRE_URL = () => {
    switch (process.env.REACT_APP_NEWS_LMD_SOURCE) {
        case 'production':
            return 'https://newswire-api.keldan.is';
        case 'staging':
            return 'https://newswire-api-staging.keldan.is';
        default:
            return 'https://newswire-api.keldan.is';
    }
}

export const GET_KELDAN_URL = () => {
    return 'https://gamla.keldan.is';
}

export const GET_PASSWORD_RESET_URL = () => {
    switch (process.env.REACT_APP_KELDAN_API_SOURCE) {
        case 'production':
            return "https://users.keldan.is/api/reset_password";
        case 'staging':
            return 'https://users-staging.keldan.is/api/reset_password';
        case 'development':
            return 'https://users-staging.keldan.is/api/reset_password';
        default:
            return "https://users.keldan.is/api/reset_password";
    }
}

export const DEFAULT_REFRESH_RATE = 10*60*1000; // 10 mín.

export const CUSTOM_REFRESH_RATES = {
    'DAGATAL': 10*60*1000,              // 10 mín.
    'HLUTABREF': 1*60*1000,             // 1 mín.
    'LANDSBANKINN': 15*60*1000,         // 15 mín.
    'KROSSAR': 10*60*1000,              // 10 mín.
    'SEDLABANKINN': 1*60*60*1000,       // 1 klst.
    'TOLLGENGI': 1*60*60*1000,          // 1 klst.
    'KREDITKORT': 1*60*60*1000,         // 1 klst.
    'RAFMYNTIR': 10*60*1000,            // 10 mín.
    'SEREIGNASJODIR': 10*60*1000,       // 10 mín.
    'FRETTIR': 1*60*1000,               // 1 mín.
    'VEXTIRSEDLABANKA': 1*60*60*1000,   // 1 klst.
    'MILLIBANKAVEXTIR': 1*60*60*1000,   // 1 klst.
    'SJODIR': 20*60*1000,               // 20 mín.
    'SKULDABREF': 1*60*1000,            // 1 mín.
    'HAGTOLUR': 10*60*1000,             // 10 mín.
    'VAXTAFERLAR': 10*60*1000,          // 10 mín.???
    'ADS': 1*60*60*1000,                // 1 klst.
    'VISITOLUR': 1*60*60*1000,          // 1 klst.
}

export const MARKET_REFRESH_RATES = {
    'SHARESTABLE': 1*60*1000,               // 1 mín.
    'SINGLESHARECHART': 10*60*1000,         // 10 mín.
    'INTRADAYCHART': 5*60*1000,             // 5 mín.
    'COMPANYREPORTSFILES': 1*60*60*1000,    // 1 klst.
    'LATESTTRADES': 1*60*1000,              // 1 mín.
    'TRADABLESCHANGES': 1*60*1000,          // 1 mín.
    'GEMMAQDATA': 1*60*60*1000,             // 1 klst.
    'FINANCIALDATA': 1*60*60*1000,          // 1 klst.
    'KEYFIGURES': 1*60*60*1000,             // 1 klst.
    'COMPANYREPORTS': 1*60*60*1000,         // 1 klst.
    'SHAREHOLDERS': 1*60*60*1000,           // 1 klst.
    'COMPANYREPORTSANALYSIS': 1*60*60*1000, // 1 klst.
    'SHARESNEWS': 10*60*1000,               // 10 mín.
    'VAKTARINNNEWS': 10*60*1000,            // 10 mín.
    'MARKETOVERVIEW': 1*60*1000,            // 1 mín.
    'HEATMAP': 1*60*1000,                   // 1 mín.
    'SHARESINDEXCHART': 10*60*1000,         // 10 mín.
    'NEWSBYMARKETS': 10*60*1000,            // 10 mín.
    'BONDSTABLE': 1*60*1000,                // 1 mín.
    'BONDSCHART': 10*60*1000,               // 10 mín.
    'YIELDCURVE': 10*60*1000,               // 10 mín.
    'FXMARKETTABLE': 1*60*1000,             // 1 mín.
    'MARKETDEPTH': 1*60*1000,               // 1 mín.
    'FXMARKETCHART': 10*60*1000,            // 10 mín.
    'CURRENCIESSINGLEBASE': 1*60*1000,      // 1 mín.
    'CURRENCYCHART': 1*60*60*1000,          // 1 klst.
    'MULTIPLEINDICES': 1*60*1000,           // 1 mín.
    'INDICESCHART': 1*60*1000,              // 1 mín.
    'SINGLEINDEX': 1*60*1000,               // 1 mín.
    'SINGLEINDEXCHART': 1*60*1000,          // 1 mín.
    'FUNDS': 1*60*1000,                     // 1 mín.
    'FUNDSCHART': 1*60*60*1000,             // 1 klst.
    'SINGLEFUND': 1*60*1000,                // 1 mín.
    'SPECIALFUNDS': 1*60*1000               // 1 mín.
}

export const currencyFractionDigits = (symbol: string) => {
    const symbolDecimalMap: {
        [key: string]: number
    } = {
        'DKK': 3,
        'NOK': 3,
        'SEK': 3,
        'JPY': 4
    };
    return symbolDecimalMap[symbol] ?? 2;
}

export const TOAST_DELAY = 5000;

export const SHORT_MONTHS = ['jan.','feb.','mars','apríl','maí','júní','júlí','ágúst','sept.','okt.','nóv.','des.'];

export const getRelease = () => (process.env.REACT_APP_API_LMD_SOURCE === 'staging') ? 'Staging' : 'Production';
