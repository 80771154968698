// Package imports:
import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
// Component imports:
import Ad, { IAdProps } from './Ad';
// Service imports:
import { useRefreshRateFetching } from '../../services/hooks';
import { CUSTOM_REFRESH_RATES } from '../../services/config';
import { ErrorMessages } from '../../services/errorMessages';
// Type imports:
import { Fetched } from '../../types/Types';

type AdLocation = 'Company400x80' | 'FrontPage310x400A' | 'FrontPage310x400B' | 'FrontPage310x400C' | 'FrontPage310x400D'
    | 'FrontPage400x80' | 'FrontPage300x250' | 'FrontPage300x250Y'
    | 'Head1360x180' | 'Head400x80' | 'Market1018x360' | 'Market400x80' | 'Market310x400' | 'MarketHead1360x180' | 'MarketHead400x80' | 'MarketHead1018x360';

interface IProps {
    location: AdLocation,
    duration?: number
}
const createAdSize = (width: number, height: number) => ({ width, height });

const adSizes = {
    'Company400x80': createAdSize(400, 80),
    'FrontPage310x400A': createAdSize(310, 400),
    'FrontPage310x400B': createAdSize(310, 400),
    'FrontPage310x400C': createAdSize(310, 400),
    'FrontPage310x400D': createAdSize(310, 400),
    'FrontPage400x80': createAdSize(400, 80),
    'FrontPage300x250': createAdSize(300, 250),
    'FrontPage300x250Y': createAdSize(300, 250),
    'Head1360x180': createAdSize(1360, 180),
    'Head400x80': createAdSize(400, 80),
    'Market1018x360': createAdSize(1018, 360),
    'Market400x80': createAdSize(400, 80),
    'Market310x400': createAdSize(310, 400),
    'MarketHead1360x180': createAdSize(1360, 180),
    'MarketHead400x80': createAdSize(400, 80),
    'MarketHead1018x360': createAdSize(1018, 360),
};

const AdRotator: React.FC<IProps> = ({
    location,
    duration = 30000
}) => {
    const [width, height] = [adSizes[location].width, adSizes[location].height];
    const [ads, setAds] = useState<Fetched<IAdProps[]>>(null);
    const [adIndex, setAdIndex] = useState(0);
    const [nextInCat, setNextInCat] = useState<{[T in string]: number}>({});
    
    const [ isTabActive, setIsTabActive ] = useState(true);

    // Create a ref that can be attached to the element that we want to monitor
    const ref = useRef<HTMLDivElement>(null);
    const [isIntersecting, setIntersecting] = useState(false);
    // Create a ref for the interval ID so that we can clear it later
    const intervalId = useRef<NodeJS.Timeout | null>(null);
    //flag to indicate if the ad index should be increased
    const shouldIncreaseAdIndex = useRef(false);

    // Update isTabActive, so that we know when the user returns to the site after inactivity.
    useEffect(() => {
        const setActive = () => { setIsTabActive(true); }
        const setInactive = () => { setIsTabActive(false); }
        window.addEventListener('focus', setActive);
        window.addEventListener('blur', setInactive);
        return () => {
            window.removeEventListener('focus', setActive);
            window.removeEventListener('blur', setInactive);
        }
    }, []);

    const increaseAdIndex = () => {
        if (ads === null || ads instanceof Error || !isTabActive) return;
        if (ads[adIndex]?.metadata.cat) {
            const nextIndexInCat = adIndex < ads.length - 1 && ads[adIndex+1].metadata.cat && ads[adIndex].metadata.cat === ads[adIndex+1].metadata.cat
                ? adIndex + 1
                : ads.findIndex((ad) => ad.metadata.cat && ad.metadata.cat === ads[adIndex].metadata.cat);
            setNextInCat({
                ...nextInCat,
                [ads[adIndex].metadata.cat ?? '']: nextIndexInCat
            });
        }
        let newIndex = (adIndex + 1) % ads.length;
        while (ads[adIndex]?.metadata.cat && ads[newIndex].metadata.cat && ads[adIndex]?.metadata.cat === ads[newIndex].metadata.cat) {
            newIndex = (newIndex + 1) % ads.length;
        }
        const newCat = ads[newIndex].metadata.cat;
        if (newCat && nextInCat[newCat] !== undefined && newIndex !== nextInCat[newCat]) {
            newIndex = nextInCat[newCat];
        }
        setAdIndex(newIndex);
    }

    // Effect hook for handling the ad rotation
    useEffect(() => {
        // If the component is intersecting and the flag to increase the ad index is set
        if (shouldIncreaseAdIndex.current) {
            // Increase the ad index
            increaseAdIndex();
            // Reset the flag
            shouldIncreaseAdIndex.current = false;
        }
        // Set an interval to check if the component is intersecting
        intervalId.current = setInterval(() => {
            // If the component is intersecting
            if (isIntersecting) {
                // Increase the ad index
                increaseAdIndex();
            } else {
                // If the component is not intersecting, set the flag to increase the ad index later
                shouldIncreaseAdIndex.current = true;
            }
        }, duration);
        // Cleanup function
        return () => {
            // If the interval is set
            if (intervalId.current) {
                // Clear the interval
                clearInterval(intervalId.current);
                // Reset the interval ID
                intervalId.current = null;
            }
        };
    }, [isIntersecting, ads, increaseAdIndex]); // Dependencies for the effect hook
    
    const fetchData = async () => {
        setNextInCat({});
        setAdIndex(0);
        try {
            const url = `https://ads.livemarketdata.com/v1/ads/${location}`;
            const response = await fetch(url);
            if (!response.ok) {
                setAds(new Error(ErrorMessages.RequestFailed));
                return;
            }
            const body = await response.json();
            setAds(handleData(body as IAdProps[]));
        } catch(e) {
            setAds(new Error(ErrorMessages.NetworkError));
        }
    }
    const handleData = (ads: IAdProps[]) => {
        if (ads === null || ads instanceof Error) {
            return [];
        } else {
            const filteredAds = ads.filter((ad) => (
                !ad.metadata.expired
                && (!ad.metadata.expires || Date.parse(ad.metadata.expires) > Date.now())
                && (!ad.metadata.show || Date.parse(ad.metadata.show) < Date.now())
            ));
            const shuffledAds = shuffle(filteredAds);
            return shuffledAds;
        }
    }

    const sortAds = (adsList: IAdProps[]) => {
        return adsList.sort((a, b) => {
            if (a.metadata.cat && b.metadata.cat) {
                if (a.metadata.cat > b.metadata.cat) return 1;
                if (a.metadata.cat < b.metadata.cat) return -1;
                return 0;
            }
            if (a.metadata.cat) return -1;
            if (b.metadata.cat) return 1;
            return 0;
        });
    }

    const shuffle = (adsList: IAdProps[]) => {
        const sorted = sortAds(adsList);
        
        let cats: {index: number, n: number}[] = [];
        for (let i = 0; i < sorted.length;) {
            let n = 1;
            while (i+n < sorted.length
                && sorted[i].metadata.cat
                && sorted[i+n].metadata.cat
                && sorted[i+n].metadata.cat === sorted[i].metadata.cat
            ) {
                n++;
            }
            cats.push({
                index: i,
                n: n
            });
            i += n;
        }
        let currentIndex = cats.length, randomIndex;
        // While there remain elements to shuffle...
        while (currentIndex !== 0) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            // And swap it with the current element.
            [cats[currentIndex], cats[randomIndex]] = [
            cats[randomIndex], cats[currentIndex]];
        }

        let newAdsList: IAdProps[] = [];
        cats.forEach(({index, n}) => {
            if (n > 1) {
                let currentIndex = index + n, randomIndex;

                // While there remain elements to shuffle...
                while (currentIndex !== index) { 
                    // Pick a remaining element...
                    randomIndex = index + Math.floor(Math.random() * n);
                    currentIndex--;
                    // And swap it with the current element.
                    [sorted[currentIndex], sorted[randomIndex]] = [
                    sorted[randomIndex], sorted[currentIndex]];
                }
            }
            newAdsList = newAdsList.concat(sorted.slice(index, index + n));
        })

        return newAdsList;
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIntersecting(entry.isIntersecting);
            }
        );
        if (ref.current) {
            observer.observe(ref.current);
        }
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect(); };
    }, [ref]);

    useRefreshRateFetching(
        () => { if (isIntersecting) fetchData(); },
        CUSTOM_REFRESH_RATES['ADS'],
        undefined, undefined, undefined, true
    );
    useEffect(() => {
        if (isIntersecting && ads === null) {
            fetchData();
        }
    }, [isIntersecting]);
    return (
        <div ref={ref} className={cx('KCL_ad-rotator', `ads${width}x${height}`)} id={`Ads_${location}`}>
            <div className="inner">
                {
                    ads !== null && !(ads instanceof Error) && ads.length > adIndex
                    ? <Ad
                        path={ads[adIndex].path}
                        metadata={ads[adIndex].metadata}
                        height={ads[adIndex].height ?? height}
                        width={ads[adIndex].width ?? width}
                        id={`Ad_${location}`}
                    />
                    : null
                }
            </div>
        </div>
    );
}

export default AdRotator;