// Package imports:
import React, { useState } from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../ui-elements/Link/Link';
import Fyrirvari from '../Fyrirvari/Fyrirvari';
import LmdTable, { IColumn } from '../../ui-elements/Table/LmdTable';
// Service imports:
import { formatNumber, getTableDate } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdCompanyReportsAnalysis } from '../../types/HlutabrefTypes';

const getSymbolFromName = (name: string) => {
    switch (name) {
        case 'Hagar':
            return 'HAGA';
        case 'Sýn':
            return 'SYN';
        case 'VÍS':
            return 'VIS';
        case 'Eimskip':
            return 'EIM';
        case 'Skeljungur':
            return 'SKEL';
        case 'Siminn':
            return 'SIMINN';
        case 'Íslandsbanki':
            return 'ISB';
        default:
            return name.toUpperCase();
    }
}

interface IOwnProps {
    symbol?: string,
    limit?: number
}

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const CompanyReportsAnalysis: React.FC<Props> = ({
    symbol,
    limit = 15,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [data] = useApiLmdData<IApiLmdCompanyReportsAnalysis[]>(
        `/v1/market_data/v1/company_reports/type/valuation/analyst/*/reference/${symbol ?? '*'}/category/*/`,
        accessToken,
        refreshRateMs
    );
    const [disclaimerOpen, setDisclaimerOpen] = useState(false);
    const openDisclaimer = () => setDisclaimerOpen(true);
    const closeDisclaimer = () => setDisclaimerOpen(false);

    const sortData = (data: IApiLmdCompanyReportsAnalysis[]) => {
        return data.sort((a, b) => {
            const aval = a.DateOfAnalysis;
            const bval = b.DateOfAnalysis;
            if (aval === null && bval === null) return 0;
            if (aval === null) return 1;
            if (bval === null) return -1;
            if (aval > bval) return -1;
            if (aval < bval) return 1;
            return 0;
        });
    }
    
    const displayData = data.data === null
    	? null
        : sortData(data.data).slice(0, limit);

    const columns: IColumn<IApiLmdCompanyReportsAnalysis>[] = [{
        title: 'Auðkenni',
        renderCell: ({ReferenceSymbol}) => <Link url={`/Markadir/Hlutabref/${ReferenceSymbol !== null ? getSymbolFromName(ReferenceSymbol) : ''}`} linkSize='15'>{ReferenceSymbol}</Link>,
        textAlign: 'left'
    }, {
        title: 'Greinandi',
        renderCell: ({Website, AnalystName}) => <Link url={Website ?? ''} targetBlank linkSize='15'>{AnalystName}</Link>,
        textAlign: 'left'
    }, {
        title: 'Dags.',
        renderCell: ({DateOfAnalysis}) => DateOfAnalysis && getTableDate(DateOfAnalysis.toString(), 'DD/MM/YYYY', '.'),
        textAlign: 'left'
    }, {
        title: 'Lýsing',
        renderCell: ({ShortDesc}) => ShortDesc,
        textAlign: 'left'
    }, {
        title: 'Verðmatsgengi',
        renderCell: ({Value}) => formatNumber(Value, null, 2)
    }]

    return (
        <DisplayBox
            className='KCL_company-reports-analysis'
            title="Greiningar"
            footerRight={
                <Fyrirvari
                    show={disclaimerOpen}
                    open={openDisclaimer}
                    close={closeDisclaimer}
                >
                    <p className='paragraph'>Þær skoðanir og spár sem hér birtast eru byggðar á almennum upplýsingum sem Jakobsson Capital ehf. hefur undir höndum þegar greiningin er gerð. Helstu heimildir eru upplýsingar frá Kauphöllinni og vefsíðum fyrirtækja í Kauphöllinni, Reuters fréttaveitunni, auk annarra opinberra upplýsinga. Upplýsingar í greiningu þessari eru settar fram samkvæmt bestu vitund greinenda hverju sinni en þær eru háðar breytingum sem geta haft áhrif á greininguna. Jakobsson Capital ehf. ábyrgst ekki áreiðanleika eða réttmæti upplýsinga sem settar eru fram í þessari greiningu.</p>
                    <p className='paragraph'>Greining eða verðmatsgengi felur ekki í sér persónulega fjárfestingarráðgjöf í skilningi laga nr. 108/2007 né ráðleggingar af neinu tagi. Greiningunni eða verðmatsgenginu er ætlað að vera til upplýsingar og engin trygging eða ábyrgð er veitt á því að nokkrar af þeim spám eða fyrirætlunum sem lýst er í greiningu gangi eftir. Framtíðarspár og fyrirætlanir eru ávallt háðar ýmsum áhættum og óvissuþáttum sem gætu leitt til verulega breyttra niðurstaðna. Jakobsson Capital ehf. vinnur greininguna sem verðmatsgengið er byggt á án skuldbindingar um að leggja fram frekari upplýsingar, uppfæra eða leiðrétta ónákvæmni sem síðar kann að koma í ljós.</p>
                    <p className='paragraph'>Jakobsson Capital ehf. eða tengdir aðilar eru með engum hætti ábyrgir fyrir hvers konar tjóni eða tapi, af hvaða tagi sem er, beint eða óbeint, sem fjárfestar og/eða hverjir þeir sem kynna sér greininguna kunna að verða fyrir með því að notast við eða nýta sér með öðrum hætti greiningu þessa eða innihald hennar. Jakobsson Capital ehf., starfsmenn. stjórnarmenn og/eða aðilar tengdir Jakobsson Capital ehf. kunna að eiga hagsmuna að gæta varðandi einstök verðbréf sem greiningar, verðmatsskýrslur og annað útgáfuefni Jakobsson Capital ehf. lýtur að hverju sinni.</p>
                </Fyrirvari>
            }
            {...props}
        >
            <LmdTable
                apiData={{...data, data: displayData}}
                columns={symbol ? columns.slice(1) : columns}
                tableSize="lg"
            />
        </DisplayBox>
    );
}

export default CompanyReportsAnalysis;