// Package imports:
import React, { useState } from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../ui-elements/Link/Link';
import Loading from '../../ui-elements/Loading/Loading';
import Alert from '../../ui-elements/Alert/Alert';
// Service imports:
import { getTableDate, getTimeFromDate } from '../../services/utils';
import { GET_NEWS_LMD_URL } from '../../services/config';
import { ErrorMessages } from '../../services/errorMessages';
import { useRefreshRateFetching } from '../../services/hooks';
// Type imports:
import { Fetched, IDefaultProps } from '../../types/Types';
import { INewsLmdNewsItem, INewsLmdResponse } from '../../types/NewsTypes';

interface IOwnProps {
    symbol: string | null,
    limit?: number
}

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const VaktarinnNews: React.FC<Props> = ({
    symbol,
    limit = 10,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [data, setData] = useState<Fetched<INewsLmdNewsItem[]>>(null);

    const fetchData = async () => {
        try {
            const url = `${GET_NEWS_LMD_URL()}/search/query?f=symbol.ticker%3Bsymbol.sourceTicker&limit=${limit}&q=${symbol}_VAKTARINN&start=0`;
            const response = await fetch(url);
            if (response.ok) {
                const body: INewsLmdResponse = await response.json();
                setData(body.feedItems);
            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            
            setData(new Error(ErrorMessages.NetworkError));
        }
    };

    useRefreshRateFetching(
        fetchData,
        refreshRateMs,
        accessToken
    );

    const displayNews = () => {
        if (data === null) return <Loading />;
        if (data instanceof Error) return <Alert type='error' headText='villa' />;
        if (data.length === 0) return <Alert type='info' headText='Engin gögn fundust' />;
        return data.slice(0, limit).map((d) => (
            <div className="news-row" key={d.id}>
                <span>
                    <Link
                        className="news-title"
                        url={d.link ?? ''}
                        linkSize='15'
                        splitLines
                        targetBlank
                    >
                        {d.title}
                    </Link>
                </span>
                <span className="time-and-date">
                    <span className="time">{getTimeFromDate(d.publish_date, 'HH:MM:SS')}</span>
                    <span className="date">{getTableDate(d.publish_date, 'DD/MM/YYYY', '.')}</span>
                </span>
            </div>
        ))
    }

    return (
        <DisplayBox
            anchorProps={{ href: '/Vaktarinn'}}
            className='KCL_vaktarinn-news'
            title="Fréttir úr Vaktaranum"
            asideComponent={<object style={{width: '75%'}} data="https://cdn.livemarketdata.com/KeldanImages/VaktarinnLogoSmall.svg" aria-label="Vaktarinn">Vaktarinn</object>}
            {...props}
        >
            {displayNews()}
        </DisplayBox>
    );
}

export default VaktarinnNews;