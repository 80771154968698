// Package imports:
import React, { useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';


interface IAccordionItem {
    id: string,
    title?: string,
    content?: JSX.Element
}

interface IProps {
    items?: IAccordionItem[],
    openId?: string
}

const Accordion: React.FC<IProps> = ({
    items,
    openId
}) => {
    const [activeId, setActiveId] = useState(openId ?? null);

    return (
        <div className="KCL_accordion">
            {items && items.map(item => (
                <div
                    key={item.id}
                    className={cx('accordion__section', {'is-active': activeId === item.id})}
                >
                    <div className="accordion__head">
                        <button type="button" className="accordion__trigger" onClick={() => setActiveId(activeId === item.id ? null : item.id)}>
                            <FontAwesomeIcon icon={activeId === item.id ? faMinus : faPlus} className="accordion__icon icon-fa" />
                            <h6>{item.title}</h6>
                        </button>
                    </div>
                    <div className="accordion__body">
                        <div className="accordion__content paragraph-small">
                            {item.content}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Accordion;