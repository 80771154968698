// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import ProfilePage from '../../components/Individual/Thjodskra/Profile';
// Type imports:
import { IAuthenticationWithId } from '../../types/Types';

const IndividualProfile: React.FC<IAuthenticationWithId> = ({
    isSubscriber,
    id
}) => {
    return (
        <Entrypoint>
            <ProfilePage isSubscriber={isSubscriber} id={id} />
        </Entrypoint>
    );
}

export default IndividualProfile;