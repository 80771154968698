// Package imports:
import cx from 'classnames'
// Component imports:
import Loading from '../Loading/Loading';
import Alert from '../Alert/Alert';
// Type imports:
import { Fetched } from '../../types/Types';

export type AlignOptions = 'left' | 'center' | 'right';
type TableSize = 'sm' | 'lg';

interface IProps<T> {
    headerTitle?: string,
    data: Fetched<Array<T>>,
    columns: Array<{
        title: string | JSX.Element
        renderCell(dataItem: T): any | JSX.Element | Array<JSX.Element>,
        textAlign?: AlignOptions,
        overrideDefaultStyle?: boolean,
    }>,
    tableSize?: TableSize,
    statusComponent?: JSX.Element,
    fallbackSymbol?: boolean,
    horizontalFlexSize?: number,
}

const VerticalTable = <T extends any>({
    data,
    columns,
    tableSize = 'sm',
    fallbackSymbol = false,
    horizontalFlexSize = 1,
    headerTitle
}: IProps<T>) => {
    // Helper funcy's (AKA Hunkys).
    const getAlignStyles = (align?: AlignOptions) => ({ textAlign: align ?? 'right' });

    const displayRowBody = (data: Array<T>) => data.map((datum, rowIndex) => (
        <tbody key={rowIndex}> 
            {columns.map(({title, renderCell, textAlign = 'left', overrideDefaultStyle}, colIndex) => 
                (overrideDefaultStyle && Array.isArray(renderCell(datum)))
                    ? renderCell(datum)
                    : <tr
                        key={colIndex}
                        style={getAlignStyles(textAlign)}
                        className={cx(`align-${textAlign}` , 'lineContainer' )}
                    >
                        <th><strong>{title}</strong></th>
                        <td style={{'flex': horizontalFlexSize }} >
                            <div className="text-wrapper">
                                {(fallbackSymbol)
                                    ? (renderCell(datum) ?? '-')
                                    : renderCell(datum)}
                            </div>
                        </td>
                    </tr>
            )}
        </tbody>
    ));

    if (data instanceof Error) {
        return <Alert type='error' headText={data.message} />;
    }
    if (data === null) {
        return <Loading />;
    }
    return (
        <div className={cx('KCL_vertical-table', tableSize)}>
            <div className='table__body'>
                <table>
                    {headerTitle && <thead><tr><th colSpan={2}>{headerTitle}</th></tr></thead>}
                    {displayRowBody(data)}
                </table>
            </div>
        </div>
    );
}

export default VerticalTable;
 