// Package imports:
import React from 'react';
// Component imports:
import ConstructionReportBody from './ConstructionReportBody';
import ConstructionsOwnedReportBody from './ConstructionsOwnedReportBody';
// Type imports:
import { IEventReport } from '../../../types/KeldanTypes';
import { ConstructionsSearchResult, IConstructionsReport } from '../../../types/VehicleTypes';
// Static data imports:
import EXAMPLE_DATA from '../../../assets/staticData/ExampleReports/Vehicle/ConstructionReport.json';
import EXAMPLE_DATA_OWNED from '../../../assets/staticData/ExampleReports/Vehicle/ConstructionsOwned.json';

interface IProps {
    type: 'profile' | 'owned'
}

const ConstructionReportExample: React.FC<IProps> = ({
    type
}) => {
    return (
        type === 'profile'
            ? <ConstructionReportBody data={EXAMPLE_DATA as IEventReport<ConstructionsSearchResult>} isExample />
            : <ConstructionsOwnedReportBody constructionReport={EXAMPLE_DATA_OWNED as IConstructionsReport} isExample />
    );
}

export default ConstructionReportExample;