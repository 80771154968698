// Package imports:
import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
// Service imports:
import { formatNumber } from '../../../services/utils';
// Type imports:
import { IApiLmdCurrency } from '../../../types/CurrencyTypes';

interface IChartDatum {
    x: number | undefined
    y: number | undefined
};
interface IChartDataInfo {
    name: string,
    data: IChartDatum[]
};

interface IProps {
    data: IApiLmdCurrency[],
    title?: string,
    detailed?: boolean,
    height?: number
};

const IntradayChart: React.FC<IProps> = ({
    data,
    title = '',
    detailed = false,
    height = 250
}) => {
    const divRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const chartDataInfo: IChartDataInfo = {
            name: title,
            data: []
        }
        for (let datum of data) {
            if (datum.Datetimerate && Date.parse(datum.Datetimerate.replace(/ /g, "T")) <= Date.now()) {
                chartDataInfo.data.push({
                    x: datum.Datetimerate ? Date.parse(datum.Datetimerate.replace(/ /g, "T")) : undefined,
                    y: datum.Averagerate ?? undefined
                });
            }
        }

        Highcharts.setOptions({
            lang: {
                resetZoom: 'Til baka',
                resetZoomTitle: ''
            }
        });

        if (divRef.current !== null) {
            Highcharts.chart(divRef.current, {
                chart: {
                    height: height,
                    marginTop: detailed ? 80 : 10,
                    reflow: true,
                    style: {
                        fontFamily: "Roboto"
                    },
                    zoomType: detailed ? "xy" : undefined,
                    selectionMarkerFill: '#4569EE18',
                    resetZoomButton: {
                        position: {
                            x: 0,
                            y: -75
                        },
                        theme: {
                            r: 6,
                            height: 28
                        }
                    },
                    plotBackgroundColor: {
                        linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                        stops: [
                            [0.05, '#FFF'],
                            [0.95, '#F8F8FA']
                        ]
                    }
                },
                exporting: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: '',
                    align: 'center',
                    x: 60,
                    margin: -14
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                tooltip: {
                    enabled: true,
                    formatter: function() {
                        return `
                            <div class="chart-tooltip">
                                <p class="tooltip-date">${'Klukkan: ' + ((new Date(this.x).getHours()).toString()).padStart(2, '0') + ':' + ((new Date(this.x).getMinutes()).toString()).padStart(2, '0') }</p>
                                <p class="tooltip-value">${'Verð: ' + chartDataInfo.name} ${formatNumber(this.y, null, 3)}</p>
                            </div>`;
                    },
                    borderWidth: 0,
                    shadow: false,
                    useHTML: true
                },
                xAxis: [{
                    type: 'datetime',
                    title: {
                        text: ''
                    },
                    minTickInterval: detailed ? 1 : 10,
                    tickWidth: 0
                }],
                yAxis: {
                    title: {
                        text: ''
                    },
                    gridLineDashStyle: 'Dash',
                    gridLineWidth: 1,
                    gridLineColor: '#D3D3D6'
                },
                plotOptions: {
                    series: {
                        color: '#4569EE',
                        enableMouseTracking: true,
                        marker: {
                            enabled: false
                        },
                        animation: true,
                        turboThreshold: 0,
                    }
                },
                legend: {
                    enabled: false,
                    align: 'left',
                    verticalAlign: 'top',
                    itemStyle: {
                        color: '#232530',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '26px'
                    },
                    y: 10
                },
                responsive: {
                    rules: [{
                        condition: {
                            callback: function() {
                                return window.matchMedia('(max-width: 767px)').matches;
                            }
                        },
                        chartOptions: {
                            chart: {
                                spacingLeft: 0,
                                spacingRight: 5
                            },
                            xAxis: {
                                labels: {
                                    style: {
                                        fontSize: '9px'
                                    }
                                }
                            },
                            yAxis: {
                                labels: {
                                    style: {
                                        fontSize: '9px'
                                    },
                                    x: -1
                                },
                            }
                        }
                    }]
                },
                series: [{
                    type: 'line',
                    data: chartDataInfo.data,
                }]
            }, () => {});
        }
    }, [data, divRef.current]);


    return (
        <div className="KCL_intraday-chart">
            <div className="chart" ref={divRef}></div>
        </div>
    );
}

export default IntradayChart;