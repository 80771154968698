// Package imports:
import React, { useMemo, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik } from "formik";
// Component imports:
import Button from '../../../ui-elements/Button/Button';
import Input from '../../../ui-elements/Input/Formik/Input';
import Checkbox from "../../../ui-elements/Input/Formik/Checkbox";
import Alert from "../../../ui-elements/Alert/Alert";
import Recaptcha from "../../../ui-elements/Recaptcha/Recaptcha";
// Service imports:
import useSendEmailHook from "../../../services/apiHooks";
// Type imports:
import { Fetched } from "../../../types/Types";
import { MySettingsViewModel } from "../../../types/MyKeldaTypes";

interface IProps {
    model?: MySettingsViewModel,
}

interface IFormValues {
    customerBonds: boolean,
    customerBondsExplain: string,
    
}

const VedbondForm: React.FC<IProps>= ({model}) => {
    const [captchaToken, setCaptchaToken] = useState<string | null>('');
    
    const { loading, contactResponse, handleSubmit } = useSendEmailHook(
        'Email/UpdateUserBonds',
        captchaToken,
        {email: model?.myUserInfo?.email ?? ''}
    )
    const subFormComponent = useMemo(() => {
        return ((props: { values: IFormValues }) => (
            <SubFormComponent
                contactResponse={contactResponse}
                values={props.values}
                setCaptchaToken={(e: string | null ) => setCaptchaToken(e)}
                loading={loading}/>
        ))
    }, [ contactResponse, loading ]);

    return(
    <div className='KCL_Vedbond-form'>
            <Formik
                initialValues={{
                    customerBonds: model?.settingsResponse?.customerBonds ?? true,
                    customerBondsExplain: model?.settingsResponse?.customerBondsExplain ?? '',
                }}
                validationSchema={Yup.object({
                    customerBonds: Yup.boolean()
                        .oneOf([true], 'Vinsamlega hakið við'),
                    customerBondsExplain: Yup.string()
                        .required("Ástæða verður að fylgja"),
                })}
                onSubmit={handleSubmit}
                component={subFormComponent}
            />
        </div>
    )
}

export default VedbondForm;

interface IFormValues {
    customerBonds: boolean,
    customerBondsExplain: string,
}

interface ISubFormProps {
    contactResponse: Fetched<string>
    values: IFormValues,
    loading: boolean,
    setCaptchaToken: (e: string | null) => void
}

const SubFormComponent: React.FC<ISubFormProps> = ({
    contactResponse,
    values,
    loading,
    setCaptchaToken,
}) => {
    return <Form>
        <div className="checkbox">
            <Checkbox 
                label="Ég óska eftir aðgangi að veðböndum"
                name="customerBonds"
                id="customerBonds"
                value={values.customerBonds ? 'true' : 'false'}
            />
        </div>
        <Input 
            name="customerBondsExplain"
            id="customerBondsExplain"
            inputType="textArea"
            className="field-input" 
            label="Ástæða fyrir aðgangi"
            value={values.customerBondsExplain}
        />
        {(contactResponse === null)
            ? null
            : (
                <div className="form__row">
                    <div className="form__col">
                        {(contactResponse instanceof Error)
                        ? <Alert type='error' headText={contactResponse.message} />
                        : <Alert type='info' headText={contactResponse} />}
                    </div>
                </div>
            )}
        <Recaptcha position="left" setToken={(e: string | null) => setCaptchaToken(e)} />
        <div className="form__actions">
            <Button showLoader={loading} type="submit" buttonType="primary">Senda umsókn</Button>
        </div>
    </Form>
}

