// Package imports:
import React from 'react';
import cx from 'classnames';

type DisplayBoxSize = 'sm' | 'lg' | 'xl' | 'xxl';

type DisplayBoxDivProps = React.HTMLAttributes<HTMLDivElement>;
type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

interface IOwnProps {
    statusComponent?: JSX.Element,
    asideComponent?: JSX.Element,
    displayBoxSize?: DisplayBoxSize,
    anchorProps?: AnchorProps,
    titleComponent?: JSX.Element,
    footerLeft?: JSX.Element,
    footerRight?: JSX.Element,
    description?: string,
    grayBackground?: boolean,
    faded?: boolean,
    stretch?: boolean
}

type Props = DisplayBoxDivProps & IOwnProps;

const DisplayBox: React.FC<Props> = ({
    title,
    statusComponent,
    children,
    asideComponent,
    displayBoxSize = 'lg',
    anchorProps,
    titleComponent,
    footerLeft,
    footerRight,
    className,
    description,
    grayBackground = false,
    faded = false,
    stretch = true,
    ...props
}) => {
    const wrapWithAnchor = (component: string | JSX.Element | undefined) => {
        if(anchorProps) {
            return (
                <a {...anchorProps}>
                    {component}
                </a>
            );
        }
        return component;
    }

    const getHeading = () => {
        switch (displayBoxSize) {
            case 'sm':
                return <h5>{wrapWithAnchor(title ?? titleComponent)}{statusComponent}</h5>;
            case 'lg':
                return <h4>{wrapWithAnchor(title ?? titleComponent)}{statusComponent}</h4>;
            case 'xl':
                return <h3>{wrapWithAnchor(title ?? titleComponent)}{statusComponent}</h3>;
            case 'xxl':
                return <h2>{wrapWithAnchor(title ?? titleComponent)}{statusComponent}</h2>;
        }
    }
    
    return (
        <div
            className={cx("display-box", displayBoxSize, className, {
                'gray-background': grayBackground,
                'faded': faded,
                'stretch': stretch
            })}
            {...props}
        >
            {(title || titleComponent || asideComponent) &&
                <div className="display-box__head">
                    {getHeading()}

                    {asideComponent && (
                        <div className="display-box__head-aside">
                            {asideComponent}
                        </div>
                    )}
                </div>
            }
            {description &&
                <p className='description extra-info'>
                    {description}
                </p>
            }
            <div className="display-box__body">
                {children}
            </div>
            {(footerLeft || footerRight) && 
                <div className='display-box__footer'>
                    <div>{footerLeft}</div>
                    <div>{footerRight}</div>
                </div>
            }
        </div>
    )
}

export default DisplayBox
