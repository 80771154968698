// Package imports:
import React from 'react';

const GemmaqScale: React.FC = () => {
    return (
        <div className='KCL_gemmaq-scale'>
            <p className="gemmaq-scale-header paragraph--bold">
                Prósentuhlutfall kvenna í stjórn og framkvæmdastjórn
            </p>
            <div className="gemmaq-scale-body">
                <div className='scale'>
                    <div className="colors">
                        <div className="color-unit" id="A" />
                        <div className="color-unit" id="B" />
                        <div className="color-unit" id="C" />
                        <div className="color-unit" id="D" />
                        <div className="color-unit" id="E" />
                        <div className="color-unit" id="F" />
                    </div>
                    <div className="percentages">
                        <p className='paragraph-small--bold'>50% +</p>
                        <p className='paragraph-small--bold'>40-49%</p>
                        <p className='paragraph-small--bold'>30-39%</p>
                        <p className='paragraph-small--bold'>20-29%</p>
                        <p className='paragraph-small--bold'>10-19%</p>
                        <p className='paragraph-small--bold'>0-9%</p>
                    </div>
                </div>
                <p className="info-box paragraph-small">
                    Bjartari litir í kynjakvarðanum sýna að meira jafnvægi sé milli kynjanna við stjórn. 
                </p>
            </div>
        </div>
    );
}

export default GemmaqScale;