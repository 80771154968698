// Package imports:
import { useEffect, useMemo, useRef, useState } from 'react';
// Service imports:
import { GET_API_LMD_URL, GET_KELDAN_API_URL } from './config';
import { ErrorMessages } from './errorMessages';
// Type imports:
import { ApiLmdData, ApiLmdDataByDate, Fetched } from '../types/Types';

type UseApiLmdDataReturnType<T> = [
    ApiLmdData<T>,
    ApiLmdData<T>
]

/**
 * Creates a state for `api.livemarketdata.com` data.  
 * The state type is ApiLmdData<T> and it has the following interface:  
 * ```
 * {
 *  data: T | null
 *  error: Error | null
 * }
 * ```
 * 
 * Here's how to read that object
 * 1. Data hasn't been fetched/received yet.  
 * data = null  
 * error = null  
 * 
 * 2. The request has only returned data.  
 * data = T  
 * error = null  
 * 
 * 3. The request has only returned errors, or has returned many consecutive errors  
 * data = null  
 * error = Error  
 * 
 * 4. The request has sometimes returned data and sometimes errors. However, the errors were not consecutive, or consecutiveErrorsDontNullify=false  
 * data = T  
 * error = Error  
 * 
 * @param apiPath The path of the request.
 * @param accessToken Access Token
 * @param refreshRateMs If refreshRateMs is present, then it will set an interval and fetch it repeatedly
 * @param consecutiveErrorsDontNullify By default, if a request fails 3 times in a row, it will nullify data. If consecutiveErrorsDontNullify=true, then it will never nullify data.
 * @returns [ currentDataAndError, previousDataAndError ]
 */
export const useApiLmdData = <T extends unknown>(
    apiPath: string,
    accessToken?: string,
    refreshRateMs?: number,
    consecutiveErrorsDontNullify?: boolean
): UseApiLmdDataReturnType<T> => {
    const [apiData, setApiData] = useState<ApiLmdData<T>>({
        data: null,
        error: null
    });
    const [previousApiData, setPreviousApiData] = useState<ApiLmdData<T>>({
        data: null,
        error: null
    });
    
    // Create ref to be accessed by interval.
    const consecutiveErrorsRef = useRef(0);
    const apiDataRef = useRef(apiData);
    useEffect(() => { apiDataRef.current = apiData }, [apiData]);
    
    const fetchData = async (fetchAccessToken: string, fetchApiPath: string) => {
        // Use ref, async functions can't read state accurately.
        const apiData = apiDataRef.current;
        // Create helper function for updating state.
        const updateApiData = (newApiData: ApiLmdData<T>) => {
            setPreviousApiData(apiData);
            setApiData(newApiData);
        }
        // Fix url just in case.
        if (!fetchApiPath.startsWith('/')) {
            fetchApiPath = '/' + fetchApiPath;
        }
        // Create full api url
        const url = GET_API_LMD_URL() + fetchApiPath;

        fetch(url, {
            headers: {
                Authorization: `Bearer ${fetchAccessToken}`
            }
        })
        .then(res => {
            if (res.ok) return res.json()
            else throw new Error(`${res.status}: ${res.statusText}`);
        })
        .then(resBody => {
            consecutiveErrorsRef.current = 0;
            updateApiData({
                ...apiData,
                data: resBody
            });
        })
        .catch(err => {
            consecutiveErrorsRef.current++;
            // If there have been many(3) consecutive errors
            // and consecutive errors should nullify,
            // Then we will nullify the data since it is outdated and there is something wrong with the requests.
            if (consecutiveErrorsRef.current >= 3 && !consecutiveErrorsDontNullify) {
                if (err instanceof Error) {
                    updateApiData({
                        data: null,
                        error: err
                    });
                } else {
                    updateApiData({
                        data: null,
                        error: new Error(ErrorMessages.RequestFailed)
                    });
                }
            }
            // else: Regular error handling. Outdated data is never overwritten.
            else {
                if (err instanceof Error) {
                    updateApiData({
                        ...apiData,
                        error: err
                    });
                } else {
                    updateApiData({
                        ...apiData,
                        error: new Error(ErrorMessages.RequestFailed)
                    });
                }
            }
        });
    }

    useEffect(() => {
        if (accessToken) {
            // If refresh rate, start fetching on an interval
            if (refreshRateMs) {
                const intervalId = window.setInterval(() => {
                    fetchData(accessToken, apiPath)
                }, refreshRateMs);
                // Initial fetchData function.
                fetchData(accessToken, apiPath)
                // Clean up: clear interval to avoid memory leaks
                return () => window.clearInterval(intervalId);
            }
            // No refresh rate. Call 1 time.
            else {
                fetchData(accessToken, apiPath)
            }
        }
    }, [ accessToken, apiPath, refreshRateMs ]);

    return [
        apiData,
        previousApiData
    ]
}

export const useFetchedApiLmdData = <T extends unknown>(
    apiPath: string | null,
    accessToken?: string,
    handleBody?: (body: any) => T,
    initialValue?: T
): Fetched<T> => {
    const [apiData, setApiData] = useState<Fetched<T>>(initialValue ?? null);

    useEffect(() => {
        const fetchData = async (fetchAccessToken: string, fetchApiPath: string) => {
            // Fix url just in case.
            if (!fetchApiPath.startsWith('/')) {
                fetchApiPath = '/' + fetchApiPath;
            }
            // Create full api url
            const url = GET_API_LMD_URL() + fetchApiPath;
    
            fetch(url, {
                headers: {
                    Authorization: `Bearer ${fetchAccessToken}`
                }
            })
            .then(res => {
                if (res.ok) return res.json()
                if (res.status === 404) throw new Error(ErrorMessages.NoDataFound);
                throw new Error(ErrorMessages.RequestFailed);
            })
            .then(resBody => {
                if (handleBody) setApiData(handleBody(resBody));
                else setApiData(resBody);
            })
            .catch(err => {
                if (err instanceof Error) setApiData(err);
                else setApiData(new Error(ErrorMessages.RequestFailed));
            });
        }
        if (accessToken && apiPath) fetchData(accessToken, apiPath);
    }, [ accessToken, apiPath ]);

    return apiData
}

type NumberPerDate = {
    [date in string]?: number;
}

type UseApiLmdDataMappedByStringReturnType<T> = [
    string,
    React.Dispatch<React.SetStateAction<string>>,
    ApiLmdData<T>,
    ApiLmdData<T>
]

export const useApiLmdDataMappedByString = <T extends unknown>(
    initialKey: string,
    apiPathFactory: (mapKey: string) => string,
    accessToken?: string,
    refreshRateMs?: number,
    consecutiveErrorsDontNullify?: boolean,
    successFunction?: (results: T) => void,
    apiPathFactoryReturFullURL?: boolean,
): UseApiLmdDataMappedByStringReturnType<T> => {
    const [mapKey, setMapKey] = useState(initialKey);
    const [apiDataMap, setApiDataMap] = useState<ApiLmdDataByDate<T>>({});
    const [previousApiDataMap, setPreviousApiDataMap] = useState<ApiLmdDataByDate<T>>({});

    // Create ref to be accessed by interval.
    const consecutiveErrorsMapRef = useRef<NumberPerDate>({});
    const apiDataMapRef = useRef(apiDataMap);
    useEffect(() => { apiDataMapRef.current = apiDataMap }, [apiDataMap]);

    const fetchData = async (fetchAccessToken: string, fetchApiPath: string, mapKey: string) => {
        // Use ref, async functions can't read state accurately.
        const apiDataMap = apiDataMapRef.current;
        const consecutiveErrorsMap = consecutiveErrorsMapRef.current;
        
        // Create helper function for updating state.
        const updateApiDataMap = (newApiDataMap: ApiLmdDataByDate<T>) => {
            setPreviousApiDataMap(apiDataMap);
            setApiDataMap(newApiDataMap);
        }

        // Fix url just in case.
        if (!fetchApiPath.startsWith('/')) fetchApiPath = '/' + fetchApiPath;
        // Create full api url
        //if iverrideApiPath -> provide full url to fetchApiData parameter
        const url = GET_API_LMD_URL() + fetchApiPath;

        fetch(url, {
            headers: {
                Authorization: `Bearer ${fetchAccessToken}`
            }
        })
        .then(res => {
            if (res.ok) return res.json()
            else throw new Error(`${res.status}: ${res.statusText}`);
        })
        .then(resBody => {
            consecutiveErrorsMapRef.current = {
                ...consecutiveErrorsMap,
                [mapKey]: 0
            }
            const currentApiDataMapForDate = apiDataMap[mapKey] ?? {
                data: null,
                error: null
            };
            updateApiDataMap({
                ...apiDataMap,
                [mapKey]: {
                    ...currentApiDataMapForDate,
                    data: resBody
                }
            });
            if (successFunction) successFunction(resBody);
        })
        .catch(err => {
            const consecutiveErrorsForDate = (consecutiveErrorsMap?.[mapKey] ?? 0) + 1;
            consecutiveErrorsMapRef.current = {
                ...consecutiveErrorsMap,
                [mapKey]: consecutiveErrorsForDate
            }
            // If there have been many(3) consecutive errors
            // and consecutive errors should nullify,
            // Then we will nullify the data since it is outdated and there is something wrong with the requests.
            const currentApiDataMapForDate = apiDataMap[mapKey] ?? {
                data: null,
                error: null
            };
            if (consecutiveErrorsForDate >= 3 && !consecutiveErrorsDontNullify) {
                if (err instanceof Error) {
                    updateApiDataMap({
                        ...apiDataMap,
                        [mapKey]: {
                            data: null,
                            error: err
                        }
                    });
                } else {
                    updateApiDataMap({
                        ...apiDataMap,
                        [mapKey]: {
                            data: null,
                            error: new Error(ErrorMessages.RequestFailed)
                        }
                    });
                }
            }
            // else: Regular error handling. Outdated data is never overwritten.
            else {
                if (err instanceof Error) {
                    updateApiDataMap({
                        ...apiDataMap,
                        [mapKey]: {
                            ...currentApiDataMapForDate,
                            error: err
                        }
                    });
                } else {
                    updateApiDataMap({
                        ...apiDataMap,
                        [mapKey]: {
                            ...currentApiDataMapForDate,
                            error: new Error(ErrorMessages.RequestFailed)
                        }
                    });
                }
            }
        });
    }

    useEffect(() => {
        if (accessToken) {
            const apiPath = apiPathFactory(mapKey);
            // If refresh rate, start fetching on an interval
            if (refreshRateMs) {
                const intervalId = window.setInterval(() => {
                    fetchData(accessToken, apiPath, mapKey)
                }, refreshRateMs);
                // Initial fetchData function.
                fetchData(accessToken, apiPath, mapKey)
                // Clean up: clear interval to avoid memory leaks
                return () => window.clearInterval(intervalId);
            }
            // No refresh rate. Call 1 time.
            else {
                fetchData(accessToken, apiPath, mapKey)
            }
        }
    }, [ accessToken, mapKey, refreshRateMs ]);

    // Memo variables based on state.
    const apiDataForDate = useMemo(() => {
        return apiDataMap[mapKey] ?? {
            data: null,
            error: null
        }
    }, [apiDataMap, mapKey]);

    const previousApiDataForDate = useMemo(() => {
        return previousApiDataMap[mapKey] ?? {
            data: null,
            error: null
        }
    }, [previousApiDataMap, mapKey]);

    return [
        mapKey,
        setMapKey,
        apiDataForDate,
        previousApiDataForDate,
    ]
}

export const useDotnetData = <T extends unknown>(
    apiPath: string,
    accessToken?: string,
    refreshRateMs?: number,
    consecutiveErrorsDontNullify?: boolean
): UseApiLmdDataReturnType<T> => {
    const [apiData, setApiData] = useState<ApiLmdData<T>>({
        data: null,
        error: null
    });
    const [previousApiData, setPreviousApiData] = useState<ApiLmdData<T>>({
        data: null,
        error: null
    });
    
    // Create ref to be accessed by interval.
    const consecutiveErrorsRef = useRef(0);
    const apiDataRef = useRef(apiData);
    useEffect(() => { apiDataRef.current = apiData }, [apiData]);
    
    const fetchData = async (fetchAccessToken: string, fetchApiPath: string) => {
        // Use ref, async functions can't read state accurately.
        const apiData = apiDataRef.current;
        // Create helper function for updating state.
        const updateApiData = (newApiData: ApiLmdData<T>) => {
            setPreviousApiData(apiData);
            setApiData(newApiData);
        }
        // Fix url just in case.
        if (!fetchApiPath.startsWith('/')) fetchApiPath = '/' + fetchApiPath;
        // Create full api url
        const url = GET_KELDAN_API_URL() + fetchApiPath;

        fetch(url, {
            headers: {
                Authorization: `Bearer ${fetchAccessToken}`
            }
        })
        .then(res => {
            if (res.ok) return res.json()
            else throw new Error(`${res.status}: ${res.statusText}`);
        })
        .then(resBody => {
            consecutiveErrorsRef.current = 0;
            updateApiData({
                ...apiData,
                data: resBody
            });
        })
        .catch(err => {
            consecutiveErrorsRef.current++;
            // If there have been many(3) consecutive errors
            // and consecutive errors should nullify,
            // Then we will nullify the data since it is outdated and there is something wrong with the requests.
            if (consecutiveErrorsRef.current >= 3 && !consecutiveErrorsDontNullify) {
                if (err instanceof Error) {
                    updateApiData({
                        data: null,
                        error: err
                    });
                } else {
                    updateApiData({
                        data: null,
                        error: new Error(ErrorMessages.RequestFailed)
                    });
                }
            }
            // else: Regular error handling. Outdated data is never overwritten.
            else {
                if (err instanceof Error) {
                    updateApiData({
                        ...apiData,
                        error: err
                    });
                } else {
                    updateApiData({
                        ...apiData,
                        error: new Error(ErrorMessages.RequestFailed)
                    });
                }
            }
        });
    }

    useEffect(() => {
        if (accessToken) {
            // If refresh rate, start fetching on an interval
            if (refreshRateMs) {
                const intervalId = window.setInterval(() => {
                    fetchData(accessToken, apiPath)
                }, refreshRateMs);
                // Initial fetchData function.
                fetchData(accessToken, apiPath)
                // Clean up: clear interval to avoid memory leaks
                return () => window.clearInterval(intervalId);
            }
            // No refresh rate. Call 1 time.
            else {
                fetchData(accessToken, apiPath)
            }
        }
    }, [ accessToken, apiPath, refreshRateMs ]);

    return [
        apiData,
        previousApiData
    ]
}

export const useDotNetDataMappedByString = <T extends unknown>(
    initialKey: string,
    apiPathFactory: (mapKey: string) => string,
    consecutiveErrorsDontNullify?: boolean,
    successFunction?: (results: T) => void,
    setCount?: (count: number) => void,
    method: 'POST' | 'GET' = 'POST'
): UseApiLmdDataMappedByStringReturnType<T> => {
    const [mapKey, setMapKey] = useState(initialKey);
    const [apiDataMap, setApiDataMap] = useState<ApiLmdDataByDate<T>>({});
    const [previousApiDataMap, setPreviousApiDataMap] = useState<ApiLmdDataByDate<T>>({});

    // Create ref to be accessed by interval.
    const consecutiveErrorsMapRef = useRef<NumberPerDate>({});
    const apiDataMapRef = useRef(apiDataMap);
    useEffect(() => { apiDataMapRef.current = apiDataMap }, [apiDataMap]);

    const fetchData = async (fetchApiPath: string, mapKey: string) => {
        // Use ref, async functions can't read state accurately.
        const apiDataMap = apiDataMapRef.current;
        const consecutiveErrorsMap = consecutiveErrorsMapRef.current;
        
        // Create helper function for updating state.
        const updateApiDataMap = (newApiDataMap: ApiLmdDataByDate<T>) => {
            setPreviousApiDataMap(apiDataMap);
            setApiDataMap(newApiDataMap);
        }

        // Fix url just in case.
        if (!fetchApiPath.startsWith('/')) fetchApiPath = '/' + fetchApiPath;
        // Create full api url
        const url = GET_KELDAN_API_URL() + fetchApiPath;
        fetch(url, {
            method: method
        })
        .then(res => {
            if (res.ok) {
                if (res.redirected) {
                    window.location.href = res.url;
                } else {
                    return res.json();
                }
            }
            else throw new Error(`${res.status}: ${res.statusText}`);
        })
        .then(resBody => {
            consecutiveErrorsMapRef.current = {
                ...consecutiveErrorsMap,
                [mapKey]: 0
            }
            const currentApiDataMapForDate = apiDataMap[mapKey] ?? {
                data: null,
                error: null
            };
            updateApiDataMap({
                ...apiDataMap,
                [mapKey]: {
                    ...currentApiDataMapForDate,
                    data: resBody
                }
            });
            if (successFunction){
                successFunction(resBody);
            } 
                
        })
        .catch(err => {
            const consecutiveErrorsForDate = (consecutiveErrorsMap?.[mapKey] ?? 0) + 1;
            consecutiveErrorsMapRef.current = {
                ...consecutiveErrorsMap,
                [mapKey]: consecutiveErrorsForDate
            }
            // If there have been many(3) consecutive errors
            // and consecutive errors should nullify,
            // Then we will nullify the data since it is outdated and there is something wrong with the requests.
            const currentApiDataMapForDate = apiDataMap[mapKey] ?? {
                data: null,
                error: null
            };
            if (consecutiveErrorsForDate >= 3 && !consecutiveErrorsDontNullify) {
                if (err instanceof Error) {
                    updateApiDataMap({
                        ...apiDataMap,
                        [mapKey]: {
                            data: null,
                            error: err
                        }
                    });
                } else {
                    updateApiDataMap({
                        ...apiDataMap,
                        [mapKey]: {
                            data: null,
                            error: new Error(ErrorMessages.RequestFailed)
                        }
                    });
                }
            }
            // else: Regular error handling. Outdated data is never overwritten.
            else {
                if (err instanceof Error) {
                    updateApiDataMap({
                        ...apiDataMap,
                        [mapKey]: {
                            ...currentApiDataMapForDate,
                            error: err
                        }
                    });
                } else {
                    updateApiDataMap({
                        ...apiDataMap,
                        [mapKey]: {
                            ...currentApiDataMapForDate,
                            error: new Error(ErrorMessages.RequestFailed)
                        }
                    });
                }
            }
        });
    }

    useEffect(() => {
        const apiPath = apiPathFactory(mapKey);
        fetchData(apiPath, mapKey)
    }, [ mapKey ]);

    // Memo variables based on state.
    const apiDataForDate = useMemo(() => {
        return apiDataMap[mapKey] ?? {
            data: null,
            error: null
        }
    }, [apiDataMap, mapKey]);

    const previousApiDataForDate = useMemo(() => {
        return previousApiDataMap[mapKey] ?? {
            data: null,
            error: null
        }
    }, [previousApiDataMap, mapKey]);

    return [
        mapKey,
        setMapKey,
        apiDataForDate,
        previousApiDataForDate,
    ]
}

interface IResponse {
    success: boolean;
    message?: string;
    errors?: string[];
}

const useSendEmailHook = (apiEndpoint: string, captcha: string | null, otherValues?: {[key: string]: string}) => {
    const [loading, setLoading] = useState(false);
    const [contactResponse, setContactResponse] = useState<string | Error | null>(null);

    const handleSubmit = async (values: any, actions: any) => {
        setLoading(true);
        if (captcha === "" || captcha === null) {
            setLoading(false);
            setContactResponse(new Error("Captcha ekki útfyllt"));
            return {
                loading,
                contactResponse
            }
        }

        try {
            setContactResponse(null);
            const params = new URLSearchParams();
            // Iterate over the keys in the object

            //put all values as url search params
            Object.entries(values).forEach(([key, value]) => {
                // Check if the key has a value (not an empty string)
                if (value !== "") {
                    params.append(key, value as string);
                }
            });

            //add captcha token to url search param
            params.append("captcha", captcha)
            // Check if the object is defined and not empty
            for (const key in otherValues) {
                // Check if the key has a value (not an empty string)
                if (otherValues[key]) {
                    params.append(key, otherValues[key]);
                }
            }
            const url = `${GET_KELDAN_API_URL()}/${apiEndpoint}`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: params.toString(),
            });

            if (!response.ok) {
                setLoading(false);
                setContactResponse(new Error(ErrorMessages.RequestFailed));
                return;
            }
            const responseBody: IResponse = await response.json();
            setLoading(false);
            if (responseBody.success) {
                actions.resetForm();
                setContactResponse(responseBody.message || 'Fyrirspurn móttekin.');
                return;
            }
            const { errors, message } = responseBody;
            if (errors && errors.length > 0) {
                setContactResponse(new Error(errors.join('\n')));
            } else if (message) {
                setContactResponse(new Error(message));
            } else {
                setContactResponse(new Error(ErrorMessages.ErrorInRequest));
            }
        } catch (e) {
            setLoading(false);
            setContactResponse(new Error(ErrorMessages.NetworkError));
        }
    };

    return {
        loading,
        contactResponse,
        handleSubmit,
    };
};

export default useSendEmailHook;

