// Package imports:
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'react-bootstrap/Spinner';
// Components imports:
import Button from '../../ui-elements/Button/Button';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Table from '../../ui-elements/Table/Table';
import Link from '../../ui-elements/Link/Link';
import Tooltip from '../../ui-elements/Tooltip/Tooltip';
import Loading from '../../ui-elements/Loading/Loading';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
// Service imports:
import { displayAddress, formatNumber, getTableDate } from '../../services/utils';
import { GET_API_LMD_URL, GET_KELDAN_API_URL } from '../../services/config';
import { useBuyingProcess } from '../../services/buyhook';
import { ErrorMessages } from '../../services/errorMessages';
// Type imports:
import { IKeldanApiResponse } from '../../types/KeldanTypes';
import { GenericListViewModel } from '../../types/ListsTypes';
import { KeldanCompanyInfoStripped } from '../../types/CompanyTypes';

export interface IOwnProps {
    model?: GenericListViewModel,
}

const Almennt: React.FC<IOwnProps> = ({
    model
}) => {
    type CompanyToSubTableMap = {
        [T in string]?: string | undefined
    }
    type SsnToCompanyMap = {
        [T in string]: KeldanCompanyInfoStripped | undefined | Error
    }
    const [ companyToSubTableMap, setCompanyToSubTableMap ] = useState<CompanyToSubTableMap>({});
    const [ ssnToCompanyMap, setSsnToCompanyMap ] = useState<SsnToCompanyMap>({});

    const { tryPurchaseItem, isItemLoading, resetState, ModalsAndToasts } = useBuyingProcess(async () => {
        try {
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            const url = `${GET_KELDAN_API_URL()}/Listar/Almennt-Kaupa/${model?.list.id}`;
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                    return;
                }
                const responseBody: IKeldanApiResponse = await response.json();
                if (responseBody.message) resetState({
                    type: 'alert',
                    headText: responseBody.message
                });
                else resetState('bad request');
            } else {
                resetState('response not ok');
            }
        } catch (e) {
            resetState('network error');
        }
    }, {
        customHeader: "Kaupa markhópalista",
        customText: (
            <span>
                Þú ert að fara að kaupa markhópalista. Verð á markhópalista er skv. <Link linkSize='18' url='/Verdskra#verdskra'>verðskrá Keldunnar</Link>. Viltu halda áfram?
            </span>
        )
    });

    // Helper functions:
    const toggleSubTableForCompanySsn = (ssn: string, clickedButton: string) => {
        const currentSubTableForDatumId = companyToSubTableMap[ssn];
        if (clickedButton === currentSubTableForDatumId) {
            setCompanyToSubTableMap({
                ...companyToSubTableMap,
                [ssn]: undefined
            })
        } else {
            setCompanyToSubTableMap({
                ...companyToSubTableMap,
                [ssn]: clickedButton
            })
        }
    }

    const fetchCompanyInfo = async (ssn: string) => {
        try {
            const response = await fetch(`${GET_API_LMD_URL()}/v1/market_data/v1/keldan/company/${ssn}/basic_info`);

            if (response.ok) {
                const responseBody = await response.json();
                if (responseBody.length > 0) {
                    return responseBody[0];
                }
            } else {
                return new Error(ErrorMessages.RequestFailed);
            }
        } catch (e) {
            return new Error(ErrorMessages.NetworkError);
        }
    }

    useEffect(() => {
        const fetchInfoForCompanies = async () => {
            let valuesChanged = false;
            const newSsnToCompanyMap = {...ssnToCompanyMap};
            const values = Object.entries(companyToSubTableMap);
            for (const [ ssn ] of values) {
                if (ssnToCompanyMap[ssn] === undefined) {
                    valuesChanged = true;
                    newSsnToCompanyMap[ssn] = await fetchCompanyInfo(ssn);
                }
            }

            if (valuesChanged) {
                setSsnToCompanyMap(newSsnToCompanyMap);
            }
        }
        fetchInfoForCompanies();
    }, [ companyToSubTableMap ]);
    
    const getExpandCompanyIcon = (id: string) => {
        const subTableOption = companyToSubTableMap[id];
        const companyData = ssnToCompanyMap[id];
        if (subTableOption === 'open') {
            //check if loading
            if (!companyData) {
                //it's loading
                return <Spinner className='loading-spinner' animation='border' />
            }
            //it has loaded
            return <FontAwesomeIcon
                className={cx('fa-icon', 'fa-circle-minus')}
                onClick={ () => toggleSubTableForCompanySsn(id, 'open')}
                icon={faMinusCircle}
            />
        }
        // it hasn't been clicked
        return <FontAwesomeIcon
            className={cx('fa-icon')}
            onClick={ () => toggleSubTableForCompanySsn(id, 'open')}
            icon={faPlusCircle}
        />
    }

    return (
        <div className="main KCL_listar-Almennt">
            <div className="main__inner-fluid">
                <div className='section__body'>
                    <div className='mini_shell'>
                        {/* HEAD SECTION */}
                        <HeadSection
                            title={model?.list.name ?? ''}
                            summary={<p>
                                Stærstu fyrirtækin á Íslandi á hverjum tíma. <br/>
                                Með sérpöntun á lista er hægt að fá breiðari sýn yfir fyrirtækin.
                            </p>}
                            actions={<>
                                <Button
                                    className='specialOrderButton'
                                    anchorProps={{ href: '/Listar/Serpontun' }}
                                >
                                    Sérpanta lista
                                </Button>
                                <Button
                                    showLoader={isItemLoading(true)}
                                    buttonType='buy'
                                    onClick={() => tryPurchaseItem(true, 'confirm-modal-with-reference')}
                                >
                                    Kaupa listann
                                </Button>
                            </>}
                            rightSide={<>
                                {(model?.meta !== null)
                                    ? <div className='stat-boxes'>
                                        <div className='stat-box'>
                                            <div className='stat-box__content'>
                                                <span className='title'>Fjöldi fyrirtækja</span>
                                                <strong className='value'>{model?.meta[0]["Fjöldi fyrirtækja"]}</strong>
                                            </div>
                                        </div>
                                        <div className='stat-box'>
                                            <div className='stat-box__content'>
                                                <span className='title'>Meðaltekjur</span>
                                                <strong className='value'>{model?.meta[0]["Meðaltekjur"]}</strong>
                                                <strong className="unit">M.kr.</strong>
                                            </div>
                                        </div>
                                        <div className='stat-box'>
                                            <div className='stat-box__content'>
                                                <span className='title'>EBITDA meðaltal</span>
                                                <strong className='value'>{model?.meta[0]["EBITDA (Meðaltal)"]}</strong>
                                                <strong className="unit">M.kr.</strong>
                                            </div>
                                        </div>
                                        <div className='stat-box'>
                                            <div className='stat-box__content'>
                                                <span className='title'>Hagnaður meðaltal</span>
                                                <strong className='value'>{model?.meta[0]["Afkoma (Meðaltal)"]}</strong>
                                                <strong className="unit">M.kr.</strong>
                                            </div>
                                        </div>
                                        <div className='stat-box'>
                                            <div className='stat-box__content'>
                                                <span className='title'>Eignir meðaltal</span>
                                                <strong className='value'>{model?.meta[0]["Eignir (Meðaltal)"]}</strong>
                                                <strong className="unit">M.kr.</strong>
                                            </div>
                                        </div>
                                    </div>
                                    : <p>ENGAR UPPLÝSINGAR FUNDUST</p>
                                }
                                {(model?.owned !== null)
                                    ? <div className='list-purchased-container'>
                                        <Button anchorProps={{href: `/Listar/Almennt-Skoda/${model?.list.id}?eventId=${model?.owned.balls}` }} buttonType='secondary'>Skoða lista</Button>
                                        <span className='italic-text'>Listi keyptur: {model?.owned.date.replace(/\//g, '.')}</span>
                                    </div>
                                    : null
                                }
                            </>}
                        />
                    </div>
                </div>
                <div className='section section--gray'>
                    <div className="shell">
                        <div className='tableInfo'>
                            <h2>Brot af listanum</h2>
                            <p className='table-disclaimer paragraph'>Allar upphæðir eru í milljónum króna.</p>
                        </div>
                        <DisplayBox>
                            {(model?.data === undefined || model?.data === null)
                                ? <Loading />
                                : <Table
                                    tableSize='lg'
                                    rowClassName={(item) => (companyToSubTableMap[item['Kennitala']] === undefined) ? undefined : 'table__opened-row'}
                                    //Kemur alltaf eitthvað drasl með
                                    data={model.data.slice(0, 10)}
                                    columns={[{
                                        title: 'nr.',
                                        renderCell: (item) => item['Númer'],
                                        textAlign: 'left',
                                        simpleSortable: (item) => parseInt(item['Númer']) 
                                    }, {
                                        title: 'Fyrirtæki',
                                        renderCell: (item) => {
                                            return <div className='table__openSubTable-button'>
                                                {getExpandCompanyIcon(item['Kennitala'])}
                                                <span>
                                                    <Link url={`/Fyrirtaeki/Yfirlit/${item['Kennitala']}`} linkSize='15'>{item['Nafn']}</Link>
                                                </span>
                                            </div>
                                        },
                                        textAlign: 'left',
                                        simpleSortable: (item) => item['Nafn'] 
                                    }, {
                                        title: 'kennitala',
                                        renderCell: (item) => item['Kennitala'],
                                        textAlign: 'left',
                                        simpleSortable: (item) => item['Kennitala'] 
                                    }, {
                                        title: 'ÍSAT',
                                        renderCell: (item) => (
                                            <Tooltip
                                                text={item['ÍSAT ID']}
                                                tooltip={ <span>{ item['ÍSAT skýring'] }</span> }
                                            />
                                        ),
                                        textAlign: 'center',
                                        simpleSortable: (item) => item['ÍSAT ID']
                                    }, {
                                        title: 'Tekjur',
                                        renderCell: (item) => formatNumber(parseInt(item['Tekjur (M.Kr.)']), '-', 0),
                                        textAlign: 'right',
                                        simpleSortable: (item) => parseInt(item['Tekjur (M.Kr.)']),
                                    }, {
                                        title: 'Gjöld',
                                        renderCell: (item) => formatNumber(parseInt(item['Gjöld (M.Kr.)']), '-', 0),
                                        textAlign: 'right',
                                        simpleSortable: (item) => parseInt(item['Gjöld (M.Kr.)'])
                                    }, {
                                        title: 'ebitda',
                                        renderCell: (item) => formatNumber(parseInt(item['EBITDA (M.Kr.)']), '-', 0),
                                        textAlign: 'right',
                                        simpleSortable: (item) => parseInt(item['EBITDA (M.Kr.)'])
                                    }, {
                                        title: 'hagnaður',
                                        renderCell: (item) => formatNumber(parseInt(item['Hagnaður (M.Kr.)']), '-', 0),
                                        textAlign: 'right',
                                        simpleSortable: (item) => parseInt(item['Hagnaður (M.Kr.)'])
                                    }, {
                                        title: 'eignir',
                                        renderCell: (item) => formatNumber(parseInt(item['Eignir (M.Kr.)']), '-', 0),
                                        textAlign: 'right',
                                        simpleSortable: (item) => parseInt(item['Eignir (M.Kr.)'])
                                    }, {
                                        title: 'eigið fé',
                                        renderCell: (item) => formatNumber(parseInt(item['Eigið fé (M.Kr.)']), '-', 0),
                                        textAlign: 'right',
                                        simpleSortable: (item) => parseInt(item['Eigið fé (M.Kr.)'])
                                    }, {
                                        title: 'skuldir',
                                        renderCell: (item) => formatNumber(parseInt(item['Skuldir (M.Kr.)']), '-', 0),
                                        textAlign: 'right',
                                        simpleSortable: (item) => parseInt(item['Skuldir (M.Kr.)'])
                                    }, {
                                        title: 'fé frá Rekstri',
                                        renderCell: (item) => formatNumber(parseInt(item['Fé frá rekstri (M.Kr.)']), '-', 0),
                                        textAlign: 'right',
                                        simpleSortable: (item) => parseInt(item['Fé frá rekstri (M.Kr.)'])
                                    }, {
                                        title: 'fé frá fjárfestingum',
                                        renderCell: (item) => formatNumber(parseInt(item['Fé frá fjárfestingum (M.Kr.)']), '-', 0),
                                        textAlign: 'right',
                                        simpleSortable: (item) => parseInt(item['Fé frá fjárfestingum (M.Kr.)'])
                                    }, {
                                        title: 'fé frá fjármögnunarhreyfingum',
                                        renderCell: (item) => formatNumber(parseInt(item['Fé frá fjármögnunarhreyfingum (M.Kr.)']), '-', 0),
                                        textAlign: 'right',
                                        simpleSortable: (item) => parseInt(item['Fé frá fjármögnunarhreyfingum (M.Kr.)'])
                                    }]}
                                    renderUnderRowComponent={(item) => {
                                        const subTableOption = companyToSubTableMap[item['Kennitala']];
                                        const companyData = ssnToCompanyMap[item['Kennitala']];
                                        if(subTableOption !== undefined)
                                        {
                                            if (companyData === undefined) {
                                                return null
                                            } else if (companyData instanceof Error) {
                                                return (
                                                    <tr className='section__table__sub-table'>
                                                        <td colSpan={99} className='full-width-td'>
                                                            <div className='section__table__sub-table__inner'>
                                                                <ErrorAlert error={companyData} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            }

                                            return (
                                                <tr className='section__table__sub-table'>
                                                    <td colSpan={99} className='full-width-td'>
                                                        <div className='section__table__sub-table__inner'>
                                                            <div className="info-col">
                                                                <div className="info-item">
                                                                    <span className="title">Heimilisfang</span>
                                                                    <span className="value">{displayAddress(companyData?.CompanyAddress, companyData?.CompanyPostalCode, companyData?.CompanyCity) ?? '-'}</span>
                                                                </div>
                                                            </div>
                                                            <div className="info-col">
                                                                <div className="info-item">
                                                                    <span className="title">Endurskoðandi</span>
                                                                    <span className="value">{companyData.AuditorName ?? '-'}</span>
                                                                </div>
                                                            </div>
                                                            <div className="info-col">
                                                                <div className="info-item">
                                                                    <span className="title">Framkvæmdastjóri</span>
                                                                    <span className="value">{companyData.ExecutiveName ?? '-'}</span>
                                                                </div>
                                                            </div>
                                                            <div className="info-col">
                                                                <div className="info-item">
                                                                    <span className="title">Forráðamaður</span>
                                                                    <span className="value">{companyData.ChairmanName ?? '-'}</span>
                                                                </div>
                                                            </div>
                                                            <div className="info-col">
                                                                <div className="info-item">
                                                                    <span className="title">Skráð</span>
                                                                    <span className="value">{getTableDate(companyData?.DateRegistered, 'DD/MM/YYYY', '.') ?? '-'}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        } else {
                                            return null
                                        }
                                    }}
                                />
                            }
                        </DisplayBox>
                    </div>
                </div>
            </div>
            <ModalsAndToasts />
        </div>
    )
}

export default Almennt;
