// Package imports:
import React from 'react'

const Logo: React.FC = () => (
  <div className="KCL_logo">
    <a href="/" aria-label="Keldan - forsíða">
      <div className='icons-container'>
        {/* ICON */}
        <svg className='icon' viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.4147 0.00756836H3C1.34315 0.00756836 0 1.35071 0 3.00757V18.464L18.4147 0.00756836Z" fill="#009FE3"/>
        <path d="M3 34.9979L18.2359 34.9979L5.9525 22.8555L-2.74194e-07 28.7263L-1.31159e-07 31.9979C-5.87221e-08 33.6548 1.34314 34.9979 3 34.9979Z" fill="#009FE3"/>
        <path d="M34.9121 31.9978L34.9121 2.99994C34.9121 1.34308 33.569 -6.09175e-05 31.9121 -6.07723e-05L28.7726 -6.04972e-05L11.1736 17.6564L28.4941 34.9978L31.9121 34.9978C33.569 34.9978 34.9121 33.6547 34.9121 31.9978Z" fill="#009FE3"/>

        </svg>
        {/* KELDAN LETTERS */}
        <svg className='letters' viewBox="0 0 136 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M70.4222 30.6768C66.3795 30.6768 63.0906 27.3804 63.0906 23.3282C63.0906 19.276 66.3795 15.9797 70.4222 15.9797C74.4605 15.9797 77.7459 19.2689 77.7534 23.3142V23.3325H77.7541C77.7516 27.3825 74.4638 30.6768 70.4222 30.6768ZM82.0442 -0.00111389H77.7534V14.2971C75.7517 12.6618 73.1998 11.6791 70.4222 11.6791C64.0135 11.6791 58.7998 16.905 58.7998 23.3282C58.7998 29.7514 64.0135 34.9773 70.4222 34.9773C76.8309 34.9773 82.0449 29.7514 82.0449 23.3282C82.0449 23.3189 82.0442 23.3096 82.0442 23.3006V-0.00111389Z" fill="white"/>
          <path d="M28.3656 21.1778C29.2839 18.1695 32.0804 15.9759 35.3782 15.9759C38.6754 15.9759 41.4708 18.1685 42.3901 21.1749L28.3656 21.1778ZM35.3782 11.6754C28.9695 11.6754 23.7559 16.9012 23.7559 23.3244C23.7559 29.7476 28.9695 34.9735 35.3782 34.9735C38.5137 34.9735 41.4533 33.741 43.6552 31.503L40.5998 28.4833C39.2106 29.8953 37.3563 30.673 35.3782 30.673C32.0829 30.673 29.2885 28.4829 28.3678 25.4783L47.0013 25.4743V23.3244C47.0013 16.9012 41.7873 11.6754 35.3782 11.6754Z" fill="white"/>
          <path d="M97.4216 30.6768C93.3785 30.6768 90.0896 27.3804 90.0896 23.3283C90.0896 19.2761 93.3785 15.9797 97.4216 15.9797C101.465 15.9797 104.753 19.2761 104.753 23.3283C104.753 27.3804 101.465 30.6768 97.4216 30.6768ZM97.4216 11.6792C91.0129 11.6792 85.7988 16.905 85.7988 23.3283C85.7988 29.7515 91.0129 34.9773 97.4216 34.9773C100.199 34.9773 102.752 33.9943 104.753 32.3587V34.9902H109.044V23.3326V23.3283C109.044 16.905 103.83 11.6792 97.4216 11.6792Z" fill="white"/>
          <path d="M124.4 11.7014C118.003 11.7014 112.8 16.9172 112.8 23.3283V34.9902H117.091V23.3283C117.091 19.2886 120.37 16.0019 124.4 16.0019C128.431 16.0019 131.71 19.2886 131.71 23.3283V34.9902H136.001V23.3283C136.001 16.9172 130.797 11.7014 124.4 11.7014Z" fill="white"/>
          <path d="M50.7549 34.9989H55.0457V-0.00101852H50.7549V34.9989Z" fill="white"/>
          <path d="M11.8682 23.4942L23.6744 11.6609H17.6065L5.22929 24.0665V11.6792H0.938477V34.9949H5.22929V30.1478L8.83428 26.5346L17.2682 34.9877H23.3358L11.8682 23.4942Z" fill="white"/>
        </svg>
      </div>
    </a>
  </div>
)

export default Logo
